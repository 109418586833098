import React, { Component } from 'react';
import RadioButton from '../FormRadioButton';
import { RadioWrapper } from './Styled';

class FormRadioText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValue: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && prevProps.value !== this.props.value) {
            this.setState({
                radioValue: this.props.value,
            });
        }
    }

    radioChangeHandler = (radio) => {
        const states = this.props.states,
            radioValue = radio,
            fieldName = this.props.id;
        states.forEach((that) => {
            const isGlobalState = that.constructor.name === 'Object',
                fields = isGlobalState
                    ? that.getFields()
                    : { ...that.state.fields },
                errorFields = isGlobalState
                    ? that.getErrorFields()
                    : { ...that.state.errorFields };
            fields[fieldName] = radioValue;
            errorFields[fieldName] = null;
            this.setState({
                radioValue,
            });
            if (isGlobalState) {
                that.setFields(fields);
                that.setErrorFields(errorFields);
            } else {
                if (!this.props.extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...this.props.extraLocalStatesOnChange,
                    });
                }
            }
        });
    };

    render() {
        return (
            <RadioWrapper>
                {this.props.list.map((radio) => (
                    <RadioButton
                        key={this.props.id + '_' + radio.value}
                        id={this.props.id + '_' + radio.value}
                        name={this.props.id}
                        label={radio.text}
                        checked={
                            (this.state.radioValue.value &&
                                this.state.radioValue.value === radio.value) ||
                            false
                        }
                        onChange={() => this.radioChangeHandler(radio)}
                    />
                ))}
            </RadioWrapper>
        );
    }
}

export default FormRadioText;
