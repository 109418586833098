import React from 'react';
import { 
    SponsoredColumnWrapper,
    TopWrapper,
    BackButton,
    AmountText,
    TotalItem,
    CardWrapper,
    BodyWrapper,
    BodyHeader,
    DaysLeftText,
    NumberText,
    TopSide,
    LeftSideCard,
    DueDate,
    OrderNumber,
    BottomButtons,
    ButtonCardWrapper,
    DeleteButton,
    ReorderButton,
    BlueButtonWrapper,
    ExpiredTag,
    ModalTitleStyles,
    ModalCloseStyles,
    SponsoredPackageContent,
    RefundButton,
    AssetsButton
} from './Styled';
import { ArrowBack } from '../../../styles/Styled';
import Modal from '../Modal';
import IconNew from '../IconNew';

class  MyListingSponsoredColumn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen:false,
            fakedata: {
            totalBudget:'600',
            totalActive:'68',
            totalZip:'3',
            packages: [ 
                {ticket:'94105', daysleft:25,cost:'60',start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false},
                {ticket:'94105', daysleft:25,cost:'60',start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false},
                {ticket:'94105', daysleft:25,cost:'60',start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false},
            ],
            expiredPackages: [
                {ticket:'94105', daysleft:25,cost:'60',start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false},
                {ticket:'94105', daysleft:25,cost:'60',start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false},
            ],
        }
        }
    }

    extendCardHandler = (isExtended, index, type) => {
        if(type === 'current') {
            let newData = {...this.state.fakedata};
            newData.packages[index].isExtended  = !isExtended;
            this.setState({
                fakedata:newData
            })
        };
        if (type === 'expired') {
            let newData = {...this.state.fakedata};
            newData.expiredPackages[index].isExtended  = !isExtended;
            this.setState({
                fakedata:newData
            })
        }

    }

    deleteCardHandler = (i) => {
        let newData = {...this.state.fakedata};
        let filteredArray = newData.packages.filter((item, index) => index !== i);
        this.setState({
            fakedata:{...this.state.fakedata, packages:filteredArray}
        })
    }

    Modal = () => {
        return (
            <Modal
            isOpen={this.state.modalOpen}
            handleClose={() => this.setState({ modalOpen: false })}
            containerWidth='375'
            containerHeight='220'
            containerSizeUnit="px"
            containerPadding="0"
            title='What do you want to do with this sponsored package ?'
            titleStyled={ModalTitleStyles}
            closeStyled={ModalCloseStyles}
            >
                <SponsoredPackageContent>
                    <RefundButton>
                        Refund
                    </RefundButton>
                    <AssetsButton>
                        Move to Assets
                    </AssetsButton>
                </SponsoredPackageContent>
            </Modal>
        )
    }

    packageCard = (data, type) => {
        return (
            data.map((item, index) => {
               return (
                <React.Fragment key={index  }>
                    <CardWrapper key={index} isExtended={item.isExtended}>
                        <TopSide isExtended={item.isExtended}>
                            {item.isExtended && type === 'current' && (
                                <DeleteButton onClick={() => this.deleteCardHandler(index, type)}>
                                    X
                                </DeleteButton>
                            )}
                            {type === 'expired' && (
                                <ExpiredTag>
                                    Expired
                                </ExpiredTag>
                            )}
                        <div className='topside'>
                            <LeftSideCard>
                                <IconNew
                                    bp1="-287"
                                    bp2="-208"
                                    iwidth="14"
                                    iheight="20"
                                 
                                />
                                <NumberText>
                                    {item.ticket}
                                </NumberText>
                            </LeftSideCard>
                            <div className='daysleft'>
                                Days left <DaysLeftText>{item.daysleft}</DaysLeftText>
                            </div>
                        </div>
                        {item.isExtended && (
                            <DueDate>
                                <div>
                                    <span className='datetype'>start Date</span>
                                    <span className='date'>{item.start}</span>
                                </div>
                                <div>
                                    <span className='datetype'>end date</span>
                                    <span className='date'>{item.end}</span>
                                </div>
                            </DueDate>
                        )}
                        </TopSide>
                        <div className='bottomside' onClick={() => this.extendCardHandler(item.isExtended, index, type)}>
                            <div className='left'>
                                {item.isExtended ? 
                                <IconNew
                                    bp1="-258"
                                    bp2="-228.2"
                                    iwidth="8.5"
                                    iheight="4.8"
                            /*         style={'arrowup'} */
                 
                                /> 
                            : 
                                <IconNew
                                    bp1="-258"
                                    bp2="-238"
                                    iwidth="8.3"
                                    iheight="4.8"
                                />}
                                {
                                    item.isExtended && (
                                        <OrderNumber>
                                            Order number: #{item.order}
                                        </OrderNumber>
                                    )
                                }
                            </div>
                            <div className='right' isExtended={item.isExtended}>
                                Cost<span>%{item.cost}</span>
                            </div>
                        </div>
                    </CardWrapper>
                    {item.isExtended && type === 'current' &&  (
                        <BottomButtons>
                            <ButtonCardWrapper>
                                    Pause
                            </ButtonCardWrapper>
                            <ButtonCardWrapper>
                                    add to Assets
                            </ButtonCardWrapper>
                        </BottomButtons>
                    )}
                      {item.isExtended && type === 'expired' &&  (
                        <ReorderButton>
                            Reorder
                        </ReorderButton>
                    )}     
                </React.Fragment>
               )
           })
        );
    }
 
    render() {
        return (
            <SponsoredColumnWrapper>
                <TopWrapper>
                    <div className='header'>
                        <BackButton>
                            <ArrowBack />
                        </BackButton>
                        <span>
                            Sponsored listings
                        </span>
                    </div>
                    <div className='totalsection'>
                        <TotalItem>
                            <span className='budget'>
                                Total spent Budget
                            </span>
                            <AmountText budget>
                                ${this.state.fakedata.totalBudget}
                            </AmountText>
                        </TotalItem>
                        <TotalItem>
                            <span>
                                Total active days
                            </span>
                            <AmountText>
                                ${this.state.fakedata.totalActive}
                            </AmountText>
                        </TotalItem>
                        <TotalItem>
                            <span >
                                Total ZIP Codes
                            </span>
                            <AmountText>
                                ${this.state.fakedata.totalZip}
                            </AmountText>
                        </TotalItem>
                    </div>
                </TopWrapper>
                <BodyWrapper>
                    <BodyHeader>
                        Your current packages
                    </BodyHeader>
                    {this.packageCard(this.state.fakedata.packages, 'current')}
                    <BodyHeader margintop={'30px'}>
                        Expired packages
                    </BodyHeader>
                    {this.packageCard(this.state.fakedata.expiredPackages, 'expired')}
                    <BlueButtonWrapper onClick={() => this.setState({modalOpen: true})}>
                    + Add New Package
                    </BlueButtonWrapper>
                </BodyWrapper>
                {this.Modal()}
            </SponsoredColumnWrapper>
        );
    }
}

export default MyListingSponsoredColumn
