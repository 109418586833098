import React, { Component, Fragment } from 'react';
import {
    ListMenu,
    ListMenuLoading,
    MenuItem,
    MenuItemLoading,
    MenuLink,
    SubMenu,
    SubMenuItem,
    SubMenuLink,
    Title,
} from './Styled';
import { ArrowBack, Column } from '../../../styles/Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import CircleProgress from '../CircleProgress';

class WizardPages extends Component {
    render() {
        return (
            <Fragment>
                <Column type="one_column_setting" first padding_right>
                    {this.props.children}
                </Column>

                <Column type="one_column_right_fixed_simple" first>
                    <Title>
                        <ArrowBack />
                        {this.props.title}
                    </Title>

                    {this.props.menu.length === 0 && (
                        <ListMenuLoading>
                            <MenuItemLoading>
                                <em>
                                    <b />
                                </em>
                            </MenuItemLoading>
                            <MenuItemLoading>
                                <em>
                                    <b />
                                </em>
                            </MenuItemLoading>
                            <MenuItemLoading>
                                <em>
                                    <b />
                                </em>
                            </MenuItemLoading>
                        </ListMenuLoading>
                    )}
                    {this.props.menu.length !== 0 && (
                        <ListMenu>
                            {this.props.menu.map((menu, index) => (
                                <MenuItem key={index}>
                                    <MenuLink
                                        to={menu.url}
                                        key={index}
                                        selected={menu.active}
                                        checked={menu.checked}
                                    >
                                        {menu.active && <span />}
                                        <strong>
                                            {menu.checked ? (
                                                <u />
                                            ) : (
                                                <CircleProgress
                                                    number={index + 1}
                                                    percent={
                                                        menu.active
                                                            ? this.props
                                                                  .percentComplete
                                                            : 0
                                                    }
                                                />
                                            )}
                                        </strong>
                                        {menu.title}
                                    </MenuLink>
                                    {menu.subMenu && (
                                        <SubMenu>
                                            {menu.subMenu.map(
                                                (childMenu, indexInner) => (
                                                    <SubMenuItem
                                                        key={'sub' + indexInner}
                                                    >
                                                        <SubMenuLink
                                                            to={childMenu.url}
                                                            key={indexInner}
                                                            selected={
                                                                childMenu.active
                                                            }
                                                            checked={
                                                                childMenu.checked
                                                            }
                                                        >
                                                            <strong>
                                                                {childMenu.checked ? (
                                                                    <u />
                                                                ) : (
                                                                    <CircleProgress
                                                                        number={
                                                                            indexInner +
                                                                            1
                                                                        }
                                                                        percent={
                                                                            childMenu.active
                                                                                ? menu.childPercentComplete
                                                                                : 0
                                                                        }
                                                                        small
                                                                    />
                                                                )}
                                                            </strong>
                                                            {childMenu.title}
                                                        </SubMenuLink>
                                                    </SubMenuItem>
                                                )
                                            )}
                                        </SubMenu>
                                    )}
                                </MenuItem>
                            ))}
                        </ListMenu>
                    )}
                </Column>

                <ClearFloats />
            </Fragment>
        );
    }
}

export default WizardPages;
