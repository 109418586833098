import React, {Component, Fragment} from 'react';

import {
    BigImage, ImageThumbnails, ImgThumb
} from './Styled';
import {ClearFloats} from "../BaseTemplate/Styled";
import ImageItemAndMore, {ImageItemType} from "../ImageItemAndMore";

export default class ListingGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldShowSlider: false,
            selectedImage: 0,
            bigImgUrl: (props.listingImages[0]) ? props.listingImages[0].image : '',
            activeImgThumb: 0
        };
        this.maxImgThumb = 10;
    }

    /*UNSAFE_componentWillMount() {
        if (typeof this.props.listingImages !== 'undefined') {
            this.setState({listingImages: this.props.listingImages});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.listingImages !== this.state.listingImages) {
            this.setState({listingImages: nextProps.listingImages});
        }
    }

    toggleSlider = (selectedImage) => {
        this.setState({shouldShowSlider: !this.state.shouldShowSlider, selectedImage});
    }*/

    imgThumbClickHandler = (imgIndex) => {
        this.setState({
            bigImgUrl: this.props.listingImages[imgIndex].image,
            activeImgThumb: imgIndex
        })
    }

    render() {
        const listingImages = [...this.props.listingImages],
              imageAlt = "Property Picture";
        if (listingImages.length === 0) {
            return null;
        }
        return (
            <Fragment>

                <BigImage
                    src={this.state.bigImgUrl}
                    alt={imageAlt} title={imageAlt}
                />
                <ImageThumbnails>
                    {
                        listingImages.map(
                            (img, index) =>
                                (index + 2 < this.maxImgThumb) &&
                                <ImgThumb
                                    key={img.pk}
                                    src={img.image} active={(index === this.state.activeImgThumb)}
                                    alt={imageAlt} title={imageAlt}
                                    onClick={() => this.imgThumbClickHandler(index)}
                                />
                        )
                    }
                    {
                        (listingImages.length > this.maxImgThumb) &&
                        <ImageItemAndMore
                            type={ImageItemType.SMALL}
                            imgCount={listingImages.length}
                            imgMax={this.maxImgThumb}
                        />
                    }
                    <ClearFloats/>
                </ImageThumbnails>

                {/*{listingImages.length >= 3 &&
                    <div>
                        <Image3LeftWrapper 
                            onClick={() => this.toggleSlider(0)} 
                            backgroundImage={listingImages[0].image}
                        />
                        
                        
                        <Image3RightTopWrapper 
                            onClick={() => this.toggleSlider(1)} 
                            backgroundImage={listingImages[1].image}
                        />
                        
                        <Image3RightBottomWrapper 
                            onClick={() => this.toggleSlider(2)} 
                            backgroundImage={listingImages[2].image}
                        />
                        
                        {listingImages.length > 3 &&
                            <Image3Count onClick={() => this.toggleSlider(2)}>
                                +{listingImages.length - 2}
                            </Image3Count>
                        }
                        
                    </div>
                }
                
                {listingImages.length === 2 &&
                    <div>
                        <Image3LeftWrapper 
                            onClick={() => this.toggleSlider(0)}
                            backgroundImage={listingImages[0].image}
                        />
                        <Image2RightWrapper 
                            onClick={() => this.toggleSlider(1)} 
                            backgroundImage={listingImages[1].image}
                        />
                    </div>
                }
                
                {listingImages.length === 1 &&
                    <Image1MainWrapper
                        onClick={() => this.toggleSlider(0)}
                        backgroundImage={listingImages[0].image}
                    />
                }
                
                <ListingCaption 
                    iLeft="10" 
                    iTop="410" 
                    bedrooms = {this.props.bedrooms}
                />
                
                {listingImages.length > 0 &&
                    <ListingSlider 
                        isOpen={this.state.shouldShowSlider} 
                        closeHadnler={this.toggleSlider} 
                        listingImages={listingImages}
                        selectedImage={this.state.selectedImage}
                    />
                }*/}
            </Fragment>
        )
    }
}