import React, { Component } from 'react';
import { BackButton, Title } from './Styled';
import { ArrowBack, Column } from '../../../styles/Styled';
import PropTypes from 'prop-types';

class ProfileMenuContent extends Component {
    render() {
        return (
            <Column type="one_column_right_fixed_simple" first>
                {this.props.title && (
                    <Title onClick={this.props.onTitleClick}>
                        <BackButton>
                            <ArrowBack />
                        </BackButton>
                        {this.props.title}
                    </Title>
                )}

                {this.props.children}
            </Column>
        );
    }
}

export default ProfileMenuContent;

ProfileMenuContent.propTypes = {
    title: PropTypes.string.isRequired,
    onTitleClick: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
};
