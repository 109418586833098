import React from 'react';
import {
    Wrapper,
    SlideLink,
    Slide,
    ArrowRightStyle,
    ArrrowLeftStyle,
    CarouselWrapper,
    SlideLinkWrapper,
    ImgStyle,
    IconWrapper,
    SlideWrapper,
    Tick,
    SlideLinkInline
} from './Styled';

class CarouselComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleSlide: 0,
            targetSlide: 0,
        };
        this.wrapperRef = React.createRef();
        this.targetSlideRef = React.createRef();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.targetSlide !== this.state.targetSlide) {
            const targetSlide = this.targetSlideRef.current;
            const wrapper = this.wrapperRef.current;
            if (wrapper && targetSlide) {
                wrapper.scrollTo({
                    top: 0,
                    left: targetSlide.offsetLeft,
                    behavior: 'smooth',
                });
            }
        }
    };


    handleScroll = () => {
        let { width } = this.wrapperRef.current.getBoundingClientRect();
        let { scrollLeft } = this.wrapperRef.current;
        this.setState({
            visibleSlide: Math.round(scrollLeft / width),
        });
    };

    render() {
        return (
            <CarouselWrapper id="trap" tabIndex="0">
                <ArrrowLeftStyle
                    color={'white'}
                    onClick={() =>
                        this.setState({
                            targetSlide: Math.max(
                                0,
                                this.state.targetSlide - 1
                            ),
                        })
                    }
                />
                <ArrowRightStyle
                    color={'white'}
                    onClick={() =>
                        this.setState({
                            targetSlide: Math.min(
                                this.state.targetSlide + 1,
                                this.props.data.length - 1
                            ),
                        })
                    }
                />

                <Wrapper onScroll={this.handleScroll} pictureHeight={this.props.pictureHeight} ref={this.wrapperRef}>
                    {this.props.data.map((item, i) => (
                        <Slide
                            key={`slide-${i}`}
                            ref={
                                i === this.state.targetSlide
                                    ? this.targetSlideRef
                                    : null
                            }
                        >
                            <SlideWrapper>
                                <ImgStyle
                                    selectableMode={this.props.selectableMode}
                                    pictureWidth={this.props.pictureWidth}
                                    pictureHeight={this.props.pictureHeight}
                                    src={item}
                                    onClick={() =>
                                        this.props.onPictureClickHandler(
                                            i,
                                            this.state.targetSlide,
                                            item
                                        )
                                    }
                                />
                                {this.props.selectableMode && (
                                <IconWrapper
                                    selected={this.props.selectedPicture === i}
                                    onClick={() =>
                                        this.props.onPictureClickHandler(
                                            i,
                                            this.state.targetSlide,
                                            item
                                        )
                                    }
                                >
                                    <Tick />
                                </IconWrapper>
                                )}
                            
                            </SlideWrapper>
                        </Slide>
                    ))}
                </Wrapper>
                <SlideLinkWrapper 
                    inlineCircles={this.props.inlineCircles}
                >
                    {this.props.data.map((_, i) => {
                        return (
                            <React.Fragment key={i}>
                                {this.props.inlineCircles ? (
                                    <SlideLinkInline
                                        firstItem={i === 0}
                                        isActive={this.state.visibleSlide === i}
                                        onClick={() =>
                                        this.setState({ targetSlide: i })
                                    }
                                    />  
                                ) : (
                                <SlideLink
                                    firstItem={i === 0}
                                    isActive={this.state.visibleSlide === i}
                                    onClick={() =>
                                        this.setState({ targetSlide: i })
                                    }
                                />  
                                )} 
                            </React.Fragment>
                        );
                    })}
                </SlideLinkWrapper>
            </CarouselWrapper>
        );
    }
}

export default CarouselComponent;
