import React, { Component } from 'react';

import {
    Wrapper,
    ContentWrapper,
    ArrowRightBlack,
    PropertyImage,
    InfoText,
    StatusText,
    DescText,
    PosterImage,
    PostText,
    PosterName,
    AnnouncementText,
    AnnouncementImage,
    IdeaInputStyles,
} from '../SharedListingProfile/Styled';
import {
    ShareButtonSubmit
} from '../SharedListingGroup/Styled';
import { OwnerImage } from '../ListingOnFeed/Styled';
import InputText from '../FormInputText/FormInputText';
import IconNew from '../IconNew';
import ButtonText from '../FormButtonText';
import SearchMembers from "../SearchMembers";

class SharedListingMessage extends Component {

    render() {
        return (
            <Wrapper>
                <OwnerImage src="/assets/img/temp/property_owner_sample_photo.png" />
                <InputText
                    id="shareInput"
                    name="shareInput"
                    type="text"
                    styled={IdeaInputStyles}
                    placeholder="Say something about this post ..."
                />
                {this.props.type === SharedCountsType.ANNOUNCEMENT ? (
                    <ContentWrapper>
                        <InfoText>
                            <ArrowRightBlack />
                            {this.props.data.title}
                        </InfoText>
                        <AnnouncementText>Announcement</AnnouncementText>
                        <AnnouncementImage src={this.props.data.images[0]} />
                        <DescText>{this.props.data.description}</DescText>
                        <PosterImage src={this.props.data.owner.profile} />
                        <PostText>Posted by</PostText>
                        <PosterName>
                            {this.props.data.owner.full_name}
                        </PosterName>
                    </ContentWrapper>
                ) : (
                    <ContentWrapper>
                        <PropertyImage size={'lg'}>
                            <img
                                src={this.props.data.images[0]}
                                alt={'Property'}
                            />
                        </PropertyImage>
                        <PropertyImage size={'sm'}>
                            <img
                                src={this.props.data.images[1]}
                                alt={'Property'}
                            />
                        </PropertyImage>
                        <PropertyImage size={'sm'}>
                            <span />
                            <img
                                src={this.props.data.images[2]}
                                alt={'Property'}
                            />
                            <strong>
                                <IconNew
                                    bp1="-103"
                                    bp2="-115"
                                    iwidth="26"
                                    iheight="23"
                                />
                                {this.props.data.images.length - 3}
                            </strong>
                        </PropertyImage>
                        <InfoText>
                            <ArrowRightBlack />
                            {this.props.data.bedrooms} Bed, +
                            {this.props.data.bathrooms} Bath,{' '}
                            {this.props.data.bedBathDirection}
                        </InfoText>
                        <StatusText>
                            Apartment-For {this.props.data.for}
                        </StatusText>
                        <DescText>{this.props.data.description}</DescText>
                        <PosterImage src={this.props.data.owner.profile} />
                        <PostText>Posted by</PostText>
                        <PosterName>
                            {this.props.data.owner.full_name}
                        </PosterName>
                    </ContentWrapper>
                )}

                <SearchMembers
                    id="shared_listing_msg"
                />

                <ButtonText
                    onClick={this.shareButtonClick}
                    styled={ShareButtonSubmit}
                >
                    Share
                </ButtonText>
            </Wrapper>
        );
    }
}

export const SharedCountsType = {
    NORMAL: 'normal',
    ANNOUNCEMENT: 'announcement',
    BIG: 'big',
};

export default SharedListingMessage;
