import React, {Component} from 'react';
import GoogleMapView from "../GoogleMapView";

class FormMapSimple extends Component {

    constructor(props) {
        super(props);
        this.state = {
            points: []
        }
    }

    componentDidMount() {
        this.setState({
            points: this.props.value || []
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fieldState.latitude !== this.props.fieldState.latitude &&
            prevProps.fieldState.longitude !== this.props.fieldState.longitude) {
            this.setState({
                points: [{
                    position: {
                        id: 1,
                        lat: this.props.fieldState.latitude,
                        lng: this.props.fieldState.longitude
                    }
                }]
            })
        }
    }

    render() {
        return (
            <GoogleMapView
                container={this.props.container}
                points={this.state.points}
            />
        )
    }
}

export default FormMapSimple;