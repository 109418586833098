import React from 'react';
import { Main } from '../../styles/Styled';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import MyNetworksSearch from '../Modules/MyNetworksSearch';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';
import ProfileMenu from '../Modules/ProfileMenu';

class MyNetworks extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;
    }

    renderBody() {
        const { category } = this.props.match.params;

        return (
            <Main>
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    (() => {
                        switch (category) {
                            case 'connections':
                                return <MyNetworksSearch defaultIndex={0} />;
                            case 'peoplesuggestion':
                                return <MyNetworksSearch defaultIndex={1} />;
                            case 'groups':
                                return <MyNetworksSearch defaultIndex={2}  />;
                            case 'groupsuggestion':
                                return <MyNetworksSearch defaultIndex={3} />;
                            case 'followers':
                                return <MyNetworksSearch defaultIndex={4} />;
                            case 'followings':
                                return <MyNetworksSearch defaultIndex={5} />;
                            case 'requests':
                                return <MyNetworksSearch defaultIndex={6} />;
                            default:
                                return <MyNetworksSearch defaultIndex={0} />;
                        }
                    })()
                )}
                <ProfileMenu />
            </Main>
        );
    }
}

export default observer(MyNetworks);
