import React, { Component } from 'react';
import { PopContainer, Input } from './Styled';

class ChangeShowStatusPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPop: false,
            showType: '',
        };
    }
    componentDidMount() {
        this.setState({
            showType: this.props.showType,
        });
    }
    render() {
        const group = this.props.group || '';
        const field = this.props.field;
        const types = this.props.types || [];
        return (
            <>
                <Input
                    id="showSelector"
                    name="showSelector"
                    readOnly
                    onClick={() =>
                        this.props.disabled
                            ? null
                            : this.setState({ showPop: true })
                    }
                    value={this.props.showType}
                    disable={this.props.disabled}
                    onBlur={() => this.setState({ showPop: false })}
                />
                
                {types !== [] && (
                    <PopContainer
                        display={this.state.showPop ? 'block' : 'none'}
                    >
                        {types.map((type, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    this.setState({ showType: type });
                                    this.props.showChange(group, field, type);
                                    this.setState({ showPop: false });
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {type}
                            </button>
                        ))}
                    </PopContainer>
                )}
            </>
        );
    }
}

export default ChangeShowStatusPop;
