import styled from "styled-components";

export const Images = styled.div`
    margin-top: 20px;
    h2 {
        font-size: 24px;
        margin: 0 0 20px;
        color: ${props => props.theme.darkBlue};
    }

    @media ${props => props.theme.mediaHD} {
        font-size: 17px;
    }
`;

export const ModalScrollable = styled.div`
    max-height: 580px;
    ${
        props => (props.isScroll)
            ? ' overflow-y: scroll; '
            : ''
    }
    img {
        width: 150px;
        height: 150px;
        margin: 5px;
        object-fit: cover;
    }
`;