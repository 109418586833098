import React, { Component } from 'react';
import { Column } from '../../../styles/Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import MyListingsSearchTabBar from '../MyListingsSearchTabBar';
import MyListingAddNewPackageColumn from '../MyListingAddNewPackageColumn';
import MyListingSponsoredColumn from '../MyListingSponsoredColumn';

export class MyListingsAll extends Component {
    render() {
        return (
            <React.Fragment>
                <Column type="one_column_setting" padding_right first>
                    <MyListingsSearchTabBar
                        defaultPageIndex={this.props.defaultIndex}
                        fakeData={this.props.data}
                        currentDataHandler={this.props.currentDataHandler}
                        currentStatisticsIndex={this.props.id}
                        pageIndex={this.props.pageIndex}
                        category={this.props.category}
                        backButtonHandler={this.props.backButtonHandler}
                     />
                </Column>
                {this.props.pageIndex === 2 &&  (
                    <Column type='one_column_right_fixed_simple'  scrollable first background={'culturedGray'}>
                        <ProfileMenu closed unexpandable />
                        <MyListingSponsoredColumn />
                      {/*  <MyListingAddNewPackageColumn /> */}
                    </Column>
                )}
                {this.props.pageIndex !== 2 && (
                    <ProfileMenu />
                )}
                <ClearFloats />
            </React.Fragment>
        );
    }
}

export default MyListingsAll;

