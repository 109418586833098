import styled, { css } from 'styled-components';

export const SkeletonLoadingEffect = css`
    background: ${(props) => props.theme.graySkeletonAnim}
        linear-gradient(
            100deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        )
        repeat-y 0 0;
    background-size: 50px 200px;
    animation: shine 1s infinite;
    @keyframes shine {
        to {
            background-position: 100% 0;
        }
    }
`;

export const SkeletonHead = styled.span`
    display: inline-block;
    width: 270px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    ${SkeletonLoadingEffect};
`;

export const LoadingContent = styled.div`
    div {
        width: 540px;
        height: 50px;
        margin-bottom: 20px;
        ${SkeletonLoadingEffect};
    }
`;

export const NavigationLoading = styled.div`
    box-sizing: border-box;
    height: 85px;
    margin-top: 85px;
    border: solid 1px transparent;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    ${SkeletonLoadingEffect};
`;

export const MenuGroupWrapper = styled.div`
    h2 {
        font-size: 28px;
        margin: 0 0 20px 20px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 300px;
            top: 0;
            left: 0;
            bottom: 0;
            ${SkeletonLoadingEffect};
        }
    }
`;

export const MenuGroupContent = styled.div`
    margin: 25px 0px 0;
    position: relative;
    div {
        width: 71px;
        height: 71px;
        margin-left: 20px;
        float: left;
        border-radius: 20px;
        background: ${(props) => props.theme.graySkeletonAnim};
    }
    h3 {
        float: left;
        margin: 4px 0 0 15px;
        font-size: 22px;
        line-height: 32px;
        position: relative;
        em,
        i {
            display: block;
            &:after {
                content: '';
                position: absolute;
                width: 150px;
                left: 0;
                ${SkeletonLoadingEffect};
            }
        }
        em {
            &:after {
                top: 0;
                height: 25px;
            }
        }
        i {
            &:after {
                bottom: 0;
                height: 25px;
            }
        }
    }
    button {
        width: 42px;
        height: 42px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        float: right;
        margin: 15px 35px 0 0;
        background: ${(props) => props.theme.graySkeletonAnim};
    }
`;

export const PropertyLoading = styled.div`
    width: 561px;
    height: 100px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 40px;
    position: relative;
    ${SkeletonLoadingEffect};
`;

export const PropertyPageHeight = styled.div`
    min-height: 1200px;
    padding: 0 40px;
    position: relative;
`;

export const AlignLeft = styled.div`
    width: ${(props) => props.width || 500}px;
    float: left;
    text-align: left;
`;

export const AlignRight = styled.div`
    width: 520px;
    float: right;
    text-align: right;
`;

export const PropertyNameBox = styled.div`
    display: inline-block;
    width: 500px;
    height: 50px;
    box-sizing: border-box;
    ${SkeletonLoadingEffect};
`;

export const PropertyAddrBox = styled.div`
    display: inline-block;
    width: 300px;
    height: 25px;
    box-sizing: border-box;
    margin-top: ${(props) => (props.sub ? 3 : 10)}px;
    ${SkeletonLoadingEffect};
`;

export const BookmarkLoading = styled.div`
    position: absolute;
    top: -60px;
    left: 40px;
    width: 25px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 3px;
    ${SkeletonLoadingEffect};
`;

export const PropertyTab = styled.div`
    display: flex;
    align-items: stretch;
    height: 54px;
    margin-top: 54px;
    border-top: solid 1px ${(props) => props.theme.grayLight};
    border-bottom: solid 1px ${(props) => props.theme.grayLight};
`;

export const TabItem = styled.div`
    flex-grow: 1;
    height: 18px;
    margin: 18px 50px 0;
    ${SkeletonLoadingEffect};
`;

export const ProfileImageBox = styled.div`
    width: 170px;
    height: 170px;
    margin-right: 40px;
    border-radius: 20px;
    float: left;
    ${SkeletonLoadingEffect};
`;

export const FloatLeft = styled.div`
    float: left;
`;

export const SocialBox = styled.div`
    display: inline-block;
    width: 34px;
    height: 34px;
    margin: 10px 0 0 33px;
    border-radius: 5px;
    ${SkeletonLoadingEffect};
`;

export const NotificationTitle = styled.div`
    width: 220px;
    height: 40px;
    float: right;
    margin: 20px 30px 35px 0;
    ${SkeletonLoadingEffect};
`;

export const NotificationHead = styled.div`
    width: 150px;
    height: 35px;
    margin: 0 0 20px 20px;
    ${SkeletonLoadingEffect};
`;

export const NotificationRow = styled.div`
    width: 300px;
    height: 25px;
    margin: 0 0 10px 20px;
    ${SkeletonLoadingEffect};
`;

export const GeneralRow = styled.div`
    height: 40px;
    margin-bottom: 20px;
    ${SkeletonLoadingEffect};
`;

export const GalleryItem = styled.div`
    width: 559px;
    height: 140px;
    border: solid 1px ${(props) => props.theme.grayBorderLight};
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    margin: 30px 30px 0 0;
    position: relative;
    float: left;
    &:nth-of-type(even) {
        margin-right: 0;
    }
    div {
        width: 100px;
        height: 100px;
        float: left;
        ${SkeletonLoadingEffect};
    }
    span {
        width: 200px;
        height: 40px;
        float: left;
        margin: 0 0 20px 20px;
        ${SkeletonLoadingEffect};
    }
`;

export const EditProfileImage = styled.div`
    float: left;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    margin-right: 20px;
    ${SkeletonLoadingEffect};
`;

export const EditProfileChangeTab = styled.div`
    width: 170px;
    height: 20px;
    border-radius: 10px;
    margin-top: 35px;
    margin-left: 120px;
    ${SkeletonLoadingEffect};
`;

export const EditProfileInput = styled.div`
    position: relative;
    width: 360px;
    height: 30px;
    top: 110px;
    margin-bottom: 40px;
    border-radius: 10px;
    ${SkeletonLoadingEffect};
`;
