import React, {Component} from "react";
import {Wrapper, CurvedButtonStyles} from "./Styled";
import WizardItemMemberBox from "../WizardItemMemberBox";
import CurvedButton from "../FormButtonText";
import {CloseModalStyles, ModalTitleStyles} from "../FormPrivilegedListing/Styled";
import {ModalWrapper} from "../Modal/Styled";
import Modal from "../Modal";
import SearchMembers from "../SearchMembers";
import {ShareButtonSubmit} from "../SharedListingGroup/Styled";
import ButtonText from "../FormButtonText";

class FormAddMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            profiles: []
        }
    }

    addMemberClickHandler = () => {
        this.setState({
            isModalOpen: true
        })
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    memberSelectHandler = (members) => {
        const states = this.props.states,
              fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                      fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                      errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[fieldName] = members.map(
                    member => {
                        return {
                            value: member.id,
                            text: member.name
                        }
                    }
                );
                errorFields[fieldName] = null;
                this.setState({
                    profiles: members
                })
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {
        return (
            <>
                <Wrapper>
                    {
                        this.state.profiles.map(
                            profile =>
                                <WizardItemMemberBox
                                    key={this.props.id + "_" + profile.id}
                                    img={profile.img}
                                    name={profile.name}
                                    address={profile.location}
                                    userBadge
                                    vip={profile.vip}
                                />
                        )
                    }
                    <CurvedButton
                        styled={CurvedButtonStyles}
                        onClick={this.addMemberClickHandler}
                    >Add</CurvedButton>
                </Wrapper>

                {
                    // Prevent Fetch Members at StartUp
                    // If you want to fetch them all, remove the condition
                    (this.state.isModalOpen) &&

                    <Modal isOpen={this.state.isModalOpen}
                           handleClose={this.closeModal}
                           containerWidth="450"
                           containerHeight="auto"
                           containerSizeUnit="px"
                           containerPadding="20"
                           title="Select Group"
                           titleStyled={ModalTitleStyles}
                           closeStyled={CloseModalStyles}
                    >
                        <ModalWrapper>

                            <SearchMembers
                                id={this.props.id}
                                memberSelect={
                                    (members) => this.memberSelectHandler(members)
                                }
                            />

                            <ButtonText
                                styled={ShareButtonSubmit}
                                onClick={this.closeModal}
                            >
                                Done
                            </ButtonText>

                        </ModalWrapper>
                    </Modal>
                }

            </>
        );
    }
}

export default FormAddMember;