import React, { Component, Fragment } from 'react';
import Config from '../../../config';
import PropTypes from 'prop-types';
import {
    Break,
    Grid,
    InputLabelAfterAnimateDynamicStyles,
    InputLabelAnimateDynamicStyles,
    InputTextDynamicStyles,
    InputTextWrapperDynamicStyles,
    NoComponent,
    PageTitle,
    InputNote,
} from './Styled';
import { ArrowRight } from '../BaseTemplate/Styled';
import InputText from '../FormInputText';
import InputDropDown from '../FormInputDropDown';
import InputAddressSearch from '../FormInputAddressSearch';
import InputList from '../FormInputList';
import InputAddressAdd from '../FormInputAddressAdd';
import FormUploadFileCrop from '../FormUploadFileCrop';
import FormButtonRadioCheckGroup from '../FormButtonRadioCheckGroup';
import FormCheckBoxGroup from '../FormCheckBoxGroup';
import FormUploadMultiSelect from '../FormUploadMultiSelect';
import FormUploadGallery from '../FormUploadGallery';
import FormSwitchBoxDynamic from '../FormSwitchBoxDynamic';
import FormPrivilegedListing from '../FormPrivilegedListing';
import FormSpecialTags from '../FormSpecialTags';
import FormRadioText from '../FormRadioText';
import FormGroupContact from '../FormGroupContact';
import FormGroupName from '../FormGroupName';
import FormGroupType from '../FormGroupType';
import FormAddMember from '../FormAddMember';
import FormGroupPermissions from '../FormGroupPermissions';
import FormMapSimple from '../FormMapSimple';
import FormProfessionRadio from '../FormProfessionRadio';

/**
 * @param {{
 *     field_title, field_required, field_option, value_name,
 *     default_value, field_sub_title, field_size, field_break,
 *     map_size
 * }} data
 */

class FormDynamicField extends Component {
    render() {
        const field = { ...this.props.field },
            that = this.props.thisRef;
        const fieldName =
                field.field_name + Config.SPECIAL_SEPARATOR + field.id,
            fieldTitle = field.field_title || '',
            fieldSubTitle = field.field_sub_title || '',
            fieldRequired = field.field_required,
            fieldSize = field.field_size,
            fieldBreak = field.field_break,
            styles = field.field_option && field.field_option.styles,
            fieldList =
                field.values.length !== 0
                    ? field.values.find(
                          (item) => item.value_name === field.field_name
                      ).default_value
                    : [];

        let component;
        
        switch (field.field_type) {
            case 'text':
                component = (
                    <InputText
                        id={fieldName}
                        type='text'
                        name={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        styled={InputTextDynamicStyles}
                        wrapperStyled={InputTextWrapperDynamicStyles}
                        labelAnimate={fieldTitle}
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        required={true}
                        requiredStar={fieldRequired}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'select':
                component = (
                    <InputDropDown
                        id={fieldName}
                        type='text'
                        name={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        styled={InputTextDynamicStyles}
                        wrapperStyled={InputTextWrapperDynamicStyles}
                        labelAnimate={fieldTitle}
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        required={true}
                        requiredStar={fieldRequired}
                        oneArrow
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'text_geocoding':
                component = (
                    <InputAddressSearch
                        id={fieldName}
                        type='text'
                        name={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        styled={InputTextDynamicStyles}
                        wrapperStyled={InputTextWrapperDynamicStyles}
                        labelAnimate={fieldTitle}
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        required={true}
                        requiredStar={fieldRequired}
                        list={fieldList}
                        value={field.values}
                    />
                );
                break;
            case 'geocoding':
                component = (
                    <InputAddressAdd
                        id={fieldName}
                        type='text'
                        name={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        styled={InputTextDynamicStyles}
                        labelAnimate={fieldTitle}
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        required={true}
                        requiredStar={fieldRequired}
                        value={field.value}
                        country_lock={
                            field.field_option.country_lock
                                ? field.field_option.country_lock
                                : null
                        }
                        country_lock_value={
                            field.field_option.country_lock_value
                                ? field.field_option.country_lock_value
                                : null
                        }
                    />
                );
                break;
            case 'list':
                component = (
                    <InputList
                        id={fieldName}
                        type='text'
                        name={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        styled={InputTextDynamicStyles}
                        labelAnimate='Search'
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        required={true}
                        requiredStar={fieldRequired}
                        title={fieldTitle}
                        subTitle={fieldSubTitle}
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'checkbox_button':
                component = (
                    <FormButtonRadioCheckGroup
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        list={fieldList}
                        value={this.props.initValue}
                        outline={
                            field.field_option.outline
                                ? field.field_option.outline
                                : false
                        }
                    />
                );
                break;
            case 'checkbox_group':
                component = (
                    <FormCheckBoxGroup
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'file':
                component = (
                    <FormUploadMultiSelect
                        id={fieldName}
                        name={fieldName}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                    />
                );
                break;
            case 'file_category':
                component = (
                    <FormUploadMultiSelect
                        id={fieldName}
                        name={fieldName}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        fileCategory={true}
                        title={fieldTitle}
                    />
                );
                break;
            case 'file_category_link':
                component = (
                    <FormUploadMultiSelect
                        id={fieldName}
                        name={fieldName}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        fileCategory={true}
                        fileLinkTitle={true}
                        title={fieldTitle}
                    />
                );
                break;
            case 'file_crop':
                component = (
                    <FormUploadFileCrop
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        title={fieldTitle}
                        align={
                            field.field_option.align
                                ? field.field_option.align
                                : 'center'
                        }
                    />
                );
                break;
            case 'file_gallery':
                component = (
                    <FormUploadGallery
                        id={fieldName}
                        name={fieldName}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                    />
                );
                break;
            case 'radio':
                component = (
                    <FormSwitchBoxDynamic
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'profession_radio':
                component = (
                    <FormProfessionRadio
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        list={fieldList}
                        value={this.props.initValue}
                        title={fieldTitle}
                        subTitle={fieldSubTitle}
                    />
                );
                break;
            case 'radio_text':
                component = (
                    <FormRadioText
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'privileged_listing':
                component = (
                    <FormPrivilegedListing
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        title={fieldTitle}
                        list={fieldList}
                        value={this.props.initValue}
                        padding={
                            field.field_option.padding
                                ? field.field_option.padding
                                : null
                        }
                    />
                );
                break;
            case 'group_privacy':
                component = (
                    <FormGroupPermissions
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        title={fieldTitle}
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'special_tags':
                component = (
                    <FormSpecialTags
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'group_name':
                component = (
                    <FormGroupName
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        value={this.props.initValue}
                        title={fieldTitle}
                    />
                );
                break;
            case 'group_contact':
                component = (
                    <FormGroupContact
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'group_type':
                component = (
                    <FormGroupType
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        list={fieldList}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'add_member':
                component = (
                    <FormAddMember
                        id={fieldName}
                        errorMessage={that.state.errorFields[fieldName]}
                        states={[that]}
                        extraLocalStatesOnChange={{ isPageChanged: true }}
                        value={this.props.initValue}
                    />
                );
                break;
            case 'map_simple':
                component = (
                    <FormMapSimple
                        id={fieldName}
                        fieldState={that.state.fields}
                        container={field.field_option.map_size}
                        points={this.props.initValue || []}
                    />
                );
                break;
            case 'section_header':
                component = (
                    <PageTitle>
                        {!field['no_arrow'] && <ArrowRight />}
                        {fieldTitle}
                    </PageTitle>
                );
                break;
            case 'note_box':
                component = (
                    <InputNote>
                        <p>{fieldTitle}</p>
                    </InputNote>
                );
                break;
            default:
                if (process.env.NODE_ENV === 'development') {
                    return (
                        <NoComponent>
                            Component Not Available:
                            {field.field_type && (
                                <small>{field.field_type}</small>
                            )}
                            <pre>{JSON.stringify(field, null, 4)}</pre>
                        </NoComponent>
                    );
                } else {
                    return null;
                }
        }
        return (
            <Fragment>
                <Grid size={fieldSize} styled={styles}>
                    {component}
                </Grid>
                {fieldBreak && <Break />}
            </Fragment>
        );
    }
}

export default FormDynamicField;

FormDynamicField.propTypes = {
    field: PropTypes.object.isRequired,
    thisRef: PropTypes.instanceOf(Component).isRequired,
};
