import styled from 'styled-components';

export const UploadWrapper = styled.div`
    width: 170px;
    display: inline-block;
    text-align: center;
`;

export const UploadTitle = styled.h3`
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: normal;
    color: ${(props) => props.theme.textBlack};
    width: 180px;
    text-align: center;
`;

export const UploadPicture = styled.div`
    display: inline-block;
    width: 170px;
    height: 170px;
    box-sizing: border-box;
    background: ${(props) => props.theme.grayBgInput};
    font-size: 18px;
    border-radius: 15px;
    text-align: center;
    padding-top: 45px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img {
        width: 170px;
        height: 170px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
`;
