import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={17.226}
      viewBox="0 0 12 17.226"
      {...props}
    >
      <path
        data-name="Subtraction 46"
        d="M6 17.226c-.06-.078-6-7.756-6-11.088A6.077 6.077 0 016 0a6.077 6.077 0 016 6.138c0 3.332-5.938 11.01-6 11.087zM6 3.069A2.922 2.922 0 003 5.9a2.922 2.922 0 003 2.826A2.921 2.921 0 009 5.9a2.921 2.921 0 00-3-2.831z"
        fill={props.color ? '#797a80' : '#07aed0'}
      />
    </svg>
  )
}

export default SvgComponent
