import React, { Component } from 'react';
import {Column} from '../../../styles/Styled';
import {
    FirstColumnWrap,
    TextWrapper,
    ManagmentFormWrap,
    InputWrap,
    InputBorder,
    InputStyle,
    SecondColumnWrap,

} from './Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import LeadManagmentSearchTabBar from '../LeadManagmentSearchTabBar';
import IconNew from '../IconNew';

export class LeadManagmentNameTag extends Component {
    constructor(props){
        super(props);
        this.state = {
            SearchInput: ''
        }
    }
    render() {
        return (
            <React.Fragment>
                <Column type="one_column_setting" padding_right first>
                <ManagmentFormWrap>
                        <FirstColumnWrap>
                            <TextWrapper>
                                Lead Managment
                            </TextWrapper>
                        </FirstColumnWrap>
                        <SecondColumnWrap>
                            <InputWrap>
                                <InputBorder>
                                    <InputStyle  value={this.state.SearchInput} onChange={e => {this.setState({SearchInput : e.target.value})}} type='text' placeholder='Search Leads...'  />
                                    <IconNew bp1="-295" bp2="-130" iwidth="29" iheight="29"/>
                                </InputBorder>
                            </InputWrap>
                        </SecondColumnWrap>
                    </ManagmentFormWrap>
                    <LeadManagmentSearchTabBar defaultIndex={1} />
                </Column>

                <ProfileMenu />

                <ClearFloats />
            </React.Fragment>
        )
    }
}

export default LeadManagmentNameTag
