import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 0 40px;
    margin: 0 auto;
    ${(props) =>
        !props.noLine &&
        `
            padding: 0 auto;
            border-top: solid 1px ${props.theme.grayLineLight};
            border-bottom: solid 1px ${props.theme.grayLineLight};
            `};

    ${(props) =>
        props.small &&
        `box-sizing: border-box; height: 75px; margin: 0px auto 0`};
    @media ${(props) => props.theme.mediaHD} {
        padding: 27px 0 29px;
    }
`;

export const StatsItem = styled.div`
    text-align: center;
    float: right;
    position: relative;
    padding: ${(props) => (props.small ? '0 25px' : '0 29px')};
    ${(props) => props.small && `line-height: 20px`};
    strong {
        display: inline-block;
        font-size: ${(props) => (props.small ? '18px' : '24px')};
        color: ${(props) => props.theme.textBlack};
    }
    span {
        font-size: ${(props) => (props.small ? '14px' : '16px')};
        font-weight: lighter;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 1px;
        background: ${(props) => props.theme.grayLight};
    }
    &:first-of-type {
        padding-right: 10px;
    }
    &:last-of-type {
        padding-left: 0;
        &:after {
            background: none;
        }
    }

    @media ${(props) => props.theme.mediaHD} {
        padding: 0 19px;
        strong {
            font-size: 17px;
        }
        span {
            font-size: 12px;
        }
    }
`;
