import React, {Component} from 'react';
import {RadioButton} from "./Styled";

class FormRadioButton extends Component {
    render() {
        return (
            <RadioButton styled={this.props.styled}>
                <span>{this.props.label}</span>
                <input
                    type="radio"
                    id={this.props.id}
                    name={this.props.name}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                />
                <em/>
            </RadioButton>
        );
    }
}

export default FormRadioButton;