import styled from 'styled-components';


export const ManagmentFormWrap = styled.div`
background:white;
display:flex;
flex-direction:row;
align-items: center;
height:75px;
border-radius:10px;
margin-top:20px;
margin-bottom:20px !important;
`;

export const FirstColumnWrap = styled.div`
flex:1;
justify-content:start;
`;

export const SecondColumnWrap = styled.div`
flex:1;
justify-content:end;
align-items:center;
`;

export const InputStyle = styled.input`
float:right;
vertical-align:middle;
border:none;

padding-top:1.5%;
width:332px;

&::placeholder {
    font-size:20px;
    color:${props => props.theme.mainFontColor};
}

`;

export const InputWrap = styled.div`
padding-right:4%;

font-weight:bold;
padding-top:1.5%;

`;

export const InputBorder = styled.div`
border-bottom: 2px solid #eaeaea;
padding-bottom:2.07%;
display:flex;
float:right;
input[type="text"] {
    font-size:20px;
    color:${props => props.theme.mainFontColor};
}
`;

export const TextWrapper = styled.span`
color:${props => props.theme.textBlack};
font-size:28px;
padding-left:5.5%;
`;