import styled from "styled-components";

export const Wrapper = styled.div`
    text-align: center;
    :after {
        content: "";
        display: inline-block;
        width: 256px; height: 32px;
        opacity: ${props => (props.isLoading) ? 1 : 0};
        background: url('/assets/img/realtyna-logo-loading.png') no-repeat center;
        animation: loading 1s infinite;
    }
    @keyframes loading {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
`;