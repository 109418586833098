import React, { Component, Fragment } from 'react';
import {
    GroupTab,
    GroupTabItem,
    HomePageSearchTab,
    HomePageSearchTabItem,
    ListingsTab,
    ListingsTabItem,
    ProfileMenuTab,
    ProfileMenuTabItem,
    PropertyDetailsTab,
    PropertyDetailsTabItem,
    PropertyFilterTab,
    PropertyFilterTabItem,
    SearchTab,
    LeadStyle,
    SearchTabItem,
    ButtonWrapper,
    MyNetworksTabItem,
    MyNetworksTabBar,
    SortButtonStyle,
    SideBar,
    SideBarLeftArrowWrapper,
    SideBarLeftArrow,
    SideBarOptions,
    Options,
    HeaderWrapper,
    HeaderText,
    MyListingsSearchTabItem,
    MyListingsSearchTab,
    TitleText,
    VaultTextTitle,
    VaultItemWrapper,
    VaultItem,
} from './Styled';
import { ArrowLeft, ClearFloats } from '../BaseTemplate/Styled';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import searchStore from '../../../stores/searchStore';
import IconNew from '../IconNew/IconNew';

class TabBarPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePageIndex: props.defaultPageIndex,
            tabTitle: props.dynamicTitle,
            activeGroupTab: props.activeTabIndex,
            tab: 0,
        };
    }

    galleryTabHandler = (tabIndex, tabTitle) => {
        this.setState({
            activePageIndex: tabIndex,
            tabTitle,
            activeGroupTab: tabIndex,
        });
    };

    tabClickHandler = (tabIndex) => {
        this.setState({
            activePageIndex: tabIndex,
        });
    };

    searchTabClickHandler = (tabIndex, tabTitle) => {
        this.setState({
            activePageIndex: tabIndex,
        });

        if (this.props.Type === 'SearchTabBar') {
            if (searchStore.getSearchValue() !== '') {
                this.props.history.push(
                    `/search/${tabTitle.toLowerCase()}/${searchStore.getSearchValue()}`
                );
            } else {
                this.props.history.push(`/search/${tabTitle.toLowerCase()}`);
            }
        } else if (this.props.Type === 'LeadManagmentType') {
            if (searchStore.getSearchValue() !== '') {
                this.props.history.push(
                    `/lead-management/${tabTitle.toLowerCase()}/${searchStore.getSearchValue()}`
                );
            } else {
                this.props.history.push(
                    `/lead-management/${tabTitle.toLowerCase()}`
                );
            }
        } else if (this.props.Type === 'MyNetworkSearchTabBar') {
            var letters = tabTitle.replace(/[^A-Za-z]+/g, '');
            if (searchStore.getSearchValue() !== '') {
                this.props.history.push(
                    `/my-networks/${letters.toLowerCase()}/${searchStore.getSearchValue()}`
                );
            } else {
                this.props.history.push(
                    `/my-networks/${letters.toLowerCase()}`
                );
            }
        } else if (this.props.Type === 'myListingsType') {
            if (searchStore.getSearchValue() !== '') {
                this.props.history.push(
                    `/my-listings/${tabTitle.toLowerCase()}/${searchStore.getSearchValue()}`
                );
            } else {
                let modifiedTabTitle = tabTitle.split(' ')[0];
                this.props.history.push(
                    `/my-listings/${modifiedTabTitle.toLowerCase()}`
                );
            }
        }
    };

    render() {
        if (!this.props.children) {
            return <Fragment />;
        }
        let children = [];
        if (!Array.isArray(this.props.children)) {
            children.push(this.props.children);
        } else {
            children = [...this.props.children];
        }
        return (
            <Fragment>
                {this.props.theme === TabStyle.GroupTab && (
                    <GroupTab>
                        {children.map((page, index) => (
                            <GroupTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                            >
                                <div>{page.props.title}</div>
                                <em data-line-up="" />
                                <span data-curve-container="">
                                    <span data-curved-up="" />
                                    <span data-curved-down="" />
                                    <span data-line-between="" />
                                    <span data-line-down="" />
                                </span>
                                <span data-curve-container="">
                                    <span data-curved-up="" />
                                    <span data-curved-down="" />
                                    <span data-line-between="" />
                                    <span data-line-down="" />
                                </span>
                            </GroupTabItem>
                        ))}
                        <ClearFloats />
                    </GroupTab>
                )}
                {this.props.theme === TabStyle.ProfileMenuTab && (
                    <ProfileMenuTab>
                        {children.map((page, index) => (
                            <ProfileMenuTabItem
                                key={'menuTab-' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                            >
                                {page.props.titleIcon}
                                <em />
                                <em />
                                <div>{page.props.title}</div>
                            </ProfileMenuTabItem>
                        ))}
                        <ClearFloats />
                    </ProfileMenuTab>
                )}
                {this.props.theme === TabStyle.ListingsTab && (
                    <ListingsTab>
                        {children.map((page, index) => (
                            <ListingsTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                                isTitle={page.props.title}
                            >
                                <div>
                                    {page.props.title}
                                    {page.props.titleIcon}
                                </div>
                            </ListingsTabItem>
                        ))}
                        <ClearFloats />
                    </ListingsTab>
                )}
                {this.props.theme === TabStyle.HomePageSearchTab && (
                    <HomePageSearchTab>
                        {children.map((page, index) => (
                            <HomePageSearchTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() =>
                                    this.searchTabClickHandler(
                                        index,
                                        page.props.title
                                    )
                                }
                                isTitle={page.props.title}
                            >
                                <LeadStyle>
                                    {page.props.title}
                                    {page.props.titleIcon}
                                </LeadStyle>
                            </HomePageSearchTabItem>
                        ))}
                        {this.props.Type === 'LeadManagmentType' ? (
                            <ButtonWrapper width="720px" />
                        ) : (
                            <ButtonWrapper>
                                <button>Save</button>
                            </ButtonWrapper>
                        )}
                        <ClearFloats />
                    </HomePageSearchTab>
                )}
                {this.props.theme === TabStyle.PropertyDetailsTab && (
                    <PropertyDetailsTab>
                        {children.map((page, index) => (
                            <PropertyDetailsTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                            >
                                <div>{page.props.title}</div>
                            </PropertyDetailsTabItem>
                        ))}
                        <ClearFloats />
                    </PropertyDetailsTab>
                )}
                {this.props.theme === TabStyle.SearchTab && (
                    <SearchTab>
                        {children.map((page, index) => (
                            <SearchTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                                isTitle={page.props.title}
                            >
                                <div>
                                    <span>
                                        {this.state.activePageIndex === index
                                            ? page.props.titleIconActive
                                            : page.props.titleIcon}
                                    </span>
                                    {page.props.title}
                                </div>
                            </SearchTabItem>
                        ))}
                        <ClearFloats />
                    </SearchTab>
                )}
                {this.props.theme === TabStyle.SearchPropertyFilter && (
                    <PropertyFilterTab>
                        {children.map((page, index) => (
                            <PropertyFilterTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                                isTitle={page.props.title}
                            >
                                <div>{page.props.title}</div>
                            </PropertyFilterTabItem>
                        ))}
                        <ClearFloats />
                    </PropertyFilterTab>
                )}
                {this.props.theme === TabStyle.MyNetworksSearchTab && (
                    <MyNetworksTabBar>
                        {children.map((page, index) => (
                            <MyNetworksTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() =>
                                    this.searchTabClickHandler(
                                        index,
                                        page.props.title
                                    )
                                }
                                isTitle={page.props.title}
                            >
                                {page.props.title}
                            </MyNetworksTabItem>
                        ))}
                        <SortButtonStyle>
                            {' '}
                            <button>
                                <span
                                    style={{
                                        marginRight: '5px',
                                        marginTop: '1px',
                                    }}
                                >
                                    Sort
                                </span>
                                {'   '}
                                <IconNew
                                    bp1="-508"
                                    bp2="-138"
                                    iwidth="24"
                                    iheight="16"
                                />
                            </button>
                        </SortButtonStyle>
                        <ClearFloats />
                    </MyNetworksTabBar>
                )}
                {this.props.theme === TabStyle.GroupGalleryTab && (
                    <React.Fragment>
                        <HeaderWrapper>
                            <HeaderText>{this.state.tabTitle}</HeaderText>
                            <button
                                onClick={this.props.handleButtonClick}
                                style={{ marginRight: '20px' }}
                            >
                                back
                            </button>
                        </HeaderWrapper>
                        <SideBar>
                            {children.map((page, index) => (
                                <SideBarOptions
                                    key={'tabItem' + index}
                                    active={this.state.activeTabIndex === index}
                                    onClick={() =>
                                        this.galleryTabHandler(
                                            index,
                                            page.props.title
                                        )
                                    }
                                    isTitle={page.props.title}
                                >
                                    <Options>
                                        {this.state.activeGroupTab === index ? (
                                            <ArrowLeft />
                                        ) : null}
                                        {page.props.title}
                                    </Options>
                                </SideBarOptions>
                            ))}
                            <SideBarLeftArrowWrapper>
                                <SideBarLeftArrow />
                            </SideBarLeftArrowWrapper>
                            <ClearFloats />
                        </SideBar>
                    </React.Fragment>
                )}
                {this.props.theme === TabStyle.MyListingsTab && (
                    <MyListingsSearchTab>
                        {children.map((page, index) => (
                            <MyListingsSearchTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() =>
                                    this.searchTabClickHandler(
                                        index,
                                        page.props.title
                                    )
                                }
                                isTitle={page.props.title}
                            >
                                <div>
                                    <TitleText
                                        firstItem={index === 0}
                                        secondItem={index === 1}
                                        thirdItem={index === 2}
                                        lastItem={index === 3}
                                    >
                                        {page.props.title}
                                    </TitleText>
                                    {page.props.titleIcon}
                                </div>
                            </MyListingsSearchTabItem>
                        ))}
                        <ClearFloats />
                    </MyListingsSearchTab>
                )}
                {this.props.theme === TabStyle.ProfileMenuVaultTabBar && (
                    <div>
                        {children.map((page, index) => (
                            <MyListingsSearchTabItem
                                key={'tabItem' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() =>
                                    this.searchTabClickHandler(
                                        index,
                                        page.props.title
                                    )
                                }
                                isTitle={page.props.title}
                            >
                                <div>
                                    <TitleText>{page.props.title}</TitleText>
                                    {page.props.titleIcon}
                                </div>
                            </MyListingsSearchTabItem>
                        ))}
                        <ClearFloats />
                    </div>
                )}
                {this.props.theme === TabStyle.VaultTabBar && (
                    <VaultItemWrapper>
                        {children.map((page, index) => (
                            <VaultItem
                                key={'menuTab-' + index}
                                active={this.state.activePageIndex === index}
                                onClick={() => this.tabClickHandler(index)}
                            >
                                {this.state.activePageIndex === index
                                    ? page.props.activeIcon
                                    : page.props.deactiveIcon}
                                <em />
                                <em />
                                <VaultTextTitle>
                                    {page.props.title}
                                </VaultTextTitle>
                            </VaultItem>
                        ))}
                        <ClearFloats />
                    </VaultItemWrapper>
                )}
                {children[this.state.activePageIndex]}
            </Fragment>
        );
    }
}

export default withRouter(TabBarPages);

TabBarPages.propTypes = {
    theme: PropTypes.string.isRequired,
    defaultPageIndex: PropTypes.number.isRequired,
};

export const TabStyle = {
    GroupTab: 'groupTab',
    ProfileMenuTab: 'profileMenuTab',
    ListingsTab: 'listingsTab',
    PropertyDetailsTab: 'propertyDetailsTab',
    SearchTab: 'searchTab',
    SearchPropertyFilter: 'searchPropertyFilter',
    HomePageSearchTab: 'homePageSearchTab',
    MyNetworksSearchTab: 'myNetworksSearchTab',
    GroupGalleryTab: 'groupGalleryTab',
    MyListingsTab: 'myListingsTab',
    ProfileMenuVaultTabBar: 'profileMenuVaultTabBar',
    VaultTabBar: 'VaultTabBar',
};
