import styled, { css } from 'styled-components';
import { ArrowRight } from '../BaseTemplate/Styled';

export const Title = styled.h1`
    font-size: 38px;
    margin: 0 0 30px;
    color: ${(props) => props.theme.darkBlue};
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: -35px;
        left: 0;
        width: 540px;
        border-bottom: solid 1px ${(props) => props.theme.grayLineLight};
    }
`;

export const Section = styled.div`
    padding: 30px 0;
    border-bottom: solid 1px ${(props) => props.theme.grayLineLight};
    h3 {
        font-size: 28px;
        color: ${(props) => props.theme.textBlack};
    }
    position: relative;
`;

export const TimeSelectorStyles = css`
    float: right;
`;

export const ListingContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

export const ListingWrapper = styled.div`
    width: 40%;
    margin-top: 20px;

    img {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        background: gray;
        float: left;
        margin-right: 20px;
    }

    h4 {
        margin: 0;
        font-size: 22px;
        color: ${(props) => props.theme.darkBlue};
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > span {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-size: 16px;
                color: ${(props) => props.theme.mainFontColor};
                margin: 0;
                text-align: center;
            }
        }
    }
`;

export const DataWrapper = styled.div`
    width: 40%;
    float: left;
`;

export const LeadDatas = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const LeadCircle = styled.span`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: ${(props) => props.theme[props.color]};
`;

export const LeadTitle = styled.h6`
font-family: 'Montserrat';
    font-size: 24px;
    margin: 0 10px;
    width: 140px;
    color: ${(props) =>
        props.color ? props.theme[props.color] : props.theme.textBlack};
`;

export const LeadNumber = styled.p`
    font-size: 22px;
    margin: 0;
    color: ${(props) =>
        props.color ? props.theme[props.color] : props.theme.textGrayLight};
`;

export const MainCircle = styled.div`
    background: ${(props) => `
		radial-gradient(
			circle closest-side,
			transparent 66%,
			white 0
		),
		conic-gradient(
			${props.theme.violet} 0,
			${props.theme.violet} ${props.newLeads}%,
			${props.theme.cheeseYellow} 0,
			${props.theme.cheeseYellow} ${props.newLeads + props.inProgress}%,
			${props.theme.textGrayLight} 0,
			${props.theme.textGrayLight} 100%
	);`};
    position: relative;
    width: 200px;
    min-height: 200px;
    margin: -50px 0 0 0;
    float: right;
    padding: 0;

    > div {
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background: ${(props) => props.theme.white};
        position: absolute;
        left: 25%;
        top: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: bold;
        color: ${(props) => props.theme.violet};
    }
`;

export const MeetingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-size: 18px;
        color: ${(props) => props.theme.textBlack};
        margin-left: 10px;
    }
`;

export const PremiumContainer = styled.div`
    padding: 30px 0;
    float: right;
    button {
        background: transparent;
        background-image: url('/assets/img/premium.png');
        border: none;
        width: 540px;
        height: 140px;
        border-radius: 10px;
        color: ${(props) => props.theme.white};
        padding: 25px;
        font-family: ${(props) => props.theme.mainFont};
        cursor: pointer;

        h1 {
            font-size: 36px;
            margin: 0;
            text-align: left;
            width: 350px;
            float: left;
        }
        h3 {
            font-size: 24px;
            margin: 0;
            text-align: left;
            font-weight: lighter;
            width: 350px;
            float: left;
        }

        span {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: ${(props) => props.theme.orchid};
            float: right;
            margin: -30px 0 0 0;

            > h2 {
                font-size: 32px;
                margin: 0;
            }

            > h4 {
                font-size: 16px;
                margin: 0;
            }
        }
    }
`;

export const PackageContainer = styled.div`
    float: left;
    padding: 30px 0;
    button {
        border: none;
        width: 541px;
        height: 140px;
        border-radius: 15px;
        background: ${(props) => props.theme.grayLineLight};
        position: relative;
        overflow: hidden;
        cursor: pointer;
        font-family: 'Montserrat';

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }

        p {
            font-size: 18px;
            font-weight: lighter;
            color: ${(props) => props.theme.textBlack};
            position: absolute;
            top: 0px;
            left: 25px;
        }

        h3 {
            font-size: 24px;
            color: ${(props) => props.theme.white};
            padding: 5px 15px;
            border-radius: 5px;
            background: ${(props) => props.theme.cyan};
            position: absolute;
            top: 30px;
            left: 25px;
        }

        h6 {
            font-size: 18px;
            color: ${(props) => props.theme.mainFontColor};
            position: absolute;
            bottom: -35px;
            right: 30px;
            font-weight: normal;
        }
    }
`;

export const Tick = styled.u`
    display: inline-block;
    transform: rotate(45deg);
    width: 8px;
    height: 16px;
    border-bottom: 3px solid ${(props) => props.theme.white};
    border-right: 3px solid ${(props) => props.theme.white};
    border-radius: 3px;
    margin-right: 10px;
`;

export const GrayArrowRight = styled(ArrowRight)`
    margin: 0 0 -13px;
    height: 25px;
`;
