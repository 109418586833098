import React, { Component, Fragment } from 'react';
import {
    Wrapper,
    OwnerImage,
    Separator,
    ImageTitle,
    Menu,
    Tag,
    TagsWrapper,
    DescriptionWrapper,
    ArrowBack,
    ItemsCountAndType,
    PropertyImageMore,
    PropertyImageLink,
    Time,
} from './Styled';
import { withRouter } from 'react-router-dom';
import { ArrowRight, ClearFloats } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';
import ListingSharedCounts, {
    SharedCountsType,
} from '../../ListingSharedCounts';
import LisitingAddAnnouncement from '../ListingAddAnnouncement';
// import ListingComments, { CommentType } from '../ListingComments'; //Used for showing header comments
import { Link } from 'react-router-dom';
import Config from '../../../config';
import { timeAgo, sendAsyncRequest } from '../../../Utils';

class ListingOnFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreContent: false,
            linesToShow: 2,
            liked: props.liked,
            totalLikes: props.totalLikes,
            saved: props.saved,
        };
    }

    moreContentHandler = (pk) => {
        if (this.state.linesToShow < 3) {
            this.setState((prevState) => {
                return { linesToShow: prevState.linesToShow + 2 };
            });
        } else {
            this.props.history.push(Config.ROUTES.listing.root + '/test/' + pk);
        }
    };

    menuButtonHandler = (listing) => { 
        console.log(listing); 
        sendAsyncRequest(`/property/hide/${this.props.id}/`, 'delete')
        .then(res => 
            { console.log(res);}) 
        }

    onLike = () => {
        if (!this.state.liked) {
            this.setState({ liked: true });
            sendAsyncRequest('/property/like', 'post', {
                listing_id: this.props.id,
            })
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ liked: false });
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            totalLikes: prevState.totalLikes + 1,
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ liked: false });
                });
        } else {
            this.setState({ liked: false });
            sendAsyncRequest(`/property/unlike/${this.props.id}`, 'delete', {})
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ liked: true });
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            totalLikes: prevState.totalLikes - 1,
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ liked: true });
                });
        }
    };

    onSave = () => {
        if (!this.state.saved) {
            this.setState({ saved: true });
            sendAsyncRequest('/property/save/', 'post', {
                listing_id: this.props.id,
            })
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ saved: false });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ saved: false });
                });
        } else {
            this.setState({ saved: false });
            sendAsyncRequest(`/property/unsave/${this.props.id}/`, 'delete', {})
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ saved: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ saved: true });
                });
        }
    };

    render() {
        const listing = { ...this.props.listing };
        return (
            <Fragment>
                {this.props.type === PropertyType.SHARE_IDEA && (
                    <LisitingAddAnnouncement />
                )}
                {(this.props.type === PropertyType.NORMAL ||
                    this.props.type === PropertyType.SPONSOR ||
                    this.props.type === PropertyType.ANNOUNCEMENT) && (
                    <Wrapper>
                        {!this.props.noHeaderComment && (
                            <Fragment>
                                <Link
                                    to={
                                        Config.ROUTES.profile.person +
                                        '/' +
                                        listing.owner.url
                                    }
                                    title={listing.owner.full_name}
                                >
                                    <OwnerImage
                                        src={listing.owner.profile}
                                        size={60}
                                    />
                                </Link>
                                <ImageTitle>
                                    <Link
                                        to={
                                            Config.ROUTES.profile.person +
                                            '/' +
                                            listing.owner.url
                                        }
                                        title={listing.owner.full_name}
                                    >
                                        {listing.owner.full_name}
                                    </Link>
                                    {listing.street !== 'hidden' && (
                                        <span>
                                            {listing.country}, {listing.street},{' '}
                                            {listing.street}
                                        </span>
                                    )}
                                </ImageTitle>
                                <Menu onClick={() => this.menuButtonHandler(listing)} />
                                {this.props.type !==
                                    PropertyType.ANNOUNCEMENT && (
                                    <Time>{timeAgo(listing.add_date)}</Time>
                                )}

                                <ClearFloats />
                                <Separator height={25} />
                            </Fragment>
                        )}

                        {this.props.type === PropertyType.ANNOUNCEMENT && [
                            <Fragment key={'frg' + listing.pk}>
                                <ItemsCountAndType>
                                    <ArrowRight />
                                    {listing.title}
                                    <Time>{timeAgo(listing.add_date)}</Time>
                                    <br />
                                    <strong>Announcement</strong>
                                    <br />
                                    <DescriptionWrapper
                                        lines={this.state.linesToShow}
                                    >
                                        <p>{listing.description}</p>
                                        {listing.description.length > 132 && (
                                            <ArrowBack
                                                onClick={() =>
                                                    this.moreContentHandler(
                                                        listing.pk
                                                    )
                                                }
                                            />
                                        )}
                                    </DescriptionWrapper>
                                </ItemsCountAndType>

                                <Separator height={30} />

                                <ListingSharedCounts
                                    listingId={listing.pk}
                                    bookmarked={listing.saved}
                                    listing={listing}
                                    type={SharedCountsType.ANNOUNCEMENT}
                                />
                            </Fragment>,

                            <ClearFloats key={'clr' + listing.pk} />,
                        ]}

                        {this.props.type === PropertyType.NORMAL &&
                            listing.images.map((image, index) => {
                                const imagesLength = listing.images.length;
                                switch (index) {
                                    case 0:
                                        return (
                                            <PropertyImageLink
                                                to={
                                                    Config.ROUTES.listing.root +
                                                    '/test/' +
                                                    listing.pk
                                                }
                                                title="Go To Property"
                                                key={'img-' + index}
                                                size="lg"
                                            >
                                                <img
                                                    src={image.image}
                                                    alt="Property"
                                                />
                                                {listing.tags && (
                                                    <TagsWrapper>
                                                        {listing.tags.map(
                                                            (tag, index) => {
                                                                switch (tag) {
                                                                    case 1:
                                                                        return (
                                                                            <Tag
                                                                                key={
                                                                                    index
                                                                                }
                                                                                to="#"
                                                                            >
                                                                                <IconNew
                                                                                    bp1="-257"
                                                                                    bp2="-77"
                                                                                    iwidth="26"
                                                                                    iheight="26"
                                                                                />
                                                                            </Tag>
                                                                        );
                                                                    case 2:
                                                                        return (
                                                                            <Tag
                                                                                key={
                                                                                    index
                                                                                }
                                                                                to="#"
                                                                            >
                                                                                <IconNew
                                                                                    bp1="-229"
                                                                                    bp2="-77"
                                                                                    iwidth="26"
                                                                                    iheight="26"
                                                                                />
                                                                            </Tag>
                                                                        );
                                                                    case 3:
                                                                        return (
                                                                            <Tag
                                                                                key={
                                                                                    index
                                                                                }
                                                                                to="#"
                                                                            >
                                                                                <IconNew
                                                                                    bp1="-201"
                                                                                    bp2="-77"
                                                                                    iwidth="26"
                                                                                    iheight="26"
                                                                                />
                                                                            </Tag>
                                                                        );
                                                                    default:
                                                                        return '';
                                                                }
                                                            }
                                                        )}
                                                    </TagsWrapper>
                                                )}
                                                <h3>
                                                    ${listing.price}
                                                    /m
                                                </h3>
                                            </PropertyImageLink>
                                        );
                                    case 1:
                                        return (
                                            <PropertyImageLink
                                                to={
                                                    Config.ROUTES.listing.root +
                                                    '/test/' +
                                                    listing.pk
                                                }
                                                title="Go To Property"
                                                key={'img-' + index}
                                                size="sm"
                                                className="top-right"
                                            >
                                                <img
                                                    src={image.image}
                                                    alt="Property"
                                                />
                                            </PropertyImageLink>
                                        );
                                    case 2: {
                                        if (imagesLength > 3) {
                                            return (
                                                <PropertyImageMore
                                                    key={'img-more-' + index}
                                                >
                                                    <PropertyImageLink
                                                        to={
                                                            Config.ROUTES
                                                                .listing.root +
                                                            '/test/' +
                                                            listing.pk
                                                        }
                                                        title="Go To Property"
                                                        key={'img-' + index}
                                                        size="sm"
                                                    />
                                                    <img
                                                        src={image.image}
                                                        alt="Property"
                                                    />
                                                    <strong>
                                                        <IconNew
                                                            bp1="-103"
                                                            bp2="-115"
                                                            iwidth="26"
                                                            iheight="23"
                                                        />
                                                        {imagesLength - 3}
                                                    </strong>
                                                </PropertyImageMore>
                                            );
                                        } else {
                                            return (
                                                <PropertyImageLink
                                                    to={
                                                        Config.ROUTES.listing
                                                            .root +
                                                        '/test/' +
                                                        listing.pk
                                                    }
                                                    title="Go To Property"
                                                    key={'img-' + index}
                                                    size="sm"
                                                >
                                                    <img
                                                        src={image.image}
                                                        alt="Property"
                                                    />
                                                </PropertyImageLink>
                                            );
                                        }
                                    }
                                    default:
                                        return '';
                                }
                            })}

                        {this.props.type === PropertyType.SPONSOR &&
                            listing.images.map((image, index) => {
                                // Only Two Images
                                if (index === 0 || index === 1) {
                                    return (
                                        <PropertyImageLink
                                            to={
                                                Config.ROUTES.listing.root +
                                                '/test/' +
                                                listing.pk
                                            }
                                            title="Go To Property"
                                            key={'img-' + index}
                                            size="md"
                                        >
                                            <img
                                                src={image.image}
                                                alt="Property"
                                            />
                                            {listing.tags && (
                                                <TagsWrapper>
                                                    {listing.tags.map(
                                                        (tag, index) => {
                                                            switch (tag) {
                                                                case 1:
                                                                    return (
                                                                        <Tag
                                                                            key={
                                                                                index
                                                                            }
                                                                            to="#"
                                                                        >
                                                                            <IconNew
                                                                                bp1="-257"
                                                                                bp2="-77"
                                                                                iwidth="26"
                                                                                iheight="26"
                                                                            />
                                                                        </Tag>
                                                                    );
                                                                case 2:
                                                                    return (
                                                                        <Tag
                                                                            key={
                                                                                index
                                                                            }
                                                                            to="#"
                                                                        >
                                                                            <IconNew
                                                                                bp1="-229"
                                                                                bp2="-77"
                                                                                iwidth="26"
                                                                                iheight="26"
                                                                            />
                                                                        </Tag>
                                                                    );
                                                                case 3:
                                                                    return (
                                                                        <Tag
                                                                            key={
                                                                                index
                                                                            }
                                                                            to="#"
                                                                        >
                                                                            <IconNew
                                                                                bp1="-201"
                                                                                bp2="-77"
                                                                                iwidth="26"
                                                                                iheight="26"
                                                                            />
                                                                        </Tag>
                                                                    );
                                                                default:
                                                                    return '';
                                                            }
                                                        }
                                                    )}
                                                </TagsWrapper>
                                            )}
                                        </PropertyImageLink>
                                    );
                                } else {
                                    return '';
                                }
                            })}

                        {this.props.type === PropertyType.ANNOUNCEMENT && [
                            <PropertyImageLink
                                to={
                                    Config.ROUTES.listing.root +
                                    '/test/' +
                                    listing.pk
                                }
                                title="Go To Property"
                                key={'img-announcement'}
                                size="xlg"
                            >
                                <img
                                    src={listing.images[0].image} // Only 1 Image
                                    alt="Property"
                                />
                                {listing.tags && (
                                    <TagsWrapper>
                                        {listing.tags.map((tag, index) => {
                                            switch (tag) {
                                                case 1:
                                                    return (
                                                        <Tag key={index} to="#">
                                                            <IconNew
                                                                bp1="-257"
                                                                bp2="-77"
                                                                iwidth="26"
                                                                iheight="26"
                                                            />
                                                        </Tag>
                                                    );
                                                case 2:
                                                    return (
                                                        <Tag key={index} to="#">
                                                            <IconNew
                                                                bp1="-229"
                                                                bp2="-77"
                                                                iwidth="26"
                                                                iheight="26"
                                                            />
                                                        </Tag>
                                                    );
                                                case 3:
                                                    return (
                                                        <Tag key={index} to="#">
                                                            <IconNew
                                                                bp1="-201"
                                                                bp2="-77"
                                                                iwidth="26"
                                                                iheight="26"
                                                            />
                                                        </Tag>
                                                    );
                                                default:
                                                    return '';
                                            }
                                        })}
                                    </TagsWrapper>
                                )}
                            </PropertyImageLink>,
                            <ClearFloats key={'clr' + listing.pk} />,
                        ]}

                        {(this.props.type === PropertyType.NORMAL ||
                            this.props.type === PropertyType.SPONSOR) && (
                            <Fragment>
                                <ClearFloats />
                                <Separator height={20} />

                                <ItemsCountAndType>
                                    <div className="info">
                                        {listing.bedrooms +
                                            'Bed | ' +
                                            listing.bathrooms +
                                            'Bath | ' +
                                            listing.area +
                                            ' sqft'}
                                    </div>
                                    <p>
                                        <IconNew
                                            bp1="-62"
                                            bp2="-77"
                                            iwidth="12"
                                            iheight="17"
                                        />{' '}
                                        {listing.city}
                                    </p>
                                    <h3>
                                        <ArrowRight />
                                        {listing.title}
                                    </h3>
                                </ItemsCountAndType>
                                <DescriptionWrapper
                                    lines={this.state.linesToShow}
                                >
                                    <p>{listing.description}</p>
                                    {listing.description.length > 132 && (
                                        <ArrowBack
                                            onClick={() =>
                                                this.moreContentHandler(
                                                    listing.pk
                                                )
                                            }
                                        />
                                    )}
                                </DescriptionWrapper>

                                <ListingSharedCounts
                                    listingId={listing.pk}
                                    saved={this.state.saved}
                                    liked={this.state.liked}
                                    totalLikes={this.state.totalLikes}
                                    onLike={this.onLike}
                                    onSave={this.onSave}
                                    listing={listing}
                                    type={SharedCountsType.NORMAL}
                                />
                                
                            </Fragment>
                        )}
                    </Wrapper>
                )}
            </Fragment>

            /*<MainWrapper showWrapper={this.state.showWrapper}>
                { this.props.sharedBy &&
                    <SharedBy>
                        <ShowImageName 
                            iSize="60" 
                            iImageSrc={this.props.sharedBy.profile_pic} 
                            iName={this.props.sharedBy.username} 
                            iLocation={this.props.sharedBy.name} 
                            iColor="black" 
                            iLink={"/" + this.props.sharedBy.username}
                            nameFlag="shared a post"
                        />
                    </SharedBy>
                }
                <Wrapper hilighWrapper={this.props.sharedBy ? true : false}>
                    <ListingTop 
                        userImage={this.props.userImage} 
                        userName={this.props.userName} 
                        description={this.props.description} 
                        address={this.props.sharedBy ? null : this.props.address}
                        addDate={this.props.addDate}
                        listingID={this.props.listingID}
                        isOwner={this.props.isOwner}
                        showSmallImage={this.props.sharedBy ? true : false}
                        listingLink={this.props.slugPath}
                        handleHideListing={this.hideListing}
                        isListingDetails={false}
                    />

                    <ListingGallery 
                        listingImages={this.props.listingImages}
                        bedrooms={this.props.bedrooms}
                    />

                    <ListingButtons 
                        toggleAllCommentsModal={this.toggleAllCommentsModal} 
                        listingID={this.props.listingID} 
                        hasLiked={this.props.hasLiked} 
                        hasSaved={this.props.hasSaved} 
                        handleLikeToggle={this.handleLikeToggle}
                        canComment={this.props.canComment}
                        canShare={this.props.canShare}
                        listingLink={this.props.slugPath}
                        listingSlug={this.props.slug}
                    />
                    
                    <ListingLikesNumberWrapper>
                        {this.state.totalLikes > 0 &&
                            <LikesNumberWithItsModal 
                                buttonFontSize="16" 
                                buttonIsBold={true} 
                                buttonColor="000000" 
                                likesNumber={this.state.totalLikes}
                                listingID={this.props.listingID}
                                objectType='listing'
                            />
                        }
                        
                    </ListingLikesNumberWrapper>
                    
                    <Description>
                        {this.props.description}
                    </Description>
                    
                    {this.state.comments.length > 0 &&
                        <ListingsCommentsOnFeed 
                            toggleAllCommentsModal={this.toggleAllCommentsModal} 
                            commentsNumber={this.props.totalComments}
                            commentsData={this.state.comments}
                        />
                    }

                    <ListingAllCommentsModal
                        isOpen={this.state.isAllCommentsModalOpen}
                        handleClose={this.toggleAllCommentsModal}
                        listingID={this.props.listingID}
                    />
                </Wrapper>
            </MainWrapper>*/
        );
    }
}

export default withRouter(ListingOnFeed);

export const PropertyType = {
    NORMAL: 'normal',
    SHARE_IDEA: 'share_idea',
    SPONSOR: 'sponsor',
    ANNOUNCEMENT: 'announcement',
};
