import React, { useEffect, useState } from 'react';
import Config from '../../../config';
import {
    useLoadScript,
    GoogleMap,
    MarkerClusterer,
    Marker,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { CardsWrapper } from './Styled';

const GoogleMapView = (props) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: Config.GOOGLE_MAP_API_KEY,
    });

    const [gMap, setGMap] = useState(null);
    const [icons, setIcons] = useState([
        '/assets/img/map_marker_numbered_pin.png',
        '/assets/img/map_marker_single_dot.png',
    ]);

    useEffect(
        () => {
            switch (props.color) {
                case 'blue':
                    setIcons([
                        '/assets/img/map_marker_numbered_pin.png',
                        '/assets/img/map_marker_single_dot.png',
                    ]);
                    break;
                case 'red':
                    setIcons([
                        '/assets/img/map_marker_numbered_pin_red.png',
                        '/assets/img/map_marker_single_dot_red.png',
                    ]);
                    break;
                default:
                    setIcons([
                        '/assets/img/map_marker_numbered_pin_green.png',
                        '/assets/img/map_marker_single_dot_green.png',
                    ]);
                    break;
            }
        },
        [props]
    );

    useEffect(
        () => {
            if (gMap) {
                if (props.points.length > 1) {
                    const bounds = new window.google.maps.LatLngBounds();
                    props.points.forEach((point) => {
                        bounds.extend(point.position);
                    });
                    gMap.panToBounds(bounds);
                } else {
                    const lat = props.points[0].position.lat,
                        lng = props.points[0].position.lng;
                    gMap.panTo({ lat, lng });
                    gMap.setZoom(12);
                }
            }
        },
        [props.points]
    );

    const renderMap = () => {
        return (
            <GoogleMap
                mapContainerStyle={props.container}
                zoom={13}
                onLoad={(map) => {
                    setGMap(map);
                    const bounds = new window.google.maps.LatLngBounds();
                    props.points.forEach((point) => {
                        bounds.extend(point.position);
                    });
                    map.fitBounds(bounds);
                }}
            >
                <MarkerClusterer
                    // averageCenter
                    styles={[
                        {
                            url: icons[0],
                            textColor: '#ffffff',
                            anchorText: [0, 0],
                            fontFamily: '"Montserrat", sans-serif;',
                            textSize: '13',
                            fontWeight: 'bold',
                            width: 48,
                            height: 48,
                            zIndex: 100,
                        },
                    ]}
                >
                    {(clusterer) =>
                        props.points.map((point) => {
                            return (
                                <Marker
                                    key={point.id}
                                    clusterer={clusterer}
                                    position={{
                                        lat: point.position.lat,
                                        lng: point.position.lng,
                                    }}
                                    icon={{
                                        url: icons[1],
                                        size: new window.google.maps.Size(
                                            42,
                                            42
                                        ),
                                    }}
                                />
                            );
                        })
                    }
                </MarkerClusterer>
                {props.cards && (
                    <CardsWrapper>
                        {props.cards.map((card, index) => card)}
                    </CardsWrapper>
                )}
            </GoogleMap>
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <div>Loading Map...</div>;
};
export default GoogleMapView;

GoogleMapView.propTypes = {
    container: PropTypes.any.isRequired,
    points: PropTypes.any.isRequired,
};
