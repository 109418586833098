import React, {Component, Fragment} from "react";
import {Connection, SeeMore, Wrapper} from "./Styled";
import {ArrowRight, ClearFloats} from "../BaseTemplate/Styled";
import {Link} from "react-router-dom";
import Config from "../../../config";

class SocialConnections extends Component {
    render() {
        return (
            <Wrapper>
                <h2>{this.props.title}</h2>
                {
                    this.props.connections.map(
                        (connection, index) =>
                            <Fragment key={'frg-'+index}>
                                <Connection>
                                    <Link to={Config.ROUTES.profile.person + "/" + connection.url}
                                          title={connection.fullname}>
                                        <img src={connection.img} alt={connection.fullname}/>
                                    </Link>
                                    <h3>
                                        <Link to={Config.ROUTES.profile.person + "/" + connection.url}
                                              title={connection.fullname}>
                                            {connection.fullname}
                                        </Link>
                                        <em>
                                            {connection.profession}
                                        </em>
                                    </h3>
                                </Connection>
                                <ClearFloats />
                            </Fragment>
                    )
                }
                {
                    (this.props.seeMore) &&
                    <SeeMore>
                        More
                        <ArrowRight/>
                    </SeeMore>
                }
            </Wrapper>
        )
    }
}

export default SocialConnections;