import styled from 'styled-components';

export const Button = styled.button`
    font-family: 'Catamaran', sans-serif;
`;

export const Wrapper = styled.div`
    position: relative;
    background: rgba(112,112,112,0.06);
    margin: 0 0 20px;
`;

export const Column = styled.div`
    width: 50%;
    box-sizing: border-box;
    position: relative;
    padding: 19px;
`;

export const LeftColumn = styled(Column)`
    padding-top: 195px;
    float: left;
`;

export const RightColumn = styled(Column)`
    float: right;
`;

export const ButtonFollow = styled(Button)`
    background: rgb(19,73,151);
    width: 139px; height: 52px;
    border: 0 none;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    border-radius: 3px;
    float: right;
    &:hover {
        background: rgba(12,62,135);
        cursor: pointer;
    }
`;

export const ButtonJoin = styled(ButtonFollow)`
    background: transparent;
    color: rgb(19,73,151);
    border: solid 3px rgb(19,73,151);
    margin-left: 10px;
    &:hover {
        background: #fff;
    }
`;
