import React, {Component} from "react";
import {Circle} from "./Styles";

class CircleProgress extends Component {
    render() {
        // const percent = Number.isNaN(this.props.percent)
        //     ? 0 : parseInt(this.props.percent);
        // const deg = (percent * 180) / 100;
        return (
            <Circle small={this.props.small}>
                {/* <em>
                    <i className="mask full"/>
                    <i className="mask half"/>
                    <b>
                        {this.props.number}
                    </b>
                </em> */}
                <b>
                    {this.props.number}
                </b>
            </Circle>
        );
    }
}

export default CircleProgress;