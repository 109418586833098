import React, { Component } from 'react';
import {
    Wrapper,
    InputLabelAnimateGroupStyles,
    DragAndDropUpload,
    DropDownWrapper,
    InputDropDownUploadedStyles,
    InputDropDownWrapperUploadedStyles,
    InputLabelAfterAnimateUploadedStyles,
    InputTextUploadedStyles,
    InputTextWrapperUploadedStyles,
    SwitchBoxDocStyles,
    UploadedGallery,
} from './Styled';
import InputText from '../FormInputText';
import InputDropDown from '../FormInputDropDown';
import SwitchBox from '../FormSwitchBox';
import { ClearFloats } from '../BaseTemplate/Styled';
import { sendAsyncRequest } from '../../../Utils';
import { InputFile } from '../FormUploadMultiSelect/Styled';
import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';

class FormUploadGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            galleries: [],
            drag: false,
        };
        this.loadingCount = 0;
        this.dropRef = React.createRef();
        this.dragCounter = 0;
    }

    componentDidMount() {
        let label = this.dropRef.current;
        label.addEventListener('dragenter', this.handleDragIn);
        label.addEventListener('dragleave', this.handleDragOut);
        label.addEventListener('dragover', this.handleDrag);
        label.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        let label = this.dropRef.current;
        label.removeEventListener('dragenter', this.handleDragIn);
        label.removeEventListener('dragleave', this.handleDragOut);
        label.removeEventListener('dragover', this.handleDrag);
        label.removeEventListener('drop', this.handleDrop);
    }

    switchBoxChangeHandler = (e, galleryId) => {
        const galleries = [...this.state.galleries];
        const gallery = galleries.find((gallery) => {
            if (gallery.imgId === galleryId) {
                gallery.privileged = e.target.checked;
                return true;
            }
            return false;
        });
        this.updateState(gallery);
        this.setState({
            galleries,
        });
    };

    dropDownChangeHandler = (val, galleryId) => {
        const galleries = [...this.state.galleries];
        const gallery = galleries.find((gallery) => {
            if (gallery.imgId === galleryId) {
                gallery.category = val; // {value, text}
                return true;
            }
            return false;
        });
        this.updateState(gallery);
        this.setState({
            galleries,
        });
    };

    imgTitleChangeHandler = (e, galleryId) => {
        const galleries = [...this.state.galleries];
        const gallery = galleries.find((gallery) => {
            if (gallery.imgId === galleryId) {
                gallery.imgTitle = e.target.value;
                return true;
            }
            return false;
        });
        this.updateState(gallery);
        this.setState({
            galleries,
        });
    };

    onAddFiles = (e, dropFiles = null) => {
        this.setState({
            loading: true,
        });
        const files = dropFiles ? dropFiles : e.target.files,
            galleries = [...this.state.galleries];
        this.loadingCount = files.length;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const data = new FormData();
            data.append('file', file);
            sendAsyncRequest(
                '/uploader/simple',
                'post',
                {},
                null,
                true,
                data
            ).then((result) => {
                const imgServerId = result.body.image_id;
                const gallery = {
                    imgId: imgServerId,
                    imgFileName: file.name,
                    imgTitle: file.name,
                    category: {
                        value: -1,
                        text: '',
                    },
                    privileged: false,
                    imgUrl: URL.createObjectURL(file),
                };
                galleries.push(gallery);
                this.updateState(gallery);
                let loading = true;
                if (--this.loadingCount === 0) {
                    loading = false;
                }
                this.setState({
                    galleries,
                    loading,
                });
            });
        }
    };

    onFileRemoveHandler = (galleryId) => {
        const galleries = [...this.state.galleries];
        galleries.splice(
            galleries.findIndex((x) => x.imgId === galleryId),
            1
        );
        this.setState({
            galleries,
        });
        this.updateState('', galleryId);
    };

    updateState = (galleryImg, removeGalleryId = null) => {
        const states = this.props.states;
        states.forEach((that) => {
            const isGlobalState = that.constructor.name === 'Object',
                fields = isGlobalState
                    ? that.getFields()
                    : { ...that.state.fields },
                errorFields = isGlobalState
                    ? that.getErrorFields()
                    : { ...that.state.errorFields },
                fieldName = this.props.id;
            if (!fields[fieldName]) {
                fields[fieldName] = [];
            }
            if (removeGalleryId) {
                // Remove
                const fieldIndex = fields[fieldName].findIndex(
                    (x) => x.imgId === removeGalleryId
                );
                if (~fieldIndex) {
                    fields[fieldName].splice(fieldIndex, 1);
                }
            } else {
                // Insert & Update
                const fieldIndex = fields[fieldName].findIndex(
                    (x) => x.imgId === galleryImg.imgId
                );
                if (~fieldIndex) {
                    fields[fieldName][fieldIndex] = {
                        imgId: galleryImg.imgId,
                        imgFileName: galleryImg.imgFileName,
                        imgTitle: galleryImg.imgTitle,
                        category: galleryImg.category,
                        privileged: galleryImg.privileged,
                    };
                } else {
                    fields[fieldName].push({
                        imgId: galleryImg.imgId,
                        imgFileName: galleryImg.imgFileName,
                        imgTitle: galleryImg.imgTitle,
                        category: galleryImg.category,
                        privileged: galleryImg.privileged,
                    });
                }
            }
            errorFields[fieldName] = null;
            if (isGlobalState) {
                that.setFields(fields);
                that.setErrorFields(errorFields);
            } else {
                if (!this.props.extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...this.props.extraLocalStatesOnChange,
                    });
                }
            }
        });
    };

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({
                drag: true,
            });
        }
    };

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({
                drag: false,
            });
        }
    };

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            drag: false,
        });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.onAddFiles(null, e.dataTransfer.files);
            try {
                e.dataTransfer.clearData();
            } catch (e) {}
            this.dragCounter = 0;
        }
    };

    render() {
        return (
            <Wrapper>
                <p>Drag your image and videos here:</p>
                <DragAndDropUpload
                    htmlFor={this.props.id + '_fileUp'}
                    data-loading={this.state.loading}
                    data-drag={this.state.drag}
                    ref={this.dropRef}
                >
                    {!this.state.loading ? (
                        !this.state.drag ? (
                            <>
                                Drag & Drop here to upload Photos
                                <br />
                                or <span>Select file</span>
                            </>
                        ) : (
                            <>
                                DROP HERE...
                                <br />
                                <span className='arrow'>➜</span>
                            </>
                        )
                    ) : (
                        <>
                            Uploading...
                            <br />
                            <small>Please wait...</small>
                        </>
                    )}
                </DragAndDropUpload>
                <InputFile
                    type='file'
                    id={this.props.id + '_fileUp'}
                    onChange={this.onAddFiles}
                    accept='image/*'
                    multiple
                />

                {this.state.galleries.map((gallery) => (
                    <UploadedGallery key={gallery.imgId}>
                        <img src={gallery.imgUrl} alt={gallery.title} />

                        <i
                            onClick={() =>
                                this.onFileRemoveHandler(gallery.imgId)
                            }
                        />

                        <InputText
                            id={'imgTitle_' + gallery.imgId}
                            name={'imgTitle_' + gallery.imgId}
                            type='text'
                            styled={InputTextUploadedStyles}
                            wrapperStyled={InputTextWrapperUploadedStyles}
                            labelAnimate='Image Title'
                            labelAnimateStyled={InputLabelAnimateGroupStyles}
                            labelAfterAnimateStyled={
                                InputLabelAfterAnimateUploadedStyles
                            }
                            required={true}
                            value={gallery.imgTitle}
                            onInput={(e) =>
                                this.imgTitleChangeHandler(e, gallery.imgId)
                            }
                        />

                        <DropDownWrapper>
                            <InputDropDown
                                id={'imgCategory_' + gallery.imgId}
                                name={'imgCategory_' + gallery.imgId}
                                type='text'
                                styled={InputDropDownUploadedStyles}
                                wrapperStyled={
                                    InputDropDownWrapperUploadedStyles
                                }
                                labelAnimate='Image Category'
                                labelAnimateStyled={
                                    InputLabelAnimateGroupStyles
                                }
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateUploadedStyles
                                }
                                required={true}
                                oneArrow
                                dropDownValue={(name, val) =>
                                    this.dropDownChangeHandler(
                                        val,
                                        gallery.imgId
                                    )
                                }
                                value={gallery.category}
                                list={[
                                    {
                                        text: 'Cat1',
                                        value: 1,
                                    },
                                    {
                                        text: 'Cat2',
                                        value: 2,
                                    },
                                    {
                                        text: 'Cat3',
                                        value: 3,
                                    },
                                ]}
                            />
                        </DropDownWrapper>

                        <strong>Privileged</strong>
                        <SwitchBox
                            styled={SwitchBoxDocStyles}
                            checked={gallery.privileged}
                            onChange={(e) =>
                                this.switchBoxChangeHandler(e, gallery.imgId)
                            }
                        />

                        <ClearFloats />
                    </UploadedGallery>
                ))}
                {this.state.loading && (
                    <SkeletonLoading type={SkeletonLoadingType.GALLERY_IMAGE} />
                )}

                <ClearFloats />
            </Wrapper>
        );
    }
}

export default FormUploadGallery;
