import React, { Component } from 'react';
import {
    Wrapper,
    ItemWrapper,
    TitleWrapper,
    OptionsWrapper,
   
} from './Styled';
import ProfileMenuContent from '../ProfileMenuContent';

class ProfileMenuSettingsMyActivitiesItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
        };
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu,
        });
    };

    handleBackButton = () => {
        this.setState({ selectedMenu: -1 });
    };

    render() {
        return (
            <ProfileMenuContent
                title={this.props.title}
                onTitleClick={this.props.backButton}
            >
                <Wrapper >
                    {this.props.activityData.map((item, index) => (
                            <ItemWrapper
                                key={index}
                                onClick={() =>
                                    this.setState({ selectedMenu: index })
                                }
                            >
                                <TitleWrapper>
                                    <p>{item.action} <span>{item.type}</span></p>
                                </TitleWrapper>
                                <OptionsWrapper>
                                    <p>{item.time}</p>
                                </OptionsWrapper>
                            </ItemWrapper>
                        ))}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettingsMyActivitiesItem;
