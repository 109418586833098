import styled, {css} from "styled-components";

export const TabPageStyles = css`
    padding: 40px 0;
    margin-bottom: 40px;
    width: 1260px;
    margin: 0 auto;
    background:white;
`;

export const PropertyColWrapper = styled.div`
    display: block;
    width: 400px;
    background:white;
    margin-right: 30px;
    &:last-of-type {
        margin-right: 0;
    }
    float: left;
`;

export const SliderWrapper = styled.div`
display:flex;
padding-left:66px;
padding-right:66px;
justify-content:space-between;
.eye {
    margin-right:11.2px;
}

.forward {
    margin-left:24px;
}
`;

export const SliderCardWrapper = styled.div`
width:525px;
`;

export const ImgWrapper = styled.img`
width:525px;
height:333px;
`;

export const CardPropertyText = styled.span`

`;

export const CardTopText = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`;

export const PropertyPrice = styled.span`
font-weight:bold;
font-size:31px;
margin-left:11.2px;
color: ${(props) => props.theme.textBlack};
`;


export const CardMiddleText = styled.div`
display:flex;
align-items:center;
margin-top:13px;
justify-content:space-between;
.bordered {
    border-right:1px solid ${props => props.bodyBg};
    border-left:1px solid ${props => props.bodyBg};
}
`;

export const PropertyDetail = styled.span`

font-size:12px;
margin-right:11.2px;

padding-left:8.8px;
padding-right:8.8px;
color: ${(props) => props.theme.mainFontColor};

`;

export const LeftSideWrapper = styled.div`
justify-self:flex-end;
`;

export const PropertyTitleText = styled.span`
font-size: 21px;
color: ${(props) => props.theme.mainFontColor};
margin-left:11.2px;
`;

export const CardLowerText = styled.div`
display:flex;
align-items:center;
margin-top:9px;
`;

export const LocationText = styled.span`
margin-left: 8.1px;
color: ${(props) => props.theme.mainFontColor};
font-size:17px;
`;