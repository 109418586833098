import styled, {css} from "styled-components";

export const TabPageStyles = css`
    padding: 40px 200px 0;
    margin-bottom: 40px;
    width: 1700px;
    margin: 0 auto;
    box-sizing: border-box;
`;

export const PropertyColWrapper = styled.div`
    display: block;
    width: 400px;
    margin-right: 30px;
    &:last-of-type {
        margin-right: 0;
    }
    float: left;
`;