import React, { Component, Fragment } from 'react';
import { Column } from '../../../styles/Styled';
import MyNetworkSearchTabBar from '../MyNetworksSearchTabBar'

class MyNetworksSearch extends Component {

    render() {
        return (
            <Fragment>
                <Column type="one_column_setting" padding_right first>
                    <MyNetworkSearchTabBar defaultIndex={this.props.defaultIndex} />
                </Column>
            </Fragment>
        )
    }
}

export default MyNetworksSearch;