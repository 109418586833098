import React, {Component} from "react";
import FormInputText from "../FormInputText";
import {InputSearchWrapper, SearchItem, SearchList} from "./Styled";
import Config from "../../../config";

class FormInputAddressSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            inputValue: '',
            listIndex: -1
        };
        this.countries = [];
    }

    componentDidMount() {
        // Set Default Value
        if (this.props.value.full_text) {
            this.setState({
                inputValue: this.props.value.full_text
            });
        }
        // Countries
        this.countries = this.props.list;
    }

    searchInputHandler = (e) => {
        let value = e.target.value;
        this.setState({
            inputValue: value
        });
        if (value && value.length > 3) {
            value = encodeURIComponent(value);
            let mapUrl = Config.GOOGLE_MAP_AUTOCOMPLETE_URL + '&input=' + value;
            if (this.props.country) {
                mapUrl += ("&components=country:"+this.props.country);
            }
            fetch(mapUrl)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    const list = [];
                    if (data && data.status === "OK") {
                        data.predictions.forEach(
                            place =>
                                list.push({
                                    id: place.id,
                                    placeId: place.place_id,
                                    text: place.structured_formatting.main_text,
                                    subText: place.structured_formatting.secondary_text,
                                    description: place.description
                                })
                        );
                    } else {
                        list.push({
                            disabled: true,
                            id: 0,
                            text: "No Result"
                        });
                    }
                    this.setState({
                        list
                    });
                });
        } else {
            this.setState({
                list: [{
                    current: true,
                    id: 1,
                    text: 'Current Location'
                }]
            });
        }
    };

    getCurrentPosition = () => {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    };

    searchClickHandler = async (item) => {
        let geo;
        if (item.current) {
            if (navigator.geolocation) {
                try {
                    const {coords} = await this.getCurrentPosition();
                    geo = {
                        lat: coords.latitude,
                        lng: coords.longitude
                    };
                    const data = await fetch(Config.GOOGLE_MAP_GEOCODE_URL + '&latlng=' + geo.lat + ',' + geo.lng)
                        .then(response => response.json());
                        console.log(data);
                    if (data.status === "OK" && data.results && data.results.length > 0) {
                        item.description = data.results[0].formatted_address;
                        if (data.results[0].address_components.length > 0) {
                            item = {
                                ...item,
                                ...addressToItem(
                                    data.results[0].address_components
                                )
                            };
                        }
                    } else {
                        item.description = "Unknown Location Name";
                    }
                } catch (error) {}
            } else {
                alert('Geolocation is not supported by this browser or it is not allowed.');
            }
        } else {
            const data = await fetch(Config.GOOGLE_MAP_PLACE_DETAIL_URL + '&place_id=' + item.placeId)
                .then(response => response.json());
            if (data.status === "OK") {
                geo = data.result.geometry.location;
                if (data.result.address_components.length > 0) {
                    item = {
                        ...item,
                        ...addressToItem(
                            data.result.address_components
                        )
                    };
                }
                // item.description was filled before this step
                // item.description = data.result.formatted_address
            }
        }
        if (geo) {
            this.props.states.forEach(
                that => {
                    const isGlobalState = (that.constructor.name === "Object"),
                          fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                          errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields},
                          name = this.props.id,
                          extraLocalStatesOnChange = {...this.props.extraLocalStatesOnChange}; // TODO :: this.props.options REMOVED/CHANGED
                    // Separate 1 Field to 6 Fields
                    // "full_address" => "country", "city", "street", "latitude", "longitude", "zipcode"
                    const country = this.countries.find(
                        c => c.iso_code === item.country
                    );
                    fields["country"] = (country && country.value)
                        ? {value: country.value, text: country.text}  : -1;
                    fields["city"] = item.city;
                    fields["street"] = item.street;
                    fields["latitude"] = geo.lat;
                    fields["longitude"] = geo.lng;
                    fields["zipcode"] = item.postal_code;
                    errorFields[name] = null;
                    if (isGlobalState) {
                        that.setFields(fields);
                        that.setErrorFields(errorFields);
                    } else {
                        if (!extraLocalStatesOnChange) {
                            that.setState({
                                fields,
                                errorFields
                            });
                        } else {
                            that.setState({
                                fields,
                                errorFields,
                                ...extraLocalStatesOnChange
                            });
                        }
                    }
                }
            );
            if (this.props.onAddressChosen) {
                this.props.onAddressChosen({
                    item, geo
                });
                this.setState({
                    list: [],
                    inputValue: ''
                });
            } else {
                this.setState({
                    list: [],
                    inputValue: (item.description) ? item.description : ''
                });
            }
        }
    };

    searchFocusHandler = (e) => {
        let value = e.target.value;
        if (!value) {
            this.setState({
                list: [{
                    current: true,
                    id: 1,
                    text: 'Current Location'
                }]
            })
        } else {
            this.searchInputHandler(e);
        }
    };

    searchKeyHandler = (key) => {
        const listIndex = this.state.listIndex;
        if (key === "ArrowDown" && (listIndex + 1 < this.state.list.length)) {
            this.setState((prevState) => {
                return {listIndex: prevState.listIndex + 1}
            });
        }
        if (key === "ArrowUp" && listIndex > 0) {
            this.setState((prevState) => {
                return {listIndex: prevState.listIndex - 1}
            });
        }
        if (key === "ArrowUp" && listIndex === 0) {
            this.setState({
                listIndex: -1
            });
        }
    };

    searchEnterHandler = () => {
        const listIndex = this.state.listIndex;
        if (listIndex !== -1) {
            const item = this.state.list[listIndex];
            this.searchClickHandler(item).then(() => {
                this.setState({
                    listIndex: -1
                });
            });
        }
    };

    searchBlurHandler = () => {
        this.setState({
            list: [],
            listIndex: -1
        });
    };

    itemMouseDownHandler = (e) => {
        // Let Click Fired "Before" Blur Handler
        e.preventDefault();
    };

    render() {
        return (
            <InputSearchWrapper styled={this.props.searchWrapperStyled}>
                <FormInputText
                    {...this.props}
                    value={this.state.inputValue}
                    onInput={this.searchInputHandler}
                    onFocus={this.searchFocusHandler}
                    onBlur={this.searchBlurHandler}
                    onKey={(key) => {
                        this.searchKeyHandler(key);
                    }}
                    onEnter={this.searchEnterHandler}
                    disableOnChange={true}
                >
                    {
                        (this.state.list.length > 0) &&
                        <SearchList>
                            {
                                this.state.list.map(
                                    (item, index) =>
                                    {
                                        return(
                                            <SearchItem
                                                key={item.id + '_' + index}
                                                onClick={
                                                    () => {
                                                        if (!item.disabled) {
                                                            this.searchClickHandler(item).then()
                                                        }
                                                    }
                                                }
                                                disabled={(item.disabled)}
                                                isFocused={this.state.listIndex === index}
                                                onMouseDown={this.itemMouseDownHandler}
                                            >
                                                <strong>
                                                    {item.text}
                                                </strong>
                                                <small>
                                                    {item.subText}
                                                </small>
                                            </SearchItem>
                                        )
                                    }

                                )
                            }
                        </SearchList>
                    }
                </FormInputText>
            </InputSearchWrapper>
        );
    }
}

export default FormInputAddressSearch;

export const addressToItem = (addressComponent) => {
    const item = {
        country: '',
        city: 'Unknown',
        street: [],
        postal_code: ''
    };
    addressComponent.forEach(
        address => {
            if (address.types.includes('country')) {
                item.country = address.short_name;
            }
            if (address.types.includes('postal_code')) {
                item.postal_code = address.long_name;
            }
            if (address.types.includes('locality')) {
                item.city = address.long_name;
            }
            if (address.types.includes('administrative_area_level_1')) {
                item.city = address.long_name;
            }
            if (address.types.includes('route')) {
                item.street.push(address.long_name);
            }
            if (address.types.includes('street_number')) {
                item.street.push(address.long_name);
            }
        }
    );
    item.street = (item.street.length > 0) ? item.street.toString() : 'Unknown';
    return item;
};