import React, {Component, Fragment} from "react";
import {UploadPicture, UploadTitle, UploadWrapper} from "./Styled";
import IconNew from "../IconNew";

class FormUploadFile extends Component {
    render() {
        return (
            <UploadWrapper>
                {
                    (this.props.title) &&
                    <UploadTitle>{this.props.title}</UploadTitle>
                }
                <UploadPicture onClick={this.props.onClick}>
                    {
                        (!this.props.img) &&
                        <Fragment>
                            <IconNew bp1="-508" bp2="0" iwidth="35" iheight="35" />
                            <br/>
                            {this.props.text}
                        </Fragment>
                    }
                    {
                        (this.props.img) &&
                        <img src={this.props.img} alt="" />
                    }
                </UploadPicture>
            </UploadWrapper>
        );
    }
}

export default FormUploadFile;