import styled, { css } from 'styled-components';

export const CircleNormalStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px; height: 64px;
    background: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    /* i, i:after, em, b {
        display: block;
    }
    em i {
        transition: transform 1s ease-in-out;
    }
    em i,
    em i:after {
        content: "";
        width: 70px; height: 70px;
        position: absolute;
        border-radius: 50%;
    }
    em i {
        clip: rect(0px, 70px, 70px, 35px);
    }
    em i:after {
        clip: rect(0px, 35px, 70px, 0px);
        background: ${(props) => props.theme.cyan};
    }
    em i:first-of-type,
    em i:after {
        transform: rotate(${(props) => props.deg}deg);
        
    } */
    b {
        display: block;
        background: transparent;
        text-align: center;
        margin: auto;
        z-index: 100;
        box-sizing: border-box;
        font-style: normal;
        font-size: 28px;
        line-height: 14px;
    }
`;

export const CircleSmallStyles = css`
    display: block;
    width: 28px;
    height: 28px;
    background: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    i,
    i:after,
    em,
    b {
        display: block;
    }
    em i {
        transition: transform 1s ease-in-out;
    }
    em i,
    em i:after {
        content: '';
        width: 28px;
        height: 28px;
        position: absolute;
        border-radius: 50%;
    }
    em i {
        clip: rect(0px, 28px, 28px, 14px);
    }
    em i:after {
        clip: rect(0px, 14px, 28px, 0px);
        background: ${(props) => props.theme.cyan};
    }
    em i:first-of-type,
    em i:after {
        transform: rotate(${(props) => props.deg}deg);
    }
    b {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        text-align: center;
        margin: 3px 0 0 3px;
        position: absolute;
        z-index: 100;
        box-sizing: border-box;
        font-style: normal;
    }
`;

export const Circle = styled.span`
    ${(props) => (props.small ? CircleSmallStyles : CircleNormalStyles)};
`;
