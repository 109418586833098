import React, {Component} from "react";
import {PermissionTable, SwitchBoxStyles, TableRow} from "./Styled";
import {PageTitle} from "../FormDynamicField/Styled";
import {ArrowRight} from "../BaseTemplate/Styled";
import SwitchBox from "../FormSwitchBox";
import {objIsEqual} from "../../../Utils";

class FormGroupPermissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            permissions: []
        }
    }

    componentDidMount() {
        // TODO :: Change this after Server Decide the Format
        this.setState({
            permissions: this.props.list
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && !objIsEqual(prevProps.value, this.props.value)) {
            this.setState({
                permissions: this.props.value
            });
        }
    }

    switchChangeHandler = (e, rowIndex, colIndex) => {
        const permissions = [...this.state.permissions];
        permissions[rowIndex].options[colIndex] = e.target.checked;
        this.setState({
            permissions
        })
        // TODO: "that.setState" Should be Implemented after Server Decide the Format
    }

    render() {

        if (this.state.permissions.length === 0) {
            return null;
        }

        return (
            <PermissionTable>
                <TableRow head>
                    <div>
                        <PageTitle>
                            <ArrowRight/> {this.props.title}
                        </PageTitle>
                    </div>
                    <div>Owner</div>
                    <div>Admin</div>
                    <div>Moderator</div>
                    <div>Member</div>
                </TableRow>
                {
                    this.state.permissions.map(
                        (row, index) =>
                            <TableRow key={"row-" + index}>
                                <div>{row.text}</div>
                                {
                                    row.options.map(
                                        (permission, innerIndex) =>
                                            <div key={"col-" + index + "-" + innerIndex}>
                                                {
                                                    (permission !== -1)
                                                        ?
                                                        <SwitchBox
                                                            styled={SwitchBoxStyles}
                                                            onChange={
                                                                (e) => this.switchChangeHandler(
                                                                    e, index, innerIndex
                                                                )
                                                            }
                                                            checked={permission}
                                                        />
                                                        : ''
                                                }
                                            </div>
                                    )
                                }
                            </TableRow>
                    )
                }
            </PermissionTable>
        );
    }
}

export default FormGroupPermissions;