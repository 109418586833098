import React, { Component } from 'react';
import {
    ButtonTextStyles,
    InnerHeader,
    Wrapper,
    InputTextWrapperListStyles,
    ClearInput,
} from './Styled';
import InputText from '../FormInputText';
import ButtonText from '../FormButtonText';
import { objIsEqual } from '../../../Utils';

class FormInputList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButtons: [],
            list: [],
            inputValue: '',
        };
        this.oneTimeInit = true;
    }

    componentDidMount() {
        // Set List Data and Set Active buttons if there is any value
        const list = [...this.props.list];
        list.forEach((item) => (item.show = true));
        // Non-Dynamic exclusive
        const activeButtons = [];
        if (!this.props.states) {
            const values = [...this.props.value];
            list.forEach((item) => {
                if (values.find((x) => x.value === item.value)) {
                    activeButtons.push(item.value);
                }
            });
        }
        this.setState({
            list,
            activeButtons,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //To handle list changes (either on one component or if we have two components in a page)(Non-Dynamic exclusive)
        if (!objIsEqual(prevProps.list, this.props.list)) {
            const list = [...this.props.list];
            list.forEach((item) => (item.show = true));
            this.setState({ list });
        }
        //To handle two components in a page which they have different values(Non-Dynamic exclusive)
        if (
            this.props.value &&
            !objIsEqual(prevProps.value, this.props.value) &&
            !this.oneTimeInit
        ) {
            const values = [...this.props.value],
                list = [...this.props.list],
                activeButtons = [];
            list.forEach((item) => {
                if (values.find((x) => x.value === item.value)) {
                    activeButtons.push(item.value);
                }
            });
            this.setState({
                activeButtons,
            });
        }
        // Default Value
        else if (
            this.props.value &&
            !objIsEqual(prevProps.value, this.props.value) &&
            this.oneTimeInit
        ) {
            const values = [...this.props.value],
                list = [...this.props.list],
                activeButtons = [...this.state.activeButtons];
            list.forEach((item) => {
                if (values.find((x) => x.value === item.value)) {
                    activeButtons.push(item.value);
                }
            });
            this.oneTimeInit = false;
            this.setState({
                activeButtons,
            });
        }
    }

    buttonClickHandler = (value) => {
        this.setState((prevState) => {
            const activeButtons = [...prevState.activeButtons],
                list = [...this.props.list];

            if (activeButtons.includes(value)) {
                activeButtons.splice(activeButtons.indexOf(value), 1);
            } else {
                activeButtons.push(value);
            }

            const selectedItems = list.filter((item) =>
                activeButtons.includes(item.value)
            );

            if (this.props.states) {
                const that = this.props.states[0];
                const fields = { ...that.state.fields },
                    errorFields = { ...that.state.errorFields },
                    extraLocalStatesOnChange = {
                        ...this.props.extraLocalStatesOnChange,
                    },
                    name = this.props.id;
                fields[name] = selectedItems;
                errorFields[name] = null;
                if (!extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...extraLocalStatesOnChange,
                    });
                }
            } else {
                const name = this.props.name;
                this.props.onChangeSelected(name, selectedItems);
            }

            return {
                activeButtons,
            };
        });
    };

    textInputChangeHandler = (e) => {
        const inputValue = e.target.value,
            list = [...this.state.list];
        list.forEach((item) => {
            item.show = item.text.toLowerCase().includes(
                inputValue
                    .trim()
                    .toString()
                    .toLowerCase()
            );
        });
        this.setState({
            inputValue,
            list,
        });
    };

    clearInputValue = () => {
        const inputValue = '',
            list = [...this.state.list];
        list.forEach((item) => {
            item.show = true;
        });
        this.setState({
            inputValue,
            list,
        });
    };

    render() {
        const list = [...this.state.list];
        return (
            <Wrapper>
                <InnerHeader>
                    {this.props.title}
                    <br />
                    <small>{this.props.subTitle}</small>
                </InnerHeader>

                <InputText
                    {...this.props}
                    wrapperStyled={InputTextWrapperListStyles}
                    onInput={this.textInputChangeHandler}
                    value={this.state.inputValue}
                >
                    {this.state.inputValue && (
                        <ClearInput onClick={this.clearInputValue} />
                    )}
                </InputText>

                {list.map(
                    (item) =>
                        item.show && (
                            <ButtonText
                                key={item.text + item.value}
                                styled={ButtonTextStyles}
                                active={this.state.activeButtons.includes(
                                    item.value
                                )}
                                onClick={() =>
                                    this.buttonClickHandler(item.value)
                                }
                            >
                                {item.text}
                            </ButtonText>
                        )
                )}
            </Wrapper>
        );
    }
}

export default FormInputList;
