import styled from 'styled-components';

export const Wrapper = styled.div`
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    i,
    em,
    b,
    u {
        position: absolute;
        background: ${(props) => props.theme.cyan};
        border-radius: 100%;
    }
    i:first-of-type {
        width: 456px;
        height: 456px;
        top: -209.6px;
        right: 212px;
        opacity: 50%;
        z-index: 2;

        @media ${(props) => props.theme.mediaHD} {
            width: 213px;
            height: 213px;
            top: -101px;
            right: 102px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            top: -301px;
            right: -32px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            top: -291px;
            right: 22px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 183px;
            height: 183px;
            top: -111px;
            right: 20px;
        }
    }
    i:last-of-type {
        width: 520px;
        height: 520px;
        top: -241.6px;
        right: 180px;
        opacity: 20%;
        z-index: 1;

        @media ${(props) => props.theme.mediaHD} {
            width: 242.5px;
            height: 242.5px;
            top: -116px;
            right: 87.5px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            top: -336px;
            right: -67.5px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            top: -326px;
            right: -10px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 208px;
            height: 208px;
            top: -121px;
            right: 8px;
        }
    }
    em:first-of-type {
        width: 704px;
        height: 704px;
        top: -267.2px;
        right: -301.6px;
        background: ${(props) => props.theme.darkBlue};
        opacity: 50%;
        z-index: 4;

        @media ${(props) => props.theme.mediaHD} {
            width: 304.5px;
            height: 304.5px;
            top: -115.5px;
            right: -130.5px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            top: -315.5px;
            right: -530.5px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            top: -315.5px;
            right: -530.5px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 300px;
            height: 300px;
            top: -151px;
            right: -200px;
        }
    }
    em:last-of-type {
        width: 788.8px;
        height: 788.8px;
        top: -309.6px;
        right: -344px;
        background: transparent;
        border: solid 2px ${(props) => props.theme.darkBlue};
        opacity: 50%;
        z-index: 3;

        @media ${(props) => props.theme.mediaHD} {
            width: 341.5px;
            height: 341.5px;
            top: -134px;
            right: -149px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            top: -365px;
            right: -590.5px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            top: -365px;
            right: -590.5px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 336px;
            height: 336px;
            top: -171px;
            right: -225px;
        }
    }
    b:first-of-type {
        width: 344px;
        height: 344px;
        bottom: -120px;
        left: -97.6px;
        background: ${(props) => props.theme.cyan};
        opacity: 50%;
        z-index: 6;

        @media ${(props) => props.theme.mediaHD} {
            width: 264px;
            height: 264px;
            bottom: -92px;
            left: -75px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            bottom: -142px;
            left: -155px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            bottom: -142px;
            left: -195px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 0;
            height: 0;
        }
    }
    b:last-of-type {
        width: 392px;
        height: 392px;
        bottom: -144px;
        left: -121.6px;
        background: ${(props) => props.theme.cyan};
        opacity: 20%;
        z-index: 5;

        @media ${(props) => props.theme.mediaHD} {
            width: 305px;
            height: 305px;
            bottom: -117px;
            left: -100px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            bottom: -162px;
            left: -175px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            bottom: -162px;
            left: -215px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 0;
            height: 0;
        }
    }
    u {
        width: 268px;
        height: 268px;
        bottom: -104px;
        left: 117.6px;
        background: transparent;
        border: solid 2px ${(props) => props.theme.darkBlue};
        opacity: 50%;
        z-index: 7;

        @media ${(props) => props.theme.mediaHD} {
            width: 251px;
            height: 251px;
            bottom: -110px;
            left: 97px;
        }
        @media ${(props) => props.theme.mediaTabletLandscape} {
            bottom: -150px;
            left: 77px;
        }
        @media ${(props) => props.theme.mediaTablet} {
            width: 228px;
            height: 228px;
            bottom: -130px;
            left: 7px;
        }
        @media ${(props) => props.theme.mediaMobile} {
            width: 0;
            height: 0;
        }
    }
    span {
        position: fixed;
        top: 267px;
        right: 431px;
        z-index: 5;
    }
`;
