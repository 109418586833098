import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      data-name="Component 68 \u2013 1105"
      xmlns="http://www.w3.org/2000/svg"
      width={9}
      height={13.226}
      viewBox="0 0 9 13.226"
      {...props}
    >
      <path
        data-name="Subtraction 17"
        d="M4.5 13.226C4.455 13.166 0 7.271 0 4.713A4.615 4.615 0 014.5 0 4.615 4.615 0 019 4.713c0 2.558-4.454 8.453-4.5 8.513zm0-10.87a2.215 2.215 0 00-2.25 2.172A2.215 2.215 0 004.5 6.7a2.214 2.214 0 002.25-2.172A2.214 2.214 0 004.5 2.356z"
        fill="#797a80"
      />
    </svg>
  )
}

export default SvgComponent
