import styled from "styled-components";

export const CheckBox = styled.label`
    display: inline-block;
    height: 17px;
    position: relative;
    padding-left: 25px;
    margin-right: 25px;
    cursor: pointer;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    ${
        props => (props.disabled) &&
            `
            cursor: default;
            input {
                cursor: default;
            }
            `
    };
    i {
        font-style: normal;
        span {
            display: inline-block;
            padding-bottom: 3px; // Fix "column-count" Bug in "FormCheckBoxGroup"
        }
    }
    em {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        box-sizing: border-box;
        border: solid 1px ${props => props.theme.mainFontColor};
        border-radius: 5px;
    }
    &:hover input ~ i em {
        // background-color: #eee;
    }
    input:checked ~ i em {
        background-color: ${props => props.theme.cyan};
        border: none;
    }
    input:checked ~ i span {
        color: ${props => props.theme.cyan};
    }
    em:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ i em:after {
        display: block;
    }
    em:after {
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        top: 47%; left: 50%;
        transform: translate(-50%,-50%) rotate(45deg);
    }
    ${
        props => props.styled
    };
`;