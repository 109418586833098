import React, {Component} from "react";
import {BookmarkButton} from "./Styled";
import IconNew from "../IconNew";
import {sendAsyncRequest} from "../../../Utils";
import Loading from "../Loading";
import PropTypes from "prop-types";

class ButtonBookmarkItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            bookmarked: props.bookmarked,
        }
    }

    bookmarkClickHandler = async () => {
        this.setState({
            loading: true
        });
        const req = (this.state.bookmarked)
            ? {
                url: `/property/unsave/${this.props.listingId}/`,
                method: 'delete',
                body: {}
            }
            : {
                url: '/property/save/',
                method: 'post',
                body: {
                    listing_id: this.props.listingId
                }
            }
        const result = await sendAsyncRequest(req.url, req.method, req.body);
        if (result.status < 299) {
            this.setState((prevState) => {
                return {
                    bookmarked: !(prevState.bookmarked),
                    loading: false
                };
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <BookmarkButton big={(this.props.big)} onClick={this.bookmarkClickHandler}
                            left={this.props.left} right={this.props.right} top={this.props.top}
                            inline={(this.props.inline)}
                            type={(this.props.type)}
            >
                {
                    (this.state.loading)
                        ? <Loading type="blue" iSize="1x" className="bookmark"/>
                        : (!this.state.bookmarked)
                        ?
                        (this.props.big)
                            ?
                            <IconNew bp1="-358" bp2="-186" iwidth="27" iheight="32"/>
                            :
                            <IconNew bp1="-284" bp2="-77" iwidth="20" iheight="25" className="bookmark"/>
                        :
                        (this.props.big)
                            ?
                            <IconNew bp1="-384" bp2="-186" iwidth="27" iheight="32"/>
                            :
                            <IconNew bp1="-304" bp2="-77" iwidth="20" iheight="25" className="bookmark"/>
                }
            </BookmarkButton>
        )
    }
}

export default ButtonBookmarkItem;

ButtonBookmarkItem.propTypes = {
    listingId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    bookmarked: PropTypes.bool.isRequired
};