import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={283.481} height={58} viewBox="0 0 283.481 58" {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.869}
          y1={0.074}
          x2={0.077}
          y2={0.944}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#07aed0" />
          <stop offset={1} stopColor="#094fd0" />
        </linearGradient>
      </defs>
      <g data-name="Group 1816">
        <text
          data-name="Better Connected social network"
          transform="translate(53.48 30)"
          fill="#07aed0"
          fontSize={30}
          fontFamily="Lato-Light, Lato"
          fontWeight={300}
        >
          <tspan x={0} y={0}>
            {"Better Connected"}
          </tspan>
          <tspan fill="#272727" fontSize={18}>
            <tspan x={0} y={24}>
              {"social network"}
            </tspan>
          </tspan>
        </text>
        <g data-name="Group 1812">
          <path
            data-name="Subtraction 21"
            d="M9.518 8.823h10.708a9.512 9.512 0 019.518 9.519v20.225H9.518A9.51 9.51 0 010 29.049V18.342a9.511 9.511 0 019.518-9.519zm17.203 26.721V19.33a7.492 7.492 0 00-7.483-7.483h-8.73a7.492 7.492 0 00-7.485 7.483v8.731a7.492 7.492 0 007.485 7.483h16.213z"
            fill="#19257c"
          />
          <path
            data-name="Subtraction 22"
            d="M20.226 29.744H9.518A9.511 9.511 0 010 20.225V0h20.226a9.51 9.51 0 019.518 9.518v10.707a9.511 9.511 0 01-9.518 9.519zM3.023 14.782v4.455a7.492 7.492 0 007.483 7.483h8.73a7.492 7.492 0 007.484-7.483v-8.73a7.492 7.492 0 00-7.484-7.483H3.023v8.73H0v3.028z"
            transform="translate(16.016 23.767)"
            fill="url(#prefix__a)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
