import styled from 'styled-components';

export const Container = styled.div`
    width: 1260px;
    background: ${(props) => props.theme.white};
    border-radius: 10px;
    padding: 50px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
    box-sizing: border-box;

    @media ${props => props.theme.mediaHD} {
        width: 830px;
    }
`;
