import React from "react";
import {Progress} from "./Styled";
import BaseTemplateNew from "../BaseTemplateNew";

class FullPageLoading extends BaseTemplateNew {
    constructor() {
        super();
        super.showHeader = false;
        super.showFooter = false;
    }
    renderBody() {
        return(
            <Progress>
                <div/>
            </Progress>
        )
    }
}

export default FullPageLoading;