import React, {Component} from 'react';
import {RadioButton} from "./Styled";
import PropTypes from "prop-types";
import {handleInputChange} from "../FormInputText";

class FormButtonRadioCheck extends Component {
    render() {
        return(
            <RadioButton
                styled={this.props.styled}
                padding={this.props.padding}
            >
                <input
                    type="radio"
                    id={this.props.id}
                    name={this.props.name}
                    checked={this.props.checked}
                    onChange={(e) => {
                        if (this.props.hasOwnProperty("onChange")) { // The page handle changes itself
                            this.props.onChange(e)
                        } else {
                            handleInputChange( // Changes handled by default
                                e, this.props.states, false, this.props.extraLocalStatesOnChange
                            )}
                        }
                    }
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    value={this.props.value}
                />
                <i>
                    <em/>
                    <span>{this.props.label}</span>
                </i>
            </RadioButton>
        )
    }
}

export default FormButtonRadioCheck;

FormButtonRadioCheck.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number
    ]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.number,
    onChange: (props, propName, componentName) => {
        if (props.onChange && props.states && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'states' together in '${componentName}'.`
            );
        }
        if (props.onChange && props.extraLocalStatesOnChange && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
    states: (props, propName, componentName) => {
        if (props.onChange && props.states && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'states' together in '${componentName}'.`
            );
        }
        if (props.onChange && props.extraLocalStatesOnChange && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
    extraLocalStatesOnChange: (props, propName, componentName) => {
        if (props.onChange && props.states && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'states' together in '${componentName}'.`
            );
        }
        if (props.onChange && props.extraLocalStatesOnChange && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    }
};