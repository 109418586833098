import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
    width: 900px;
    column-count: 3;
    & > label {
        display: block;
        margin-bottom: 22px;
    }
`;

export const MoreOptions = styled.span`
    font-size: 18px;
    color: ${props => props.theme.textBlack};
    cursor: pointer;
    span {
        margin-left: 2px;
        &:after, &:before {
            background: ${props => props.theme.textBlack};
        }
    }
`;