import React, { Component } from 'react';
import { ClearFloats } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';
import FormButtonText from '../FormButtonText/FormButtonText';
import {
    Wrapper,
    OwnerImage,
    PostButtonStyles,
    ButtonBox,
    InputTextWrapperStyles,
    VideoInputTextWrapperStyles,
    ModalWrapper,
    VideoProvider,
    VideoPlayer,
    TextStylesInput,
    ModalWrapperLink,
    DoneButtonWrapper,
    ImgStyle,
    ImgWrapper,
    AltButtonWrapper,
    Line,
} from './Styled';
import { Separator } from '../ListingOnFeed/Styled';
import { ModalTitleStyles, ModalCloseStyles } from '../SharedListing/Styled';
import Modal from '../Modal';
import InputAddressSearch from '../FormInputAddressSearch';
import InputText from '../FormInputText';
import { getInputNameAndValue, sendAsyncRequest, __ } from '../../../Utils';
import {
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
} from '../LoginForm/Styled';
import { MenuOption } from '../ProfileMenuList/Styled';
import { MenuOptionSelect } from '../ProfileMenuSettingsPrivileged/Styled';
import {
    InputTextDynamicStyles,
    InputTextWrapperDynamicStyles,
} from '../FormDynamicField/Styled';
import SelectorPopUp from '../SelectorPopUp/SelectorPopUp';
import TextArea from '../AutoCompleteTextArea';
import CarouselComponent from '../CarouselComponent/CarouselComponent';

class ListingAddAnnouncements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postText: '',
            modalOpen: false,
            modalTitle: '',
            location: '',
            fields: {},
            mediaType: '',
            link: '',
            validLink:false,
            linkImages: [],
            videoProvider: '',
            videoLink: '',
            selectedPicture: -1,
            selectedPictureUrl:'',
            urlafterClickDone:'',
            doneButtonClicked:false,
            picturesArray:[

            ],
            arrayofPictures: [
                'https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1134&q=80',
                'https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1134&q=80',
                'https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1134&q=80',
                'https://images.unsplash.com/photo-1586023492125-27b2c045efd7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1134&q=80',
            ],
            privacy: {
                visibility: { text: 'Public', value: 1 },
                comments: { text: 'Public', value: 1 },
                share: { text: 'Public', value: 1 },
            },
        };
        this.privacyOptions = [
            { text: 'Public', value: 1 },
            { text: 'My Followers', value: 2 },
            { text: 'My Connections', value: 3 },
        ];
    }

    handleInputChange = (e) => {
        this.carouselPictures();
        const { name, value } = getInputNameAndValue(e);
        this.setState({ [name]: value });
    };

    handleFileChange = (file) => {
        console.log(file);
        this.setState({
            imageFile: file,
        });
    };

    onPictureClickHandler = (index, targetSlide, photo) => {
        if (this.state.selectedPicture === targetSlide) {
            this.setState({
                selectedPicture: -1,
                selectedPictureUrl: '',
            });
        } else if (this.state.selectedPicture !== targetSlide) {
            this.setState({
                selectedPicture: index,
                selectedPictureUrl: photo,
            });
        }
    };


    carouselPictures = () => {
        sendAsyncRequest(`/url/info?url=${this.state.link}`, 'get',{})
        .then((res) => {
            if (res.status === 200 && 'URL is not valid') {
                console.log(res);
                this.setState({
                    validLink:false
                })
            } else if (res.status === 200 && res.body.Result.images.length !== null) {
                console.log(res);
                this.setState({
                    validLink:true,
                    picturesArray: res.body.Result.images

                })
            }
        })
    }

    componentDidMount() {
    }

    onPost = () => {
        console.log();
        sendAsyncRequest('/announcement/create/', 'post', {
            announcement: {
                text: this.state.postText,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                link: this.state.link,
                location_text: this.state.fields.city,
            },
            visibility: this.state.privacy.visibility.value,
            comments: this.state.privacy.comments.value,
            share: this.state.privacy.share.value,
        })
            .then((res) => {
                console.log(res);
                if (res.body.ErrorCode === 201) {
                    this.setState({
                        postText: '',
                        location: '',
                        fields: {},
                        mediaType: '',
                        link: '',
                        linkImages: [],
                        videoProvider: '',
                        videoLink: '',
                    });
                    alert('Operation done succesfully');
                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        let modalComponent;
        switch (this.state.modalTitle) {
            case 'Location':
                modalComponent = (
                    <ModalWrapper height={550}>
                        <InputAddressSearch
                            id={'somthing'}
                            type="text"
                            name={'something'}
                            states={[this]}
                            styled={InputTextDynamicStyles}
                            wrapperStyled={InputTextWrapperDynamicStyles}
                            required={true}
                            list={[]}
                            value={this.state.location}
                            autoComplete="off"
                        />
                        <FormButtonText
                            styled={PostButtonStyles}
                            onClick={() => this.setState({ modalOpen: false })}
                        >
                            Done
                        </FormButtonText>
                    </ModalWrapper>
                );
                break;
            case 'Add Link':
                modalComponent = (
                    <ModalWrapperLink>
                        <InputText
                            id="link"
                            type="text"
                            name="link"
                            onChange={this.handleInputChange}
                            styled={TextStylesInput}
                            wrapperStyled={InputTextWrapperStyles}
                            labelAnimate={__('url', 'URL')}
                            labelAnimateStyled={InputLabelAnimateStyles}
                            labelAfterAnimateStyled={
                                InputLabelAfterAnimateStyles
                            }
                            required
                        />
                        {(this.state.link !== '' && this.state.validLink) && (
                            <div>
                                <div>
                                    <CarouselComponent
                                        selectableMode
                                        data={this.state.picturesArray}
                                        onPictureClickHandler={
                                            this.onPictureClickHandler
                                        }
                                        selectedPicture={
                                            this.state.selectedPicture
                                        }
                                    />
                                </div>
                                {  this.state.selectedPictureUrl && (
                                    <DoneButtonWrapper onClick={() => this.setState({urlafterClickDone: this.state.selectedPictureUrl, doneButtonClicked:true})}>
                                        Done
                                    </DoneButtonWrapper>
                                )}
                            </div>
                        )}
                    </ModalWrapperLink>
                );
                break;
            case 'Privacy':
                modalComponent = (
                    <ModalWrapper>
                        <MenuOptionSelect>
                            <p>Visibility</p>
                            <SelectorPopUp
                                value={this.state.privacy.visibility}
                                onChange={(option) =>
                                    this.setState((prevState) => {
                                        return {
                                            privacy: {
                                                ...prevState.privacy,
                                                visibility: option,
                                            },
                                        };
                                    })
                                }
                                color={'textBlack'}
                                items={this.privacyOptions}
                                doubleArrow
                            />
                        </MenuOptionSelect>
                        <MenuOptionSelect>
                            <p>Comments</p>
                            <SelectorPopUp
                                value={this.state.privacy.comments}
                                onChange={(option) =>
                                    this.setState((prevState) => {
                                        return {
                                            privacy: {
                                                ...prevState.privacy,
                                                comments: option,
                                            },
                                        };
                                    })
                                }
                                color={'textBlack'}
                                items={this.privacyOptions}
                                doubleArrow
                            />
                        </MenuOptionSelect>
                        <MenuOptionSelect>
                            <p>Share</p>
                            <SelectorPopUp
                                value={this.state.privacy.share}
                                onChange={(option) =>
                                    this.setState((prevState) => {
                                        return {
                                            privacy: {
                                                ...prevState.privacy,
                                                share: option,
                                            },
                                        };
                                    })
                                }
                                color={'textBlack'}
                                items={this.privacyOptions}
                                doubleArrow
                            />
                        </MenuOptionSelect>
                        <FormButtonText
                            styled={PostButtonStyles}
                            onClick={() => this.setState({ modalOpen: false })}
                        >
                            Save
                        </FormButtonText>
                    </ModalWrapper>
                );
                break;
            case 'Upload Image':
                modalComponent = (
                    <ModalWrapper>
                        <MenuOption fontSize={18}>
                            <span>
                                <IconNew
                                    bp1="-371"
                                    bp2="-359"
                                    iwidth="32"
                                    iheight="28"
                                />
                            </span>
                            <p>Select from Gallery</p>
                        </MenuOption>
                        <MenuOption fontSize={18}>
                            <span>
                                <IconNew
                                    bp1="-333"
                                    bp2="-359"
                                    iwidth="35"
                                    iheight="29"
                                />
                            </span>
                            <p>Select from your previous uploads</p>
                        </MenuOption>
                    </ModalWrapper>
                );
                break;
            case 'Add Video':
                modalComponent = (
                    <ModalWrapper>
                        <VideoProvider
                            selected={this.state.videoProvider === 'youtube'}
                            onClick={() =>
                                this.setState({
                                    videoProvider: 'youtube',
                                    videoLink: '',
                                })
                            }
                        >
                            <IconNew
                                bp1="-54"
                                bp2="-607"
                                iwidth="59"
                                iheight="42"
                                className="youtube"
                            />
                        </VideoProvider>
                        <VideoProvider
                            selected={this.state.videoProvider === 'vimeo'}
                            onClick={() =>
                                this.setState({
                                    videoProvider: 'vimeo',
                                    videoLink: '',
                                })
                            }
                        >
                            <IconNew
                                bp1="0"
                                bp2="-607"
                                iwidth="53"
                                iheight="43"
                                className="youtube"
                            />
                        </VideoProvider>
                        {this.state.videoProvider !== '' && (
                            <InputText
                                id="videoLink"
                                type="text"
                                name="videoLink"
                                onInput={this.handleInputChange}
                                value={this.state.videoLink}
                                styled={TextStylesInput}
                                wrapperStyled={VideoInputTextWrapperStyles}
                                labelAnimate={__('url', 'URL')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateStyles
                                }
                                required
                            />
                        )}
                        {this.state.videoLink && (
                            <VideoPlayer
                                src={
                                    this.state.videoProvider === 'youtube'
                                        ? `https://www.youtube.com/embed/${
                                              this.state.videoLink
                                                  .split('?v=')[1]
                                                  .split('&')[0]
                                          }`
                                        : `https://player.vimeo.com/video/${
                                              this.state.videoLink
                                                  .split('/')
                                                  .slice(-1)[0]
                                          }}`
                                }
                                width="355"
                                height="225"
                                title="youtube"
                            />
                        )}
                        {this.state.videoLink && (
                            <FormButtonText styled={PostButtonStyles}>
                                Done
                            </FormButtonText>
                        )}
                    </ModalWrapper>
                );
                break;
            default:
                modalComponent = <div />;
                break;
        }

        return (
            <Wrapper>
                <OwnerImage
                    src="/assets/img/temp/property_owner_sample_photo.png"
                    size={70}
                />
                <TextArea />
                <ClearFloats />
                {(this.state.doneButtonClicked === true) && (
                    <ImgWrapper>
                        <ImgStyle  src={this.state.urlafterClickDone} alt='selectedPicture' />
                        <AltButtonWrapper>
                            Alt
                        </AltButtonWrapper>
                    </ImgWrapper>
                )}

                <Separator height={10} />
                <Line />

                <ButtonBox
                    left
                    enabled={
                        this.state.mediaType === 'image' ||
                        this.state.mediaType === ''
                    }
                    onClick={() => {
                        this.setState({
                            modalTitle: 'Upload Image',
                            modalOpen: true,
                        });
                    }}
                >
                    {this.state.mediaType === 'image' ||
                    this.state.mediaType === '' ? (
                        <IconNew
                            bp1="-467"
                            bp2="-211"
                            iwidth="16"
                            iheight="17"
                        />
                    ) : (
                        <IconNew bp1="0" bp2="-93" iwidth="22" iheight="20" />
                    )}
                </ButtonBox>
                <ButtonBox
                    left
                    enabled={
                        this.state.mediaType === 'video' ||
                        this.state.mediaType === ''
                    }
                    onClick={() => {
                        this.setState({
                            modalTitle: 'Add Video',
                            modalOpen: true,
                        });
                    }}
                >
                    {this.state.mediaType === 'video' ||
                    this.state.mediaType === '' ? (
                        <IconNew
                            bp1="-487"
                            bp2="-177"
                            iwidth="16"
                            iheight="16"
                        />
                    ) : (
                        <IconNew
                            bp1="-487"
                            bp2="-195"
                            iwidth="16"
                            iheight="16"
                        />
                    )}
                </ButtonBox>
                <ButtonBox
                    left
                    enabled={
                        this.state.mediaType === 'voice' ||
                        this.state.mediaType === ''
                    }
                    onClick={() => {
                        this.setState({
                            modalTitle: 'Add Voice',
                            modalOpen: true,
                        });
                    }}
                >
                    {this.state.mediaType === 'voice' ||
                    this.state.mediaType === '' ? (
                        <IconNew
                            bp1="-507"
                            bp2="-177"
                            iwidth="11"
                            iheight="16"
                        />
                    ) : (
                        <IconNew
                            bp1="-506"
                            bp2="-195"
                            iwidth="12"
                            iheight="16"
                        />
                    )}
                </ButtonBox>
                <ButtonBox
                    left
                    enabled
                    onClick={() => {
                        this.setState({
                            modalTitle: 'Add Link',
                            modalOpen: true,
                        });
                    }}
                >
                    <IconNew bp1="-487" bp2="-212" iwidth="16" iheight="16" />
                </ButtonBox>
                <ButtonBox
                    right
                    enabled
                    noBorder
                    onClick={() => {
                        this.setState({
                            modalTitle: 'Privacy',
                            modalOpen: true,
                        });
                    }}
                >
                    <IconNew bp1="-487" bp2="-155" iwidth="18" iheight="20" />
                </ButtonBox>
                <ButtonBox
                    right
                    enabled
                    noBorder
                    onClick={() => {
                        this.setState({
                            modalTitle: 'Location',
                            modalOpen: true,
                        });
                    }}
                >
                    <IconNew bp1="-507" bp2="-155" iwidth="16" iheight="20" />
                </ButtonBox>

                {this.state.postText && (
                    <FormButtonText
                        styled={PostButtonStyles}
                        onClick={this.onPost}
                    >
                        Post
                    </FormButtonText>
                )}

                <ClearFloats />
                <Modal
                    isOpen={this.state.modalOpen}
                    handleClose={() => this.setState({ modalOpen: false })}
                    containerWidth="400"
                    containerHeight="auto"
                    containerSizeUnit="px"
                    containerPadding="0"
                    title={this.state.modalTitle}
                    titleStyled={ModalTitleStyles}
                    closeStyled={ModalCloseStyles}
                >
                    {modalComponent}
                </Modal>
            </Wrapper>
        );
    }
}

export default ListingAddAnnouncements;
