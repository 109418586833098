import styled from 'styled-components';
import { AppLink } from '../../../styles/Styled';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div``;

export const OwnerImage = styled.img`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    float: left;
    border-radius: 10px;
`;

export const Separator = styled.div`
    height: ${(props) => props.height}px;
`;

export const ImageTitle = styled.h3`
    float: left;
    font-size: 18px;
    color: ${(props) => props.theme.textBlack};
    line-height: 28px;
    a {
        color: ${(props) => props.theme.textBlack};
        margin: 0;
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    ${(props) =>
        Array.isArray(props.children) // Contains <span>
            ? ' margin: 4px 0 0 10px; '
            : ' margin: 20px 0 0 10px; '};
    & > span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        color: ${(props) => props.theme.mainFontColor};
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        span {
            margin-right: 7px;
            position: relative;
            top: 2px;
        }
    }
`;

export const TagsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
`;

export const Tag = styled(AppLink)`
    float: right;
    margin: 27px 8px 0 0;
`;

export const Time = styled.em`
    float: right;
    font-size: 16px;
    font-style: normal;
    font-weight: lighter;
    color: ${(props) => props.theme.mainFontColor};
    margin: 20px 0 0;
`;

export const Menu = styled.div`
    float: right;
    padding: 0 10px;
    margin: 20px -20px 0 0;
    &:after {
        content: '\\22EE';
        display: inline-block;
        font-size: 33px;
        font-weight: bold;
        color: ${(props) => props.theme.grayCircle};
        width: 15px;
    }
    &:hover {
        cursor: pointer;
    }
`;

export const PropertyImageLink = styled(Link)`
    ${(props) => {
        switch (props.size) {
            case 'xlg':
                return `
                        margin-top: -10px;
                    `;
            case 'lg':
                return `
                        margin-right: 7px;
                    `;
            case 'sm':
                return ``;
            case 'md':
                return `
                        margin-right: 10px;
                    `;
            default:
                return ``;
        }
    }};
    display: block;
    position: relative;
    line-height: 0;
    &.top-right {
        margin-bottom: 10px;
    }
    &:last-of-type {
        margin-right: 0;
    }
    float: left;
    img {
        object-fit: cover;
        border-radius: 10px;
        position: relative;
        ${(props) => {
            switch (props.size) {
                case 'xlg':
                    return `
                            width: 575px; height: 351px;
                        `;
                case 'lg':
                    return `
                            width: 433px; height: 274px;
                        `;
                case 'sm':
                    return `
                            width: 131px; height: 131px;
                        `;
                case 'md':
                    return `
                            width: 280px; height: 351px;
                        `;
                default:
                    return ``;
            }
        }};
    }

    h3 {
        min-width: 100px;
        height: 40px;
        background: ${(props) => props.theme.darkBlue};
        opacity: 0.8;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow: hidden;
        font-size: 18px;
        font-weight: lighter;
        color: ${(props) => props.theme.white};
        position: absolute;
        bottom: -18px;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        line-height: 25px;
    }

    @media ${(props) => props.theme.mediaHD} {
        img {
            ${(props) => {
                switch (props.size) {
                    case 'xlg':
                        return `
                            width: 360px; height: 220px;
                        `;
                    case 'lg':
                        return `
                            width: 271px; height: 172px;
                        `;
                    case 'sm':
                        return `
                            width: 82px; height: 82px;
                        `;
                    case 'md':
                        return `
                            width: 175px; height: 220px;
                        `;
                    default:
                        return ``;
                }
            }};
        }
    }
`;

export const PropertyImageMore = styled.span`
    // For "sm" Size. For other sizes, use "switch" above
    width: 131px;
    height: 131px;
    display: inline-block;
    position: relative;
    a {
        float: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${(props) => props.theme.textBlack};
        border-radius: 10px;
        opacity: 0.5;
        z-index: 1;
    }
    img {
        // For "sm" Size. For other sizes, use "switch" above
        object-fit: cover;
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }
    span {
        pointer-events: none;
        position: relative;
        top: 2px;
        margin-right: 10px;
    }
    strong {
        pointer-events: none;
        width: 105px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -16px 0 0;
        transform: translateX(-50%);
        font-size: 22px;
        text-align: center;
        color: #fff;
        z-index: 2;
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 82px;
        height: 82px;
    }
`;

export const ItemsCountAndType = styled.div`
    h3 {
        font-size: 16px;
        color: ${(props) => props.theme.textBlack};
        line-height: 24px;
        padding: 0;
        box-sizing: border-box;
        display: block;
    }
    p {
        float: right;
        font-size: 16px;
        font-weight: lighter;
        margin: 0;
    }
    strong {
        color: ${(props) => props.theme.cyan};
        font-size: 16px;
    }
    span:first-of-type {
        position: relative;
        top: 2px;
        margin: 0 1px 0 0;
    }
    div.info {
        font-size: 16px;
        border-bottom: 1px solid ${(props) => props.theme.textGrayLight};
        display: inline-block;
        padding: 0 15px 0 5px;
        font-weight: lighter;
    }
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: -20px 0 20px;;

    p {
        margin: 15px 0 0 0;
        font-size: 18px;
        line-height: 20px;
        color: ${(props) => props.theme.mainFontColor};
        font-weight: normal;
        height: ${(props) => props.lines * 21}px;
        overflow: hidden;
        width: 510px;
    }
`;

export const ArrowBack = styled.i`
    background: ${(props) => props.theme.cyan};
    height: 2px;
    width: 15px;
    position: relative;
    bottom: 5px;
    cursor: pointer;
    &:before,
    &:after {
        content: '';
        background: ${(props) => props.theme.cyan};
        position: absolute;
        height: 2px;
        width: 12px;
    }
    &:before {
        left: 7px;
        bottom: 4px;
        transform: rotate(45deg);
    }
    &:after {
        left: 7px;
        top: 4px;
        transform: rotate(-45deg);
    }
`;

/*
export const MainWrapper = styled(ListingWrapper)`
    display: ${props => props.showWrapper ? "block" : "none"};
`

export const Wrapper = styled(ListingWrapper)`
    margin-bottom:20px;
`

export const SharedBy = styled.div`
    margin-bottom:10px;
`

export const Description = styled.div`
    margin-top:10px
	text-overflow: ellipsis;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    padding:0 15px;
`*/
