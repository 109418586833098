import React, { Component } from 'react';
import { ListContent } from './Styled';
//import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';
import ProfileMenuContent from '../ProfileMenuContent';
import { sendAsyncRequest } from '../../../Utils';

class NotificationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedId: '-1',
            notificationContainer:[],
            
        };

      
    }

    handleItemClick = (index) => {
        this.setState({
            selectedId: index,
        });
    };


    async componentDidMount() {
        await sendAsyncRequest('/notifications/list', 'get', {
            only_unread:true,
        }).then((res) => {
         this.setState({
            notificationContainer: res.body.Result
         })
         console.log(this.state.notificationContainer)
        })
    }

    render() {
        // if (!this.state.loading) {
        //     return (
        //         <SkeletonLoading type={SkeletonLoadingType.NOTIFICATION_MENU} />
        //     );
        // }
        let itemsToShow;
        /* if (this.state.selectedId === '-1') {
            itemsToShow = this.state.notificationContainer.map((notificationItem) => {
                let filteredItems = this.state.notificationContainer.filter((item, index) => item.notification_type === notificationItem.notification_type);
                const filteredNotificationType = notificationItem.notification_type !== notificationItem.notification_type;
                console.log(notificationItem.notification_type);
                const hello = new Set(notificationItem.notification_type);
                return (
                <NotificationsItem
                    key={notificationItem.notification_type_id}
                    id={notificationItem.notification_type_id}
                    count={notificationItem.count}
                    data={filteredItems}
                    message={notificationItem.message}
                    title={notificationItem.notification_type}
                    onClick={() => this.handleItemClick(notificationItem.id)}
                    allType
                />
                );
            });
        } else {
            const selectedItem = this.state.notificationContainer.find(
                (item) => item.notificationContainer === this.state.selectedId
            );
            itemsToShow = (
                <NotificationsItem
                    key={selectedItem.notification_type_id}
                    id={selectedItem.notification_type_id}
                    count={selectedItem.count}
                    data={selectedItem.data}
                    message={selectedItem.message}
                    title={selectedItem.title}
                    onClick={() => this.handleItemClick(selectedItem.id)}
                    allType
                />
            );
        } */
        return (
            <ProfileMenuContent
                title="Notifications"
                onTitleClick={() => {
                    if (this.state.selectedId !== '-1') {
                        this.handleItemClick('-1');
                    } else {
                        this.props.backButton();
                    }
                }}
            >
                <ListContent>{itemsToShow}</ListContent>
            </ProfileMenuContent>
        );
    }
}

export default NotificationsList;
