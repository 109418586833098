import queryString from 'query-string';
import Config from './config';
import { Auth } from "aws-amplify";

export const makeKeyword = (string) => {
    return string.replace(/\s+/g, '_').toLowerCase();
}

let languageKeywords = null;
export const __ = (keyword, defaultValue) => {

    if (languageKeywords === null) {
        const keywords = localStorage.getItem('languageKeywords');
        if (keywords) {
            languageKeywords = JSON.parse(keywords);
        } else {
            languageKeywords = [];
        }
    }

    if (typeof languageKeywords[keyword] === 'string') {
        return languageKeywords[keyword];
    }

    return defaultValue;
}

/**
* Check if page scrooled to elementbottom
*
* @param {timestamp} time
* @return true or false
* @public
*/

export const timeAgo = (time) => {
    switch (typeof time) {
        case 'number':
            break;
        case 'string':
            time = +new Date(time);
            break;
        case 'object':
            if (time.constructor === Date) time = time.getTime();
            break;
        default:
            time = +new Date();
    }
    const time_formats = [
        [60, 's', 1], // 60
        [120, '1min', '1 minute from now'], // 60*2
        [3600, 'mins', 60], // 60*60, 60
        [7200, '1hr', '1 hour from now'], // 60*60*2
        [86400, 'hrs', 3600], // 60*60*24, 60*60
        [172800, 'yesterday', 'tomorrow'], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, 'last week', 'next week'], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, 'last month', 'next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'last year', 'next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'last century', 'next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000,
        token = 'ago',
        list_choice = 1;

    if (seconds === 0) {
        return 'Just now'
    }

    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'from now';
        list_choice = 2;
    }
    let i = 0, format;
    format = time_formats[i++];
    while (format) {
        if (seconds < format[0]) {
            if (typeof format[2] == 'string')
                return format[list_choice];
            else
                return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }
        format = time_formats[i++];
    }
    return time;
}

/**
* Check if page scrooled to elementbottom
*
* @param {el} element
* @return true or false
* @public
*/
export const isScrollBottom = (el) => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    return scrolledToBottom;
}

/**
* Check if clientKey exist or not
*
* @return clientKey or false
* @public
*/
export const getClientKey = () => {
    if (localStorage.getItem('clientKey')) {
        return localStorage.getItem('clientKey');
    }

    return false;
}

/**
* Calculate input data from it's event
*
* @param {object} event - the event will be available when a function call on the onChange event of inuts
* @return name and value of input
* @public
*/
export const getInputNameAndValue = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return { name, value };
};

/**
*
*
* @param {string} requestUrl
* @param {object} requestBody
* @param {string} requestMethod
* @param {function} callback
* @public
*/
export const sendRequest = (requestUrl, requestBody, requestMethod, callback, addLastSeprator, addContentType, bodyJsonStringify) => {

    //Set default value in the cases that they do not exist
    if (typeof addLastSeprator === 'undefined') {
        addLastSeprator = true;
    }

    if (typeof addContentType === 'undefined') {
        addContentType = true;
    }

    if (typeof bodyJsonStringify === 'undefined') {
        bodyJsonStringify = true;
    }


    //Create Header object
    let headerObject = {};

    headerObject['Accept'] = 'application/json';

    if (addContentType) {
        headerObject['Content-Type'] = 'application/json';
    }

    const clientKey = getClientKey();
    if (clientKey) {
        // headerObject['Authorization'] = 'Token ' + clientKey;
        headerObject['Authorization'] = 'Bearer ' + clientKey;
    }

    //Create Request Object
    const requestObj = {
        method: requestMethod,
        headers: headerObject
    };

    let sendRequestURL = Config.ENDPOINT + requestUrl + (addLastSeprator ? '/' : '');

    if (requestMethod !== 'get') {
        requestObj['body'] = (bodyJsonStringify ? JSON.stringify(requestBody) : requestBody);
    } else {
        if (Object.keys(requestBody).length > 0) {
            sendRequestURL = sendRequestURL + '?' + queryString.stringify(requestBody);
        }
    }

    let requestStatus = 0;
    fetch(sendRequestURL, requestObj)
        .then((response) => {
            requestStatus = response.status;

            if (requestStatus === 401) {

                // 401 Unauthorized => Call RefreshToken
                let username, refreshToken, refreshError = false;
                if (getCurrentUserData() && localStorage.getItem('clientTokens')) {
                    username = getCurrentUserData().username;
                    refreshToken = (JSON.parse(localStorage.getItem('clientTokens'))).refreshToken;
                    if (username && refreshToken) {
                        localStorage.removeItem('clientKey');
                        sendRequest('refresh/token', {
                            "username": username,
                            "refresh_token": refreshToken
                        }, 'post', refreshTokenCallback);
                    } else {
                        refreshError = true;
                    }
                } else {
                    refreshError = true;
                }
                if (refreshError) {
                    localStorage.removeItem('clientKey');
                    localStorage.removeItem('clientTokens');
                    localStorage.removeItem('userData');
                    window.location.href = '/';
                }
                // TODO :: Prevent Callback (Not Working)
                // return false;
                // throw new Error('401 Unauthorized');
            } else {
                return response.json();
            }
        })
        .then((responseJson) => {
            if (callback) {
                callback(requestStatus, responseJson);
            }
        })
        .catch((error) => {
            if (callback) {
                callback(requestStatus, {});
            }
        });
};

/**
* @param {string} requestUrl
* @param {string} requestMethod
* @param {object} requestBody
* @param {object} extraHeaders
* @param {boolean} bodyJsonStringify
* @param {boolean} formData
* @public
*/
export const sendAsyncRequest = async (
    requestUrl, requestMethod, requestBody,
    extraHeaders = null, bodyJsonStringify = true,
    formData = false
) => {

    let requestHeaders = {};
    requestHeaders['Accept'] = 'application/json';

    if (!formData) {
        requestHeaders['Content-Type'] = 'application/json';
    }

    const clientTokens = await getClientTokens();
    if (clientTokens) {
        requestHeaders['Authorization'] = 'Bearer ' + clientTokens.idToken;
        console.log('async_call', requestUrl, clientTokens);
        console.log(clientTokens.idToken);
    }

    let url = Config.ENDPOINT + requestUrl;

    if (requestMethod.toLowerCase() !== 'get') {
        if (formData) {
            requestBody = formData;
        } else {
            requestBody = (bodyJsonStringify ? JSON.stringify(requestBody) : requestBody);
        }
    } else {
        if (Object.keys(requestBody).length > 0) {
            url = url + '?' + queryString.stringify(requestBody);
        }
        requestBody = null;
    }

    if (extraHeaders) {
        for (let [key, value] of Object.entries(extraHeaders)) {
            requestHeaders[key] = value;
        }
    }
    const fetchIt = async () => {
        try {
            let response = await fetch(url, {
                method: requestMethod,
                headers: requestHeaders,
                body: requestBody
            });
            const result = await response.json();
            return {
                status: response.status,
                body: result
            }
        } catch (err) {
            return {
                status: 500,
                body: err.message
            };
        }
    };
    return await fetchIt();
};

export const getCurrentUserData = () => {
    if (localStorage.getItem('userData')) {
        return JSON.parse(localStorage.getItem('userData'));
    }
    return false;
};

export const getUserServer = async () => {
    try {
        await Auth.currentAuthenticatedUser();
        const user = await sendAsyncRequest('/user/', 'get', {});
        if (user.status === 500) {
            return false;
        }
        return user.body;
    } catch (e) {
        return false;
    }
}

export const commaSeparator = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isEmail = (email) => {
    // eslint-disable-next-line
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
};

export const isMobile = (mobile) => {
    mobile = mobileE164(mobile); // Make Compatible with All Mobile Formats
    // eslint-disable-next-line
    const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return reg.test(String(mobile).toLowerCase());
};

export const mobileE164 = (mobile) => {
    return (mobile) ? '+' + mobile.toString().replace(/\D/g, '') : '';
};

export const random14no4 = () => {
    let randomStr = (Date.now()).toString();
    const one2nine = [0, 1, 2, 3, 5, 6, 7, 8, 9][Math.floor(Math.random() * 9)], // Except 4
        one2nine4replace = [0, 1, 2, 3, 5, 6, 7, 8, 9][Math.floor(Math.random() * 9)], // Except 4
        randomPosition = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12][Math.floor(Math.random() * 12)];
    randomStr = randomStr.slice(0, randomPosition) + one2nine + randomStr.slice(randomPosition);
    return randomStr.replace(/4/g, one2nine4replace.toString());
};

export const StorageItem = {
    set: (key, value, obj = false) => {
        localStorage.setItem(key, (obj) ? JSON.stringify(value) : value);
    },
    get: (key) => {
        const item = localStorage.getItem(key);
        try {
            return JSON.parse(item); // Is Object
        } catch (err) {
            return item; // Is Not Object
        }
    },
    remove: (key) => {
        localStorage.removeItem(key);
    }
};

export const getClientTokens = async () => {
    try {
        const clientTokens = await Auth.currentSession();
        return {
            idToken: clientTokens.getIdToken().getJwtToken(),
            accessToken: clientTokens.getAccessToken().getJwtToken(),
            refreshToken: clientTokens.getRefreshToken()
        }
    } catch (e) {
        return null;
    }
};

export const getAuthenticateStatus = async () => {
    try {
        await Auth.currentAuthenticatedUser();
        const user = await sendAsyncRequest('/user/', 'get', {});
        if (user.status === 500) {
            return false;
        }
        return {
            isAuth: true,
            isInvKey: user.body.invite_code
        };
    } catch (e) {
        return {
            isAuth: false,
            isInvKey: false
        };
    }
};

export const getCurrentUser = async () => {
    try {
        const clientTokens = await Auth.currentSession();
        return {
            ...clientTokens.getIdToken().decodePayload()
        }
    } catch (e) {
        return null;
    }
};

export const publicRouteRule = async (that) => {
    const authObj = await getAuthenticateStatus();
    if (authObj === false) {
        that.props.history.push(
            Config.ROUTES.serverError
        );
    }
    if (authObj.isAuth && authObj.isInvKey) {
        that.props.history.push(
            Config.ROUTES.homePage
        );
    } else if (authObj.isAuth && !authObj.isInvKey) {
       /*  that.props.history.push(
            Config.ROUTES.register.invitationKey
        ); */
    }
};

export const objIsEqual = (a, b) => {
    if (!a || !b) {
        return false;
    }
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

export const refreshTokenCallback = (requestStatus, responseJson) => {
    if (requestStatus === 200) {
        localStorage.setItem("clientKey", responseJson.AuthenticationResult.IdToken);
        localStorage.setItem("clientTokens", JSON.stringify({
            accessToken: responseJson.AuthenticationResult.AccessToken,
            refreshToken: responseJson.AuthenticationResult.RefreshToken
        }));
    } else {
        localStorage.removeItem('clientKey');
        localStorage.removeItem('clientTokens');
        localStorage.removeItem('userData');
        window.location.href = '/';
    }
};

export const numberFormatter = (num, digits = 1) => {
    const si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "K" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const sequentialNumber = (num) => {
    if (num % 10 === 1) {
        return `${num}st`;
    } else if (
        num % 10 === 2
    ) {
        return `${num}nd`;
    } else if (
        num % 10 === 3
    ) {
        return `${num}rd`;
    }
    return `${num}th`;
}

export const checkPasswordValidity=(password)=>{
    let isValid = false;
    isValid = password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
    return{
        status:isValid,
        message: (isValid?"":"Password must be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
    }
}