import React, { Component } from 'react';
import { Container, ItemWrapper, TransparentBackground } from './Styled';
import { ClearFloats } from '../BaseTemplate/Styled';

class StatisticsChart extends Component {
    render() {
        return (
            <Container>
                {this.props.data.values.map((item, index) => (
                    <ItemWrapper key={index}>
                        <TransparentBackground
                            max={this.props.data.max}
                            amount={item.amount}
                        >
                            +{item.amount}
                        </TransparentBackground>
                        <p>{item.time}</p>
                <p></p>
                    </ItemWrapper>
                ))}
                <ClearFloats />
            </Container>
        );
    }
}

export default StatisticsChart;
