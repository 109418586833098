import styled from 'styled-components';

export const Wrapper = styled.div``;

export const VerificationStyle = styled.div`
    .code-input {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .code-inputs {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .code-inputs input {
        border: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-align: center;
        height: 44px;
        width: 40px;
        margin: 0 4px;
        border-bottom: solid 2px #e3e3e6;
        font-size: 30px;
        color: #2e2e33;
        font-family: "Montserrat";
    }
    .code-inputs input:focus {
        outline: none;
    }
`;
