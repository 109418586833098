import React, { Component } from 'react';
import { FooterWrapper } from './Styled';

export default class Footer extends Component {

    /*
    constructor(props){
        super(props);
        this.state = {
            language: 'EN'
        };
    }

    UNSAFE_componentWillMount() {
        const selectedLanguage = localStorage.getItem('languageSelected');
        this.setState({ language: selectedLanguage});
    }

    hanldeLanguageChange = (event) => {
        const value = event.target.value;

        localStorage.setItem('languageSelected', value);

        sendRequest('translates/'+value, {}, 'get', this.responseCallback);
    }

    responseCallback = (requestStatus, responseJson) => {
        let keywords = {};

        Object.keys(responseJson).forEach(function(key) {
            const keyword = responseJson[key];
            keywords[keyword.keyword_eng] = keyword.keyword;
        });

        localStorage.setItem('languageKeywords', JSON.stringify(keywords));

        window.location.reload(false);
    }

    footerLinks (){
        return(
            <UL>
                <FooterLi>
                    <FooterLink to="/page/privacy-policy">{__('privacy_policy', 'Privacy Policy')}</FooterLink>
                </FooterLi>
            </UL>
        );
    }

    footerCopyright(){
        return(
            <FooterCopyright>
                <LanguageSelect onChange={this.hanldeLanguageChange} defaultValue={this.state.language}>
                    <option value="EN">
                        English
                    </option>
                    <option value="GE">
                        ქართული
                    </option>
                </LanguageSelect>

                © {__('copyright', '2019 Realtyfeed')}
            </FooterCopyright>
        );
    }
    */

    render() {
        return(
            <FooterWrapper />
        )
    }
}