import styled, { css } from 'styled-components';
import {
    InputTextStyles,
    InputTextWrapperStyles,
    ButtonTextColoredStyles,
} from '../LoginForm/Styled';

export const Wrapper = styled.div`
    padding: 25px;
`;

export const InputTextSearchStyles = css`
    ${InputTextStyles};
    font-size: 21px;
    font-weight: normal;
`;

export const InputTextWrapperSearchStyles = css`
    ${InputTextWrapperStyles};
    height: 35px;
    margin-bottom: 15px;
`;

export const SelectedGroupsTag = styled.div`
    span {
        display: inline-block;
        position: relative;
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        color: white;
        background: ${(props) => props.theme.cyan};
        padding: 2px 20px 2px 4px;
        border-radius: 3px;
        margin-right: 8px;
        cursor: pointer;
        i {
            position: absolute;
            right: 6px;
            top: 7px;
            width: 8px;
            height: 8px;
        }
        i:before,
        i:after {
            position: absolute;
            left: 3px;
            content: ' ';
            height: 8px;
            width: 1px;
            background-color: white;
        }
        i:before {
            transform: rotate(45deg);
        }
        i:after {
            transform: rotate(-45deg);
        }
    }
`;

export const GroupRowItems = styled.div`
    height: 362px;
    max-height: 362px;
    margin-top: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
`;

export const GroupRowItem = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    :last-of-type {
        margin-bottom: 0;
    }
    img {
        width: 100px;
        height: 100px;
        margin-right: 15px;
    }
    div {
        line-height: 18px;
        position: relative;
        strong {
            font-size: 12px;
            line-height: 12px;
            padding: 2px 5px;
            background: ${(props) => props.theme.darkBlue};
            color: white;
            border-radius: 3px;
        }
        h4 {
            font-size: 16px;
            line-height: 12px;
            margin: 14px 0;
            color: ${(props) => props.theme.darkBlue};
            span {
                position: relative;
                top: 5px;
                margin-left: 5px;
            }
        }
        > span {
            font-size: 14px;
            font-weight: normal;
            span {
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const CheckBoxRowStyles = css`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 0;
`;

export const ShareButtonSubmit = css`
    ${ButtonTextColoredStyles};
    height: 50px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 25px;
    font-size: 18px;
`;
