import React, { Component } from 'react';
import { Wrapper, MenuOption } from '../ProfileMenuList/Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import ProfileMenuEditProfile from '../ProfileMenuEditProfile';
import ProfileMenuSettingsNotifications from '../ProfileMenuSettingsNotifications';
import ProfileMenuSettingsPrivacy from '../ProfileMenuSettingsPrivacy';
import ProfileMenuSettingsMLS from '../ProfileMenuSettingsMLS';
import IconNew from '../IconNew';

class ProfileMenuSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
        };
        this.menu = [
            {
                title: 'Feed Configuration',
                icon: (
                    <IconNew
                        bp1="-174.5"
                        bp2="-388.5"
                        iwidth="30"
                        iheight="30"
                    />
                ),
            },
            {
                title: 'Edit Profile',
                icon: (
                    <IconNew
                        bp1="-144.5"
                        bp2="-388.5"
                        iwidth="30"
                        iheight="30"
                    />
                ),
            },
            {
                title: 'Billing / Subscriptions',
                icon: (
                    <IconNew bp1="-294" bp2="-418.5" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Privacy / Security',
                icon: (
                    <IconNew bp1="-264" bp2="-418.5" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'MLS Settings',
                icon: (
                    <IconNew bp1="-234" bp2="-418.5" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'My Network',
                icon: (
                    <IconNew bp1="-204" bp2="-418.5" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Notification Configuration',
                icon: (
                    <IconNew
                        bp1="-174.5"
                        bp2="-418.5"
                        iwidth="30"
                        iheight="30"
                    />
                ),
            },
            {
                title: 'Sync Calendar / Contact',
                icon: (
                    <IconNew
                        bp1="-144.5"
                        bp2="-418.5"
                        iwidth="30"
                        iheight="30"
                    />
                ),
            },
        ];
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu,
        });
    };

    handleBackButton = () => {
        this.setState({ selectedMenu: -1 });
    };

    render() {
        const selectedMenu = this.state.selectedMenu;
        let itemsToShow;
        switch (selectedMenu) {
            case 1:
                itemsToShow = (
                    <ProfileMenuEditProfile
                        backButton={this.handleBackButton}
                    />
                );
                break;
            case 3:
                itemsToShow = (
                    <ProfileMenuSettingsPrivacy
                        backButton={this.handleBackButton}
                    />
                );
                break;
            case 4:
                itemsToShow = (
                    <ProfileMenuSettingsMLS
                        backButton={this.handleBackButton}
                    />
                );
                break;
            case 6:
                itemsToShow = (
                    <ProfileMenuSettingsNotifications
                        backButton={this.handleBackButton}
                    />
                );
                break;
            default:
                itemsToShow = null;
        }
        return (
            <ProfileMenuContent
                title="Settings"
                onTitleClick={() => {
                    if (selectedMenu !== -1) {
                        this.handleItemClick(-1);
                    } else {
                        this.props.backButton();
                    }
                }}
            >
                <Wrapper>
                    {selectedMenu === -1 &&
                        this.menu.map((option, index) => (
                            <MenuOption
                                key={index}
                                onClick={() =>
                                    this.setState({ selectedMenu: index })
                                }
                            >
                                <span>{option.icon}</span>
                                <p>{option.title}</p>
                            </MenuOption>
                        ))}
                    {selectedMenu !== -1 && itemsToShow}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettings;
