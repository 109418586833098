import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 96px);
    padding-left: 5%;
    padding-right: 5%;
`;

export const ItemWrapper = styled.button`
    width: 100%;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    background: white;
    padding-top:10px;
    padding-bottom:10px;
    border:none;
    &:nth-child(1) {
        border-top:1px solid #eaeaea;
    }
    font-family: 'Montserrat';
`;
export const TitleWrapper = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    cursor: pointer;
    height: 100%;

    span {
        font-size: 14px;
        color: #07aed0;
        margin: 0;
        text-align: left;
    }

    p {
        font-size: 14px;
        color:#313136;
        text-align: left;
        margin: 0;
        margin-top: 5px;
    }
`;
export const OptionsWrapper = styled.div`
    display: flex;
    width: 40%;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    height: 100%;

    p {
        font-size: 14px;
        font-weight: lighter;
        color: #797a80;
        margin: 0;
        text-align: left;
        padding-top:6px;
    }
`;
