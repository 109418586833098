import {css} from "styled-components";

export const SwitchBoxStyles = css`
    width: 84px; height: 37px;
    border-radius: 40px;
    border: solid 1px ${props => props.theme.grayBgInput};
    box-sizing: border-box;
    i em {
        box-sizing: border-box;
        width: 76px; height: 31px;
        top: 2px; left: 3px;
    }
    i em:before {
        width: 21px; height: 21px;
        left: 5px; bottom: 5px;
    }
    input:checked + i em:before {
        transform: translateX(45px);
    }
    input:checked + i em {
        background-color: ${props => props.theme.noticeGreen};
    }
    b {
        position: absolute;
        right: 13px;
        top: 8px;
        width: 19px;
        height: 19px;
    }
    b:before, b:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 19px;
        width: 4px;
        background-color: #fff;
        border-radius: 10px;
    }
    b:before {
        transform: rotate(45deg);
    }
    b:after {
        transform: rotate(-45deg);
    }
    input:checked + i b {
        display: none;    
    }
    u {
        display: none;
        position: absolute;
        top: 6px; left: 17px;
        transform: rotate(45deg);
        width: 7px; height: 16px;
        border-radius: 4px;
        border-bottom: 4px solid #fff;
        border-right: 4px solid #fff;
    }
    input:checked + i u {
        display: block;
    }
`;

export const SwitchBoxMediumStyles = css`
    ${SwitchBoxStyles};    
    width: 60px; height: 27px;
    i em {
        width: 54px; height: 23px;
        top: 1px; left: 2px;
    }
    i em:before {
        width: 17px; height: 17px;
        left: 3px; bottom: 3px;
    }
    input:checked + i em:before {
        transform: translateX(31px);
    }
    b {
        right: 10px;
        top: 7px;
        width: 12px;
        height: 12px;
    }
    b:before, b:after {
        left: 7px;
        height: 12px;
        width: 3px;
    }
    u {
        display: none;
        position: absolute;
        top: 6px; left: 14px;
        transform: rotate(45deg);
        width: 4px; height: 9px;
        border-radius: 3px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
    }
`;