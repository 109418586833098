import React, { Component } from 'react';
import {Wrapper, MenuOption} from '../ProfileMenuList/Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import IconNew from '../IconNew';

class ProfileMenuSettingsMLS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
        };
        this.menu = [
            {
                title: 'My MLS Settings',
                icon: <IconNew bp1="-144.5" bp2="-448" iwidth="30" iheight="30"/>
            },
            {
                title: 'Outgoing RESO Feed',
                icon: <IconNew bp1="-294" bp2="-478" iwidth="30" iheight="30"/>
            },
        ];
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu,
        });
    };

    handleBackButton = () => {
        this.setState({selectedMenu: -1});
    }

    render() {
        const selectedMenu = this.state.selectedMenu;
        let itemsToShow;
        switch (selectedMenu) {
            case 1:
                itemsToShow = null
                break;
            default:
                itemsToShow = null;
        }
        return (
            <ProfileMenuContent
                title="MLS Settings"
                onTitleClick={() => {
                    if (selectedMenu !== -1) {
                        this.handleItemClick(-1);
                    } else {
                        this.props.backButton();
                    }
                }}
            >
                <Wrapper>
                {selectedMenu === -1 && this.menu.map((option, index) => 
                    <MenuOption key={index} onClick={() => this.setState({selectedMenu: index})}>
                        <span>{option.icon}</span>
                        <p>{option.title}</p>
                    </MenuOption>
                )}
                {selectedMenu !== -1 && itemsToShow}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettingsMLS;
