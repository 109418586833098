import React from 'react';
import { 
    BodySectionWrapper,
    SectionRight,
    SectionTopWrapper,
    SectionBottomWrapper,
    IconText,
    SectionCardWrapper
} from './Styled'

class ProfileMenuVaultTabSectionsWrapper extends React.Component {
    render() {
        const { sectionName, rightSideColor, rightSideText, sectionData, clickHandler } = this.props;
    return (
        <BodySectionWrapper>
            <SectionTopWrapper>
                <div className='top-left'>
                    {sectionName}
                    {' '}
                    <span>
                        ({sectionData.length})
                    </span>
                </div>
                <SectionRight rightSideColor={rightSideColor} onClick={clickHandler}>
                    {rightSideText} {'>'}
                </SectionRight>
            </SectionTopWrapper>
            <SectionBottomWrapper>
               {sectionData.map((item, index) => {
                   return (
                       <SectionCardWrapper key={index}>
                            <IconText>
                                icon
                            </IconText>
                            <span>
                                {item.name}
                            </span>
                       </SectionCardWrapper>
                   );
               })}
            </SectionBottomWrapper>
        </BodySectionWrapper>
    );
            }
}


export default ProfileMenuVaultTabSectionsWrapper;
