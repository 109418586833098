import styled, {css} from "styled-components";
import {ArrowDown} from "../BaseTemplate/Styled";

export const InputStyled = css`
    caret-color: transparent;
`;

export const DropDownWrapper = styled.div`
    position: relative;
`;

export const DropDownList = styled.ul`
    list-style: none;
    margin: 0; padding: 0;
    border: solid 1px ${props => props.theme.grayLine};
    border-radius: ${props => (props.scroll) ? '10px 0 0 10px' : '10px' };
    background: #fff;
    position: absolute;
    top: 0; left: 0; right: 0;
    z-index: 100;
    overflow: hidden;
    overflow-y: auto;
    max-height: 240px;
`;

export const DropDownItem = styled.li`
    margin: 0;
    padding: 7px 15px;
    position: relative;
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.grayLighter};
    }
`;

export const DropDownArrowDown = styled(ArrowDown)`
    width: 1.1rem;
    margin: -4px 0 0;
    &:after, &:before {
        width: .7rem;
        background: ${props => props.theme.mainFontColor};
    }
`;

export const TypedText = styled.i `
    position: absolute;
    top: -35px; right: 0;
    color: ${props => props.theme.textBlack};
    font-size: 14px;
    font-style: normal;
`;
