import React from 'react';
import {
    CardWrapper,
    CardTopWrapper,
    CardtopItem,
    RightBorder,
    TopTextWrapper,
    CarouselMiddleContainer,
    CarouselTopSideHeaderText,
    CarouselBottomAddressText,
    CarouselMiddleBottomSide,
    CarouselMiddlePriceText,
    CarouselMiddleRightSideText,
    CarouselMiddleTopSide,
    CarouselMiddleSide,
    RightSideText,
    RightSideTextBorder,
    RighttSideTextNumber,
    CardMiddlebottomLeftside,
    IconWrapper,
    CardBottomContainer,
    CardBottomLeftSide,
    CardBottomRightSide,
    BoostButtonWrapper,
    RocketIconWrapper,
    TopText,
    AddTagWrapper,
    AddTagLeftSideDivide,
    AddTagDivideLeft,
    AddTagDivide,
    AddTagHeaderWrapper,
    ArrowSpanWrapper,
    TagCardWrapper,
    TagCardtopWrapper,
    LeftSideWrapper,
    BoxSquare,
    TagCardBody,
    TagCardItem,
    AItag,
    DaysLeft,
    PrivilegedSide,
    ConfirmButton,
    AddTagLeftRightDivide,
    AssetsHeader,
    AssetType,
    AssetsContainer,
    AssetsBody,
    PrivilegedItem,
    ItemLeftSide,
    Amount,
    RightSide,
    SponsoredBottom,
    SponsoredBottomSide,
    SponsoredItemWrapper,
    TicketNumber,
    ModalTitleStyles,
    ModalCloseStyles,
    DurationModalMiddle,
    DaysLeftModal,
    BoxStyles,
    ConfirmButtonModal,
    MapWrapper,
    NoteWrapper,
    PrivilegedSettingModalWrapper,
    MiddlebodyWrapper,
    PrivilegedModalItem,
    PrivilegedModalPrivacyItem,
    CircleModal,
    PrivacyText


} from './Styled';
import { ArrowBack } from '../../../styles/Styled';
import CarouselComponent from '../CarouselComponent';
import IconNew from '../IconNew';
import { numberFormatter } from '../../../Utils';
import { withRouter } from 'react-router-dom';
import Map from './addTagAssets/Map';
import Modal from '../Modal';


class  MyListingsStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagInputValue: '',
            tags: [],
            showstatType: { text: 'Profile Views', value: 1 },
            showstatTime:{ text: 'Daily', value: 1 },
            isBoostVisible: false,
            currentIndex: 5,
            modalOpen:false,
            modalOpen1:false,
            modalOpen2:false,
            isActive:null

        };   

        this.privilegedModalItems = [
            'Hide it',
            'Make it public'
        ]
        
        this.fakeData= {
            privileged:{
                total:2,
                available:1,
            },
            featured:{
                startDate:'December 17, 1995 ',
                total:13,
                Available:5,
            },
            sponsored: { 
                tickets: [
                    {number:'94105', days:'10',},
                    {number:'94105', days:'13',},
                    {number:'94105', days:'7',}
                ]
            }
        }
    }
    

    componentDidMount() {
        if(this.props.id !== null) {
            this.setState({
                currentIndex:this.props.id * 1
    
            })
        }
    }



     Modals = () => {
        return (
            <Modal
            isOpen={this.state.modalOpen}
            handleClose={() => this.setState({ modalOpen: false })}
            containerWidth='375'
            containerHeight='260'
            containerSizeUnit="px"
            containerPadding="0"
            title='Please select duration'
            titleStyled={ModalTitleStyles}
            closeStyled={ModalCloseStyles}
            >
                <DurationModalMiddle>
                    <DaysLeftModal>
                        You already have <span>5</span> days left
                    </DaysLeftModal>
                    <BoxStyles>
                        <BoxSquare>
                            -
                        </BoxSquare>
                        <div>
                            <span className='amount'>
                                3
                            </span>
                            <span className='day'>
                                day
                            </span>
                        </div>
                        <BoxSquare>
                            +
                        </BoxSquare>
                    </BoxStyles>
                    <ConfirmButtonModal marginTop={41}>
                        Confirm
                    </ConfirmButtonModal>
                </DurationModalMiddle>
            </Modal>
        )
    }

    Modal2 = () => {
        return (
            <Modal
            isOpen={this.state.modalOpen1}
            handleClose={() => this.setState({ modalOpen1: false })}
            containerWidth='375'
            containerHeight='615'
            containerSizeUnit="px"
            containerPadding="0"
            title='Change privileged setting'
            titleStyled={ModalTitleStyles}
            closeStyled={ModalCloseStyles}
            >
                <PrivilegedSettingModalWrapper>
                    <NoteWrapper>
                        <span>
                            Note:
                        </span>
                        {' Please note that listing title,\n description and cover photo will remain\n public even if the listing record is\n privileged.'}
                    </NoteWrapper>
                    <MiddlebodyWrapper>
                        <PrivilegedModalItem>
                            <span className='left'>
                                Property Record
                            </span>
                            <div>
                                <span>
                                    Please Select
                                </span>
                                <span>
                                    h
                                </span>
                            </div>
                        </PrivilegedModalItem>
                        <PrivilegedModalItem>
                            <span className='left'>
                                Price
                            </span>
                            <div>
                                <span>
                                    Please Select
                                </span>
                                <span>
                                    h
                                </span>
                            </div>
                        </PrivilegedModalItem>
                        <PrivilegedModalItem>
                            <span className='left'>
                                Address
                            </span>
                            <div>
                                <span>
                                    Please Select
                                </span>
                                <span>
                                    h
                                </span>
                            </div>
                        </PrivilegedModalItem>
                        <PrivilegedModalItem>
                            <span className='left'>
                                Privileged&reg; Photos
                            </span>
                            <div>
                                <span>
                                    Please Select
                                </span>
                                <span>
                                    h
                                </span>
                            </div>
                        </PrivilegedModalItem>
                        <PrivilegedModalItem>
                            <span className='left'>
                                Documents
                            </span>
                            <div>
                                <span>
                                    Please Select
                                </span>
                                <span>
                                    h
                                </span>
                            </div>
                        </PrivilegedModalItem>
                        <ConfirmButtonModal marginTop={35.5}>
                            Confirm
                        </ConfirmButtonModal>
                    </MiddlebodyWrapper>
                </PrivilegedSettingModalWrapper>
            </Modal>
        )
    }

    modal3 = () => { 
        return (
            <Modal
            isOpen={this.state.modalOpen2}
            handleClose={() => this.setState({ modalOpen2: false })}
            containerWidth='375'
            containerHeight='398'
            containerSizeUnit="px"
            containerPadding="0"
            title='Change privileged setting'
            titleStyled={ModalTitleStyles}
            closeStyled={ModalCloseStyles}
            >
                <PrivilegedSettingModalWrapper>
                    <NoteWrapper>
                        <span>
                            Note:
                        </span>
                        {' Please note that listing title,\n description and cover photo will remain\n public even if the listing record is\n privileged.'}
                    </NoteWrapper>
                    <MiddlebodyWrapper>
                        {this.privilegedModalItems.map((item, index) => {
                            let activeTab = this.state.isActive === index;
                            return (
                                <PrivilegedModalPrivacyItem key={index}>
                                    <CircleModal active={activeTab} onClick={() => this.setState({isActive: index})} />
                                    <PrivacyText>
                                        {item}
                                    </PrivacyText>
                                </PrivilegedModalPrivacyItem>
                            );
                        })}
                    </MiddlebodyWrapper>
                    <ConfirmButtonModal marginTop={31}>
                        Confirm
                    </ConfirmButtonModal>
                </PrivilegedSettingModalWrapper>
            </Modal>
        )
    }

    cardTagWrapper = (type) => {
        const color = () =>{
            if(type === 'Featured') {
                   return props => props.theme.darkBlue
            }
            if(type === 'Hot offer') {
                return props => props.theme.counterBg
            }
            if(type === 'Price Reduced') {
                return props => props.theme.noticeGreen
            }
        }
        return (
            <TagCardWrapper colorBorder={color}>
                {
                    type !== 'Featured' && (
                        <AItag AIcolor={color}>
                            AI Tag
                        </AItag>
                    )
                }
                <TagCardtopWrapper>
                    <div>
                        <span>
                            {type}
                        </span>
                        {
                        type !== 'Featured' && (
                            <span style={{marginLeft:'25.2px'}}>
                                h
                            </span>
                        )
                        }
                    </div>
                    {
                        type === 'Featured' && (
                            <div className='plus-minus'>
                                <BoxSquare>
                                    -
                                </BoxSquare>
                                <span className='amountclass'>
                                    <span className='amountDays'>5</span> days
                                </span>
                                <BoxSquare>
                                    +
                                </BoxSquare>
                            </div>
                        )
                    }
                    {type !== 'Featured' && (
                        <DaysLeft>
                            <span>
                                5
                            </span>
                            Days
                        </DaysLeft>
                    )}
                </TagCardtopWrapper>
                <TagCardBody>
                    <TagCardItem>
                        <span className='datetype'>
                            Start Date
                        </span>
                        <span className='date'>
                            16 Nov 2020
                        </span>
                    </TagCardItem>
                    <TagCardItem>
                        <span className='datetype'>
                            End Date
                        </span>
                        <span className='date'>
                            16 Nov 2020
                        </span>
                    </TagCardItem>
                </TagCardBody>
            </TagCardWrapper>
        );
    }

    render() {
    return (
        <React.Fragment>
            <AddTagWrapper>
                <AddTagDivideLeft>
                    <AddTagHeaderWrapper>
                    <ArrowSpanWrapper>
                        <ArrowBack onClick={() => this.props.backButtonHandler()}  />
                    </ArrowSpanWrapper> 
                    <span>
                        Add Tag to Listing
                    </span>
                    </AddTagHeaderWrapper>
                    <LeftSideWrapper>
                    <AddTagLeftSideDivide>
                        {this.props.data.filter((item, index) => index === this.state.currentIndex).map((item,index)=> {
                                return (
                                    <CardWrapper moreMarginTop key={index}>
                                    <CardTopWrapper>
                                        <CardtopItem>
                                            <TopTextWrapper>
                                                <IconNew
                                                bp1="-470"
                                                bp2="-146"
                                                iwidth="12"
                                                iheight="12"
                                            />
                                            <TopText>
                                                {numberFormatter(item.likes)}
                                            </TopText>
                                            </TopTextWrapper>
                                    <RightBorder />
                                    </CardtopItem>
                                    <CardtopItem>
                                        <TopTextWrapper>
                                            <IconNew
                                                bp1="-447"
                                                bp2="-146"
                                                iwidth="20"
                                                iheight="12"
                                            />
                                            <TopText>
                                                {numberFormatter(item.views)}
                                            </TopText>
                                        </TopTextWrapper>
                                        <RightBorder />
                                    </CardtopItem>
                                    <CardtopItem>
                                        <TopTextWrapper>
                                            <IconNew
                                                bp1="-447"
                                                bp2="-177"
                                                iwidth="16"
                                                iheight="15"
                                            />
                                            <TopText>
                                                {numberFormatter(item.comments)}
                                            </TopText>
                                        </TopTextWrapper>
                                        <RightBorder />
                                    </CardtopItem>
                                    <CardtopItem>
                                        <TopTextWrapper>
                                        <IconNew
                                            bp1="-470"
                                            bp2="-159"
                                            iwidth="12"
                                            iheight="14"
                                        /> 
                                        <TopText>
                                            {numberFormatter(item.shares)}
                                        </TopText>
                                        </TopTextWrapper>
                                    </CardtopItem>
                                </CardTopWrapper>
                            <CarouselComponent 
                                pictureHeight='275'
                                pictureWidth='400'
                                onPictureClickHandler={() => null}
                                inlineCircles 
                                data={item.photos} 
                            />
                            <CarouselMiddleContainer withBorders> 
                                <CarouselMiddleTopSide>
                                    <CarouselTopSideHeaderText>
                                        Best Property in Thes Area
                                    </CarouselTopSideHeaderText>
                                    <IconWrapper>
                                    <IconNew
                                        bp1="-396"
                                        bp2="-220"
                                        iwidth="13"
                                        iheight="14"
                                    /> 
                                    </IconWrapper>
                                </CarouselMiddleTopSide>
                                <CarouselMiddleSide>
                                    <CarouselMiddlePriceText>
                                        $3,400 / m
                                    </CarouselMiddlePriceText>
                                    <CarouselMiddleRightSideText>
                                        <RightSideText>
                                            <RighttSideTextNumber>4 </RighttSideTextNumber>{' '} BED
                                            <RightSideTextBorder />
                                        </RightSideText>
                                        <RightSideText>
                                            <RighttSideTextNumber>2 </RighttSideTextNumber>{' '}BATH
                                            <RightSideTextBorder />
                                        </RightSideText>
                                        <RightSideText lastItem>
                                        <RighttSideTextNumber>2,750 </RighttSideTextNumber>{' '} sqft
                                        </RightSideText>
                                    </CarouselMiddleRightSideText>
                                </CarouselMiddleSide>
                                <CarouselMiddleBottomSide>
                                    <CardMiddlebottomLeftside>
                                        <IconWrapper>
                                        <IconNew
                                            bp1="-172"
                                            bp2="-115"
                                            iwidth="9"
                                            iheight="13"
                                            className="heart"
                                        />  
                                        </IconWrapper>
                                        <CarouselBottomAddressText>
                                            548 Market St, San Francisco
                                        </CarouselBottomAddressText>
                                    </CardMiddlebottomLeftside>
                                </CarouselMiddleBottomSide>
                            </CarouselMiddleContainer>
                            <CardBottomContainer>
                                <CardBottomLeftSide>         
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />    
                                        <IconNew
                                        bp1="-229"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />  
                                    <IconNew
                                        bp1="-257"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                </CardBottomLeftSide>
                                <CardBottomRightSide>
                                    <BoostButtonWrapper onClick={() => this.boostButtonHandler(item.id, index)}>
                                        Boost <RocketIconWrapper>h</RocketIconWrapper>
                                    </BoostButtonWrapper>
                                </CardBottomRightSide>
                            </CardBottomContainer>                  
                                </CardWrapper>
                                );  
                    })} 
                    </AddTagLeftSideDivide>
                    <AddTagLeftRightDivide>
                        <PrivilegedSide>
                            <div>
                                <span>
                                    Privileged&reg;	
                                </span>
                            </div>
                            <button>
                                hmm
                            </button>
                        </PrivilegedSide>
                        {this.cardTagWrapper('Featured')} 
                        {this.cardTagWrapper('Hot offer')} 
                        {this.cardTagWrapper('Price Reduced')} 
                        <ConfirmButton>
                            Confirm
                        </ConfirmButton>
                    </AddTagLeftRightDivide>
                    </LeftSideWrapper>
                </AddTagDivideLeft>
                <AddTagDivide className='right'>
                    <AssetsContainer>
                        <AssetsHeader>
                            <span>
                                Your Assets
                            </span>
                            <RightSide>
                                <AssetType>
                                    <span>
                                        Total
                                    </span>
                                </AssetType>
                                <AssetType morePadding rightSide>
                                    <span>
                                        Available
                                    </span>
                                </AssetType>
                            </RightSide>
                        </AssetsHeader >
                        <AssetsBody>
                            <PrivilegedItem>
                                <ItemLeftSide>
                                    Privileged&reg;	
                                </ItemLeftSide>
                                <RightSide>
                                    <Amount>
                                        2
                                    </Amount>
                                    <Amount customWidth={'82px'}>
                                        <span>1</span>
                                        <span>-</span>
                                    </Amount>
                                </RightSide>
                            </PrivilegedItem>
                            <PrivilegedItem>
                                <ItemLeftSide>
                                    Featured	
                                </ItemLeftSide>
                                <RightSide>
                                    <Amount>
                                        2
                                    </Amount>
                                    <Amount customWidth={'82px'}>
                                    <span>
                                        1
                                    </span>
                                    <span>
                                        +
                                    </span>
                                    </Amount>
                                </RightSide>
                            </PrivilegedItem>
                            <PrivilegedItem>
                                <ItemLeftSide>
                                    <span className='horizontaline' />
                                    Sponsored	
                                </ItemLeftSide>
                                <RightSide>
                                    <Amount>
                                        2
                                    </Amount>
                                    <Amount customWidth={'82px'}>
                                        <span>
                                            1
                                        </span>
                                        <span>
                                            h
                                        </span>
                                    </Amount>
                                </RightSide>
                            </PrivilegedItem>
                            <SponsoredBottom>
                                <div className='verticalline' />
                                    {this.fakeData.sponsored.tickets.map((item, index) => {
                                        return (
                                            <SponsoredItemWrapper key={index}>
                                                <SponsoredBottomSide>
                                                    <MapWrapper>
                                                        <Map />
                                                    </MapWrapper>
                                                    <TicketNumber>
                                                        {item.number}
                                                    </TicketNumber>
                                                    </SponsoredBottomSide>
                                                    <SponsoredBottomSide className={'right'}>
                                                    <span className='amountdays'>
                                                        {item.days} <span className='days'>days</span>
                                                    </span>
                                                    <span>
                                                        h
                                                    </span>
                                                    </SponsoredBottomSide>
                                            </SponsoredItemWrapper>
                                        );
                                    })}
                                {/*  <SponsoredBottomSide>
                                        <Map  />
                                        941107 
                                    </SponsoredBottomSide>
                                    <SponsoredBottomSide>
                                        <span>
                                            10 days
                                        </span>
                                        <span>
                                            h
                                        </span>
                                    </SponsoredBottomSide> */}           
                            </SponsoredBottom>
                            <div onClick={() => this.setState({modalOpen: true})}>
                                click for unknown modal
                            </div>
                            <div onClick={() => this.setState({modalOpen1: true})}>
                                modal 2
                            </div>
                            <div onClick={() => this.setState({modalOpen2: true})}>
                                modal 3
                            </div>
                        </AssetsBody>
                    </AssetsContainer>
                </AddTagDivide>     
            </AddTagWrapper>
            {this.Modals()}
            {this.Modal2()}
            {this.modal3()}
        </React.Fragment>
    );
        }
}

export default withRouter(MyListingsStatistics);
