import styled from 'styled-components';

export const InputLabel = styled.label`
    font-size: 16px;
    margin-bottom:-10px;
    color: ${(props) => props.theme.textGrayLight};
    & > span {
        color: red;
    }
    ${(props) => props.styled};
`;

export const InputLabelAnimate = styled.label`
    display: block;
    position: relative;
    height: 45px;
    bottom: 45px;
    margin-bottom: -45px;
    transition: all 0.15s ease-in-out;
    color: ${(props) => props.theme.textGrayLight};
    ${(props) => props.styled};
    & > span {
        color: red;
    }
    ${(props) => (props.dropdown ? ` cursor: pointer; ` : ` cursor: text; `)};

    @media ${props => props.theme.mediaTabletLandscape} {
        height: 25px;
    }
    @media ${props => props.theme.mediaTablet} {
        height: 25px;
    }
    @media ${props => props.theme.mediaMobile} {
        height: 25px;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    height: 45px;
    margin-bottom:${props => (props.keyy === 'regPassword' || props.keyy === 'newPass' && props.isError) && '83px ! important'};
    & > span {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 20;
        &.clickable {
            cursor: pointer;
        }
        ${(props) => props.dropdown && ` pointer-events: none; `};
    }
    & > div {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 20;
        padding-bottom: 10px;
        &.clickable {
            cursor: pointer;
        }
        span {
            margin-right: 6px;
            position: relative;
            top: 3px;
        }
    }
    ${(props) => props.styled};
`;

export const InputText = styled.input`
    font-family: ${(props) => props.theme.mainFont};
    box-sizing: border-box;
    box-shadow: none; // Remove FirFox Red Outline
    border-radius: 0;
    ${(props) => props.styled};
    ${(props) => props.extraStyled};
    &:read-only + label {
        ${(props) => props.readOnlyValue && props.animateStyled};
    }
    &:valid + label,
    &:focus + label {
        ${(props) => props.animateStyled};
    }
    ${(props) =>
        props.isLabelAnimate &&
        `
            &:valid + label,
            &:focus + label {
                
            }
            `};
    ${(props) => props.dropdown && ` cursor: pointer; `};
`;

export const ErrorMessage = styled.p`
    position: absolute;
    bottom: -25px;
    display:block;
    left: 0;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    color: ${(props) => props.theme.errorMessage};

    @media ${(props) => props.theme.mediaMobile} {
        bottom: -40px;
    }
`;
