import React, {Component} from 'react';
import Loading from '../Loading';
import {Button} from './Styled';

export default class FormButtonText extends Component {
    render() {
        return (
            <Button
                onClick={this.props.onClick}
                styled={this.props.styled}
                {...this.props}
            >
                {this.props.isLoading ? <Loading iSize="1x"/> : this.props.children}
            </Button>
        )
    }
}