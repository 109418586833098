import React, { Component } from 'react';
import { 
    ProfileMenuVerificationOfficialLicensesWrapper,
    ProfileVerificationHeaderWrapper,
    BackButton,
    ProfileMenuVerificationHeader,
    ContentWrapper,
    TopCard,
    LowerButtonsWrapper,
    SaveButton,
    CancelButton,
    FooterWrapper,
    FooterItemTextStyle,
    FooterItemCompanyTextStyle,
    InputDropDownWrapperStyles,
    InputTextDetailsStyles,
    ButtonWrapper,
    PlusSpan
} from './Styled';
import { ArrowBack } from "../../../styles/Styled";
import FormUploadFileCrop from '../FormUploadFileCrop';
import FormInputDropDown from "../FormInputDropDown";

export class ProfileMenuVerificationOfficialLicenses extends Component {
    constructor(props) {
        super(props);

        this.footerItems = [
            {item:'About us', link:''},
            {item:'Help', link:''},
            {item:'Jobs', link:''},
            {item:'Privacy Policy', link:''},
            {item:'Terms', link:''},
        ];

        this.state = {
            list  : [
                {text:'Real Estate Agent',
                value:1,
                },
            ]
        }
    }

    dropDownValue = (value,text, img) => {
        console.log('DropDown Value = ' + value);
        this.setState({
            currentImage: img
        })
    };

    onAddMoreHandler = () => {
        this.setState(previousState => ({
            list: [...previousState.list, {text:'', value:this.state.list.length + 1}]
        }));
    }
    render() {
        return (
            <ProfileMenuVerificationOfficialLicensesWrapper>
                <ProfileVerificationHeaderWrapper onClick={this.props.BackCurrentPage}>
                    <BackButton >
                        <ArrowBack />
                        <ProfileMenuVerificationHeader>Back</ProfileMenuVerificationHeader>
                    </BackButton>
                </ProfileVerificationHeaderWrapper>
                <ContentWrapper>
                    {this.state.list.map((item,index) => {
                            return (
                                <TopCard>
                                    <FormUploadFileCrop align={'center'} />
                                    <FormInputDropDown
                                        id="selectCategory2"
                                        type="text"
                                        name="selectCategory2"
                                        placeholder={item.text}
                                        styled={InputTextDetailsStyles}
                                        wrapperStyled={InputDropDownWrapperStyles}
                                        dropDownValue={(value) => this.dropDownValue(value)}
                                        list={this.state.list}
                            />
                                </TopCard>
                            );
                })}
                        <ButtonWrapper onClick={this.onAddMoreHandler}>
                            <PlusSpan>+</PlusSpan> Add More
                        </ButtonWrapper>
                    <LowerButtonsWrapper>
                        <SaveButton>
                            Save
                        </SaveButton>
                        <CancelButton>
                            Cancel
                        </CancelButton>
                    </LowerButtonsWrapper>
                    <FooterWrapper>
                        <div>
                            {this.footerItems.map((item,index) => (
                                <FooterItemTextStyle key={index} last={index} first={index === 0} >
                                    {item.item} {index !== 4  && '|'}
                                </FooterItemTextStyle>
                            ))}
                        </div>
                        <FooterItemCompanyTextStyle>
                            © 2020 RealtyFeed
                        </FooterItemCompanyTextStyle>
                    </FooterWrapper>
                </ContentWrapper>
            </ProfileMenuVerificationOfficialLicensesWrapper>
        )
    }
}

export default ProfileMenuVerificationOfficialLicenses
