import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoadingWrapper = styled.div`
    text-align: center;
    margin-top: 5px;
    padding: 5px;
`;

export const InsidePageTitle = styled.h2`
    border-bottom: 1px solid rgb(39, 39, 39);
    font-size: 30px;
    color: rgb(39, 39, 39);
`;

export const UL = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const LiInlineBlock = styled.li`
    display: inline-block;
`;

export const AppLink = styled(Link)`
    text-decoration: none;
`;

export const MainConrainer = styled.div`
    width: 1170px;
    margin: 0 auto;

    @media only screen and (max-width: 1169px) {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    ::after {
        clear: both;
        display: block;
        content: '';
    }
`;

export const InsideConrainer = styled(MainConrainer)`
    padding-top: 25px;
`;

export const RightColumnStyle = styled.div`
    width: 370px;
    float: right;

    @media only screen and (max-width: 1169px) {
        width: auto;
        float: none;
        padding-top: 15px;
    }
`;

export const LeftColumn = styled.div`
    width: 770px;
    float: left;

    @media only screen and (max-width: 1169px) {
        width: auto;
        float: none;
        padding-top: 15px;
    }
`;

export const ListingWrapper = styled.div`
    background-color: ${(props) =>
        props.hilighWrapper
            ? 'rgba(112,112,112,0.15)'
            : 'rgba(112,112,112,0.05)'};
    border-radius: 5px;
    padding-bottom: 20px;
`;

export const ListingLikesNumberWrapper = styled.div`
    padding: 0 15px;
`;

export const UserListRow = styled.div`
    padding: 10px;

    &:nth-child(even) {
        background-color: rgba(46, 128, 246, 0.05);
    }

    ::after {
        clear: both;
        content: '';
        display: block;
    }
`;

export const GalleryModeWrapper = styled.div`
    margin: 0 -5px;

    ::after {
        clear: both;
        content: '';
        display: block;
    }
`;

//Center Form
export const CenterForm = styled.div`
    margin: 0 auto;
    width: ${(props) => props.wrapperWidth || '370'}px;

    @media only screen and (max-width: 600px) {
        margin: 5% auto;
        width: 90%;
    }
`;
export const CenterFormWrapper = styled.div`
    background-color: #ffffff;
    padding: 30px 40px;
    border-radius: 5px;
`;
export const CenterFormTitle = styled.p`
    color: #707070;
    text-align: center;
`;

export const IconButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
`;

//SettingsNew Forms
export const SettingsFormRow = styled.div`
    display: flex;
    margin: 0 -15px 40px;

    @media only screen and (max-width: 1169px) {
        display: block;
        margin-bottom: 0;
    }
`;

export const SettingsFormRowFlexEnd = styled(SettingsFormRow)`
    flex-direction: column;
    align-items: flex-end;
`;

export const SettingsFormColumn = styled.div`
    width: ${(props) => props.wrapperWidth || '50'}%;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;

    &::after {
        content: '';
        height: 1px;
        left: 15px;
        right: 15px;
        bottom: 0px;
        position: absolute;
        background-color: #${(props) => (props.iErrorMessage ? 'ff9c9c' : '707070')};
    }

    @media only screen and (max-width: 1169px) {
        width: 100%;
        margin-bottom: 10px;
    }
`;

export const SettingsFormButton = styled.button`
    border: none;
    margin-right: 15px;
    background-color: rgb(112, 112, 112);
    color: #ffffff;
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;

    @media only screen and (max-width: 1169px) {
        float: right;
    }
`;
export const SettingsFormMessage = styled.div`
    color: ${(props) =>
        props.iMessageType === 'success' ? '#0cb000' : '#a62700'};
    margin-top: 20px;
    margin-right: 15px;
`;
export const SettingsFormSelect = styled.select`
    width: 100%;
    border: none;
    background: #ffffff;
    color: rgb(193, 193, 193);
    font-size: 17px;
    padding: 7px 0;
    height: 35px;
`;
export const SettingsAddPlusButton = styled.button`
    background-color: rgb(112, 112, 112);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 4px;
    cursor: pointer;
    padding: 2px 8px 4px;
    line-height: 24px;
    > span {
        font-size: 20px;
        font-weight: 300;
    }
`;

/* Action Modal */

export const ActionModalWrapper = styled.span`
    padding-top: 9px;
`;
export const ActionModalWrapperNoBorder = styled.div`
    > div:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
`;
export const ActionModalRow = styled.div`
    display: block;
    margin: 0 -20px;
    border-bottom: 1px solid rgb(154, 153, 153);
    text-align: center;
    padding: 8px 0;
`;
export const ActionModalRowEnd = styled(ActionModalRow)`
    border-bottom: none;
    padding-bottom: 0;
`;

export const ActionModalButton = styled(IconButton)`
    color: rgb(154, 153, 153);
    font-size: 15px;
`;
export const ActionModalLink = styled(AppLink)`
    color: rgb(154, 153, 153);
    font-size: 15px;
`;

export const Progress = styled.div`
    height: 6px;
    background: rgb(159, 159, 159);
    position: relative;
    border-radius: 5px;

    ::after {
        position: absolute;
        left: 0;
        top: 0;
        width: ${(props) => props.iProgress || '0'}%;
        height: 6px;
        background: rgb(112, 112, 112);
        border-radius: 5px;
        content: '';
    }
`;
export const NoData = styled.div`
    text-align: center;
    padding: 20px 0;
`;
export const NoDataText = styled.div`
    color: rgb(112, 112, 112);
    margin-top: 20px;

    > b {
        color: #000000;
    }
`;

export const PageTitle = styled.h1`
    color: rgb(39, 39, 39);
`;

export const PageInsideTitle = styled.h2`
    color: rgb(39, 39, 39);
`;

export const PageContent = styled.div`
    color: rgb(112, 112, 112);
    padding-bottom: 50px;
`;

/*
 * New Design
 */
export const Main = styled.main`
    width: 1810px;
    margin: 0 auto;
    ${(props) =>
        props.backgrounded &&
        `
            padding-top: 52px;
            background: #fff;
            border-radius: 10px;
            `};
    @media ${(props) => props.theme.mediaHD} {
        width: 1274px;
    }
`;

export const Column = styled.div`
    width: ${(props) => {
        switch (props.type) {
            case 'one_column':
                return 530;
            case 'one_column_right_fixed':
                return 560;
            case 'one_column_right_fixed_simple':
                return 500;
            case 'one_column_setting':
                return 1260;
            case 'two_column':
                return 1130;
            case 'two_column_container':
                return 1162;
            case 'two_column_container_big':
                return 1260;
            case 'feed_column':
                return 1260;
            default:
                return 0;
        }
    }}px;

    ${(props) => props.padding_right && 'padding-right: 500px'};
    ${(props) => props.scrollable && 'overflow-y: scroll; overflow-x: hidden'};
    margin: 0 auto;
    ${(props) =>
        props.type === 'two_column_container' ||
        props.type === 'two_column_container_big' ||
        props.type === 'feed_column'
            ? ''
            : props.first
                ? 'margin-left: 25px; float: left'
                : 'float: right; margin-right: 30px'};

    ${(props) => props.right && 'float: right'};
    ${(props) =>
        props.type === 'one_column_right_fixed' &&
        `
            margin-left: 0;
            float: none;
            width: 560px;
            position: fixed;
            top: 153px; right: 0; bottom: 13px;
            border-radius: 40px 0 0 0;
            border: solid 1px ${props.theme.grayDarkBorder};
            border-right: none;
            background: #fff;
            `};
    ${(props) =>
        props.type === 'one_column_right_fixed_simple' &&
        `
            margin-left: 0;
            float: none;
            width: 500px;
            position: fixed;
            top: 100px; right: 0; bottom: 0;
            box-shadow: -4px 0 10px 0 rgba(0, 0, 0, 0.16);
            background: #fff;
            `};
    ${(props) => props.noMargin && ` margin-left: 0; `};
    ${(props) =>
        props.background && `background: ${props.theme[props.background]}`};

    @media ${(props) => props.theme.mediaHD} {
        width: ${(props) => {
            switch (props.type) {
                case 'one_column':
                    return 360;
                case 'one_column_right_fixed':
                    return 560;
                case 'one_column_right_fixed_simple':
                    return 400;
                case 'one_column_setting':
                    return 830;
                case 'two_column':
                    return 820;
                case 'two_column_container':
                    return 1162;
                case 'two_column_container_big':
                    return 1260;
                case 'feed_column':
                    return 830;
                default:
                    return 0;
            }
        }}px;
        ${(props) =>
            props.type === 'two_column_container' ||
            props.type === 'two_column_container_big' ||
            props.type === 'feed_column'
                ? ''
                : props.first
                    ? 'margin-left: 5px; float: left'
                    : 'float: right; margin-right: 30px'};
    }
`;

export const TabContent = styled.div`
    margin-top: ${(props) => (props.margin ? props.margin : 50)}px;
`;

export const MainContentBg = styled.div`
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    width: 1260px;
    overflow: hidden;

    @media ${(props) => props.theme.mediaHD} {
        width: 830px;
    }
`;

export const ArrowBack = styled.i`
    display: inline-block;
    background: ${(props) => props.theme.darkBlue};
    height: 2px;
    width: 30px;
    position: relative;
    cursor: pointer;
    top: -10px;
    margin-right: 12px;
    &:before,
    &:after {
        content: '';
        background: ${(props) => props.theme.darkBlue};
        position: absolute;
        height: 2px;
        width: 12px;
    }
    &:before {
        right: 22px;
        bottom: 4px;
        transform: rotate(-45deg);
    }
    &:after {
        right: 22px;
        top: 4px;
        transform: rotate(45deg);
    }
`;
