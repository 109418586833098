import React, { Component } from 'react';
import {
    ProfileMenuVerificationWrapper,
    VerificationStepsWrapper,
    VerificationStepsRightSide,
    VerificationStepsLeftSide,
    VerificationStatusHeader,
    StepWrapper,
    StepBottom,
    StepTop,
    PendingWrapper,
    ArrowRightWrap,
    VerifyButton,
    VerifyButtonText,
    OKButtonStyles,
    ModalWrapper,
    VerificationWrapper,
    ModalHeader,
    ModalTitleStyles,
    ModalCloseStyles,
    IdModalBottom,
    IDModalWrapper,
    IdModalTop,
    IDModalText,
} from './Styled';
import Modal from '../Modal';
import VerificationInput from '../VerificationInput';
import ProfileMenuVerificationID from '../ProfileMenuVerificationID';
import ProfileMenuVerificationOfficialLicenses from '../ProfileMenuVerificationOfficialLicenses';
import IconNew from '../IconNew/IconNew';
import ButtonText from '../FormButtonText';
import ProfileMenuVerificationAddress from '../ProfileMenuVerificationAddress';
import ProfileMenuContent from '../ProfileMenuContent';

export class ProfileMenuVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            modalTitle: '',
            modalWidth: 0,
            currentPage: 0,
        };
        this.pagesToShow = {
            profileMenuPage: 0,
            IDpage: 1,
            professionalLicense: 2,
            Addresspage: 3,
        };
        this.stepList = [
            {
                verificationHeader: {
                    name: 'Personal Verification',
                    status: '70',
                },
                steps: [
                    {
                        name: 'Email',
                        credential: 'samanthasmith@gmail.com',
                        editable: true,
                        status: 'verified',
                        id: 0,
                    },
                    {
                        name: 'Phone',
                        credential: '+989022261378',
                        editable: true,
                        status: 'notVerified',
                        id: 1,
                    },
                    {
                        name: 'Address',
                        credential: '31n. Boorklyn, NYC',
                        status: 'pending',
                        id: 2,
                    },
                    { name: 'ID Verification', status: 'pending', id: 3 },
                ],
            },
            {
                verificationHeader: {
                    name: 'Professional Verification',
                    status: '30',
                },
                steps: [
                    {
                        name: 'Professional Email',
                        credential: 'samanthasmith@realty.com',
                        status: 'notVerified',
                        editable: true,
                        id: 4,
                    },
                    { name: 'Professional Licenses', status: {}, id: 5 },
                    { name: 'Professional Memberships', status: {}, id: 6 },
                ],
            },
        ];
        this.professionalItems = [
            'Your account will appear in search results and user suggestions',
            'Your listings will appear in property search results as well as feed generation for related users who are not necessarily your connections and followers (permission based).',
            'Your posts will appear in the feed of other users who are not necessarily your connections and followers (permission based).',
            'You can create professional groups such as Real Estate brokerage, association, MLS, etc (other terms and conditions apply).',
            'You will receive a professional sinage on top of your profile page, which ensures your profile visitors about your credibility as a professional.',
        ];
    }

    onHelpClick = (name) => {
        this.setState({
            modalOpen: true,
            modalTitle: name,
            modalWidth: 470,
        });
    };

    onVerifyClick = (id) => {
        if (id === 1) {
            const modalName = 'Phone Confirmation';
            this.setState({
                modalOpen: true,
                modalTitle: modalName,
                modalWidth: 375,
            });
        }
    };

    handleVerificationFill = () => {
        this.setState({ modalOpen: false });
    };

    onClickHeader = (index, id) => {
        if (id === 3) {
            const modalName = 'Please select an option';
            this.setState({
                modalOpen: true,
                modalTitle: modalName,
                modalWidth: 375,
            });
        } else if (id === 5) {
            this.setState({
                currentPage: 2,
            });
        } else if (id === 6) {
            this.setState({
                currentPage: 3,
            });
        }
    };

    IDVerificationHandler = () => {
        this.setState({
            currentPage: 1,
            modalOpen: false,
        });
    };

    BackCurrentPage = () => {
        this.setState({
            currentPage: 0,
        });
    };

    modalHandler = () => {
        switch (this.state.modalTitle) {
            case 'Phone Confirmation':
                return (
                    <ModalWrapper>
                        <ModalHeader>
                            Please enter the verification code
                        </ModalHeader>
                        <VerificationWrapper>
                            <VerificationInput
                                length={6}
                                number={this.stepList[0].steps[1].credential}
                                type={1}
                                onComplete={this.handleVerificationFill}
                            />
                        </VerificationWrapper>
                    </ModalWrapper>
                );
            case 'Please select an option':
                return (
                    <IDModalWrapper>
                        <IdModalTop onClick={this.IDVerificationHandler}>
                            <div>icon</div>
                            <IDModalText>Upload National ID card</IDModalText>
                        </IdModalTop>
                        <IdModalBottom>
                            <div>icon</div>
                            <IDModalText onClick={this.IDVerificationHandler}>
                                Driving Licenses
                            </IDModalText>
                        </IdModalBottom>
                    </IDModalWrapper>
                );
            case 'Personal Verification':
                return (
                    <ModalWrapper>
                        <p>
                            In order to receive the verification checkmark, we
                            need to verify your identity. Please note that for
                            non-professional and non-premium users, this process
                            may take up to one year.
                        </p>
                        <IconNew
                            bp1="0"
                            bp2="-650"
                            iwidth="160"
                            iheight="160"
                        />
                        <ButtonText
                            styled={OKButtonStyles}
                            onClick={() => this.setState({ modalOpen: false })}
                        >
                            OK
                        </ButtonText>
                    </ModalWrapper>
                );
            case 'Professional Verification':
                return (
                    <ModalWrapper>
                        <h6>
                            Verification is required for activating your account
                            as a verified professional. The result will be:
                        </h6>
                        {this.professionalItems.map((item, index) => (
                            <h6 key={index}>
                                <span>{index + 1}</span>
                                {item}
                            </h6>
                        ))}
                        <h6>
                            Please note that for non-premium users, this process
                            may take up to one year.
                        </h6>
                        <ButtonText
                            styled={OKButtonStyles}
                            onClick={() => this.setState({ modalOpen: false })}
                        >
                            OK
                        </ButtonText>
                    </ModalWrapper>
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <ProfileMenuContent
                title={'Verification Process'}
                onTitleClick={this.props.backButton}
            >
                {this.state.currentPage === 0 && (
                    <ProfileMenuVerificationWrapper>
                        <div>
                            {this.stepList.map((item, index) => (
                                <div key={index} style={{ marginTop: '30px' }}>
                                    <VerificationStepsWrapper>
                                        <VerificationStepsLeftSide>
                                            <VerificationStatusHeader>
                                                {item.verificationHeader.name}
                                                {'  '}
                                                <IconNew
                                                    bp1="-507"
                                                    bp2="-453"
                                                    iwidth="20"
                                                    iheight="20"
                                                    onClick={() =>
                                                        this.onHelpClick(
                                                            item
                                                                .verificationHeader
                                                                .name
                                                        )
                                                    }
                                                />
                                            </VerificationStatusHeader>
                                        </VerificationStepsLeftSide>
                                        <VerificationStepsRightSide>
                                            <VerificationStatusHeader>
                                                {item.verificationHeader.status}
                                                %
                                            </VerificationStatusHeader>
                                        </VerificationStepsRightSide>
                                    </VerificationStepsWrapper>
                                    {item.steps.map((step, index) => (
                                        <VerificationStepsWrapper key={index}>
                                            <VerificationStepsLeftSide>
                                                <StepWrapper>
                                                    <StepTop
                                                        onClick={() =>
                                                            this.onClickHeader(
                                                                index,
                                                                step.id
                                                            )
                                                        }
                                                    >
                                                        {step.name}
                                                    </StepTop>
                                                    <StepBottom>
                                                        {step.credential}
                                                    </StepBottom>
                                                </StepWrapper>
                                                <VerifyButton
                                                    onClick={() =>
                                                        this.onVerifyClick(
                                                            step.id
                                                        )
                                                    }
                                                >
                                                    {step.status ===
                                                    'notVerified' ? (
                                                        <VerifyButtonText>
                                                            verify
                                                        </VerifyButtonText>
                                                    ) : null}
                                                </VerifyButton>
                                            </VerificationStepsLeftSide>
                                            <VerificationStepsRightSide>
                                                {(() => {
                                                    if (
                                                        step.status ===
                                                        'pending'
                                                    ) {
                                                        return (
                                                            <PendingWrapper>
                                                                Pending
                                                            </PendingWrapper>
                                                        );
                                                    } else if (
                                                        step.editable === true
                                                    ) {
                                                        return <div>icon</div>;
                                                    } else {
                                                        return (
                                                            <ArrowRightWrap />
                                                        );
                                                    }
                                                })()}
                                            </VerificationStepsRightSide>
                                        </VerificationStepsWrapper>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </ProfileMenuVerificationWrapper>
                )}
                {this.state.currentPage === this.pagesToShow.IDpage && (
                    <ProfileMenuVerificationID
                        BackCurrentPage={this.BackCurrentPage}
                    />
                )}
                {this.state.currentPage === this.pagesToShow.Addresspage && (
                    <ProfileMenuVerificationAddress
                        BackCurrentPage={this.BackCurrentPage}
                    />
                )}

                {this.state.currentPage ===
                    this.pagesToShow.professionalLicense && (
                    <ProfileMenuVerificationOfficialLicenses
                        BackCurrentPage={this.BackCurrentPage}
                    />
                )}
                <Modal
                    isOpen={this.state.modalOpen}
                    handleClose={() => this.setState({ modalOpen: false })}
                    containerWidth={this.state.modalWidth}
                    containerHeight="auto"
                    containerSizeUnit="px"
                    containerPadding="0"
                    titleStyled={ModalTitleStyles}
                    title={this.state.modalTitle}
                    closeStyled={ModalCloseStyles}
                >
                    {this.modalHandler()}
                </Modal>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuVerification;
