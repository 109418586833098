import styled from "styled-components";
import {Link} from "react-router-dom";
import {Arrow} from "../ProfileGroupTypeInfoBox/Styled";

export const Wrapper = styled.ul`
    list-style: none;
    margin: 25px 0 0;
    padding: 0 0 10px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    color: rgb(25,37,124);
    border-bottom: solid 1px #e6e6e6;
`;

export const TreeArrow = styled(Arrow)``;

export const TreeText = styled.span`
    font-size: 25px;
    &:hover {
        cursor: pointer;
    }

    @media ${props => props.theme.mediaHD} {
        font-size: 17px;
    }
`;

export const RootContentWrapper = styled.li`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const RootContent = styled.ul`
    list-style: none;
    margin: 20px 0 0 0;
    padding: 0;
    overflow: hidden;
    /*transition: all 0.2s ease;
    height: ${props => props.height}px;*/
    ${
        props => (!props.isExpand)
            ? ' height: 0; '
            : ''
    }
`;

export const TreeItem = styled.li`
    margin: 0 0 0 25px;
`;

export const TreeContent = styled.p`
    margin: 20px 0;
    overflow: hidden;
    /*transition: all 0.2s ease;
    height: ${props => props.height}px;*/
    ${
        props => (!props.isExpand)
            ? ' height: 0; margin-bottom: 0; '
            : ''
    }
`;

export const PDF = styled(Link)`
    display: inline-block;
    width: 62px;
    margin-bottom: 10px;
    margin-right: 25px;
    font-family: 'Catamaran', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: rgb(112,112,112);
    text-align: center;
    span {
        margin-bottom: 5px;
    }
`;

export const UrlLink = styled(Link)`
    display: block;
    margin-bottom: 10px;
`;