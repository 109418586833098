import React from 'react';
import SearchNewListing from '../Modules/SearchNewListing';
import SearchNewPeople from '../Modules/SearchNewPeople';
import SearchNewAll from '../Modules/SearchNewAll';
import SearchNewGroup from '../Modules/SearchNewGroup';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import { Main } from '../../styles/Styled';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';
import ProfileMenu from '../Modules/ProfileMenu';

class SearchPageNew extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;
        this.state = {
            showMap: false,
        };
    }

    toggleShow = () => {
        this.setState((prevState) => {
            return { showMap: !prevState.showMap };
        });
    };

    renderBody() {
        const { category } = this.props.match.params;

        return (
            <Main>
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    (() => {
                        switch (category) {
                            case 'all':
                                return <SearchNewAll />;
                            case 'listings':
                                return (
                                    <SearchNewListing
                                        showMap={this.state.showMap}
                                        toggleShow={this.toggleShow}
                                    />
                                );
                            case 'group':
                                return (
                                    <SearchNewGroup
                                        showMap={this.state.showMap}
                                        toggleShow={this.toggleShow}
                                    />
                                );
                            case 'people':
                                return (
                                    <SearchNewPeople
                                        showMap={this.state.showMap}
                                        toggleShow={this.toggleShow}
                                    />
                                );
                            default:
                                return <SearchNewAll />;
                        }
                    })()
                )}
                {category === 'all' && <ProfileMenu />}
            </Main>
        );
    }
};

export default observer(SearchPageNew);
