import styled from 'styled-components'
import { IconButton } from '../../../styles/Styled';

export const BigImage = styled.img`
    display: block;
    width: 100%;
    height: 630px;
    object-fit: cover;
    border-radius: 10px;
`;

export const ImageThumbnails = styled.div`
    width: 100%;
    margin-top: 15px;
    > div {
        float: right;
    }
`;

export const ImgThumb = styled.img`
    width: 115px; height: 115px;
    border-radius: 10px;
    float: left;
    margin-right: 10px;
    box-sizing: border-box;
    opacity: .5;
    cursor: pointer;
    &:last-of-type {
        margin-right: 0;
    }
    ${
        props => (props.active) &&
            `
            opacity: 1;
            border: solid 4px ${props.theme.darkBlue};
            `
    };
`;






export const Image3LeftWrapper = styled.div`
    float:left;
    width:380px;
    height:470px;
    background-image: url('${props => props.backgroundImage}');
    background-position: center;
    background-size: cover;

    @media only screen and (max-width: 1169px) {
        float:none;
        max-width:100%;
    }
`
export const Image2RightWrapper = styled(Image3LeftWrapper)`
    float:right;
`
export const Image3RightTopWrapper = styled.div`
    width:380px;
    height:230px;
    float:right;
    background-image: url('${props => props.backgroundImage}');
    background-position: center;
    background-size: cover;

    @media only screen and (max-width: 1169px) {
        float:none;
        max-width:100%;
    }
`
export const Image3RightBottomWrapper = styled(Image3RightTopWrapper)`
    margin-top:10px;
`
export const Image3Count = styled(IconButton)`
    width:380px;
    height:230px;
    background-color:rgba(0,0,0,0.6);
    position:absolute;
    right:0;
    bottom:0;
    display: flex;
    align-items: center;
    color:#ffffff;
    justify-content: center;
    font-size:50px;

    @media only screen and (max-width: 1169px) {
        width:100%;
        position:static;
        display:block;
    }
`
export const Image1Main = styled.img`
    height:470px;
`
export const Image1MainWrapper = styled.div`
    background-image: url('${props => props.backgroundImage}');
    background-position: center;
    background-size: cover;
    height:470px;
`