import styled, { css } from 'styled-components';
import { InputTextStyles } from '../LoginForm/Styled';

export const Wrapper = styled.div``;

export const OwnerImage = styled.img`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    float: left;
    border-radius: 10px;
`;

export const PostButtonStyles = css`
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 115% 0 0;
    font-size: 18px;
    color: white;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        to right,
        ${(props) => props.theme.cyan} 1%,
        ${(props) => props.theme.cyanDark}
    );
`;

export const ButtonBox = styled.button`
    width: 30px;
    height: 30px;
    float: ${(props) => (props.left ? 'left' : 'right')};
    background: ${(props) => (props.enabled ? '#fff' : props.theme.paleGray)};
    border-radius: 6px;
    box-sizing: border-box;
    margin-right: 10px;
    border: ${(props) =>
        props.noBorder
            ? 'none'
            : props.enabled
                ? `1px solid ${props.theme.blue}`
                : 'none'};
    &:hover {
        cursor: pointer;
    }
    span {
        position: relative;
        top: 1px;
    }
`;

export const InputTextWrapperStyles = css`
    margin: 10px 0 20px;
    @media ${(props) => props.theme.mediaHD} {
        & > a {
            font-size: 18px;
        }
    }
`;

export const VideoInputTextWrapperStyles = css`
    margin: 30px 0 20px;
    @media ${(props) => props.theme.mediaHD} {
        & > a {
            font-size: 18px;
        }
    }
`;

export const ModalWrapper = styled.div`
    padding: 30px 22px 20px;
    ${(props) => props.height && `height: ${props.height}px`};
`;

export const VideoProvider = styled.button`
    border: none;
    background: transparent;
    width: 130px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid
        ${(props) => (props.selected ? props.theme.cyan : props.theme.paleGray)};

    :first-of-type {
        margin-left: 40px;
        margin-right: 20px;
    }
`;

export const VideoPlayer = styled.iframe``;

export const TextStylesInput = css`
    ${InputTextStyles};
    font-size: 16px;
    line-height: 19px;
`;

export const PostImage = styled.img`
    width: 100%;
    height: 200px;
`;

export const DoneButtonWrapper = styled.button`
    width: 100%;
    margin-top: 70px;
    cursor: pointer;
    height: 50px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    background-image: linear-gradient(to right, #07aed0 1%, #106ba6);
`;

export const ModalWrapperLink = styled.div`
    padding: 30px 33px 33px;
    ${(props) => props.height && `height: ${props.height}px`};
`;

export const BlogPostCard = styled.div`
    width: 335px;
    padding: 1rem;

    background-image: linear-gradient(90deg, #000, #999);
    color: #fff;
`;

export const ImgStyle = styled.img`
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
`;

export const ImgWrapper = styled.div`
    width: 100%;
    height: 296px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.grayCircle};
    margin-top: 20px;
    position: relative;
`;

export const AltButtonWrapper = styled.div`
    position: absolute;
    left: 7px;
    bottom: 15px;
    color: white;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background: ${props => props.theme.grayBorderLight};
    margin-bottom: 20px;
`;
