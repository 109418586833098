import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import ButtonText from '../FormButtonText';
import {
    getInputNameAndValue,
    __,
    isMobile,
    mobileE164,
    publicRouteRule,
} from '../../../Utils';
import InputText from '../FormInputText';
import {
    ButtonTextColoredStyles,
    ButtonTextWhiteStyles,
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    InputTextWrapperStyles,
    Wrapper,
} from './Styled';
import { Link, withRouter } from 'react-router-dom';
import { ErrorMessage } from '../RegisterForm/Styled';
import Config from '../../../config';

export class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fields: {},
            errorFields: {},
        };
    }

    componentDidMount() {
        (async () => {
            await publicRouteRule(this);
        })();
    }

    // Should be a Normal Function for "Unit Test" to Work
    async handleLogInClick() {
        let username = this.state.fields['loginUsername'];
        const password = this.state.fields['loginPassword'];

        if (!username) {
            this.setErrorFields('loginUsername', 'Please enter your username.');
        }
        if (!password) {
            this.setErrorFields('loginPassword', 'Please enter your password.');
        }
        if (!username || !password) {
            return false;
        }

        // Convert to E164
        if (isMobile(username)) {
            username = mobileE164(username);
        }

        this.setState({ isLoading: true });

        try {
            const user = await Auth.signIn(username, password);
            console.log('LOGIN Success', user);
            if (user.signInUserSession) {
                this.setState(
                    {
                        isLoading: false, // Prevent Memory Leak
                    },
                    () => {
                        this.props.history.push(Config.ROUTES.homePage);
                    }
                );
            } else {
                console.log('LOGIN ERROR', 'FATAL');
                this.setErrorFields(
                    'formError',
                    'Fatal Error, Please Try Again'
                );
                this.setState(
                    {
                        isLoading: false, // Prevent Memory Leak
                    },
                    () => {
                        return false;
                    }
                );
            }
        } catch (error) {
            console.log('LOGIN ERROR', error);
            this.setErrorFields('formError', 'The username or password is incorrect.');
            this.setState(
                {
                    isLoading: false, // Prevent Memory Leak
                },
                () => {
                    return false;
                }
            );
        }
    }

    setErrorFields = (field, errMessage) => {
        const errorFields = this.state.errorFields;
        errorFields[field] = errMessage;
        this.setState({
            errorFields,
        });
    };

    handleInputChange = (event) => {
        const inputData = getInputNameAndValue(event),
            fields = { ...this.state.fields },
            errorFields = { ...this.state.errorFields };
        fields[inputData.name] = inputData.value;
        errorFields[inputData.name] = null;
        this.setState({
            fields,
            errorFields,
        });
    };

    render() {
        return (
            <Wrapper>
                <InputText
                    id="loginUsername"
                    type="text"
                    name="loginUsername"
                    errorMessage={this.state.errorFields['loginUsername']}
                    onChange={this.handleInputChange}
                    onEnter={this.handleLogInClick.bind(this)}
                    styled={InputTextStyles}
                    wrapperStyled={InputTextWrapperStyles}
                    labelAnimate={__('username', 'Username')}
                    labelAnimateStyled={InputLabelAnimateStyles}
                    labelAfterAnimateStyled={InputLabelAfterAnimateStyles}
                    required={true}
                />

                <InputText
                    id="loginPassword"
                    type="password"
                    name="loginPassword"
                    errorMessage={this.state.errorFields['loginPassword']}
                    onChange={this.handleInputChange}
                    onEnter={this.handleLogInClick.bind(this)}
                    styled={InputTextStyles}
                    wrapperStyled={InputTextWrapperStyles}
                    labelAnimate={__('password', 'Password')}
                    labelAnimateStyled={InputLabelAnimateStyles}
                    labelAfterAnimateStyled={InputLabelAfterAnimateStyles}
                    required={true}
                >
                    <Link to={Config.ROUTES.forgetPassword.root}>
                        Forgot Password?
                    </Link>
                </InputText>

                {this.state.errorFields['formError'] && (
                    <ErrorMessage>
                        {this.state.errorFields['formError']}   
                    </ErrorMessage>
                )}

                <ButtonText
                    onClick={this.handleLogInClick.bind(this)}
                    isLoading={this.state.isLoading}
                    styled={ButtonTextColoredStyles}
                >
                    {__('login', 'Sign in')}
                </ButtonText>

                <Link to={Config.ROUTES.register.root}>
                    <ButtonText styled={ButtonTextWhiteStyles}>
                        {__('sign_up', 'Join now')}
                    </ButtonText>
                </Link>

                {/* <InfoText>or login with</InfoText>

                <LoginSocialWrapper>
                    <SocialButton className="twitter">
                        <IconNew
                            bp1="-366"
                            bp2="-484"
                            iwidth="33"
                            iheight="27"
                        />
                    </SocialButton>
                    <SocialButton className="google">
                        <IconNew
                            bp1="-339"
                            bp2="-484"
                            iwidth="27"
                            iheight="27"
                        />
                    </SocialButton>
                    <SocialButton className="facebook">
                        <IconNew
                            bp1="-324"
                            bp2="-484"
                            iwidth="14"
                            iheight="27"
                        />
                    </SocialButton>
                </LoginSocialWrapper> */}
            </Wrapper>
        );
    }
}

export default withRouter(LoginForm);
