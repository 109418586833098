import styled from 'styled-components';
import { IconButton } from '../../../styles/Styled';

export const Wrapper = styled.footer`
    width: 100%;
    height: 48px;
    border-top: solid 1px ${(props) => props.theme.grayBorderFooter};
    box-sizing: border-box;
    text-align: center;
    margin-top: 5px;

    @media ${(props) => props.theme.mediaHD} {
        height: 37px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        height: 37px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        height: 37px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        height: 37px;
    }
`;

export const RightText = styled.span`
    color: ${(props) => props.theme.textGrayLight};
    margin-left: 10px;
    font-size: 20px;

    @media ${(props) => props.theme.mediaHD} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        font-size: 14px;
    }
`;

export const LocationButton = styled(IconButton)`
    font-family: ${(props) => props.theme.mainFont};
    color: ${(props) => props.theme.textBlack};
    font-size: 20px;
    padding: 0 10px;
    span {
        position: relative;
        top: 1px;
        margin-right: 0;
    }

    @media ${(props) => props.theme.mediaHD} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        font-size: 14px;
    }
`;

export const PopContainer = styled.div`
    position: relative;
    display: inline;
`;

export const PopUp = styled.div`
    width: 195px;
    background: red;
    border: 1px solid;
    border-color: ${(props) => props.theme.grayDarkBorder};
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    background: white;
    z-index: 1000;
    position: absolute;
    bottom: 0;
    left: 0;
`;

export const PopButton = styled.button`
    background: transparent;
    border: 0;
    width: 100%;
    margin: 0;
    font-size: 20px;
    font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
    color: ${(props) => props.theme.textBlack};
    text-align: left;
    padding-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-family: ${(props) => props.theme.mainFont};

    @media ${(props) => props.theme.mediaHD} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        font-size: 14px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        font-size: 14px;
    }
`;
