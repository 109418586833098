import React, {Component} from "react";
import {ClearFloats} from "../BaseTemplate/Styled";
import {CheckBoxTagStyles, TagSchedule, TagsWrapper} from "./Styled";
import IconNew from "../IconNew";
import CheckBox from "../FormCheckBox";
import {objIsEqual} from "../../../Utils";

class FormSpecialTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTags: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && !objIsEqual(prevProps.value, this.props.value)) {
            this.setState({
                selectedTags: this.props.value
            });
        }
    }

    checkBoxChangeHandler = (tag) => {
        const selectedTags = [...this.state.selectedTags];
        if (selectedTags.some(
            x => x.value === tag.value
        )) {
            selectedTags.splice(selectedTags.findIndex(
                x => x.value === tag.value
            ), 1);
        } else {
            selectedTags.push(tag);
        }
        this.updateStates(selectedTags);
    }

    updateStates = (tags) => {
        const states = this.props.states,
              fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                      fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                      errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[fieldName] = tags;
                errorFields[fieldName] = null;
                this.setState({
                    selectedTags: tags
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {

        if (!this.props.list) {
            return null;
        }

        return (
            <TagsWrapper>
                {
                    this.props.list.map(
                        tag => {
                            let icons;
                            switch (tag.value) {
                                case 1:
                                    icons =
                                        <>
                                            <IconNew bp1="-229" bp2="-77" iwidth="26" iheight="26"/>
                                            <IconNew bp1="-358" bp2="-159" iwidth="47" iheight="26"/>
                                        </>
                                    break;
                                case 2:
                                    icons =
                                        <>
                                            <IconNew bp1="-201" bp2="-77" iwidth="26" iheight="26"/>
                                            <IconNew bp1="-358" bp2="-131" iwidth="60" iheight="26"/>
                                        </>
                                    break;
                                case 3:
                                    icons =
                                        <>
                                            <IconNew bp1="-420" bp2="-131" iwidth="26" iheight="26"/>
                                            <IconNew bp1="-358" bp2="-103" iwidth="109" iheight="26"/>
                                        </>
                                    break;
                                case 4:
                                    icons =
                                        <>
                                            <IconNew bp1="-257" bp2="-77" iwidth="26" iheight="26"/>
                                            <IconNew bp1="-358" bp2="-75" iwidth="119" iheight="26"/>
                                        </>
                                    break;
                                default:
                                    icons = null;
                            }
                            return (
                                <div key={"check-tag_" + this.props.id + "_" + tag.value}>
                                    {
                                        icons
                                    }
                                    {
                                        (tag.value === 3)
                                            ?
                                            <TagSchedule show>
                                                <CheckBox
                                                    id={"check-tag_" + this.props.id + "_" + tag.value}
                                                    name={"check-tag_" + this.props.id}
                                                    styled={CheckBoxTagStyles}
                                                    label={tag.text}
                                                    checked={
                                                        (this.state.selectedTags.length > 0) &&
                                                        (this.state.selectedTags.some(
                                                            x => x.value === tag.value
                                                        ))
                                                    }
                                                    onChange={
                                                        () => this.checkBoxChangeHandler(tag)
                                                    }
                                                />
                                                <br/>
                                                11:00 &nbsp; <b>➞</b> 19:00
                                                <IconNew bp1="-396" bp2="-220" iwidth="13" iheight="14"
                                                         className="pen"/>
                                                <br/>
                                                15 Apr <b>➞</b> 22 Jun
                                                <IconNew bp1="-396" bp2="-220" iwidth="13" iheight="14"
                                                         className="pen"/>
                                            </TagSchedule>
                                            :
                                            <TagSchedule>
                                                <CheckBox
                                                    id={"check-tag_" + this.props.id + "_" + tag.value}
                                                    name={"check-tag_" + this.props.id}
                                                    styled={CheckBoxTagStyles}
                                                    label={tag.text}
                                                    checked={
                                                        (this.state.selectedTags.length > 0) &&
                                                        (this.state.selectedTags.some(
                                                            x => x.value === tag.value
                                                        ))
                                                    }
                                                    onChange={
                                                        () => this.checkBoxChangeHandler(tag)
                                                    }
                                                />
                                            </TagSchedule>
                                    }
                                </div>
                            )
                        }
                    )
                }
                <ClearFloats/>
            </TagsWrapper>
        );
    }
}

export default FormSpecialTags;