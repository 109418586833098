import React, { Component } from 'react';
import {
    LocationButton,
    RightText,
    Wrapper,
    PopUp,
    PopContainer,
    PopButton,
} from './Styled';
import { ArrowDown } from '../BaseTemplate/Styled';
import { Tick } from '../SelectorPopUp/Styled';
import Config from '../../../config';

class LoginRegisterFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            popUpOpen: false,
        };
        //Canada, France, Germany, Italy, Japan, the United States, the United Kingdom
        this.countries = ['United States', 'Canada', 'France', 'Germany', 'Italy', 'Japan', 'Spain','United Kingdom'];
    }

    componentDidMount() {
        if (!localStorage.getItem('country')) {
            let mapUrl = Config.GOOGLE_MAP_GEOCODE_URL;
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    mapUrl += `&latlng=${position.coords.latitude},${
                        position.coords.longitude
                    }`;
                    fetch(mapUrl)
                        .then((response) => response.json())
                        .then((res) => {
                            const last = res.results.length - 1;
                            this.setState({
                                country: res.results[last].formatted_address,
                            });
                            localStorage.setItem(
                                'country',
                                res.results[last].formatted_address
                            );
                        });
                },
                (err) => console.log(err)
            );
        } else {
            this.setState({
                country: localStorage.getItem('country'),
            });
        }
    }

    handleChange(country) {
        this.setState({ country: country });
        this.setState({ popUpOpen: false });
        localStorage.setItem('country', country);
    }

    render() {
        return (
            <Wrapper>
                <RightText>Your location</RightText>
                <PopContainer>
                    <LocationButton
                        onClick={() => this.setState({ popUpOpen: true })}
                        onBlur={() => this.setState({ popUpOpen: false })}
                    >
                        {this.state.country}
                        <ArrowDown />
                    </LocationButton>
                    {this.state.popUpOpen && (
                        <PopUp>
                            {this.countries.map((country, index) => (
                                <PopButton
                                    key={index}
                                    onClick={() => this.handleChange(country)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    selected={
                                        country === this.state.country
                                    }
                                >
                                    {country === this.state.country && (
                                        <Tick />
                                    )}
                                    {country}
                                </PopButton>
                            ))}
                        </PopUp>
                    )}
                </PopContainer>
            </Wrapper>
        );
    }
}

export default LoginRegisterFooter;
