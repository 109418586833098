import React, {Component} from "react";
import {
    InputLabelAfterAnimateGroupStyles,
    InputLabelAnimateGroupStyles,
    InputTextGroupStyles,
    InputTextWrapperGroupStyles
} from "../FormGroupContact/Styled";
import InputText from "../FormInputText";
import IconNew from "../IconNew";
import {sendAsyncRequest} from "../../../Utils";

class FormGroupName extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: "",
            loading: false,
            available: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && prevProps.value !== this.props.value) {
            this.setState({
                inputValue: this.props.value,
                available: true
            });
        }
    }

    inputChangeHandler = (e) => {
        const value = e.target.value;
        if (value.length > 3) {
            this.setState({
                loading: true
            })
            sendAsyncRequest("/check-group", "get", {}).then(result => {
                this.setState({
                    available: (result.status === 200),
                    loading: false
                })
            });
        }
        this.setState({
            inputValue: value
        });
        this.updateState(value);
    }

    updateState = (value) => {
        const states = this.props.states,
              fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                      fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                      errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[fieldName] = value;
                errorFields[fieldName] = null;
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {
        return (
            <InputText
                id="groupNameText"
                name="groupNameText"
                type="text"
                styled={InputTextGroupStyles}
                wrapperStyled={InputTextWrapperGroupStyles}
                labelAnimate={this.props.title}
                labelAnimateStyled={InputLabelAnimateGroupStyles}
                labelAfterAnimateStyled={InputLabelAfterAnimateGroupStyles}
                icon={
                    (this.state.loading)
                        ? null
                        : (this.state.available)
                        ? <IconNew bp1="-29" bp2="-215" iwidth="19" iheight="19"/>
                        : null
                }
                iconText={
                    (this.state.loading)
                        ? "Checking..."
                        : (this.state.available)
                        ? "Available"
                        : null
                }
                required={true}
                requiredStar
                value={this.state.inputValue}
                onInput={this.inputChangeHandler}
            />
        );
    }
}

export default FormGroupName;