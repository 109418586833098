import React, { Component } from 'react';
import {
    ButtonTextDarkBlueStyles,
    FormWrapper,
    InputRichTextContactStyles,
    InputTextContactStyles,
    InputTextContactWrapperStyles,
} from './Styled';
import InputText from '../FormInputText';
import ButtonText from '../FormButtonText';
import IconNew from '../IconNew';

class ProfilePageContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errorFields: {},
        };
    }

    render() {
        return (
            <FormWrapper>
                <h2> {'>'} Contact Form</h2>
                <InputText
                    type="text"
                    id="yourName"
                    name="yourName"
                    states={[this]}
                    styled={InputTextContactStyles}
                    wrapperStyled={InputTextContactWrapperStyles}
                    placeholder="Your Name"
                />
                <InputText
                    type="text"
                    id="phoneNum"
                    name="phoneNum"
                    states={[this]}
                    styled={InputTextContactStyles}
                    wrapperStyled={InputTextContactWrapperStyles}
                    placeholder="Phone Number"
                />
                <InputText
                    type="text"
                    id="subject"
                    name="subject"
                    states={[this]}
                    styled={InputTextContactStyles}
                    wrapperStyled={InputTextContactWrapperStyles}
                    placeholder="Subject"
                />
                <InputText
                    type="text"
                    id="message"
                    name="message"
                    states={[this]}
                    styled={InputRichTextContactStyles}
                    wrapperStyled={InputTextContactWrapperStyles}
                    placeholder="Write Message ..."
                />

                <ButtonText styled={ButtonTextDarkBlueStyles}>
                    Send Message
                    <IconNew bp1="-412" bp2="-50" iwidth="28" iheight="25" />
                </ButtonText>
            </FormWrapper>
        );
    }
}

export default ProfilePageContactForm;
