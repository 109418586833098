import {Auth} from 'aws-amplify';
import {useHistory} from "react-router-dom";
import Config from "../../config";

const Logout = () => {
    (async () => {
        try {
            localStorage.clear();
            await Auth.signOut();
        } catch (err) {}
    })();
    const history = useHistory();
    history.push(Config.ROUTES.landingPage);
    return null;
};

export default Logout;