import styled, {css} from "styled-components";

export const Wrapper = styled.div``;

export const CurvedButtonStyles = css`
    height: 55px;
    background: ${props => props.theme.grayBgInput};
    color: ${props => props.theme.textBlack};
    font-size: 22px;
    font-weight: bold;
    border: none;
    padding: 0 30px 0 60px;
    border-radius: 15px 0 15px 15px;
    margin: 14px 0 0 40px;
    position: relative;
    ${
    props => (props.noMargin) &&
        ` margin-left: 0; `
};
    &:after, &:before {
        content: "";
        position: absolute;
        background: ${props => props.theme.textBlack};
        top: 50%;
        transform: translateY(-50%);
    }
    &:after {
        width: 19px; height: 3px;
        left: 25px;
    }
    &:before {
        width: 3px; height: 19px;
        left: 33px;
    }
`;