import styled from "styled-components";

export const Progress = styled.div`
    position: relative;
    height: 2px;
    display: block;
    width: 100%;
    background-clip: padding-box;
    margin: 0;
    overflow: hidden;
    > div {
        background-color: ${props => props.theme.darkBlue};
    }
    > div:before {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0; left: 0; bottom: 0;
        will-change: left, right;
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    > div:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0; left: 0; bottom: 0;
        will-change: left, right;
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
    }
    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }
    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }
        60% {
            left: 107%;
            right: -8%;
        }
        100% {
            left: 107%;
            right: -8%;
        }
    }
`;