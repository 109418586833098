import React from 'react';
import { TabPageStyles } from './Styled';
import TabPages, { TabStyle } from '../TabBarPages/TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import ProfileMenuContent from '../ProfileMenuContent/ProfileMenuContent';
import ProfileMenuVaultTab from '../ProfileMenuVaultTab';
import IconNew from '../IconNew';

class ProfileMenuVaultTabBar extends React.Component {
    render() {
        return (
            <ProfileMenuContent>
                <TabPages theme={TabStyle.VaultTabBar} defaultPageIndex={0}>
                    <TabPage
                        title="Vault"
                        activeIcon={
                            <IconNew
                                bp1="-206"
                                bp2="-164"
                                iwidth="30"
                                iheight="25"
                            />
                        }
                        deactiveIcon={
                            <IconNew
                                bp1="-175"
                                bp2="-164"
                                iwidth="30"
                                iheight="25"
                            />
                        }
                        styled={TabPageStyles}
                    >
                        <ProfileMenuVaultTab />
                    </TabPage>

                    <TabPage
                        title="Wallet"
                        activeIcon={
                            <IconNew
                                bp1="-231"
                                bp2="-190"
                                iwidth="28"
                                iheight="25"
                            />
                        }
                        deactiveIcon={
                            <IconNew
                                bp1="-202"
                                bp2="-190"
                                iwidth="28"
                                iheight="25"
                            />
                        }
                        styled={TabPageStyles}
                    />

                    <TabPage
                        title="Market"
                        activeIcon={
                            <IconNew
                                bp1="-237"
                                bp2="-161"
                                iwidth="22"
                                iheight="28"
                            />
                        }
                        deactiveIcon={
                            <IconNew
                                bp1="-237"
                                bp2="-131"
                                iwidth="22"
                                iheight="28"
                            />
                        }
                        styled={TabPageStyles}
                    />
                </TabPages>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuVaultTabBar;
