import styled from 'styled-components';

export const PropertiesWrapperLeft = styled.div`
    float: left;
    margin-right: 30px;
    margin-top: 50px;
`;
export const PropertiesWrapperRight = styled.div`
    float: right;
    margin-top: 50px;
`;

export const PropertyWrapper = styled.div`
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 40px;
    position: relative;
    width: 615px;

    @media ${props => props.theme.mediaHD} {
        width: 400px;
    }
`;

export const AnnouncementMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 15px;
    background: ${(props) => props.theme.cyan};
    border-radius: 15px 15px 0 0;
    &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        height: 16px;
        background: #fff;
        border-radius: 15px 15px 0 0;
    }
`;

export const SponsorMask = styled.div`
    position: absolute;
    top: 0; left: 0; right: 142px;
    height: 15px;
    background: ${(props) => props.theme.darkBlue};
    ${(props) =>
        props.bg === 'cyan' ? ` background: ${props.theme.cyan} ` : ''};
    border-radius: 15px 0 0 0;
    z-index: 1;
    &:after {
        content: "";
        position: absolute;
        top: 6px; left: 0; right: 0;
        height: 14px;
        background: #fff;
        ${(props) =>
            props.bg === 'blue'
                ? ` background: ${props.theme.lightBlue} `
                : ''};
        ${(props) =>
            props.bg === 'cyan'
                ? ` background: ${props.theme.lightCyan} `
                : ''};
        border-radius: 18px 15px 0 0;
    }
    &:before {
        content: "${(props) => props.text}";
        ${(props) => (props.bg ? ' font-weight: bold; ' : '')};
        width: 143px; height: 40px;
        box-sizing: border-box;
        padding: 4px 0;
        position: absolute;
        top: 0; right: -142px;
        background: ${(props) => props.theme.darkBlue};
        ${(props) =>
            props.bg === 'cyan' ? ` background: ${props.theme.cyan} ` : ''};
        z-index: 3;
        color: #fff;
        font-size: 17px;
        text-align: center;
        border-radius: 0 15px 0 15px;
    }
`;



