import styled from 'styled-components';

export const Container = styled.div`
    ${props => props.styled};
    position: relative;
    display: inline-block;
    > div {
        width: 195px;
        border: 1px solid;
        border-color: ${(props) => props.theme.grayDarkBorder};
        border-radius: 5px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        background: white;
        z-index: 10;
        position: absolute;
        display: ${(props) => (props.isOpen ? 'block' : 'none')};
        right: 0;
        top: 0;
    }

    > button {
        border: 0;
        padding: 0;
        color: ${(props) => props.theme[props.color]};
        float: ${(props) => props.float};
        text-align: left;
        cursor: pointer;
        font-size: 18px;
        background: transparent;
        font-family: ${(props) => props.theme.mainFont};
    }
`;

export const Tick = styled.u`
    display: inline-block;
    transform: rotate(45deg);
    width: 8px;
    height: 16px;
    border-bottom: 3px solid ${(props) => props.theme.cyan};
    border-right: 3px solid ${(props) => props.theme.cyan};
    border-radius: 3px;
    margin-right: 10px;
`;

export const PopButton = styled.button`
    background: transparent;
    border: 0;
    width: 100%;
    margin: 0;
    font-size: 20px;
    font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
    color: ${(props) => props.theme.textBlack};
    text-align: left;
    padding-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-family: ${(props) => props.theme.mainFont};
`;
