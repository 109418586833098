import styled from "styled-components";

export const InputSearchWrapper = styled.div`
    position: relative;
    ${props => props.styled};
`;

export const SearchList = styled.ul`
    list-style: none;
    position: relative;
    margin: 0; padding: 0;
    background: white;
    z-index: 9999;
    border: solid 1px ${props => props.theme.textGrayLight};
    border-top: none;
    border-radius: 0 0 5px 5px;
`;

export const SearchItem = styled.li`
    padding: 10px 15px;
    border-bottom: solid 1px ${props => props.theme.grayLineLight};
    :last-of-type {
        border-bottom: none;
    }
    small {
        display: block;
    }
    ${
        props => (!props.disabled) &&
            `
            :hover {
                background: ${props.theme.grayBgInput};
                cursor: pointer;
            }
            `
    };
    ${
        props => (props.isFocused) && 
            ` background: ${props.theme.grayBgInput}; `
    };
`;
