import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonTextFlatStyles } from '../RegisterForm/Styled';

export const WizardPage = styled.div`
    position: relative;
    padding: 45px;
    background: #fff;
    z-index: 2;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border-right: 4px solid ${props => props.theme.darkBlue};
    & > h1 {
        font-size: 35px;
        margin: 0 0 30px;
        color: ${(props) => props.theme.darkBlue};
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 540px;
            border-bottom: solid 1px ${(props) => props.theme.grayLineLight};
        }
    }
`;

export const WizardContent = styled.div`
    ${(props) => props.styled};
`;

export const WizardWrapper = styled.div`
    margin: ${(props) => (props.margin ? props.margin : 90)}px 0 0 0;
    min-height: 350px;
    position: relative;
`;

export const Title = styled.h1`
    font-size: 34px;
    font-weight: normal;
    text-align: right;
    color: ${(props) => props.theme.darkBlue};
    margin: 15px 40px 0 0;
`;

export const ListMenu = styled.ul`
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0 35px 0 55px;
`;

export const MenuItem = styled.li`
    margin: 0;
    padding: 0;
    border-bottom: solid 1px ${(props) => props.theme.grayBorder};

    &:last-of-type {
        border-bottom: none;
    }
`;

export const MenuLink = styled(Link)`
    display: block;
    position: relative;
    margin: 0;
    padding: 25px 0;
    font-size: 26px;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) =>
        props.selected
            ? props.theme.darkBlue
            : props.checked
                ? props.theme.cyan
                : props.theme.textBlack};
    & > strong {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 6px;
        left: -91px;
        width: 72px;
        height: 72px;
        border: ${(props) =>
            props.selected
                ? `4px solid ${props.theme.darkBlue}`
                : props.checked
                    ? `none`
                    : `solid 1px ${props.theme.textGrayLight}`};
        border-radius: 100%;
        font-size: 28px;
        text-align: center;
        background: ${(props) => (props.checked ? 'linear-gradient(to bottom, #07aed0, #094FD0)' : '#fff')};
        color: ${(props) =>
            props.selected ? props.theme.darkBlue : props.theme.textBlack};
        box-sizing: border-box;
        & > u {
            display: inline-block;
            transform: rotate(45deg);
            width: 10px;
            height: 20px;
            border-bottom: 6px solid #fff;
            border-right: 6px solid #fff;
            border-radius: 6px;
        }
        z-index: 2131252342312;
    }

    > span {
        height: 4px;
        background: ${props => props.theme.darkBlue};
        width: 100px;
        position: absolute;
        left: -180px; 
        top: 40px;
        z-index: -100;
    }
`;

export const SubMenu = styled.ul`
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: -50px;
        left: -100px;
        width: 100px;
        height: 200px;
        border: solid ${(props) => props.theme.darkBlue};
        border-width: 2px 2px 0 0;
        border-radius: 50px;
        clip-path: inset(0 0 120px 50px);
        z-index: 5;
    }
`;

export const SubMenuItem = styled.li`
    margin: 0;
    padding: 0 0 0 30px;
    &:last-of-type {
        & > a:after {
            bottom: 20px;
        }
    }
`;

export const SubMenuLink = styled(Link)`
    display: block;
    position: relative;
    margin: 0;
    padding: 12px 0;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) =>
        props.selected
            ? props.theme.darkBlue
            : props.checked
                ? props.theme.cyan
                : props.theme.textBlack};
    & > strong {
        position: absolute;

        top: 8px;
        left: -45px;
        width: 30px;
        height: 30px;
        border: solid 1px
            ${(props) =>
                props.selected
                    ? props.theme.darkBlue
                    : props.checked
                        ? props.theme.darkBlue
                        : props.theme.textGrayLight};
        border-radius: 100%;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        background: ${(props) => (props.checked ? props.theme.cyan : '#fff')};
        color: ${(props) =>
            props.selected ? props.theme.darkBlue : props.theme.textBlack};
        box-sizing: border-box;
        & > u {
            display: inline-block;
            margin-top: 6px;
            transform: rotate(45deg);
            width: 5px;
            height: 10px;
            border-bottom: 3px solid #fff;
            border-right: 3px solid #fff;
            border-radius: 3px;
        }
        z-index: 20;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        width: 2px;
        background: ${(props) => props.theme.darkBlue};
        z-index: 10;
    }
`;

export const AddButtonModalStyles = css`
    ${ButtonTextFlatStyles};
    height: 70px;
    font-size: 25px;
    font-weight: normal;
    padding-bottom: 2px;
    margin-top: 20px;
`;

export const ListMenuLoading = styled.ul`
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0 35px 0 55px;
`;

export const MenuItemLoading = styled.li`
    margin: 0;
    padding: 0;
    border-bottom: solid 1px ${(props) => props.theme.grayBorder};
    &:last-of-type {
        border-bottom: none;
    }
    em {
        display: block;
        position: relative;
        margin: 0;
        padding: 25px 0;
        font-size: 26px;
    }
    em:before {
        content: '\\00a0';
    }
    em:after {
        content: '';
        position: absolute;
        top: 25px;
        left: 0;
        bottom: 25px;
        right: 0;
        background: ${(props) => props.theme.graySkeletonAnim}
            linear-gradient(
                100deg,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 80%
            )
            repeat-y 0 0;
        background-size: 50px 200px;
        animation: shine 1s infinite;
    }
    b {
        position: absolute;
        top: 6px;
        left: -91px;
        width: 72px;
        height: 72px;
        background: ${(props) => props.theme.graySkeletonAnim};
        border: solid 1px transparent;
        border-radius: 100%;
        box-sizing: border-box;
    }
    @keyframes shine {
        to {
            background-position: 100% 0;
        }
    }
`;
