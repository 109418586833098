import {types} from 'mobx-state-tree';

const searchStore = types

    .model({
        searchValue: types.string,
        searchLat: types.number,
        searchLng: types.number,
    })

    .views(self => ({
        getSearchValue() {
            return self.searchValue;
        },
        getSearchLat () {
            return self.searchLat
        },
        getSearchLng () {
            return self.searchLng
        }
    }))

    .actions(self => ({
        setSearchValue(str) {
            self.searchValue = str;
        },
        setSearchLat(lat) {
            self.searchLat = lat;
        },
        setSearchLng(lng) {
            self.searchLng = lng;
        }
    }))

    .create({
        searchValue: '',
        searchLat: 0,
        searchLng: 0
    });

export default searchStore;