import React, { Component } from 'react';
import {
    SelectFileWrapper,
    InputLabelAfterAnimateGroupStyles,
    InputLabelAnimateGroupStyles,
    InputTextGroupStyles,
    InputTextWrapperDocStyles,
    SelectLabel,
    InputFile,
    SelectStatus,
    InputTextWrapperWithItemStyles,
    CreateCatButton,
    CategoryDesc,
} from './Styled';
import InputText from '../FormInputText';
import PropTypes from 'prop-types';
import { sendAsyncRequest } from '../../../Utils';
import InputDropDown from '../FormInputDropDown';

class FormUploadMultiSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileInputs: [
                {
                    id: 1,
                    loading: false,
                    uploaded: false,
                    fileName: '',
                    imgTitle: '',
                    imgServerId: -1,
                    categoryId: -1,
                    linkTitle: '',
                },
            ],
            categoryInput: '',
            categories: [
                {
                    text: '(No Category)',
                    value: -1,
                },
            ],
        };
        this.imgIdCounter = 2;
        this.categoryIdCounter = 1;
    }

    onAddFiles = (e) => {
        const files = e.target.files,
            fileInputs = [...this.state.fileInputs];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const imgId = this.imgIdCounter++;
            fileInputs.unshift({
                id: imgId,
                loading: true,
                uploaded: false,
                fileName: file.name,
                imgTitle: '',
                imgServerId: -1,
                categoryId: -1,
                linkTitle: '',
            });
            const data = new FormData();
            data.append('file', file);
            sendAsyncRequest(
                '/uploader/simple',
                'post',
                {},
                null,
                true,
                data
            ).then((result) => {
                const imgServerId = result.body.Result.pk;
                const uploadedFileInputIndex = fileInputs.findIndex(
                    (x) => x.id === imgId
                );
                fileInputs[uploadedFileInputIndex] = {
                    id: imgId,
                    loading: false,
                    uploaded: true,
                    fileName: file.name,
                    imgTitle: file.name,
                    imgServerId,
                };
                this.updateState(fileInputs[uploadedFileInputIndex]);
                this.setState({
                    fileInputs,
                });
            });
        }
        this.setState({
            fileInputs,
        });
    };

    onFileNameChangeHandler = (e, fileInput) => {
        const fileInputs = [...this.state.fileInputs],
            inputValue = e.target.value;
        fileInputs.forEach((file) => {
            if (file.imgServerId === fileInput.imgServerId) {
                file.imgTitle = inputValue;
            }
        });
        this.setState({
            fileInputs,
        });
        this.updateState(fileInput);
    };

    updateState = (fileInput, removeServerId = null) => {
        const states = this.props.states;
        states.forEach((that) => {
            const isGlobalState = that.constructor.name === 'Object',
                fields = isGlobalState
                    ? that.getFields()
                    : { ...that.state.fields },
                errorFields = isGlobalState
                    ? that.getErrorFields()
                    : { ...that.state.errorFields },
                fieldName = this.props.id;
            if (!fields[fieldName]) {
                fields[fieldName] = [];
            }
            if (removeServerId) {
                // Remove
                const fieldIndex = fields[fieldName].findIndex(
                    (x) => x.imgId === removeServerId
                );
                if (~fieldIndex) {
                    fields[fieldName].splice(fieldIndex, 1);
                }
            } else {
                // Insert
                const fieldIndex = fields[fieldName].findIndex(
                    (x) => x.imgId === fileInput.imgServerId
                );
                if (~fieldIndex) {
                    fields[fieldName][fieldIndex] = {
                        imgId: fileInput.imgServerId,
                        imgFileName: fileInput.fileName,
                        imgTitle: fileInput.imgTitle,
                        imgCatId: fileInput.categoryId,
                        linkTitle: fileInput.linkTitle,
                    };
                } else {
                    fields[fieldName].push({
                        imgId: fileInput.imgServerId,
                        imgFileName: fileInput.fileName,
                        imgTitle: fileInput.imgTitle,
                        imgCatId: fileInput.categoryId,
                        linkTitle: fileInput.linkTitle,
                    });
                }
            }
            errorFields[fieldName] = null;
            if (isGlobalState) {
                that.setFields(fields);
                that.setErrorFields(errorFields);
            } else {
                if (!this.props.extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...this.props.extraLocalStatesOnChange,
                    });
                }
            }
        });
    };

    onFileRemoveHandler = (imgServerId) => {
        const fileInputs = [...this.state.fileInputs];
        fileInputs.splice(
            fileInputs.findIndex((x) => x.imgServerId === imgServerId),
            1
        );
        this.setState({
            fileInputs,
        });
        this.updateState(null, imgServerId);
    };

    fileCategoryChanged = (valueObj, fileInput) => {
        const fileInputs = [...this.state.fileInputs];
        fileInputs.forEach((file) => {
            if (file.id === fileInput.id) {
                file.categoryId = valueObj.value;
            }
        });
        this.setState({
            fileInputs,
        });
        this.updateState(fileInput);
    };

    categoryInputChangeHandler = (e) => {
        this.setState({
            categoryInput: e.target.value,
        });
    };

    linkTitleInputChangeHandler = (e, fileInput) => {
        const fileInputs = [...this.state.fileInputs],
            inputValue = e.target.value;
        fileInputs.forEach((file) => {
            if (file.imgServerId === fileInput.imgServerId) {
                file.linkTitle = inputValue;
            }
        });
        this.setState({
            fileInputs,
        });
        this.updateState(fileInput);
    };

    addToCategory = () => {
        const text = this.state.categoryInput,
            categories = [...this.state.categories];
        if (text.trim()) {
            categories.push({
                value: this.categoryIdCounter++,
                text,
            });
            this.setState({
                categories,
                categoryInput: '',
            });
        }
    };

    render() {
        return (
            <>
                {this.props.fileCategory && (
                    <>
                        <CategoryDesc>{this.props.title}</CategoryDesc>
                        <SelectFileWrapper key='add_to_category' width={606}>
                            <InputText
                                id={this.props.id + '_add_cat'}
                                name={this.props.name + '_add_cat'}
                                type='text'
                                styled={InputTextGroupStyles}
                                wrapperStyled={InputTextWrapperDocStyles}
                                labelAnimate='Category Name'
                                labelAnimateStyled={
                                    InputLabelAnimateGroupStyles
                                }
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateGroupStyles
                                }
                                required={true}
                                value={this.state.categoryInput}
                                onInput={this.categoryInputChangeHandler}
                            />

                            <CreateCatButton onClick={this.addToCategory}>
                                Create
                            </CreateCatButton>
                        </SelectFileWrapper>
                    </>
                )}
                {this.state.fileInputs.map((fileInput, index) => (
                    <SelectFileWrapper key={index}>
                        {this.props.fileCategory && (
                            <InputDropDown
                                id={this.props.id + '_dropdown_' + index}
                                name={this.props.id + '_dropdown_' + index}
                                type='text'
                                styled={InputTextGroupStyles}
                                wrapperStyled={InputTextWrapperDocStyles}
                                labelAnimate={'Select a Category'}
                                labelAnimateStyled={
                                    InputLabelAnimateGroupStyles
                                }
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateGroupStyles
                                }
                                required={true}
                                oneArrow
                                list={this.state.categories}
                                listParent={true}
                                readonly={!fileInput.uploaded}
                                value={fileInput.categoryId || -1}
                                dropDownValue={(name, valueObj) =>
                                    this.fileCategoryChanged(
                                        valueObj,
                                        fileInput
                                    )
                                }
                            />
                        )}
                        {this.props.fileLinkTitle && (
                            <InputText
                                id={this.props.id + '_file_link' + index}
                                name={this.props.name + '_file_link' + index}
                                type='text'
                                styled={InputTextGroupStyles}
                                wrapperStyled={InputTextWrapperWithItemStyles}
                                labelAnimate='Link Title'
                                labelAnimateStyled={
                                    InputLabelAnimateGroupStyles
                                }
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateGroupStyles
                                }
                                required={true}
                                readonly={!fileInput.uploaded}
                                value={fileInput.linkTitle}
                                onInput={(e) =>
                                    this.linkTitleInputChangeHandler(
                                        e,
                                        fileInput
                                    )
                                }
                            />
                        )}
                        <InputText
                            id={this.props.id + '_' + index}
                            name={this.props.name + '_' + index}
                            type='text'
                            styled={InputTextGroupStyles}
                            wrapperStyled={
                                !this.props.fileCategory
                                    ? InputTextWrapperDocStyles
                                    : InputTextWrapperWithItemStyles
                            }
                            labelAnimate='File Name'
                            labelAnimateStyled={InputLabelAnimateGroupStyles}
                            labelAfterAnimateStyled={
                                InputLabelAfterAnimateGroupStyles
                            }
                            required={true}
                            readonly={!fileInput.uploaded}
                            value={fileInput.imgTitle}
                            onInput={(e) =>
                                this.onFileNameChangeHandler(e, fileInput)
                            }
                        />
                        {fileInput.loading ? (
                            <SelectStatus>Uploading...</SelectStatus>
                        ) : fileInput.uploaded ? (
                            <SelectStatus uploaded>
                                {fileInput.fileName} (Uploaded)
                                <i
                                    onClick={() =>
                                        this.onFileRemoveHandler(
                                            fileInput.imgServerId
                                        )
                                    }
                                />
                            </SelectStatus>
                        ) : (
                            <SelectLabel
                                htmlFor={
                                    this.props.id + '_' + index + '_fileUp'
                                }
                                data-loading={fileInput.loading}
                                data-uploaded={fileInput.uploaded}
                            >
                                <img
                                    src='/assets/img/loading.gif'
                                    alt='Uploading'
                                />
                                Select File<small>(s)</small>
                                <i />
                            </SelectLabel>
                        )}
                        <InputFile
                            type='file'
                            id={this.props.id + '_' + index + '_fileUp'}
                            onChange={this.onAddFiles}
                            accept='image/*'
                            multiple
                        />
                    </SelectFileWrapper>
                ))}
            </>
        );
    }
}

export default FormUploadMultiSelect;

FormUploadMultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
