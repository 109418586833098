import React, { Component } from 'react';
import {
    ThirdSectionWrapper,
    ThirdSection,
    SectionThreeImg,
    HeaderText,
    DescriptionText,
    ButtonClick,
    ContentWrapper
 } from './Styled';
 import { withRouter } from 'react-router-dom';
class LandingPageShowcaseSection extends Component {


    buttonHandler = (link) => {
        const win = window.open(link, "_blank");
        win.focus(); 
    }

    render() {
        const { data } = this.props;
        return (
            <ThirdSectionWrapper  
                reverse={data.reverse} 
                gradientBackground={data.gradientBackground}
            >
                <ThirdSection image reverse={data.reverse}  >
                    <SectionThreeImg src={data.photo} />
                </ThirdSection>
                    <ContentWrapper>
                        <HeaderText gradientBackground={data.gradientBackground} >
                            {data.title}
                        </HeaderText>
                        <DescriptionText gradientBackground={data.gradientBackground} >
                            {data.description}
                        </DescriptionText>
                        <ButtonClick gradientBackground={data.gradientBackground} target="_blank" onClick={ () => this.buttonHandler(data.link)} target="_blank">
                            {data.buttonText}
                        </ButtonClick>
                    </ContentWrapper>
            </ThirdSectionWrapper>
        )
    }
}

export default withRouter(LandingPageShowcaseSection);
