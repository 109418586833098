import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import { Main } from '../../styles/Styled';
import MyListingsAll from '../Modules/MyListingsAll';
import { temporaryData } from './temporaryData';
import { withRouter } from 'react-router-dom';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';

class MyListings extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;

        this.state = {
            fakeData: temporaryData,
            currentDataIndex: null,
        };
    }

    currentDataHandler = (index) => {
        this.setState({
            currentDataIndex: index,
        });
    };

    backButtonHandler = () => {
        this.props.history.goBack();
    };

    renderBody() {
        const { category, id, index } = this.props.match.params;
        return (
            <Main>
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    (() => {
                        if (category) {
                            switch (category) {
                                case 'listing':
                                    return (
                                        <MyListingsAll
                                            id={id} 
                                            category={category}
                                            pageIndex={0}
                                            data={this.state.fakeData}
                                            currentDataHandler={
                                                this.currentDataHandler
                                            }
                                            defaultIndex={0}
                                            backButtonHandler={this.backButtonHandler}
                                        />
                                    );
                                case 'privileged':
                                    return (
                                        <MyListingsAll
                                            id={id} 
                                            category={category}
                                            data={this.state.fakeData}
                                            currentDataHandler={
                                                this.currentDataHandler
                                            }
                                            defaultIndex={1}
                                            pageIndex={0}
                                            backButtonHandler={this.backButtonHandler}
                                        />
                                    );
                                case 'featured':
                                    return (
                                        <MyListingsAll
                                            id={id}
                                            category={category}
                                            data={this.state.fakeData}
                                            currentDataHandler={
                                                this.currentDataHandler
                                            }
                                            defaultIndex={2}
                                            pageIndex={0}
                                            backButtonHandler={this.backButtonHandler}
                                        />
                                    );
                                case 'sponsored':
                                    return (
                                        <MyListingsAll
                                            id={id}
                                            category={category}
                                            data={this.state.fakeData}
                                            currentDataHandler={
                                                this.currentDataHandler
                                            }
                                            defaultIndex={3}
                                            pageIndex={0}
                                            backButtonHandler={this.backButtonHandler}
                                        />
                                    );
                                default:
                                    return (
                                        <MyListingsAll
                                            id={id}
                                            category={category}
                                            data={this.state.fakeData}
                                            currentDataHandler={
                                                this.currentDataHandler
                                            }
                                            defaultIndex={0}
                                            pageIndex={0}
                                            backButtonHandler={this.backButtonHandler}
                                        />
                                    );
                            }
                        } else if (id) {
                            return (
                                <MyListingsAll 
                                    id={id}
                                    category={category}
                                   currentDataIndex = {
                                       this.state.currentDataIndex
                                   }
                                   data={this.state.fakeData}
                                   pageIndex={1}
                                   defaultIndex={0}
                                   backButtonHandler={this.backButtonHandler}
                                />
                            );
                        } else if( index) {
                            return (
                                <MyListingsAll 
                                    id={index}
                                    category={category}
                                    currentDataIndex = { this.state.currentDataIndex}
                                    data={this.state.fakeData}
                                    pageIndex={2}
                                    defaultIndex={0}
                                    backButtonHandler={this.backButtonHandler}
                                />
                            )
                        } else {
                            return (
                                <MyListingsAll
                                    id={id} 
                                    category={category}
                                    currentDataIndex = {
                                        this.state.currentDataIndex
                                    }
                                    currentDataHandler={this.currentDataHandler}
                                    data={this.state.fakeData}
                                    defaultIndex={0}
                                    backButtonHandler={this.backButtonHandler}
                                    pageIndex={0}
                                />
                            );
                        }
                    })()
                )}
            </Main>
        );
    }
}

export default withRouter(observer(MyListings));
