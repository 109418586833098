import React, {Component} from "react";
import FormSwitchBox from "../FormSwitchBox";
import {SwitchBoxMediumStyles} from "./Styled";

class FormSwitchBoxDynamic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            switchValue: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && (prevProps.value !== this.props.value)) {
            this.setState({
                switchValue: this.props.value
            });
        }
    }

    switchChangeHandler = (e) => {
        const states = this.props.states,
              switchValue = e.target.checked,
              fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                      fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                      errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[fieldName] = switchValue;
                errorFields[fieldName] = null;
                this.setState({
                    switchValue
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {
        return (
            <FormSwitchBox
                id={this.props.id}
                name={this.props.id}
                type="checkbox"
                checked={this.state.switchValue}
                onChange={this.switchChangeHandler}
                styled={SwitchBoxMediumStyles}
            />
        );
    }
}

export default FormSwitchBoxDynamic;