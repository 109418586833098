import React, { Component, createRef } from 'react';
import { VerificationStyle, Wrapper } from './Styled';
import ButtonText from '../FormButtonText';
import { VerifyButtonStyles } from '../ProfileMenuVerification/Styled';
import { sendAsyncRequest } from '../../../Utils';

export class VerificationInput extends Component {
    constructor(props) {
        super(props);
        this.inputs = createRef();
        this.inputs.current = [];
        this.state = {
            code: [...Array(this.props.length)].map(() => ''),
            sent: false,
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        sendAsyncRequest(
            '/verify/phone-number/verification/send-code/',
            'post',
            {
                mobile_number: this.props.number,
                mobile_type: this.props.type,
            }
        )
            .then((res) => {
                console.log(res);
                if (res.body.IsSuccess) {
                    this.setState({ loading: false, sent: true });
                } else {
                    this.setState({ sent: false, loading: false });
                }
            })
            .catch((err) => {
                console.log('error sending sms:', err);
                this.setState({ loading: false, sent: false });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.number !== this.props.number) {
            sendAsyncRequest(
                '/verify/phone-number/verification/send-code/',
                'post',
                {
                    mobile_number: this.props.number,
                    mobile_type: this.props.type,
                }
            )
                .then((res) => {
                    console.log(res);
                    if (res.body.IsSuccess) {
                        this.setState({ loading: false, sent: true });
                    } else {
                        this.setState({ sent: false, loading: false });
                    }
                })
                .catch((err) => {
                    console.log('error sending sms:', err);
                    this.setState({ loading: false, sent: false });
                });
        }
    }

    processInput = (e, slot) => {
        const num = e.target.value;
        if (/[^0-9]/.test(num)) return;
        const newCode = [...this.state.code];
        newCode[slot] = num;
        this.setState({
            code: newCode,
        });
        console.log(newCode);
        if (slot !== this.props.length - 1) {
            this.inputs.current[slot + 1].focus();
        }
    };

    onKeyUp = (e, slot) => {
        if (e.keyCode === 8 && !this.state.code[slot] && slot !== 0) {
            const newCode = [...this.state.code];
            newCode[slot - 1] = '';
            this.setState({
                code: newCode,
            });
            console.log(newCode);
            this.inputs.current[slot - 1].focus();
        }
    };

    onSubmit = () => {
        console.log(this.state.code.join(''));
        sendAsyncRequest(
            '/verify/phone-number/verification/confirm-code/',
            'post',
            {
                mobile_number: this.props.number,
                code: parseInt(this.state.code.join('')),
            }
        )
            .then((res) => {
                console.log(res);
                if (res.body.IsSuccess) {
                    this.props.onComplete();
                    this.setState({
                        code: [...Array(this.props.length)].map(() => ''),
                    });
                } else {
                    alert('Your code is incorrect!!!');
                }
            })
            .catch((err) => console.log(err.Message));
        this.props.onComplete();
    };

    render() {
        return (
            <Wrapper>
                {this.state.loading ? (
                    <p>loading...</p>
                ) : this.state.sent ? (
                    <>
                        <VerificationStyle className="code-input">
                            <div className="code-inputs">
                                {this.state.code.map((num, idx) => {
                                    return (
                                        <input
                                            key={idx}
                                            type="text"
                                            inputMode="numeric"
                                            maxLength={1}
                                            value={num}
                                            autoFocus={
                                                !this.state.code[0].length &&
                                                idx === 0
                                            }
                                            readOnly={this.props.loading}
                                            onChange={(e) =>
                                                this.processInput(e, idx)
                                            }
                                            onKeyUp={(e) =>
                                                this.onKeyUp(e, idx)
                                            }
                                            ref={(ref) => {
                                                this.inputs.current.push(ref);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </VerificationStyle>
                        <ButtonText
                            styled={VerifyButtonStyles}
                            onClick={this.onSubmit}
                        >
                            Verify
                        </ButtonText>
                    </>
                ) : (
                    <p>Problem sending code</p>
                )}
            </Wrapper>
        );
    }
}

export default VerificationInput;
