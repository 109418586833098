import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 96px);
    padding-left: 5%;
    padding-right: 5%;

    p {
        font-size: 14px;
        font-weight: lighter;
        color: ${(props) => props.theme.textGrayLight};
    }
`;

export const DefaultOption = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    margin: 0;
    padding-bottom: 16px;
    padding-left: 1%;

    p {
        font-size: 22px;
        color: ${(props) => props.theme.textBlack};
        margin: 0;
        text-align: left;
    }
`;

export const MenuOptionSelect = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    margin: 0;
    padding-bottom: 20px;
    padding-left: 1%;
    padding-top: 20px;

    p {
        font-size: 18px;
        font-weight: lighter;
        color: ${(props) => props.theme.textGrayLight};
        margin: 0;
        text-align: left;
        height: 30px;
        width: 45%;
    }

    > button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        background: transparent;
        border: 0;
        width: 55%;
        height: 30px;
        cursor: pointer;
        span {
            font-size: 18px;
            font-weight: bold;
            color: ${(props) => props.theme.textBlack};
            margin: 0;
            text-align: left;
        }

        h5 {
            font-size: 18px;
            color: ${(props) => props.theme.textGrayLight};
            margin: 0;
            text-align: left;
        }

        .icon {
            position: relative;
            margin-left: 14px;
        }
    }
`;

export const ModalWrapper = styled.div``;

export const ModalWrapperContent = styled.div`
    margin-top: ${(props) => (props.margin ? props.margin : 40)}px;
    text-align: left;
    & > div {
        width: 100%;
    }
`;

export const RadioBoxStyles = css`
    margin-left: 25px;
    padding-left: 45px;
    padding-bottom: 20px;
    padding-top: 12px;
    em {
        top: 20px;
        width: 22px;
        height: 22px;

        &:after {
            top: 3px;
            left: 3px;
            width: 16px;
            height: 16px;
        }
    }
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    font-size: 21px;
    color: ${(props) => props.theme.textBlack};
`;

export const SaveButtonSubmit = css`
    width: 30%;
    height: 40px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 17px;
    color: white;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        to right,
        ${(props) => props.theme.cyan} 1%,
        ${(props) => props.theme.cyanDark}
    );
`;

export const ApplyButtonSubmit = css`
    width: 90%;
    height: 50px;
    margin-left: 5%;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 18px;
    color: white;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        to right,
        ${(props) => props.theme.cyan} 1%,
        ${(props) => props.theme.cyanDark}
    );
`;
