import React, { Component } from 'react';
import {
    Address,
    BedBath,
    LeftItems,
    RightItems,
    Tags,
    Title,
    Wrapper,
} from './Styled';
import IconNew from '../IconNew';
import { ClearFloats } from '../BaseTemplate/Styled';
import ButtonBookmarkItem from '../ButtonBookmarkItem';
import ListingSharedCounts, {
    SharedCountsType,
} from '../../ListingSharedCounts';
import { sendAsyncRequest } from '../../../Utils';

export default class ListingTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liked: props.listing.liked,
            totalLikes: props.listing.total_likes,
            saved: props.listing.saved,
        };
    }

    onLike = () => {
        if (!this.state.liked) {
            this.setState({ liked: true });
            sendAsyncRequest('/property/like', 'post', {
                listing_id: this.props.id,
            })
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ liked: false });
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            totalLikes: prevState.totalLikes + 1,
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ liked: false });
                });
        } else {
            this.setState({ liked: false });
            sendAsyncRequest(`/property/unlike/${this.props.id}`, 'delete', {})
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ liked: true });
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            totalLikes: prevState.totalLikes - 1,
                        }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ liked: true });
                });
        }
    };

    onSave = () => {
        if (!this.state.saved) {
            this.setState({ saved: true });
            sendAsyncRequest('/property/save/', 'post', {
                listing_id: this.props.id,
            })
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ saved: false });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ saved: false });
                });
        } else {
            this.setState({ saved: false });
            sendAsyncRequest(`/property/unsave/${this.props.id}/`, 'delete', {})
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ saved: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ saved: true });
                });
        }
    };

    render() {
        const listing = { ...this.props.listing };
        return (
            <Wrapper>
                <ButtonBookmarkItem
                    listingId={listing.pk}
                    bookmarked={listing.saved}
                    left={40}
                    top={-60}
                    big
                />

                <LeftItems>
                    <Title>
                        {listing.name}
                        <span>
                            {listing.property_type} - {listing.listing_type}
                        </span>
                    </Title>
                    <Address>
                        <IconNew bp1="-62" bp2="-77" iwidth="12" iheight="17" />
                        {listing.state}
                        <br />
                        MLSID #{listing.mls_id}
                    </Address>
                    <ListingSharedCounts
                        listingId={listing.pk}
                        saved={this.state.saved}
                        liked={this.state.liked}
                        totalLikes={this.state.totalLikes}
                        onLike={this.onLike}
                        onSave={this.onSave}
                        listing={listing}
                        type={SharedCountsType.BIG}
                    />
                </LeftItems>

                <RightItems>
                    <strong className="price">
                        {listing.price_unit}
                        {listing.formated_price} / m
                    </strong>
                    <BedBath>
                        <IconNew
                            bp1="-326"
                            bp2="-77"
                            iwidth="27"
                            iheight="25"
                            className="bed"
                        />
                        <span>
                            {listing.bedrooms} <small>BED</small>
                        </span>
                        <IconNew
                            bp1="-326"
                            bp2="-133"
                            iwidth="23"
                            iheight="28"
                            className="bath"
                        />
                        <span>
                            {listing.bathrooms} <small>BATH</small>
                        </span>
                        <IconNew
                            bp1="-326"
                            bp2="-104"
                            iwidth="28"
                            iheight="27"
                            className="area"
                        />
                        <span>
                            {listing.lot_area}{' '}
                            <small>{listing.lot_area_unit}</small>
                        </span>
                    </BedBath>
                    <Tags>
                        <IconNew
                            bp1="-358"
                            bp2="-75"
                            iwidth="119"
                            iheight="26"
                        />
                        <IconNew
                            bp1="-358"
                            bp2="-159"
                            iwidth="47"
                            iheight="26"
                        />
                        <IconNew
                            bp1="-358"
                            bp2="-103"
                            iwidth="109"
                            iheight="26"
                        />
                        <IconNew
                            bp1="-358"
                            bp2="-131"
                            iwidth="60"
                            iheight="26"
                        />
                    </Tags>
                </RightItems>

                <ClearFloats />

                {/*<ActionTimeName>
                    
                    <ActionTime iSize={this.props.showSmallImage ? "40" : "60"}>
                        <Time>
							{timeAgo(this.props.addDate)}
                        </Time>
                        
                        <ListingAction 
                            listingID={this.props.listingID} 
                            isOwner={this.props.isOwner}
                            listingLink={this.props.listingLink}
                            handleHideListing={this.props.handleHideListing}
                            isListingDetails={this.props.isListingDetails}
                        />                           
                        
                    </ActionTime>

                    <ShowImageName 
                        iSize={this.props.showSmallImage ? "40" : "60"} 
                        iImageSrc={this.props.userImage} 
                        iName={this.props.userName} 
                        iLocation={this.props.address} 
                        iColor="black" 
                        iLink={"/" + this.props.userName}
                    />
                    
                </ActionTimeName>*/}
            </Wrapper>
        );
    }
}
