import React, { Component, Fragment } from 'react';
import {
    ErrorMessage,
    InputLabel,
    InputLabelAnimate,
    InputText,
    InputWrapper,
} from './Styled';
import IconNew from '../IconNew';
import PropTypes from 'prop-types';
import { commaSeparator, getInputNameAndValue } from '../../../Utils';

class FormInputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputPlaceholder: true,
            isPasswordShow: false,
            value: props.hasOwnProperty('numSeparatorValue')
                ? props.numSeparatorValue
                : props.value,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }

    inputFocusHandler = () => {
        this.setState({
            inputPlaceholder: false,
        });
    };

    inputBlurHandler = () => {
        this.setState({
            inputPlaceholder: true,
        });
    };

    inputKeyDownHandler = (e) => {
        if (e.key === 'Enter' && this.props.onEnter) {
            this.props.onEnter();
            this.inputBlurHandler();
        } else if (this.props.onKey) {
            this.props.onKey(e.key);
            // Prevent Browser Scrolling
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault();
                return false;
            }
        }
    };

    showPasswordHandler = () => {
        this.setState((prevState) => {
            return { isPasswordShow: !prevState.isPasswordShow };
        });
    };


    render() {
        return (
            <Fragment>
                {this.props.label && (
                    <InputLabel
                        htmlFor={this.props.id}
                        styled={this.props.labelStyled}
                    >
                        {this.props.label}
                        {this.props.requiredStar && <span> * </span>}
                    </InputLabel>
                )}

                <InputWrapper
                    styled={this.props.wrapperStyled}
                    dropdown={this.props.dropdown}
                    keyy={this.props.id}
                    isError={this.props.errorMessage === "Password must be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."}
                >
                    <InputText
                        type={
                            this.props.type
                                ? this.state.isPasswordShow
                                    ? 'text'
                                    : this.props.type
                                : 'text'
                        }
                        key={this.props.id}
                        id={this.props.id}
                        name={this.props.name}
                        value={
                            this.props.hasOwnProperty('onChange') &&
                            !this.props.dropdown
                                ? undefined // special way to keeps the component Controlled
                                : this.state.value || ''
                        }
                        styled={this.props.styled}
                        extraStyled={this.props.extraStyled}
                        placeholder={
                            this.state.inputPlaceholder
                                ? this.props.placeholder
                                : ''
                        }
                        onFocus={
                            (e) =>
                                this.props.hasOwnProperty('onFocus')
                                    ? this.props.onFocus(e) // The page handle focus itself
                                    : this.inputFocusHandler() // Focus handled by default
                        }
                        onBlur={
                            (e) =>
                                this.props.hasOwnProperty('onBlur')
                                    ? this.props.onBlur(e) // The page handle blur itself
                                    : this.inputBlurHandler() // Blur handled by default
                        }
                        onKeyDown={this.inputKeyDownHandler}
                        onChange={(e) =>
                            this.props.hasOwnProperty('onChange')
                                ? this.props.onChange(e) // The page handle changes itself
                                : !this.props.disableOnChange &&
                                  handleInputChange(
                                      // Changes handled by default
                                      e,
                                      this.props.states,
                                      this.props.hasOwnProperty(
                                          'numSeparatorValue'
                                      ),
                                      this.props.extraLocalStatesOnChange,
                                      this
                                  )
                        }
                        onInput={this.props.onInput}
                        onClick={this.props.onClick}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        readOnlyValue={this.props.readOnlyValue}
                        required={this.props.required}
                        className={this.props.className}
                        isLabelAnimate={this.props.labelAnimate}
                        animateStyled={this.props.labelAfterAnimateStyled}
                        dropdown={this.props.dropdown}
                        ref={this.props.refSource}
                        autoComplete={this.props.autoComplete}
                    />
                    {this.props.labelAnimate && (
                        <InputLabelAnimate
                            htmlFor={this.props.id}
                            styled={this.props.labelAnimateStyled}
                            dropdown={this.props.dropdown}
                        >
                            {this.props.labelAnimate}
                            {this.props.requiredStar && <span> * </span>}
                        </InputLabelAnimate>
                    )}
                    {this.props.icon && !this.props.iconText && this.props.icon}
                    {this.props.iconText && (
                        <div>
                            {this.props.icon}
                            {this.props.iconText}
                        </div>
                    )}
                    {this.props.showPassword && this.state.isPasswordShow ? (
                        <IconNew
                            bp1="-24"
                            bp2="-196"
                            iwidth="24"
                            iheight="14"
                            onClick={this.showPasswordHandler}
                            className="clickable"
                        />
                    ) : (
                        this.props.showPassword &&
                        !this.state.isPasswordShow && (
                            <IconNew
                                bp1="0"
                                bp2="-194"
                                iwidth="24"
                                iheight="18"
                                onClick={this.showPasswordHandler}
                                className="clickable"
                            />
                        )
                    )}
                    {this.props.children}
                    {this.props.errorMessage && (
                        <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
                    )}
                </InputWrapper>
            </Fragment>
        );
    }
}

export default FormInputText;

export const handleInputChange = (
    event,
    states,
    isNumSeparator = false,
    extraLocalStatesOnChange = false,
    inputThis = false
) => {
    if (inputThis) {
        inputThis.setState({
            value: event.target.value,
        });
    }
    if (!states) return;
    states.forEach((that) => {
        const isGlobalState = that.constructor.name === 'Object',
            inputData = getInputNameAndValue(event),
            fields = isGlobalState
                ? that.getFields()
                : { ...that.state.fields },
            errorFields = isGlobalState
                ? that.getErrorFields()
                : { ...that.state.errorFields };
        fields[inputData.name] = isNumSeparator
            ? inputData.value.replace(/,/g, '')
            : inputData.value;
        errorFields[inputData.name] = null;
        if (isNumSeparator) {
            fields[inputData.name + '-displayOnly'] = commaSeparator(
                inputData.value.replace(/,/g, '')
            );
        }
        if (isGlobalState) {
            that.setFields(fields);
            that.setErrorFields(errorFields);
        } else {
            if (!extraLocalStatesOnChange) {
                that.setState({
                    fields,
                    errorFields,
                });
            } else {
                that.setState({
                    fields,
                    errorFields,
                    ...extraLocalStatesOnChange,
                });
            }
        }
    });
};

FormInputText.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    styled: PropTypes.array, // styled-component -> css
    extraStyled: PropTypes.array, // styled-component -> css
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClick: PropTypes.func,
    onKey: PropTypes.func,
    onEnter: PropTypes.func,
    wrapperStyled: PropTypes.array, // styled-component -> css
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    readOnlyValue: PropTypes.bool,
    required: PropTypes.bool,
    requiredStar: PropTypes.bool,
    className: PropTypes.string,
    labelAnimateStyled: PropTypes.array, // styled-component -> css
    labelAfterAnimateStyled: PropTypes.array, // styled-component -> css
    dropdown: PropTypes.bool,
    disableOnChange: PropTypes.bool,
    label: PropTypes.string,
    showPassword: PropTypes.bool,
    errorMessage: PropTypes.string,
    ref: PropTypes.any,
    icon: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array, // styled-component -> css (Loadings)
        PropTypes.string, // Usually '' string
        PropTypes.instanceOf(IconNew),
    ]),
    iconText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(IconNew),
    ]),
    labelAnimate: (props, propName, componentName) => {
        if (
            typeof props.labelAnimate !== 'string' &&
            typeof props.labelAnimate !== 'undefined'
        ) {
            return new Error(
                `'${componentName}': Type of 'labelAnimate' should be 'string'.`
            );
        }
        if (props.labelAnimate && !props.required) {
            return new Error(
                `'${componentName}': Type of 'labelAnimate' needs 'required=true' for its animation functionality.`
            );
        }
    },
    value: (props, propName, componentName) => {
        if (props.value && props.hasOwnProperty('numSeparatorValue')) {
            return new Error(
                `You shouldn't use 'value' & 'numSeparatorValue' together in '${componentName}'.`
            );
        }
    },
    numSeparatorValue: (props, propName, componentName) => {
        if (props.value && props.hasOwnProperty('numSeparatorValue')) {
            return new Error(
                `You shouldn't use 'value' & 'numSeparatorValue' together in '${componentName}'.`
            );
        }
    },
    onChange: (props, propName, componentName) => {
        if (props.onChange && props.states && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'states' together in '${componentName}'.`
            );
        }
        if (
            props.onChange &&
            props.extraLocalStatesOnChange &&
            !props.dropdown
        ) {
            return new Error(
                `You shouldn't use 'onChange' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
        if (props.onChange && props.disableOnChange) {
            return new Error(
                `You shouldn't use 'onChange' & 'disableOnChange' together in '${componentName}'.`
            );
        }
        if (props.onChange && props.value) {
            return new Error(
                "You shouldn't use 'onChange' & 'value' together in '" +
                    componentName +
                    "'. " +
                    "The 'onChange' attribute used internally. If you want to handle the change yourself, " +
                    "try to use 'onInput' with 'value'."
            );
        }
    },
    states: (props, propName, componentName) => {
        if (props.onChange && props.states && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'states' together in '${componentName}'.`
            );
        }
        if (
            props.onChange &&
            props.extraLocalStatesOnChange &&
            !props.dropdown
        ) {
            return new Error(
                `You shouldn't use 'onChange' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
    extraLocalStatesOnChange: (props, propName, componentName) => {
        if (props.onChange && props.states && !props.dropdown) {
            return new Error(
                `You shouldn't use 'onChange' & 'states' together in '${componentName}'.`
            );
        }
        if (
            props.onChange &&
            props.extraLocalStatesOnChange &&
            !props.dropdown
        ) {
            return new Error(
                `You shouldn't use 'onChange' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
};
