import React, { Component, Fragment } from 'react';
import PropertyInput from '../FormInputText';
import IconNew from '../IconNew';
import {
    Wrapper,
    InputLabel,
    CommentInputStyles,
    Comments,
    Comment,
    CommentOnPage,
    CommentLoadMore,
} from './Styled';
import PropTypes from 'prop-types';
import { ClearFloats } from '../BaseTemplate/Styled';
import { Separator } from '../ListingOnFeed/Styled';
import { sendAsyncRequest, timeAgo } from '../../../Utils';

class ListingComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liked: props.liked,
        };
    }

    onLike = () => {
        if (!this.state.liked) {
            this.setState({ liked: true });
            sendAsyncRequest('/comment/like', 'post', {
                comment_id: this.props.comment.pk,
            })
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ liked: false });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ liked: false });
                });
        } else {
            this.setState({ liked: false });
            sendAsyncRequest(
                `/comment/unlike/${this.props.comment.pk}`,
                'delete',
                {}
            )
                .then((res) => {
                    if (res.status > 299) {
                        this.setState({ liked: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ liked: true });
                });
        }
    };

    render() {
        const comment = this.props.comment;
        return (
            <Comment>
                <h3>
                    <img src={comment.owner.profile} alt="" />
                    <span>{comment.owner.username}</span>
                    <em>{timeAgo(comment.add_date)}</em>
                </h3>
                <ClearFloats />
                <p>{comment.text}</p>
                <button onClick={this.onLike}>
                    {!this.state.liked ? (
                        <IconNew
                            bp1="-56"
                            bp2="-118"
                            iwidth="22"
                            iheight="19"
                        />
                    ) : (
                        <IconNew
                            bp1="-80"
                            bp2="-118"
                            iwidth="20"
                            iheight="17"
                        />
                    )}
                </button>
                <button className="comment">
                    <IconNew bp1="-257" bp2="-105" iwidth="25" iheight="23" />
                </button>
            </Comment>
        );
    }
}

class ListingComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingLike: false,
            loadingMore: false,
            inputValue: '',
            comments: props.comments ? [...props.comments] : [],
        };
        this.page = 2;
        this.pageSize = 5;
    }

    sendCommentHandler = async () => {
        const inputValue = this.state.inputValue;
        if (!inputValue.trim()) {
            return false;
        }
        this.setState({
            loading: true,
        });
        const result = await sendAsyncRequest('/comment/', 'post', {
            listing_id: this.props.listingId,
            text: inputValue,
        });
        if (result.status === 201) {
            console.log(result);
            const comments = [...this.state.comments];
            comments.unshift(result.body);
            console.log(comments);
            this.setState({
                comments,
                inputValue: '',
                loading: false,
            });
        } else {
            alert('Error'); // TODO :: Change to Server Message
            this.setState({
                loading: false,
            });
        }
    };

    sendCommentChangeHandler = (e) => {
        this.setState({
            inputValue: e.target.value,
        });
    };

    likeClickHandler = async (commentId) => {
        this.setState({
            loadingLike: true,
        });
        const result = await sendAsyncRequest('/comment/like', 'post', {
            comment_id: commentId,
        });
        if (result.status !== 201) {
            alert('Error'); // TODO :: Change to Server Message
        }
        this.setState({
            loadingLike: false,
        });
    };

    moreCommentClickHandler = async () => {
        this.setState({
            loadingMore: true,
        });
        const url = `/comment/list/${this.props.listingId}?page=${
            this.page
        }&page_size=${this.pageSize}`;
        const result = await sendAsyncRequest(url, 'get', {});
        if (result.status === 200) {
            const comments = [...result.body.results, ...this.state.comments];
            this.setState({
                comments,
                loadingMore: false,
            });
            this.page++;
        } else {
            alert('Error'); // TODO :: Change to Server Message
            this.setState({
                loadingMore: false,
            });
        }
    };

    render() {
        return (
            <Wrapper>
                {this.props.type === CommentType.LISTING_PAGE && (
                    <Fragment>
                        {this.state.comments.map((comment) => (
                            <CommentOnPage
                                key={comment.pk}
                                special={comment.special}
                            >
                                <h3>
                                    <i>
                                        <img
                                            src={comment.image}
                                            alt={comment.owner}
                                        />
                                    </i>
                                    <span>{comment.owner}</span>
                                    <em>
                                        <IconNew
                                            bp1="-56"
                                            bp2="-118"
                                            iwidth="22"
                                            iheight="19"
                                        />
                                        <IconNew
                                            bp1="-229"
                                            bp2="-105"
                                            iwidth="25"
                                            iheight="23"
                                        />
                                        {timeAgo(comment.add_date)}
                                    </em>
                                </h3>
                                <p>{comment.text}</p>
                            </CommentOnPage>
                        ))}

                        <br />
                    </Fragment>
                )}

                <InputLabel disabled={this.state.loading}>
                    <PropertyInput
                        id={'listingCommentInput-' + this.props.listingId}
                        name={'listingCommentInput-' + this.props.listingId}
                        type="text"
                        styled={CommentInputStyles}
                        placeholder="Write a comment ..."
                        value={this.state.inputValue}
                        onInput={this.sendCommentChangeHandler}
                        onEnter={this.sendCommentHandler}
                        disabled={this.state.loading}
                    />
                    <IconNew
                        bp1="0"
                        bp2="-115"
                        iwidth="27"
                        iheight="27"
                        className="emoticon"
                    />
                    <IconNew
                        bp1="-32"
                        bp2="-118"
                        iwidth="22"
                        iheight="20"
                        className="send"
                        onClick={this.sendCommentHandler}
                    />
                </InputLabel>

                {this.props.type === CommentType.LISTING_PAGE && (
                    <CommentLoadMore
                        onClick={this.moreCommentClickHandler}
                        disabled={this.state.loadingMore}
                    >
                        + Load More
                    </CommentLoadMore>
                )}

                {this.props.type === CommentType.HOME_PAGE && (
                    <Fragment>
                        <ClearFloats />
                        {this.state.comments.length > 0 && (
                            <Fragment>
                                <Separator height={45} />
                                <Comments>
                                    {this.state.comments.map((comment, index) => (
                                        <ListingComment
                                            key={index}
                                            comment={comment}
                                        />
                                    ))}
                                </Comments>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Wrapper>
        );
    }
}

export default ListingComments;

export const CommentType = {
    HOME_PAGE: 'home_page',
    LISTING_PAGE: 'listing_page',
};

ListingComments.propTypes = {
    listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    type: PropTypes.string.isRequired,
};
