import React, {Component} from 'react';
import {Wrapper, IconWrapper} from "./Styled";
import {ClearFloats} from "../BaseTemplate/Styled";

class ProfileGroupTypeSocialIcons extends Component {
    render() {
        return (
            <Wrapper>
                {
                    this.props.items.map(
                        (item, index) => {
                            return (
                                <IconWrapper
                                    key={'social-'+index}
                                    href={item.to}
                                    target="_blank"
                                >
                                    {item.icon}
                                </IconWrapper>
                            );
                        }
                    )
                }
                <ClearFloats/>
            </Wrapper>
        );
    }
}

export default ProfileGroupTypeSocialIcons;