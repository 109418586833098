import styled, {css} from "styled-components";
import {ArrowLeft, ArrowRight} from "../BaseTemplate/Styled";
import {WizardNavigationType} from "./WizardItemNavigation";

export const NextPrevButtons = css`
    button {
        float: right;
        width: 140px; height: 85px;
        box-sizing: border-box;
        padding-bottom: 4px;
        font-family: ${props => props.theme.mainFont};
        font-size: 23px;
        font-weight: bold;
        color: ${props => props.theme.cyan};
        border: none;
        border-left: solid 1px ${props => props.theme.textGrayLight};
        background: transparent;
        cursor: pointer;
    }
    button:last-of-type {
        float: left;
        width: 85px;
        border-left: none;
        border-right: solid 1px ${props => props.theme.textGrayLight};
    }
    button.no-pointer {
        cursor: default;
    }
`;

export const Wrapper = styled.div`
    box-sizing: border-box;
    height: 85px;
    margin-top: 85px;
    border: solid 1px ${props => props.theme.textGrayLight};
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    ${
        props => (props.type === WizardNavigationType.NEXT_PREV) &&
            NextPrevButtons
    }
    ${
        props => (props.type === WizardNavigationType.DRAFT) &&
            `
            padding: 12px;
            `
    }
`;

export const ArrowRightNav = styled(ArrowRight)`
    margin: 0 0 0 10px;
    position: relative;
    top: 4px;
    &:after, &:before {
        background: ${props => props.theme.cyan};
        width: 1.0rem;
        height: 0.2rem;
    }
    &:after {
        top: -2px;
    }
`;

export const ArrowLeftNav = styled(ArrowLeft)`
    margin: 0 14px 0 0;
    position: relative;
    top: 4px;
    &:after, &:before {
        background: ${props => props.theme.mainFontColor};
        width: 1.0rem;
        height: 0.2rem;
    }
    &:after {
        top: -2px;
    }
`;

export const NavButtonStyles = css`
    height: 60px;
    border-radius: 10px;
    background: ${props => props.theme.cyan};
    background: linear-gradient(
            to right,
            ${props => props.theme.cyan} 0%,
            ${props => props.theme.ceruleanBlue} 100%
        );
    border: none;
    color: #fff;
    font-size: 22px;
    font-weight: lighter;
    padding: 0 25px;
    float: right;
    span {
        margin-left: 40px;
        &:after, &:before {
            background: #fff;
        }
    }
`;

export const NavButtonBlankStyles = css`
    ${ NavButtonStyles };
    border: solid 1px ${props => props.theme.textGrayLight};
    background: #fff;
    font-weight: normal;
    color: ${props => props.theme.textBlack};
    margin-right: 10px;
    float: right;
`;

export const NavButtonRedStyles = css`
    ${ NavButtonStyles };
    border: solid 1px ${props => props.theme.noticeRed};
    background: #fff;
    font-weight: normal;
    color: ${props => props.theme.noticeRed};
    padding-right: 80px;
    float: left;
    position: relative;
    i {
        position: absolute;
        right: 15px;
        top: 22px;
        width: 16px;
        height: 16px;
    }
    i:before, i:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 16px;
        width: 2px;
        background-color: ${props => props.theme.noticeRed};
    }
    i:before {
        transform: rotate(45deg);
    }
    i:after {
        transform: rotate(-45deg);
    }
`;

export const NavLoading = styled.div`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: ${props => props.theme.textBlack};
    opacity: .2;
`;