import React, { Component, Fragment } from 'react';
import {
    Title,
    Section,
    TimeSelectorStyles,
    ListingWrapper,
    ListingContainer,
    DataWrapper,
    LeadDatas,
    LeadCircle,
    LeadTitle,
    LeadNumber,
    MainCircle,
    MeetingWrapper,
    PremiumContainer,
    PackageContainer,
    Tick,
    GrayArrowRight,
} from './Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import SelectorPopUp from '../SelectorPopUp';
import PackageButton from './PackageButton';
import StatisticsChart from '../StatisticsChart';
import IconNew from '../IconNew';
import { sendAsyncRequest } from '../../../Utils';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showType: { text: 'Profile Views', value: 'profile_views' },
            showTypePop: false,
            period: { text: 'Daily', value: 'daily' },
            periodPop: false,
            statisticsData: { max: 0, values: [] },
            loading: false,
        };
        this.showTypes = [
            { text: 'Profile Views', value: 'profile_views' },
            { text: 'Connections', value: 'connections' },
            { text: 'Likes', value: 'likes' },
            { text: 'Followers', value: 'followers' },
            { text: 'Listing Views', value: 'listing_views' },
            { text: 'Comments', value: 'comments' },
        ];
        this.timePeriods = [
            { text: 'Daily', value: 'daily' },
            { text: 'Weekly', value: 'weekly' },
            { text: 'Monthly', value: 'monthly' },
            { text: 'Yearly', value: 'yearly' },
        ];
        this.lisitings = [
            {
                id: 12312,
                users: 12,
                viewed: 140,
                searched: 28,
                saved: 7,
                comments: 15,
                likes: 117,
                shared: 10,
            },
            {
                id: 12312,
                users: 12,
                viewed: 140,
                searched: 28,
                saved: 7,
                comments: 15,
                likes: 117,
                shared: 10,
            },
            {
                id: 12312,
                users: 12,
                viewed: 140,
                searched: 28,
                saved: 7,
                comments: 15,
                likes: 117,
                shared: 10,
            },
        ];
        this.leads = {
            newLeads: 23,
            inProgress: 7,
            closed: 11,
        };
        this.meetings = [
            'Meeting - 2July - 11:20 AM',
            'Meeting - 2July - 11:20 AM',
            'Meeting - 2July - 11:20 AM',
            'Meeting - 2July - 11:20 AM',
        ];
    }

    componentDidMount() {
        this.getChartData(false, false);
    }

    getChartData = (type, period) => {
        this.setState({ loading: true });
        sendAsyncRequest('/profile/stats/', 'post', {
            stat_type: type ? type.value : this.state.showType.value,
            period_type: period ? period.value : this.state.period.value,
        })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        statisticsData: res.body.Result,
                        loading: false,
                    });
                }
            })
            .catch((err) => console.log('statistics error:' + err));
    };

    render() {
        let allLeads =
            this.leads.closed + this.leads.inProgress + this.leads.newLeads;
        return (
            <Fragment>
                <Title>Dashboard</Title>
                <Section>
                    <h3>Statistics</h3>

                    <SelectorPopUp
                        value={this.state.showType}
                        onChange={(type) => {
                            this.setState({ showType: type });
                            this.getChartData(type, false);
                        }}
                        color={'textBlack'}
                        items={this.showTypes}
                    />

                    <SelectorPopUp
                        value={this.state.period}
                        onChange={(period) => {
                            this.setState({ period });
                            this.getChartData(false, period);
                        }}
                        color={'mainFontColor'}
                        items={this.timePeriods}
                        styled={TimeSelectorStyles}
                    />
                    <ClearFloats />
                    {!this.state.loading && (
                        <StatisticsChart data={this.state.statisticsData} />
                    )}
                </Section>
                <Section>
                    <h3>My listings</h3>
                    <ListingContainer>
                        {this.lisitings.map((listing, index) => (
                            <ListingWrapper key={index}>
                                <img alt="property" />
                                <h4>#{listing.id}</h4>
                                <div>
                                    <span>
                                        <IconNew
                                            bp1="-399"
                                            bp2="-492"
                                            iwidth="21"
                                            iheight="19"
                                        />
                                        <p>{listing.users}</p>
                                    </span>
                                    <span>
                                        <IconNew
                                            bp1="-422"
                                            bp2="-493"
                                            iwidth="26"
                                            iheight="18"
                                        />
                                        <p>{listing.viewed}</p>
                                    </span>
                                    <span>
                                        <IconNew
                                            bp1="-449"
                                            bp2="-494"
                                            iwidth="17"
                                            iheight="17"
                                        />
                                        <p>{listing.searched}</p>
                                    </span>
                                    <span>
                                        <IconNew
                                            bp1="-467"
                                            bp2="-491"
                                            iwidth="16"
                                            iheight="20"
                                        />
                                        <p>{listing.saved}</p>
                                    </span>
                                    <span>
                                        <IconNew
                                            bp1="-483"
                                            bp2="-493"
                                            iwidth="18"
                                            iheight="18"
                                        />
                                        <p>{listing.comments}</p>
                                    </span>
                                    <span>
                                        <IconNew
                                            bp1="-503"
                                            bp2="-494"
                                            iwidth="19"
                                            iheight="17"
                                        />
                                        <p>{listing.likes}</p>
                                    </span>
                                    <span>
                                        <IconNew
                                            bp1="-522"
                                            bp2="-493"
                                            iwidth="23"
                                            iheight="18"
                                        />
                                        <p>{listing.shared}</p>
                                    </span>
                                </div>
                            </ListingWrapper>
                        ))}
                    </ListingContainer>
                </Section>
                <Section>
                    <h3>Lead Management</h3>
                    <DataWrapper>
                        <LeadDatas>
                            <LeadCircle color={'violet'} />
                            <LeadTitle color={'violet'}>New Leads</LeadTitle>
                            <LeadNumber color={'violet'}>{this.leads.newLeads}</LeadNumber>
                        </LeadDatas>
                        <LeadDatas>
                            <LeadCircle color={'cheeseYellow'} />
                            <LeadTitle>In Progress</LeadTitle>
                            <LeadNumber>{this.leads.inProgress}</LeadNumber>
                        </LeadDatas>
                        <LeadDatas>
                            <LeadCircle color={'textGrayLight'} />
                            <LeadTitle>Closed</LeadTitle>
                            <LeadNumber>{this.leads.closed}</LeadNumber>
                        </LeadDatas>
                    </DataWrapper>
                    <MainCircle
                        newLeads={(this.leads.newLeads / allLeads) * 100}
                        inProgress={(this.leads.inProgress / allLeads) * 100}
                    >
                        <div>{this.leads.newLeads}</div>
                    </MainCircle>
                    <ClearFloats />
                </Section>
                <Section>
                    <h3>My next meetings</h3>
                    {this.meetings.map((meeting, index) => (
                        <MeetingWrapper>
                            <IconNew
                                bp1="-528"
                                bp2="-426"
                                iwidth="27"
                                iheight="25"
                            />
                            <p>{meeting}</p>
                        </MeetingWrapper>
                    ))}
                </Section>
                <PremiumContainer>
                    <button>
                        <h3>upgrade now to</h3>
                        <h1>Premium Package</h1>
                        <span>
                            <h2>$49</h2>
                            <h4>/month</h4>
                        </span>
                        <ClearFloats />
                    </button>
                </PremiumContainer>
                <PackageContainer>
                    <button>
                        <PackageButton />
                        <p>Your current package</p>
                        <h3>
                            <Tick />
                            Basic Membership
                        </h3>
                        <h6>
                            Details <GrayArrowRight />
                        </h6>
                    </button>
                </PackageContainer>
                <ClearFloats />
            </Fragment>
        );
    }
}

export default Dashboard;
