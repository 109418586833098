import React, {Component} from "react";
import {ArrowDownGray, Note, PreviewButtonStyles, RadioBoxStyles, Wrapper} from "./Styled";
import ButtonText from "../FormButtonText";
import IconNew from "../IconNew";
import RadioButton from "../FormRadioButton";

class WizardItemExpandBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpand: props.isExpand
        }
    }

    expandCollapseHandler = () => {
        this.setState((prevState) => {
            return {isExpand: !prevState.isExpand};
        });
    };

    radioChangeHandler = (e) => {
        e.stopPropagation();
        this.props.onChange();
    };

    previewButtonClickHandler = (e) => {
        e.stopPropagation();
        alert("Preview");
    };

    render() {
        return (
            <Wrapper>
                <h4 onClick={this.expandCollapseHandler}>
                    <RadioButton
                        id={this.props.id}
                        name={this.props.name}
                        styled={RadioBoxStyles}
                        label={this.props.title}
                        checked={this.props.checked || false}
                        onChange={this.radioChangeHandler}
                    />
                    <ButtonText
                        styled={PreviewButtonStyles}
                        onClick={this.previewButtonClickHandler}
                    >
                        <IconNew bp1="0" bp2="-214" iwidth="25" iheight="14"/>
                        Preview
                    </ButtonText>
                </h4>
                {
                    (this.state.isExpand) &&
                    <p>
                        {
                            (this.props.noteText === "Note")
                                ?
                                <>
                                    <Note isBlock={false}>{this.props.noteText}:</Note>
                                    {this.props.note}
                                </>
                                :
                                <>
                                    <Note isBlock={true}>{this.props.noteText}:</Note>
                                    <span dangerouslySetInnerHTML={{__html: this.props.note}}/>
                                </>
                        }
                    </p>
                }
                <ArrowDownGray
                    isExpand={(this.state.isExpand)}
                    onClick={this.expandCollapseHandler}
                />
            </Wrapper>
        );
    }
}

export default WizardItemExpandBox;