import styled, {css} from 'styled-components';

export const AgentItem = styled.div`
    position: relative;
    width: calc(50% - 25px);
    float: left;
    box-sizing: border-box;
    margin: 0 50px 15px 0;
    padding-bottom: 15px;
    border-bottom: solid 2px ${props => props.theme.grayBorderLight};
    &:nth-of-type(2n) {
        margin-right: 0;
    }
    ${
        props => (!props.border) && ` border-bottom: none; `
    };
    img {
        float: left;
        width: 68px; height: 68px;
        border-radius: 5px;
    }
    div {
        float: left;
        margin-left: 15px;
        h3 {
            margin: 5px 0 0;
            font-size: 22px;
            color: ${props => props.theme.darkBlue};
            span {
                position: relative;
                top: -1px;
                margin-right: 5px;
            }
        }
        p {
            margin: 0;
            font-size: 19px;
            line-height: 24px;
            span {
                position: relative;
                top: 1px;
                margin-right: 5px;
            }
        }
    }
`;

export const ImageBorder = styled.div`
    position: relative;
    width: 135px; height: 135px;
    border-radius: 25px 25px 25px 0;
    background: ${props => props.theme.darkBlue};
    box-sizing: border-box;
    padding: 4px;
    float: left;
    margin: 0 20px 53px 0;
`;

export const Countries = styled.div`
    position: absolute;
    bottom: 15px; right: -5px;
    width: 17px;
    line-height: 20px;
`;

export const ButtonFollowConnectStyles = css`
    width: 105px; height: 31px;
    margin: 30px 7px 0 0;
    font-size: 14px;
    line-height: 14px;
`;