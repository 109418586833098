import React, { Component } from 'react';
import {
    ProfileMenuVerificationIDWrapper,
    ProfileVerificationHeaderWrapper,
    BackButton,
    ProfileMenuVerificationHeader,
    ContentWrapper,
    TopCard,
    LowerButtonsWrapper,
    SaveButton,
    CancelButton,
    FooterWrapper,
    FooterItemTextStyle,
    FooterItemCompanyTextStyle,
    AddressStyle,
    AddressHeaderText,
    ImgWrapper,
    ImgStyle
} from './Styled';
import { ArrowBack } from '../../../styles/Styled';

export class ProfileMenuVerificationID extends Component {
    constructor(props) {
        super(props);
        this.footerItems = [
            { item: 'About us', link: '' },
            { item: 'Help', link: '' },
            { item: 'Jobs', link: '' },
            { item: 'Privacy Policy', link: '' },
            { item: 'Terms', link: '' },
        ];
        this.addressInfo = [
            {address:'brooklyn, bla bla', documentImg:'https://templates.business-in-a-box.com/imgs/1000px/deed-of-sale_real-estate-property-D1172.png'}
        ]
    }
    render() {
        return (
            <ProfileMenuVerificationIDWrapper>
                <ProfileVerificationHeaderWrapper
                    onClick={this.props.BackCurrentPage}
                >
                    <BackButton>
                        <ArrowBack />
                        <ProfileMenuVerificationHeader>
                            Back
                        </ProfileMenuVerificationHeader>
                    </BackButton>
                </ProfileVerificationHeaderWrapper>
                <ContentWrapper>
                {this.addressInfo.map((item) => (
                    <React.Fragment>
                        <TopCard>
                            <AddressHeaderText>
                                Your Address
                            </AddressHeaderText>
                            <AddressStyle>
                                {item.address}
                            </AddressStyle>
                        </TopCard>
                        <ImgWrapper>
                            <ImgStyle src={item.documentImg} alt='document' />
                        </ImgWrapper>
                    </React.Fragment>
                ))}
                    <LowerButtonsWrapper>
                        <SaveButton>Save</SaveButton>
                        <CancelButton>Cancel</CancelButton>
                    </LowerButtonsWrapper>
                    <FooterWrapper>
                        <div>
                            {this.footerItems.map((item, index) => (
                                <FooterItemTextStyle
                                    key={index}
                                    last={index}
                                    first={index === 0}
                                >
                                    {item.item} {index !== 4 && '|'}
                                </FooterItemTextStyle>
                            ))}
                        </div>
                        <FooterItemCompanyTextStyle>
                            © 2020 RealtyFeed
                        </FooterItemCompanyTextStyle>
                    </FooterWrapper>
                </ContentWrapper>
            </ProfileMenuVerificationIDWrapper>
        );
    }
}

export default ProfileMenuVerificationID;
