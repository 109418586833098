import React, { Component } from 'react';
import { 
    AddNewPackageColumnWrapper,
    TopWrapper,
    BackButton,
    TotalItem,
    AmountText,
    PremiumContainer,
    BodyHeader,
    BodyWrapper,
    PackageCardWrapper,
    AddPackageTopSide,
    AddPackageMiddleSide,
    AddPackageBottomSide,
    DeleteButton,
    DaysAmount,
    PlusMinusButtonWrapper,
    SquareButton,
    BottomLeftSide,
    BottomRightSide,
    ZipcodeInputWrapper,
    CirclesWrapper,
    AddzipCodeButton,
    AddPackageBottomWrapper,
    AddPackageBottomSideWrapper,
    ContinueButton

 } from './Styled';
 import { ArrowBack } from '../../../styles/Styled';
 import MapBlue from './ColumnAssets/MapBlue';

export class MyListingAddNewPackageColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fakedata: {
            totalBudget:'600',
            totalActive:'68',
            totalZip:'3',
            packages: [ 
                {ticket:'94105', daysleft:25,start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false, days:30, cost:60},
                {ticket:'94105', daysleft:25,start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false, days:15, cost:30},
                {ticket:'94105', daysleft:25,start:'12 Oct 2020', end:'12 Nov 2020', order:'558836',isExtended:false, days:0, cost:0},
            ],
        }
        }
    }

    plusButtonHandler = (index) => {
        let newData = {...this.state.fakedata};
        newData.packages[index].days = newData.packages[index].days + 1;
        newData.packages[index].cost = newData.packages[index].days * 2;

        this.setState({
            fakedata:newData
        })

    }

    minusButtonHandler = (index) => {
        let newData = {...this.state.fakedata};
        if ( newData.packages[index].days <= 0  ) {
            console.log('below zerooo!!')
        } else {
            let newData = {...this.state.fakedata};
            newData.packages[index].days = newData.packages[index].days  -1;
            newData.packages[index].cost = newData.packages[index].days * 2;
    
            this.setState({
                fakedata:newData
            });
        }
    }

    deleteButtonHandler = (i) => {
        let newData = {...this.state.fakedata};
        let filteredArray = newData.packages.filter((item , index) => index !== i);
        this.setState({
            fakedata:{...this.state.fakedata, packages:filteredArray}
        })
    }

    addPackageCardWrapper = (data) => {
        console.log(data)
        return (
            data.map((item,index) => {
                return (
                    <PackageCardWrapper key={index}>
                        <AddPackageTopSide>
                            <DeleteButton onClick={() => this.deleteButtonHandler(index)}>
                                X
                            </DeleteButton>
                            <MapBlue />
                            <span>
                                {item.ticket}
                            </span>
                        </AddPackageTopSide>
                        <AddPackageMiddleSide days={item.days} >
                            <span>
                                Cost per day
                            </span>
                            <span>
                                $2
                            </span>
                            <PlusMinusButtonWrapper>
                                <SquareButton onClick={() => this.minusButtonHandler(index)}>
                                    -
                                </SquareButton>
                                <DaysAmount>
                                    <span>
                                        {item.days}
                                    </span>
                                    days
                                </DaysAmount>
                                <SquareButton onClick={() => this.plusButtonHandler(index)}>
                                    +
                                </SquareButton>
                            </PlusMinusButtonWrapper>
                        </AddPackageMiddleSide>
                        { item.days > 0 && (
                            <AddPackageBottomSide>
                                <BottomLeftSide>
                                    <span className='dateType'>
                                        Start Date:
                                    </span>
                                    {' '}
                                    <span className='date'>
                                        16 Nov 2020
                                    </span>
                                    {' '}
                                    <span>
                                        h
                                    </span>
                                </BottomLeftSide>
                                <BottomRightSide>
                                    Total cost:{' '} 
                                    <span className='cost'>
                                        ${item.cost}
                                    </span>
                                </BottomRightSide>
                            </AddPackageBottomSide>
                        )
                        }
                    </PackageCardWrapper>  
                );
            })
        );
    }

    render() {
        return (
            <AddNewPackageColumnWrapper>
                <TopWrapper>
                    <div className='header'>
                        <BackButton>
                            <ArrowBack />
                        </BackButton>
                        <span>
                            Add New Package
                        </span>
                    </div>
                    <div className='totalsection'>
                        <TotalItem>
                            <span className='budget'>
                                Total spent Budget
                            </span>
                            <AmountText budget>
                                ${this.state.fakedata.totalBudget}
                            </AmountText>
                        </TotalItem>
                        <TotalItem>
                            <span>
                                Total active days
                            </span>
                            <AmountText>
                                ${this.state.fakedata.totalActive}
                            </AmountText>
                        </TotalItem>
                        <TotalItem>
                            <span >
                                Total ZIP Codes
                            </span>
                            <AmountText>
                                ${this.state.fakedata.totalZip}
                            </AmountText>
                        </TotalItem>
                    </div>
                </TopWrapper>
                <PremiumContainer>
                    <button>
                        <h3>upgrade now to</h3>
                        <h1>Premium Package</h1>
                        <span>
                            <h2>$49</h2>
                            <h4>/month</h4>
                        </span>
                    </button>
                </PremiumContainer>
                <BodyWrapper>
                    <BodyHeader>
                        Select a ZIP Code
                    </BodyHeader>
                    {this.addPackageCardWrapper(this.state.fakedata.packages)}
                    <ZipcodeInputWrapper>
                        <div className='left'>
                            <MapBlue color />
                            <input 
                                placeholder={'Enter ZIP code'}
                            />
                        </div>
                        <CirclesWrapper>
                            <span className='circle1' />
                            <span className='circle2' />
                            <span className='circle3' />
                        </CirclesWrapper>
                    </ZipcodeInputWrapper>
                    <AddzipCodeButton>
                        + Add ZIP Code
                    </AddzipCodeButton>
                    <AddPackageBottomWrapper>
                        <AddPackageBottomSideWrapper left>
                            <span className='header'>
                                total price
                            </span>
                            <span className='price'>
                                $100
                            </span>
                        </AddPackageBottomSideWrapper>
                        <AddPackageBottomSideWrapper>
                            <span className='header-purple'>
                                total price
                            </span>
                            <span className='price-gray'>
                                $100
                            </span>
                        </AddPackageBottomSideWrapper>
                    </AddPackageBottomWrapper>
                    <ContinueButton>
                        Continue
                    </ContinueButton>
                </BodyWrapper>
            </AddNewPackageColumnWrapper>
        )
    }
}

export default MyListingAddNewPackageColumn;

