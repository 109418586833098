import styled from 'styled-components';
import { ArrowLeft } from '../BaseTemplate/Styled';

export const GroupTab = styled.div`
    height: 56px;
    margin-bottom: 55px;

    position: relative;
    &:after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${(props) => props.theme.darkBlue};
        z-index: 1;
    }
    &:before {
        content: '';
        position: absolute;
        width: 39px;
        height: 2px;
        bottom: 0;
        left: -25px;
        background: ${(props) => props.theme.darkBlue};
        z-index: 1;
    }
`;

export const GroupTabItem = styled.div`
    width: 340px;
    height: 56px;
    position: relative;
    background: ${(props) => (props.active ? '#fff' : 'transparent')};
    overflow: hidden;
    float: left;
    z-index: 2;
    &:hover {
        cursor: pointer;
    }
    &:not(:first-of-type) {
        left: -100px;
    }
    div {
        text-align: center;
        margin-top: 12px;
        font-size: 22px;
        font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
        color: ${(props) =>
            props.active ? props.theme.darkBlue : props.theme.textBlueGray};
    }
    ${(props) =>
        props.active &&
        `
        & em[data-line-up] {
            height: 2px;
            background: ${props.theme.darkBlue};    
            position: absolute;
            top: 0; left: 93px; right: 93px;
        }
        & span[data-curve-container]:first-of-type span[data-curved-up],
        & span[data-curve-container]:first-of-type span[data-curved-down] {
            width: 50px;
            height: 15px;  
            border: solid 2px;
            border-color: ${
                props.theme.darkBlue
            } transparent transparent transparent;
            border-radius: 50%/14px 15px 0 0;
            position: absolute;
        }
        & span[data-curve-container]:first-of-type span[data-curved-up] {
            transform: rotate(-28deg);
            left: 50px; top: 4px;
        }
        & span[data-curve-container]:first-of-type span[data-curved-down] {
            transform: rotate(152deg);
            left: 0; bottom: 4px;
        }
        & span[data-curve-container]:first-of-type span[data-line-between] {
            position: absolute;
            width: 20px; height: 2px;
            background: ${props.theme.darkBlue};
            transform: rotate(-57deg);
            top: 27px; left: 42px;
        }
        & span[data-curve-container]:first-of-type span[data-line-down] {
            width: 11px; height: 2px;
            background: ${props.theme.darkBlue};    
            position: absolute;
            bottom: 0; left: 0;
        }
        
        & span[data-curve-container]:last-of-type span[data-curved-up],
        & span[data-curve-container]:last-of-type span[data-curved-down] {
            width: 50px;
            height: 15px;  
            border: solid 2px;
            border-color: ${
                props.theme.darkBlue
            } transparent transparent transparent;
            border-radius: 50%/14px 15px 0 0;
            position: absolute;
        }
        & span[data-curve-container]:last-of-type span[data-curved-up] {
            transform: rotate(28deg);
            right: 50px; top: 4px;
        }
        & span[data-curve-container]:last-of-type span[data-curved-down] {
            transform: rotate(-152deg);
            right: 0; bottom: 4px;
        }
        & span[data-curve-container]:last-of-type span[data-line-between] {
            position: absolute;
            width: 20px; height: 2px;
            background: ${props.theme.darkBlue};
            transform: rotate(56deg);
            top: 27px; right: 42px;
        }
        & span[data-curve-container]:last-of-type span[data-line-down] {
            width: 11px; height: 2px;
            background: ${props.theme.darkBlue};
            position: absolute;
            bottom: 0; right: 0;
        }
        `};

    @media ${(props) => props.theme.mediaHD} {
        div {
            font-size: 18px;
        }
    }
`;

export const ProfileMenuTab = styled.div`
    text-align: center;
    height: 60px;
    background: ${(props) => props.theme.darkerBlueBg};
    padding-left: 30px;
`;

export const ProfileMenuTabItem = styled.div`
    width: 87px;
    height: 50px;
    background: ${(props) => (props.active ? '#fff' : 'transparent')};
    text-align: center;
    display: inline-block;
    margin: 10px 18px 0;
    position: relative;
    border-radius: 15px 15px 0 0;
    &:hover {
        cursor: pointer;
    }
    ${(props) =>
        props.active &&
        `
        em {
            width: 15px; height: 15px;
            position: absolute;
            bottom: 0;
            background: #fff;
            &:after {
                content: "";
                width: 15px;
                position: absolute;
                top: 0; bottom: 0; right: 0;
                background: ${props.theme.darkerBlueBg};
            }
        }
        em:first-of-type {
            left: -15px;
            &:after {
                border-radius: 0 0 15px 0;
            }
        }
        em:last-of-type {
            right: -15px;
            &:after {
                border-radius: 0 0 0 15px;
            }
        }
        `};
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
            scale(${(props) => (props.active ? 0.7 : 0.8)});
    }
    div {
        position: absolute;
        bottom: -37px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-weight: lighter;
        color: ${(props) => props.theme.darkBlue};
        padding: 10px 0 0;
        width: 85px;
        z-index: 2;
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ListingsTab = styled.div`
    display: flex;
    align-items: stretch;
`;

export const ListingsTabItem = styled.div`
    font-size: 21px;
    height: 75px;
    box-sizing: border-box;
    padding: 20px 0;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    border-top: solid 3px
        ${(props) =>
            props.active ? props.theme.textBlack : props.theme.grayLineLight};
    ${(props) => (props.isTitle ? ' flex-grow: 3; ' : ' flex-grow: 1; ')};
    ${(props) =>
        props.active
            ? ` font-weight: bold; color: ${props.theme.textBlack}; `
            : ``};

    @media ${(props) => props.theme.mediaHD} {
        font-size: 15px;
        height: 55px;
        padding: 5px 0;
    }
`;

export const HomePageSearchTab = styled.div``;

export const HomePageSearchTabItem = styled.div`
    font-size: 18px;
    height: 55px;
    width: 180px;
    box-sizing: border-box;
    padding: 10px 0;
    text-align: center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
    border-bottom: ${(props) =>
        props.active
            ? `solid 3px ${props.theme.textBlack}`
            : `solid 3px ${props.theme.grayLineLight}`};
    ${(props) => (props.isTitle ? ' flex-grow: 3; ' : ' flex-grow: 1; ')};
    ${(props) =>
        props.active
            ? ` font-weight: bold; color: ${props.theme.textBlack}; `
            : ``};
`;

export const BorderExtend = styled.div`
    float: right;
    height: 55px;
    width: ${(props) => (props.width ? props.width : '540px')};
    box-sizing: border-box;
    border-bottom: solid 3px ${(props) => props.theme.grayish};
    position: relative;
`;

export const MyListingsSearchTabItem = styled.div`
    font-size: 16px;
    height: 55px;
    width:25%;
    font-weight:lighter;
    box-sizing: border-box;
    padding: 10px 0;
    background: 'transparent';
    text-align: center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
    border-bottom: ${(props) =>
        props.active
            ? `solid 2px #2e2e33`
            : `solid 2px ${props.theme.grayish}`};
    ${(props) => (props.isTitle ? ' flex-grow: 3; ' : ' flex-grow: 1; ')};
    ${(props) =>
        props.active
            ? ` font-weight: bold; color: ${props.theme.textBlack}; `
            : ``};
`;

export const MyListingsSearchTab = styled.div`
width:50%;
`;

export const ButtonWrapper = styled.div`
    float: right;
    height: 55px;
    width: ${(props) => (props.width ? props.width : '540px')};
    box-sizing: border-box;
    border-bottom: solid 3px ${(props) => props.theme.grayLineLight};
    position: relative;

    button {
        width: 60px;
        height: 30px;
        background: transparent;
        border: 1px solid ${(props) => props.theme.cyan};
        border-radius: 3px;
        color: ${(props) => props.theme.cyan};
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }
`;

export const PropertyDetailsTab = styled.div`
    display: flex;
    align-items: stretch;
`;

export const PropertyDetailsTabItem = styled.div`
    position: relative;
    flex-grow: 1;
    font-size: 22px;
    height: 54px;
    box-sizing: border-box;
    padding: 8px 0 0;
    text-align: center;
    cursor: pointer;
    border-top: solid 1px ${(props) => props.theme.grayLight};
    border-bottom: solid 1px
        ${(props) => (props.active ? props.theme.cyan : props.theme.grayLight)};
    ${(props) =>
        props.active
            ? ` color: ${props.theme.cyan}; `
            : `font-weight: lighter`};
    &:after {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        bottom: 15px;
        width: 1px;
        background: ${(props) => props.theme.grayLight};
    }
    &:last-of-type {
        &:before {
            content: '';
            position: absolute;
            top: 15px;
            right: 0;
            bottom: 15px;
            width: 1px;
            background: ${(props) => props.theme.grayLight};
        }
    }
`;

export const SearchTab = styled.div``;

export const SearchTabItem = styled.div`
    font-size: 15px;
    div {
        width: 79px;
        height: 79px;
        border: solid 1px transparent;
        position: relative;
        ${(props) =>
            props.active &&
            `
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
                border-color: ${props.theme.grayLightBg};
                &:after {
                    content: "▼";
                    color: #fff;
                    position: absolute;
                    bottom: -21px; left: 50%;
                    transform: translateX(-50%) scale(1.5, 1);
                    text-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
                }
            `};
        border-radius: 10px;
        float: left;
        text-align: center;
        > span {
            display: block;
            height: 30px;
            margin-top: 10px;
        }
    }
    &:hover {
        cursor: pointer;
    }
`;

export const PropertyFilterTab = styled.div`
    padding: 30px 20px 0 20px;
    border-top: solid 1px ${(props) => props.theme.textGrayLight};
    width: calc(100% - 40px);
`;

export const PropertyFilterTabItem = styled.div`
    width: 50%;
    box-sizing: border-box;
    font-size: 18px;
    float: left;
    padding: 10px 40px;
    border-bottom: solid 2px ${(props) => props.theme.textGrayLight};
    position: relative;
    bottom: -2px;
    color: ${(props) => props.theme.textGrayLight};
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    ${(props) =>
        props.active &&
        `
        font-weight: bold;
        color: ${props.theme.textBlack};
        border-bottom: solid 2px ${props.theme.cyan};
        `};
`;

export const TabPage = styled.div`
    ${(props) => props.styled};

    > button {
        background: transparent;
        border: 0;
        font-size: 18px;
        font-weight: bold;
        color: ${(props) => props.theme.textBlack};
        cursor: pointer;
        float: right;
        height: 55px;
        width: 120px;
        margin-top: -10px;
        margin-right: -20px;
        border-left: 1px solid ${(props) => props.theme.grayLineLight};
        :last-of-type {
            border: none;
        }
    }
`;

export const MyNetworksTabItem = styled.span`
    font-size: 18px;
    padding-left: 17px;
    border-radius: 5px;
    padding-right: 17px;
    margin-left: 10px;
    height: 38px;
    box-sizing: border-box;
    background: ${(props) =>
        props.active
            ? 'linear-gradient(to right, #07aed0 0%, #106ba6 100%)'
            : '#ebebeb'};
    color: ${(props) => (props.active ? 'white' : null)};
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`;

export const MyNetworksTabBar = styled.div`
    margin-top: 25px;
    padding-left: 10px;
`;

export const SortButtonStyle = styled.span`
    float: right;
    box-sizing: border-box;

    button {
        background: transparent;
        border: 0;
        font-size: 18px;
        color: ${(props) => props.theme.textBlack};
        cursor: pointer;
        float: right;
        display: flex;
        align-items: center;
        height: 55px;
        margin-top: -8px;
        margin-right: 3px;

        border-left: 1px solid ${(props) => props.theme.grayLineLight};
        :last-of-type {
            border: none;
        }
    }
`;

export const SideBar = styled.div`
    width: 250px;
    background: rgba(49, 49, 54, 0.6);
    position: fixed;
    z-index: 2;
    height: 100vh;
    top: 80px;
    left: 0;
`;

export const SideBarLeftArrow = styled(ArrowLeft)`
    margin: 0;
    &:after,
    &:before {
        content: '';
        background-color: white;
    }
`;

export const SideBarLeftArrowWrapper = styled.div`
    height: 40px;
    width: 40px;
    position: absolute;
    right: -40px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(49, 49, 54, 0.6);
    border-bottom-right-radius: 10px;
`;

export const SideBarOptions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Options = styled.div`
    color: white;
    font-size: 26px;
    margin-top: 25px;
    margin-left: 20px;
    cursor: pointer;
`;

export const HeaderWrapper = styled.div`
    height: 80px;
    background: rgba(49, 49, 54, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: none;
    z-index: 2;
    display: flex;
    justify-content: space-between;
`;

export const HeaderText = styled.span`
    color: white;
    font-size: 26px;
    margin-left: 20px;
    margin-top: 24px;
    display: flex;
`;

export const TitleText = styled.div`
${(props) =>
    props.firstItem &&
    `color:#2e2e33;
    font-family: 'Montserrat';

    `}
${(props) =>
    props.secondItem &&
    `color:#19257c;
    font-family: 'Montserrat';
    
    `}
${(props) =>
    props.thirdItem &&
    `color:#b90795;
    font-family: 'Montserrat';
    `}
${(props) =>
    props.lastItem &&
    `color:#07aed0;
    font-family: 'Montserrat';
    `}
`;

export const LeadStyle = styled.div`
    font-family: 'Montserrat';
`;

export const VaultTextTitle = styled.div`
font-size:16px;
color:'#7106C4';
`;

export const VaultItemWrapper = styled.div`
    text-align: center;
    height: 60px;
    background: transparent linear-gradient(270deg, #19257C 0%, #7106C4 100%) 0% 0% no-repeat padding-box;
    z-index:2;

`;

export const VaultItem = styled.div`
    width: 80px;
    height: 50px;
    background: ${(props) => (props.active ? '#fff' : 'transparent')};
    text-align: center;
    display: inline-block;
    margin: 10px 18px 0;
    position: relative;
    border-radius: 15px 15px 0 0;
    &:hover {
        cursor: pointer;
    }
    ${(props) =>
        props.active &&
        `
        em {
            width: 15px; height: 15px;
            position: absolute;
            bottom: 0;
            background: #fff;
            &:after {
                content: "";
                width: 15px;
                position: absolute;
                top: 0; bottom: 0; right: 0;
                background: transparent linear-gradient(270deg, #19257C 0%, #7106C4 100%) 0% 0% no-repeat padding-box;
            }
        }
        em:first-of-type {
            left: -15px;
            &:after {
                border-radius: 0 0 15px 0;
            }
        }
        em:last-of-type {
            right: -15px;
            &:after {
                border-radius: 0 0 0 15px;
            }
        }
        `};
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
            scale(${(props) => (props.active ? 0.7 : 0.8)});
    }
    div {
        position: absolute;
        bottom: -37px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        color: #7106C4;
        padding: 10px 0 0;
        width: 80px;
        z-index: 2;
        &:hover {
            cursor: pointer;
        }
    }
`;