import styled, { keyframes } from 'styled-components';

export const InvatationStepsWrapper = styled.div`
    height: 860px;
    width: 100%;
    border-radius: 10px;
    padding-left: 30.5px;
    padding-top: 26px;
    background: white;
    overflow-y: scroll;
`;

export const InvatationStepsHeaderWrapper = styled.div`
    width: 380px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${(props) => props.theme.textGrayLight};
`;

export const InvatationStepsHeader = styled.span`
    padding-left: 9.5px;
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.theme.deepBlue};
`;

export const InvatationStepsLineWrapper = styled.div`
    margin-top: 72.5px;
    padding-left: 12.5px;
    color: black;
    display: flex;
    align-items: center;
    position: relative;
`;

export const ProgressBar = styled.div`
    height: 1px;
    float: left;
    border-top: ${(props) =>
        `5px ${props.theme.grayBorderLight} ${
            props.last ? 'dashed' : 'solid'
        }`};
    background: ${(props) => props.theme.white};
    width: 375px;
    position: relative;
    display: flex;
    align-items: center;
`;

const BarAnimation = (w) => keyframes`
    0% {width: 0%};
    100% {width: ${w}px };
`;

export const FilteredBar = styled.div`
    height: 6px;
    width: ${(props) => props.current * 375}px;
    animation: ${(props) => BarAnimation(props.current * 370)} 3s linear;
    animation-fill-mode: forwards;
    background-image: linear-gradient(
        to right,
        #19257c 3%,
        #07aed0 48%,
        #7106c4 92%
    );
    border-radius: inherit;
    text-align: right;
    position: absolute;
    top: 35px;
    z-index: 1;

    i {
        border: solid #7106c4;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: absolute;
        top: -3px;
        margin-left: -11px;
    }
`;

export const ActiveProgressBarCircle = styled.div`
    height: 10px;
    width: 10px;
    left: -7px;
    top: -9px;
    z-index: 10;
    position: absolute;
    border: 2px solid
        ${(props) =>
            props.compeleted ? props.theme.white : props.theme.grayBorderLight};
    border-radius: 100%;
    background: ${(props) =>
        props.compeleted ? props.theme.darkBlue : props.theme.white};
`;

export const ProgressBarWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const SigninProgressBarHeader = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.vividCyan};
    width: 100%;
    margin-left: -13px;
`;

export const StepIndicator = styled.div`
    position: absolute;
    top: -38px;
    left: -8px;
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StepIndicatorLeftSide = styled.div``;

export const StepIndicatorRightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;

    p {
        font-size: 10px;
        line-height: 12px;
        font-weight: bold;
        margin: 0;
    }

    h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: bold;
        margin: 0;
    }
`;

export const InvatationStepsDetailsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 37px;
    padding-left: 11.5px;
    width: auto;
`;

/*We could't use colors with theme here so we just copied color hex*/
const BorderAnimation = (height) => keyframes`
    0% {height: 0; min-width: 0};
    100% {height: ${height}px; min-width: 377px};
`;

export const FirstDetailsWrapper = styled.div`
    min-width: 377px;
    height: ${(props) => `${props.height * 55}px`};
    border-top: 1px ${(props) => (props.dashed ? 'dashed' : 'solid')};
    border-left: 1px solid;
    border-top-color: ${(props) => props.theme.grayBorderLight};
    border-left-color: ${(props) => props.theme.grayish};
    position: relative;

    .fillBorder {
        position: absolute;
        border-left: 1px solid;
        border-top: 1px ${(props) => (props.dashed ? 'dashed' : 'solid')};
        border-top-color: ${(props) =>
            props.compeleted
                ? props.theme.darkBlue
                : props.theme.grayBorderLight};
        border-left-color: ${(props) =>
            props.compeleted
                ? props.theme.darkBlue
                : props.theme.grayish};
        animation: ${(props) =>
                props.compeleted ? BorderAnimation(props.height * 55) : null}
            ${(props) => `${3 / props.current}s`} linear;
        ${(props) =>
            props.index !== 0 &&
            `animation-delay: ${(props.index / props.current) * 3}s`};
        animation-fill-mode: forwards;
    }
`;

export const InvatationStepDetailWrapper = styled.div`
    position: relative;
    align-self: flex-start;
`;

export const ObjectiveWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: -7px;
    margin-bottom: 10px;
    :first-child {
        margin-top: 50px;
    }
`;

const ObjectiveCircleAnimation = keyframes`
    0% {
        background: #fff;
        border-color: #B3B3B3;
    }
    100% {
        background: #19257C;
        border-color: #19257C;
    }
`;

const ObjectiveConnectorAnimation = keyframes`
    0% { width: 0px };
    100% {width: 15px };
`;

export const Objective = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1px;

    .circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 1px solid;
        background: ${(props) => props.theme.white};
        animation: ${(props) => (props.done ? ObjectiveCircleAnimation : null)}
            3s linear;
        animation-fill-mode: forwards;
    }

    .connector {
        height: 1px;
        background: ${(props) =>
            props.done ? props.theme.darkBlue : props.theme.grayish};
        animation: ${ObjectiveConnectorAnimation} 3s linear;
        animation-fill-mode: forwards;
    }

    h3 {
        font-size: 16px;
        color: ${(props) => props.theme.darkBlue};
        margin-left: 10px;
    }
`;

const StarAnimation = keyframes`
    0%{
        color: #fff;
    }
    100% {
        color: #19257C
    }
`;

const StarNoAnimation = keyframes`
    0%{
        color: #B3B3B3;
    }
    100% {
        color: #B3B3B3
    }`;

export const Achievments = styled.div`
    display: flex;
    flex-direction: column;
    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -3px;

        span {
            animation: ${(props) =>
                    props.done ? StarAnimation : StarNoAnimation}
                3s linear;
            animation-fill-mode: forwards;
        }

        p {
            margin: 0;
            font-size: 15;
            color: ${(props) => props.theme.mainFontColor};
            margin-left: 10px;
        }
    }
`;

export const InvatationStepLayout = styled.div`
    height: 28px;
    width: 40px;
    font-size: 10px;
    font-weight: bold;
    justify-content: center;
    background: ${(props) =>
        props.compeleted ? props.theme.darkBlue : props.theme.white};
    color: ${(props) =>
        props.compeleted ? props.theme.white : props.theme.darkBlue};
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border: 1px solid ${(props) => props.theme.darkBlue};
    display: flex;
    align-items: center;
`;

export const InvatationLayoutWrap = styled.div`
    position: absolute;
    top: 20px;
    left: -20px;
    display: flex;
    align-items: center;
`;

export const InvatationStepDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LayoutHeaderText = styled.span`
    font-size: 18px;
    color: ${(props) => props.theme.darkBlue};
    font-weight: bold;
    margin-left: 7px;
`;
