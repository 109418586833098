import styled, { css } from 'styled-components';
import { SwitchBoxStyles } from '../WizardPageGroupPermissions/Styled';
import {
    InputTextWrapperStyles,
    InputTextStyles,
    InputLabelAnimateStyles,
    InputLabelAfterAnimateStyles,
} from '../LoginForm/Styled';
import { IconButton } from '../../../styles/Styled';

export const Price = styled.div`
    font-size: ${(props) => (props.big ? '32' : '25')}px;
    color: ${(props) => props.theme.textBlack};
    font-weight: bold;
    float: left;
`;

export const ListingInfoWrapper = styled.div`
    float: ${(props) => (props.left ? 'left' : 'right')};
    margin-right: 0px;
    font-size: ${(props) => (props.big ? '12' : '10')}px;
    span {
        text-transform: lowercase;
    }
    strong {
        font-size: ${(props) => (props.big ? '18' : '14')}px;
        ${(props) => (props.big ? ' margin-right: 3px; ' : '')};
    }
    em {
        display: inline-block;
        height: 10px;
        border-right: solid 1px ${(props) => props.theme.grayLineLight};
        margin: 0 ${(props) => (props.big ? '12' : '7')}px;
    }
`;

export const TabPage = styled.div`
    ${(props) => props.styled};
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
`;

export const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    color: ${(props) => props.theme.darkBlue};
    margin-left: 20px;
`;

export const Input = styled.input`
    width: 80px;
    border: none;
    color: ${(props) => props.theme.cyan};
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    float: right;
    margin: 0;
    margin-top: 8px;
    line-height: 20px;
    background: transparent;
`;

export const PopContainer = styled.div`
    width: 100px;
    border: 1px solid;
    border-color: ${(props) => props.theme.grayLight};
    border-radius: 5px;
    background: white;
    z-index: 10;
    position: absolute;
    right: 0;
    display: ${(props) => props.display};

    button {
        background: transparent;
        border: 0;
        width: 100px;
        margin: 0;
        font-size: 14px;
        color: ${(props) => props.theme.mainFontColor};
        padding-left: 10px;
        border-bottom: 1px solid;
        border-color: ${(props) => props.theme.grayBorder};
        padding-bottom: 10px;
        cursor: pointer;
        :last-of-type {
            border: 0;
        }
    }
`;

export const CheckBoxGroup = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const CheckBoxStyles = css`
    margin-right: 0;
    margin-top: 10px;
`;

export const InputLabelStyles = css`
    font-size: 12px;
    display: block;
    width: 44%;
    line-height: 15px;
    height: 15px;
`;

export const InputWrapperStyles = css`
    ${InputTextWrapperStyles};
    width: 44%;
    display: block;
    float: left;
    margin-right: 20px;
    line-height: 22px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const InputLabeledWrapperStyles = css`
    ${InputTextWrapperStyles};
    width: 44%;
    float: left;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 20px;
`;

export const TextInputStyles = css`
    ${InputTextStyles};
    ::placeholder {
        font-size: 18px;
    }
`;

export const InputSelectStyles = css`
    ${InputTextStyles};
    font-size: 18px;
`;

export const LabelStyles = css`
    ${InputLabelAnimateStyles};
    font-size: 12px;
`;

export const LabelAfterStyles = css`
    ${InputLabelAfterAnimateStyles};
    font-size: 12px;
`;

export const SwitchBoxMenuStyles = css`
    ${SwitchBoxStyles};
    width: 40px;
    height: 19px;
    border: 0;
    float: right;
    margin-top: 7px;
    i em {
        width: 40px;
        height: 19px;
        top: 2px;
        left: 2px;
    }
    i em:before {
        width: 11px;
        height: 11px;
        left: 5px;
        bottom: 4px;
    }
    input:checked + i em:before {
        transform: translateX(20px);
    }
`;

export const ApplyButtonSubmit = css`
    width: 90%;
    height: 50px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 25px 0 0 20px;
    font-size: 18px;
    color: white;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        to right,
        ${(props) => props.theme.cyan} 1%,
        ${(props) => props.theme.cyanDark}
    );
`;

export const MapCard = styled.div`
    width: 370px;
    height: 180px;
    padding: 10px;
    margin: auto 20px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.22);
    position: relative;
    float: left;

    p {
        font-size: 16px;
        line-height: 19px;
        color: ${(props) => props.theme.mainFontColor};
        display: block;
        margin: 0 0 10px 0;
    }

    img {
        background: #eee;
        width: 140px;
        height: 95px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .footer {
        width: 100%;
        padding: 0 10px;
        height: 35px;
        background: ${(props) => props.theme.grayBgBig};
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
                margin-right: 5px;
            }

            p {
                font-size: 14px;
                color: ${(props) => props.theme.textBlack};
                align-self: center;
            }

            em {
                display: inline-block;
                height: 20px;
                border-right: solid 1px ${(props) => props.theme.grayLineLight};
                margin: 0 25px;
            }

            :last-of-type {
                em {
                    border: none;
                }
            }
        }
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 20px;
    width: 11px;
    height: 11px;
    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 11px;
        width: 3px;
        height: 25px;
        background-color: ${(props) => props.theme.textBlack};
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    cursor: pointer;
`;

export const LocationWrapper = styled.div`

`;

export const CardSectionWrapper = styled.div`
display:flex;
flex-direction:column;
`;

export const SocialDetailsWrapper = styled.div`
display:flex;
align-items:center;
`;

export const SocialActinvityAmount = styled.span`
font-size:14px;

`;
