import styled, { css } from 'styled-components';

export const Label = styled.p`
    font-size: 16px;
    color: ${(props) => props.theme.mainFontColor};
`;

export const ProfileImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 50px;
    position: relative;

    img {
        width: 90px;
        height: 90px;
        border-radius: 10px;
        margin-right: 20px;
    }

    button {
        font-size: 16px;
        color: ${(props) => props.theme.cyan};
        border: 0;
        background: transparent;
        cursor: pointer;
    }

    .icon {
        position: absolute;
        right: 7.6%;
        top: 125px;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
        width: 20%;
        font-size: 16px;
        color: ${(props) => props.theme.mainFontColor};
    }

    .icon {
        margin-left: -20px;
    }
`;

export const InputTextStyle = css`
    width: 100%;
    height: 30px;
    border: 0;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorder};
    color: ${(props) => props.theme.textBlack};
    font-size: 16px;
`;

export const Line = styled.div`
    position: relative;
    height: 1px;
    width: 120%;
    background: ${(props) => props.theme.grayBorder};
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: -10%;
`;

export const SectionTitle = styled.h1`
    font-weight: bold;
    font-size: 18px;
    color: ${(props) =>
        props.color ? props.theme[props.color] : props.theme.textBlack};
    margin: 0;
    cursor: ${(props) => props.cursor};
`;

export const ModalWrapper = styled.div`
    padding-right: 22px;
    padding-left: 22px;
    padding-bottom:22px;
`;

export const InputListStyles = css`
    width: 100%;
    height: 45px;
    font-family: ${(props) => props.theme.mainFont};
    font-size: 23px;
    line-height: 23px;
    color: ${(props) => props.theme.textBlack};
    border: none;
    border-bottom: solid 1px ${(props) => props.theme.textGrayLight};
    padding: 0 0 12px;
    background: transparent;
    &:focus {
        border-bottom-color: ${(props) => props.theme.cyan};
    }
    &::placeholder {
        color: ${(props) => props.theme.mainFontColor};
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .btn {
        display: flex;
        flex-direction: row;
        background: transparent;
        border: 0;
        align-items: center;
        justify-content: flex-start;

        p {
            font-size: 16px;
            color: ${(props) => props.theme.mainFontColor};
            margin-left: 10px;
        }
    }
`;

export const VerifyButton = styled.button`
    border: 0;
    margin: 0;
    background: white;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 10;
    color: ${props => props.theme.squash};
    cursor: pointer;
`;

export const InterestModalFormHeader = styled.span`
margin-top:35.5px !important;
font-size: 18px;
`;