import styled, {css} from "styled-components";
import {ButtonTextFlatStyles} from "../RegisterForm/Styled";

export const UploadCenter = styled.div`
    text-align: ${props => props.align};
`;

export const ModalUploadContent = styled.div`
    > p {
        width: 400px;
        margin: 65px auto 12px;
        font-size: 18px;
        color: ${props => props.theme.textBlack};
        span:last-of-type {
            float: right;
        }
    }
`;

export const UploadImage = styled.div`
    width: 700px; height: 430px;
    background: ${props => props.theme.grayBgBig};
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
    padding-top: 120px;
    input[type=file] {
        position: absolute;
        left: 0; top: 0; right: 0; bottom: 0;
        opacity: 0;
        cursor: pointer;
        width: 700px; height: 430px;
    }
    p {
        color: ${props => props.theme.textBlack};
        margin-top: 10px;
    }
`;

export const UploadImageButton = styled.div`
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    input[type=file] {
        position: absolute;
        left: 0; top: 0; right: 0; bottom: 0;
        opacity: 0;
        cursor: pointer;
        width: 100%; height: 67px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    padding: 0 65px;
    margin: 0 0 15px;
    > div, > button {
        margin: 5px;
    }
`;

export const InputSlider = styled.input`
    display: block;
    -webkit-appearance: none;
    width: 400px;
    height: 1px;
    background: ${props => props.theme.grayLightBgInput};
    border: none;
    outline: none;
    margin: 0 auto 75px;
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%; 
        background: ${props => props.theme.cyan};
        cursor: pointer;
    }
    ::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: ${props => props.theme.cyan};
        cursor: pointer;
    }
`;

export const ButtonTextFlatUploadStyles = css`
    ${ButtonTextFlatStyles};
    font-size: 18px;
    font-weight: lighter;
    height: 50px;
    padding-bottom: 4px;
    ${
        props => (props.disabled) &&
            ` background: ${props.theme.textGrayLight}; `
    };
`;

export const ButtonTextFlatUploadWhiteStyles = css`
    ${ButtonTextFlatUploadStyles};
    background: white;
    color: ${props => props.theme.cyan};
`;

export const ErrorMessageFile = styled.p`
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.errorMessage};
    margin: 5px 0 0;
`;