import styled, {css} from "styled-components";

export const Wrapper = styled.div``;

export const InputLabel = styled.label`
    display: inline-block;
    position: relative;
    span {
        position: absolute;
        cursor: pointer;
        ${
            props => (props.disabled) &&
                ` opacity: .5;
                  cursor: default;
                  pointer-events: none; `
        };
    }
    span.emoticon {
        top: 17px; right: 60px;
    }
    span.send {
        top: 21px; right: 20px;
    }
`;

export const CommentInputStyles = css`
    display: block;
    margin: 0 auto;
    width: 575px;
    padding: 15px 100px 16px 10px;
    font-size: 16px;
    border: solid 2px ${props => props.theme.grayBorder};
    border-radius: 5px;
    &::placeholder {
        color: ${props => props.theme.textGrayLight}
    }

    @media ${props => props.theme.mediaHD} {
        width: 360px;
    }
`;

export const Comments = styled.div``;

export const Comment = styled.div`
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: -50px;
    }
    h3 {
        margin: 0;
        span {
            font-size: 16px;
            color: ${props => props.theme.textBlack};
            position: relative;
            top: 0; left: 10px;
        }
        img {
            width: 39px; height: 39px;
            border-radius: 5px;
            float: left;
        }
        em {
            font-style: normal;
            font-size: 12px;
            font-weight: normal;
            color: ${props => props.theme.mainFontColor};
            float: right;
            position: relative;
            top: 2px;
        }
    }
    p {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        margin-top: -25px;
        margin-left: 10px;
        float: left;
    }
    button {
        display: inline-block;
        background: transparent;
        border: none;
        padding: 10px 15px 5px;
        height: 50px;
        margin: 5px 15px 0 -15px;
        &:hover {
            cursor: pointer;
        }
        &.comment {
            position: relative;
            top: 3px;
            span {
                position: relative;
                top: 1px;
            }
        }
        & .loading-like {
            height: 17px;
        }
    }
`;

export const CommentOnPage = styled.div`
    margin-top: 35px;
    h3 {
        font-size: 22px;
        font-weight: normal;
        color: ${props => props.theme.textBlack};
        margin: 0;
        i {
            width: 51px; height: 51px;
            padding: 3px;
            display: inline-block;
            background: ${props => (props.special) ? props.theme.darkBlue : 'transparent'};
            border-radius: 15px 15px 15px 0;
        }
        img {
            width: 51px; height: 51px;
            border-radius: 13px;
        }
        > span {
            display: inline-block;
            margin-left: 13px;
            position: relative;
            top: -20px;
        }
        em {
            font-style: normal;
            font-size: 18px;
            font-weight: lighter;
            float: right;
            margin-top: 15px;
            color: ${props => props.theme.mainFontColor};
            span {
                position: relative;
                margin-right: 30px;
            }
            span:nth-of-type(1) {
                top: 2px;
            }
            span:nth-of-type(2) {
                top: 6px;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 28px;
        font-weight: lighter;
        margin: 5px 0 0;
    }
`;

export const CommentLoadMore = styled.button`
    width: 160px; height: 40px;
    font-family: ${props => props.theme.mainFont};
    font-size: 18px;
    color: ${props => props.theme.textBlack};
    background: transparent;
    border: solid 2px ${props => props.theme.grayBorder};
    border-radius: 5px;
    float: right;
    cursor: pointer;
    position: relative;
    top: 9px;
    ${
        props => (props.disabled) &&
            ` opacity: .5; pointer-events: none; `
    };
`;