import React, { Component } from 'react';
import { RadioWrapper, SelectWrapperStyles } from './Styled';
import RadioButton from '../FormRadioButton';
import InputDropDown from '../FormInputDropDown';
import InputList from '../FormInputList';
import { InputTextDynamicStyles } from '../FormDynamicField/Styled';

class FormProfessionRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValue: {},
            role: {},
            expertise: [],
        };
    }

    radioChangeHandler = (radio) => {
        const states = this.props.states,
            radioValue = radio,
            fieldName = this.props.id;
        states.forEach((that) => {
            const isGlobalState = that.constructor.name === 'Object',
                fields = isGlobalState
                    ? that.getFields()
                    : { ...that.state.fields },
                errorFields = isGlobalState
                    ? that.getErrorFields()
                    : { ...that.state.errorFields };
            errorFields[fieldName] = null;

            if (radioValue.value === 2) {
                fields[fieldName] = {
                    radioValue: radioValue,
                    role: {},
                    expertise: [],
                };
                this.setState({
                    radioValue,
                    role: {},
                    expertise: [],
                });
            } else {
                fields[fieldName] = {
                    radioValue: radioValue,
                    role: {},
                    expertise: [],
                };
                this.setState({
                    radioValue,
                });
            }

            if (isGlobalState) {
                that.setFields(fields);
                that.setErrorFields(errorFields);
            } else {
                if (!this.props.extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...this.props.extraLocalStatesOnChange,
                    });
                }
            }
        });
    };

    handleDropDownChange = (name, value) => {
        const states = this.props.states;
        states.forEach((that) => {
            const isGlobalState = that.constructor.name === 'Object',
                fields = isGlobalState
                    ? that.getFields()
                    : { ...that.state.fields },
                errorFields = isGlobalState
                    ? that.getErrorFields()
                    : { ...that.state.errorFields };
            errorFields[name] = null;
            fields[name].role = value;
            this.setState({
                role: value,
            });
            if (isGlobalState) {
                that.setFields(fields);
                that.setErrorFields(errorFields);
            } else {
                if (!this.props.extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...this.props.extraLocalStatesOnChange,
                    });
                }
            }
        });
    };

    handleListChange = (name, value) => {
        const states = this.props.states;
        states.forEach((that) => {
            const isGlobalState = that.constructor.name === 'Object',
                fields = isGlobalState
                    ? that.getFields()
                    : { ...that.state.fields },
                errorFields = isGlobalState
                    ? that.getErrorFields()
                    : { ...that.state.errorFields };
            errorFields[name] = null;
            fields[name].expertise = value;
            this.setState({
                expertise: value,
            });
            if (isGlobalState) {
                that.setFields(fields);
                that.setErrorFields(errorFields);
            } else {
                if (!this.props.extraLocalStatesOnChange) {
                    that.setState({
                        fields,
                        errorFields,
                    });
                } else {
                    that.setState({
                        fields,
                        errorFields,
                        ...this.props.extraLocalStatesOnChange,
                    });
                }
            }
        });
    };

    render() {
        return (
            <RadioWrapper>
                {this.props.list[0].map((
                    radio,
                    index //The list array containes three arrays which the first one is for radio, //second one is for role and the third one is for expertise.
                ) => (
                    <div key={this.props.id + '_' + radio.value}>
                        <RadioButton
                            id={this.props.id + '_' + radio.value}
                            name={this.props.id}
                            label={radio.text}
                            checked={
                                (this.state.radioValue.value &&
                                    this.state.radioValue.value ===
                                        radio.value) ||
                                false
                            }
                            onChange={() => this.radioChangeHandler(radio)}
                        />
                        {index === 0 && this.state.radioValue.value === 1 && (
                            <>
                                <InputDropDown
                                    id={this.props.id + '_' + radio.value}
                                    name={this.props.id}
                                    type='text'
                                    styled={InputTextDynamicStyles}
                                    wrapperStyled={SelectWrapperStyles}
                                    placeholder={'I am a/an'}
                                    list={this.props.list[1]}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                />
                                <InputList
                                    id={this.props.id + '_' + radio.value}
                                    type='text'
                                    name={this.props.id}
                                    styled={InputTextDynamicStyles}
                                    title={this.props.title}
                                    subTitle={this.props.subTitle}
                                    list={this.props.list[2]}
                                    required
                                    value={this.state.expertise}
                                    onChangeSelected={this.handleListChange}
                                />
                            </>
                        )}
                    </div>
                ))}
            </RadioWrapper>
        );
    }
}

export default FormProfessionRadio;
