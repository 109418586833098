import {WizardNavigationType} from "./components/Modules/WizardItemNavigation";

const Config = {
    ENDPOINT                    : 'https://dev.api.realtyfeed.com/rest-auth',
    //ENDPOINT                    : 'http://localhost:3001',
    ENDPOINT_SOCKET             : 'http://dev.api.realtyfeed.com:8081/',
    AWS_CONFIG: {
        identityPoolId          : 'us-east-1:71527184-6f01-465a-8783-44628c5aca6b',
        region                  : 'us-east-1',
        userPoolId              : 'us-east-1_2TJngZO4U',
        userPoolWebClientId     : 'rfrqf8fh05888pu8p6jil7ova'
    },
    GOOGLE_MAP_API_KEY          : 'AIzaSyCuOa3mMYYeFL3YZdjLWmaPmNn3CjmJbz4',
    GOOGLE_MAP_AUTOCOMPLETE_URL : 'https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyCuOa3mMYYeFL3YZdjLWmaPmNn3CjmJbz4',
    GOOGLE_MAP_PLACE_DETAIL_URL : 'https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyCuOa3mMYYeFL3YZdjLWmaPmNn3CjmJbz4&fields=geometry/location,formatted_address,address_components',
    GOOGLE_MAP_GEOCODE_URL      : 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCuOa3mMYYeFL3YZdjLWmaPmNn3CjmJbz4',
    SPECIAL_SEPARATOR           : '__|__',
    ROUTES: {
        homePage                : '/',
        landingPage             : '/landingPage',
        pageNotFound            : '/page-not-found/404',
        login                   : '/login',
        logout                  : '/logout',
        serverError             : '/server-error',
        register: {
            root                : '/register',
            paramPage           : '/register/:page',
            confirm             : '/register/confirm',
            invitationKey       : '/register/invitationKey',
            askInvitationKey    : '/register/askInvitationKey'
        },
        forgetPassword: {
            root                : '/forget-password',
            paramPage           : '/forget-password/:page',
            confirm             : '/forget-password/confirm',
            changed             : '/forget-password/changed'
        },
        search: {
            root                : '/search',
            paramCategory       : '/search/:category',
            paramValue          : '/search/:category/:value',
            agent               : '/search/agent',
            property            : '/search/property',
            group               : '/search/group',
            people              : '/search/people'
        },
        profile: {
            group               : '/group',
            person              : '/profile',
            paramGroup          : '/group/:groupname',
            paramPerson         : '/profile/:username'
        },
        listing: {
            root                : '/listing',
            paramId             : '/listing/:address/:id',
            paramModal          : '/listing/:address/:id/:modaltype'
        },
        settings: {
            root                : '/settings',
            paramCategory       : '/settings/:category',
            paramPage           : '/settings/:category/:page',
            paramDetail         : '/settings/:category/:page/:detail'
        },
        leadManagement: {
            root                : '/lead-management',
            paramCategory       : '/lead-management/:category',
            paramPerson         : '/lead-management/profile/:person'
        },
        myNetworks: {
            root                : '/my-networks',
            paramCategory       : '/my-networks/:category',
        },
        dashboard: {
            root                : '/dashboard',
        },
        buildNetwork: {
            root                : '/build-network',
        },
        myListings: {
            root                : '/my-listings',
            paramCategory       : '/my-listings/:category',
            paramStatistics     : '/my-listings/:category?/statistics/:id',
            paramAddTag         : '/my-listings/add-tag/:index'
        },
        temporaryRoute: {
            temporaryLanding    : '/landingpage'
        },
        
        templates: {
            "profile-setup" : {
                title           : 'Profile Setup',
                endpoint        : '/regional/profile_complete/?template_type=web',
                navType         : WizardNavigationType.NEXT_PREV
            },
            "add-property"  : {
                title           : 'Add Property',
                endpoint        : '/regional/listing/?template_type=web',
                navType         : WizardNavigationType.DRAFT
            },
            "create-group"  : {
                title           : 'Group Creation',
                endpoint        : '/regional/create_group/?template_type=web',
                navType         : WizardNavigationType.DRAFT
            }
        }
    }
};

export default Config;