import React, { Component } from 'react';

import { Img } from './Styled';

export default class Loading extends Component {
    
    render() {
        return(
            <Img
                src={this.props.type ? "/assets/img/loading-blue.gif" : "/assets/img/loading.gif" }
                iSize={this.props.iSize}
                {...this.props}
            />
        )
    }
}