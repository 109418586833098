import React, { Component } from 'react';
import {
    InputTextTagStyles,
    InputTextTagWrapperStyles,
    SearchTag,
    SearchTags,
    TabPageStyles,
    LeadManagementFiltersStyle,
    ButtonTextWrap,
    SaveButtonStyle,
} from './Styled';
import TabPages, { TabStyle } from '../TabBarPages/TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import InputText from '../FormInputText/FormInputText';
import { MainContentBg, TabContent } from '../../../styles/Styled';
import IconNew from '../IconNew/IconNew';
import { ClearFloats } from '../BaseTemplate/Styled';
import { ArrowDown } from '../BaseTemplate/Styled';

class LeadManagmentSearchTabBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagInputValue: '',
            tags: [],
        };
    }

    tagChangeHandler = (e) => {
        this.setState({
            tagInputValue: e.target.value,
        });
    };

    tagEnterHandler = () => {
        this.setState((prevState) => {
            let tags = [...prevState.tags];
            tags.push(this.state.tagInputValue);
            return {
                tags,
                tagInputValue: '',
            };
        });
    };

    removeTag = (index) => {
        let tags = [...this.state.tags];
        tags.splice(index, 1);
        this.setState({ tags });
    };

    render() {
        return (
            <MainContentBg>
                <TabContent margin={1}>
                    <TabPages
                        theme={TabStyle.HomePageSearchTab}
                        defaultPageIndex={this.props.defaultIndex}
                        Type="LeadManagmentType"
                    >
                        <TabPage title="All" styled={TabPageStyles}>
                            <InputText
                                id="tagsInput"
                                name="tagsInput"
                                type="text"
                                onInput={this.tagChangeHandler}
                                value={this.state.tagInputValue}
                                onEnter={this.tagEnterHandler}
                                styled={InputTextTagStyles}
                                wrapperStyled={InputTextTagWrapperStyles}
                                placeholder="add tags..."
                            >
                                <IconNew
                                    bp1="-440"
                                    bp2="-49"
                                    iwidth="26"
                                    iheight="26"
                                />
                            </InputText>

                            <SearchTags>
                                {this.state.tags.map((tag, index) => (
                                    <SearchTag key={index}>
                                        <IconNew
                                            bp1="-467"
                                            bp2="-60"
                                            iwidth="10"
                                            iheight="15"
                                        />
                                        {tag}
                                        <i
                                            onClick={(e) =>
                                                this.removeTag(index)
                                            }
                                        />
                                    </SearchTag>
                                ))}
                            </SearchTags>
                            <span>
                                <SaveButtonStyle>Save</SaveButtonStyle>
                                <LeadManagementFiltersStyle>
                                    <ArrowDown />
                                    <ButtonTextWrap>Filters</ButtonTextWrap>
                                </LeadManagementFiltersStyle>
                            </span>

                            <ClearFloats />
                        </TabPage>

                        <TabPage title="Name" styled={TabPageStyles}>
                            <InputText
                                id="tagsInput"
                                name="tagsInput"
                                type="text"
                                onInput={this.tagChangeHandler}
                                value={this.state.tagInputValue}
                                onEnter={this.tagEnterHandler}
                                styled={InputTextTagStyles}
                                wrapperStyled={InputTextTagWrapperStyles}
                                placeholder="add tags..."
                            >
                                <IconNew
                                    bp1="-440"
                                    bp2="-49"
                                    iwidth="26"
                                    iheight="26"
                                />
                            </InputText>

                            <SearchTags>
                                {this.state.tags.map((tag, index) => (
                                    <SearchTag key={index}>
                                        <IconNew
                                            bp1="-467"
                                            bp2="-60"
                                            iwidth="10"
                                            iheight="15"
                                        />
                                        {tag}
                                        <i
                                            onClick={(e) =>
                                                this.removeTag(index)
                                            }
                                        />
                                    </SearchTag>
                                ))}
                            </SearchTags>
                            <span>
                                <SaveButtonStyle>Save</SaveButtonStyle>
                                <LeadManagementFiltersStyle>
                                    <ArrowDown />
                                    <ButtonTextWrap>Filters</ButtonTextWrap>
                                </LeadManagementFiltersStyle>
                            </span>

                            <ClearFloats />
                        </TabPage>

                        <TabPage title="Tag" styled={TabPageStyles}>
                            <InputText
                                id="tagsInput"
                                name="tagsInput"
                                type="text"
                                onInput={this.tagChangeHandler}
                                value={this.state.tagInputValue}
                                onEnter={this.tagEnterHandler}
                                styled={InputTextTagStyles}
                                wrapperStyled={InputTextTagWrapperStyles}
                                placeholder="add tags..."
                            >
                                <IconNew
                                    bp1="-440"
                                    bp2="-49"
                                    iwidth="26"
                                    iheight="26"
                                />
                            </InputText>

                            <SearchTags>
                                {this.state.tags.map((tag, index) => (
                                    <SearchTag key={index}>
                                        <IconNew
                                            bp1="-467"
                                            bp2="-60"
                                            iwidth="10"
                                            iheight="15"
                                        />
                                        {tag}
                                        <i
                                            onClick={(e) =>
                                                this.removeTag(index)
                                            }
                                        />
                                    </SearchTag>
                                ))}
                            </SearchTags>
                            <span>
                                <SaveButtonStyle>Save</SaveButtonStyle>
                                <LeadManagementFiltersStyle>
                                    <ArrowDown />
                                    <ButtonTextWrap>Filters</ButtonTextWrap>
                                </LeadManagementFiltersStyle>
                            </span>

                            <ClearFloats />
                        </TabPage>
                    </TabPages>
                </TabContent>
            </MainContentBg>
        );
    }
}

export default LeadManagmentSearchTabBar;
