import React, {  Fragment } from 'react';
import { } from './Styled';
import { LandingPageWrapper } from './Styled';
import LandingPageTemplate from '../Modules/LandingPageTemplate'
import BaseTemplateNew from '../Modules/BaseTemplateNew';

export default class LandingPage extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showHeader = false;
        super.showFooter = false;
    }
    renderBody() {
        return (
            <LandingPageWrapper>
                <LandingPageTemplate />
            </LandingPageWrapper>
        )
    }
}
