import React, { Component } from 'react';
import { SwitchBox } from './Styled';

class FormSwitchBox extends Component {
    render() {
        return (
            <SwitchBox styled={this.props.styled}>
                <span>{this.props.label}</span>
                <input
                    id={this.props.id}
                    name={this.props.name}
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                />
                <i>
                    <em />
                    {!this.props.noSigns && (
                        <>
                            <b />
                            <u />
                        </>
                    )}
                </i>
            </SwitchBox>
        );
    }
}

export default FormSwitchBox;
