import React, { Component } from 'react';
import {
    ButtonTextFlatUploadStyles,
    ButtonTextFlatUploadWhiteStyles,
    ButtonWrapper,
    ErrorMessageFile,
    InputSlider,
    ModalUploadContent,
    UploadCenter,
    UploadImage,
    UploadImageButton,
} from './Styled';
import FormUploadFile from '../FormUploadFile';
import Modal from '../Modal';
import { ModalWrapper, ModalWrapperContent } from '../Modal/Styled';
import IconNew from '../IconNew';
import ButtonText from '../FormButtonText';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../WizardPageProfileDetailsPicture/CropImage';
import { sendAsyncRequest } from '../../../Utils';

class FormUploadFileCrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            isCropping: false,
            isModalOpen: false,
        };
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels,
        });
    };

    onZoomChange = (e) => {
        const zoom = 1 + e.target.value / 100;
        this.setState({ zoom });
    };

    saveResult = async () => {
        try {
            this.setState({
                isCropping: true,
            });
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels
            );
            this.setState({
                croppedImage,
                isCropping: false,
                isModalOpen: false,
            });
            let file = await fetch(croppedImage)
                .then((r) => r.blob())
                .then((blobFile) => new File([blobFile], 'picture.jpeg'));
            this.addFileUpdateState(file);
        } catch (e) {
            this.setState({
                isCropping: false,
            });
        }
        // <img src={this.state.croppedImage} />
    };

    addFileUpdateState = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        sendAsyncRequest('/uploader/simple', 'post', {}, null, true, formData).then(
            (result) => {
                console.log(result.body.Result);
                const imgServerId = result.body.Result.pk;
                if (this.props.hasOwnProperty('states')) {
                    const states = this.props.states;
                    states.forEach((that) => {
                        const isGlobalState =
                                that.constructor.name === 'Object',
                            fields = isGlobalState
                                ? that.getFields()
                                : { ...that.state.fields },
                            errorFields = isGlobalState
                                ? that.getErrorFields()
                                : { ...that.state.errorFields },
                            fieldName = this.props.id;
                        fields[fieldName] = {
                            imgId: imgServerId,
                        };
                        errorFields[fieldName] = null;
                        if (isGlobalState) {
                            that.setFields(fields);
                            that.setErrorFields(errorFields);
                        } else {
                            if (!this.props.extraLocalStatesOnChange) {
                                that.setState({
                                    fields,
                                    errorFields,
                                });
                            } else {
                                that.setState({
                                    fields,
                                    errorFields,
                                    ...this.props.extraLocalStatesOnChange,
                                });
                            }
                        }
                    });
                } else {
                    this.props.handleFileUpdateState(file);
                }
            }
        );
    };

    onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await this.readFile(file);

            this.setState({
                imageSrc: imageDataUrl,
                crop: { x: 0, y: 0 },
                zoom: 1,
            });
        }
    };

    readFile = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => resolve(reader.result),
                false
            );
            reader.readAsDataURL(file);
        });
    };

    toggleModal = () => {
        this.setState((prevState) => {
            return { isModalOpen: !prevState.isModalOpen };
        });
    };

    render() {
        return (
            <>
                <UploadCenter align={this.props.align || 'center'}>
                    <FormUploadFile
                        title={this.props.title}
                        text="Add Photo"
                        onClick={this.toggleModal}
                        img={this.state.croppedImage}
                    />
                    {this.props.errorMessage && (
                        <ErrorMessageFile>
                            {this.props.errorMessage}
                        </ErrorMessageFile>
                    )}
                </UploadCenter>

                <Modal
                    isOpen={this.state.isModalOpen}
                    handleClose={this.toggleModal}
                    containerWidth="1100"
                    containerHeight="850"
                    containerSizeUnit="px"
                    containerPadding="25"
                    title={this.props.title}
                >
                    <ModalWrapper>
                        <ModalWrapperContent>
                            <ModalUploadContent>
                                <UploadImage>
                                    <IconNew
                                        bp1="0"
                                        bp2="-365"
                                        iwidth="130"
                                        iheight="130"
                                    />
                                    <p>Upload Image</p>
                                    <input
                                        type="file"
                                        onChange={this.onFileChange}
                                    />
                                    {this.state.imageSrc && (
                                        <Cropper
                                            image={this.state.imageSrc}
                                            crop={this.state.crop}
                                            zoom={this.state.zoom}
                                            aspect={1}
                                            cropSize={{
                                                width: 370,
                                                height: 370,
                                            }}
                                            onCropChange={this.onCropChange}
                                            onCropComplete={this.onCropComplete}
                                            onZoomChange={this.onZoomChange}
                                            showGrid={false}
                                            style={{
                                                cropAreaStyle: {
                                                    borderRadius: 10,
                                                    border: 'none',
                                                },
                                            }}
                                        />
                                    )}
                                </UploadImage>

                                <p>
                                    <span>Zoom</span>
                                    <span>
                                        %{' '}
                                        {Math.round(
                                            (this.state.zoom - 1) * 100
                                        )}
                                    </span>
                                </p>
                                <InputSlider
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={(this.state.zoom - 1) * 100}
                                    onChange={() => {
                                        /* Disable Warning */
                                    }}
                                    onInput={this.onZoomChange}
                                />

                                <ButtonWrapper>
                                    <UploadImageButton>
                                        <ButtonText
                                            styled={
                                                ButtonTextFlatUploadWhiteStyles
                                            }
                                        >
                                            Change Image
                                        </ButtonText>
                                        <input
                                            type="file"
                                            onChange={this.onFileChange}
                                        />
                                    </UploadImageButton>
                                    <ButtonText
                                        onClick={this.saveResult}
                                        isLoading={this.state.isLoading}
                                        styled={ButtonTextFlatUploadStyles}
                                        disabled={this.state.isCropping}
                                    >
                                        Save Photo
                                    </ButtonText>
                                </ButtonWrapper>
                            </ModalUploadContent>
                        </ModalWrapperContent>
                    </ModalWrapper>
                </Modal>
            </>
        );
    }
}

export default FormUploadFileCrop;
