import styled from "styled-components";

export const RadioButton = styled.label`
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        height: 0; width: 0;
        cursor: pointer;
    }
    em {
        position: absolute;
        top: 7px; left: 0;
        height: 18px;
        width: 18px;
        border: solid 1px ${props => props.theme.grayDarkBorder};
        border-radius: 50%;
    }
    &:hover input ~ em {
        // background-color: #ccc;
    }
    input:checked ~ em {
        background-color: #fff;
    }
    em:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ em:after {
        display: block;
    }
    em:after {
        top: 3px; left: 3px;
        width: 12px; height: 12px;
        border-radius: 50%;
        background: ${props => props.theme.cyan};
    }
    ${
        props => props.styled
    };
`;