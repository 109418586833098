import React, { Component, createRef } from 'react';
import { TextArea, List, Triangle } from './Styled';
import { sendAsyncRequest } from '../../../Utils';
import getCaretCoordinates from 'textarea-caret';

class AutoCompleteTextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            caret: {
                top: 0,
                left: 0,
            },
            isOpen: false,
            matchType: null,
            match: null,
            list: [],
            selectionEnd: null,
        };
        this.users = [
            { name: 'Bob', id: 'bobrm' },
            { name: 'Andrew', id: 'andrew_s' },
            { name: 'Simon', id: 'simon__a' },
        ];
        this.hashtagPattern = new RegExp(`([#])(?:(?!\\1)[^\\s])*$`);
        this.userPattern = new RegExp(`([@])(?:(?!\\1)[^\\s])*$`);
        this.textareaRef = createRef();
    }

    keyDown = (e) => {
        this.autosize();
       /*  const code = e.keyCode || e.which; */
        // Down
        //if (code === 40) down()
        // Up
        //if (code === 38) up()
        // Enter
        //if (code === 13) onSelect()
    };

    onChange = (e) => {
        this.setState({
            caret: getCaretCoordinates(e.target, e.target.selectionEnd),
        });
        const { selectionEnd, value } = e.target;
        this.setState({ value });
        const userMatch = this.userPattern.exec(value.slice(0, selectionEnd));
        const hashtagMatch = this.hashtagPattern.exec(
            value.slice(0, selectionEnd)
        );
        if (hashtagMatch && hashtagMatch[0]) {
            this.setState({
                matchType: hashtagMatch[1],
                match: hashtagMatch[0],
                selectionEnd,
            });
            this.suggest(hashtagMatch[0], hashtagMatch[1]);
        } else if (userMatch && userMatch[0]) {
            this.setState({
                matchType: userMatch[1],
                match: userMatch[0],
                selectionEnd,
            });
            this.suggest(userMatch[0], userMatch[1]);
        } else {
            this.setState({
                match: null,
                matchType: null,
                isOpen: false,
            });
        }
    };

    suggest = (match, matchType) => {
        if (matchType === '#') {
            sendAsyncRequest(
                `/hashtag/?hashtag=${match.substring(1)}`,
                'get',
                {}
            ).then((res) => {
                this.setState({
                    list: res.body.Result,
                    isOpen: res.body.Result.length !== 0,
                });
            });
        } else if (matchType === '@') {
            this.setState({
                list: this.users,
                isOpen: this.users.length !== 0,
            });
        }
    };

    autosize = () => {
        var el = document.getElementsByClassName('autoComplete')[0];
        setTimeout(function() {
            el.style.cssText = 'height:auto; padding:0';
            // for box-sizing other than "content-box" use:
            // el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
        }, 0);
    };

    clickHandler = (selected) => {
        const { selectionEnd, match, matchType, value } = this.state;
        const select = matchType + selected;

        // It's replace value text
        const pre = value.substring(0, selectionEnd - match.length) + select;
        const next = value.substring(selectionEnd);
        const newValue = pre + next;
        this.setState({ isOpen: false, value: newValue });
        this.textareaRef.current.selectionEnd = pre.length;
    };

    render() {
        return (
            <>
                <TextArea
                    id="postText"
                    name="postText"
                    placeholder="What's on your mind ? ..."
                    maaxLength={255}
                    className="autoComplete"
                    onKeyDown={this.keyDown}
                    onChange={this.onChange}
                    value={this.state.value}
                    ref={this.textareaRef}
                />
                {this.state.isOpen && (
                    <List top={this.state.caret.top}>
                        <Triangle left={this.state.caret.left} />
                        {this.state.matchType === '#'
                            ? this.state.list.map((hashtag, index) => (
                                  <button
                                      key={'hashtag' + index}
                                      className="listItem"
                                      onClick={() =>
                                          this.clickHandler(
                                              hashtag.hashtag,
                                          )
                                      }
                                  >
                                      <h5>{`#${hashtag.hashtag}`}</h5>
                                  </button>
                              ))
                            : this.state.list.map((user, index) => (
                                  <button
                                      key={'user' + index}
                                      className="listItem"
                                      onClick={() =>
                                        this.clickHandler(
                                            user.id
                                        )
                                    }
                                  >
                                      <h5>{user.name}</h5>
                                      <p>{user.id}</p>
                                  </button>
                              ))}
                    </List>
                )}
            </>
        );
    }
}

export default AutoCompleteTextArea;
