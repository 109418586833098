import React, { Component } from 'react';
import { Column } from '../../../styles/Styled';
import {
    Wrapper,
    ButtonWrapper,
    Image,
    Right,
    GalleryCardWrapper,
    GalleryWrapper,
    ImgWrapper,
    ImgStyle,
    ExtendButton,
    ArrowRightMore,
    GalleryHeader,
    CardsWrapper,
} from './Styled';
import { TabPage } from '../TabBarPages/Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import GroupTypeTitle from '../GroupTypeTitle';
import HeaderInfo from '../ProfileGroupTypeHeaderInfo';
import ActionButtons from '../ProfileGroupTypeActionButtons';
import TabPages, { TabStyle } from '../TabBarPages';
import ImageGroup from '../ProfileGroupTypeImageGroup';
import SocialIcons from '../ProfileGroupTypeSocialIcons';
import Stats from '../ProfileGroupTypeStats';
import InfoBox, { ContentType } from '../ProfileGroupTypeInfoBox';
import InfoTree from '../ProfileGroupTypeInfoTree';
import IconNew from '../IconNew';
import Button, { ButtonFollowConnectType } from '../ButtonFollowConnect';
import SimilarListings from '../SimilarListings';
import { ImageItemType } from '../ImageItemAndMore';
//import { Link } from 'react-router-dom';
import { sendAsyncRequest } from '../../../Utils';
import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';

class GroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: {},
            visibleGallery: 3,
            hideExtendButton: false,
        };
    }

    extendButtonHandler = () => {
        this.setState({
            visibleGallery: this.props.imageGallery.length,
            hideExtendButton: true,
        });
    };

    componentDidMount() {
        sendAsyncRequest('/group/full_details/1/', 'get', {}).then((json) => {
            if (json.status === 200) {
                this.setState({
                    group: { ...json.body.Result },
                });
            }
        });
    }

    onClickHandler = () => {
        this.setState({ showComponent: true });
    };

    render() {
        const group = { ...this.state.group };
        let groupType;
        let buttons;
        switch (group.general_group_type) {
            case 'general':
                groupType = 'GENRAL GROUP';
                buttons = (
                    <ButtonWrapper>
                        <Button type={ButtonFollowConnectType.FOLLOW} />
                        <Button type={ButtonFollowConnectType.JOIN} active />
                        <ActionButtons contactInfo group />
                    </ButtonWrapper>
                );
                break;
            case 'brokerage_office':
                groupType = 'BROKERAGE OFFICE';
                buttons = (
                    <ButtonWrapper>
                        <Button type={ButtonFollowConnectType.FOLLOW} />
                        <Button type={ButtonFollowConnectType.CONNECT} active />
                        <ActionButtons contactInfo group />
                    </ButtonWrapper>
                );
                break;
            case 'mls_provider':
                groupType = 'MLS Provider';
                buttons = (
                    <ButtonWrapper>
                        <Button type={ButtonFollowConnectType.FOLLOW} />
                        <Button type={ButtonFollowConnectType.CONNECT} active />
                        <ActionButtons contactInfo group />
                    </ButtonWrapper>
                );
                break;
            case 'Franchise':
                groupType = 'Franchise';
                buttons = (
                    <ButtonWrapper>
                        <Button type={ButtonFollowConnectType.FOLLOW} />
                        <ActionButtons contactInfo group />
                    </ButtonWrapper>
                );
                break;
            case 'Association':
                groupType = 'Association';
                buttons = (
                    <ButtonWrapper>
                        <Button type={ButtonFollowConnectType.FOLLOW} />
                        <Button type={ButtonFollowConnectType.CONNECT} active />
                        <ActionButtons contactInfo group />
                    </ButtonWrapper>
                );
                break;
            case 'authority':
                groupType = 'Authority';
                buttons = (
                    <ButtonWrapper>
                        <Button type={ButtonFollowConnectType.FOLLOW} />
                        <Button type={ButtonFollowConnectType.CONNECT} active />
                        <ActionButtons contactInfo group />
                    </ButtonWrapper>
                );
                break;
            default:
                groupType = '';
                break;
        }
        if (!group.pk) {
            return <SkeletonLoading type={SkeletonLoadingType.PROFILE_PAGE} />;
        }

        return (
            <Wrapper>
                <GroupTypeTitle>{groupType}</GroupTypeTitle>

                <Column type="two_column" first>
                    <Image src={group.picture} />

                    <Right>
                        <HeaderInfo
                            groupTitle={group.name}
                            groupAddress={group.location_text}
                            groupTagLink={group.tagLink}
                            groupMergeLink={group.link}
                            goldBadge={group.premium}
                            blueBadge={group.verified}
                        />
                        {buttons}
                    </Right>

                    {group.general_group_type !== 'general' ? (
                        <TabPages
                            theme={TabStyle.GroupTab}
                            defaultPageIndex={0}
                        >
                            <TabPage title="About us">
                                <div>
                                    <strong>About us: </strong>
                                    <br />
                                    {group.description}
                                    <br />
                                    <p>{group.about_more}</p>
                                </div>
                                <br />
                                <GalleryWrapper>
                                    <GalleryHeader>Photo Gallery</GalleryHeader>
                                    <CardsWrapper
                                        extended={
                                            this.state.visibleGallery ===
                                            this.props.imageGallery.length
                                        }
                                    >
                                        {this.props.imageGallery
                                            .slice(0, this.state.visibleGallery)
                                            .map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <GalleryCardWrapper
                                                        firstItem ={index === 0}
                                                        everySeventh={(index % 6) === 0}
                                                        key={item.id}
                                                        onClick={() =>
                                                            this.props.handleButtonClick(
                                                                index,
                                                                item.name
                                                            )
                                                        }
                                                    >
                                                        {item.photos
                                                            .slice(0, 4)
                                                            .map(
                                                                (
                                                                    image,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <ImgStyle
                                                                        >
                                                                            <span
                                                                            >
                                                                                {
                                                                                    item.photoNumber
                                                                                }
                                                                            </span>
                                                                        </ImgStyle>
                                                                        <ImgWrapper
                                                                            key={
                                                                                index
                                                                            }
                                                                            src={
                                                                                image.image
                                                                            }
                                                                            alt="property"
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                    </GalleryCardWrapper>
                                                </React.Fragment>
                                            ))}
                                        {!this.state.hideExtendButton && (
                                            <ExtendButton
                                                onClick={
                                                    this.extendButtonHandler
                                                }
                                            >
                                                <span>
                                                    {this.props.imageGallery
                                                        .length - 3}{' '}
                                                    <ArrowRightMore />{' '}
                                                </span>
                                                <span className="textStyle">
                                                    MORE
                                                </span>
                                            </ExtendButton>
                                        )}
                                    </CardsWrapper>
                                </GalleryWrapper>
                            </TabPage>
                            <TabPage title="Our Team">
                                <ImageGroup
                                    type={ImageItemType.LARGE}
                                    title="Our Team"
                                    count={group.our_team.length}
                                    max="9"
                                    images={group.our_team
                                        .slice(0, 9)
                                        .map((img) => {
                                            return {
                                                id: img.id,
                                                img: img.img,
                                                fullName: img.fullName,
                                                role: img.role,
                                            };
                                        })}
                                />
                                <ImageGroup
                                    type={ImageItemType.LARGE}
                                    title="Board of Director"
                                    count={group.board_of_director.length}
                                    max="5"
                                    images={group.board_of_director
                                        .slice(0, 5)
                                        .map((img) => {
                                            return {
                                                id: img.id,
                                                img: img.img,
                                                fullName: img.fullName,
                                                role: img.role,
                                                gearBadge: !!img.gearBadge,
                                                userBadge: !!img.userBadge,
                                            };
                                        })}
                                />
                            </TabPage>
                        </TabPages>
                    ) : (
                        <TabPage title="About us">
                            <div>
                                <strong>About us: </strong>
                                <br />
                                {group.description}
                                <br />
                                <p>{group.about_more}</p>
                            </div>
                            <br />
                            <ImageGroup
                                type={ImageItemType.MEDIUM}
                                title="Gallery"
                                count={group.gallery.length}
                                max="5"
                                images={group.gallery
                                    .slice(0, 5)
                                    .map((img, index) => {
                                        return {
                                            id: 'gImg-' + index,
                                            img,
                                        };
                                    })}
                            />
                        </TabPage>
                    )}
                </Column>

                <Column type="one_column">
                    <SocialIcons
                        items={[
                            {
                                to: group.instagram_link,
                                icon: (
                                    <IconNew
                                        bp1="-521"
                                        bp2="-358"
                                        iwidth="34"
                                        iheight="34"
                                    />
                                ),
                            },
                            {
                                to: group.linked_in_link,
                                icon: (
                                    <IconNew
                                        bp1="-449"
                                        bp2="-358"
                                        iwidth="34"
                                        iheight="34"
                                    />
                                ),
                            },
                            {
                                to: group.faceBook_link,
                                icon: (
                                    <IconNew
                                        bp1="-426"
                                        bp2="-358"
                                        iwidth="22"
                                        iheight="34"
                                    />
                                ),
                            },
                            {
                                to: group.twitter_link,
                                icon: (
                                    <IconNew
                                        bp1="-485"
                                        bp2="-358"
                                        iwidth="34"
                                        iheight="34"
                                    />
                                ),
                            },
                        ]}
                    />

                    <Stats
                        items={
                            group.following
                                ? [
                                      {
                                          name: 'Following',
                                          count: group.following,
                                      },
                                      {
                                          name: 'Followers',
                                          count: group.followers,
                                      },
                                      {
                                          name: 'Connections',
                                          count: group.connections,
                                      },
                                      {
                                          name: 'Properties',
                                          count: group.properties,
                                      },
                                  ]
                                : [
                                      {
                                          name: 'Groups',
                                          count: group.group_count,
                                      },
                                      {
                                          name: 'Followers',
                                          count: group.followers,
                                      },
                                      {
                                          name: 'Connections',
                                          count: group.connections,
                                      },
                                      {
                                          name: 'Properties',
                                          count: group.properties,
                                      },
                                  ]
                        }
                    />

                    {group.contact_details && (
                        <InfoBox
                            closed={true}
                            title="Contact Details"
                            contentType={ContentType.LINKS}
                            content={group.contact_details}
                        />
                    )}

                    {group.areas && (
                        <InfoBox
                            closed={true}
                            title="Area"
                            titleButton="Map"
                            contentType={ContentType.TEXT}
                            content={group.area}
                        />
                    )}

                    {group.important_links && (
                        <InfoTree
                            title="Important Links"
                            items={group.important_links}
                        />
                    )}

                    {group.member_ships && (
                        <InfoBox
                            title="Membership"
                            subTitle="(Groups)"
                            contentType={ContentType.IMAGE}
                            content={group.member_ships}
                        />
                    )}

                    {group.certificates && (
                        <InfoBox
                            title="Certificates"
                            contentType={ContentType.IMAGE_SMALL}
                            content={group.certificates}
                        />
                    )}

                    {group.trophies && (
                        <InfoBox
                            title="Trophies"
                            contentType={ContentType.IMAGE_BADGE}
                            content={group.trophies}
                        />
                    )}

                    {group.documents && (
                        <InfoTree title="Documents" items={group.documents} />
                    )}
                </Column>
                <ClearFloats />

                <SimilarListings listings={group.feeds} type={group.type} />
            </Wrapper>
        );
    }
}

export default GroupPage;
