import React, { Component, Fragment } from 'react';
import {
    ExpandButton,
    WheelButton,
    FabButton,
    Header,
    MenuFooter,
    Wrapper,
    Triangle,
} from './Styled';
import IconNew from '../IconNew';
import { ArrowDown } from '../BaseTemplate/Styled';
import { sendAsyncRequest } from '../../../Utils';
import Config from '../../../config';
import { Link, withRouter } from 'react-router-dom';
import NotificationsList from '../NotificationsList';
import ProfileMenuList from '../ProfileMenuList';
import ProfileMenuVerification from '../ProfileMenuVerification';
import ProfileMenuNetworkTab from '../ProfileMenuNetworkTab';
import roadMapStore from '../../../stores/roadMapStore';
import { observer } from 'mobx-react';
import ProfileMenuContent from '../ProfileMenuContent/ProfileMenuContent';
import ProfileMenuVaultTabBar from '../ProfileMenuVaultTabBar';

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wheelOpen: false,
            userInfo: {},
            unreadCount: 0,
            notificationCount: 0,
            isClosed: props.closed,
            pageToShow: 0,
        };
        this.pagesToShow = {
            NOTHING: -1,
            NETWORK: 0,
            INVITE: 1,
            VAULT: 2,
            ROADMAP: 3,
            NOTIFICATION: 4,
            LIST: 5,
            VERIFICATION: 6,
        };
    }

    componentDidMount() {
        // sendAsyncRequest('/menu-group', 'get', {}).then((json) => {
        //     const groupLists = [];
        //     if (json.status === 200) {
        //         json.body.results.forEach(
        //             result => {
        //                 groupLists.push(
        //                     <SocialConnections
        //                         key={result.id}
        //                         title={result.title}
        //                         connections={result.list}
        //                         seeMore
        //                     />
        //                 );
        //             }
        //         );
        //         this.setState({
        //             groupLists
        //         });
        //     }
        // });

        sendAsyncRequest('/user/', 'get', {}).then((json) => {
            if (json.status === 200) {
                this.setState({
                    userInfo: json.body,
                });
            }
        });
        // Unread Notifications
        /*getClientTokens().then(result => {
            const socket = openSocket(Config.ENDPOINT_SOCKET);
            const messageContent = {
                deviceType: 'WEB',
                userToken: result.idToken,
                deviceToken: null,
                userID: null,
                onesignalUserID: null
            };
            socket.on('connect', function () {
                socket.emit('message', {
                    action: 'takeMyInformation',
                    content: messageContent
                });
            });
            socket.on("message", function (data) {
                if (data.action === 'notification') {
                    const notification = data.content;
                    console.log("notification = ", notification);
                }
            });
            socket.on('disconnect', function () {});
        });*/
    }

    expandClickHandler = () => {
        this.setState((prevState) => {
            return { isClosed: !prevState.isClosed };
        });
    };

    notificationClickHandler = () => {
        this.setState((prevState) => {
            return {
                pageToShow:
                    prevState.pageToShow === this.pagesToShow.NOTIFICATION
                        ? this.pagesToShow.NOTHING
                        : this.pagesToShow.NOTIFICATION,
                isClosed: false,
            };
        });
    };

    listClickHandler = () => {
        this.setState((prevState) => {
            return {
                pageToShow:
                    prevState.pageToShow === this.pagesToShow.LIST
                        ? this.pagesToShow.NETWORK
                        : this.pagesToShow.LIST,
                isClosed: false,
            };
        });
    };
    verificationClickHandler = () => {
        this.setState((prevState) => {
            return {
                pageToShow:
                    prevState.pageToShow === this.pagesToShow.VERIFICATION
                        ? this.pagesToShow.NOTHING
                        : this.pagesToShow.VERIFICATION,
                isClosed: false,
            };
        });
    };

    render() {
        return (
            <Wrapper closed={this.state.isClosed}>
                <Header
                    closed={this.state.isClosed}
                    unexpandable={this.props.unexpandable}
                >
                    <Link
                        to={Config.ROUTES.profile.person + '/dave'}
                        title={this.state.userInfo.name}
                    >
                        <img
                            src={this.state.userInfo.picture}
                            alt={this.state.userInfo.name}
                        />
                    </Link>
                    {this.state.unreadCount > 0 && (
                        <div>
                            <IconNew
                                bp1="-132"
                                bp2="-149"
                                iwidth="38"
                                iheight="34"
                            />
                            <span>{this.state.unreadCount}</span>
                        </div>
                    )}
                    <h3>
                        <span>{this.state.userInfo.name}</span>
                        <i />
                        <em>online</em>
                    </h3>
                    <IconNew
                        bp1="-523"
                        bp2="-203"
                        iwidth="30"
                        iheight="30"
                        onClick={this.listClickHandler}
                    />

                    <IconNew
                        bp1="-207"
                        bp2="-130"
                        iwidth="29"
                        iheight="33"
                        onClick={this.notificationClickHandler}
                    />
                    {this.state.notificationCount > 0 && (
                        <strong onClick={this.notificationClickHandler}>
                            {this.state.notificationCount}
                        </strong>
                    )}

                    <IconNew
                        onClick={this.verificationClickHandler}
                        bp1="-172"
                        bp2="-130"
                        iwidth="33"
                        iheight="33"
                    />
                </Header>

                {!this.props.unexpandable &&
                    (this.state.isClosed ? (
                        <ExpandButton onClick={this.expandClickHandler}>
                            <ArrowDown />
                        </ExpandButton>
                    ) : (
                        <ExpandButton
                            isExpand
                            onClick={this.expandClickHandler}
                        >
                            <ArrowDown isExpand />
                        </ExpandButton>
                    ))}

                {!this.state.isClosed && (
                    <Fragment>
                        <WheelButton open={this.state.wheelOpen}>
                            <button
                                onClick={() =>
                                    this.setState((prevState) => {
                                        return {
                                            wheelOpen: !prevState.wheelOpen,
                                        };
                                    })
                                }
                            >
                                <IconNew
                                    bp1="-324"
                                    bp2="-432"
                                    iwidth="52"
                                    iheight="50"
                                />
                            </button>
                            <span
                                onClick={() => {
                                    roadMapStore.setShowRoadMap(false);
                                    this.setState({
                                        pageToShow: this.pagesToShow.NETWORK,
                                    });
                                }}
                            >
                                {this.state.pageToShow ===
                                this.pagesToShow.NETWORK ? (
                                    <>
                                        <IconNew
                                            bp1="-470"
                                            bp2="-450"
                                            iwidth="36"
                                            iheight="23"
                                        />
                                        <Triangle />
                                    </>
                                ) : (
                                    <IconNew
                                        bp1="-470"
                                        bp2="-425"
                                        iwidth="36"
                                        iheight="25"
                                    />
                                )}
                                <p>Network</p>
                            </span>
                            <span
                                onClick={() => {
                                    roadMapStore.setShowRoadMap(false);
                                    this.setState({
                                        pageToShow: this.pagesToShow.INVITE,
                                    });
                                }}
                            >
                                {this.state.pageToShow ===
                                this.pagesToShow.INVITE ? (
                                    <>
                                        <IconNew
                                            bp1="-442"
                                            bp2="-453"
                                            iwidth="26"
                                            iheight="26"
                                        />
                                        <Triangle />
                                    </>
                                ) : (
                                    <IconNew
                                        bp1="-442"
                                        bp2="-426"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                )}
                                <p>Invite</p>
                            </span>
                            <span
                                onClick={() => {
                                    roadMapStore.setShowRoadMap(false);
                                    this.setState({
                                        pageToShow: this.pagesToShow.VAULT,
                                    });
                                }}
                            >
                                {this.state.pageToShow ===
                                this.pagesToShow.VAULT ? (
                                    <>
                                        <IconNew
                                            bp1="-411"
                                            bp2="-452"
                                            iwidth="31"
                                            iheight="26"
                                        />
                                        <Triangle />
                                    </>
                                ) : (
                                    <IconNew
                                        bp1="-412"
                                        bp2="-426"
                                        iwidth="31"
                                        iheight="26"
                                    />
                                )}
                                <p>Vault</p>
                            </span>
                            <span
                                onClick={() => {
                                    roadMapStore.setShowRoadMap(true);
                                    this.setState({
                                        pageToShow: this.pagesToShow.ROADMAP,
                                    });
                                }}
                            >
                                {this.state.pageToShow ===
                                this.pagesToShow.ROADMAP ? (
                                    <>
                                        <IconNew
                                            bp1="-381"
                                            bp2="-457"
                                            iwidth="31"
                                            iheight="30"
                                        />
                                        <Triangle left />
                                    </>
                                ) : (
                                    <IconNew
                                        bp1="-381"
                                        bp2="-426"
                                        iwidth="31"
                                        iheight="30"
                                    />
                                )}
                                <p>Road Map</p>
                            </span>
                        </WheelButton>
                        <FabButton wheelOpen={this.state.wheelOpen}>
                            <button>+</button>
                        </FabButton>

                        {this.state.pageToShow ===
                            this.pagesToShow.VERIFICATION && (
                            <ProfileMenuVerification
                                backButton={this.verificationClickHandler}
                            />
                        )}
                        {this.state.pageToShow ===
                            this.pagesToShow.NOTIFICATION && (
                            <NotificationsList
                                backButton={this.notificationClickHandler}
                            />
                        )}
                        {this.state.pageToShow === this.pagesToShow.LIST && (
                            <ProfileMenuList
                                backButton={this.listClickHandler}
                            />
                        )}
                        {this.state.pageToShow === this.pagesToShow.NETWORK && (
                            <ProfileMenuNetworkTab />
                        )}
                        {this.state.pageToShow === this.pagesToShow.INVITE && (
                            <ProfileMenuContent>
                                <p>INVITE</p>
                            </ProfileMenuContent>
                        )}
                        {this.state.pageToShow === this.pagesToShow.VAULT && (
                            <ProfileMenuVaultTabBar />
                        )}
                        {this.state.pageToShow === this.pagesToShow.ROADMAP && (
                            <ProfileMenuNetworkTab />
                        )}

                        <MenuFooter>
                            About us | Help | Jobs | Privacy Policy | Terms
                            <br />© 2020 RealtyFeed
                        </MenuFooter>
                    </Fragment>
                )}
            </Wrapper>
        );
    }
}

export default withRouter(observer(ProfileMenu));
