import styled, { css } from 'styled-components';
import { ArrowRight } from '../BaseTemplate/Styled';

export const Wrapper = styled.div`
    position: relative;
`;

export const Right = styled.div`
    height: 290px;
    margin-left: 200px;

    @media ${(props) => props.theme.mediaHD} {
        height: 230px;
        margin-left: 150px;
    }
`;

export const Image = styled.img`
    width: 170px;
    height: 170px;
    margin: 0;
    float: left;
    border-radius: 20px;

    @media ${(props) => props.theme.mediaHD} {
        width: 120px;
        height: 120px;
    }
`;

export const ButtonWrapper = styled.div`
    margin-top: 24px;
    button {
        margin-right: 15px;
    }
    button:first-of-type {
        margin-right: 10px;
    }
    button:nth-of-type(2) {
        margin-right: 20px;
    }

    @media ${(props) => props.theme.mediaHD} {
        margin-top: 0;
    }
`;

export const BadgeButtonStyle = css`
    background: ${(props) => props.theme.darkBlue};
    width: 190px;
`;

export const GalleryCardWrapper = styled.div`
    height: 160px;
    width: 160px;
    margin-top:20px;
    margin-left:${props => props.everySeventh ? '0px' : '20px'};
    display: grid;
    grid-template-rows: repeat(2, 79px);
    grid-template-columns: repeat(2, 79px);
    grid-gap: 2px;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;

    .LastPicture {
        opacity: 0.6;
        background: ${(props) => props.theme.textBlack};
        height: 79px;
        width: 79px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const GalleryWrapper = styled.div`
`;

export const ImgStyle = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 79px;
    width: 79px;
    background: rgba(49, 49, 54, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
`;

export const ImgWrapper = styled.img`
    height: 79px;
    width: 79px;
`;

export const ExtendButton = styled.div`
    width: 160px;
    margin-left:20px;
    height: 160px;
    margin-top:20px;
    background: ${(props) => props.theme.grayLighter};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 36px;
    font-weight: bold;
    color: ${(props) => props.theme.textBlack};
    border-radius: 10px;
    cursor: pointer;

    .textStyle {
        font-size: 26px;
        margin-top: 5.4px;
    }
`;

export const ArrowRightMore = styled(ArrowRight)`
    margin: 0;
    margin-bottom: 2.5px;
`;

export const GalleryHeader = styled.span`
    font-size: 25px;
    font-weight: bold;
    color: ${(props) => props.theme.darkBlue};
`;

export const CardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
`;
