import React, { Component, Fragment } from 'react';
import {
    getInputNameAndValue,
    __,
    isEmail,
    random14no4,
    sendAsyncRequest,
    publicRouteRule,
    getUserServer,
    checkPasswordValidity
} from '../../../Utils';
import {
    ButtonTextFlatStyles,
    ButtonTextFlatStylesDark,
    ErrorMessage,
    FormBox,
    InfoParagraph,
    //InfoTextRegister,
    InnerBox,
    InputTextWrapperRegisterStyles,
    Wrapper,
    HintText,
} from './Styled';
import {
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    // LoginSocialWrapper,
    // SocialButton,
} from '../LoginForm/Styled';
import { withRouter } from 'react-router-dom';
import InputText from '../FormInputText';
import ButtonText from '../FormButtonText';
import { Auth } from 'aws-amplify';
import { ClearFloats } from '../BaseTemplate/Styled';
import Config from '../../../config';

export class RegisterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isSubmitStep4: false,
            fields: {},
            errorFields: {},
            // 3 Buttons (For Each Step) | true = disabled
            disabledButtons: [true, true, true, true],
            box: [0, 1, 2, 3], // 3 Steps
        };
        this.pathNames = {
            home: Config.ROUTES.register.root,
            confirm: Config.ROUTES.register.confirm,
            invitationKey: Config.ROUTES.register.invitationKey,
            askInvitationKey: Config.ROUTES.register.askInvitationKey,
        };

        this.username = null;

        this.unknownErrorMsg =
            'Unknown error message occurred. Please try again later.';
        this.registerKey = props.registerKey ? props.registerKey : null;
    }

    componentDidMount() {
        (async () => {
            await publicRouteRule(this);
        })();
        if (
            this.props.location.pathname ===
            Config.ROUTES.register.invitationKey
        ) {
            this.setState({
                box: [-2, -1, 0, 1],
            });
        }
        this.unregisterHistoryListener = this.props.history.listen(
            (location, action) => {
                if (action === 'PUSH') {
                    switch (location.pathname) {
                        case this.pathNames.home:
                            this.setState({
                                box: [0, 1, 2, 3],
                            });
                            break;
                        case this.pathNames.confirm:
                            this.setState({
                                box: [-1, 0, 1, 2],
                            });
                            break;
                        case this.pathNames.invitationKey:
                            this.setState({
                                box: [-2, -1, 0, 1],
                            });
                            break;
                        case this.pathNames.askInvitationKey:
                            this.setState({
                                box: [-3, -2, -1, 0],
                            });
                            break;
                        default:
                            this.setState({
                                box: [0, 1, 2, 3],
                            });
                            break;
                    }
                } else if (action === 'POP') {
                    // If Step4 -> Step3
                    if (location.pathname === this.pathNames.invitationKey) {
                        this.setState({
                            box: [-2, -1, 0, 1],
                        });
                    }
                    // Other Steps -> Start from Begin
                    else {
                        this.setState({
                            box: [0, 1, 2, 3],
                        });
                    }
                }
            }
        );
    }

    componentWillUnmount() {
        this.unregisterHistoryListener();
    }

    // Should be Normal Function for Unit Testing
    // Step 1
    async handleSignUpClick() {
        this.setState({ isLoading: true });

        const regEmail = this.state.fields['regEmail'],
            regPassword = this.state.fields['regPassword'],
            regFullname = this.state.fields['regFullname'];

        if (!isEmail(regEmail)) {
            this.setErrorFields('regEmail', 'Please enter a valid email address.');
        }
        if (!regPassword) {
            this.setErrorFields('regPassword', 'Please enter your password.');
        }
        if (!regFullname) {
            this.setErrorFields('regFullname', 'Please enter your full name.');
        }
        if (!isEmail(regEmail) || !regPassword || !regFullname) {
            this.setState({ isLoading: false });
            return false;
        }
        let passState = checkPasswordValidity(regPassword);
        if( !passState.status ){
            this.setErrorFields('regPassword', passState.message);
            return false;
        }
        this.username = random14no4();

        try {
            const user = await Auth.signUp({
                username: this.username,
                password: regPassword,
                attributes: {
                    email: regEmail,
                    name: regFullname,
                },
            });
            console.log('STEP 01 (SignUp) Success', user);
            this.props.history.push(this.pathNames.confirm);
        } catch (error) {
            console.log('STEP 01 (SignUp) ERROR CAUGHT', error);
            if (
                error.message &&
                error.message.toLowerCase().includes('password')
            ) {
                this.setErrorFields(
                    'regPassword',
                    error.message.substring(error.message.lastIndexOf(':') + 1)
                );
            } else if (
                error.message &&
                error.message.toLowerCase().includes('already exists')
            ) {
                this.setErrorFields('regEmail', 'This email address has already been registered.');
            } else {
                this.setErrorFields(
                    'step1',
                    error.message ? error.message : this.unknownErrorMsg
                );
            }
        }

        this.setState({ isLoading: false });
    }

    // Should be Normal Function for Unit Testing
    // Step 2
    async handleConfirm() {
        this.setState({ isLoading: true });
        try {
            const response = await Auth.confirmSignUp(
                this.username,
                this.state.fields['confirmCode']
            );
            if (response === 'SUCCESS') {
                console.log('STEP 02 (ConfirmSignUp) Success', response);
                // SignIn the User Automatically and Save the User Object
                try {
                    const user = await Auth.signIn(
                        this.username,
                        this.state.fields['regPassword']
                    );
                    console.log('STEP 02 (AutoSignIn) Success', user);
                    if (user.signInUserSession) {
                        this.props.history.push(this.pathNames.invitationKey);
                    } else {
                        console.log('STEP 02 (AutoSignIn) ERROR', 'FATAL');
                        this.setErrorFields(
                            'step1',
                            'Fatal Error, Please Try Again'
                        );
                    }
                } catch (error) {
                    console.log('STEP 02 (AutoSignIn) ERROR', error);
                    this.setErrorFields('step1', error.message);
                }
            } else {
                console.log('STEP 02 (ConfirmSignUp) ERROR', response);
                this.setErrorFields('confirmCode', response);
            }
        } catch (error) {
            console.log('STEP 02 (ConfirmSignUp) ERROR CAUGHT', error);
            this.setErrorFields('confirmCode', error.message);
        }
        this.setState({ isLoading: false });
    }

    // Should be Normal Function for Unit Testing
    // Step 3
    async handleInvitationKey() {
        this.setState({ isLoading: true });
        try {
            const response = await sendAsyncRequest(
                '/invite/key/checker/',
                'post',
                {
                    invite_id: this.state.fields['invitationKey'],
                }
            );
            console.log(response);
            if (response.body.IsSuccess) {
                console.log('Key is O.K.', response.body);
                this.props.history.push(
                    //Config.ROUTES.settings.completeProfile.quickSetup
                    Config.ROUTES.homePage
                );
            } else {
                this.setErrorFields(
                    'invitationKey',
                    response.body.Message
                        ? response.body.Message
                        : 'Server Error. Please try again later.'
                );
            }
        } catch (error) {
            console.log('Key Availability ERROR CAUGHT', error);
            this.setErrorFields('invitationKey', error.message);
        }
        this.setState({ isLoading: false });
    }

    // Step 3 - (2)
    handleAskForInvitationKey = async () => {
        this.setState({ isLoading: true });

        let phoneNumber = this.state.fields['phone_number'];
        const user = await getUserServer();
        console.log(user);
        const name = user.name,
            email = user.email;

        try {
            const response = await sendAsyncRequest(
                '/invite/create/ask/invitation/',
                'post',
                {
                    phone_number: phoneNumber,
                    name: name,
                    email: email,
                },
                null,
                true
            );
            if (response.body.IsSuccess) {
                console.log(
                    'Request for Call Submitted Successfully',
                    response.body
                );
                this.setState({
                    isLoading: false,
                    fields: {},
                    isSubmitStep4: true,
                });
            } else {
                this.setErrorFields('phone_number', response.body.Message);
            }
        } catch (error) {
            console.log('Request for Call ERROR CAUGHT', error);
            this.setErrorFields('phone_number', error.message);
        }
        this.setState({ isLoading: false });
    };

    setErrorFields = (field, errMessage) => {
        const errorFields = this.state.errorFields;
        errorFields[field] = errMessage;
        this.setState({
            errorFields,
        });
    };

    handleInputChange = (event) => {
        const inputData = getInputNameAndValue(event),
            fields = this.state.fields,
            errorFields = this.state.errorFields;
        fields[inputData.name] = inputData.value;
        errorFields[inputData.name] = null;
        this.setState(
            {
                fields,
                errorFields,
            },
            async () => {
                // Enable/Disable the Related Button
                const disabledButtons = [...this.state.disabledButtons];
                switch (inputData.name) {
                    // Fields for Step 1
                    case 'regEmail':
                    case 'regPassword':
                    case 'regFullname':
                        if (
                            this.state.fields['regEmail'] &&
                            this.state.fields['regPassword'] &&
                            this.state.fields['regFullname']
                        ) {
                            disabledButtons[0] = false; // false = enabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        } else {
                            disabledButtons[0] = true; // true = disabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        }
                        break;
                    // Fields for Step 2
                    case 'confirmCode':
                        if (this.state.fields['confirmCode']) {
                            disabledButtons[1] = false; // false = enabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        } else {
                            disabledButtons[1] = true; // true = disabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        }
                        break;
                    // Fields for Step 3
                    case 'invitationKey':
                        if (this.state.fields['invitationKey']) {
                            disabledButtons[2] = false; // false = enabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        } else {
                            disabledButtons[2] = true; // true = disabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        }
                        break;
                    // Fields for Step 4
                    case 'phone_number':
                        if (this.state.fields['phone_number']) {
                            disabledButtons[3] = false; // false = enabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        } else {
                            disabledButtons[3] = true; // true = disabled
                            this.setState({
                                disabledButtons: disabledButtons,
                            });
                        }
                        break;
                    default:
                        break;
                }
            }
        );
    };

    render() {
        return (
            <Wrapper>
                <FormBox>
                    <InnerBox step={this.state.box[0]}>
                        <Fragment>
                            <InputText
                                id="regEmail"
                                type="text"
                                name="regEmail"
                                errorMessage={
                                    this.state.errorFields['regEmail']
                                }
                                onChange={this.handleInputChange}
                                onEnter={this.handleSignUpClick.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperRegisterStyles}
                                labelAnimate={__('email', 'Email')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateStyles
                                }
                                required={true}
                            />

                            <InputText
                                id="regPassword"
                                type="password"
                                name="regPassword"
                                errorMessage={
                                    this.state.errorFields['regPassword']
                                }
                                onChange={this.handleInputChange}
                                onEnter={this.handleSignUpClick.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperRegisterStyles}
                                showPassword
                                labelAnimate={__('password', 'Password')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateStyles
                                }
                                required={true}
                            />
                            {!this.state.errorFields['regPassword'] && (
                                <HintText>
                                    Your password should have at least 8
                                    characters and it must contain at least a
                                    symbol and a number
                                </HintText>
                            )}

                            <InputText
                                id="regFullname"
                                type="text"
                                name="regFullname"
                                errorMessage={
                                    this.state.errorFields['regFullname']
                                }
                                onChange={this.handleInputChange}
                                onEnter={this.handleSignUpClick.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperRegisterStyles}
                                labelAnimate={__('full_name', 'Full Name')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateStyles
                                }
                                required={true}
                            />

                            {this.state.errorFields['step1'] && (
                                <ErrorMessage>
                                    {this.state.errorFields['step1']}
                                </ErrorMessage>
                            )}

                            <ButtonText
                                id="step1button"
                                onClick={this.handleSignUpClick.bind(this)}
                                isLoading={this.state.isLoading}
                                styled={ButtonTextFlatStyles}
                            >
                                {__('continue', 'Continue')}
                            </ButtonText>

                            {/* <InfoTextRegister>or Sign up with</InfoTextRegister>

                            <LoginSocialWrapper>
                                <SocialButton className='twitter'>
                                    <IconNew
                                        bp1='-366'
                                        bp2='-484'
                                        iwidth='33'
                                        iheight='27'
                                    />
                                </SocialButton>
                                <SocialButton className='google'>
                                    <IconNew
                                        bp1='-339'
                                        bp2='-484'
                                        iwidth='27'
                                        iheight='27'
                                    />
                                </SocialButton>
                                <SocialButton className='facebook'>
                                    <IconNew
                                        bp1='-324'
                                        bp2='-484'
                                        iwidth='14'
                                        iheight='27'
                                    />
                                </SocialButton>
                            </LoginSocialWrapper> */}
                        </Fragment>
                    </InnerBox>

                    <InnerBox step={this.state.box[1]}>
                        <Fragment>
                            <InfoParagraph>
                                Confirm your email
                                <br />
                                Type in the code we sent to{' '}
                                <strong>{this.state.fields['regEmail']}</strong>
                            </InfoParagraph>

                            <InputText
                                id="confirmCode"
                                type="text"
                                name="confirmCode"
                                errorMessage={
                                    this.state.errorFields['confirmCode']
                                }
                                onChange={this.handleInputChange}
                                onEnter={this.handleConfirm.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperRegisterStyles}
                                labelAnimate={__(
                                    'verification',
                                    'Verification Code'
                                )}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateStyles
                                }
                                required={true}
                            />

                            {this.state.errorFields['step2'] && (
                                <ErrorMessage>
                                    {this.state.errorFields['step2']}
                                </ErrorMessage>
                            )}

                            <ButtonText
                                id="step2button"
                                onClick={this.handleConfirm.bind(this)}
                                isLoading={this.state.isLoading}
                                styled={ButtonTextFlatStyles}
                            >
                                {__('continue', 'Continue')}
                            </ButtonText>
                        </Fragment>
                    </InnerBox>

                    <InnerBox step={this.state.box[2]}>
                        <Fragment>
                            <InputText
                                id="invitationKey"
                                type="text"
                                name="invitationKey"
                                errorMessage={
                                    this.state.errorFields['invitationKey']
                                }
                                onChange={this.handleInputChange}
                                onEnter={this.handleConfirm.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperRegisterStyles}
                                labelAnimate={__(
                                    'invitationKey',
                                    'Invitation Key'
                                )}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={
                                    InputLabelAfterAnimateStyles
                                }
                                required={true}
                            />

                            {this.state.errorFields['step3'] && (
                                <ErrorMessage>
                                    {this.state.errorFields['step3']}
                                </ErrorMessage>
                            )}

                            <ButtonText
                                id="step3button"
                                onClick={this.handleInvitationKey.bind(this)}
                                isLoading={this.state.isLoading}
                                styled={ButtonTextFlatStyles}
                            >
                                {__('continue', 'Continue')}
                            </ButtonText>
                            {this.state.disabledButtons[2] && (
                                <ButtonText
                                    isLoading={this.state.isLoading}
                                    styled={ButtonTextFlatStylesDark}
                                    onClick={() => {
                                        this.props.history.push(
                                            this.pathNames.askInvitationKey
                                        );
                                    }}
                                >
                                    {__(
                                        'request_invitation',
                                        'Request an Invitation Key'
                                    )}
                                </ButtonText>
                            )}
                        </Fragment>
                    </InnerBox>

                    <InnerBox step={this.state.box[3]}>
                        {!this.state.isSubmitStep4 && (
                            <Fragment>
                                <InfoParagraph>
                                    You don't have an invitation key.
                                    <br />
                                    Please enter your phone number, we will call
                                    you soon.
                                </InfoParagraph>

                                <InputText
                                    id="phone_number"
                                    type="text"
                                    name="phone_number"
                                    errorMessage={
                                        this.state.errorFields['phone_number']
                                    }
                                    onChange={this.handleInputChange}
                                    onEnter={this.handleConfirm.bind(this)}
                                    styled={InputTextStyles}
                                    wrapperStyled={
                                        InputTextWrapperRegisterStyles
                                    }
                                    labelAnimate={__(
                                        'phone_number',
                                        'Phone Number'
                                    )}
                                    labelAnimateStyled={InputLabelAnimateStyles}
                                    labelAfterAnimateStyled={
                                        InputLabelAfterAnimateStyles
                                    }
                                    required={true}
                                />

                                {this.state.errorFields['step4'] && (
                                    <ErrorMessage>
                                        {this.state.errorFields['step4']}
                                    </ErrorMessage>
                                )}

                                <ButtonText
                                    onClick={this.handleAskForInvitationKey.bind(
                                        this
                                    )}
                                    isLoading={this.state.isLoading}
                                    styled={ButtonTextFlatStylesDark}
                                >
                                    {__('submit_call', 'Submit for Call')}
                                </ButtonText>
                            </Fragment>
                        )}
                        {this.state.isSubmitStep4 && (
                            <Fragment>
                                <InfoParagraph>
                                    Thank you for your request.
                                    <br />
                                    We will call you soon.
                                </InfoParagraph>
                            </Fragment>
                        )}
                    </InnerBox>

                    <ClearFloats />
                </FormBox>
            </Wrapper>
        );
    }
}

export default withRouter(RegisterForm);
