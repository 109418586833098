import React, { Component } from 'react';
import IconNew from '../IconNew/IconNew';
import Stats from '../ProfileGroupTypeStats';
import { SearchPeopleWrapper } from './Styled';

class SearchNewPeopleItem extends Component {
    render() {
        return this.props.type === 'all' ? (
            <SearchPeopleWrapper vip={this.props.item.vip}>
                <img alt="agent" />
                <span>
                    {this.props.item.type === 'people'
                        ? this.props.item.role
                        : this.props.item.group_type}
                </span>
                <h3>
                    {this.props.item.name}
                    {this.props.item.verified && (
                        <IconNew
                            bp1="-411"
                            bp2="-186"
                            iwidth="20"
                            iheight="20"
                        />
                    )}
                </h3>

                <p>
                    <IconNew bp1="-62" bp2="-77" iwidth="12" iheight="17" />{' '}
                    {this.props.item.location}
                </p>
                <Stats
                    items={
                        this.props.item.type === 'people'
                            ? [
                                  {
                                      name: 'Groups',
                                      count: this.props.item.groups,
                                  },
                                  {
                                      name: 'Followers',
                                      count: this.props.item.followers,
                                  },
                                  {
                                      name: 'Connections',
                                      count: this.props.item.connections,
                                  },
                              ]
                            : [
                                  {
                                      name: 'Active Listings',
                                      count: this.props.item.active_listings,
                                  },
                                  {
                                      name: 'Followers',
                                      count: this.props.item.followers,
                                  },
                                  {
                                      name: 'Members',
                                      count: this.props.item.members,
                                  },
                              ]
                    }
                    small
                    noLine
                />
                <div className="footer">
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-117"
                            iwidth="13"
                            iheight="14"
                        />
                        <h6>
                            {this.props.item.type === 'people'
                                ? 'Social'
                                : 'Group'}{' '}
                            Activity
                        </h6>
                        <p>
                            {this.props.item.type === 'people'
                                ? this.props.item.social_activity
                                : this.props.item.group_activity}
                            %
                        </p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-131"
                            iwidth="14"
                            iheight="14"
                        />
                        <h6>Last Activity</h6>
                        <p>{this.props.item.last_activity} day</p>
                        <em />
                    </div>
                </div>
            </SearchPeopleWrapper>
        ) : (
            <SearchPeopleWrapper vip={this.props.item.vip}>
                <img alt="agent" src={this.props.item.picture} />
                <span>
                    {this.props.item.type === 'people'
                        ? this.props.item.role
                        : this.props.item.group_type}
                </span>
                <h3>
                    {this.props.item.name}
                    {this.props.item.verified && (
                        <IconNew
                            bp1="-411"
                            bp2="-186"
                            iwidth="20"
                            iheight="20"
                        />
                    )}
                </h3>

                <p>
                    <IconNew bp1="-62" bp2="-77" iwidth="12" iheight="17" />{' '}
                    {this.props.item.location}
                </p>
                <Stats
                    items={
                        this.props.item.type === 'people'
                            ? [
                                  {
                                      name: 'Groups',
                                      count: this.props.item.groups,
                                  },
                                  {
                                      name: 'Followers',
                                      count: this.props.item.followers,
                                  },
                                  {
                                      name: 'Connections',
                                      count: this.props.item.connections,
                                  },
                              ]
                            : [
                                  {
                                      name: 'Active Listings',
                                      count: this.props.item.active_listings,
                                  },
                                  {
                                      name: 'Followers',
                                      count: this.props.item.followers,
                                  },
                                  {
                                      name: 'Members',
                                      count: this.props.item.members,
                                  },
                              ]
                    }
                    small
                    noLine
                />
                <div className="footer">
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-117"
                            iwidth="13"
                            iheight="14"
                        />
                        <h6>
                            {this.props.item.type === 'people'
                                ? 'Social'
                                : 'Group'}{' '}
                            Activity
                        </h6>
                        <p>
                            {this.props.item.type === 'people'
                                ? this.props.item.social_activity
                                : this.props.item.group_activity}
                            %
                        </p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-131"
                            iwidth="14"
                            iheight="14"
                        />
                        <h6>Last Activity</h6>
                        <p>{this.props.item.last_activity} day</p>
                        <em />
                    </div>
                </div>
            </SearchPeopleWrapper>
        );
    }
}

export default SearchNewPeopleItem;
