import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import RegisterForm from '../Modules/RegisterForm';
import LoginRegisterDesign from '../Modules/LoginRegisterDesign';
import LoginRegisterFooter from '../Modules/LoginRegisterFooter';
import { LoginRegisterColumn, LoginRegisterMain, Logo } from '../Login/Styled';
import MainLogo from '../Login/MainLogo';
import MainLogoSmall from '../Login/MainLogoSmall';

export default class Register extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showHeader = false;
        super.bodyBg = 'white';
    }

    renderBody() {
        return (
            <>
            <LoginRegisterMain>
                <LoginRegisterDesign />
                <LoginRegisterColumn marginTop={110}>
                    <Logo>
                        {window.matchMedia(this.theme.mediaMobile).matches ? (
                            <MainLogoSmall />
                        ) : (
                            <MainLogo />
                        )}
                    </Logo>
                    <RegisterForm registerKey={this.props.match.params.key} />
                </LoginRegisterColumn>
            </LoginRegisterMain>
            <LoginRegisterFooter />
            </>
        );
    }
}
