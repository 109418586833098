import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 34px;
    color: ${props => props.theme.darkBlue};
    span {
        font-size: 18px;
        font-weight: normal;
        color: ${props => props.theme.textBlueGray};
        margin-left: 12px;
    }
`;

export const Address = styled.p`
    margin: 5px 0 0;
    font-size: 20px;
    font-weight: lighter;
    span {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }
`;

export const LeftItems = styled.div`
    float: left;
`;

export const RightItems = styled.div`
    float: right;
    text-align: right;
    strong {
        display: block;
        font-size: 34px;
        color: ${props => props.theme.textBlack};
    }
`;

export const BedBath = styled.div`
    margin-top: 20px;
    font-size: 18px;
    span {
        margin-right: 15px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    small {
        font-size: 12px;
        border-right: solid 1px ${props => props.theme.grayBorderLight};
        padding-right: 12px;
        &:last-of-type {
            border-right: none;
            padding-right: 0;
        }
    }
    .bath, .area {
        position: relative;
        top: 2px;
    }
`;

export const Tags = styled.div`
    position: relative;
    top: 50px;
    span {
        margin-left: 10px;
    }
`;



export const ActionTimeName = styled.div`
    
`;
export const ActionTime = styled.div`
    display: flex;
    float:right;
    height:${props => props.iSize || "0"}px;
    align-items: center;
`;
export const Time = styled.span`
    color:#999999;
    padding-right:10px;
`;