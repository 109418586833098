import React, { Component, Fragment } from 'react';
import FormInputText from '../FormInputText';
import IconNew from '../IconNew';
import {
    DropDownWrapper,
    DropDownItem,
    DropDownList,
    DropDownArrowDown,
    TypedText,
    InputStyled,
} from './Styled';
import PropTypes from 'prop-types';
import { objIsEqual } from '../../../Utils';

class FormInputDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            inputValue: '',
            value: null,
            disabled: false,
            list: [],
            listNotFound: false,
            loadingText: '',
        };
        this.inputTextRef = React.createRef();
        this.notFoundText = '(Nothing Found)';
    }

    componentDidMount() {
        // We add "show" to list and Save it to State
        if (this.props.hasOwnProperty('list') && this.props.list.length > 0) {
            const list = [...this.props.list];
            list.forEach((item) => (item.show = true));
            this.setState({
                list,
            });
        }
        // Set Default Value
        if (this.props.value) {
            this.initState();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.value &&
            !objIsEqual(prevProps.value, this.props.value)
        ) {
            this.initState();
        }
    }

    initState = () => {
        const itemValue = this.props.list.find(
            (item) => item.value === this.props.value.value // value = {value, text}
        );
        this.setState({
            inputValue: itemValue ? itemValue.text : '',
        });
    };

    dropDownClickHandler = () => {
        if (!this.props.readonly) {
            let inputValue = this.state.inputValue;
            const list = [...this.state.list];
            list.forEach((item) => (item.show = true));
            this.setState({
                list,
                isOpen: true,
                inputValue,
            });
        }
    };

    handleDropDownChange = (that, name, value, extraLocalStatesOnChange) => {
        const isGlobalState = that.constructor.name === 'Object',
            fields = isGlobalState
                ? that.getFields()
                : { ...that.state.fields },
            errorFields = isGlobalState
                ? that.getErrorFields()
                : { ...that.state.errorFields };
        if (value) {
            fields[name] = value;
        } else {
            delete fields[name];
        }
        errorFields[name] = null;
        if (isGlobalState) {
            that.setFields(fields);
            that.setErrorFields(errorFields);
        } else {
            if (!extraLocalStatesOnChange) {
                that.setState({
                    fields,
                    errorFields,
                });
            } else {
                that.setState({
                    fields,
                    errorFields,
                    ...extraLocalStatesOnChange,
                });
            }
        }
    };

    dropDownItemClickHandler = (value, text) => {
        if (value !== -1) {
            // Set values as object = {value, text}
            value = {
                value,
                text,
            };
            // The page handle changes itself
            if (this.props.hasOwnProperty('dropDownValue')) {
                this.props.dropDownValue(this.props.name, value);
                // Changes handled by default
            } else {
                this.props.states.forEach((state) => {
                    this.handleDropDownChange(
                        state,
                        this.props.name,
                        value,
                        this.props.extraLocalStatesOnChange
                    );
                });
            }
            this.setState({
                inputValue: text,
                isOpen: false,
            });
        } else {
            if (this.props.hasOwnProperty('states')) {
                this.props.states.forEach((state) => {
                    this.handleDropDownChange(
                        state,
                        this.props.name,
                        null,
                        this.props.extraLocalStatesOnChange
                    );
                });
            }
            this.setState({
                inputValue: '',
                isOpen: false,
                value: null,
                listNotFound: false,
            });
        }
        this.inputTextRef.current.blur();
    };

    dropDownTextChangeHandler = (e) => {
        const inputValue = e.target.value;
        let list = [...this.state.list];
        let listNotFound = false;
        list.forEach((item) => {
            item.show = item.text.toLowerCase().includes(
                inputValue
                    .trim()
                    .toString()
                    .toLowerCase()
            );
        });
        if (list.every((item) => !item.show)) {
            listNotFound = true;
        }
        this.setState({
            list,
            //inputValue,
            listNotFound,
        });
    };

    dropDownBlurHandler = () => {
        const list = [...this.state.list];
        list.forEach((item) => (item.show = true));
        this.setState({
            list,
            isOpen: false,
            listNotFound: false,
        });
    };

    itemMouseDownHandler = (e) => {
        // Let Click Fired "Before" Blur Handler
        e.preventDefault();
    };

    render() {
        const list = this.props.listParent
            ? [...this.props.list]
            : [...this.state.list];
        if (this.props.listParent) {
            list.forEach((item) => (item.show = true));
        }
        return (
            <Fragment>
                <DropDownWrapper>
                    <FormInputText
                        {...this.props}
                        extraStyled={InputStyled}
                        disabled={
                            this.props.disabled
                                ? this.props.disabled
                                : this.state.disabled
                        }
                        dropdown
                        icon={
                            this.props.oneArrow ? (
                                <DropDownArrowDown />
                            ) : (
                                <IconNew
                                    bp1='-333'
                                    bp2='-214'
                                    iwidth='10'
                                    iheight='19'
                                />
                            )
                        }
                        onClick={this.dropDownClickHandler}
                        value={this.state.inputValue}
                        onInput={this.dropDownTextChangeHandler}
                        onBlur={this.dropDownBlurHandler}
                        refSource={this.inputTextRef}
                    >
                        {this.state.isOpen && (
                            <Fragment>
                                <TypedText>{this.state.inputValue}</TypedText>
                                <DropDownList
                                    scroll={
                                        list.filter((item) => item.show)
                                            .length > 5
                                    }
                                >
                                    {!this.state.listNotFound &&
                                        list.map(
                                            (item) =>
                                                item.show && (
                                                    <DropDownItem
                                                        key={item.value}
                                                        value={item.value}
                                                        onClick={() =>
                                                            this.dropDownItemClickHandler(
                                                                item.value,
                                                                item.text
                                                            )
                                                        }
                                                        onMouseDown={
                                                            this
                                                                .itemMouseDownHandler
                                                        }
                                                    >
                                                        {item.text}
                                                    </DropDownItem>
                                                )
                                        )}
                                    {this.state.listNotFound && (
                                        <DropDownItem
                                            key={0}
                                            value={-1}
                                            onClick={() =>
                                                this.dropDownItemClickHandler(
                                                    -1,
                                                    this.notFoundText
                                                )
                                            }
                                            onMouseDown={
                                                this.itemMouseDownHandler
                                            }
                                        >
                                            (Nothing Found)
                                        </DropDownItem>
                                    )}
                                    {this.props.children}
                                </DropDownList>
                            </Fragment>
                        )}
                    </FormInputText>
                </DropDownWrapper>
            </Fragment>
        );
    }
}

export default FormInputDropDown;

FormInputDropDown.propTypes = {
    oneArrow: PropTypes.bool,
    list: PropTypes.array.isRequired,
    dropDownValue: (props, propName, componentName) => {
        if (props.dropDownValue && props.states) {
            return new Error(
                `You shouldn't use 'dropDownValue' & 'states' together in '${componentName}'.`
            );
        }
        if (props.dropDownValue && props.extraLocalStatesOnChange) {
            return new Error(
                `You shouldn't use 'dropDownValue' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
    states: (props, propName, componentName) => {
        if (props.dropDownValue && props.states) {
            return new Error(
                `You shouldn't use 'dropDownValue' & 'states' together in '${componentName}'.`
            );
        }
        if (props.dropDownValue && props.extraLocalStatesOnChange) {
            return new Error(
                `You shouldn't use 'dropDownValue' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
    extraLocalStatesOnChange: (props, propName, componentName) => {
        if (props.dropDownValue && props.states) {
            return new Error(
                `You shouldn't use 'dropDownValue' & 'states' together in '${componentName}'.`
            );
        }
        if (props.dropDownValue && props.extraLocalStatesOnChange) {
            return new Error(
                `You shouldn't use 'dropDownValue' & 'extraLocalStatesOnChange' together in '${componentName}'.`
            );
        }
    },
};
