import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import Config from "./config";
import { getAuthenticateStatus,/* sendAsyncRequest */ } from './Utils';
import Logout from './components/Logout';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ListingDetail from './components/ListingDetail';
import SearchPageNew from './components/SearchPageNew';
import Profile from './components/Profile';
import Group from './components/Group';
import SettingsNew from './components/SettingsNew';
import FullPageLoading from "./components/Modules/FullPageLoading";
import PageNotFound from "./components/PageNotFound";
import ServerError from "./components/ServerError";
import LeadManagement from "./components/LeadManagement/LeadManagement";
import MyNetworks from './components/MyNetworks';
import DashboardPage from './components/DashboardPage';
import BuildNetwork from "./components/BuildNetwork";
import MyListings from './components/MyListings';
import LandingPage from './components/LandingPage'
//import invitationHeaderStore from './stores/invitationHeaderStore';

class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <ScrollToTopListener />

                <Switch>
                    <Route exact path={[
                        Config.ROUTES.register.root,
                        Config.ROUTES.register.paramPage
                    ]} render={
                        ({ match }) => <Register match={match} />
                    } />
                    <Route exact path={
                        Config.ROUTES.login
                    } render={
                        ({ match }) => <Login match={match} />
                    } />
                     <Route exact path={
                        Config.ROUTES.landingPage
                    } render={
                        ({ match }) => <LandingPage match={match} />
                    } />
                    <Route exact path={[
                        Config.ROUTES.forgetPassword.root,
                        Config.ROUTES.forgetPassword.paramPage
                    ]} render={
                        ({ match }) => <ForgotPassword match={match} />
                    } />
                    <Route exact path={
                        Config.ROUTES.logout
                    } component={Logout} />
                    <Route exact path={
                        Config.ROUTES.serverError
                    } component={ServerError} />

                    <PrivateRoute exact path={
                        Config.ROUTES.homePage
                    }>
                        <Home />
                    </PrivateRoute>

                    <PrivateRoute exact path={[
                        Config.ROUTES.settings.paramCategory,
                        Config.ROUTES.settings.paramPage,
                        Config.ROUTES.settings.paramDetail
                    ]}>
                        <SettingsNew />
                    </PrivateRoute>

                    <PrivateRoute exact path={[
                        Config.ROUTES.search.root,
                        Config.ROUTES.search.paramCategory,
                        Config.ROUTES.search.paramValue
                    ]}>
                        <SearchPageNew />
                    </PrivateRoute>

                    <PrivateRoute exact path={[
                        Config.ROUTES.listing.paramId,
                        Config.ROUTES.listing.paramModal
                    ]}>
                        <ListingDetail />
                    </PrivateRoute>

                    <PrivateRoute exact path={
                        Config.ROUTES.profile.paramGroup
                    }>
                        <Group />
                    </PrivateRoute>

                    <PrivateRoute exact path={
                        Config.ROUTES.profile.paramPerson
                    }>
                        <Profile />
                    </PrivateRoute>

                    <PrivateRoute exact path={[
                        Config.ROUTES.leadManagement.root,
                        Config.ROUTES.leadManagement.paramCategory,
                        Config.ROUTES.leadManagement.paramPerson
                    ]}>
                        <LeadManagement />
                    </PrivateRoute>

                    <PrivateRoute exact path={[
                        Config.ROUTES.myNetworks.root,
                        Config.ROUTES.myNetworks.paramCategory
                    ]}>
                        <MyNetworks />
                    </PrivateRoute>

                    <PrivateRoute exact path={
                        Config.ROUTES.dashboard.root
                    }>
                        <DashboardPage />
                    </PrivateRoute>

                    <PrivateRoute exact path={
                        Config.ROUTES.buildNetwork.root
                    }>
                        <BuildNetwork />
                    </PrivateRoute>
                    <PrivateRoute exact path={[
                        Config.ROUTES.myListings.root,
                        Config.ROUTES.myListings.paramCategory,
                        Config.ROUTES.myListings.paramStatistics,
                        Config.ROUTES.myListings.paramAddTag
                    ]}>
                        <MyListings />
                    </PrivateRoute>

                    {/*<Route exact path="/search-old/:category/:value" component={SearchPage}/>

                    <Route exact path="/settings-old/:category/:type/:subtype/:id"
                           render={({match}) => <Settings match={match}/>}/>
                    <Route exact path="/settings-old/:category/:type"
                           render={({match}) => <Settings match={match}/>}/>

                    <Route exact path="/page/:type" render={({match}) => <Page match={match}/>}/>
                    <Route exact path="/profile/:type" render={({match}) => <Profile match={match}/>}/>
                    <Route exact path="/edit-listing/:id"
                           render={({match}) => <AddListing match={match} type="edit-listing"/>}/>

                    <Route exact path="/ask-for-invitation-key" component={AskForInvitationKey}/>

                    <Route exact path="/insert-key" component={InsertKey}/>
                    <Route exact path="/insert-key/:type" render={({match}) => <InsertKey match={match}/>}/>
                    <Route exact path="/reset-password/:uid/:key"
                           render={({match}) => <ResetPassword match={match}/>}/>

                    <Route exact path="/add-listing"
                           render={({match}) => <AddListing match={match} type="add-listing"/>}/>
                    <Route exact path="/explore" component={Explore}/>
                    <Route exact path="/connections" component={Connections}/>
                    <Route exact path="/agent-map-search" component={AgentMapSearchPage}/>*/}

                    <PrivateRoute>
                        <PageNotFound />
                    </PrivateRoute>

                </Switch>

            </BrowserRouter>
        );
    }
}

export default App;

const PrivateRoute = ({ children, ...rest }) => {
    const [authObj, setAuth] = useState(null);
    useEffect(() => {
        (async () => {
            setAuth(await getAuthenticateStatus());
        })();
    }, []);
    if (authObj === null) {
        return <FullPageLoading />;
    }
    if (authObj === false) {
        return <ServerError />;
    }
    // else {
    //     if (invitationHeaderStore.show) {
    //         const res = sendAsyncRequest('get', '/invitation-header/', {});
    //         console.log(res);
    //         invitationHeaderStore.setHasHeader(true);
    //         invitationHeaderStore.setHeaderType('prem');
    //         invitationHeaderStore.setShow(false);
    //     }
    //     console.log("APP JS RENDERED");
    // }       
    return (
        <Route
            {...rest}
            render={({ location, match }) =>
                (authObj.isAuth)
                    ? (authObj.isInvKey)
                        ? React.cloneElement(children, { match: match })
                        : <Redirect
                            to={{
                                pathname: Config.ROUTES.register.invitationKey
                            }}
                        />
                    : <Redirect
                        to={{
                            pathname: Config.ROUTES.landingPage,
                            state: { from: location }
                        }}
                    />
            }
        />
    )
};

class ScrollToTop extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            // window.scrollTo(0, 0); // No Animation
            const duration = 300,
                scrollStep = -window.scrollY / (duration / 15),
                scrollInterval = setInterval(function () {
                    if (window.scrollY !== 0) {
                        window.scrollBy(0, scrollStep);
                    } else clearInterval(scrollInterval);
                }, 15);
        }
    }
    render() {
        return <React.Fragment />
    }
}

const ScrollToTopListener = withRouter(ScrollToTop);