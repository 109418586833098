import React, { Component, Fragment } from 'react';
import { ClearFloats } from '../BaseTemplate/Styled';
import {
    PropertiesWrapperLeft,
    PropertiesWrapperRight,
} from '../FeedPage/Styled';
import { Column } from '../../../styles/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import SearchTabBar from '../SearchTabBar';
import SearchNewListingItem from '../SearchNewListingItem';
import SearchNewPeopleItem from '../SearchNewPeopleItem';

class SearchNewAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            result: [],
            agentHoverId: 0,
            isLoading: false,
            resultError: false,
        };

        this.fakeResult = [
            {
                type: 'listing',
                address: 'rue Monge, 75005 Paris',
                price: 115000,
                bedrooms: 3,
                bathrooms: 2,
                area: 2150,
                tags: ['N', 'R'],
                likes: 129,
                comments: 73,
                views: 13000,
                shares: 5,
            },
            {
                type: 'listing',
                address: 'rue Monge, 75005 Paris',
                price: 115000,
                bedrooms: 3,
                bathrooms: 2,
                area: 2150,
                tags: ['N', 'R'],
                likes: 129,
                comments: 73,
                views: 13000,
                shares: 5,
            },
            {
                type: 'people',
                role: 'Agent',
                vip: true,
                name: 'Jose Pearson',
                verified: true,
                location: 'Paris',
                connections: 1200,
                followers: 482,
                groups: 13,
                social_activity: 82,
                last_activity: 1,
            },
            {
                type: 'group',
                group_type: 'Authority',
                name: 'FIABCI International Real Estate Federation',
                verified: true,
                location: '75015 Paris, France',
                members: 1200,
                followers: 482,
                active_listings: 136,
                group_activity: 76,
                last_activity: 1,
            },
        ];
    }

    componentDidMount() {
        this.loadSearch(this.fakeResult);
    }

    loadSearch = (data) => {
        let result = [...this.state.result];
        data.forEach((item, index) => {
            switch (item.type) {
                case 'listing':
                    result.push(
                        <SearchNewListingItem
                            type={'all'}
                            listing={item}
                            key={`listing${index}`}
                        />
                    );
                    break;
                default:
                    result.push(
                        <SearchNewPeopleItem
                            type={'all'}
                            item={item}
                            key={`item${index}`}
                        />
                    );
                    break;
            }
        });
        this.setState({ result });
    };

    render() {
        return (
            <Fragment>
                <Column type="one_column_setting" padding_right first>
                    <SearchTabBar defaultIndex={0} />
                    <PropertiesWrapperLeft>
                        {this.state.result.map(
                            (item, index) => index % 3 === 0 && item
                        )}
                    </PropertiesWrapperLeft>
                    <PropertiesWrapperLeft>
                        {this.state.result.map(
                            (item, index) => index % 3 === 1 && item
                        )}
                    </PropertiesWrapperLeft>
                    <PropertiesWrapperRight>
                        {this.state.result.map(
                            (item, index) => index % 3 === 2 && item
                        )}
                    </PropertiesWrapperRight>
                </Column>

                <ProfileMenu />

                <ClearFloats />
            </Fragment>
        );
    }
}

export default SearchNewAll;
