import styled from "styled-components";
import {ArrowRight} from "../BaseTemplate/Styled";
import {ImageItemType} from "./ImageItemAndMore";

export const ImageItem = styled.div`
    ${
        props => {
            switch (props.type) {
                case ImageItemType.SMALL:
                    return `
                            width: 115px;
                            height: 115px;
                        `;
                case ImageItemType.SMALL_TYPE_2:
                    return `
                            width: 110px;
                            height: 110px;
                            margin: 0 30px 30px 0;
                            &:nth-of-type(4n):not(.in-modal) {
                                margin-right: 0;
                            }
                        `;
                case ImageItemType.MEDIUM:
                    return `
                            width: 140px;
                            height: 140px;
                            margin: 0 25px 25px 0;
                            &:nth-of-type(7n):not(.in-modal) {
                                margin-right: 0;
                            }
                        `;
                case ImageItemType.LARGE:
                    return `
                            width: 170px;
                            height: 280px;
                            margin: 0 40px 40px 0;
                            &:nth-of-type(5n):not(.in-modal) {
                                margin-right: 0;
                            }
                        `;
                default:
                    return ``;
            }
        }
    };
    text-align: center;
    float: left;
    position: relative;
    strong {
        display: inline-block;
        font-size: 20px;
        color: ${props => props.theme.cyan};
        word-spacing: 140px;
        line-height: 28px;
    }
    em {
        display: block;
        font-style: normal;
        font-weight: normal;
        margin-top: -4px;
    }
    p {
        ${
            props => {
                switch (props.type) {
                    case ImageItemType.SMALL:
                        return `
                                width: 115px;
                                height: 115px;
                                padding-top: 27px;
                                font-size: 18px;
                                line-height: 28px;
                                `;
                    case ImageItemType.SMALL_TYPE_2:
                        return `
                                width: 110px;
                                height: 110px;
                                padding-top: 26px;
                                font-size: 18px;
                                line-height: 28px;
                                `;
                    case ImageItemType.MEDIUM:
                        return `
                                width: 140px;
                                height: 140px;
                                padding-top: 34px;
                                font-size: 26px;
                                `;
                    case ImageItemType.LARGE:
                        return `
                                width: 170px;
                                height: 170px;
                                padding-top: 50px;
                                font-size: 26px;
                                `;
                    default:
                        return ``;
                }
            }
        };
        text-align: center;
        color: ${props => props.theme.textBlack};
        font-weight: bold;
        background: ${props => props.theme.grayLighter};
        margin: 0; 
        border-radius: 5px;
        box-sizing: border-box;
        &:hover {
            cursor: pointer;
        }
    }
    p > span {
        ${
            props => {
                switch (props.type) {
                    case ImageItemType.SMALL:
                        return `
                                font-size: 25px;
                                `;
                    case ImageItemType.SMALL_TYPE_2:
                        return `
                                font-size: 25px;
                                `;
                    case ImageItemType.MEDIUM:
                        return `
                                font-size: 36px;
                                `;
                    case ImageItemType.LARGE:
                        return `
                                font-size: 36px;
                                `;
                    default:
                        return ``;
                }
            }
        };
        display: inline-block;
        font-weight: bold;
        color: ${props => props.theme.textBlack};
        position: relative;
        ${
            props => (props.type === ImageItemType.SMALL ||
                      props.type === ImageItemType.SMALL_TYPE_2) &&
                `
                span {
                    top: 2px;
                }
                `
        };        
    }
    i {
        width: 25px; height: 25px;
        position: absolute;
        top: 130px; right: -12px;
        border-radius: 5px;
        background: ${props => props.theme.darkBlue};
        box-sizing: border-box;
        span {
            position: relative;
            top: -5px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 8px; left: -4px;
            width: 0; height: 0;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 4px solid ${props => props.theme.darkBlue};
        }
    }
    img {
        ${
            props => {
                switch (props.type) {
                    case ImageItemType.SMALL:
                        return `width: 140px; height: 115px;`;
                    case ImageItemType.SMALL_TYPE_2:
                        return `width: 110px; height: 110px;`;
                    case ImageItemType.MEDIUM:
                        return `width: 140px; height: 140px;`;
                    case ImageItemType.LARGE:
                        return `width: 170px; height: 170px;`;
                    default:
                        return ``;
                }
            }
        };
        border-radius: 5px;
    }

    @media ${props => props.theme.mediaHD} {
        ${
            props => {
                switch (props.type) {
                    case ImageItemType.SMALL:
                        return `width: 140px; height: 115px;`;
                    case ImageItemType.SMALL_TYPE_2:
                        return `width: 110px; height: 110px;`;
                    case ImageItemType.MEDIUM:
                        return `width: 110px; height: 110px;`;
                    case ImageItemType.LARGE:
                        return `width: 130px; height: 130px;`;
                    default:
                        return ``;
                }
            }
        };
    }
`;

export const ImageArrow = styled(ArrowRight)`
    margin: 0 -2px 0 -2px;
    position: relative;
    top: -3px;
`;