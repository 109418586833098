import styled, { css } from 'styled-components';
import { InputTextWrapperStyles } from '../LoginForm/Styled';

export const Wrapper = styled.div``;

export const InputWrapperStyles = css`
    ${InputTextWrapperStyles};
    width: 85%;
    display: block;
    float: left;
    margin-left: 30px;
    line-height: 22px;
    margin-bottom: 20px;
    margin-top: 30px;
`;

export const SelectingWrapper = styled.div`
    width: 85%;
    margin: 10px auto 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;



export const SelectableButton = styled.span`
    height: 30px;
    padding: 4px 10px 5px 10px;
    border: 1px solid ${(props) => props.grayBorderLight};
    border-radius: 5px;
    font-size: 14px;
    background: ${(props) => (props.selected ? props.theme.cyan : '#fff')};
    color: ${(props) => (props.selected ? '#fff' : props.theme.mainFontColor)};
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const MapCard = styled.div`
    width: 400px;
    height: 220px;
    padding: 10px;
    margin: auto 20px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.22);
    float: left;

    img {
        background: #eee;
        width: 100px;
        height: 100px;
        float: left;
        margin-right: 20px;
        border: ${(props) =>
            props.vip ? `3px solid ${props.theme.darkBlue}` : 'none'};
        border-radius: ${(props) => (props.vip ? '5px 5px 5px 0' : '5px')};
    }

    > span {
        display: inline-block;
        border-radius: 3px;
        padding: 0 5px;
        background: ${(props) => props.theme.darkBlue};
        color: #fff;
        font-size: 13px;
        line-height: 22px;
        font-weight: bold;
        margin: 0;
    }

    h3 {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        color: ${(props) => props.theme.darkBlue};
        margin: 0;
        height: 50px;
        width: 250px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    p {
        color: ${(props) => props.theme.mainFontColor};
        font-size: 14px;
        margin: 0 0 5px 0;
    }

    .footer {
        width: 100%;
        height: 35px;
        background: ${(props) => props.theme.grayBgBig};
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 49%;

            span {
                margin-right: 5px;
            }

            h6 {
                font-size: 14px;
                color: ${(props) => props.theme.mainFontColor};
            }

            p {
                font-size: 14px;
                font-weight: bold;
                line-height: 17px;
                margin-top: 5px;
                margin-left: 3px;
                color: ${(props) => props.theme.textBlack};
                align-self: center;
            }

            em {
                display: inline-block;
                height: 20px;
                border-right: solid 1px ${(props) => props.theme.grayLineLight};
                margin: 0;
                position: absolute;
                right: 50%;
            }

            :last-of-type {
                em {
                    border: none;
                }
            }
        }
    }
`;
