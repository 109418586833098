import React, { Component } from 'react';
import {
    HeaderWrapper,
    LogoLink,
    ButtonArrowDown,
    ButtonInputCategories,
    InputSearchStyles,
    InputSearchOpenStyles,
    InputSearchWrapper,
    ChangeCategory,
    SearchDrop,
    BlackLayer,
    ButtonsWrapper,
    Button,
} from './Styled';
import { CloseButton } from './Styled';
import { SearchItem } from '../FormInputAddressSearch/Styled';
import IconNew from '../IconNew';
import InputSearch from '../FormInputText';
import Config from '../../../config';
import { ClearFloats } from '../BaseTemplate/Styled';
import { withRouter } from 'react-router-dom';
import searchStore from '../../../stores/searchStore';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            list: [],
            selectedGeo: null,
            searchCategoryPopOpen: false,
            searchCategoryValue: 'All',
            searchOpen: false,
            recentSearches: [],
            savedSearches: [],
        };
        this.types = ['All', 'Listings', 'People', 'Group'];
    }

    // componentDidMount() {
    //     sendAsyncRequest('/searchdatas', 'get', {}).then((res) => {
    //         this.setState({
    //             recentSearches: res.body.results.recent_searches,
    //             savedSearches: res.body.results.saved_searches,
    //         });
    //     });
    // }

    handleSearchInput = (e) => {
        let value = e.target.value;
        this.setState({
            inputValue: value,
        });
        if (value && value.length > 3) {
            value = encodeURIComponent(value);
            let mapUrl = Config.GOOGLE_MAP_AUTOCOMPLETE_URL + '&input=' + value;
            if (this.props.country) {
                mapUrl += '&components=country:' + this.props.country;
            }
            fetch(mapUrl)
                .then((response) => response.json())
                .then((data) => {
                    const list = [];
                    if (data && data.status === 'OK') {
                        data.predictions.forEach((place) => list.push(place));
                    } else {
                        list.push({
                            id: 0,
                            text: 'No Result',
                        });
                    }
                    this.setState({ list });
                });
        } else {
            this.setState({
                list: [
                    {
                        current: true,
                        id: 1,
                        text: 'Current Location',
                    },
                ],
            });
        }
    };

    searchClickHandler = async (item) => {
        let geo;
        const data = await fetch(
            Config.GOOGLE_MAP_PLACE_DETAIL_URL + '&place_id=' + item.place_id
        ).then((response) => response.json());
        if (data.status === 'OK') {
            geo = data.result.geometry.location;
            searchStore.setSearchLat(geo.lat);
            searchStore.setSearchLng(geo.lng);
            searchStore.setSearchValue(item.structured_formatting.main_text);
        }
        this.setState({ searchOpen: false });
        if (searchStore.getSearchValue() !== '') {
            this.props.history.push(
                `/search/${this.state.searchCategoryValue.toLowerCase()}/${searchStore.getSearchValue()}`
            );
        } else {
            this.props.history.push(
                `/search/${this.state.searchCategoryValue.toLowerCase}`
            );
        }
    };

    render() {
        return (
            <HeaderWrapper innerHeader={this.props.innerHeader}>
                <LogoLink
                    to={Config.ROUTES.homePage}
                >
                    <IconNew bp1="0" bp2="0" iwidth="324" iheight="76" />
                </LogoLink>
                {this.props.showSearchBar && (
                    <ButtonsWrapper>
                        <BlackLayer show={this.state.searchOpen} />
                        <InputSearchWrapper open={this.state.searchOpen}>
                            <IconNew
                                className="icon"
                                bp1="-390"
                                bp2="-55"
                                iwidth="20"
                                iheight="20"
                            />
                            <InputSearch
                                id="asdasdasdasda"
                                name="searcasdasddasdashInput"
                                type="email"
                                autoComplete="off"
                                list="autocompleteOff"
                                styled={
                                    this.state.searchOpen
                                        ? InputSearchOpenStyles
                                        : InputSearchStyles
                                }
                                placeholder="Enter neighborhood, city, or zip code ..."
                                onInput={this.handleSearchInput}
                                onEnter={this.searchHandler}
                                onFocus={(e) =>
                                    this.setState({ searchOpen: true })
                                }
                                onBlur={(e) =>
                                    this.setState({ searchOpen: false })
                                }
                                value={searchStore.getSearchValue()}
                            />
                            <ButtonInputCategories
                                onClick={() =>
                                    this.setState({
                                        searchCategoryPopOpen: true,
                                    })
                                }
                                onBlur={() =>
                                    this.setState({
                                        searchCategoryPopOpen: false,
                                    })
                                }
                            >
                                {this.state.searchCategoryValue}
                                <ButtonArrowDown />
                            </ButtonInputCategories>
                            <ChangeCategory
                                show={this.state.searchCategoryPopOpen}
                            >
                                {this.types.map((type, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            this.setState({
                                                searchCategoryValue: type,
                                            });
                                            this.setState({
                                                searchCategoryPopOpen: false,
                                            });
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </ChangeCategory>
                            <SearchDrop
                                open={this.state.searchOpen}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {this.state.list.length > 0 ? (
                                    <>
                                        {this.state.list.map((item, index) => {
                                            return (
                                                <SearchItem
                                                    key={
                                                        item.place_id +
                                                        '_' +
                                                        index
                                                    }
                                                    onClick={() => {
                                                        this.searchClickHandler(
                                                            item
                                                        ).then();
                                                    }}
                                                    isFocused={
                                                        this.state.listIndex ===
                                                        index
                                                    }
                                                    onMouseDown={
                                                        this
                                                            .itemMouseDownHandler
                                                    }
                                                >
                                                    {item.structured_formatting && (
                                                        <>
                                                            <strong>
                                                                {item
                                                                    .structured_formatting
                                                                    .main_text ||
                                                                    ''}
                                                            </strong>
                                                            <small>
                                                                {item
                                                                    .structured_formatting
                                                                    .secondary_text ||
                                                                    ''}
                                                            </small>
                                                        </>
                                                    )}
                                                </SearchItem>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <h3>Recent Searches</h3>
                                        {this.state.recentSearches.map(
                                            (item, index) => (
                                                <div key={index}>
                                                    <p>{item.title}</p>
                                                    {item.tags.map((tag, i) => (
                                                        <span
                                                            key={`${index}${i}`}
                                                        >
                                                            {tag}
                                                        </span>
                                                    ))}
                                                    <ClearFloats />
                                                    <CloseButton />
                                                </div>
                                            )
                                        )}
                                        <h3>Saved Searches</h3>
                                        {this.state.savedSearches.map(
                                            (item, index) => (
                                                <div key={index}>
                                                    <p>{item.title}</p>
                                                    {item.tags.map((tag, i) => (
                                                        <span
                                                            key={`${index}${i}`}
                                                        >
                                                            {tag}
                                                        </span>
                                                    ))}
                                                    <ClearFloats />
                                                    <CloseButton />
                                                </div>
                                            )
                                        )}
                                    </>
                                )}
                            </SearchDrop>
                        </InputSearchWrapper>
                        <Button
                            selected={this.props.homeType === 'feed'}
                            onClick={() => this.props.changeHomeType('feed')}
                        >
                            <div>
                                {this.props.homeType === 'feed' ? (
                                    <IconNew
                                        bp1="-405"
                                        bp2="-541"
                                        iwidth="31"
                                        iheight="28"
                                    />
                                ) : (
                                    <IconNew
                                        bp1="-437"
                                        bp2="-541"
                                        iwidth="31"
                                        iheight="28"
                                    />
                                )}
                            </div>
                            <p>Feed</p>
                        </Button>
                        <Button
                            selected={this.props.homeType === 'discover'}
                            onClick={() =>
                                this.props.changeHomeType('discover')
                            }
                        >
                            <div>
                                {this.props.homeType === 'discover' ? (
                                    <IconNew
                                        bp1="-405"
                                        bp2="-512"
                                        iwidth="28"
                                        iheight="28"
                                    />
                                ) : (
                                    <IconNew
                                        bp1="-434"
                                        bp2="-512"
                                        iwidth="31"
                                        iheight="28"
                                    />
                                )}
                            </div>
                            <p>Discover</p>
                        </Button>
                    </ButtonsWrapper>
                )}
            </HeaderWrapper>
        );
    }
}

export default withRouter(Header);
