import React, {Fragment} from "react";
import {WizardContent, WizardPage, WizardWrapper} from "../WizardPages/Styled";
import {
    LoadingContent,
    NavigationLoading,
    SkeletonHead,
    MenuGroupWrapper,
    MenuGroupContent,
    PropertyLoading,
    PropertyNameBox,
    PropertyPageHeight,
    BookmarkLoading,
    PropertyAddrBox,
    AlignLeft,
    AlignRight,
    PropertyTab,
    TabItem,
    ProfileImageBox,
    FloatLeft,
    SocialBox,
    NotificationHead,
    NotificationRow,
    NotificationTitle,
    GeneralRow, GalleryItem,
    EditProfileImage,
    EditProfileChangeTab,
    EditProfileInput,
} from "./Styled";
import PropTypes from "prop-types";
import {PropertiesWrapperLeft, PropertiesWrapperRight} from "../FeedPage/Styled";
import {ClearFloats} from "../BaseTemplate/Styled";

const SkeletonLoading = (props) => {
    switch (props.type) {
        case SkeletonLoadingType.WIZARD_TEMPLATE:
            return (
                <WizardPage>
                    <h1>
                        &nbsp;
                        <SkeletonHead/>
                    </h1>
                    <WizardContent>
                        <WizardWrapper>
                            <LoadingContent>
                                <div/>
                                <div/>
                                <div/>
                            </LoadingContent>
                        </WizardWrapper>
                        <NavigationLoading/>
                    </WizardContent>
                </WizardPage>
            );
        case SkeletonLoadingType.MENU_GROUPS:
            return (
                <MenuGroupWrapper>
                    <h2>&nbsp;</h2>
                    <MenuGroupContent>
                        <div/>
                        <h3>
                            <i>&nbsp;</i>
                            <em>&nbsp;</em>
                        </h3>
                        <button>&nbsp;</button>
                    </MenuGroupContent>
                    <ClearFloats/>
                    <MenuGroupContent>
                        <div/>
                        <h3>
                            <i>&nbsp;</i>
                            <em>&nbsp;</em>
                        </h3>
                        <button>&nbsp;</button>
                    </MenuGroupContent>
                </MenuGroupWrapper>
            );
        case SkeletonLoadingType.LISTING_FEEDS:
            return (
                <Fragment>
                    <PropertiesWrapperLeft>
                        <PropertyLoading/>
                        <ClearFloats/>
                    </PropertiesWrapperLeft>
                    <PropertiesWrapperRight>
                        <PropertyLoading/>
                        <ClearFloats/>
                    </PropertiesWrapperRight>
                </Fragment>
            );
        case SkeletonLoadingType.LISTING_PAGE:
            return (
                <PropertyPageHeight>
                    <AlignLeft>
                        <PropertyNameBox/>
                        <PropertyAddrBox/>
                        <PropertyAddrBox sub/>
                        <BookmarkLoading/>
                    </AlignLeft>
                    <AlignRight>
                        <PropertyNameBox right/>
                        <PropertyAddrBox right/>
                    </AlignRight>
                    <ClearFloats/>
                    <PropertyTab>
                        <TabItem/>
                        <TabItem/>
                        <TabItem/>
                        <TabItem/>
                        <TabItem/>
                    </PropertyTab>
                </PropertyPageHeight>
            );
        case SkeletonLoadingType.PROFILE_PAGE:
            return (
                <PropertyPageHeight>
                    <FloatLeft>
                        <ProfileImageBox/>
                        <AlignLeft>
                            <PropertyNameBox/>
                            <PropertyAddrBox/>
                            <PropertyAddrBox sub/>
                        </AlignLeft>
                    </FloatLeft>
                    <AlignRight>
                        <SocialBox/>
                        <SocialBox/>
                        <SocialBox/>
                        <SocialBox/>
                    </AlignRight>
                    <ClearFloats/>
                </PropertyPageHeight>
            );
        case SkeletonLoadingType.NOTIFICATION_MENU:
            return (
                <Fragment>
                    <NotificationTitle/>
                    <ClearFloats/>
                    <NotificationHead/>
                    <NotificationRow/>
                    <NotificationRow/>
                </Fragment>
            );
        case SkeletonLoadingType.GENERAL_ROWS:
            return (
                <Fragment>
                    <GeneralRow/>
                    <GeneralRow/>
                    <GeneralRow/>
                </Fragment>
            );
        case SkeletonLoadingType.GALLERY_IMAGE:
            return (
                <GalleryItem>
                    <div/><span/><span/>
                    <ClearFloats/>
                </GalleryItem>
            );
        case SkeletonLoadingType.EDIT_PROFILE:
            return(
                <Fragment>
                    <EditProfileImage />
                    <EditProfileChangeTab />
                    <EditProfileInput />
                    <EditProfileInput />
                </Fragment>
            )
        default:
            return null;
    }
};

export default SkeletonLoading;

export const SkeletonLoadingType = {
    WIZARD_TEMPLATE: 'wizard_template',
    LISTING_FEEDS: 'listing_feeds',
    MENU_GROUPS: 'menu_groups',
    LISTING_PAGE: 'listing_page',
    PROFILE_PAGE: 'profile_page',
    NOTIFICATION_MENU: 'notification_menu',
    GENERAL_ROWS: 'general_rows',
    GALLERY_IMAGE: 'gallery_image',
    EDIT_PROFILE: 'edit_profile',
};

SkeletonLoading.propTypes = {
    type: PropTypes.string.isRequired
};