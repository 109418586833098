import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg width={540} height={125.001} viewBox='0 0 540 125.001' {...props}>
            <g data-name='Path 1355' fill='#fff'>
                <path d='M383.18 124.502c-.083 0-.166 0-.25-.002-5.545-.072-40.45-.108-103.746-.108-107.768 0-262.633.107-264.184.108a14.405 14.405 0 01-10.253-4.247A14.405 14.405 0 01.5 110V15c0-3.873 1.508-7.514 4.247-10.253A14.405 14.405 0 0115 .5h510c3.873 0 7.514 1.508 10.253 4.247A14.405 14.405 0 01539.5 15v69c0 3.873-1.508 7.514-4.247 10.253A14.405 14.405 0 01525 98.5H411.115c-8.861 0-11.415 6.613-13.884 13.009-1.244 3.22-2.53 6.55-4.612 8.987-1.104 1.292-2.34 2.24-3.78 2.899-1.629.745-3.48 1.107-5.658 1.107z' />
                <path
                    d='M15 1c-3.74 0-7.255 1.456-9.9 4.1A13.908 13.908 0 001 15v95c0 3.74 1.456 7.255 4.1 9.9A13.908 13.908 0 0015 124c1.551-.001 156.416-.108 264.184-.108 63.3 0 98.207.036 103.752.108l.245.002c3.947 0 6.825-1.217 9.058-3.83 2.027-2.373 3.297-5.662 4.525-8.843 2.53-6.553 5.147-13.329 14.351-13.329H525c3.74 0 7.255-1.456 9.9-4.1A13.908 13.908 0 00539 84V15c0-3.74-1.456-7.255-4.1-9.9A13.908 13.908 0 00525 1H15m0-1h510c8.284 0 15 6.716 15 15v69c0 8.284-6.716 15-15 15H411.115c-17.313 0-9.477 26.002-27.934 26.002-.086 0-.171 0-.258-.002-6.263-.081-49.237-.108-103.739-.108C170.169 124.892 15 125 15 125c-8.284 0-15-6.716-15-15V15C0 6.716 6.716 0 15 0z'
                    fill='#bcbcbc'
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
