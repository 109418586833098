import styled, {css} from "styled-components";
import {
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    InputTextWrapperStyles
} from "../LoginForm/Styled";

export const SelectFileWrapper = styled.div`
    display: flex;
    & > div:last-of-type {
        margin-right: 0;
    }
    ${
        props => (props.width) && ` width: ${props.width}px; `
    };
`;

export const InputLabelAnimateGroupStyles = css`
    ${InputLabelAnimateStyles};
    font-size: 20px;
    color: ${props => props.theme.mainFontColor};
`;

export const InputLabelAfterAnimateGroupStyles = css`
    ${InputLabelAfterAnimateStyles};
    font-size: 16px;
`;

export const InputTextGroupStyles = css`
    ${InputTextStyles};
    font-size: 20px;
    height: 40px;
`;

export const SelectStatus = styled.span`
    display: inline-block;
    min-width: 210px;
    height: 35px;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin-left: 30px;
    top: 60px;
    color: ${
        props => (props.uploaded)
            ? props.theme.noticeGreen
            : props.theme.textBlack
    };
    i {
        display: inline-block;
        width: 23px; height: 23px;
        background: ${props => props.theme.noticeRed};
        border-radius: 100%;
        margin-left: 15px;
        position: relative;
        top: 4px;
        cursor: pointer;
    }
    i:before, i:after {
        content: '';
        position: absolute;
        top: 5px; left: 10px;
        width: 2px; height: 13px;        
        background-color: white;
    }
    i:before {
        transform: rotate(45deg);
    }
    i:after {
        transform: rotate(-45deg);
    }
`;

export const SelectLabel = styled.label`
    display: inline-block;
    min-width: 210px;
    height: 55px;
    color: #fff;
    font-size: 22px;
    background: ${
        props => (!props["data-loading"])
            ? (!props["data-uploaded"])
                ? props.theme.darkBlue
                : props.theme.statusGreen
            : props.theme.grayBgButton
    };
    border: none;
    border-radius: 5px;
    padding: 10px 30px 0 60px;
    position: relative;
    top: 30px;
    margin-left: 30px;
    box-sizing: border-box;
    cursor: pointer;
    &:after, &:before {
        display: ${props => (props["data-uploaded"] || props["data-loading"])
            ? 'none' : 'block'
        };
        content: "";
        position: absolute;
        background: #fff;
        top: 50%;
        transform: translateY(-50%);
    }
    &:after {
        width: 19px; height: 3px;
        left: 25px;
    }
    &:before {
        width: 3px; height: 19px;
        left: 33px;
    }
    ${
        props => (props["data-loading"]) &&
            ` pointer-events: none; `
    };
    img {
        display: ${props => (!props["data-loading"]) ? 'none' : 'block'};
        width: 30px;
        position: absolute;
        top: 50%; left: 20px;
        transform: translateY(-50%);
    }
    i {
        display: ${props => (!props["data-uploaded"]) ? 'none' : 'block'};
        position: absolute;
        top: 47%; left: 28px;
        transform: translateY(-50%) rotate(45deg);
        width: 9px; height: 18px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
    }
`;

export const CategoryDesc = styled.p`
    font-size: 22px;
    margin-bottom: 0;
`;

export const CreateCatButton = styled.span`
    display: inline-block;
    min-width: 100px;
    height: 55px;
    color: #fff;
    font-size: 22px;
    background: ${props => props.theme.darkBlue};
    border: none;
    border-radius: 5px;
    padding: 10px 30px 0;
    position: relative;
    top: 30px;
    margin-left: 30px;
    box-sizing: border-box;
    cursor: pointer;
`;

export const InputTextWrapperDocStyles = css`
    ${InputTextWrapperStyles};
    width: 100%;
    margin: 40px 0 30px;
    flex: 1;
`;

export const InputTextWrapperWithItemStyles = css`
    ${InputTextWrapperStyles};
    width: 100%;
    margin: 40px 0 30px 20px;
    flex: 1;
`;

export const InputFile = styled.input`
    display: none;
`;