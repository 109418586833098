import React, { Component } from 'react';
import {
    InvatationStepsWrapper,
    InvatationStepsHeader,
    InvatationStepsHeaderWrapper,
    InvatationStepsLineWrapper,
    ProgressBar,
    StepIndicator,
    StepIndicatorLeftSide,
    StepIndicatorRightSide,
    FilteredBar,
    ActiveProgressBarCircle,
    SigninProgressBarHeader,
    ProgressBarWrapper,
    InvatationStepsDetailsWrapper,
    FirstDetailsWrapper,
    ObjectiveWrapper,
    Objective,
    Achievments,
    InvatationStepLayout,
    InvatationStepDetails,
    InvatationStepDetailWrapper,
    InvatationLayoutWrap,
    LayoutHeaderText,
} from './Styled';
import IconNew from '../IconNew/';

export class InvatationSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 2,
        };
        this.steps = [
            {
                name: 'Sign Up',
                length: 3,
                objectives: [
                    {
                        title: 'Invite',
                        done: true,
                        achievements: [
                            {
                                name: '2 Basic Invitations',
                                icon: 's',
                                function: true,
                            },
                            {
                                name: '0 Privileged Invitations',
                                icon: 's',
                                function: false,
                            },
                        ],
                    },
                ],
            },
            {
                length: 5,
                name: 'Blue Checkmark',
                objectives: [
                    {
                        title: 'Validate your ID',
                        done: true,
                        achievements: [
                            {
                                name: 'Blue Checkmark',
                                icon: 's',
                                function: false,
                            },
                            {
                                name: '+2 Basic Invitations',
                                icon: 's',
                                function: true,
                            },
                        ],
                    },
                    {
                        title: 'Invite Connections',
                        done: true,
                        achievements: [
                            {
                                name: 'Points per Invitations',
                                icon: 's',
                                function: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Pro Status',
                length: 5,
                objectives: [
                    {
                        title: 'Validate your Professional License',
                        done: true,
                        achievements: [
                            {
                                name: 'Pro status signage',
                                icon: 's',
                                function: false,
                            },
                            {
                                name: 'Privileged account upgrade',
                                icon: 's',
                                function: true,
                            },
                        ],
                    },
                    {
                        title: 'Invite Connections',
                        done: false,
                        achievements: [
                            {
                                name: 'Points per Invitations',
                                icon: 's',
                                function: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Blue Checkmark',
                length: 2,
                objectives: [
                    {
                        title: 'Here is your objective',
                        done: false,
                        achievements: [
                            {
                                name: 'you will get something',
                                icon: 's',
                                function: false,
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Blue Checkmark',
                length: 5,
                objectives: [
                    {
                        title: 'Validate your Professional License',
                        done: false,
                        achievements: [
                            {
                                name: 'Pro status signage',
                                icon: 's',
                                function: false,
                            },
                            {
                                name: 'Privileged account upgrade',
                                icon: 's',
                                function: true,
                            },
                        ],
                    },
                    {
                        title: 'Invite Connections',
                        done: false,
                        achievements: [
                            {
                                name: 'Points per Invitations',
                                icon: 's',
                                function: false,
                            },
                        ],
                    },
                ],
            },
        ];
    }

    render() {
        return (
            <React.Fragment>
                <InvatationStepsWrapper>
                    <InvatationStepsHeaderWrapper>
                        <InvatationStepsHeader>
                            Build Network
                        </InvatationStepsHeader>
                    </InvatationStepsHeaderWrapper>
                    <InvatationStepsLineWrapper>
                        {this.steps.map((step, index) => {
                            return (
                                <ProgressBarWrapper>
                                    <SigninProgressBarHeader>
                                        Level {index}
                                    </SigninProgressBarHeader>
                                    <ProgressBar
                                        last={this.state.step <= index - 1}
                                    >
                                        {this.state.step === index ? (
                                            <StepIndicator>
                                                <StepIndicator>
                                                    <StepIndicatorLeftSide>
                                                        <IconNew
                                                            bp1="-506"
                                                            bp2="-426"
                                                            iwidth="22"
                                                            iheight="26"
                                                        />
                                                    </StepIndicatorLeftSide>
                                                    <StepIndicatorRightSide>
                                                        <p>you are</p>
                                                        <h5>HERE</h5>
                                                    </StepIndicatorRightSide>
                                                </StepIndicator>
                                            </StepIndicator>
                                        ) : null}
                                        <ActiveProgressBarCircle
                                            compeleted={this.state.step > index}
                                        />
                                    </ProgressBar>
                                </ProgressBarWrapper>
                            );
                        })}
                        <FilteredBar current={this.state.step}>
                            <i />
                        </FilteredBar>
                    </InvatationStepsLineWrapper>
                    <InvatationStepsDetailsWrapper>
                        {this.steps.map((step, index) => (
                            <FirstDetailsWrapper
                                height={
                                    index <= this.state.step ? step.length : 1
                                }
                                index={index}
                                current={this.state.step}
                                compeleted={this.state.step > index}
                                dashed={this.state.step < index}
                                key={step.name + index} 
                            >
                                <div className="fillBorder" />
                                <InvatationStepDetails>
                                    <InvatationLayoutWrap>
                                        <InvatationStepLayout
                                            compeleted= {this.state.step > index}
                                        >
                                            {this.state.step > index
                                                ? 'icon'
                                                : this.state.step === index
                                                    ? `LV${index}`
                                                    : 'Lock'}
                                        </InvatationStepLayout>
                                        {this.state.step >= index && (
                                            <LayoutHeaderText>
                                                {step.name}
                                            </LayoutHeaderText>
                                        )}
                                    </InvatationLayoutWrap>
                                    <InvatationStepDetailWrapper>
                                        {index <= this.state.step &&
                                            step.objectives.map((objective) => (
                                                <ObjectiveWrapper
                                                    height={
                                                        index <= this.state.step
                                                            ? step.length
                                                            : 1
                                                    }
                                                    key={
                                                        objective.title + index
                                                    }
                                                >
                                                    <Objective
                                                        done={objective.done}
                                                        index={index}
                                                    >
                                                        <span className="circle" />
                                                        <span className="connector" />
                                                        <h3>
                                                            {objective.title}
                                                        </h3>
                                                    </Objective>
                                                    <Achievments done={objective.done}>
                                                        {objective.achievements.map(
                                                            (achievement) => (
                                                                <div
                                                                    key={
                                                                        achievement.name +
                                                                        index
                                                                    }
                                                                >
                                                                    {objective.done ? (
                                                                        <span>
                                                                            <i />
                                                                            &#x2605;
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            ☆
                                                                        </span>
                                                                    )}
                                                                    <p>
                                                                        {
                                                                            achievement.name
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )
                                                        )}
                                                    </Achievments>
                                                </ObjectiveWrapper>
                                            ))}
                                    </InvatationStepDetailWrapper>
                                </InvatationStepDetails>
                            </FirstDetailsWrapper>
                        ))}
                    </InvatationStepsDetailsWrapper>
                </InvatationStepsWrapper>
            </React.Fragment>
        );
    }
}

export default InvatationSteps;
