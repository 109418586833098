import React from 'react';
import { Main } from '../../styles/Styled';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import ListingDetailPage from '../Modules/ListingDetailPage';
import ProfileMenu from '../Modules/ProfileMenu';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';

class ListingDetail extends BaseTemplateNew {
    constructor(props) {
        super(props);
        this.state = {
            isAllCommentsModalOpen: false,
        };
        super.showFooter = false;
    }

    toggleAllCommentsModal = (id) => {
        this.setState({
            isAllCommentsModalOpen: !this.state.isAllCommentsModalOpen,
        });
    };

    renderBody() {
        return (
            <Main backgrounded>
                <ProfileMenu closed />
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    <ListingDetailPage
                        listingAddress={this.props.match.params.address}
                        listingId={this.props.match.params.id}
                        modalType={this.props.match.params.modaltype}
                    />
                )}
            </Main>
        );
    }
}

export default observer(ListingDetail);
