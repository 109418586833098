import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import { Main } from '../../styles/Styled';
import LeadManagementSearchAll from '../Modules/LeadManagementSearchAll';
import LeadManagmentTagTab from '../Modules/LeadManagmentTagTab';
import LeadManagmentNameTag from '../Modules/LeadManagmentNameTag';
import LeadManagmentProfileDetails from '../Modules/LeadManagmentProfileDetails';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';
import ProfileMenu from '../Modules/ProfileMenu';

class LeadManagement extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;

        this.state = {
            showMap: false,
        };
        this.CardDetails = {
            id: 23,
            details: {
                name: 'Ruth Wallace',
                photo: 'https://i.stack.imgur.com/34AD2.jpg',
                conversation: '3',
                meeting: {
                    date: '2July',
                    hour: '12:00 PM',
                },
                followUp: '24/06/2020',
                note: [
                    {
                        text:
                            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do ',
                        date: '24/06/2020',
                    },
                    {
                        text:
                            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut abore ... ',
                        date: '24/06/2020',
                    },
                ],
                tags: [
                    { tag: '#Apartment' },
                    { tag: '#Apartment' },
                    { tag: '#Apartment' },
                ],
            },
        };
        this.CardProfile = [
            {
                id: 1,
                details: {
                    photo: 'https://i.stack.imgur.com/34AD2.jpg',
                    name: 'Sara Coner Williams',
                    hashtags: ['#Apartment', '#for_sale', '#Luxury'],
                    timeframe: '6-12 Months',
                    dollar: '499K',
                    rating: 'HOT',
                    age: '25 days',
                    conversation: '3',
                    meeting: {
                        date: '2July',
                        hour: '12:00 PM',
                    },
                    followUp: '24/06/2020',
                    note: [
                        {
                            text:
                                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do ',
                            date: '24/06/2020',
                        },
                        {
                            text:
                                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut abore ... ',
                            date: '24/06/2020',
                        },
                    ],
                },
            },
            {
                id: 2,
                details: {
                    photo: 'https://i.stack.imgur.com/34AD2.jpg',
                    name: 'Kyle Evans',
                    hashtags: ['#coolhouse', '#forsale', '#trending'],
                    timeframe: '1-2 Months',
                    dollar: '100K',
                    rating: 'MEH',
                    age: '10 days',
                    conversation: '3',
                    meeting: {
                        date: '2July',
                        hour: '12:00 PM',
                    },
                    followUp: '24/06/2020',
                    note: [
                        {
                            text:
                                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do ',
                            date: '24/06/2020',
                        },
                        {
                            text:
                                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut abore ... ',
                            date: '24/06/2020',
                        },
                    ],
                },
            },
        ];
    }

    toggleShow = () => {
        this.setState((prevState) => {
            return { showMap: !prevState.showMap };
        });
    };

    renderBody() {
        const { category, person } = this.props.match.params;
        return (
            <Main>
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    (() => {
                        if (category) {
                            switch (category) {
                                case 'all':
                                    return (
                                        <LeadManagementSearchAll
                                            CardProfile={this.CardProfile}
                                        />
                                    );
                                case 'name':
                                    return (
                                        <LeadManagmentNameTag
                                            showMap={this.state.showMap}
                                            toggleShow={this.toggleShow}
                                        />
                                    );
                                case 'tag':
                                    return (
                                        <LeadManagmentTagTab
                                            showMap={this.state.showMap}
                                            toggleShow={this.toggleShow}
                                        />
                                    );
                                default:
                                    return (
                                        <LeadManagementSearchAll
                                            CardProfile={this.CardProfile}
                                        />
                                    );
                            }
                        } else if (person) {
                            return (
                                <LeadManagmentProfileDetails
                                    CardDetails={this.CardDetails}
                                />
                            );
                        } else {
                            return <LeadManagementSearchAll
                                    CardProfile={this.CardProfile}
                                   
                                    />;
                        }
                    })()
                )}
                {person && <ProfileMenu />}
            </Main>
        );
    }
};

export default observer(LeadManagement);
