import styled, {css} from "styled-components";
import {ArrowDown} from "../BaseTemplate/Styled";

export const Wrapper = styled.div`
    border: solid 1px ${props => props.theme.textGrayLight};
    border-radius: 10px;
    box-sizing: border-box;
    padding: 17px 17px 15px;
    position: relative;
    margin-bottom: 20px;
    h4 {
        font-size: 22px;
        color: ${props => props.theme.textBlack};
        margin: 0;
        cursor: pointer;
    }
    p {
        font-size: 22px;
        margin: 15px 0 0;
        strong {
            color: ${props => props.theme.darkBlue};
            margin-right: 10px;
        }
        > span {
            display: inline-block;
            margin-top: 10px;
        }
        > span ol {
            margin: 0;
            counter-reset: item;
            list-style: none;
        }
        > span > ol {
            padding-left: 35px;
            li:before {
                counter-increment: item;
                position: absolute;
                text-align: left;
            }
            & > li:before {
                color: ${props => props.theme.darkBlue};
                content: counter(item, upper-roman) ".";
                margin-left: -35px;
                font-weight: bold;
            }
            ol {
                padding-left: 25px;
                li:before {
                    color: ${props => props.theme.textBlack};
                    content: counter(item) ".";
                    margin-left: -25px;
                }
            }
        }
    } 
`;

export const Note = styled.strong`
    ${props => (props.isBlock) && ` display: block; `};
`;

export const RadioBoxStyles = css`
    float: left;
    margin-right: 25px;
    padding-left: 45px;
    em {
        width: 30px; height: 30px;
        top: 0;
        &:after {
            top: 4px; left: 4px;
            width: 22px; height: 22px;
        }
    }
`;

export const PreviewButtonStyles = css`
    font-size: 20px;
    background: #fff;
    color: ${props => props.theme.mainFontColor};
    height: 30px;
    padding: 0 10px;
    border: solid 1px ${props => props.theme.grayLight};
    border-radius: 5px;
    span {
        margin-right: 7px;
    }
`;

export const ArrowDownGray = styled(ArrowDown)`
    position: absolute;
    top: 25px; right: 15px;
    width: 1.1rem; height: 1.1rem;
    cursor: pointer;
    &:after, &:before {
        background: ${props => props.theme.mainFontColor};
        width: .7rem;
    }
`;