import React, { Component } from 'react';
import TabPages, { TabStyle } from '../TabBarPages';
import IconNew from '../IconNew';
import { ClearFloats } from '../BaseTemplate/Styled';
import { TabPage } from '../TabBarPages/Styled';
import { TabContent } from '../../../styles/Styled';
import { 
    TabPageStyles,
    SliderWrapper,
    SliderCardWrapper,
    ImgWrapper,
    CardTopText,
    PropertyPrice,
    CardMiddleText,
    PropertyDetail,
    LeftSideWrapper,
    PropertyTitleText,
    CardLowerText,
    LocationText

            } from './Styled';




class SimilarLeadManagementListings extends Component {
    render() {
        return (
            <TabContent>
                <TabPages theme={TabStyle.ListingsTab} defaultPageIndex={0}>
                    <TabPage title="Feed" styled={TabPageStyles}>
                        <SliderWrapper>
                            {this.props.PotentialData.map(item => (
                                <SliderCardWrapper key={item.id}>
                                    <ImgWrapper src={item.photo}/>
                                    <CardTopText>
                                        <LeftSideWrapper>
                                            <PropertyTitleText>Property title will show here</PropertyTitleText>
                                            <IconNew
                                                bp1="-80"
                                                bp2="-118"
                                                iwidth="20"
                                                iheight="17"
                                                className="forward"
                                            />
                                          </LeftSideWrapper>
                                                <IconNew
                                                    bp1="0"
                                                    bp2="-214"
                                                    iwidth="25"
                                                    iheight="14"
                                                    className="eye"
                                                />
                                     </CardTopText>
                                     <CardMiddleText>
                                         <PropertyPrice>${item.price}</PropertyPrice>
                                         <div>
                                             <PropertyDetail>
                                                {item.bed} BED
                                             </PropertyDetail>
                                             <PropertyDetail className='bordered'>
                                                {item.bath} BATH
                                             </PropertyDetail>
                                             <PropertyDetail>
                                                {item.sqft} sqft
                                             </PropertyDetail>
                                         </div>
                                     </CardMiddleText>
                                     <CardLowerText>
                                         <div style={{marginLeft:'11.2px'}}>
                                        <IconNew
                                            bp1="-62"
                                            bp2="-77"
                                            iwidth="12"
                                            iheight="17"
                                            className="heart"
                                        />
                                        </div>
                                         <LocationText>
                                            {item.location}
                                         </LocationText>
                                     </CardLowerText>
                                 </SliderCardWrapper>
                            ))}
                        </SliderWrapper>
                        <ClearFloats />
                    </TabPage>
                    <TabPage title="Active Listings">
                        <p>
                            Active Listings Page
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </TabPage>
                    <TabPage title="Closed">
                        <p>
                            Closed Page
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </TabPage>
                </TabPages>
            </TabContent>
        );
    }
}

export default SimilarLeadManagementListings;

