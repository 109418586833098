import styled, {css} from "styled-components";

export const TagsWrapper = styled.div`
    div {
        float: left;
        margin-right: 100px;
    }
    div:last-of-type {
        margin-right: 0;
    }
    span:last-of-type {
        margin-left: 6px;
    }
`;

export const TagSchedule = styled.p`
    background: ${props => (props.show) ? props.theme.grayLighter : 'transparent'};
    padding: 3px 10px 5px;
    margin: -3px 0 0 -10px;
    border-radius: 10px;
    font-size: 18px;
    color: ${props => props.theme.textGrayLight};
    b {
        color: ${props => props.theme.textBlack};
    }
    span.pen {
        float:right;
        margin-top: 12px;
    }
`;

export const CheckBoxTagStyles = css`
    i {
        font-weight: bold;
    }
    input:checked ~ i span {
        color: ${props => props.theme.textBlack};
    }
`;