import styled from 'styled-components';
import {ArrowRight} from "../BaseTemplate/Styled";

export const ProfileMenuVerificationIDWrapper = styled.div`
padding-left:30px;
padding-right:30px;
padding-top:30px;
height:100%;
overflow-y:scroll;
`;

export const ArrowRightWrap = styled(ArrowRight)`
 margin: 0 0 -13px;
height: 25px;

`;

export const HeaderText = styled.span`
font-size: 22px;
font-weight: bold;
color: ${(props) => props.theme.deepBlue};
`;

export const HeaderWrapper = styled.div`
display:flex;
`;

export const ProfileVerificationHeaderWrapper = styled.div`
display:flex;
justify-content:flex-end;
align-items:center;
padding-bottom:25.5px;
border-bottom:1px solid ${(props) => props.theme.textGrayLight};
`;

export const ProfileMenuVerificationHeader = styled.span`
 font-size: 22px;
font-weight: bold;
color:${(props) => props.theme.deepBlue};
`;

export const BackButton = styled.div`
cursor:pointer;
 i {
     top:-7px;
 }
`;
export const ContentWrapper = styled.div`
display:flex;
flex-direction:column;
height:100%;
`;
export const IDImage = styled.img`
width:100%;
height:100%;
border-radius: 5px;
border: solid 1px #cccccc;

`;
export const TopCard = styled.div`
padding-top:22.5px;
display:flex;
flex-direction:column;
border-bottom:1px solid ${(props) => props.theme.grayBgInput};
padding-bottom:9.5px;

`;

export const ImgWrapper = styled.div`
height:536px;
width:100%;
`;

export const ImgStyle = styled.img`
width:100%;
height:100%;
border-radius:5px;
`;

export const AddressHeaderText = styled.span`
color:${(props) => props.theme.coolGray};
font-size:12px;
color:#2e2e33;
`;

export const AddressStyle = styled.span`
font-size:18px;
color:#2e2e33;
`;

export const LowerButtonsWrapper = styled.div`
margin-top:10%;
display:flex;
align-items:center;
`;
export const SaveButton = styled.button`
width:295px;
height:50px;
background-image: linear-gradient(to right, #07aed0,  #094fd0);
border-radius:5px;
color:white;
font-size: 16px;
border:none;
`;
export const CancelButton = styled.button`
margin-left:30px;
font-size: 16px;
height:19px;
color:#2e2e33;
border:none;
background:transparent;
border-bottom:1px solid #2e2e33;
`;

export const FooterWrapper = styled.div`
bottom:10px;
left:35px;
display:flex;
flex-direction:column;
margin-top:30px;

`;

export const FooterItemTextStyle = styled.span`
font-size: 14px;
color: ${(props) => props.theme.textGrayLight};
margin-left:${props => props.first ? null : `4px `};

`;

export const FooterItemCompanyTextStyle = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.textGrayLight};
`;