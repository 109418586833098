import styled from 'styled-components';
import { Link } from 'react-router-dom';



export const ThirdSectionWrapper = styled.div`
display:flex;
height:100%;
flex-direction:${props => props.reverse ? 'row-reverse'  : 'row'};
padding-left:${props => props.reverse && '5.208333333333334%'};
justify-content:center;
background:${props => props.reverse ? "#DFDFDF" : "#CCCBCB"};
 
@media (max-width:767px) { 
        display:${props => props.image && 'none'};
        justify-self:center;

}
    @media ${props => props.theme.mediaTabletLandscape} {
        
    }
    @media ${props => props.theme.mediaTablet} {
        padding-left:${props => props.reverse && '0'};
    }
    @media ${props => props.theme.mediaMobile} {
        padding-left:${props => props.reverse && '0'};
    }



`;

export const ThirdSection = styled.div`
width:53.3854166667%;

    @media (max-width:767px) { 
        margin-top:${props => props.reverse && '15px'};
        justify-self:center;

}

    @media ${props => props.theme.mediaTabletLandscape} {
        
    }
    @media ${props => props.theme.mediaTablet} {
        display:${props => props.image && 'none'};
        width:100%;
    }
    @media ${props => props.theme.mediaMobile} {
        display:${props => props.image && 'none'};
        width:100%;
    }

`;

export const SectionThreeImg = styled.img`
width:100%;
float:right;
display: block;
`;

export const ContentWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:46.6145833333%;
    margin-top:6%;
    margin-bottom:4%;


    @media ${props => props.theme.mediaTabletLandscape} {
        
    }
    @media ${props => props.theme.mediaTablet} {
        align-items:center;
        width:100%;
    }
    @media ${props => props.theme.mediaMobile} {
        align-items:center;
        width:100%;
    }

     
`;

export const HeaderText = styled.div`
  text-align:${props => props.centered &&'center'};
  font-size:48px;
  line-height:1;
  font-weight:bold;
  width:90%;
  color:#4E4E4E;


 
    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 30px;
        
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 20px;
        text-align:center;
        
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 16px;
        text-align:center;

    }

`;

export const DescriptionText = styled.div`
    font-size:30px;
    color:#4E4E4E;
    width:87.6%;
    line-height:1.3;
    text-align:${props => props.centered ? 'center'  : 'start'};
    @media (max-width: 992px) {
        width:80%;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        width:80%;
        font-size:16px;
        
    }
    @media ${props => props.theme.mediaTablet} {
        width:45%;
        font-size:16px;
        text-align:center;
        margin-top:10px;
        margin-bottom:10px;
    }
    @media ${(props) => props.theme.mediaHD} {
        font-size: 24px;
        
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 14px;
        text-align:center;
        margin-top:10px;
        margin-bottom:10px;
        width:70%;
        
    }
`;

export const ButtonClick = styled(Link)`
background:#17297F;
width:fit-content;
display:flex;
flex-direction:row;
align-items:center;
color:white;
font-weight:bold;
border-radius:10px;
justify-content:center;
cursor:pointer;
font-size: 25px;
height:50px;
padding-left:10px;
padding-right:10px;

    @media ${props => props.theme.mediaTabletLandscape} {
        font-size:16px;
        height:40px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size:14px;
        height:30px;
    }
    @media ${(props) => props.theme.mediaHD} {
        font-size: 20px;
        height:50px;
        padding-left:10px;
        padding-right:10px;
        
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;
        height:25px;
        padding-left:10px;
        padding-right:10px;
    }

`;
