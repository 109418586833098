import styled from 'styled-components';

export const IconWrapper = styled.span`
    background-image: url(/assets/img/icons-new.png);
    background-repeat: no-repeat;
    display: inline-block;
    background-position: ${(props) => props.bp1 || '0'}px
        ${(props) => props.bp2 || '0'}px;
    width: ${(props) => props.iwidth || '0'}px;
    height: ${(props) => props.iheight || '0'}px;
    ${(props) => props.onClick && 'cursor: pointer'};
`;
