import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 33px;
    position: absolute;
    top: -50px; left: 50%;
    transform: translateX(-50%);
    em {
        background: linear-gradient(90deg,
            ${props => props.theme.darkerBlue} 0%,
            ${props => props.theme.darkBlue} 25%,
            ${props => props.theme.darkBlue} 50%,
            ${props => props.theme.darkBlue} 75%,
            ${props => props.theme.darkerBlue} 100%
        );
        position: absolute;
        height: 3px;
        top: -3px; right: 0; left: 0;
        border-radius: 5px 5px 0 0;
    }
    & > div {
        height: 33px;
        overflow: hidden;
        position: relative;
    }
    & > div > div {
        position: relative;
        height: 33px;
        margin: 0 64px 0 61px;
        line-height: 32px;
        background: linear-gradient(
            to bottom,
            ${props => props.theme.darkBlue} 0%,
            ${props => props.theme.darkerBlue} 100%
        );
        text-transform: uppercase;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 14px;
        color: #fff;
    }
    span:first-of-type {
        background: ${props => props.theme.darkBlue};        
        position: absolute;
        top: 0; right: 61px;
    }
    span:first-of-type:before {
        content: '';
        background-image: radial-gradient(
            circle at 100% 100%,
            transparent 56px,
            ${props => props.theme.darkerBlue} 57px
        );
        position: absolute;
        top: -6px;
        right: -71px;
        width: 44px;
        height: 60px;
    }
    span:first-of-type:after {
        content: '';
        background-image: radial-gradient(
        circle at 100% 100%,
            ${props => props.theme.darkBlue} 15px,
            ${props => props.theme.darkerBlue} 40px,
            transparent 41px
        );
        transform: rotate(180deg);
        position: absolute;
        top: -7px;
        right: -62px;
        width: 65px;
        height: 40px;
    }
    span:last-of-type {
        background: ${props => props.theme.darkBlue};        
        position: absolute;
        top: 0; left: 1px;
    }
    span:last-of-type:before {
        content: '';
        background-image: radial-gradient(
            circle at 100% 100%,
            transparent 56px,
            ${props => props.theme.darkerBlue} 57px
        );
        position: absolute;
        top: -6px;
        right: -30px;
        width: 44px;
        height: 60px;
        transform: scaleX(-1);
    }
    span:last-of-type:after {
        content: '';
        background-image: radial-gradient(
            circle at 100% 100%,
            ${props => props.theme.darkBlue} 15px,
            ${props => props.theme.darkerBlue} 40px,
            transparent 41px
        );
        transform: scaleX(-1) rotate(180deg);
        position: absolute;
        top: -7px;
        right: -61px;
        width: 65px;
        height: 40px;
    }
`;