import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg
            width={344.098}
            height={73.455}
            viewBox="0 0 344.098 73.455"
            {...props}
        >
            <g data-name="Group 805" fill="#19257c">
                <path
                    data-name="Subtraction 9"
                    d="M36.833 73.455A36.487 36.487 0 0123.159 70.8a36.117 36.117 0 01-12.02-7.959l-.5-.495a36.023 36.023 0 01-9.977-18.7 36.244 36.244 0 012-20.555 36 36 0 017.982-11.986l.5-.495A36.208 36.208 0 0129.893.663a36.537 36.537 0 0120.613 1.99 36.118 36.118 0 0112.02 7.959l26.19 26.115-26.19 26.116a36.208 36.208 0 01-18.754 9.949 36.7 36.7 0 01-6.939.663zm-17-38.848l4.7 7.173h12.78l7.49 12.862H57.47L49.5 41.78h3.269a10.261 10.261 0 004.111-1.528c1.873-1.214 4.1-3.643 4.076-8.325a10.027 10.027 0 00-4.11-8.579 10.786 10.786 0 00-4.076-1.781H11.283l4.7 7.17h34.295a2.748 2.748 0 01.93.649 3.1 3.1 0 01.633.978 3.977 3.977 0 01.273 1.563 3.589 3.589 0 01-.3 1.483 2.175 2.175 0 01-.63.8 1.9 1.9 0 01-.906.393z"
                />
                <path
                    data-name="Path 477"
                    d="M112.472 38.992v11.465h-3.2V22.888h7.561q5.072 0 7.5 1.942a7.042 7.042 0 012.423 5.846q0 5.468-5.544 7.392l7.486 12.389h-3.79l-6.683-11.465zm0-2.753h4.4q3.394 0 4.978-1.348a5.017 5.017 0 001.584-4.045 4.6 4.6 0 00-1.612-3.941q-1.612-1.207-5.176-1.207h-4.174zm36.036 14.218H133.14V22.888h15.368v2.847h-12.162v8.881h11.426v2.828h-11.426v10.145h12.162zm23.439 0l-3.432-8.768h-11.05l-3.393 8.768h-3.244l10.9-27.681h2.7l10.838 27.681zm-4.431-11.654l-3.206-8.542q-.622-1.622-1.282-3.979a35.729 35.729 0 01-1.188 3.979l-3.243 8.542zm11.54 11.653V22.888h3.206v24.665h12.162v2.9zm28.53 0h-3.206v-24.72h-8.73v-2.847h20.667v2.847h-8.731zm19.894-13.783l7.354-13.784h3.47l-9.2 16.877v10.692h-3.243V39.916l-9.189-17.028h3.507zm35.045 13.784h-5.753V22.888h15.8v4.79h-10.048v7.109h9.353v4.771h-9.353zm31.321 0h-15.874V22.888h15.877v4.79h-10.036v6.053h9.334v4.79h-9.334v7.109h10.032zm21.628 0h-15.878V22.888h15.876v4.79h-10.03v6.053h9.33v4.79h-9.33v7.109h10.03zm28.624-14.048q0 6.807-3.875 10.428t-11.191 3.62h-7.807V22.888h8.655q6.751 0 10.484 3.564t3.734 9.957zm-6.072.151q0-8.881-7.844-8.881h-3.11V45.63h2.508q8.446-.001 8.446-9.071z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
