import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 22px;
    text-align: right;
    color: ${(props) => props.theme.darkBlue};
    margin: 15px 20px 20px 0;
    cursor: pointer;
`;

export const BackButton = styled.button`
    border: 0;
    background: transparent;
    
    i {
        top: -7px;
    }
`;
