import React, {Component} from 'react';
import {CheckBox} from "./Styled";
import PropTypes from "prop-types";

class FormCheckBox extends Component {
    render() {
        return (
            <CheckBox styled={this.props.styled} disabled={this.props.disabled}>
                <input
                    type="checkbox"
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    checked={this.props.checked}
                />
                <i>
                    <span>{this.props.label}</span>
                    <em/>
                </i>
            </CheckBox>
        );
    }
}

export default FormCheckBox;

FormCheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};