import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 60px;
    position: relative;
    h2 {
        color: ${(props) => props.theme.darkBlue};
        font-size: 24px;
        margin: 0 0 0 20px;
    }
`;

export const Connection = styled.div`
    margin: 20px 20px 0;
    img {
        width: 71px;
        height: 71px;
        float: left;
        border-radius: 10px;
    }
    h3 {
        float: left;
        margin: 12px 0 0 15px;
        font-size: 18px;
        font-weight: normal;
        line-height: 22px;
        color: ${(props) => props.theme.cyan};
        a {
            color: ${(props) => props.theme.cyan};
        }
    }
    em {
        display: block;
        font-style: normal;
        font-weight: lighter;
        font-size: 14px;
        color: ${(props) => props.theme.textBlack};
        span {
            position: relative;
            top: 1px;
            margin-right: 5px;
        }
    }
    button {
        width: 42px;
        height: 42px;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        background: ${(props) => props.theme.lighterBlue};
        float: right;
        margin: 20px 15px 0 0;
        &:hover {
            cursor: pointer;
        }
        span {
            position: relative;
            top: 1px;
        }
    }
`;

export const SeeMore = styled.div`
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 18px;
    color: ${(props) => props.theme.textBlack};
    &:hover {
        cursor: pointer;
    }
    span {
        position: relative;
        top: 4px;
        margin-left: 10px;
    }
`;
