import styled from "styled-components";

export const Wrapper = styled.div`
    box-sizing: border-box;
    height: 100px;
    background: ${props => props.theme.cyan};
    padding: 32px;
    font-size: 30px;
    color: #fff;
    position: relative;
    z-index: 200;
`;

export const Close = styled.span`
    position: absolute;
    right: 36px;
    top: 36px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    &:before, &:after {
        position: absolute;
        left: 12px;
        content: ' ';
        height: 28px;
        width: 3px;
        background-color: #fff;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;