import React, { Component } from 'react';
import {
    Wrapper,
    DefaultOption,
    MenuOptionSelect,
    ModalWrapper,
    ModalWrapperContent,
    ApplyButtonSubmit,
    SaveButtonSubmit,
    RadioBoxStyles,
} from './Styled';
import { ModalTitleStyles, ModalCloseStyles } from '../SharedListing/Styled';
import Modal from '../Modal';
import ProfileMenuContent from '../ProfileMenuContent';
import ButtonText from '../FormButtonText';
import RadioButton from '../FormRadioButton';
import SelectorPopUp from '../SelectorPopUp';

class ProfileMenuSettingsPrivileged extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyModalOpen: false,
            privacy: {
                propertyRecord: { text: 'Public', value: 1 },
                price: { text: 'Public', value: 1 },
                address: { text: 'Public', value: 1 },
                privilegedPhotos: { text: 'Public', value: 1 },
                documents: { text: 'Public', value: 1 },
            },
        };
        this.menu = [
            {
                label: 'Property Record',
                id: 'propertyRecord',
            },
            {
                label: 'Price',
                id: 'price',
            },
            {
                label: 'Address',
                id: 'address',
            },
            {
                label: 'Privileged Photos',
                id: 'privilegedPhotos',
            },
            {
                label: 'Documents',
                id: 'documents',
            },
        ];
        this.privacyOptions = [
            { text: 'Public', value: 1 },
            { text: 'My Followers', value: 2 },
            { text: 'My Connections', value: 3 },
        ];
    }

    applyClickHandler = () => {
        this.setState({
            applyModalOpen: false,
        });
    };

    saveClickHandler = () => {
        this.setState({
            applyModalOpen: true,
        });
    };

    render() {
        return (
            <>
                <ProfileMenuContent
                    title='Privileged® Listing'
                    onTitleClick={this.props.backButton}
                >
                    <Wrapper>
                        <p>Last edited on: 20 July 2020</p>
                        <DefaultOption>
                            <p>Default Settings</p>
                            <ButtonText
                                styled={SaveButtonSubmit}
                                onClick={this.saveClickHandler}
                            >
                                SAVE
                            </ButtonText>
                        </DefaultOption>
                        {this.menu.map((menu, index) => (
                            <MenuOptionSelect key={index}>
                                <p>{menu.label}</p>
                                <SelectorPopUp
                                    value={this.state.privacy[menu.id]}
                                    onChange={(option) =>
                                        this.setState((prevState) => {
                                            return {
                                                privacy: {
                                                    ...prevState.privacy,
                                                    [menu.id]: option,
                                                },
                                            };
                                        })
                                    }
                                    color={'textBlack'}
                                    items={this.privacyOptions}
                                    doubleArrow
                                />
                            </MenuOptionSelect>
                        ))}
                    </Wrapper>
                </ProfileMenuContent>
                <Modal
                    isOpen={this.state.applyModalOpen}
                    handleClose={this.applyClickHandler}
                    containerWidth='450'
                    containerHeight='360'
                    containerSizeUnit='px'
                    containerPadding='0'
                    title='Apply Settings'
                    titleStyled={ModalTitleStyles}
                    closeStyled={ModalCloseStyles}
                >
                    <ModalWrapper>
                        <ModalWrapperContent margin='0' />
                        <RadioButton
                            name='groupType'
                            styled={RadioBoxStyles}
                            label={
                                'apply this new settings for all previous listings'
                            }
                            onClick={this.radioClickHandler}
                        />
                        <RadioButton
                            name='groupType'
                            styled={RadioBoxStyles}
                            label={
                                'apply this only to the new listings that I add in the future'
                            }
                            onClick={this.radioClickHandler}
                        />
                        <ButtonText
                            styled={ApplyButtonSubmit}
                            onClick={this.applyClickHandler}
                        >
                            Apply Settings
                        </ButtonText>
                    </ModalWrapper>
                </Modal>
            </>
        );
    }
}

export default ProfileMenuSettingsPrivileged;
