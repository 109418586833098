import React, { Component, Fragment } from 'react';
import { Column } from '../../../styles/Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import LeadManagmentSearchTabBar from '../LeadManagmentSearchTabBar';

import {
    FirstColumnWrap,
    ManagmentFormWrap,
    InputWrap,
    SecondColumnWrap,
    CardWrapper,
    LeadManagmentWrap,
    FirstRightSide,
    CardLowerSideWrap,
    CardLowerItem,
    TopText,
    Lowertext,
    LeftSideContent,
    RightSideContent,
    ContactText,
    TagsStyle,
    LeftSideContentWrap,
    ContactButtonStyle,
    SecondButton,
    ProfileNameStyle,
    VioletArrowRight,
    LeadManagmentFiltersWrapper,
    HeaderWrapper,
    LeadNameInputWrapper,
    InputWrapper,
    RecordDateWrapper,
    RecordDateItemWrapper,
    RecordDateMiddleWrapper,
    DropUpPlaceHolder,
    RecordBottomWrapper,
    Circle,
    TimeFrameRangeWrapper,
    DropDownWrapper,
    DropDownMiddleItem,
    LastInteraction,
    ListingWrapper,
    PriceRange,
    LocationInput,
    TagStyle,
    PriceRangeContent,
    ApplySection,
    BtnCircle,
    ToggleButton,
    ApplyButton,
    MenuFooter
} from './Styled';
import { ArrowDown } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';
import FormButtonText from '../FormButtonText';

class LeadManagementSearchAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            SearchInput: '',
            leadSearchInput:'',
            locationInput:'',
            locationContainer: [],
            isButtonOn:false,
            isToggleClicked: false
        };

        this.CardProfile = [
            {
                id: 1,
                details: {
                    photo: 'https://i.stack.imgur.com/34AD2.jpg',
                    name: 'Sara Coner Williams',
                    hashtags: ['#Apartment', '#for_sale', '#Luxury'],
                    timeframe: '6-12 Months',
                    dollar: '499K',
                    rating: 'HOT',
                    age: '25 days',
                },
            },
            {
                id: 2,
                details: {
                    photo: 'https://i.stack.imgur.com/34AD2.jpg',
                    name: 'Kyle Evans',
                    hashtags: ['#coolhouse', '#forsale', '#trending'],
                    timeframe: '1-2 Months',
                    dollar: '100K',
                    rating: 'MEH',
                    age: '10 days',
                },
            },
        ];
        this.CardDetails = {
            id: 23,
            details: {
                name: 'Ruth Wallace',
                photo: 'https://i.stack.imgur.com/34AD2.jpg',
                conversation: '3',
                meeting: {
                    date: '2July',
                    hour: '12:00 PM',
                },
                followUp: '24/06/2020',
                note: [
                    {
                        text:
                            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do ',
                        date: '24/06/2020',
                    },
                    {
                        text:
                            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut abore ... ',
                        date: '24/06/2020',
                    },
                ],
                tags: [
                    { tag: '#Apartment' },
                    { tag: '#Apartment' },
                    { tag: '#Apartment' },
                ],
            },
        };
    }

    leadInputHandler = (e) => {
        this.setState({
            leadSearchInput: e.target.value
        })
    }

    locationInputHandler = (e) => {
        this.setState({
            locationInputHandler:e.target.value
        })
    }

    onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                locationContainer: [
                    ...this.state.locationContainer,
                    this.state.locationInputHandler,
                ],
                emailInput: '',
            });
        }
    };

    onToggleButtonHandler = () => {
        this.setState({
            isButtonOn: !this.state.isButtonOn,
            isToggleClicked:true,
        })
    }

    render() {
        return (
            <Fragment>
                <Column type="one_column_setting"  first >
                    <ManagmentFormWrap>
                        <FirstColumnWrap>
                            <span>Lead Managment</span>
                        </FirstColumnWrap>
                        <SecondColumnWrap>
                            <InputWrap>
                                <div>
                                    <input
                                        value={this.state.SearchInput}
                                        onChange={(e) => {
                                            this.setState({
                                                SearchInput: e.target.value,
                                            });
                                        }}
                                        type="text"
                                        placeholder="Search Leads..."
                                    />
                                    <IconNew
                                        bp1="-295"
                                        bp2="-130"
                                        iwidth="29"
                                        iheight="29"
                                    />
                                </div>
                            </InputWrap>
                        </SecondColumnWrap>
                    </ManagmentFormWrap>
                    <LeadManagmentSearchTabBar defaultIndex={0} />
                    <LeadManagmentWrap>
                        {this.CardProfile.map((card) => (
                            <CardWrapper key={card.id}>
                                <LeftSideContent>
                                    <img
                                        w="49"
                                        h="49"
                                        src={card.details.photo}
                                        alt="profile"
                                    />
                                    <LeftSideContentWrap>
                                        <ProfileNameStyle>
                                            {card.details.name}
                                        </ProfileNameStyle>
                                        <div style={{ display: 'flex' }}>
                                            <ContactButtonStyle
                                                to={`/lead-management/profile/${
                                                    card.details.name
                                                }`}
                                            >
                                                <ContactText>contact</ContactText>
                                                <ArrowDown color="white" />
                                            </ContactButtonStyle>
                                            <FormButtonText
                                                styled={SecondButton}
                                            >
                                                <IconNew
                                                    bp1="-541"
                                                    bp2="-453"
                                                    iwidth="14"
                                                    iheight="10"
                                                    className="threedots"
                                                />
                                                <VioletArrowRight
                                                    color={'violet'}
                                                />
                                            </FormButtonText>
                                        </div>
                                    </LeftSideContentWrap>
                                    <FirstRightSide>
                                        <RightSideContent>
                                            <IconNew
                                                bp1="-539"
                                                bp2="-464"
                                                iwidth="17"
                                                iheight="16"
                                            />
                                            <div>
                                                {card.details.hashtags.map(
                                                    (tag, index) => (
                                                        <TagsStyle key={'hashtag' + index}>
                                                            {tag}
                                                        </TagsStyle>
                                                    )
                                                )}
                                            </div>
                                        </RightSideContent>
                                    </FirstRightSide>
                                </LeftSideContent>
                                <CardLowerSideWrap>
                                    <CardLowerItem>
                                        <TopText>TIMEFRAME</TopText>
                                        <Lowertext>
                                            {card.details.timeframe}
                                        </Lowertext>
                                    </CardLowerItem>
                                    <CardLowerItem>
                                        <TopText>$</TopText>
                                        <Lowertext>
                                            {card.details.dollar}
                                        </Lowertext>
                                    </CardLowerItem>
                                    <CardLowerItem>
                                        <TopText>RATING</TopText>
                                        <Lowertext>
                                            {card.details.rating}
                                        </Lowertext>
                                    </CardLowerItem>
                                    <CardLowerItem>
                                        <TopText>AGE</TopText>
                                        <Lowertext>
                                            {card.details.age}
                                        </Lowertext>
                                    </CardLowerItem>
                                </CardLowerSideWrap>
                            </CardWrapper>
                        ))}
                    </LeadManagmentWrap>
                </Column>
                <Column type='one_column_right_fixed_simple'  scrollable first background={'culturedGray'}>
                <ProfileMenu closed unexpandable />
                <LeadManagmentFiltersWrapper>
                    <HeaderWrapper>
                        <span>
                            Filters
                        </span>
                    </HeaderWrapper>
                    <LeadNameInputWrapper>
                       <div>
                           <InputWrapper
                                onChange={(e) => this.leadInputHandler(e)} 
                                placeholder={'Enter lead name'}
                           />
                           <span>h</span>
                       </div>
                    </LeadNameInputWrapper>
                    <RecordDateWrapper>
                        <RecordDateItemWrapper>
                            <span>
                                Record's Date
                            </span>
                            <span className='date'>
                                Within the last week
                            </span>
                        </RecordDateItemWrapper>
                        <RecordDateMiddleWrapper>
                            <span>
                                Date Period
                            </span>
                            <div>
                                <DropUpPlaceHolder>
                                    Whithin the last Week
                                </DropUpPlaceHolder>
                                <span>
                                    h
                                </span>
                            </div>
                        </RecordDateMiddleWrapper>
                        <RecordBottomWrapper>
                            <div>
                                <Circle />
                                <span>
                                    Time Period
                                </span>
                            </div>
                            <div>
                                <Circle />
                                <span>
                                    Custom Time Perod
                                </span>
                            </div>
                        </RecordBottomWrapper>
                    </RecordDateWrapper>  
                    <TimeFrameRangeWrapper>
                        <RecordDateItemWrapper>
                            <span>
                                Timeframe Range(remaining)
                            </span>
                            <span className='date'>
                                (1-2) mths
                            </span>
                        </RecordDateItemWrapper>
                            <DropDownWrapper>
                                <DropDownMiddleItem>
                                    <span>
                                        Min
                                    </span>
                                    <div>
                                        <DropUpPlaceHolder>
                                            1
                                        </DropUpPlaceHolder>
                                    </div>
                                </DropDownMiddleItem>
                                <DropDownMiddleItem>
                                    <span>
                                        Max
                                    </span>
                                    <div>
                                        <DropUpPlaceHolder>
                                            1
                                        </DropUpPlaceHolder>
                                    </div>
                                </DropDownMiddleItem>
                            </DropDownWrapper>
                    </TimeFrameRangeWrapper>    
                    <LastInteraction>
                            <RecordDateItemWrapper>
                                <span>
                                last Interaction
                                </span>
                                <span className='date'>
                                    10 days ago
                                </span>
                            </RecordDateItemWrapper>
                            <DropDownWrapper>
                                <div className='dropdownitem'>
                                    <div>
                                        <DropUpPlaceHolder>
                                            1
                                        </DropUpPlaceHolder>
                                    </div>
                                </div>
                                <div className='dropdownitem-small'>
                                    <div>
                                        <DropUpPlaceHolder>
                                            Days ago
                                        </DropUpPlaceHolder>
                                    </div>
                                </div>
                            </DropDownWrapper>
                        </LastInteraction>            
                        <ListingWrapper>
                                <RecordDateItemWrapper>
                                    <span>
                                        Listing
                                    </span>
                                    <span className='date'>
                                        ^
                                    </span>
                                </RecordDateItemWrapper>
                                <div>
                                <RecordDateMiddleWrapper>
                                <span>
                                    Property Type
                                </span>
                                <div>
                                    <DropUpPlaceHolder>
                                        Apartment
                                    </DropUpPlaceHolder>
                                    <span>
                                        h
                                    </span>
                                </div>
                            </RecordDateMiddleWrapper>
                            <RecordDateMiddleWrapper>
                                <span>
                                    Listing Type
                                </span>
                                <div>
                                    <DropUpPlaceHolder>
                                        For sale
                                    </DropUpPlaceHolder>
                                    <span>
                                        h
                                    </span>
                                </div>
                            </RecordDateMiddleWrapper>
                            <RecordDateMiddleWrapper>
                                <span>
                                    Location
                                </span>
                                <div>
                                    <DropUpPlaceHolder className='location'>
                                            {this.state.locationContainer.map((item, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <TagStyle firstItem={index === 0}>
                                                            {item}
                                                            <span style={{marginLeft:'3.74px'}}>
                                                                x
                                                            </span>
                                                        </TagStyle>
                                                    </React.Fragment>
                                                );
                                            })}
                                        <LocationInput 
                                                onChange={(e) => this.locationInputHandler(e)}
                                                onKeyDown={(e) => this.onKeyDownHandler(e)}
                                                placeholder={'Add location'}
                                            />
                                    </DropUpPlaceHolder>
                                    <span>
                                        h
                                    </span>
                                </div>
                            </RecordDateMiddleWrapper>
                            </div>
                        </ListingWrapper> 
                        <PriceRange>
                        <RecordDateItemWrapper>
                            <span>
                                Price Range
                            </span>
                            <span className='date'>
                                ($500k - $1.5M)
                            </span>
                        </RecordDateItemWrapper>
                        <PriceRangeContent>
                            <span className='date'>
                                    ($500k - $1.5M)
                            </span>
                            <DropDownWrapper noMarginTop>
                                    <DropDownMiddleItem>
                                        <div>
                                            <DropUpPlaceHolder>
                                                500K
                                            </DropUpPlaceHolder>
                                        </div>
                                    </DropDownMiddleItem>
                                    <DropDownMiddleItem>
                                        <div>
                                            <DropUpPlaceHolder>
                                                1.5M
                                            </DropUpPlaceHolder>
                                        </div>
                                    </DropDownMiddleItem>
                                </DropDownWrapper>
                        </PriceRangeContent>
                        </PriceRange>
                        <ApplySection>
                            <div className='pre'>
                                <span>
                                    Pre-Approved
                                </span>
                                <ToggleButton  onClick={this.onToggleButtonHandler}>
                                    <BtnCircle  isBtnOn={!this.state.isButtonOn && this.state.isToggleClicked} isBtnOff={this.state.isButtonOn && this.state.isToggleClicked}  />
                                </ToggleButton>
                            </div>
                            <div className='button'>
                                <ApplyButton>
                                    Apply
                                </ApplyButton>
                                <MenuFooter>
                                    About us | Help | Jobs | Privacy Policy
                                    | Terms
                                    <br />© 2020 RealtyFeed
                                </MenuFooter>
                            </div>
                        </ApplySection>
                </LeadManagmentFiltersWrapper>
                </Column>
                <ClearFloats />
            </Fragment>
        );
    }
}

export default LeadManagementSearchAll;
