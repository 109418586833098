import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
        props.closed && !props.unexpandable ? 'transparent' : '#fff'};
    width: 500px;
    z-index: 100;
    ${(props) =>
        props.closed &&
        !props.unexpandable &&
        ' height: 140px; bottom: auto; '};

    @media ${(props) => props.theme.mediaHD} {
        width: 400px;
    }

    @media ${(props) => props.theme.mediaTablet} {
        width: 347px;
    }
`;

export const ExpandButton = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${(props) => (props.isExpand ? '20' : '70')}px;
    background: #fff;
    border-radius: 0 0 0 40px;
    z-index: 10;
    box-sizing: border-box;
    border-bottom: solid 1px ${(props) => props.theme.grayDarkBorder};
    border-left: solid 1px ${(props) => props.theme.grayDarkBorder};
    ${(props) => props.isExpand && 'border: none'};
    &:hover {
        cursor: pointer;
    }
    span {
        width: 1.1rem;
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        &:after,
        &:before {
            width: 0.7rem;
            background-color: ${(props) => props.theme.grayLine};
        }
    }
`;

export const Header = styled.div`
    ${(props) =>
        props.closed && !props.unexpandable && ' border-radius: 0 0 0 40px; '};
    height: ${(props) =>
        props.closed && !props.unexpandable ? '120px' : '100px'};
    box-sizing: border-box;
    padding: 20px;
    background: ${(props) =>
        `linear-gradient(to right, ${props.theme.darkBlue} 15%, ${
            props.theme.violet
        } 100%)`};
    position: relative;
    z-index: 11;
    img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        float: left;
    }
    div {
        position: absolute;
        top: 11px;
        left: 64px;
        span {
            display: inline-block;
            width: 37px;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            color: #fff;
        }
    }
    h3 {
        float: left;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 15px 0 0 18px;
        span {
            display: block;
            color: #fff;
            margin-bottom: 5px;
        }
        em {
            display: inline-block;
            font-style: normal;
            color: #fff;
            opacity: 0.5;
        }
        i {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 10px;
            margin-right: 8px;
            background: ${(props) => props.theme.statusGreen};
        }
    }
    > span {
        float: right;
        margin: 20px 0 0 30px;
        &:hover {
            cursor: pointer;
        }
    }
    > strong {
        position: absolute;
        line-height: 19px;
        top: 32px;
        right: 73px;
        width: 20px;
        height: 20px;
        border-radius: 5px 5px 5px 0;
        font-weight: normal;
        background: ${(props) => props.theme.cyan};
        text-align: center;
        color: #fff;
        cursor: pointer;
    }
`;

export const TabContainer = styled.div`
    position: absolute;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const WheelButton = styled.div`
    border-radius: ${(props) => (props.open ? '50px' : '50%')};
    background: #fff;
    width: 80px;
    height: ${(props) => (props.open ? 400 : 0)}px;
    margin-top: 30px;
    margin-left: -38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: fixed;
    z-index: 1;

    > span {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        display: ${(props) => (props.open ? 'flex' : 'none')};
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        > p {
            font-size: 12px;
            color: ${(props) => props.theme.mainFontColor};
            font-weight: lighter;
            margin: 0;
        }
        cursor: pointer;
    }

    > button {
        width: 64px;
        min-height: 64px;
        border-radius: 69px;
        margin: 0;
        padding: 0;
        box-sizing: content-box;
        background: linear-gradient(
            134deg,
            ${(props) => props.theme.purple} 16%,
            ${(props) => props.theme.lipstickRed} 79%
        );
        border: solid 7px
            ${(props) => (props.open ? '#fff' : props.theme.bodyBg)};
        z-index: 1;
        cursor: pointer;
    }
`;

export const Triangle = styled.div`
    width: 0;
    height: 0;
    border-bottom: 14px solid transparent;
    border-top: 14px solid transparent;
    ${(props) =>
        props.left
            ? `border-right: 14px solid ${props.theme.textGrayLight}`
            : `border-left: 14px solid ${props.theme.textGrayLight};`};
    position: absolute;
    ${(props) => (props.left ? 'left: -13px' : 'right: -13px;')};

    ::after {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 13px solid transparent;
        border-top: 13px solid transparent;
        ${(props) =>
            props.left
                ? 'border-right: 13px solid #fff'
                : 'border-left: 13px solid #fff'};
        position: absolute;
        ${(props) => (props.left ? 'left: 2px' : 'right: 2px')};
        top: -13px;
    }
`;

export const FabButton = styled.div`
    border-radius: ${(props) => (props.open ? '50px' : '50%')};
    background: white;
    width: 80px;
    height: ${(props) => (props.open ? 400 : 0)}px;
    margin-top: ${(props) => (props.wheelOpen ? '460' : '130')}px;
    margin-left: -38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    button {
        width: 64px;
        min-height: 64px;
        border-radius: 69px;
        margin: 0;
        padding: 0;
        box-sizing: content-box;
        background: linear-gradient(
            134deg,
            ${(props) => props.theme.cyan} 16%,
            ${(props) => props.theme.darkBlue} 79%
        );
        border: solid 7px
            ${(props) => (props.open ? '#fff' : props.theme.bodyBg)};
        z-index: 1;
        cursor: pointer;
        font-size: 40px;
        color: #fff;
    }
`;

export const MenuFooter = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.textGrayLight};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    height: 90px;
    padding: 10px 35px;
    background: #fff;
`;
