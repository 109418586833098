import React from "react";
import BaseTemplateNew from "../Modules/BaseTemplateNew";
import {Main} from "../../styles/Styled";
import Config from "../../config";
import {Link} from "react-router-dom";
import {Wrapper} from "./Styled";

class PageNotFound extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showHeader = !(props.noHeader);
    }
    renderBody() {
        return(
            <Main backgrounded>
                <Wrapper>
                    <h1>
                        <strong>⚠</strong>
                        <br/>
                        Page Not Found
                    </h1>
                    The page you're looking for, is not available.<br/>
                    Please start from <Link to={Config.ROUTES.homePage}>Home Page</Link>.
                </Wrapper>
            </Main>
        );
    }
}

export default PageNotFound;