import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import FeedPage from '../Modules/FeedPage';
import { Main } from '../../styles/Styled';
import ProfileMenu from '../Modules/ProfileMenu';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';

 class Home extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;
    }

    renderBody() {
        return (
            <Main>
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : this.state.homeType === 'feed' ? (
                    <FeedPage />
                ) : (
                    <p>Discover</p>
                )}
                <ProfileMenu unexpandable />
            </Main>
        );
    }
}

export default observer(Home);
