import React from 'react';
import {
    ProfileMenuSendInvatationWrapper,
    InviteText,
    ContactListButtonWrapper,
    ButtonsWrapper,
    FacebookButtonWrapper,
    EmailButtonWrapper,
    InputWrapper,
    EmailCardWrapper,
    EmailCardContainer,
    InviteButtonWrapper,
    InviteInstructionWrapper,
    InviteHeader,
    InviteInstructionText,
    ContactcardWrapper,
    ContactCardSide,
    ContactImage,
    ContactName,
    ContactMail,
    ContactTextWrapper,
    Circle,
    AddContactButton,
    Invitebutton,
    SendInvatationButton,
    InvatationTextWrapper,
    InvatationTextInstruction,
    InvtationText,
    FirstModalStyle,
    FirstModalButtonWrapper,
    NoButtonWrapper,
    YesButtonWrapper,
    ThirdModalWrapper,
    ModalNote,
    ModalNoteHeader,
    FinishButtonWrapper,
    NameColor
} from './Styled';
import Modal from '../Modal';

class ProfileMenuSendInvatation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEmailActive: false,
            emailInput: '',
            selectedObject: {},
            selectedItem: null,
            selectedUserName:'',
            isButtonSelected: false,
            contactListInput: '',
            currentPageIndex: 0,
            isFirstModalOpen: false,
            modalStep: 1,
            modalTitle: '',
            sentNameValue: '',
            sentMailNumberValue: '',
            emailsContainer: [
                'johndoe@react.com',
                'johndoe@react.com',
                'johndoe@react.com',
                'johndoe@react.com',
            ],
        };

        this.pagesToShow = {
            sendInvatation: 0,
            sendContactInvatation: 1,
            sendContactInvatationLastPage: 2,
        };
        this.contacts = [
            {
                name: 'King Kong',
                email: 'johnnysmith@realtor.com',
                photo:
                    'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            },
            {
                name: 'Jack Sparrow',
                email: 'johnnysmith@realtor.com',
                photo:
                    'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            },
            {
                name: 'Peter Parker',
                email: 'johnnysmith@realtor.com',
                photo:
                    'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            },
            {
                name: 'Cool Name',
                email: 'johnnysmith@realtor.com',
                photo:
                    'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            },
        ];
        this.invText =
            `Dear ${this.state.selectedUserName}\nLet's work together on realtyFeed and connect. They have the best market area.Seriosuly.\ntap to join me:`;
        this.link = 'https://realtyfeed.com/25fd390?inv=hectorz';
    }


    modalTitle = () => {
        let firstName = this.state.selectedUserName.split(" ")[0];
        return (
            <span>
                Did you send the invitation to <NameColor>{firstName}</NameColor>?`
            </span>
        );
    };

    sendInvatationHandler = () => {
        this.setState({
            isFirstModalOpen: true,
            modalTitle: this.modalTitle(),
        });
    };

    closeFirstModal = () => {
        this.setState({
            isFirstModalOpen: false,
        });
    };

    nextStepModal = () => {
        this.setState({
            modalStep: 2,
            modalTitle: 'Did you send it to someone else?',
        });
    };

    thirdStepModal = () => {
        this.setState({
            modalStep: 3,
            modalTitle: 'To whom?',
        });
    };

    inviteButtonHandler = () => {
        this.setState({
            currentPageIndex: this.pagesToShow.sendContactInvatationLastPage,
        });
    };

    onSelectButtonHandler = (id, mailObj) => {
        if (id !== this.state.selectedItem) {
           /*  let firstname = mailObj.name.split(" ")[0]; */
            this.setState({
                selectedItem: id,
                isButtonSelected: true,
                selectedObject: { ...this.state.selectedObject, mailObj },
                selectedUserName:mailObj.name

            });
        } else if (
            this.state.isButtonSelected ||
            id === this.state.selectedItem
        ) {
            this.setState({
                isButtonSelected: !this.state.isButtonSelected,
            });
        }
    };

    contactInputHandler = (e) => {
        this.setState({
            contactListInput: e.target.value,
        });
    };

    emailButtonHandler = () => {
        this.setState({
            isEmailActive: !this.state.isEmailActive,
        });
    };

    onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                emailsContainer: [
                    ...this.state.emailsContainer,
                    this.state.emailInput,
                ],
                emailInput: '',
            });
        }
    };

    emailAddHandler = (e) => {
        this.setState({
            emailInput: e.target.value,
        });
    };

    emailDeleteHandler = (email, i) => {
        const filteredEmails = this.state.emailsContainer.filter(
            (item, index) => (item && index) !== (email && i)
        );
        this.setState({
            emailsContainer: filteredEmails,
        });
    };

    contactButtonHandler = () => {
        this.setState({
            currentPageIndex: this.pagesToShow.sendContactInvatation,
        });
    };

    InvatationCard = () => {
        const { InvNum, index } = this.props.inviteCardData;
        return (
            <React.Fragment>
                {this.props.InvatationCard(InvNum, index)}
            </React.Fragment>
        );
    };

    render() {
        console.log(this.state.selectedUserName)
        return (
            <React.Fragment>
                {this.state.currentPageIndex ===
                    this.pagesToShow.sendInvatation && (
                    <ProfileMenuSendInvatationWrapper>
                        {this.InvatationCard()}

                        <InviteText>
                            In order to help us invite your friend please get
                            access to these accounts
                        </InviteText>
                        <ButtonsWrapper>
                            <ContactListButtonWrapper
                                onClick={this.contactButtonHandler}
                            >
                                Access to Contact List
                            </ContactListButtonWrapper>
                            <FacebookButtonWrapper>
                                Acess to Facebook
                            </FacebookButtonWrapper>
                            <EmailButtonWrapper
                                active={this.state.isEmailActive}
                                onClick={this.emailButtonHandler}
                            >
                                Add email address manually
                            </EmailButtonWrapper>
                        </ButtonsWrapper>
                        {this.state.isEmailActive && (
                            <React.Fragment>
                                <EmailCardContainer>
                                    {this.state.emailsContainer.map(
                                        (item, index) => {
                                            return (
                                                <EmailCardWrapper key={index}>
                                                    <span>{item}</span>
                                                    <span
                                                        onClick={() =>
                                                            this.emailDeleteHandler(
                                                                item,
                                                                index
                                                            )
                                                        }
                                                        style={{
                                                            marginLeft: '9px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </span>
                                                </EmailCardWrapper>
                                            );
                                        }
                                    )}
                                </EmailCardContainer>
                                <InputWrapper
                                    value={this.state.emailInput}
                                    placeholder={'Search'}
                                    onChange={(e) => this.emailAddHandler(e)}
                                    onKeyDown={(e) => this.onKeyDownHandler(e)}
                                />
                            </React.Fragment>
                        )}
                        <InviteButtonWrapper
                            active={this.state.isEmailActive}
                            disabled={!this.state.isEmailActive}
                        >
                            Invite {'>'}
                        </InviteButtonWrapper>
                    </ProfileMenuSendInvatationWrapper>
                )}
                {this.state.currentPageIndex ===
                    this.pagesToShow.sendContactInvatation && (
                    <ProfileMenuSendInvatationWrapper>
                        {this.InvatationCard()}
                        <InviteInstructionWrapper>
                            <ModalNoteHeader>Note:</ModalNoteHeader>
                            <InviteInstructionText>
                                Each invitation is unique and can be used only
                                one time. Please make sure to send it to the
                                right person.
                            </InviteInstructionText>
                        </InviteInstructionWrapper>
                        <InputWrapper
                            value={this.state.contactListInput}
                            placeholder={'Search'}
                            onChange={(e) => this.contactInputHandler(e)}
                        />
                        {this.contacts.map((item, index) => {
                            const active = index === this.state.selectedItem;
                            return (
                                <ContactcardWrapper>
                                    <ContactCardSide>
                                        <ContactImage
                                            src={item.photo}
                                            alt="picturee"
                                        />
                                        <ContactTextWrapper>
                                            <ContactName>
                                                {item.name}
                                            </ContactName>
                                            <ContactMail>
                                                {item.email}
                                            </ContactMail>
                                        </ContactTextWrapper>
                                    </ContactCardSide>
                                    <ContactCardSide>
                                        <Circle
                                            active={
                                                (active &&
                                                    this.state
                                                        .isButtonSelected) ===
                                                true
                                            }
                                            onClick={() =>
                                                this.onSelectButtonHandler(
                                                    index,
                                                    item
                                                )
                                            }
                                        />
                                        <span>h</span>
                                    </ContactCardSide>
                                </ContactcardWrapper>
                            );
                        })}
                        <AddContactButton>+ Add More Contact</AddContactButton>
                        <Invitebutton
                            active={this.state.isButtonSelected}
                            disabled={!this.state.isButtonSelected}
                            onClick={this.inviteButtonHandler}
                        >
                            <span>Invite new Members</span>
                            <span>{'>'}</span>
                        </Invitebutton>
                    </ProfileMenuSendInvatationWrapper>
                )}
                {this.state.currentPageIndex ===
                    this.pagesToShow.sendContactInvatationLastPage && (
                    <ProfileMenuSendInvatationWrapper>
                        {this.InvatationCard()}
                        <InviteText>
                            Please select way to invite your friends
                        </InviteText>
                        <SendInvatationButton
                            onClick={this.sendInvatationHandler}
                        >
                            Send Invatation
                        </SendInvatationButton>
                        <InvatationTextWrapper>
                            <InvatationTextInstruction>
                                Or copy and send it yourself
                            </InvatationTextInstruction>
                            <InvtationText>
                                <span>{`Dear ${this.state.selectedUserName}\nLet's work together on realtyFeed and connect. They have the best market area.Seriosuly.\ntap to join me:`}</span>
                                <span className="link-text">{this.link}</span>
                            </InvtationText>
                        </InvatationTextWrapper>
                        <InviteInstructionWrapper>
                            <InviteHeader>Note:</InviteHeader>
                            <InviteInstructionText>
                                Each invitation is unique and can be used only
                                one time. Please make sure to send it to the
                                right person.
                            </InviteInstructionText>
                        </InviteInstructionWrapper>
                    </ProfileMenuSendInvatationWrapper>
                )}
                <Modal
                    title={this.state.modalTitle}
                    isOpen={this.state.isFirstModalOpen}
                    handleClose={this.closeFirstModal}
                    containerWidth="450"
                    containerHeight="auto"
                    containerSizeUnit="px"
                    containerPadding="20"
                    titleStyled={FirstModalStyle}
                >
                    {this.state.modalStep === 1 && (
                        <FirstModalButtonWrapper>
                            <NoButtonWrapper onClick={this.nextStepModal}>
                                No
                            </NoButtonWrapper>
                            <YesButtonWrapper onClick={this.closeFirstModal}>
                                Yes
                            </YesButtonWrapper>
                        </FirstModalButtonWrapper>
                    )}
                    {this.state.modalStep === 2 && (
                        <FirstModalButtonWrapper>
                            <NoButtonWrapper onClick={this.thirdStepModal}>
                                No
                            </NoButtonWrapper>
                            <YesButtonWrapper onClick={this.closeFirstModal}>
                                Yes
                            </YesButtonWrapper>
                        </FirstModalButtonWrapper>
                    )}
                    {this.state.modalStep === 3 && (
                        <ThirdModalWrapper>
                            <InputWrapper
                                value={this.state.sentNameValue}
                                placeholder={'Please enter his/her name'}
                                onChange={(e) => this.emailAddHandler(e)}
                            />
                            <InputWrapper
                                value={this.state.sentMailNumberValue}
                                placeholder={
                                    'Please enter phone number or email'
                                }
                                onChange={(e) =>
                                    this.setState({
                                        sentMailNumberValue: e.target.value,
                                    })
                                }
                            />
                            <InviteInstructionWrapper>
                                <ModalNote>
                                    <ModalNoteHeader>Note:</ModalNoteHeader>
                                    As the number of invitations are limited, we
                                    can keep the records for your future
                                    reference.
                                </ModalNote>
                            </InviteInstructionWrapper>
                            <FinishButtonWrapper onClick={this.closeFirstModal}>
                                Finish
                            </FinishButtonWrapper>
                        </ThirdModalWrapper>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

export default ProfileMenuSendInvatation;
