import styled, {css} from "styled-components";
import {
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    InputTextWrapperStyles
} from "../LoginForm/Styled";

export const InputTextWrapperPropertyStyles = css`
    ${InputTextWrapperStyles};
    width: 900px;
    margin-top: 60px;
    height: 35px;
    &:first-of-type {
        margin-right: 60px;
    }
`;

export const InputTextWrapperPropertyMiniStyles = css`
    ${InputTextWrapperPropertyStyles};
    width: 300px;
    margin-top: 60px;
    &:first-of-type {
        margin-right: 10px;
    }
`;

export const DropDownWrapperPropertyStyles = css`
    ${InputTextWrapperPropertyStyles};
    width: 420px;
`;

export const DropDownWrapperPropertyMiniStyles = css`
    ${DropDownWrapperPropertyStyles};
    width: 110px;
`;

export const DropDownWrapperPropertyHalfStyles = css`
    ${InputTextWrapperPropertyMiniStyles};
    width: 205px;
`;

export const InputTextPropertyStyles = css`
    ${InputTextStyles};
    font-size: 20px;
`;

export const InputLabelAnimatePropertyStyles = css`
    ${InputLabelAnimateStyles};
    font-size: 20px;
    color: ${props => props.theme.mainFontColor};
`;

export const InputLabelAfterAnimatePropertyStyles = css`
    ${InputLabelAfterAnimateStyles};
    font-size: 16px;
`;

export const ButtonRadioCheckStyles = css`
    font-size: 21px;
    input:checked ~ i {
        background: ${props => props.theme.cyan};
        color: #fff;
        &:after {
            content: "";
            position: absolute;
            bottom: -10px; left: 50%;
            transform: translateX(-50%);
            width: 0; height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid ${props => props.theme.cyan};
        }
    }
    em:before {
        background: #fff;
    }
    em:after {
        border-color: ${props => props.theme.cyan};
    }
`;

export const DropDownWrapper = styled.div`
    display: flex;
`;

export const DropDownWrapperTwoCol = styled.div`
    display: flex;
`;