import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 96px);
    padding-left: 5%;
    padding-right: 5%;
`;

export const MenuOption = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 0;
    background: transparent;
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    margin: 0;
    padding-bottom: 16px;
    padding-top: 20px;
    padding-left: 30px;
    cursor: pointer;
    :first-of-type{
        padding-top: 0;
    }

    span {
        margin: 0;
        margin-right: 10px;
    }

    p {
        font-family: 'Montserrat';
        font-size: ${props => props.fontSize ? `${props.fontSize}px` : '18px'};
        color: ${(props) => props.theme.mainFontColor};
        margin: 0;
        text-align: left;
        height: 30px;
        padding-top: 3px;
    }
`;

export const LogoutButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 0;
    background: transparent;
    width: 90%;
    margin: 0;
    margin-top: 100px;
    cursor: pointer;

    span {
        margin: 0;
        margin-right: 10px;
    }

    p {
        font-size: 20px;
        color: ${(props) => props.theme.mainFontColor};
        margin: 0;
        text-align: left;
        height: 30px;
        padding-top: 5px;
    }
`;
