import React, { Component } from 'react';

import ListingOnFeed, { PropertyType } from '../ListingOnFeed';
import { sendAsyncRequest } from '../../../Utils';

import {
    AnnouncementMask,
    PropertyWrapper,
    SponsorMask,
    PropertiesWrapperLeft,
    PropertiesWrapperRight,
} from './Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import { Column } from '../../../styles/Styled';
import LogoLoading from '../LogoLoading';
import { withRouter } from 'react-router';
import Config from '../../../config';
import { withTheme } from 'styled-components';

class FeedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSearchOpen: false,
            data: [],
            modalOpen: true,
        };
        this.pageNumber = 0;
        this.pageSize = 5;
        this.endOfPage = false;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleEndOfPage);
        (async () => {
            await this.getListings();
        })();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleEndOfPage);
    }

    getListings = async () => {
        this.pageNumber++;
        this.setState({ isLoading: true });
        const result = await sendAsyncRequest('/feed/', 'get', {
            page: this.pageNumber,
        });
        if (result.status === 200) {
            console.log(result.body.Result.results);
            this.loadListings(result.body.Result.results);
        }
    };

    loadListings = (results) => {
        let data = [...this.state.data];

        // Fixed Listing (Share Idea)
        if (this.pageNumber === 1) {
            data.push(
                <PropertyWrapper key={0}>
                    <ListingOnFeed id={0} type={PropertyType.SHARE_IDEA} />
                </PropertyWrapper>
            );
        }

        results.forEach((listing) => {
            let listingType, headerFrame;
            switch (listing.type) {
                case 'announcement':
                    listingType = PropertyType.ANNOUNCEMENT;
                    headerFrame = <AnnouncementMask />;
                    break;
                case 'sponsor':
                    listingType = PropertyType.SPONSOR;
                    headerFrame = <SponsorMask text="Sponsor Listing" />;
                    break;
                default:
                    listingType = PropertyType.NORMAL;
                    break;
            }
            data.push(
                <PropertyWrapper key={listing.pk}>
                    {headerFrame}
                    <ListingOnFeed
                        id={listing.pk}
                        type={listingType}
                        listing={listing}
                        liked={listing.liked}
                        totalLikes={listing.total_likes}
                        saved={listing.saved}
                    />
                </PropertyWrapper>
            );
        });

        this.setState({ data }, () => {
            this.setState({ isLoading: false });
            this.endOfPage = false;
        });

        /*Object.keys(results).forEach(function (key) {
            const listing = results[key];
            const slug = listing.slug.split(".com");

            let isOwner = false;
            if (userID === listing.owner.pk) {
                isOwner = true;
            }

            data.push(
                <ListingOnFeed
                    key={listing.pk}
                    userImage={listing.owner.profile}
                    userName={listing.owner.username}
                    description={listing.description}
                    address={listing.street !== 'hidden' ? listing.street : ''}
                    addDate={listing.add_date}
                    listingImages={listing.images}
                    listingID={listing.pk}
                    hasLiked={listing.liked}
                    hasSaved={listing.saved}
                    totalComments={listing.total_comments}
                    totalLikes={listing.total_likes}
                    isOwner={isOwner}
                    bedrooms={listing.bedrooms}
                    canComment={listing.can_comment}
                    canShare={listing.can_share}
                    slug={listing.slug}
                    slugPath={slug[1]}
                />
            );
        });*/
    };

    toggleSearchModal = () => {
        this.setState((prevState) => {
            return { isSearchOpen: !prevState.isSearchOpen };
        });
    };

    handleEndOfPage = () => {
        if (
            window.innerHeight + Math.ceil(window.pageYOffset + 1) >=
                document.body.offsetHeight &&
            !this.endOfPage
        ) {
            this.endOfPage = true;
            (async () => {
                await this.getListings();
            })();
        }
    };

    searchChangeHandler = (e) => {
        this.setState({
            searchInputValue: e.target.value,
        });
    };

    searchHandler = () => {
        const query = this.state.searchInputValue;
        this.props.history.push(Config.ROUTES.search.agent + '/' + query);
    };

    render() {
        return (
            <Column type="feed_column" padding_right>
                {window.matchMedia(this.props.theme.mediaFullHD).matches && (
                    <>
                        <PropertiesWrapperLeft>
                            {this.state.data.map(
                                (listing, index) => index % 2 === 0 && listing
                            )}
                            <ClearFloats />
                        </PropertiesWrapperLeft>
                        <PropertiesWrapperRight>
                            {this.state.data.map(
                                (listing, index) => index % 2 === 1 && listing
                            )}
                            <ClearFloats />
                        </PropertiesWrapperRight>
                    </>
                )}
                {window.matchMedia(this.props.theme.mediaHD).matches && (
                    <>
                        <PropertiesWrapperLeft>
                            {this.state.data.map(
                                (listing, index) => index % 2 === 0 && listing
                            )}
                            <ClearFloats />
                        </PropertiesWrapperLeft>
                        <PropertiesWrapperRight>
                            {this.state.data.map(
                                (listing, index) => index % 2 === 1 && listing
                            )}
                            <ClearFloats />
                        </PropertiesWrapperRight>
                    </>
                )}
                {window.matchMedia(this.props.theme.mediaTablet).matches && (
                    <PropertiesWrapperLeft>
                        {this.state.data.map((listing, index) => listing)}
                        <ClearFloats />
                    </PropertiesWrapperLeft>
                )}

                {/*{
                        (this.state.data.length !== 0) &&
                        <ClearFloats/>
                    }
                    <SkeletonLoading
                        type={SkeletonLoadingType.LISTING_FEEDS}
                    />*/}

                <ClearFloats />
                <LogoLoading isLoading={this.state.isLoading} />

                <ClearFloats />

                {/*<div id="listings">
                    {this.state.data}
                </div>
                {this.state.isLoading ? <LoadingWrapper><Loading type="blue" iSize="2x"/></LoadingWrapper> : null}
                */}
            </Column>
        );
    }
}

export default withRouter(withTheme(FeedPage));
