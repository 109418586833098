import React, { Component, Fragment } from 'react';
import { ShareCounts } from './Styled';
import IconNew from '../Modules/IconNew';
import PropTypes from 'prop-types';
import SharedListing from '../Modules/SharedListing';
import { withTheme } from 'styled-components';
import CommentsModal from '../Modules/CommentsModal';

class ListingSharedCounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            modalHeight: 0,
            comments: [],
        };
    }

    componentDidMount() {
        this.setState({ comments: this.props.listing.comments });
        if (window.matchMedia(this.props.theme.mediaFullHD).matches) {
            this.setState({ modalHeight: 850 });
        } else if (window.matchMedia(this.props.theme.mediaHD).matches) {
            this.setState({ modalHeight: 700 });
        }
    }

    render() {
        return (
            <ShareCounts type={this.props.type}>
                {this.props.type === SharedCountsType.BIG ? (
                    this.props.liked ? (
                        <button onClick={this.props.onLike}>
                            <IconNew
                                bp1="-158"
                                bp2="-207"
                                iwidth="30"
                                iheight="26"
                                className="heart"
                            />
                        </button>
                    ) : (
                        <button onClick={this.props.onLike}>
                            <IconNew
                                bp1="-100"
                                bp2="-177"
                                iwidth="32"
                                iheight="28"
                                className="heart"
                            />
                        </button>
                    )
                ) : this.props.liked ? (
                    <button onClick={this.props.onLike}>
                        <IconNew
                            bp1="-80"
                            bp2="-118"
                            iwidth="20"
                            iheight="17"
                            className="heart"
                        />
                    </button>
                ) : (
                    <button onClick={this.props.onLike}>
                        <IconNew
                            bp1="-56"
                            bp2="-118"
                            iwidth="22"
                            iheight="19"
                            className="heart"
                        />
                    </button>
                )}
                {this.props.listing ? this.props.totalLikes : 0}
                {(this.props.type === SharedCountsType.NORMAL ||
                    this.props.type === SharedCountsType.BIG) && (
                    <Fragment>
                        {this.props.type === SharedCountsType.BIG ? (
                            <IconNew
                                bp1="-129"
                                bp2="-206"
                                iwidth="29"
                                iheight="29"
                                className="comment"
                            />
                        ) : (
                            <button
                                onClick={() =>
                                    this.setState({
                                        modalOpen: true,
                                    })
                                }
                            >
                                <IconNew
                                    bp1="-229"
                                    bp2="-105"
                                    iwidth="25"
                                    iheight="23"
                                    className="comment"
                                />
                            </button>
                        )}
                        <span>
                            {this.props.listing
                                ? this.props.listing.total_comments
                                : 0}
                        </span>
                    </Fragment>
                )}
                <SharedListing
                    type={this.props.type}
                    listing={this.props.listing}
                />
                <CommentsModal
                    modalOpen={this.state.modalOpen}
                    onModalClose={() => this.setState({ modalOpen: false })}
                    modalHeight={this.state.modalHeight}
                    title={'All Comments'}
                    comments={this.state.comments}
                />
                {/* {this.props.type !== SharedCountsType.BIG && (
                    <ButtonBookmarkItem
                        listingId={this.props.listingId}
                        bookmarked={this.props.saved}
                        inline
                    />
                )} */}
            </ShareCounts>
        );
    }
}

export default withTheme(ListingSharedCounts);

export const SharedCountsType = {
    NORMAL: 'normal',
    ANNOUNCEMENT: 'announcement',
    BIG: 'big',
};

ListingSharedCounts.propTypes = {
    type: PropTypes.string.isRequired,
    listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};
