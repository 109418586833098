import React, {Component, Fragment} from "react";
import SkeletonLoading, {SkeletonLoadingType} from "../SkeletonLoading";
import {InputTextSearchStyles, SelectedGroupsTag} from "../SharedListingGroup/Styled";
import {
    CheckBoxRowStyles, SearchProfileItem,
    SearchProfilesWrapper, InputTextWrapperSearchStyles
} from "./Styled";
import IconNew from "../IconNew";
import FormCheckBox from "../FormCheckBox";
import InputText from "../FormInputText";
import {sendAsyncRequest} from "../../../Utils";
import PropTypes from "prop-types";

class SearchMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchInputValue: '',
            profiles: [],
        };
    }

    componentDidMount() {
        sendAsyncRequest('/user-list', 'get', {}).then((result) => {
            if (result.status === 200) {
                this.setState({
                    profiles: result.body.profiles.map((result) => {
                        return {
                            show: true,
                            selected: false,
                            ...result,
                        };
                    }),
                });
            }
        });
    }

    searchChangeHandler = (e) => {
        const value = e.target.value;
        let profiles = [...this.state.profiles];
        profiles.forEach((profile) => {
            profile.show = profile.name
                .toLowerCase()
                .includes(value.toString().toLowerCase());
        });
        this.setState({
            searchInputValue: value,
            profiles
        });
    };

    profileRowClickHandler = (profileId) => {
        let profiles = [...this.state.profiles];
        profiles.forEach((profile) => {
            if (profile.id === profileId) {
                profile.selected = !profile.selected;
            }
        });
        this.setState({
            profiles
        });
        if (this.props.hasOwnProperty("memberSelect")) {
            this.props.memberSelect(
                profiles.filter(profile => profile.selected)
            );
        }
    };

    render() {
        return(
            <>
                <InputText
                    id="searchGroups"
                    name="searchGroups"
                    type="text"
                    styled={InputTextSearchStyles}
                    wrapperStyled={InputTextWrapperSearchStyles}
                    placeholder="Search"
                    required={true}
                    icon={
                        <IconNew
                            bp1="-407"
                            bp2="-159"
                            iwidth="25"
                            iheight="25"
                        />
                    }
                    onInput={this.searchChangeHandler}
                    value={this.state.searchInputValue}
                />

                <SelectedGroupsTag>
                    {this.state.profiles.map(
                        (profile) =>
                            profile.selected && (
                                <span
                                    key={profile.id}
                                    onClick={() =>
                                        this.profileRowClickHandler(profile.id)
                                    }
                                >
                                    {profile.name}
                                    <i />
                                </span>
                            )
                    )}
                </SelectedGroupsTag>

                <SearchProfilesWrapper>
                    {this.state.profiles.length === 0 && (
                        <SkeletonLoading
                            type={SkeletonLoadingType.GENERAL_ROWS}
                        />
                    )}

                    {this.state.profiles.length > 0 &&
                    this.state.profiles.map(
                        (profile, index) =>
                            profile.show && (
                                <Fragment key={this.props.id + index}>
                                    <SearchProfileItem
                                        vip={profile.vip}
                                        onClick={() =>
                                            this.profileRowClickHandler(
                                                profile.id
                                            )
                                        }
                                    >
                                        <img
                                            alt={'profile'}
                                            src={profile.img}
                                        />
                                        <p>
                                            {profile.name}
                                            <br/>
                                            <small>
                                                <IconNew
                                                    bp1="-172"
                                                    bp2="-115"
                                                    iwidth="9"
                                                    iheight="13"
                                                />
                                                {profile.location}
                                            </small>
                                        </p>
                                        <FormCheckBox
                                            id={"check-"+index}
                                            name={"check-"+index}
                                            styled={CheckBoxRowStyles}
                                            onChange={() =>
                                                this.profileRowClickHandler(
                                                    profile.id
                                                )
                                            }
                                            checked={profile.selected}
                                        />
                                    </SearchProfileItem>
                                </Fragment>
                            )
                    )}
                </SearchProfilesWrapper>
            </>
        )
    }
}

export default SearchMembers;

SearchMembers.propTypes = {
    id: PropTypes.string.isRequired
}