import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import GroupPage from '../Modules/GroupPage';
import { Main } from '../../styles/Styled';
import ProfileMenu from '../Modules/ProfileMenu';
import GroupAlbumGallery from '../Modules/GroupAlbumGallery';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';

class Group extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.innerHeader = false;

        this.state = {
            isButtonClicked: false,
            activeTabIndex: 0,
            dynamicTitle: '',
        };

        super.showHeader = true;
        this.ImageGallery = [
            {
                id: 0,
                photoNumber: '14',
                name: 'Main Album #1',
                photos: [
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.pinimg.com/originals/2f/82/39/2f823993ba069d0aa966144e6f20d459.jpg',
                        likes: 34,
                        description: 'somewhereeee',
                        id: 1,
                    },
                    {
                        image:
                            'https://archello.com/thumbs/images/2019/05/23/Modern-Classic-House-Design-2.1558569756.4266.jpg?fit=crop&w=414&h=518',
                        likes: 145,
                        description: 'califaroniaaa',
                        id: 3,
                    },
                    {
                        image:
                            'https://agenda.ge/files/news/026/tbilisi-old-town-house-restored.jpg',
                        likes: 34,
                        description: 'somewhereeee',
                        id: 4,
                    },
                    {
                        image:
                            'https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                        likes: 34,
                        description: 'somewhereeee',
                        id: 4,
                    },
                    {
                        image:
                            'https://image.shutterstock.com/image-illustration/house-3d-rendering-260nw-566451109.jpg',
                        likes: 34,
                        description: 'somewhereeee',
                        id: 5,
                    },
                    {
                        image:
                            'https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                        likes: 34,
                        description: 'somewhereeee',
                        id: 6,
                    },
                    {
                        image:
                            'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSeXhRCusQRe2qcq0psB4noTljMSUk3N1Kw4A&usqp=CAU',
                        likes: 34,
                        description: 'somewhereeee',
                        id: 7,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        id: 8,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
            {
                id: 1,
                photoNumber: '10',
                name: 'Main Album #2',
                photos: [
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 71,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 25,
                        description: 'brooklyn, blabalba',
                        id: 1,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 2,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 1,
                        description: 'brooklyn, blabalba',
                        id: 3,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 4,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 0,
                        description: 'brooklyn, blabalba',
                        id: 5,
                    },
                    {
                        image:
                            'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',
                        likes: 75,
                        description: 'brooklyn, blabalba',
                        id: 0,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 6,
                    },
                    {
                        image:
                            'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',
                        likes: 5,
                        description: 'brooklyn, blabalba',
                        id: 7,
                    },
                ],
            },
        ];
    }

    handleButtonClick = (index, title) => {
        let isButtonClicked = !this.state.isButtonClicked;
        this.setState({
            isButtonClicked,
            activeTabIndex: index,
            dynamicTitle: title,
        });
    };

    galleryTabClickHandler = (Tabindex, tabtitle) => {
        this.setState({
            activeTabIndex: Tabindex,
            dynamicTitle: tabtitle,
        });
    };

    renderBody() {
        if (this.state.isButtonClicked) {
            return (
                <GroupAlbumGallery
                    imageGallery={this.ImageGallery}
                    dynamicTitle={this.state.dynamicTitle}
                    activeTabIndex={this.state.activeTabIndex}
                    defaultIndex={this.state.activeTabIndex}
                    handleButtonClick={this.handleButtonClick}
                />
            );
        } else {
            return (
                <Main backgrounded>
                    {roadMapStore.getShowRoadMap() ? (
                        <BuildNetworkMain />
                    ) : (
                        <GroupPage
                            activeTabIndex={this.state.activeTabIndex}
                            imageGallery={this.ImageGallery}
                            handleButtonClick={this.handleButtonClick}
                        />
                    )}

                    <ProfileMenu closed />
                </Main>
            );
        }
    }
}

export default observer(Group);
