import React, { Component } from "react";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { 
  StyledSlider
} from './Styled'

export default class SlickCarouselComponent extends Component {
  render() {
    const { settings } = this.props;
    return (
        <StyledSlider containerStyle={this.props.containerStyle} styles={this.props.styles} {...settings}>
            {this.props.children}
        </StyledSlider>
    );
  }
}