import React, { Component } from 'react';
import { Column } from '../../../styles/Styled';
import GroupTypeTitle from '../GroupTypeTitle';
import { ButtonWrapper, Image, Right, Wrapper } from '../GroupPage/Styled';
import HeaderInfo from '../ProfileGroupTypeHeaderInfo';
import Button, { ButtonFollowConnectType } from '../ButtonFollowConnect';
import ActionButtons from '../ProfileGroupTypeActionButtons';
import { ClearFloats } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';
import { ImageGallery } from './temporaryData'
import {
    ProfileImageWrapper,
    TextSection, 
    About,
    GalleryCardWrapper,
    GalleryWrapper,
    CardsWrapper,
    GalleryHeader,
    ImgStyle,
    ImgWrapper,
    ExtendButton,
    ArrowRightMore,
    } from './Styled';
import { AgentCountries } from '../ListingDetailPage/Styled';
import SocialIcons from '../ProfileGroupTypeSocialIcons';
import InfoBox, { ContentType } from '../ProfileGroupTypeInfoBox';
import Stats from '../ProfileGroupTypeStats';
import SimilarListings from '../SimilarListings';
import ProfilePageContactForm from '../ProfilePageContactForm';
import { Link } from 'react-router-dom';
//import { sendAsyncRequest } from '../../../Utils';
import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';
import {ArrowDown} from '../BaseTemplate/Styled';

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            charsToShow: 90,
            visibleGallery: 2,
            hideExtendButton: false,
        };
        this.fakeProfile = {
            pk: 1,
            name: 'Jose Pearson',
            image: '/serverImages/profile_pic_sample.png',
            address: '2571 Wallingford Dr, Beverly Hills, CA 90210',
            tagLink: '@stevie.h',
            mergeLink: 'steviehahn.com',
            premium: true,
            verified: true,
            following: 32,
            followers: 12300,
            connections: 982,
            properties: 1234,
            expertise: ['Residential', 'For Sale'],
            brokerage: 'Keller Williams Realty Brooklyn',
            about: 'Property Agents on NY',
            about_more:
                'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaawqe',
            ceo_of: '@brookproperty',
            images: [
                '/serverImages/property_detail_image_sample_1.jpg',
                '/serverImages/images/property_detail_image_sample_2.jpg',
                '/serverImages/property_detail_image_sample_3.jpg',
                '/serverImages/property_detail_image_sample_4.jpg',
                '/serverImages/property_detail_image_sample_1.jpg',
                '/serverImages/property_detail_image_sample_2.jpg',
                '/serverImages/property_detail_image_sample_3.jpg',
                '/serverImages/property_detail_image_sample_4.jpg',
            ],
            information: {
                area:
                    'Trousdale Estates, Century City, The Flats, The Bird Streets, NY11203, NY 99593 ...',
                membership: [
                    '/serverImages/group_type_memberships_sample.png',
                    '/serverImages/group_type_memberships_sample.png',
                    '/serverImages/group_type_memberships_sample.png',
                    '/serverImages/group_type_memberships_sample.png',
                    '/serverImages/group_type_memberships_sample.png',
                ],
                certificates: [
                    '/serverImages/group_type_certificate_sample.png',
                    '/serverImages/group_type_certificate_sample.png',
                    '/serverImages/group_type_certificate_sample.png',
                ],
                badges: [
                    '/serverImages/group_type_group_badge_sample.png',
                    '/serverImages/group_type_group_badge_sample.png',
                    '/serverImagess/group_type_group_badge_sample.png',
                    '/serverImages/group_type_group_badge_sample.png',
                    '/serverImages/group_type_group_badge_sample.png',
                ],
            },
            similar_listings: [
                {
                    type: 'normal', // EXTRA
                    pk: 1234,
                    owner: {
                        profile: '/serverImages/team_gallery_sample_1.png',
                        username: 'dave.someone',
                        full_name: 'Eugene Henderson', // EXTRA
                        url: 'dave', // EXTRA
                    },
                    description:
                        'Quiet and secluded, 2 bedroom, 3 bathroom, townhouse in the highly sought after Cabrini Villas in Burbank',
                    street: 'Little Falls, MN, 563452',
                    add_date: '21h ago',
                    images: [
                        '/serverImages/property_detail_image_sample_1.jpg',
                        '/serverImages/property_detail_image_sample_2.jpg',
                        '/serverImages/property_detail_image_sample_3.jpg',
                        '/serverImages/property_detail_image_sample_4.jpg',
                    ],
                    liked: true,
                    saved: false,
                    total_comments: 10,
                    total_likes: 5,
                    bedrooms: 2,
                    bathrooms: 3, // EXTRA
                    for: 'Sale', // EXTRA
                    bedBathDirection: 'North St', // EXTRA
                    can_comment: true,
                    can_share: true,
                    slug: 'sample.slug',
                    tags: [1, 2, 3], // EXTRA
                    comments: [
                        {
                            // Extra
                            pk: 1,
                            listing_id: 1234,
                            text:
                                'Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue',
                            image: '/serverImages/team_gallery_sample_1.png',
                            owner: 'Louis Hughes',
                            add_date: 1597235243200,
                            comment_total_likes: '10',
                            replies_count: '5',
                            like: '3',
                        },
                    ],
                },
                {
                    type: 'announcement', // EXTRA
                    title: 'New office open in LA', // EXTRA
                    pk: 5678,
                    owner: {
                        profile: '/serverImages/team_gallery_sample_2.png',
                        username: 'dave.someone',
                        full_name: 'Vincent Collins', // EXTRA
                        url: 'dave', // EXTRA
                    },
                    description:
                        'Quiet and secluded, 2 bedroom, 3 bathroom, townhouse in the highly sought after Cabrini Villas in Burbank Quiet and secluded, 2 bedroom, 3 bathroom, townhouse in the highly sought after Cabrini Villas in Burbank',
                    street: 'Little Falls, MN, 56345',
                    add_date: 1597235243200,
                    images: [
                        '/serverImages/property_detail_image_sample_1.jpg',
                        '/serverImages/property_detail_image_sample_2.jpg',
                        '/serverImages/property_detail_image_sample_3.jpg',
                        '/serverImages/property_detail_image_sample_4.jpg',
                    ],
                    liked: true,
                    saved: false,
                    total_comments: 10,
                    total_likes: 5,
                    bedrooms: 2,
                    bathrooms: 3, // EXTRA
                    for: 'Rent', // EXTRA
                    bedBathDirection: 'North St', // EXTRA
                    can_comment: true,
                    can_share: true,
                    slug: 'sample.slug',
                },
                {
                    type: 'sponsor', // EXTRA
                    pk: 9012,
                    owner: {
                        profile: '/serverImages/team_gallery_sample_3.png',
                        username: 'dave.someone',
                        full_name: 'Vincent Collins', // EXTRA
                        url: 'dave', // EXTRA
                    },
                    description:
                        'Quiet and secluded, 2 bedroom, 3 bathroom, townhouse in the highly sought after Cabrini Villas in Burbank',
                    street: 'Little Falls, MN, 56345',
                    add_date: 1597235243200,
                    images: [
                        '/serverImages/property_detail_image_sample_1.jpg',
                        '/serverImages/property_detail_image_sample_2.jpg',
                        '/serverImages/property_detail_image_sample_3.jpg',
                        '/serverImages/property_detail_image_sample_4.jpg',
                    ],
                    liked: true,
                    saved: false,
                    total_comments: 10,
                    total_likes: 5,
                    bedrooms: 2,
                    bathrooms: 3, // EXTRA
                    for: 'Rent', // EXTRA
                    bedBathDirection: 'North St', // EXTRA
                    can_comment: true,
                    can_share: true,
                    slug: 'sample.slug',
                    tags: [2, 3], // EXTRA
                    comments: [
                        {
                            // Extra
                            pk: 1,
                            listing_id: 9012,
                            text:
                                'Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue',
                            image: '/serverImages/team_gallery_sample_1.png',
                            owner: 'Louis Hughes',
                            add_date: 1597235243200,
                            comment_total_likes: '10',
                            replies_count: '5',
                            like: '3',
                        },
                    ],
                },
            ],
        };
    }

    componentDidMount() {
        this.setState({ profile: this.fakeProfile });
    }

    extendButtonHandler = () => {
        this.setState({
            visibleGallery: ImageGallery.length,
            hideExtendButton: true,
        });
    };

    render() {
        const profile = { ...this.state.profile };
        if (!profile.pk) {
            return <SkeletonLoading type={SkeletonLoadingType.PROFILE_PAGE} />;
        }
        return (
            <Wrapper>
                <GroupTypeTitle>Real Estate Agent</GroupTypeTitle>

                <Column type="two_column" first>
                    <ProfileImageWrapper>
                        <Image src={profile.image} />
                        <AgentCountries>
                            <IconNew
                                bp1="-507"
                                bp2="-511"
                                iwidth="23"
                                iheight="23"
                            />
                            <IconNew
                                bp1="-532"
                                bp2="-511"
                                iwidth="23"
                                iheight="23"
                            />
                        </AgentCountries>
                    </ProfileImageWrapper>

                    <Right>
                        <HeaderInfo
                            groupTitle={profile.name}
                            groupAddress={profile.address}
                            groupTagLink={profile.tagLink}
                            groupMergeLink={profile.mergeLink}
                            goldBadge={profile.premium}
                            blueBadge={profile.verified}
                        />
                        <ButtonWrapper>
                            <Button type={ButtonFollowConnectType.FOLLOW} />
                            <Button
                                type={ButtonFollowConnectType.CONNECT}
                                active
                            />
                            <ActionButtons contactInfo />
                        </ButtonWrapper>
                    </Right>

                    <Column type="one_column">
                        <ProfilePageContactForm />
                    </Column>

                    <Column type="one_column" noMargin>
                        <Stats
                            noLine
                            items={[
                                { name: 'Following', count: profile.following },
                                { name: 'Followers', count: profile.followers },
                                {
                                    name: 'Connections',
                                    count: profile.connections,
                                },
                                {
                                    name: 'Properties',
                                    count: profile.properties,
                                },
                            ]}
                        />

                        <TextSection>
                            <p>
                                <strong>Expertise: </strong>
                                {profile.expertise.join(', ')}
                            </p>
                            <p>
                                <strong>Brokerage: </strong>
                                {profile.brokerage}
                            </p>
                            <div>
                                <strong>About me: </strong>
                                <br />
                                {profile.about}
                                <br />
                                CEO of: <Link to="#">{profile.ceo_of}</Link>
                                <br />
                                <About height={this.state.charsToShow}>
                                <h6>{profile.about_more}</h6>
                                {profile.about_more.length >
                                    this.state.charsToShow && (
                                    <span
                                        onClick={() =>
                                            this.setState((prevState) => {
                                                return {
                                                    charsToShow:
                                                        prevState.charsToShow +
                                                        90,
                                                };
                                            })
                                        }
                                    >
                                        <ArrowDown />
                                        MORE
                                    </span>
                                )}
                                </About>
                            </div>
                        </TextSection>
                        <GalleryWrapper>
                                    <GalleryHeader>Photo Gallery</GalleryHeader>
                                    <CardsWrapper
                                        extended={
                                            this.state.visibleGallery ===
                                            ImageGallery.length
                                        }
                                    >
                                        {this.props.imageGallery
                                            .slice(0, this.state.visibleGallery)
                                            .map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <GalleryCardWrapper
                                                        firstItem ={index === 0}
                                                        everySeventh={(index % 3) === 0}
                                                        key={item.id}
                                                        onClick={() =>
                                                            this.props.handleButtonClick(
                                                                index,
                                                                item.name
                                                            )
                                                        }
                                                    >
                                                        {item.photos
                                                            .slice(0, 4)
                                                            .map(
                                                                (
                                                                    image,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <ImgStyle
                                                                        >
                                                                            <span
                                                                            >
                                                                                {
                                                                                    item.photoNumber
                                                                                }
                                                                            </span>
                                                                        </ImgStyle>
                                                                        <ImgWrapper
                                                                            key={
                                                                                index
                                                                            }
                                                                            src={
                                                                                image.image
                                                                            }
                                                                            alt="property"
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                    </GalleryCardWrapper>
                                                </React.Fragment>
                                            ))}
                                        {!this.state.hideExtendButton && (
                                            <ExtendButton
                                                onClick={
                                                    this.extendButtonHandler
                                                }
                                            >
                                                <span>
                                                    {ImageGallery.length - 2}{' '}
                                                    <ArrowRightMore />{' '}
                                                </span>
                                                <span className="textStyle">
                                                    MORE
                                                </span>
                                            </ExtendButton>
                                        )}
                                    </CardsWrapper>
                                </GalleryWrapper>
                    </Column>
                </Column>

                <Column type="one_column">
                    <SocialIcons
                        items={[
                            {
                                to: '#',
                                icon: (
                                    <IconNew
                                        bp1="-521"
                                        bp2="-358"
                                        iwidth="34"
                                        iheight="34"
                                    />
                                ),
                            },
                            {
                                to: '#',
                                icon: (
                                    <IconNew
                                        bp1="-449"
                                        bp2="-358"
                                        iwidth="34"
                                        iheight="34"
                                    />
                                ),
                            },
                            {
                                to: '#',
                                icon: (
                                    <IconNew
                                        bp1="-426"
                                        bp2="-358"
                                        iwidth="22"
                                        iheight="34"
                                    />
                                ),
                            },
                            {
                                to: '#',
                                icon: (
                                    <IconNew
                                        bp1="-485"
                                        bp2="-358"
                                        iwidth="34"
                                        iheight="34"
                                    />
                                ),
                            },
                        ]}
                    />

                    <ClearFloats />

                    {!!profile.information.area && (
                        <InfoBox
                            closed={true}
                            title="Area"
                            titleButton="Map"
                            contentType={ContentType.TEXT}
                            content={profile.information.area}
                        />
                    )}

                    {!!profile.information.membership && (
                        <InfoBox
                            title="Membership"
                            subTitle="(Groups)"
                            contentType={ContentType.IMAGE}
                            content={profile.information.membership}
                        />
                    )}

                    {!!profile.information.certificates && (
                        <InfoBox
                            title="Certificates"
                            contentType={ContentType.IMAGE_SMALL}
                            content={profile.information.certificates}
                        />
                    )}

                    {!!profile.information.badges && (
                        <InfoBox
                            title="Trophies"
                            contentType={ContentType.IMAGE_BADGE}
                            content={profile.information.badges}
                        />
                    )}
                </Column>

                <ClearFloats />

                <SimilarListings listings={profile.similar_listings} />
            </Wrapper>
        );
    }
}

export default ProfilePage;
