import React, {Component} from "react";
import {Wrapper} from "./Styled";
import WizardItemExpandBox from "../WizardItemExpandBox";

class FormGroupType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radioValue: {}
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && (prevProps.value !== this.props.value)) {
            this.setState({
                radioValue: this.props.value
            });
        }
    }

    itemChangeHandler = (item) => {
        const states = this.props.states,
              radioValue = item,
              fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                    fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                    errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[fieldName] = radioValue;
                errorFields[fieldName] = null;
                this.setState({
                    radioValue
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {

        if (!this.props.list) {
            return null;
        }

        return (
            <Wrapper>
                {
                    this.props.list.map(
                        item =>
                            <WizardItemExpandBox
                                key={item.value}
                                id={this.props.id + "_" + item.value}
                                name={this.props.id}
                                title={item.title}
                                note={item.note}
                                noteText={item.noteText}
                                isExpand={false}
                                checked={
                                    (this.state.radioValue) &&
                                    (this.state.radioValue.value === item.value)
                                }
                                onChange={
                                    () => this.itemChangeHandler(item)
                                }
                            />
                    )
                }
            </Wrapper>
        )
    }
}

export default FormGroupType;