import React, {Component, Fragment} from 'react';
import {Images, ModalScrollable} from "./Styled";
import {ClearFloats} from "../BaseTemplate/Styled";
import {ModalWrapper} from "../SettingsAreaOfInterest/Styled";
import Modal from "../Modal";
import ImageItemAndMore from "../ImageItemAndMore";

class ProfileGroupTypeImageGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };
    }

    toggleModal = () => {
        // It is more safe to use prevState instead of direct use
        // Because setState runs ASYNC
        this.setState((prevState, props) => {
            return {isModalOpen: !prevState.isModalOpen};
        });
    };

    render() {

        // Mock Data (For Modal)
        const mockArr = [1, 2, 3, 4, 5, 6],
              imgLength = (this.props.images) ? this.props.images.length : 0,
              isModalScroll = imgLength * mockArr.length > 15;

        return (
            <Fragment>
                <Images>
                    <h2>{this.props.title}</h2>
                    {
                        (Array.isArray(this.props.images)) &&
                        this.props.images.map(
                            (image, index) => {
                                let more = '';
                                const isLastItem = (this.props.images.length === (index + 1));
                                if (this.props.count > parseInt(this.props.max) && isLastItem) {
                                    more =
                                        <ImageItemAndMore
                                            key={'more-'+image.id}
                                            type={this.props.type}
                                            onClick={this.toggleModal}
                                            imgCount={this.props.count}
                                            imgMax={this.props.max}
                                        />
                                }
                                return (
                                    <Fragment key={image.id}>
                                        <ImageItemAndMore
                                            key={image.id}
                                            type={this.props.type}
                                            image={image.img}
                                            name={image.fullName}
                                            subName={image.role}
                                            gearBadge={(image.gearBadge)}
                                            userBadge={(image.userBadge)}
                                        />
                                        {
                                            more
                                        }
                                        {
                                            (isLastItem) && <ClearFloats />
                                        }
                                    </Fragment>
                                );
                            }
                        )
                    }
                </Images>

                <Modal
                    title={this.props.title}
                    isOpen={this.state.isModalOpen}
                    handleClose={this.toggleModal}
                    containerWidth={isModalScroll ? '730' : '710'}
                    containerSizeUnit="px" containerPadding="20"
                >
                    <ModalWrapper>
                        <ModalScrollable isScroll={isModalScroll}>
                            {
                                mockArr.map(
                                    (item, idx) => {
                                        const isLastItem = (mockArr.length === (idx + 1));
                                        return (
                                            <Fragment key={'root-'+idx}>
                                                {
                                                    (this.props.images) &&
                                                    this.props.images.map(
                                                        (image, index) => {
                                                            // const isLastItem = (this.props.images.length === (index + 1));
                                                            return (
                                                                <Fragment
                                                                    key={image.id+'-'+idx+'--'+index}
                                                                >
                                                                    <ImageItemAndMore
                                                                        className="in-modal"
                                                                        image={image.img}
                                                                        name={image.fullName}
                                                                        gearBadge={(image.gearBadge)}
                                                                        userBadge={(image.userBadge)}
                                                                    />
                                                                    {/* TODO :: Remove Comment After Removing Mock Data
                                                                    (isLastItem) ? <ClearFloats/> : '' */}
                                                                </Fragment>
                                                            );
                                                        }
                                                    )
                                                }
                                                {
                                                    (isLastItem) ? <ClearFloats /> : ''
                                                }
                                            </Fragment>
                                        );
                                    }
                                )
                            }
                        </ModalScrollable>
                    </ModalWrapper>
                </Modal>
            </Fragment>
        );
    }
}

export default ProfileGroupTypeImageGroup;