import React, {Component, Fragment} from 'react';
import {
    Wrapper,
    ButtonTextColoredStyles,
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    InputTextWrapperStyles,
} from "../LoginForm/Styled";
import {Link, withRouter} from 'react-router-dom';
import {
    getInputNameAndValue,
    __,
    isEmail,
    isMobile,
    mobileE164,
    publicRouteRule,
    checkPasswordValidity
} from '../../../Utils';
import {ErrorMessage, FormBox, InfoParagraph, InnerBox, InputTextWrapperRegisterStyles} from "../RegisterForm/Styled";
import InputText from "../FormInputText";
import ButtonText from "../FormButtonText";
import {Auth} from "aws-amplify";
import Config from "../../../config";

export class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fields: {},
            errorFields: {},
            hashedEmail: '',
            box: [0, 1, 2] // 3 Steps
        };
        this.pathNames = {
            home: Config.ROUTES.forgetPassword.root,
            confirm: Config.ROUTES.forgetPassword.confirm,
            changed: Config.ROUTES.forgetPassword.changed
        };
    }

    componentDidMount() {
        (async () => {
            await publicRouteRule(this);
        })();
        this.unregisterHistoryListener = this.props.history.listen((location, action) => {
            if (action === 'PUSH') {
                switch (location.pathname) {
                    case this.pathNames.home:
                        this.setState({
                            box: [0, 1, 2]
                        });
                        break;
                    case this.pathNames.confirm:
                        this.setState({
                            box: [-1, 0, 1]
                        });
                        break;
                    case this.pathNames.changed:
                        this.setState({
                            box: [-2, -1, 0]
                        });
                        break;
                    default:
                        this.setState({
                            box: [0, 1, 2]
                        });
                        break;
                }
            } else if (action === 'POP') {
                this.setState({
                    box: [0, 1, 2]
                });
            }
        });
    }

    componentWillUnmount() {
        this.unregisterHistoryListener();
    }

    // Should be Normal Function for Unit Testing
    async handleForgetPasswordClick() {

        let email = this.state.fields['email'];

        if (!isEmail(email)) {
            this.setErrorFields('email', 'Please enter a valid email address.');
            return false;
        }

        this.setState({isLoading: true});

        try {
            const result = await Auth.forgotPassword(
                email
            );
            console.log('Forget Password Successfully Sent', result);
            this.setState({
                hashedEmail: result.CodeDeliveryDetails.Destination
            }, () => {
                this.props.history.push(
                    this.pathNames.confirm
                );
            });
        } catch (error) {
            console.log('Forget Password ERROR', error.message);
            this.setErrorFields('step1', 'This email address is not registered.');
        }

        this.setState({isLoading: false});
    };

    handleForgetPasswordConfirmClick = async () => {
        let email = this.state.fields['email'];
        const validationCode = this.state.fields['validationCode'],
              newPass = this.state.fields['newPass'];

        if (!validationCode) {
            this.setErrorFields('validationCode', 'Please enter the confirmation code which is sent to you.');
        }
        if (!newPass) {
            this.setErrorFields('newPass', 'Please enter your new password.');
        }
        if (!validationCode || !newPass) {
            return false;
        }
        let passState = checkPasswordValidity(newPass);
        if( !passState.status ){
            this.setErrorFields('newPass', passState.message);
            return false;
        }

        if (isMobile(email)) {
            email = mobileE164(email);
        }

        this.setState({isLoading: true});

        try {
            const result = await Auth.forgotPasswordSubmit(
                email,
                validationCode,
                newPass
            );
            console.log('Forgot Password Successfully Submitted', result);
            this.props.history.push(
                this.pathNames.changed
            );
        } catch (error) {
            console.log('Forget Password Submitted ERROR', error.message);
            if (error.message && error.message.toLowerCase().includes('password')) {
                this.setErrorFields('newPass', error.message.substring(error.message.lastIndexOf(":") + 1));
            } else if (error.message && error.message.includes('verification code')) {
                this.setErrorFields('validationCode', error.message);
            } else {
                if(error.message === 'Invalid code provided, please request a code again') {
                    this.setErrorFields('step2', 'The verification code you entered is not valid. Please check the code and try again.');
                } else {
                    this.setErrorFields('step2', error.message);
                }
            }
        }

        this.setState({isLoading: false});
    };

    setErrorFields = (field, errMessage) => {
        const errorFields = {...this.state.errorFields};
        errorFields[field] = errMessage;
        this.setState({
            errorFields
        });
    };

    handleInputChange = (event) => {
        const inputData = getInputNameAndValue(event),
              fields = {...this.state.fields},
              errorFields = {...this.state.errorFields};
        fields[inputData.name] = inputData.value;
        errorFields[inputData.name] = null;
        this.setState({
            fields,
            errorFields
        });
    };

    render() {
        return (
            <Wrapper>

                <FormBox>

                    <InnerBox step={this.state.box[0]}>

                        <Fragment>

                            <InputText
                                id="email"
                                type="text"
                                name="email"
                                errorMessage={this.state.errorFields['email']}
                                onChange={this.handleInputChange}
                                onEnter={this.handleForgetPasswordClick.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperStyles}
                                labelAnimate={__('email', 'Email')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={InputLabelAfterAnimateStyles}
                                required={true}
                            />

                            {
                                (this.state.errorFields['step1']) &&
                                <ErrorMessage>
                                    {
                                        this.state.errorFields['step1']
                                    }
                                </ErrorMessage>
                            }

                            <ButtonText
                                onClick={this.handleForgetPasswordClick.bind(this)}
                                isLoading={this.state.isLoading}
                                styled={ButtonTextColoredStyles}
                            >
                                {__('set_new_pass', 'Reset Password')}
                            </ButtonText>

                        </Fragment>

                    </InnerBox>

                    <InnerBox step={this.state.box[1]}>

                        <Fragment>

                            <InfoParagraph>
                                <strong>We just sent you a verification code</strong>
                                <br/>We just sent a verification code to <b>{this.state.hashedEmail}</b>
                                <br/>Follow the instructions sent to your email address and reset your password.
                            </InfoParagraph>

                            <InputText
                                id="validationCode"
                                type="text"
                                name="validationCode"
                                errorMessage={this.state.errorFields['validationCode']}
                                onChange={this.handleInputChange}
                                onEnter={this.handleForgetPasswordClick.bind(this)}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperStyles}
                                labelAnimate={__('validationCode', 'Verification Code')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={InputLabelAfterAnimateStyles}
                                required={true}
                            />

                            <InputText
                                id="newPass"
                                type="password"
                                name="newPass"
                                errorMessage={this.state.errorFields['newPass']}
                                onChange={this.handleInputChange}
                                onEnter={this.handleSignUpClick}
                                styled={InputTextStyles}
                                wrapperStyled={InputTextWrapperRegisterStyles}
                                showPassword
                                labelAnimate={__('newPass', 'New Password')}
                                labelAnimateStyled={InputLabelAnimateStyles}
                                labelAfterAnimateStyled={InputLabelAfterAnimateStyles}
                                required={true}
                            />

                            {
                                (this.state.errorFields['step2']) &&
                                <ErrorMessage>
                                    {
                                        this.state.errorFields['step2']
                                    }
                                </ErrorMessage>
                            }

                            <ButtonText
                                onClick={this.handleForgetPasswordConfirmClick}
                                isLoading={this.state.isLoading}
                                styled={ButtonTextColoredStyles}
                            >
                                {__('confirm', 'Confirm')}
                            </ButtonText>

                        </Fragment>

                    </InnerBox>

                    <InnerBox step={this.state.box[2]}>

                        <Fragment>

                            <InfoParagraph>
                                <br/><br/>
                                <strong>Your password has been changed successfully.</strong>
                                <br/>Please <Link to={Config.ROUTES.login}>sign in</Link> again to access your account.
                            </InfoParagraph>

                        </Fragment>

                    </InnerBox>

                </FormBox>

            </Wrapper>
        );
    }
}

export default withRouter(ForgotPasswordForm);