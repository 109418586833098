import styled, {css} from "styled-components";
import {IconButton} from "../../../styles/Styled";

export const Wrapper = styled.div``;

export const InfoText = styled.p`
    margin: 20px 0 20px;
    font-size: 18px;
    font-weight: lighter;
    color: ${props => props.theme.textBlack};
    text-align: center;

    @media ${props => props.theme.mediaHD} {
        font-size: 18px;
        margin: 20px 0 20px;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 18px;
        margin: 20px 0 10px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 18px;
        margin: 20px 0 10px;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 16px;
        margin: 20px 0 5px;
    }
`;

export const LoginSocialWrapper = styled.div`
    text-align: center;
`;

export const SocialButton = styled(IconButton)`
    display: inline-block;
    width: 65px; height: 65px;
    margin: 0 17px;
    border-radius: 100%;
    padding-top: 5px;
    &.twitter {
        background: ${props => props.theme.cyan};
    }
    &.google {
        background: ${props => props.theme.googleRed};
    }
    &.facebook {
        background: ${props => props.theme.facebookBlue};
    }

    @media ${props => props.theme.mediaHD} {
        width: 48px; height: 48px;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        width: 48px; height: 48px;
    }
    @media ${props => props.theme.mediaTablet} {
        width: 48px; height: 48px;
    }
    @media ${props => props.theme.mediaMobile} {
        width: 47px; height: 47px;
    }
`;

export const InputTextWrapperStyles = css`
    margin-bottom: 65px;
    & > div {
        font-size: 19px;
        color: ${props => props.theme.statusGreen};
    }
    & > a {
        position: absolute;
        top: 0; right: 20px;
        color: ${props => props.theme.textBlack};
        font-size: 22px;
    }

    @media ${(props) => props.theme.mediaHD} {
        & > a {
            font-size: 18px;
        }
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        margin-bottom: 35px;
        & > a {
            font-size: 14px;
        }
    }
    @media ${props => props.theme.mediaTablet} {
        margin-bottom: 35px;
        & > a {
            font-size: 14px;
        }
    }
    @media ${props => props.theme.mediaMobile} {
        margin-bottom: 35px;
        & > a {
            font-size: 14px;
        }
    }
`;

export const InputTextStyles = css`
    width: 100%; height: 45px;
    font-family: ${props => props.theme.mainFont};
    font-size: 18px;
    line-height: 22px;
    color: ${props => props.theme.textBlack};
    border: none;
    border-bottom: solid 1px ${props => props.theme.textGrayLight};
    padding: 0 0 12px;
    background: transparent;
    &:focus {
        border-bottom-color: ${props => props.theme.cyan};
    }
    &::placeholder {
        color: ${props => props.theme.mainFontColor};
    }

    @media ${props => props.theme.mediaTabletLandscape} {
        height: 30px;
        font-size: 18px;
        line-height: 19px;
    }
    @media ${props => props.theme.mediaTablet} {
        height: 30px;
        font-size: 18px;
        line-height: 19px;
    }
    @media ${props => props.theme.mediaMobile} {
        height: 30px;
        font-size: 14px;
        line-height: 15px;
    }
`;

export const InputLabelAnimateStyles = css`
    font-size: 18px;
    font-weight: lighter;

    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 18px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 18px;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 14px;
        bottom: 35px;
    }
`;
export const InputLabelAfterAnimateStyles = css`
    font-size: 15px;
    bottom: 70px;

    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 12px;
        bottom: 60px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 12px;
        bottom: 60px;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;
        bottom: 60px;
    }
`;

export const ButtonTextColoredStyles = css`
    width: 100%; height: 80px;
    font-size: 24px;
    padding-bottom: 6px;
    border-radius: 5px;
    color: #fff;
    border: none;
    background: ${props => props.theme.cyan};
    background: linear-gradient(90deg,
        ${props => props.theme.cyan} 0%,
        ${props => props.theme.ceruleanBlue} 100%
    );

    @media ${props => props.theme.mediaHD} {
        height: 60px;
        font-size: 21px;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        height: 60px;
        font-size: 18px;
    }
    @media ${props => props.theme.mediaTablet} {
        height: 60px;
        font-size: 18px;
    }
    @media ${props => props.theme.mediaMobile} {
        height: 50px;
        font-size: 16px;
    }
`;

export const ButtonTextWhiteStyles = css`
    width: 100%; height: 80px;
    font-size: 24px;
    padding-bottom: 6px;
    border-radius: 5px;
    color: ${props => props.theme.textBlack};
    border: solid 2px ${props => props.theme.textGrayLight};
    background: #fff;
    margin-top: 15px;

    @media ${props => props.theme.mediaHD} {
        height: 60px;
        font-size: 21px;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        height: 60px;
        font-size: 18px;
    }
    @media ${props => props.theme.mediaTablet} {
        height: 60px;
        font-size: 18px;
    }
    @media ${props => props.theme.mediaMobile} {
        height: 50px;
        font-size: 16px;
    }
`;