import styled from 'styled-components';

export const ProfileDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25%;

    span {
        font-size: 14px;
        color: ${(props) => props.theme.textBlack};
        font-family: 'Montserrat';
    }

    span.DetailType {
        font-weight: lighter;
        font-size: 12px;
        color: ${(props) => props.theme.mainFontColor};
        font-family: 'Montserrat';
    }
`;