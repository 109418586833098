import React, { Component } from 'react';
import {
    Wrapper,
    ModalList,
    ModalListItem,
    ModalCloseStyles,
    ModalTitleStyles,
    ModalShareItem,
} from './Styled';
import IconNew from '../IconNew';
import { SharedCountsType } from '../../ListingSharedCounts';
import PropTypes from 'prop-types';
import { ModalWrapper, ModalWrapperContent } from '../Modal/Styled';
import Modal from '../Modal';
import SharedListingGroup from '../SharedListingGroup';
import SharedListingProfile from '../SharedListingProfile';
import SharedListingMessage from '../SharedListingMessage';

class SharedListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareModalOpen: false,
            selectedItem: {
                id: -1,
            },
        };
    }

    shareClickHandler = () => {
        this.setState((prevState) => {
            return {
                shareModalOpen: !prevState.shareModalOpen,
            };
        });
    };

    shareItemClickHandler = (item) => {
        this.setState({
            shareModalOpen: false,
            selectedItem: item,
        });
    };

    closeItemModal = () => {
        this.setState({
            selectedItem: {
                id: -1,
            },
        });
    };

    render() {
        const sharedItems = [
            {
                id: 1,
                text: 'Share in a group',
                title: 'Select Group',
                icon: (
                    <IconNew bp1="-523" bp2="-395" iwidth="32" iheight="30" />
                ),
            },
            {
                id: 2,
                text: 'Share in my profile',
                title: 'Share in my profile',
                icon: (
                    <IconNew bp1="-491" bp2="-397" iwidth="30" iheight="30" />
                ),
            },
            {
                id: 3,
                text: 'Share as a message',
                title: 'Share as a message',
                icon: (
                    <IconNew bp1="-459" bp2="-396" iwidth="30" iheight="30" />
                ),
            },
            {
                id: 4,
                text: 'Share to Facebook',
                title: 'Share to Facebook',
                icon: (
                    <IconNew bp1="-427" bp2="-394" iwidth="30" iheight="30" />
                ),
            },
            {
                id: 5,
                text: 'Share to Twitter',
                title: 'Share to Twitter',
                icon: (
                    <IconNew bp1="-393" bp2="-396" iwidth="32" iheight="30" />
                ),
            },
            {
                id: 6,
                text: 'Share via Email',
                title: 'Share via Email',
                icon: (
                    <IconNew bp1="-361" bp2="-397" iwidth="30" iheight="30" />
                ),
            },
            {
                id: 7,
                text: 'Copy Link',
                title: 'Copy Link',
                icon: (
                    <IconNew bp1="-333" bp2="-394" iwidth="26" iheight="30" />
                ),
            },
        ];

        let selectedItem;
        switch (this.state.selectedItem.id) {
            case 1:
                selectedItem = <SharedListingGroup />;
                break;
            case 2:
                selectedItem = (
                    <SharedListingProfile
                        type={this.props.type}
                        data={this.props.listing}
                    />
                );
                break;
            case 3:
                selectedItem = (
                    <SharedListingMessage
                        type={this.props.type}
                        data={this.props.listing}
                    />
                );
                break;
            default:
                selectedItem = null;
        }

        return (
            <>
                <Wrapper
                    type={this.props.type}
                    onClick={this.shareClickHandler}
                >
                    {this.props.type === SharedCountsType.BIG ? (
                        <IconNew
                            bp1="-75"
                            bp2="-206"
                            iwidth="24"
                            iheight="28"
                            className="share"
                        />
                    ) : (
                        <IconNew
                            bp1="-284"
                            bp2="-103"
                            iwidth="21"
                            iheight="22"
                            className="share"
                        />
                    )}
                </Wrapper>

                <Modal
                    isOpen={this.state.shareModalOpen}
                    handleClose={this.shareClickHandler}
                    containerWidth="450"
                    containerHeight="480"
                    containerSizeUnit="px"
                    containerPadding="0"
                    title="Share"
                    titleStyled={ModalTitleStyles}
                    closeStyled={ModalCloseStyles}
                >
                    <ModalWrapper>
                        <ModalWrapperContent margin="0">
                            <ModalList>
                                {sharedItems.map((item) => (
                                    <ModalListItem
                                        key={item.id}
                                        onClick={() => {
                                            this.shareItemClickHandler(item);
                                        }}
                                    >
                                        {item.icon}
                                        {item.text}
                                    </ModalListItem>
                                ))}
                            </ModalList>
                        </ModalWrapperContent>
                    </ModalWrapper>
                </Modal>

                <Modal
                    isOpen={this.state.selectedItem.id !== -1}
                    handleClose={this.closeItemModal}
                    containerWidth="450"
                    containerHeight="auto"
                    containerSizeUnit="px"
                    containerPadding="0"
                    title={this.state.selectedItem.title}
                    titleStyled={ModalTitleStyles}
                    closeStyled={ModalCloseStyles}
                >
                    <ModalWrapper>
                        <ModalWrapperContent margin="0">
                            <ModalShareItem>{selectedItem}</ModalShareItem>
                        </ModalWrapperContent>
                    </ModalWrapper>
                </Modal>
            </>
        );
    }
}

export default SharedListing;

SharedListing.propTypes = {
    type: PropTypes.string.isRequired,
};
