import React, {Component} from "react";
import {
    ButtonRadioCheckDocsStyles,
    CheckBoxGroupRow, CloseModalStyles,
    GroupInputTextModalStyles, GroupInputTextWrapperModalStyles,
    ModalRowWrapper,
    ModalTitleStyles,
    NumberBadge, Row,
    TableRowDocs
} from "./Styled";
import {ClearFloats} from "../BaseTemplate/Styled";
import {ModalWrapper, ModalWrapperContent} from "../Modal/Styled";
import {AddButtonModalStyles} from "../WizardPages/Styled";
import Modal from "../Modal";
import FormButtonRadioCheck from "../FormButtonRadioCheck";
import InputText from "../FormInputText";
import IconNew from "../IconNew";
import AddMLSButton from "../FormButtonText";
import {objIsEqual, sendAsyncRequest} from "../../../Utils";

class FormPrivilegedListing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            selectedItem: {},
            groupSearchFilterValue: ""
        }
        this.serverSpecialKey = "dataUrl";
        this.modalOptions = [];
    }

    componentDidMount() {
        this.props.list.forEach(
            item => {
                if (item.hasOwnProperty(this.serverSpecialKey)) {
                    sendAsyncRequest("/user-group-list", "get", {}).then(result => {
                        if (Array.isArray(result.body.data)) {
                            result.body.data.forEach(
                                opt => {
                                    opt.show = true;
                                    this.modalOptions.push(opt)
                                }
                            );
                        }
                    });
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && !objIsEqual(prevProps.value, this.props.value)) {
            this.setState({
                selectedItem: this.props.value
            });
        }
    }

    selectedGroupsChangedHandler = (group) => {
        const selectedItem = {...this.state.selectedItem},
            selectedOptions = [...selectedItem.options];
        if (selectedOptions.some(x => x.id === group.id)) {
            selectedOptions.splice(selectedOptions.findIndex(
                x => x.id === group.id
            ), 1);
        } else {
            selectedOptions.push(group);
        }
        // Copy "id" into "value" for Post Format
        selectedOptions.forEach(
            opt => opt.value = opt.id
        )
        selectedItem.options = selectedOptions;
        this.updateStates(selectedItem);
    };

    groupSearchFilterChangeHandler = (e) => {
        const value = e.target.value;
        this.modalOptions.forEach(
            group => {
                group.show = group.name.toLowerCase().includes(value.toString().toLowerCase());
            }
        );
        this.setState({
            groupSearchFilterValue: value
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    radioChangeHandler = (permission) => {
        if (permission.hasOwnProperty(this.serverSpecialKey)) {
            this.modalOptions.forEach(
                group => group.show = true
            );
            permission.options = this.state.selectedItem.options || [];
            this.setState({
                isModalOpen: true,
                groupSearchFilterValue: ""
            });
            this.updateStates(permission);
        } else {
            this.updateStates(permission);
        }
    }

    updateStates = (permission) => {
        const states = this.props.states,
            fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                    fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                    errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[fieldName] = permission;
                errorFields[fieldName] = null;
                this.setState({
                    selectedItem: permission
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {

        if (!this.props.list) {
            return null;
        }

        return (
            <Row>
                <TableRowDocs>
                    <div>{this.props.title}</div>
                    {
                        this.props.list.map(
                            permission => {
                                return (
                                    <div key={permission.value}>
                                        <FormButtonRadioCheck
                                            padding={this.props.padding}
                                            styled={ButtonRadioCheckDocsStyles}
                                            id={"radio_check_" + this.props.id + "_" + permission.value}
                                            name={"radio_check_" + this.props.id + "_" + permission.value}
                                            label={permission.text}
                                            checked={
                                                (this.state.selectedItem.hasOwnProperty("value")) &&
                                                (this.state.selectedItem.value === permission.value)
                                            }
                                            onClick={
                                                () => this.radioChangeHandler(permission)
                                            }
                                        />
                                        {
                                            (permission.hasOwnProperty(this.serverSpecialKey)) &&
                                            (this.state.selectedItem.hasOwnProperty(this.serverSpecialKey)) &&
                                            (this.state.selectedItem.value === permission.value) &&
                                            (this.state.selectedItem.options.length > 0) &&
                                            <NumberBadge>
                                                {this.state.selectedItem.options.length}
                                            </NumberBadge>
                                        }
                                    </div>)
                            }
                        )
                    }
                </TableRowDocs>

                <Modal isOpen={this.state.isModalOpen}
                       handleClose={this.closeModal}
                       containerWidth="450"
                       containerHeight="auto"
                       containerSizeUnit="px"
                       containerPadding="20"
                       title="Select Group"
                       titleStyled={ModalTitleStyles}
                       closeStyled={CloseModalStyles}
                >

                    <ModalWrapper>
                        <ModalWrapperContent margin={20}>

                            <InputText
                                id={"groupSearchFilter_" + this.props.id}
                                name={"groupSearchFilter_" + this.props.id}
                                type="text"
                                value={this.state.groupSearchFilterValue}
                                styled={GroupInputTextModalStyles}
                                wrapperStyled={GroupInputTextWrapperModalStyles}
                                placeholder="Search"
                                onInput={this.groupSearchFilterChangeHandler}
                                icon={<IconNew bp1="-390" bp2="-55" iwidth="20" iheight="20"/>}
                            />

                            <ModalRowWrapper>
                                {
                                    this.modalOptions.map(
                                        group => {
                                            let isSelected =
                                                (this.state.selectedItem.options) &&
                                                this.state.selectedItem.options.some(
                                                    opt => opt.id === group.id
                                                );
                                            return (
                                                (group.show)
                                                    ?
                                                    <CheckBoxGroupRow
                                                        key={this.props.id + "_" + group.id}
                                                        isSelected={isSelected}
                                                    >
                                                        <img src={group.logo}
                                                             alt=""/>
                                                        <strong>
                                                            {group.name}
                                                        </strong>
                                                        <small>
                                                            <IconNew bp1="-286" bp2="-156" iwidth="9" iheight="13"/>
                                                            {group.location}
                                                        </small>
                                                        <input
                                                            id={"pop-check_" + this.props.id + "_" + group.id}
                                                            name={"pop-check_" + this.props.id + "_" + group.id}
                                                            type="checkbox"
                                                            checked={isSelected || false}
                                                            onChange={
                                                                () => this.selectedGroupsChangedHandler(group)
                                                            }
                                                        />
                                                        <span/>
                                                        <ClearFloats/>
                                                    </CheckBoxGroupRow>
                                                    : ''
                                            )
                                        }
                                    )
                                }
                            </ModalRowWrapper>

                            <AddMLSButton
                                styled={AddButtonModalStyles}
                                onClick={this.closeModal}
                            >
                                Done
                            </AddMLSButton>

                        </ModalWrapperContent>
                    </ModalWrapper>
                </Modal>
            </Row>
        )
    }
}

export default FormPrivilegedListing;