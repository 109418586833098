import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew/BaseTemplateNew';
import ProfileMenu from '../Modules/ProfileMenu';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import { Main } from '../../styles/Styled';
import { Column } from '../../styles/Styled';

class BuildNetwork extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;
    }

    renderBody() {
        return (
            <Main>
                <Column type='one_column_setting' padding_right first>
                    <BuildNetworkMain />
                </Column>
                <ProfileMenu />
            </Main>
        );
    }
}

export default BuildNetwork;
