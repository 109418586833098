import React, { Component } from 'react';
import {
    Wrapper,
    ItemWrapper,
    TitleWrapper,
    OptionsWrapper,
} from './Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import ProfileMenuSettingsMyActivitiesItem from '../ProfileMenuSettingsMyActivitiesItem';
import ProfileMenuSettingsActivitiesClearHistory from '../ProfileMenuSettingsActivitiesClearHistory';
import { ArrowRight } from '../BaseTemplate/Styled';

class ProfileMenuSettingsMyActivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
        };
        this.menu = [
            {
                title: 'Likes',
                subtitle:'on listings, comments, posts, etc.'
            },
            {
                title: 'Comments',
                subtitle:'on listings, comments, posts, etc.'
            },
            {
                title: 'Networking',
                subtitle:'connections, follow/unfollow, invitations, group activities'
            },
            {
                title: 'Realtyfeed Privacy Policy',
                subtitle:'information, change password, etc.'
            },
            {
                title: 'Active Sessions',
            },
            {
                title: 'Clear History',
            },
        ];
        this.likeData = [
            {
                action: "You liked Smith's",
                type:'listing',
                time:'1hr',
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'2hr'
            },
            {
                action: "You liked Smith's",
                type:'post',
                time:'2hr'
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'3hr'
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'4hr'
            },
            {
                action: "You liked Smith's",
                type:'post',
                time:'5hr'
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'5hr'
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'6hr'
            },
            {
                action: "You liked Smith's",
                type:'post',
                time:'7hr'
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'8hr'
            },
            {
                action: "You liked Smith's",
                type:'post',
                time:'9hr'
            },
            {
                action: "You liked Smith's",
                type:'listing',
                time:'10hr'
            },
        ];
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu,
        });
    };

    handleBackButton = () => {
        this.setState({ selectedMenu: -1 });
    };

    render() {
        const selectedMenu = this.state.selectedMenu;
        let itemsToShow;
        switch (selectedMenu) {
            case 0:
                itemsToShow = (
                    <ProfileMenuSettingsMyActivitiesItem
                        backButton={this.handleBackButton}
                        activityData={this.likeData}
                        title='Likes'
                    />
                );
                break;
            case 5:
                itemsToShow = (
                    <ProfileMenuSettingsActivitiesClearHistory 
                        backButton={this.handleBackButton}
                    />
                );
                break;
            default:
                itemsToShow = null;
        }
        return (
            <ProfileMenuContent
                title="My Activities"
                onTitleClick={() => {
                    if (selectedMenu !== -1) {
                        this.handleItemClick(-1);
                    } else {
                        this.props.backButton();
                    }
                }}
            >
                <Wrapper>
                    {selectedMenu === -1 &&
                        this.menu.map((item, index) => (
                            <ItemWrapper
                                key={index}
                                onClick={() =>
                                    this.setState({ selectedMenu: index })
                                }
                            >
                                <TitleWrapper>
                                    <span>{item.title}</span>
                                    <p>{item.subtitle}</p>
                                </TitleWrapper>
                                <OptionsWrapper>
                                    <ArrowRight color={'mainFontColor'} />
                                </OptionsWrapper>
                            </ItemWrapper>
                        ))}
                    {selectedMenu !== -1 && itemsToShow}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettingsMyActivities;
