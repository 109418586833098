import styled from 'styled-components';
import { Main } from '../../styles/Styled';

export const LoginRegisterMain = styled(Main)`
    width: 1810px;
    display: flex;
    min-height: calc(100vh - 48px);
    box-sizing: border-box;

    @media ${(props) => props.theme.mediaHD} {
        width: 1274px;
        min-height: calc(100vh - 40px);
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        width: 900px;
        min-height: calc(100vh - 40px);
    }
    @media ${(props) => props.theme.mediaTablet} {
        width: 600px;
        min-height: calc(100vh - 40px);
    }
    @media ${(props) => props.theme.mediaMobile} {
        width: 340px;
        min-height: calc(100vh - 40px);
    }
`;

export const LoginRegisterColumn = styled.div`
    width: 566px;
    margin: ${(props) => (props.noMargin ? '0' : '0 auto')};
    ${(props) => props.marginTop && `margin-top: ${props.marginTop}px`};
    padding-top: 90px;
    z-index: 1000;
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    > p {
        font-size: 18px;
        text-align: center;
        margin-bottom: 50px;
        font-weight: lighter;
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 404px;
        ${(props) => props.marginTop && `margin-top: 0`};
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        width: 400px;
        ${(props) => props.marginTop && `margin-top: 0`};
    }
    @media ${(props) => props.theme.mediaTablet} {
        width: 400px;
        padding-top: 60px;
        ${(props) =>
            props.marginTop && `margin-top: ${props.marginTop + 40}px`};
        > p {
            font-size: 12px;
        }
    }
    @media ${(props) => props.theme.mediaMobile} {
        width: 335px;
        padding-top: 20px;
        ${(props) => props.marginTop && `${props.marginTop + 40}px`};

        p {
            font-size: 10px;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
`;

export const Logo = styled.div`
    text-align: ${(props) => (props.align ? props.align : 'center')};
    margin-bottom: 60px;

    @media ${(props) => props.theme.mediaTablet} {
        margin-bottom: 10px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        margin-bottom: 20px;
    }
`;

export const TwoSideWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 1300px;
    margin: 180px auto 0;

    @media ${(props) => props.theme.mediaHD} {
        margin: 20px auto 0;
        width: 1100px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        width: 850px;
        margin: 70px auto 0;
    }
`;

export const VideoSideWrapper = styled.div`
    margin-top: 80px;
    position: relative;
    width: 590px;

    > p {
        font-size: 18px;
        color: ${(props) => props.theme.mainFontColor};
        text-align: center;
    }

    @media ${(props) => props.theme.mediaHD} {
        > p {
            font-size: 18px;
        }
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        width: 400px;
        > p {
            font-size: 12px;
        }
    }
`;

export const VideoWrapper = styled.div`
    height: 330px;
    border-radius: 15px;
    video {
        border-radius: 15px;
        overflow: hidden;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        height: 225px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        margin-top: 20px;
        height: 225px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        margin-top: 20px;
        height: 190px;
    }
`;
