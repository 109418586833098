import React, { Component } from 'react';
import { ClearFloats } from '../BaseTemplate/Styled';
import {
    commaSeparator,
    numberFormatter,
    sequentialNumber,
} from '../../../Utils';
import { Price } from '../SearchNewListing/Styled';
import IconNew from '../IconNew/IconNew';
import {
    SearchListingWrapperAll,
    ListingInfoWrapper,
    Tag,
    TagWrapper,
    SearchListingWrapper,
    InfoWrapper,
    LocationWrapper,
    CardLowerSection,
    LocationTextWrapper,
    CardMiddleSection,
} from './Styled';
import ButtonBookmarkItem from '../ButtonBookmarkItem';

class SearchNewListingItem extends Component {
    render() {
        return this.props.type === 'all' ? (
            <SearchListingWrapperAll>
                <img alt="proprty" />
                <p>{this.props.listing.address}</p>
                <Price>{commaSeparator(this.props.listing.price)}</Price>
                <ListingInfoWrapper>
                    <strong>{this.props.listing.bedrooms}</strong> BEDS
                    <em />
                    <strong>{this.props.listing.bathrooms}</strong> BATH
                    <em />
                    <strong>{commaSeparator(this.props.listing.area)}</strong>
                    <span>sqft</span>
                </ListingInfoWrapper>
                <TagWrapper all>
                    {this.props.listing.tags.map((tag) => {
                        switch (tag) {
                            case '%':
                                return (
                                    <Tag key={'newListing-' + tag} to="/">
                                        <IconNew
                                            bp1="-257"
                                            bp2="-77"
                                            iwidth="26"
                                            iheight="26"
                                        />
                                    </Tag>
                                );
                            case 'N':
                                return (
                                    <Tag key={'newListing-' + tag} to="/">
                                        <IconNew
                                            bp1="-229"
                                            bp2="-77"
                                            iwidth="26"
                                            iheight="26"
                                        />
                                    </Tag>
                                );
                            case 'R':
                                return (
                                    <Tag key={'newListing-' + tag} to="/">
                                        <IconNew
                                            bp1="-201"
                                            bp2="-77"
                                            iwidth="26"
                                            iheight="26"
                                        />
                                    </Tag>
                                );
                            default:
                                return '';
                        }
                    })}
                </TagWrapper>
                <div className="footer">
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-103"
                            iwidth="13"
                            iheight="12"
                        />
                        <p>{numberFormatter(this.props.listing.likes)}</p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-447"
                            bp2="-160"
                            iwidth="17"
                            iheight="14"
                        />
                        <p>{numberFormatter(this.props.listing.comments)}</p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-447"
                            bp2="-131"
                            iwidth="22"
                            iheight="13"
                        />
                        <p>{numberFormatter(this.props.listing.views)}</p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-117"
                            iwidth="13"
                            iheight="14"
                        />
                        <p>{numberFormatter(this.props.listing.shares)}</p>
                        <em />
                    </div>
                </div>
                <ClearFloats />
            </SearchListingWrapperAll>
        ) : (
            <SearchListingWrapper>
                <div className="header">
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-146"
                            iwidth="12"
                            iheight="11"
                        />
                        <p>{numberFormatter(this.props.listing.total_likes)}</p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-447"
                            bp2="-177"
                            iwidth="16"
                            iheight="14"
                        />

                        <p>{numberFormatter(this.props.listing.total_comments)}</p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-447"
                            bp2="-146"
                            iwidth="20"
                            iheight="12"
                        />
                        <p>{numberFormatter(this.props.listing.visibility)}</p>
                        <em />
                    </div>
                    <div>
                        <IconNew
                            bp1="-470"
                            bp2="-159"
                            iwidth="12"
                            iheight="14"
                        />
                        <p>{numberFormatter(this.props.listing.total_share)}</p>
                        <em />
                    </div>
                </div>
                <img alt="property" src={this.props.listing.images[0].image}/>
                <InfoWrapper>
                    <div>
                        <ButtonBookmarkItem
                            inline
                            bookmarked={this.props.listing.saved}
                            listingId={1}
                        />
                        <span>
                            {this.props.listing.state}, {this.props.listing.zipcode} {this.props.listing.city}{' '}
                        </span>
                    </div>
                    <CardMiddleSection>
                        <Price>${commaSeparator(parseFloat(this.props.listing.price))}</Price>
                        <ListingInfoWrapper>
                            <strong>{this.props.listing.bedrooms}</strong> BEDS
                            <em />
                            <strong>{this.props.listing.bathrooms}</strong> BATH
                            <em />
                            <strong>
                                {commaSeparator(parseFloat(this.props.listing.living_area))}
                            </strong>
                            <span> sqft</span>
                        </ListingInfoWrapper>
                    </CardMiddleSection>
                    <CardLowerSection>
                        <LocationWrapper>
                            <IconNew
                                bp1="-172"
                                bp2="-115"
                                iwidth="9"
                                iheight="13"
                            />
                            <LocationTextWrapper>
                                {this.props.listing.street}, {this.props.listing.city}{' '}
                                {sequentialNumber(parseInt(this.props.listing.street_number))}
                            </LocationTextWrapper>
                        </LocationWrapper>
                        <TagWrapper>
                            {this.props.listing.tags.map((tag) => {
                                switch (tag) {
                                    case '%':
                                        return (
                                            <Tag
                                                key={'newListing-' + tag}
                                                to="/"
                                            >
                                                <IconNew
                                                    bp1="-257"
                                                    bp2="-77"
                                                    iwidth="26"
                                                    iheight="26"
                                                />
                                            </Tag>
                                        );
                                    case 'N':
                                        return (
                                            <Tag
                                                key={'newListing-' + tag}
                                                to="/"
                                            >
                                                <IconNew
                                                    bp1="-229"
                                                    bp2="-77"
                                                    iwidth="26"
                                                    iheight="26"
                                                />
                                            </Tag>
                                        );
                                    case 'R':
                                        return (
                                            <Tag
                                                key={'newListing-' + tag}
                                                to="/"
                                            >
                                                <IconNew
                                                    bp1="-201"
                                                    bp2="-77"
                                                    iwidth="26"
                                                    iheight="26"
                                                />
                                            </Tag>
                                        );
                                    default:
                                        return '';
                                }
                            })}
                        </TagWrapper>
                    </CardLowerSection>
                </InfoWrapper>
            </SearchListingWrapper>
        );
    }
}

export default SearchNewListingItem;
