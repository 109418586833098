import styled from "styled-components";
import { ArrowDown } from '../BaseTemplate/Styled';

export const Button = styled.button`
    width: 140px; height: 42px;
    padding: 0;
    border-radius: 5px;
    border: ${props => (props.active) ? 'solid 2px ' + props.theme.darkBlue : 'none'};
    background: ${props => (props.active) ? '#fff' : props.theme.cyan};
    font-family: ${props => props.theme.mainFont};
    font-size: 18px;
    color: ${props => (props.active) ? props.theme.darkBlue : '#fff'};
    &:hover {
        cursor: pointer;
    }
    ${
        props => props.styled
    };

    @media ${props => props.theme.mediaHD} {
        width: 98px; height: 30px;
        font-size: 14px;
    }
`;

export const WhiteArrowDown = styled(ArrowDown)`
    &:after, &:before {
        background-color: white;
    }
`;