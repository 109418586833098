import styled, { css } from 'styled-components';
import { SharedCountsType } from './ListingSharedCounts';

export const ShareCounts = styled.div`
    color: ${(props) => props.theme.textBlack};
    ${(props) => props.type === SharedCountsType.NORMAL && 'margin-top: 0'};
    ${(props) =>
        props.type === SharedCountsType.BIG &&
        ` width: 370px; font-size: 24px; margin-top: 20px; `} > button > span,
    > span {
        position: relative;
    }
    span.heart {
        ${(props) =>
            props.type === SharedCountsType.BIG
                ? ' top: 3px; '
                : ' top: 1px; '};
        margin-right: 6px;
        float: left;
    }
    span.comment {
        ${(props) =>
            props.type === SharedCountsType.BIG
                ? ' top: 6px; '
                : ' top: 4px; '};
        margin: 0 6px 0 25px;
        ${(props) =>
            props.type === SharedCountsType.BIG && ` margin-left: 63px; `};
    }

    > button {
        border: 0;
        background: transparent;
        cursor: pointer;
    }
`;

export const ProfileImageStyle = styled.img`
    height: 51px;
    width: 51px;
    border-radius: 10px;
`;

export const ModalTopWrapper = styled.div`
    padding-bottom: 22.2px;
    border-bottom: 1.5px solid #c3cacc;
`;

export const ModalCommentProfile = styled.div`
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ModalProfileSide = styled.div`
    display: flex;
    align-items: center;
`;

export const ProfileName = styled.div`
    color: ${(props) => props.theme.textBlack};
    margin-left: 14px;
    font-weight: bold;
    font-size: 22px;
`;

export const TopCommentWrapper = styled.div`
    margin-top: 7.5px;
`;

export const TopComment = styled.span`
    line-height: 1.72;
    font-size: 18px;
    vertical-align: top;
    color: ${(props) => props.theme.mainFontColor};
`;

export const CommentTime = styled.div`
    font-size: 18px;
    color: ${(props) => props.theme.textGrayLight};
`;

export const Menu = styled.div`
    padding: 0 10px;
    &:after {
        content: '\\22EE';
        display: inline-block;
        font-size: 33px;
        font-weight: bold;
        color: ${(props) => props.theme.grayCircle};
        width: 15px;
    }
    &:hover {
        cursor: pointer;
    }
`;

export const ModalTitleStyles = css`
    border-bottom-width: 1px;
    height: 80px;
    margin: 0 !important;
    box-sizing: border-box;
    padding: 20px 30px;
    border-bottom: 3px solid;
`;

export const ModalCloseStyles = css`
    top: 22px;
    right: 25px;
    width: 20px;
    height: 20px;
    &:before,
    &:after {
        top: 0;
        left: 9px;
        width: 2px;
        height: 20px;
    }
`;

export const SocialIconsWrapper = styled.div`
    display: flex;
    margin-top: 7.5px;
    align-items: center;
    .share-icon {
        margin-top: 10px;
        margin-left: 23.5px;
        transform: scale(-1, 1);
    }
`;

export const AmountOfLikes = styled.div`
    margin-left: 8px;
    font-size: 18px;
    font-weight: bold;
`;

export const ModalWrapper = styled.div`
    padding-left: 14px;
    padding-right: 14px;
    background: #f2fbfb;
    overflow-y: scroll;
    height: ${(props) => props.containerHeight - 160}px;
`;

export const ModalCenterWrapper = styled.div`
    margin-top: 15.5px;
`;

export const DisplayWrapper = styled.div`
    display: flex;
    margin-top: 7.5px;
    justify-content: flex-start;
`;

export const RealEstateImg = styled.img`
    width: 85px;
    height: 85px;
    border-radius: 10px;
    margin-left: ${(props) => (props.firstElement ? props.firstElement : null)};
`;

export const CenterProfileComment = styled.div`
    margin-left: 17px;
    padding-bottom: 11px;
`;

export const RepliesExpand = styled.div`
    margin-left: auto;
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;
    font-weight: bold;
`;

export const ExpandSection = styled.div`
    display: flex;
    justify-content: space-between;
    height: 74.5px;
    align-items: center;
`;

export const CommendExpand = styled.div`
    font-size: 19px;
    color: ${(props) => props.theme.mainFontColor};
    cursor: pointer;
`;

export const LoadButton = styled.div`
    font-size: 19px;
    color: ${(props) => props.theme.textBlack};
    cursor: pointer;
`;

export const InputWrapper = styled.div`
    width: 655px;
    height: 100%;
`;

export const ModalBottomWrapper = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-top: 3px solid ${(props) => props.theme.darkBlue};
    height: 80px;
    overflow-y: hidden;
    width: 100%;
`;

export const CommentInput = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 14px;
    font-size: 18px;
    color: ${(props) => props.theme.textBlack};
    font-family: 'Montserrat';

    &::placeholder {
        font-size: 18px;
        font-weight: lighter;
        color: ${(props) => props.theme.textGrayLight};
    }
`;

export const SearchOptions = styled.div`
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70px;
`;
