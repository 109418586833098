import React from 'react';
import {Main} from "../../styles/Styled";
import BaseTemplateNew from "../Modules/BaseTemplateNew";
import SettingsDynamicWizard from "../Modules/SettingsDynamicWizard";
import Config from "../../config";
import PageNotFound from "../PageNotFound";

class SettingsNew extends BaseTemplateNew {

    constructor(props) {
        super(props);
        super.showFooter = false;
    }

    renderBody() {
        const category = this.props.match.params.category;
        if (!Config.ROUTES.templates[category].title) {
            return <PageNotFound noHeader />
        }
        return (
            <Main>
                <SettingsDynamicWizard
                    title={Config.ROUTES.templates[category].title}
                    match={this.props.match}
                    mainRoute={Config.ROUTES.settings.root + '/' + category}
                    endpoint={Config.ROUTES.templates[category].endpoint}
                />
            </Main>
        )
    }
}

export default SettingsNew;
