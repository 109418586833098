import React, {Component} from 'react';
import {
    ArrowDownExpandButton,
    Wrapper,
    Arrow,
    ExpandButton,
    HeadButton,
    Image,
    ImageSmall,
    ImageBadge, LinkItem
} from "./Styled";
import {ClearFloats} from "../BaseTemplate/Styled";
import Icon from "../Icon";
import {Link} from "react-router-dom";

class ProfileGroupTypeInfoBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpand: (props.closed)
        };
    }

    headerClickHandler = () => {
        // It is more safe to use prevState instead of direct use
        // Because setState runs ASYNC
        this.setState((prevState, props) => {
            return {isExpand: !prevState.isExpand};
        });
    };

    headerButtonClickHandler = (e) => {
        e.stopPropagation();
        alert('Button Clicked');
    };

    render() {

        let content;
        switch (this.props.contentType) {
            case ContentType.TEXT:
                content = this.props.content;
                break;
            case ContentType.IMAGE:
                content = this.props.content.map(
                    (image,index) => <Image key={'img-'+index} src={image}/>
                );
                break;
            case ContentType.IMAGE_SMALL:
                content = this.props.content.map(
                    (image,index) => <ImageSmall key={'img-'+index} src={image}/>
                );
                break;
            case ContentType.IMAGE_BADGE:
                content = this.props.content.map(
                    (image,index) => <ImageBadge key={'img-'+index} src={image}/>
                );
                break;
            case ContentType.LINKS:
                content = this.props.content.map(
                    (item,index) => {
                        let link;
                        switch (item.type) {
                            case ContentType.LINK_TYPE.PHONE:
                                link = 'tel:' + item.contact_number;
                                break;
                            case ContentType.LINK_TYPE.EMAIL:
                                link = 'mailto:' + item.contact_email;
                                break;
                            case ContentType.LINK_TYPE.URL:
                                link = item.link;
                                break;
                            default:
                                link = item.link;
                                break;
                        }
                        return (
                            <LinkItem key={'linkItem-'+index}>
                                <strong>{item.name}:</strong>
                                <Link to={link} target="_blank" rel="noopener noreferrer">{item.link}</Link>
                            </LinkItem>
                        )
                    }
                );
                break;
            default:
                content = '';
                break;
        }

        return (
            <Wrapper isExpand={this.state.isExpand} height={'145px'}>
                <h2 onClick={this.headerClickHandler}>
                    <Arrow isExpand={this.state.isExpand}/>
                    {this.props.title}
                    {
                        (this.props.titleButton)
                            &&
                            <HeadButton onClick={this.headerButtonClickHandler}>
                                {this.props.titleButton}
                                <Icon bp1="-138" bp2="-1535" iwidth="22" iheight="24"/>
                            </HeadButton>
                    }
                    {
                        (this.props.subTitle)
                            &&
                            <span>
                                {this.props.subTitle}
                            </span>
                    }
                </h2>
                {
                    (this.props.contentType !== ContentType.LINKS)
                        &&
                        <>
                            <ClearFloats/>
                            <ExpandButton>
                                <ArrowDownExpandButton/>
                            </ExpandButton>
                        </>
                }
                <p>
                    {content}
                </p>
            </Wrapper>
        );
    }
}

export default ProfileGroupTypeInfoBox;

export const ContentType = {
    TEXT: 'text',
    IMAGE: 'image',
    IMAGE_SMALL: 'imageSmall',
    IMAGE_BADGE: 'imageBadge',
    LINKS: 'links',
    LINK_TYPE: {
        URL: 'url',
        EMAIL: 'email',
        PHONE: 'phone'
    }
};
