import  {css} from 'styled-components';

export const TabPageStyles = css`
    position: absolute;
    top: 85px;
    right: 0;
    bottom: 0;
    left: 0;


    overflow-y: scroll;
`;
