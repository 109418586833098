import styled from 'styled-components';

export const Input = styled.input`
    border: 0;
    width: 21%;
    color: ${(props) =>
        props.disable ? props.theme.textGrayLight : props.theme.cyan};
    text-align: center;
    cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
`;

export const PopContainer = styled.div`
    width: 100px;
    border: 1px solid;
    border-color: ${(props) => props.theme.grayLight};
    border-radius: 5px;
    background: white;
    z-index: 10;
    position: absolute;
    right: 0;
    display: ${(props) => props.display};

    button {
        background: transparent;
        border: 0;
        width: 100px;
        margin: 0;
        font-size: 14px;
        color: ${(props) => props.theme.mainFontColor};
        padding-left: 10px;
        border-bottom: 1px solid;
        border-color: ${(props) => props.theme.grayBorder};
        padding-bottom: 10px;
        cursor: pointer;
        :last-of-type {
            border: 0;
        }
    }
`;
