import styled, { css } from 'styled-components';
import { Countries } from '../SearchNewAgentItem/Styled';

export const Wrapper = styled.div`
    padding: 0 40px 40px;
`;

export const TabPageStyles = css`
    margin-top: 20px;
`;

export const Section = styled.div`
    h2 {
        margin: 35px 0 0;
        color: ${(props) => props.theme.darkBlue};
        font-size: 30px;
    }
    p {
        line-height: 42px;
    }
    ${(props) =>
        props.border &&
        `
            border-top: solid 1px ${props.theme.grayLineLightDark};
            margin-top: 40px;
            `};
`;

export const FeaturesParent = styled.ul`
    float: left;
    width: 50%;
    margin: 25px 0 0 0;
    padding: 0;
    list-style: none;
    color: ${(props) => props.theme.textBlack};
    line-height: 42px;
    column-count: 2;
`;

export const SchoolInfoHeadline = styled.h2`
    margin-top: 60px;
`;

export const FeaturesItem = styled.li`
    margin: 0;
    padding: 0;
    &:after {
        content: '';
        float: left;
        transform: rotate(45deg);
        width: 6px;
        height: 12px;
        border-bottom: 3px solid ${(props) => props.theme.cyan};
        border-right: 3px solid ${(props) => props.theme.cyan};
        position: relative;
        top: 9px;
        margin: 0 15px 0 2px;
    }
    span {
        color: ${(props) => props.theme.darkBlue};
    }
`;

export const RequestForAccess = styled.div`
    width: 523px;
    height: 203px;
    float: right;
    border: dashed 2px ${(props) => props.theme.grayDarkBorder};
    border-radius: 10px;
    text-align: center;
    font-size: 31px;
    line-height: 34px;
    box-sizing: border-box;
    padding-top: 38px;
    position: relative;
    top: -20px;
    span {
        position: relative;
    }
    small {
        color: ${(props) => props.theme.textBlack};
        font-size: 14px;
        font-weight: 900;
        position: absolute;
        top: -10px;
        right: -20px;
    }
    strong {
        color: ${(props) => props.theme.noticeRed};
    }
`;

export const SchoolInfo = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom: dashed 2px ${(props) => props.theme.grayLight};
    .noPaddingTop {
        padding-top: 0px;
    }
    h3 {
        font-size: 27px;
        color: ${(props) => props.theme.textBlack};
        span {
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background: ${(props) => props.theme[props.color]};
            color: #fff;
            position: absolute;
            text-align: center;
            right: 0;
            bottom: 0;
            box-sizing: border-box;
            padding-top: 10px;
            font-weight: normal;
            font-size: 25px;
        }
    }
    div > i {
        display: inline-block;
        width: 24px;
        height: 21px;
        margin-right: 12px;
        line-height: 21px;
        position: relative;
        top: 3px;
        text-align: center;
    }
`;

export const CircleWrap = styled.span`
    height: 56px;
    width: 56px;
    background-color: #bbb;
    border-radius: 100%;
    background: ${(props) => props.theme[props.color]};
    color: #fff;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SchoolName = styled.span`
    font-size: 2۴px;
    font-weight: lighter;
    color: ${(props) => props.theme.textBlack};
`;

export const SchoolDetailsWrapper = styled.div`
    margin-left: 20px;
    padding-bottom: 20px;
`;

export const SchoolInfoHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 84px;
`;

export const SimilarProperties = styled.div``;

export const SimilarProperty = styled.div`
    width: 530px;
    float: left;
    margin: 36px 68px 0 0;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const PropertyAgent = styled.div`
    ${(props) =>
        props.bg === 'blue' ? ` background: ${props.theme.lightBlue} ` : ''};
    ${(props) =>
        props.bg === 'cyan' ? ` background: ${props.theme.lightCyan} ` : ''};
    box-sizing: border-box;
    padding: ${(props) => (props.normal ? '13' : '17')}px 13px 13px;
    position: relative;
    margin-bottom: 30px;
`;

export const AgentContent = styled.div`
    position: relative;
    z-index: 10;
    h3 {
        position: relative;
        font-size: 25px;
        color: ${(props) => props.theme.darkBlue};
        float: left;
        margin: 0 0 0 25px;
        border-bottom: solid 1px ${(props) => props.theme.darkBlue};
        span {
            position: absolute;
            top: 7px;
            right: -34px;
        }
        a {
            color: ${(props) => props.theme.darkBlue};
        }
    }
    h4 {
        font-size: 18px;
        font-weight: normal;
        float: left;
        margin: 0 0 0 25px;
        span {
            position: relative;
            top: 1px;
            margin-right: 7px;
        }
    }
`;

export const AgentImage = styled.div`
    float: left;
    position: relative;
    width: 125px;
    height: 125px;
    box-sizing: border-box;
    padding: 4px;
    background: ${(props) => props.theme.darkBlue};
    border-radius: 10px 10px 10px 0;
    img {
        width: 117px;
        height: 117px;
        border-radius: 7px;
    }
    & > span {
        position: absolute;
        top: 10px;
        right: -13px;
    }
`;

export const AgentCountries = styled(Countries)`
    bottom: 10px;
`;

export const ButtonContainer = styled.div`
    float: right;
    margin-top: -2px;
`;

export const ButtonOnline = styled.button`
    width: 132px;
    height: 42px;
    box-sizing: border-box;
    background: #fff;
    border: solid 3px ${(props) => props.theme.noticeGreen};
    font-family: ${(props) => props.theme.mainFont};
    font-size: 18px;
    color: ${(props) => props.theme.textBlack};
    border-radius: 5px;
    margin-bottom: 8px;
    cursor: pointer;
    i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: ${(props) => props.theme.noticeGreen};
        margin-right: 15px;
        position: relative;
        top: -1px;
    }
    span {
        position: relative;
        top: 3px;
        margin-left: 10px;
    }
`;

export const ButtonAgent = styled(ButtonOnline)`
    background: ${(props) => props.theme.grayLight};
    border: none;
    span {
        margin: 0 0 0 7px;
        position: relative;
        top: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const DottedSeparator = styled.div`
    border-bottom: dashed 2px ${(props) => props.theme.grayLineLightDark};
    margin: 50px 0;
`;

export const Banner = styled.div`
    height: 170px;
    background: ${(props) => props.theme.grayBorder};
    border-radius: 10px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
    padding-top: 65px;
`;

export const SectionForm = styled.div`
    box-sizing: border-box;
    margin-top: 45px;
    padding-right: 30px;
    label > div {
        float: right;
    }
    label {
        display: block;
        margin-top: 20px;
        position: relative;
        .monthlyPayment {
            color: ${(props) => props.theme.darkBlue};
            font-size: 20px;
        }
    }
    strong {
        color: ${(props) => props.theme.textBlack};
        float: left;
        padding-top: 8px;
        margin-right: 10px;
    }
    label span {
        position: absolute;
        top: 8px;
        right: 10px;
        color: ${(props) => props.theme.textGrayLightPlaceholder};
        &:nth-of-type(2) {
            right: 115px;
        }
    }
    label.sum {
        margin-top: 30px;
        input {
            background: ${(props) => props.theme.grayBgInput};
            border: none;
            ::placeholder {
                font-weight: bold;
                font-size: 20px;
                color: ${(props) => props.theme.mainFontColor};
            }
        }
    }
`;

export const SectionFormInputStyles = css`
    width: 500px;
    height: 50px;
    border: none;
    border-bottom: solid 2px ${(props) => props.theme.grayBorderLight};
    font-size: 20px;
    padding: 13px 14px;
    ::placeholder {
        color: ${(props) => props.theme.textGrayLightPlaceholder};
    }
    &.medium-placeholder {
        width: 397px;
        margin-right: 5px;
        margin-left: 0;
        padding-right: 60px;
    }
    &.medium {
        width: 397px;
        margin-right: 5px;
        margin-left: 0;
    }
    &.long {
        padding-right: 30px;
    }
    &.small-placeholder {
        width: 98px;
        padding-right: 30px;
    }

    &.small {
        width: 98px;
        padding-right: 30px;
    }
`;

export const SectionFormInputWrapperStyles = css`
    float: right;
`;

export const MapContainer = styled.div`
    margin-top: 30px;
    height: 515px;
    border-radius: 10px;
    overflow: hidden;
`;

export const Description = styled.div`
    margin-top: 10px;
    padding: 0 15px;
`;
