import styled, { css } from 'styled-components'

export const Img = styled.img`
    
    ${props => props.iSize === '1x' && css`
        width:20px;`
    }

    ${props => props.iSize === '2x' && css`
        width:40px;`
    }

    ${props => props.iSize === '3x' && css`
        width:60px;`
    }
`