import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 100px 0 200px;
    text-align: center;
    color: ${props => props.theme.textBlack};
    h1 {
        font-size: 40px;
        color: ${props => props.theme.darkBlue};
        strong {
            font-size: 150px;
            line-height: 170px;
            display: inline-block;
        }
    }
    a, a:link {
        color: ${props => props.theme.darkBlue};
    }
`;