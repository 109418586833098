import styled from 'styled-components';


export const UsercardContainerWrapper = styled.div`
    display: flex;
    box-shadow: 0px 1px 5px #00000029;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    border-radius: 10px;
    position: relative;

    ::after {
      width: 15px;
      height: 15px;
      content: '';
      background: #fff;
      border-radius: 50%;
      position: absolute;
      right: -8px;
      box-shadow: inset 1px 0 1px 0 #00000029;
      border: none;
    }
    ::before {
      width: 15px;
      height: 15px;
      content: '';
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: -8px;
      box-shadow: inset -1px 0 1px 0 #00000029;
      border: none;
    }
`;

export const UsercardLeftside = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
`;

export const UsercardRightside = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    justify-content: ${(props) =>
        props.forConnected ? 'flex-end' : 'space-between'};
    justify-self: flex-end;
    width: 30%;
`;

export const UserCardLeftSideTextContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
`;

export const UserCardNameText = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.textBlack};
    line-height: 24px;
`;

export const UserCardNumber = styled.span`
    color: ${(props) => props.theme.textGrayLight};
    font-size: 12px;
    font-weight: lighter;
    line-height: 20px;
`;

export const UsercardImg = styled.img`
    height: 38px;
    width: 38px;
    border-radius: 100px;
`;

export const UserCardPosition = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const DateText = styled.span`
    line-height: 14px;
    font-weight: lighter;
    font-size: 10px;
    color: ${(props) => props.theme.textGrayLight};
    width: 40%;
`;
