import React, { Component } from 'react';
import {
    Wrapper,
    ItemWrapper,
    TitleWrapper,
    OptionsWrapper,
    MenuOption,
    ClearDataButton,
} from './Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import Checkbox from '../FormCheckBox/FormCheckBox';
import FormButtonText from '../FormButtonText/FormButtonText';
import SelectorPopUp from '../SelectorPopUp';

class ProfileMenuSettingsActivitiesClearHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
            timePeriod: { text: 'Last Week', value: 1 },
        };
        this.periods = [
            { text: 'Last Week', value: 1 },
            { text: 'Last Month', value: 2 },
            { text: 'Last Year', value: 3 },
        ];

        this.history = [
            {
                title: 'My Likes History',
                subtitle: 'on listings, comments, posts, etc.',
            },
            {
                title: 'My comments history',
                subtitle: 'on listings, comments, posts, etc.',
            },
            {
                title: 'My Activities',
                subtitle:
                    'connections, follow/unfollow, invitations, group activities',
            },
            {
                title: 'Realtyfeed Privacy Policy',
                subtitle: 'information, change password, etc.',
            },
        ];
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu,
        });
    };

    handleBackButton = () => {
        this.setState({ selectedMenu: -1 });
    };

    render() {
        return (
            <ProfileMenuContent
                title="Clear History"
                onTitleClick={this.props.backButton}
            >
                <Wrapper>
                    <MenuOption>
                        <p>Time Range</p>
                        <SelectorPopUp
                            value={this.state.timePeriod}
                            onChange={(timePeriod) => {
                                this.setState({ timePeriod });
                            }}
                            color={'textBlack'}
                            items={this.periods}
                        />
                    </MenuOption>
                    {this.history.map((item, index) => (
                        <ItemWrapper key={index}>
                            <TitleWrapper>
                                <span>{item.title}</span>
                                <p>{item.subtitle}</p>
                            </TitleWrapper>
                            <OptionsWrapper>
                                <Checkbox styled={{ margin: '0 !important' }} />
                            </OptionsWrapper>
                        </ItemWrapper>
                    ))}
                    <FormButtonText styled={ClearDataButton}>
                        Clear Data
                    </FormButtonText>
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettingsActivitiesClearHistory;
