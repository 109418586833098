import React from "react";
import BaseTemplateNew from "../Modules/BaseTemplateNew";
import {Main} from "../../styles/Styled";
import {Wrapper} from "../PageNotFound/Styled";

class ServerError extends BaseTemplateNew {
    renderBody() {
        return(
            <Main backgrounded>
                <Wrapper>
                    <h1>
                        <strong>⚠</strong>
                        <br/>
                        Server Error
                    </h1>
                    The server is not responding.<br/>
                    Please check your internet connection and try again.
                </Wrapper>
            </Main>
        );
    }
}

export default ServerError;