import styled from 'styled-components'

export const Wrapper = styled.div`
    position:relative;
`

export const Row = styled.div`
    display:flex;
    color:rgb(39, 39, 39);
    width:100%;
    margin-bottom:20px;
    
    @media only screen and (max-width: 1169px) {
        width:100%;
    }
`

export const Column = styled.div`
    flex:0.9;
`

export const ColumnFlexEnd = styled.div`
    flex: 0.1;
    text-align: right;
    justify-content: center;
    display: flex;

    > button{
        text-align:right;
        margin-left:10px;
    }
`

export const ModalWrapper = styled.div`
    text-align:center;
`

export const ImgWrapper = styled.div`
    margin-bottom:20px;

    > img{
        max-width:100%;
    }
`

export const ModalAddButton = styled.button`
    width:110px;
    height:40px;
    background-color:rgb(112, 112, 112);
    border:none;
    border-radius:5px;
    color:#ffffff;
    font-size:15px;
    cursor:pointer;
`