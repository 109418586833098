import styled, {css} from "styled-components";
import {InputTextStyles, InputTextWrapperStyles} from "../LoginForm/Styled";

export const Row = styled.div``;

export const CheckBox = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    & > span {
        position: relative;
        height: 17px;
        width: 17px;
        box-sizing: border-box;
        // border: solid 1px #fff;
        border-radius: 1px;
    }
    &:hover input ~ span {
        // background-color: #eee;
    }
    input:checked ~ span {
        // background-color: #fff;
    }
    & > span:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ span:after {
        display: block;
    }
    & > span:after {
        left: 5px;
        top: 0;
        width: 10px;
        height: 20px;
        border: solid ${props => props.theme.cyan};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const TableRow = styled.div`
    display: flex;
    font-size: ${props => (props.head) ? 20 : 22}px;
    margin-bottom: ${props => (props.head) ? 0 : 25}px;
    & > div:first-of-type {
        flex-grow: 4;
        flex-shrink: 0;
        flex-basis: auto;
        color: ${props => props.theme.mainFontColor};
        font-weight: normal;
        text-align: left;
        padding: ${props => (props.head) ? 0 : '8px 0 0 40px'};
    }
    & > div {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 140px;
        color: ${props => props.theme.darkBlue};
        font-weight: bold;
        text-align: center;
    }
`;

export const TableRowDocs = styled(TableRow)`
    font-size: 26px;
    border-bottom: solid 1px ${props => props.theme.grayBorder};
    padding: 0 0 25px;
    & > div:first-of-type {
        flex-grow: 2;
        flex-shrink: 0;
        flex-basis: auto;
        color: ${props => props.theme.textBlack};
        font-weight: bold;
        padding: 3px 0 0;
    }
    & > div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        position: relative;
    }
`;

export const ButtonRadioCheckStyles = css`
    font-size: 18px;
    font-weight: normal;
    color: ${props => props.theme.mainFontColor};
    i {
        height: 42px;
        padding-top: 3px;
        border-width: 1px;
    }
    input:checked ~ i {
        color: ${props => props.theme.darkBlue};
        border-color: ${props => props.theme.darkBlue};
        border-width: 3px;
        padding-top: 1px;
    }
    em {
        width: 16px; height: 16px;
        top: 2px;
        border-color: ${props => props.theme.textGrayLight};
    }
    em:before {
        background: ${props => props.theme.darkBlue};
    }
    em:after {
        border-bottom-width: 1px;
        border-right-width: 1px;
    }
`;

export const ButtonRadioCheckDocsStyles = css`
    ${ButtonRadioCheckStyles};
    margin-left: 20px;
    i {
        width: auto;
        padding: 3px ${props => (props.padding) ? props.padding : "15px"} 0;
    }
`;

export const NumberBadge = styled.div`
    width: 25px; height: 25px;
    background: ${props => props.theme.noticeGreen};
    border-right: solid 3px #fff;
    border-left: solid 3px #fff;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: -12px; right: 20px;
`;

export const CheckBoxGroupRow = styled(CheckBox)`
    box-sizing: border-box;
    height: 70px;
    margin: 10px 0;
    border: solid 1px ${props => (props.isSelected)
    ? props.theme.cyan : 'transparent'};
    ${
        props => (props.isSelected) &&
            ` box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2); `
    };
    border-radius: 10px;
    padding: 3px;
    position: relative;
    img {
        width: 62px; height: 62px;
        border-radius: 7px;
        float: left;
    }
    strong {
        width: 280px;
        font-size: 18px;
        line-height: 18px;
        color: ${props => props.theme.darkBlue};
        margin: 10px 0 0 15px;
        float: left;
    }
    small {
        margin-left: 15px;
        font-size: 14px;
        color: ${props => props.theme.textGrayLight};
        float: left;
    }
    small span {
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
    & > span {
        width: 23px; height: 23px;
        position: absolute;
        top: 50%; right: 15px;
        transform: translateY(-50%);        
        background: ${props => (props.isSelected)
            ? props.theme.cyan : 'transparent'
        };
        border: solid 1px ${props =>(props.isSelected)
            ? 'transparent' : props.theme.textGrayLight
        };
        border-radius: 100%;
        box-sizing: border-box;
    }
    & > span:after {
        top: 3px; left: 8px;
        width: 4px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const CloseModalStyles = css`
    width: 18px; height: 18px;
    &:before, &:after {
        width: 2px; height: 19px;
        left: 8px;
    }
`;

export const GroupInputTextModalStyles = css`
    ${
        InputTextStyles
    };
    font-size: 18px;
    border: solid 1px ${props => props.theme.textGrayLight};
    border-radius: 5px;
    padding: 10px 15px;
    height: 50px;
    &:focus {
        border-color: ${props => props.theme.cyan};
    }
`;

export const GroupInputTextWrapperModalStyles = css`
    ${
        InputTextWrapperStyles
    };
    height: 50px;
    margin-bottom: 10px;
    span {
        opacity: 1;
        right: 15px;
    }
`;

export const ModalRowWrapper = styled.div`
    box-sizing: border-box;
    height: 470px;
    padding-right: 20px;
    overflow-y: auto;
`;

export const ModalTitleStyles = css`
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom-width: 1px;
    margin-bottom: 10px;
`;