import styled from "styled-components";
export const Wrapper = styled.div`
    margin: 10px 0 35px;
`;

export const IconWrapper = styled.a`
    display: block;
    float: right;
    width: 34px; height: 34px;
    margin-left: 33px;
    text-align: center;
    position: relative;
    color: ${props => props.theme.mainFontColor};
    &:last-of-type {
        margin-left: 0;
    }
`;