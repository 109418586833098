import styled from 'styled-components';

export const ProfileMenuVaultInvatationTabWrapper = styled.div`
`;

export const TabWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 60px;
    padding-right: 30px;
    padding-bottom: 100px;
`;

export const InputWrapper = styled.input`
    font-weight: lighter;
    font-size: 14px;
    color: ${(props) => props.theme.textGrayLight};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.textGrayLight};
    width: 100%;
    height: 26px;
    margin-top: 29px;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-weight: lighter;
        font-size: 14px;
        color: ${(props) => props.theme.textGrayLight};
    }
`;
