import styled from 'styled-components';






export const BodySectionWrapper = styled.div`
display: flex;
margin-top: 38px;
flex-direction: column;
width: 100%;
`;

export const SectionTopWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

div.top-left {
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;

    span {
        font-size: 14px;
        font-weight: lighter;
        color: #19257c;
    }
}
`;
export const SectionRight = styled.div`
font-size: 13px;
color: ${(props) => props.rightSideColor && props.rightSideColor};
cursor: pointer;
`;

export const SectionBottomWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

export const SectionCardWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-right: 20px;

span {
    color: ${(props) => props.theme.textBlack};
    font-weight: lighter;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}
`;

export const IconText = styled.div`
    color: ${(props) => props.textColor && props.textColor};
`;


