import React, { Component, Fragment } from 'react';
import { 
    HeaderWrapper,
    HeaderSection,
    FirstSectionContainer,
    ButtonWrapper,
    DownloadButton,
    FirstSectionWrapper,
    SecondSectionWrapper,
    HeaderText,
    ItemsWrapper,
    ItemContainer,
    ItemText,
    SecondDescriptionText,
    FifthSectionWrapper,
    SpecialistContentWrapper,
    CardWrapper,
    CardDescription,
    CardHeader,
    HeaderOptions,
    HeaderItem,
    BackgroundVideoWrapper,
    BackLayer,
    ReadMoreWrapper,
    FooterWrapper,
    LogoWrapper,
    HeaderRight,
    BurgerMenuWrapper,
    IconWrapper,
    IconItem,
    ScrollBox,
    NavItem,
    CardContentTop
} from './Styled';
import SlickCarouselComponent from '../SlickCarouselComponent';
import { withRouter } from 'react-router-dom';
import Config from '../../../config';
import realtyfeedPicture2 from './assets/secondpicture.png';
import realtyfeedPicture3 from './assets/thirdpicture.png';
import realtyfeedPicture4 from './assets/fourth.jpg';
import realtyfeedPicture5 from './assets/fifthpicture.png';
import Chip  from './assets/artificialintelligence.png';
import Resolution from './assets/manufacture.png';
import SelectAll from './assets/machinelearning.png';
import World from './assets/workflow.png';
import dataScreenshot from './assets/data.png';
import LandingPageShowcaseSection from '../LandingPageShowcaseSection/';
import Logo from './assets/logo.svg';
import RealtyFeedVideo from './assets/RF_B.mp4';
import Gamification from './assets/gamification.jpg';
import Simplification from './assets/simplification.jpg';
import SocialNetworking from './assets/socialNetworking.jpg';
import { ReactComponent as Twitter } from './assets/twitter.svg';
import { ReactComponent as Instagram } from './assets/instagram.svg';
import { ReactComponent as Linkedin } from './assets/linkedin.svg';
import { ReactComponent as Facebook } from './assets/facebook.svg';
import ArrowTop from './assets/arrowTop.svg';
import MouseSvg from './assets/MouseSvg';

  class LandingPageTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex : 0,
            mobileHeaderItemsOpen:false,
        }

        this.items = [
            {
                icon:Resolution,
                title:'High Resolution',
                description:
                `A disruptive real estate 
                social network`
            },
            {
                icon:Chip,
                title:'AI Support',
                description:
                `Bringing AI and machine 
                learning to the real estate arena`
            },
            {
                icon:SelectAll,
                title:'Flexible',
                description:
                `All-in-one platform for day 
                to day RE transactions`
            },
            {
                icon:World,
                title:'Trending',
                description:
                `Partnerships with 200+ real estate 
                associations with more than 1.6M 
                members`
            }
        ];

        this.FifthItems = [
            {
                icon:Simplification,
                title:'Social Networking',
                description:`
                RealtyFeed connects all real estate players, from agents and brokers to Associations and Boards.
                `,
                link:'https://www.hostingadvice.com/blog/create-advanced-real-estate-websites-with-realtyna/'
            },
            {
                icon:SocialNetworking,
                title:'Simplification',
                description:`
                Simplification:
                RealtyFeed is simplifying the real estate business by offering an all-in-one platform for day-to-day real estate needs, for clients and real estate professionals.
                `,
                link:'https://www.inman.com/2021/01/21/realtyfeed-is-crowdfunding-a-disruptive-social-network-for-real-estate/'
            },
            {
                icon: Gamification,
                title:'Gamification',
                description:'It is based on gamified models to create an intuitive business environment in which users don’t have to undergo hours of training…',
                link:'https://realtyna.com/blog/realtyfeed-generation-z-future-real-estate/',
            },
        ];
        
        this.ContentToggleItems = [
            {
                reversed:true,
                icon:'h',
                description:'Design',
                data: {
                    picture:realtyfeedPicture3,
                    header:'RealtyFeed Design provides user friendly design, where anyone can work easily.',
                    description:'We designed our product to be a user-friendly tool that encourages collaboration among all players in the real estate industry—agents, brokers, real estate associations, multiple listing service (MLS) providers, mortgage providers, sellers, and buyers. '
                }
            },
            {
                reversed:false,
                icon:'h',
                description:'Flexibility',
                data: {
                    picture:realtyfeedPicture4,
                    header:'Realtyna Flexibility gives opportunity to use application smoothly ',
                    description:'RealtyFeed is a hybrid app, a blend of a social network and listing platform that drives leads, grows B2B referrals, and makes B2C sales more flexible.'
                }
            },
            {
                reversed:true,
                icon:'h',
                description:'Track Records',
                data: {
                    picture:realtyfeedPicture5,
                    header:'Proven track record with satisfaction rate 4.9 out of 5',
                    description:'Our current clients come from 96 countries, although our main focus is on the U.S. and Canadian markets, which represent 85%+ of our overall business. We have a proven success rate and a satisfaction rate of 4.9/5 across the board for our products. '
                }
            },
            {
                reversed:true,
                icon:'h',
                description:'Business model',
                data: {
                    picture:realtyfeedPicture3,
                    header:'Proven success and future growth',
                    description:'The company behind RealtyFeed, Realtyna, has been in business for a decade and is profitable.Our most popular product, WPL Pro, is a listing web app. Real estate professionals can use WPL Pro to list their properties on their own website and add other functionality to their platform.'
                }
            },
            {
                reversed:false,
                icon:'h',
                description:'Vision',
                data: {
                    picture:realtyfeedPicture2,
                    header:'Simplifying the real estate industry!',
                    description:'Younger generations entering the real estate market are looking for innovative, user-friendly and exciting tech to help grow their businesses. We believe RealtyFeed is the solution, and our vision is to make it as popular as conventional social networks, with a focus on the real estate space. '
                }
            },
        ];
        this.footerItems = [
            ['Linux',
            'Home',
            'Knowledge',
            'About',],
            ['Windows',
            'About us',
            'Developers',
            'Blog',],
            ['Web',
            'Our\n Team',
            'FAQ',
            'Press',],
            ['Android',
            'Portfolio',
            'Forum',
            'Carrers']
        ];
        this.addressItems = [
            {info:'1234 North Luke lane, South Bend, In 360001',icon: 'h'},
            
            {info:'+0123 456 789',icon: 'h'},
            {info:'mail@sofbox.com',icon: 'h'},
        ];
        this.carouselItems = [
            'CarouselItem 1',
            'CarouselItem 2',
            'CarouselItem 3',
            'CarouselItem 4',
            'CarouselItem 5',
            'CarouselItem 6'
        ];
        this.headerOptions = [
            'Login',
            'Register'
        ];
        this.showcaseData = [
            {
                title:'What is RealtyFeed?',
                description:"RealtyFeed, Realtyna's newest product, is an intuitive social network that combines the real estate market and a communication app into one platform to empower real estate professionals everywhere. Empowered by AI and Machine Learning, it makes real estate transactions easier for Realtors and their clients.",
                buttonText:'Learn More',
                photo:realtyfeedPicture2,
                gradientBackground:false,
                reverse:true,
                link:'https://realtyna.com/realtyfeed/'

            },
            {
                title:'Be the First to Know when RealtyFeed Launches!',
                description:'RealtyFeed will be launched soon. Be the first to be notified and have a chance to get a sneak peek into the cutting-edge features of the next generation of real estate platforms.',
                buttonText:'Sign Up',
                photo:realtyfeedPicture3,
                gradientBackground:false,
                reverse:false,
                link:Config.ROUTES.register.root
            },
            {
                title:'Be the First to Know when RealtyFeed Launches!',
                description:"RealtyFeed will be launched soon. Be the first to be notified and have a chance to get a sneak peek into the cutting-edge features of the next generation of real estate platforms.",
                buttonText:'Sign Up',
                photo:realtyfeedPicture3,
                gradientBackground:true,
                reverse:false,
                link:Config.ROUTES.login
            },
            {
                title:'RealtyFeed is open to investors',
                description:'If you want to be part of the next generation of proptech, join our crowdfunding campaign. RealtyFeed has reached its first investment target during its first month into the crowdfunding campaign. Visit our Republic.co campaign page for more details:',
                buttonText:'READ MORE',
                photo:dataScreenshot,
                gradientBackground:false,
                reverse:true   ,
                link:'https://republic.co/realtyfeed'
            }
        ];
        this.socialNetworkITems = [
            {
                icon: <Instagram />,
                link:'https://www.instagram.com/realtyfeed/'
            },
            {
                icon: <Twitter />,
                link:'https://twitter.com/RealtyFeed1'
            },
            {
                icon: <Facebook />,
                link:'https://www.facebook.com/RealtyFeed.Social/'
            },
            {
                icon: <Linkedin />,
                link:'https://www.linkedin.com/company/realtyfeed/'
            },
        ];
    
        this.firstSectionRef = React.createRef();

    }
    

    handleImageClick = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
      };


    mobileHeaderItemsHandler = () => {
        this.setState({
            mobileHeaderItemsOpen: !this.state.mobileHeaderItemsOpen
        })
    }

    activeIndexHandler = (index) => {
        this.setState({
            activeIndex: index,
        })
    }

    routeHandler = (index) => {
        if (index == 0) {
            this.props.history.push(
                Config.ROUTES.login
            )

        }
        if ( index === 1) { 
            this.props.history.push(
                Config.ROUTES.register.root
            )
        }
    }

    scrollDownHandler = () => {
        const sectionHeight = this.firstSectionRef.current.clientHeight;
        window.scroll({top: sectionHeight, behavior: 'smooth' })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrow:true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            autoplay:true,
            pauseOnFocus:true,
            initialSlide:1,
            responsive: [
              {
                breakpoint:9999,
                settings:'unslick'
              },
              {
                breakpoint:1020,
                settings: {
                  slidesToShow: 1,
                  arrow:true,
                }
              },
            ]
            
          };
        return (
            <Fragment>
                <HeaderWrapper >
                    <HeaderSection>
                        <LogoWrapper 
                            src={Logo}
                        />
                    </HeaderSection>
                    <HeaderRight isActive={this.state.mobileHeaderItemsOpen} onClick={this.mobileHeaderItemsHandler}>
                        <BurgerMenuWrapper>
                            <div />
                            <div />
                            <div />
                        </BurgerMenuWrapper>  
                        <HeaderOptions className='options'>
                            {this.headerOptions.map((item , index) => {
                                return (
                                    <NavItem className='item'  key={index} onClick={() => this.routeHandler(index)}>
                                        {item}
                                    </NavItem>
                                );
                            })}
                        </HeaderOptions>
                    </HeaderRight>
                </HeaderWrapper>
                <FirstSectionWrapper id="top" ref={this.firstSectionRef}>
                    <BackgroundVideoWrapper autoPlay={'autoplay'} loop='loop' muted >
                        <source  src={RealtyFeedVideo} type='video/mp4' />
                    </BackgroundVideoWrapper>
                    <BackLayer />
                    <FirstSectionContainer>
                        <div className='bottom-text' >
                            RealtyFeed is the next generation of Proptech.
                        </div>
                        <ButtonWrapper>
                            <DownloadButton onClick={() => this.routeHandler(1)}>
                                Sign up
                            </DownloadButton>
                        </ButtonWrapper>
                        <div className='mouse-wrap'>
                            <MouseSvg onClick={ this.scrollDownHandler } />
                        </div>
                    </FirstSectionContainer>
                </FirstSectionWrapper>
                <LandingPageShowcaseSection data={this.showcaseData[0]} />
                <LandingPageShowcaseSection data={this.showcaseData[2]} />
                <FifthSectionWrapper>
                        <SlickCarouselComponent
                            containerStyle={SpecialistContentWrapper}
                            styles={CardWrapper}
                            settings={ settings }
                        >
                            {this.FifthItems.map((item, index) => {
                                    return  (
                                        <CardWrapper key={index} middle={ index === 1 } >
                                            <img src={item.icon} alt='iconpic' />
                                            <CardContentTop>
                                                <CardHeader>
                                                    {item.title}
                                                </CardHeader>
                                                <CardDescription>
                                                    <div>
                                                        {item.description}
                                                    </div>
                                                </CardDescription>
                                            </CardContentTop>
                                            <ReadMoreWrapper href={item.link} target="_blank">
                                                Learn More
                                            </ReadMoreWrapper>
                                        </CardWrapper>
                                    );
                                })}
                        </SlickCarouselComponent>
                </FifthSectionWrapper>
                <SecondSectionWrapper>
                    <HeaderText centered>
                        Main Features
                    </HeaderText>
                    <ItemsWrapper>
                        {this.items.map((item, index) => {
                            return (
                                <ItemContainer key={index}>
                                    <div className='icon-wrapper'>
                                        <img 
                                            src={ item.icon }
                                            alt='pic'
                                        />
                                    </div>
                                    <div className='bottom-container'>
                                        <ItemText>
                                            {item.title}
                                        </ItemText>
                                        <SecondDescriptionText>
                                            {item.description}
                                        </SecondDescriptionText>
                                    </div>
                                </ItemContainer>
                            );
                        })}
                    </ItemsWrapper>
                    <ScrollBox onClick={this.handleImageClick}>
                        <img 
                            src={ ArrowTop }
                            alt='arrowtop'
                        />
                    </ScrollBox>
                </SecondSectionWrapper>
                <FooterWrapper>
                    <div className='topside'>
                        <div className='topside-item'>
                            <HeaderItem>
                               Contact us:
                            </HeaderItem>
                            <HeaderItem>
                                info@realtyfeed.com
                            </HeaderItem>
                        </div>
                        <div className='topside-item'>
                            <HeaderItem>
                                Follow  us:
                            </HeaderItem>
                            <IconWrapper>
                                {
                                    this.socialNetworkITems.map((item, index) => {
                                        return (
                                            <IconItem notFirst={ index !== 0 } key={index} rel="noopener noreferrer" href={item.link} target="_blank">
                                                {item.icon}
                                            </IconItem>
                                        )
                                    })
                                }
                            </IconWrapper>
                        </div>
                    </div>
                    <div className='bottomside'>
                        Copyright © 2021, All rights reserved.
                    </div>
                </FooterWrapper>
            </Fragment>
        )
    }
}


export default withRouter(LandingPageTemplate);
