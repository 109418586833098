import React, { Component } from 'react';
import { Button, WhiteArrowDown } from './Styled';
import { sendAsyncRequest } from '../../../Utils';
import PropTypes from 'prop-types';

class ButtonFollowConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFollowBack: false,
            requestStatus: 0,
            isMyRequest: false,
            hasFollowed: false,
            userId: 0,
            active: props.active,
        };
    }

    UNSAFE_componentWillMount() {
        const {
            showFollowBack,
            requestStatus,
            isMyRequest,
            hasFollowed,
            userID,
        } = this.props;
        this.setState({
            showFollowBack,
            requestStatus,
            isMyRequest,
            hasFollowed,
            userID,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {
            showFollowBack,
            requestStatus,
            isMyRequest,
            hasFollowed,
            userID,
        } = nextProps;
        if (
            showFollowBack !== this.state.showFollowBack ||
            requestStatus !== this.state.requestStatus ||
            isMyRequest !== this.state.isMyRequest ||
            hasFollowed !== this.state.hasFollowed ||
            userID !== this.state.userID
        ) {
            this.setState({
                showFollowBack,
                requestStatus,
                isMyRequest,
                hasFollowed,
                userID,
            });
        }
    }

    connectClickHandler = async (connectText) => {
        const { userId } = this.state;
        switch (connectText) {
            case 'Connect':
                await sendAsyncRequest('/connection/send/', 'post', {
                    receiver_id: userId,
                });
                this.setState({
                    requestStatus: 1,
                    isMyRequest: true,
                    active: false,
                });
                break;
            case 'Confirm':
                await sendAsyncRequest('/connection/approve/', 'post', {
                    sender_id: userId,
                    status: 2,
                });
                this.setState({
                    requestStatus: 2,
                    active: true,
                });
                break;
            case 'Disconnect':
            case 'Cancel':
                await sendAsyncRequest('/connection/approve/', 'post', {
                    sender_id: userId,
                    status: 0,
                });
                this.setState({
                    requestStatus: 0,
                    isMyRequest: false,
                    active: true,
                });
                break;
            default:
        }
    };

    followClickHandler = async () => {
        const { hasFollowed, userId } = this.state;
        if (hasFollowed === false) {
            await sendAsyncRequest('/followers/create/', 'post', {
                user_id: userId,
            });
        } else {
            await sendAsyncRequest('/followers/remove/' + userId, 'delete', {});
        }
        this.setState((prevState) => {
            return {
                hasFollowed: !hasFollowed,
                showFollowBack: false,
                active: !prevState.active,
            };
        });
    };

    render() {
        const {
            requestStatus,
            hasFollowed,
            isMyRequest,
            showFollowBack,
        } = this.state;

        let connectText = 'Connect';
        if (requestStatus === 2) {
            connectText = 'Disconnect';
        } else if (requestStatus === 1 && isMyRequest === true) {
            connectText = 'Cancel';
        } else if (requestStatus === 1 && isMyRequest === false) {
            connectText = 'Confirm';
        }

        return (
            <Button
                active={this.state.active}
                styled={this.props.styled}
                onClick={async () => {
                    if (this.props.type === ButtonFollowConnectType.CONNECT) {
                        await this.connectClickHandler(connectText);
                    }
                    if (this.props.type === ButtonFollowConnectType.FOLLOW) {
                        await this.followClickHandler();
                    }
                }}
            >
                {this.props.type === ButtonFollowConnectType.CONNECT &&
                    this.state.requestStatus !== null &&
                    connectText}
                {this.props.type === ButtonFollowConnectType.JOIN &&
                    this.state.requestStatus !== null &&
                    'Join'}
                {this.props.type === ButtonFollowConnectType.FOLLOW &&
                    (this.state.requestStatus !== 2 && hasFollowed !== null) &&
                    (hasFollowed && !showFollowBack ? 'Unfollow' : 'Follow')}
                {this.props.type === ButtonFollowConnectType.BADGE && (
                    <>
                        Request Badge
                        <WhiteArrowDown />
                    </>
                )}
            </Button>
        );
    }
}

export default ButtonFollowConnect;

export const ButtonFollowConnectType = {
    CONNECT: 'connect',
    FOLLOW: 'follow',
    BADGE: 'badge',
    JOIN: 'join',
};

ButtonFollowConnect.propTypes = {
    type: PropTypes.string.isRequired,
};
