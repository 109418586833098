import styled from 'styled-components';

export const AlbumWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`;
export const HeaderWrapper = styled.div`
    height: 80px;
    background: rgba(49, 49, 54, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: none;
    z-index: 2;
`;

export const HeaderText = styled.span`
    color: white;
    font-size: 26px;
    margin-left: 20px;
    margin-top: 24px;
    display: flex;
`;

export const Options = styled.div`
    color: white;
    font-size: 26px;
    margin-top: 25px;
    margin-left: 20px;
`;

export const ImgWrapper = styled.div`
z-index:1;
position:absolute;
width: 100%;
`;


export const TileWrap = styled.div`
margin-bottom:-4.5px;
margin-left:5px;
width:100%;
cursor:pointer;
	img{
		width: 100%;
	}
`;

export const FullScreenImageWrapper = styled.div`
 position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;

img {
    width:100%;
    height:100%;
}
`;

export const BottomNavWrapper = styled.div`
height: 187px;
background: rgba(49, 49, 54, 0.8);
position:absolute;
left: 0;
bottom:0;
width: 100%;
border-bottom: none;
z-index: 2;

`;

export const BottomNavWrapperStyle = styled.div`
display:flex;
flex-direction:column;
padding:2%;
justify-content:space-between;
`;

export const LikeNumber = styled.span`
font-size:20px;
color:white;
`;

export const MainHeader = styled.span`
font-size:20px;
font-weight:bold;
color:white;

`;

export const MainInformation = styled.span`
 font-size: 20px;
 color:white;

`;

export const BackToAlbumGallery = styled.div`
position:absolute;
top:0;
background: rgba(49, 49, 54, 0.8);
right:0;
width:60px;
height:60px;
color:white;
border-bottom-left-radius:5px;
display:flex;
justify-content:center;
align-items:center;

`