import React, {Component} from "react";
import {WizardContent, WizardPage, WizardWrapper} from "../WizardPages/Styled";
import {ErrorMessage} from "../RegisterForm/Styled";
import {ClearFloats} from "../BaseTemplate/Styled";
import {FormWrapper} from "../WizardTemplateDynamicContent/Styled";
import DynamicField from "../FormDynamicField";
import Navigation from "../WizardItemNavigation";
import Config from "../../../config";
import {objIsEqual, sendAsyncRequest} from "../../../Utils";
import {withRouter} from "react-router-dom";

/**
 * @param {{
 *     all_fields, init_value, field_order, step_name
 * }} data
 */

class WizardTemplateDynamic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isPageChanged: false,
            fields: {},
            errorFields: {}
        }
    }

    componentDidMount() {
        this.initState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.template && !objIsEqual(prevProps.template.all_fields, this.props.template.all_fields)) {
            this.initState();
        }
    }

    initState = () => {
        const templateFields = [...this.props.template.all_fields];
        let fields = {};
        templateFields.forEach(
            field => {
                fields = {
                    ...fields,
                    // TODO :: Change 'init_value' after Server Implementation (as needed)
                    ...field.init_value
                }
            }
        );
        this.setState({
            fields
        });
    }

    handleNextPage = async () => {
        this.setState({
            isLoading: true
        });
        const updatedFields = {...this.state.fields},
              postFields = [];

        for (let [key, value] of Object.entries(updatedFields)) {
            const valueOptions = [];
            key = key.split(Config.SPECIAL_SEPARATOR).pop();
            if (Array.isArray(value)) { // Array
                const tempValue = [];
                value.forEach(
                    item => {
                        if (item.value) { // Normal Fields
                            tempValue.push(
                                item.value
                            )
                        } else if (item.imgId) { // Image Fields
                            tempValue.push(
                                item
                            )
                        } else if (item.latitude) { // Geo Location Fields
                            tempValue.push(
                                item
                            )
                        }
                        // ignore other auto generated Arrays
                    }
                )
                value = tempValue;
            } else if (typeof value === "object" && value !== null) { // Object
                if (value.value && value.text) { // ignore other Objects
                    if (value.hasOwnProperty("options")) {
                        value.options.forEach(
                            opt => {
                                valueOptions.push(opt.value)
                            }
                        )
                    }
                    value = value.value;
                } else {
                    value = null;
                }
            }
            try {
                key = parseInt(key);
                if (value) {
                    postFields.push({
                        "field_value_id": key,
                        "field_value": value,
                        "field_value_options": valueOptions
                    });
                }
            } catch (e) {
                console.log("key not a number", e);
            }
        }
        //console.log(postFields);
        const url = '/regional/' + this.props.category + '/update/';
        const result = await sendAsyncRequest(url, 'put', {
            data: postFields
        });
        if (result.status !== 200) {
            this.setState({
                errorFields: {
                    ...result.body.errorFields
                },
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                this.props.history.push(
                    this.props.nextUrl
                );
            })
        }
    }

    clearPageError = () => {
        const errorFields = {...this.state.errorFields};
        if (errorFields.hasOwnProperty("pageError")) {
            delete errorFields.pageError;
            this.setState({
                errorFields
            });
        }
    }

    handleIsPageChanged = () => {
        // console.log('handleIsPageChanged');
    }

    render() {

        const templateFields = [...this.props.template.all_fields],
              fields = {...this.state.fields};
              //console.log(templateFields);

        templateFields.sort((curr, next) => {
            return curr.field_order - next.field_order;
        });

        return (
            <WizardPage>

                <h1>{this.props.template.step_name}</h1>

                <WizardContent>

                    <WizardWrapper>

                        {
                            (this.state.errorFields.pageError) &&
                            <>
                                <ErrorMessage>
                                    {this.state.errorFields.pageError}
                                    <i onClick={this.clearPageError}/>
                                </ErrorMessage>
                                <ClearFloats/>
                            </>
                        }

                        <FormWrapper>

                            {
                                templateFields.map(
                                    (field, index) =>
                                        <DynamicField
                                            key={field.field_name + index}
                                            field={field}
                                            initValue={fields[field.field_name]}
                                            thisRef={this}
                                        />
                                )
                            }
                            <ClearFloats/>

                        </FormWrapper>

                    </WizardWrapper>

                    <Navigation
                        type={this.props.wizardType}
                        fields={this.state.fields}
                        isFinal={(this.props.nextUrl === Config.ROUTES.homePage)}
                        loading={this.state.isLoading}
                        pageChanged={this.handleIsPageChanged}
                        nextPage={this.handleNextPage}
                        prevPage={this.props.prevUrl} // Only for "next_prev" WizardType
                        buttonText={this.props.nextTitle} // Only for "draft" WizardType
                    />

                </WizardContent>

            </WizardPage>
        )
    }
}

export default withRouter(WizardTemplateDynamic);