import styled, { css } from 'styled-components';
import { InputTextWrapperStyles } from '../LoginForm/Styled';

export const RadioWrapper = styled.div``;

export const SelectWrapperStyles = css`
    ${InputTextWrapperStyles};
    margin-top: 50px;
    margin-bottom: 20px;
`;
