import React, { Component } from 'react';
import {
    ProfileImageStyle,
    ModalTopWrapper,
    ModalCommentProfile,
    ModalProfileSide,
    CommentTime,
    ModalCloseStyles,
    ModalTitleStyles,
    ProfileName,
    TopCommentWrapper,
    TopComment,
    SocialIconsWrapper,
    AmountOfLikes,
    ModalWrapper,
    DisplayWrapper,
    ExpandSection,
    CommendExpand,
    LoadButton,
    ModalBottomWrapper,
    InputWrapper,
    SearchOptions,
    CommentInput,
    Menu,
    MenuItem,
    DialogText,
    DialogButtonsWrapper,
    ButtonTextColoredStyles,
    ButtonTextWhiteStyles,
    DialogWrapper,
    DialogStyles,
    DialogDesc,
} from './Styled';

import ButtonText from '../FormButtonText';
import Modal from '../Modal';
import { timeAgo } from '../../../Utils';
import IconNew from '../IconNew';

class CommentsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxCommentsToShow: 4,
            dialogOpen: false,
            dialogTitle: '',
            commentInput: '',
            step: 0,
        };
    }

    onLoadMore = () => {
        this.setState((prevState) => {
            return {
                maxCommentsToShow: prevState.maxCommentsToShow + 5,
            };
        });
    };

    onLoadAll = () => {
        this.setState({ maxCommentsToShow: this.props.comments.length });
    };

    getComponent = () => {
        switch (this.state.step) {
            case Steps.MENU:
                return (
                    <>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.REPORT,
                                    dialogTitle: 'Report Comment',
                                })
                            }
                        >
                            <p>Report Comment</p>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.DELETE,
                                    dialogTitle: 'Delete Comment',
                                })
                            }
                        >
                            <p>Delete Comment</p>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.HIDE,
                                    dialogTitle: 'Hide Comment',
                                })
                            }
                        >
                            <p>Hide Comment</p>
                        </MenuItem>
                    </>
                );
            case Steps.REPORT:
                return (
                    <>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.THANKS,
                                    dialogTitle: 'Thanks for your cooperation',
                                })
                            }
                        >
                            <p>Hate speech, racism and violence</p>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.THANKS,
                                    dialogTitle: 'Thanks for your cooperation',
                                })
                            }
                        >
                            <p>Hate speech, racism and violence</p>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.THANKS,
                                    dialogTitle: 'Thanks for your cooperation',
                                })
                            }
                        >
                            <p>Misinformation</p>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    step: Steps.THANKS,
                                    dialogTitle: 'Thanks for your cooperation',
                                })
                            }
                        >
                            <p>It's a spam</p>
                        </MenuItem>
                    </>
                );
            case Steps.DELETE:
                return (
                    <DialogWrapper>
                        <DialogText>
                            Are you sure to delete your comment?
                        </DialogText>
                        <DialogButtonsWrapper>
                            <ButtonText
                                onClick={() => null}
                                styled={ButtonTextWhiteStyles}
                            >
                                No
                            </ButtonText>
                            <ButtonText
                                onClick={() => null}
                                styled={ButtonTextColoredStyles}
                            >
                                Yes
                            </ButtonText>
                        </DialogButtonsWrapper>
                    </DialogWrapper>
                );
            case Steps.HIDE:
                return (
                    <DialogWrapper>
                        <DialogText>
                            Are you sure to hide this comment?
                        </DialogText>
                        <DialogButtonsWrapper>
                            <ButtonText
                                onClick={() => null}
                                styled={ButtonTextWhiteStyles}
                            >
                                No
                            </ButtonText>
                            <ButtonText
                                onClick={() => null}
                                styled={ButtonTextColoredStyles}
                            >
                                Yes
                            </ButtonText>
                        </DialogButtonsWrapper>
                    </DialogWrapper>
                );
            case Steps.THANKS:
                return (
                    <DialogWrapper>
                        <DialogDesc>
                            Your feedback is important to keep ourcommunity
                            safe.
                        </DialogDesc>
                    </DialogWrapper>
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <Modal
                isOpen={this.props.modalOpen}
                handleClose={this.props.onModalClose}
                containerWidth="790"
                containerHeight={this.props.modalHeight}
                containerSizeUnit="px"
                containerPadding="0"
                title={this.props.title}
                titleStyled={ModalTitleStyles}
                closeStyled={ModalCloseStyles}
            >
                <ModalWrapper containerHeight={this.props.modalHeight}>
                    {this.props.comments &&
                        this.props.comments.map(
                            (item, index) =>
                                index < this.state.maxCommentsToShow && (
                                    <React.Fragment key={index}>
                                        <ModalTopWrapper>
                                            <ModalCommentProfile>
                                                <ModalProfileSide>
                                                    <ProfileImageStyle
                                                        src={item.owner.profile}
                                                        alt="profile"
                                                    />
                                                    <ProfileName>
                                                        {item.owner.full_name}
                                                    </ProfileName>
                                                </ModalProfileSide>
                                                <ModalProfileSide>
                                                    <CommentTime>
                                                        {timeAgo(item.add_date)}
                                                    </CommentTime>
                                                    <Menu
                                                        onClick={() =>
                                                            this.setState({
                                                                dialogOpen: true,
                                                                dialogTitle:
                                                                    'Please select an option',
                                                            })
                                                        }
                                                    />
                                                </ModalProfileSide>
                                            </ModalCommentProfile>
                                            <TopCommentWrapper>
                                                <TopComment>
                                                    {item.text}
                                                </TopComment>
                                            </TopCommentWrapper>
                                            <DisplayWrapper>
                                                {/* {item.commentPhoto.map(
                                            (item, index) => (
                                                <RealEstateImg
                                                    firstElement={
                                                        index === 0
                                                            ? '0px'
                                                            : '10px'
                                                    }
                                                    key={index}
                                                    src={item}
                                                    alt="RealEstate"
                                                />
                                            )
                                        )} */}
                                            </DisplayWrapper>
                                            <SocialIconsWrapper>
                                                <IconNew
                                                    bp1="-56"
                                                    bp2="-118"
                                                    iwidth="22"
                                                    iheight="19"
                                                />
                                                <AmountOfLikes>
                                                    <span>{item.like}</span>
                                                </AmountOfLikes>
                                                <div>
                                                    <IconNew
                                                        bp1="-283"
                                                        bp2="-104"
                                                        iwidth="21"
                                                        iheight="21"
                                                        className="share-icon"
                                                    />
                                                </div>
                                            </SocialIconsWrapper>
                                        </ModalTopWrapper>
                                    </React.Fragment>
                                )
                        )}
                    {this.props.comments &&
                        this.props.comments.length >
                            this.state.maxCommentsToShow && (
                            <ExpandSection>
                                <LoadButton onClick={this.onLoadMore}>
                                    Load more
                                </LoadButton>
                                <CommendExpand onClick={this.onLoadAll}>
                                    Show all comments
                                </CommendExpand>
                            </ExpandSection>
                        )}
                </ModalWrapper>
                <ModalBottomWrapper>
                    <InputWrapper>
                        <CommentInput
                            placeholder="Write a Comment ..."
                            value={this.state.commentInput}
                            onChange={(e) => {
                                this.setState({
                                    commentInput: e.target.value,
                                });
                            }}
                        />
                    </InputWrapper>
                    <SearchOptions>
                        <IconNew bp1="0" bp2="-115" iwidth="27" iheight="27" />
                        <IconNew
                            bp1="-32"
                            bp2="-118"
                            iwidth="22"
                            iheight="20"
                        />
                    </SearchOptions>
                </ModalBottomWrapper>
                <Modal
                    isOpen={this.state.dialogOpen}
                    handleClose={() =>
                        this.setState({ dialogOpen: false, step: 0 })
                    }
                    containerWidth="450"
                    containerHeight={'auto'}
                    containerSizeUnit="px"
                    containerPadding="0"
                    title={this.state.dialogTitle}
                    titleStyled={DialogStyles}
                    closeStyled={ModalCloseStyles}
                >
                    {this.getComponent()}
                </Modal>
            </Modal>
        );
    }
}

export default CommentsModal;

const Steps = {
    MENU: 0,
    REPORT: 2,
    DELETE: 1,
    HIDE: 4,
    THANKS: 3,
};
