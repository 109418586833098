import styled from 'styled-components';

export const AddNewPackageColumnWrapper = styled.div`
background:white;

`;

export const TopWrapper = styled.div`
height:191px;
padding-top:20px;
display:flex;
border-bottom:1px solid ${(props) => props.theme.viridianGreen};
flex-direction:column;
justify-content:space-between;
padding-left:30px;
padding-bottom:28.5px;
background-color: rgba(26, 146, 141, 0.05);
.header {
    display:flex;
    align-items:center;
    text-align:right;
    padding-right:20px;
    align-self:flex-end;

    span {
        font-size:22;
        color:${(props) => props.theme.darkBlue};
    }
}

.totalsection {
    display:flex;
    flex-direction:column;
    padding-right:30px;
}
`;

export const BackButton = styled.button`
    border: 0;
    background: transparent;
    
    i {
        top: -4px;
    }
`;


export const AmountText = styled.span`
 color:${(props) => props.budget ?  props.theme.textBlack : props.theme.mainFontColor};
 font-size:16px;
 font-weight: 600;
`;

export const TotalItem = styled.div`
display:flex;
flex-direction:row;
margin-top:10px;
align-items:center;
justify-content:space-between;
 span {

    font-size:16px;
 }
 .budget { 
    color:${(props) => props.theme.textBlack};
    font-size:16px;
     }
`;


export const PremiumContainer = styled.div`
    padding: 30px 0;
    float: right;
    button {
        background: transparent;
        background-image: url('/assets/img/premium.png');
        border: none;
        width: 100%;
        height: 99px;
        color: ${(props) => props.theme.white};
        padding: 25px;
        font-family: ${(props) => props.theme.mainFont};
        cursor: pointer;

        h1 {
            font-size: 28px;
            margin: 0;
            text-align: left;
            width: 267px;
            float: left;
        }
        h3 {
            font-size: 19px;
            margin: 0;
            text-align: left;
            font-weight: lighter;
            width: 267px;
            float: left;
        }

        span {
            width: 63px;
            height: 63px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: ${(props) => props.theme.orchid};
            float: right;
            margin: -30px 0 0 0;

            > h2 {
                font-size: 22px;
                margin: 0;
            }

            > h4 {
                font-size: 12px;
                margin: 0;
            }
        }
    }
`;



export const BodyWrapper = styled.div`
padding-top:30px;
padding-left:30px;
padding-right:30px;
padding-bottom:44px;
`;

export const BodyHeader = styled.div`
margin-top:${props => props.margintop && props.margintop};
color:${(props) => props.theme.darkBlue};
font-size:18px;

`;

export const PackageCardWrapper = styled.div`
  border-radius: 5px;
  border: solid 1px #07aed0; 
  margin-top:20px;
`;

export const AddPackageTopSide = styled.div`
height:42px;
padding-left:12px;
display:flex;
align-items:center;
justify-content:flex-start;
position:relative;

span {
    font-weight:lighter;
    font-size:18px;
    color:${(props) => props.theme.mainFontColor};
    margin-left:10px;    
}

`;

export const AddPackageMiddleSide = styled.div`
height:30px;
background:${props => props.days === 0 ? props.theme.paleGray : 'rgba(7, 150, 179, 0.1)'  };
display:flex;
padding-left:${props => props.days === 0 ? '27px' : '59px' };
padding-right:${props => props.days === 0 ? '27px' : '59px' };
align-items:center;
justify-content:space-between;

span {
    font-size:12px;
    color:${(props) => props.theme.textBlack};
}
`;

export const AddPackageBottomSide = styled.div`
height:28px;
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
padding-left:12px;
padding-right:12px;
`;

export const DeleteButton = styled.button`
width:18px;
height:18px;
border: solid 1px #e3e3e3;
background-color: #ffffff;
display:flex;
cursor:pointer;
align-items:center;
justify-content:center;
position:absolute;
border-bottom-left-radius:5px;
top:0;
right:0;

`;

export const DaysAmount = styled.div`
   color:${(props) => props.theme.textBlack};
   font-size:12px;
   padding-left:10px;
   padding-right:10px;

   span {
        color:#2e2e33;
        font-size:12px;
        font-weight:lighter;
        margin-right:5px;

   }
`;

export const PlusMinusButtonWrapper = styled.div`
display:flex;
align-items:center;
`;

export const SquareButton = styled.button`
display:flex;
cursor:pointer;
align-items:center;
justify-content:center;
width:18px;
height:18px;
background:white;
cursor:pointer;
border-radius: 2px;
border: solid 1px #e3e3e3;
`;

export const BottomLeftSide = styled.div`

.date {
color:#19257c;
font-size:12px;
font-weight:lighter;
}

.dateType {
    color:${(props) => props.theme.textBlack};
    font-weight:lighter;
    font-size:12px;
}
`;

export const BottomRightSide = styled.div`
color:#2e2e33;
font-size:12px;
font-weight:lighter;

.cost {
    color:${(props) => props.theme.textBlack};
    font-weight:normal;
}
`;

export const ZipcodeInputWrapper = styled.div`
display:flex;
flex-direction:row;
margin-top:20px;
align-items:center;
justify-content:space-between;
border: solid 1px #e3e3e3;
border-radius:5px;
height:50px;
padding-right:16px;
padding-left:16px;

.left {
    height:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
}

input {
    margin-left:10px;
    border:none;
    padding-bottom:3.5px;
    border-bottom: solid 1px ${(props) => props.theme.grayDarkBorder};
    width:150px;
    color: ${(props) => props.theme.mainFontColor};
    font-size:18px;
    font-weight:lighter;

    &::placeholder {
       color: ${(props) => props.theme.mainFontColor};
       font-size:18px;
       font-weight:lighter;
   }
}

`;

export const CirclesWrapper = styled.div`
display:flex;
align-items:center;

.circle1 {
    width:8px;
    height:8px;
    border-radius:100%;
    background-color: ${(props) => props.theme.mainFontColor};
}
.circle2 {
    width:8px;
    height:8px; 
    border-radius:100%;
    opacity:0.5;
    margin-left:6px;
    margin-right:6px;
    background-color: ${(props) => props.theme.mainFontColor};
}
.circle3 {
    width:8px;
    opacity: 0.3;
    border-radius:100%;
    height:8px;
    background-color: ${(props) => props.theme.mainFontColor};
}
`;

export const AddzipCodeButton = styled.button`
 height: 50px;
 display:flex;
 margin-top:60px;
 align-items:center;
 border:none;
 border-radius: 5px;
 justify-content:center;
 width:100%;
 background-color:${(props) => props.theme.grayLightBg};
 color:${(props) => props.theme.textBlack};
 font-size:16px;

`;

export const AddPackageBottomWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`;

export const AddPackageBottomSideWrapper = styled.div`
margin-top:30.5px;
padding-top:15.5px;
border-top:${props => props.left ? `2px solid #19257c` : '2px solid #7106c4'};
width:45%;
display:flex;
flex-direction:column;
align-items:flex-start;

.header {
    font-size:14px;
    color:${(props) => props.theme.mainFontColor};
    line-height:26px;
}

.price {
    font-size:24px;
    color:${(props) => props.theme.textBlack};
    line-height:26px;
}

.header-purple {
    font-size:12px;
    color:#580697;
    line-height:26px;
}

.price-gray {
    font-size:24px;
    line-height:26px;
    color:${(props) => props.theme.mainFontColor};
}
`;

export const  ContinueButton = styled.button`
border:none;
background-image: linear-gradient(to right, #07aed0, #094fd0);
height:50px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
margin-top:75px;
font-size:16px;
border-radius: 5px;
color:white;
`;