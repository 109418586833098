import React, {Component} from "react";
import {ButtonRadioCheckStyles} from "../WizardPagePropertyBasic/Styled";
import ButtonRadioCheck from "../FormButtonRadioCheck";
import {getInputNameAndValue, objIsEqual} from "../../../Utils";
import {ErrorMessage} from "../FormInputText/Styled";
import {Wrapper} from "./Styled";

class FormButtonRadioCheckGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: -1
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && !objIsEqual(prevProps.value, this.props.value)) {
            const itemValue = this.props.list.find(
                item => item.value === this.props.value.value // value = {value, text}
            );
            this.setState({
                inputValue: (itemValue) ? itemValue.value : -1
            });
        }
    }

    radioGroupChangeHandler = (e) => {
        const states = this.props.states;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                    {name, value} = getInputNameAndValue(e),
                    fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                    errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[name] = {
                    value: parseInt(value),
                    text: (this.props.list.find(x => x.value === parseInt(value))).text
                };
                errorFields[name] = null;
                this.setState({
                    inputValue: parseInt(value)
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {
        return (
            <Wrapper>
                {
                    this.props.list.map(
                        (item, index) => {
                            return (
                                <ButtonRadioCheck
                                    key={this.props.id + "_" + index}
                                    id={this.props.id + "_" + index}
                                    name={this.props.id}
                                    label={item.text}
                                    styled={
                                        (!this.props.outline)
                                            ? ButtonRadioCheckStyles
                                            : null
                                    }
                                    value={item.value}
                                    checked={(item.value === this.state.inputValue)}
                                    onChange={this.radioGroupChangeHandler}
                                />
                            )
                        }
                    )
                }
                {
                    (this.state.errorMessage) &&
                    <ErrorMessage>
                        {this.props.errorMessage}
                    </ErrorMessage>
                }
            </Wrapper>
        )
    }
}

export default FormButtonRadioCheckGroup;