import styled, {css} from "styled-components";
import {
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    InputTextWrapperStyles
} from "../LoginForm/Styled";

export const Grid = styled.div`
    flex: ${props => (props.size) ? (props.size) : 1};
    ${
        props => props.styled
    };
`;

export const Break = styled.div`
    width: 100%;
    flex-basis: 100%;
    height: 0;
`;

export const NoComponent = styled.div`
    color: ${props => props.theme.textBlack};
    line-height: 18px;
    background: ${props => props.theme.noticeRedLight};
    padding: 15px 15px 0;
    border-radius: 5px;
    width: 100%;
    position: relative;
    small {
        font-size: 14px;
        background: ${props => props.theme.textBlack};
        color: white;
        padding: 0 3px;
        margin-left: 5px;
        border-radius: 3px;
    }
    pre {
        font-size: 14px;
        line-height: 18px;
    }
    :after {
        content: "Only Shows on Development Mode";
        position: absolute;
        right: 15px; bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.noticeRed};
    }
`;

export const InputTextDynamicStyles = css`
    ${InputTextStyles};
`;

export const InputTextWrapperDynamicStyles = css`
    ${InputTextWrapperStyles};
    margin-bottom: 55px;
`;

export const InputLabelAnimateDynamicStyles = css`
    ${InputLabelAnimateStyles};
    font-size: 20px;
    color: ${props => props.theme.mainFontColor};
`;

export const InputLabelAfterAnimateDynamicStyles = css`
    ${InputLabelAfterAnimateStyles};
`;

export const PageTitle = styled.h3`
    font-size: 24px;
    font-weight: normal;
    color: ${props => props.theme.textBlack};
    margin: 0;
    span {
        margin: 0;
        position: relative;
        top: 1px;
    }
`;

export const InputNote = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 10px 18px;
    margin-bottom: 40px;
    background: ${props => props.theme.grayBgBig};
    font-size: 18px;
    border-radius: 5px;
    font-weight: lighter;
    p {
        margin: 0;
    }
    &:before {
        content: "Note:";
        color: ${props => props.theme.noticeRed};
        font-weight: normal;
        margin-right: 10px;
    }
`;

export const SwitchBoxStyles = css`
    width: 84px; height: 37px;
    border-radius: 40px;
    border: solid 1px ${props => props.theme.grayBgInput};
    box-sizing: border-box;
    i em {
        box-sizing: border-box;
        width: 76px; height: 31px;
        top: 2px; left: 3px;
    }
    i em:before {
        width: 21px; height: 21px;
        left: 5px; bottom: 5px;
    }
    input:checked + i em:before {
        transform: translateX(45px);
    }
    input:checked + i em {
        background-color: ${props => props.theme.noticeGreen};
    }
    b {
        position: absolute;
        right: 13px;
        top: 8px;
        width: 19px;
        height: 19px;
    }
    b:before, b:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 19px;
        width: 4px;
        background-color: #fff;
        border-radius: 10px;
    }
    b:before {
        transform: rotate(45deg);
    }
    b:after {
        transform: rotate(-45deg);
    }
    input:checked + i b {
        display: none;    
    }
    u {
        display: none;
        position: absolute;
        top: 6px; left: 17px;
        transform: rotate(45deg);
        width: 7px; height: 16px;
        border-radius: 4px;
        border-bottom: 4px solid #fff;
        border-right: 4px solid #fff;
    }
    input:checked + i u {
        display: block;
    }
`;

export const SelectButtonStyles = css`
    height: 55px;
    color: #fff;
    font-size: 22px;
    background: ${props => props.theme.grayBgButton};
    border: none;
    border-radius: 5px;
    padding: 0 30px 0 60px;
    position: relative;
    top: 30px;
    margin-left: 30px;
    &:after, &:before {
        content: "";
        position: absolute;
        background: #fff;
        top: 50%;
        transform: translateY(-50%);
    }
    &:after {
        width: 19px; height: 3px;
        left: 25px;
    }
    &:before {
        width: 3px; height: 19px;
        left: 33px;
    }
`;