





export const temporaryData = [
    {
        id:0,
        sponsored:5,
        featured:3,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://paragraphhotels.com/sites/default/files/styles/photo_slider_thumb/public/2018-12/Paragraph_146841.jpg?itok=xB1OTvop',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'sponsored'
        
    },
    {
        id:1,
        likes:500,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'privileged'
        
    },
    {
        id:2,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'privileged'
        
    },
    {
        id:3,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'sponsored'
        
    },
    {
        id:3,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'sponsored'
        
    },
    {
        id:3,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'featured'
        
    },
    {
        id:3,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'featured'
        
    },
    {
        id:3,
        likes:135,
        views:1200,
        comments:85,
        shares:17,
        photos: [
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 1,},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 2},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036',  id: 3},
            {photo:'https://i.ytimg.com/vi/Q99aeM7lhP4/hqdefault.jpg',id: 4},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
            {photo:'https://images.adsttc.com/media/images/5d9b/f5a8/284d/d1b9/ac00/0071/newsletter/featured_image.jpg?1570502036'},
        ],
        areaDescription:'Best property in this area',
        pricePerMonth: '3,400',
        address:'548 Market St. San Francisco',
        bed:4,
        bath:2,
        sqft:'2,750',
        privileged:1,
        isBoostVisible:false,
        type:'featured'
        
    },

]