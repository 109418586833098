import React, {Component} from "react";
import CheckBox from "../FormCheckBox";
import {CheckBoxWrapper, MoreOptions} from "./Styled";
import {objIsEqual} from "../../../Utils";
import {ErrorMessage} from "../FormInputText/Styled";
import {ArrowDown} from "../BaseTemplate/Styled";

class FormCheckBoxGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValues: [],
            listToShow: [],
            moreOptionShow: false
        };
        this.oneTimeInit = true;
    }

    componentDidMount() {
        let listToShow = this.props.list;
        let moreOptionShow = false;
        if (listToShow.length > 12) {
            listToShow = listToShow.slice(12);
            moreOptionShow = true;
        }
        this.setState({
            listToShow,
            moreOptionShow
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && !objIsEqual(prevProps.value, this.props.value)) {
            this.setState({
                inputValues: this.props.value
            });
        }
    }

    checkGroupChangeHandler = (e, item) => {
        const states = this.props.states,
              isChecked = e.target.checked,
              inputValues = [...this.state.inputValues],
              checkGroupName = e.target.name;
        if (isChecked) {
            if (!(inputValues.find(x => x.value === item.value))) {
                inputValues.push(item);
            }
        } else {
            if (!!(inputValues.find(x => x.value === item.value))) {
                inputValues.splice(
                    inputValues.findIndex(x => x.value === item.value), 1
                );
            }
        }
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                      fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                      errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields};
                fields[checkGroupName] = inputValues;
                errorFields[checkGroupName] = null;
                this.setState({
                    inputValues
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    moreOptionClickHandler = () => {
        this.setState({
            listToShow: this.props.list,
            moreOptionShow: false
        })
    }

    render() {
        return (
            <>
                <CheckBoxWrapper>
                    {
                        this.state.listToShow.map(
                            (item, index) => {
                                return (
                                    <CheckBox
                                        key={this.props.id + "_" + index}
                                        id={this.props.id + "_" + index}
                                        name={this.props.id}
                                        label={item.text}
                                        value={item.value}
                                        checked={
                                            !!(this.state.inputValues.find(
                                                x => x.value === item.value
                                            ))
                                        }
                                        onChange={
                                            (e) => this.checkGroupChangeHandler(e, item)
                                        }
                                    />
                                )
                            }
                        )
                    }
                </CheckBoxWrapper>

                {
                    (this.state.moreOptionShow) &&
                    <MoreOptions onClick={this.moreOptionClickHandler}>
                        MORE OPTIONS <ArrowDown/>
                    </MoreOptions>
                }

                {
                    (this.state.errorMessage) &&
                    <ErrorMessage>
                        {this.props.errorMessage}
                    </ErrorMessage>
                }
            </>
        );
    }
}

export default FormCheckBoxGroup;