import React, {Component} from 'react';
import {Wrapper} from "./Styled";

class GroupTypeTitle extends Component {
    render() {
        return (
            <Wrapper>
                <em />
                <div>
                    <span />
                    <div>
                        {this.props.children}
                    </div>
                    <span />
                </div>
            </Wrapper>
        )
    }
}

export default GroupTypeTitle;