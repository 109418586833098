import React, {Component} from "react";
import {ImageArrow, ImageItem} from "./Styled";
import IconNew from "../IconNew";

class ImageItemAndMore extends Component {
    render() {
        return (
            <>
                {
                    (!this.props.image) &&
                    <ImageItem
                        type={this.props.type}
                        onClick={this.props.onClick}
                    >
                        <p>
                            <span>{this.props.imgCount - this.props.imgMax}
                                <ImageArrow/>
                            </span>
                            <br/>
                            MORE
                        </p>
                    </ImageItem>
                }
                {
                    (this.props.image) &&
                    <ImageItem
                        type={this.props.type}
                    >
                        <img src={this.props.image} alt="" />
                        <strong>{this.props.name}</strong>
                        <em>{this.props.subName}</em>
                        {
                            (this.props.gearBadge)
                                ? <i><IconNew bp1="-106" bp2="-97" iwidth="14" iheight="14"/></i>
                                : ''
                        }
                        {
                            (this.props.userBadge)
                                ? <i><IconNew bp1="-121" bp2="-97" iwidth="14" iheight="14"/></i>
                                : ''
                        }
                    </ImageItem>
                }
            </>
        );
    }
}

export default ImageItemAndMore;

export const ImageItemType = {
    SMALL: 'small',
    SMALL_TYPE_2: 'small_type_2',
    MEDIUM: 'medium',
    LARGE: 'large'
};