import React from 'react';
import {
    Wrapper,
    SlideLink,
    Slide,
    ArrowRightStyle,
    CarouselWrapper,
    SlideLinkWrapper,
    ImgStyle,
    SlideWrapper,

} from './Styled';
class CarouselComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleSlide: 0,
            targetSlide: 0,
        };
        this.wrapperRef = React.createRef();
        this.targetSlideRef = React.createRef();
    }


 

    componentDidUpdate = (prevProps, prevState) => {
        console.log(this.targetSlideRef);
        if (prevState.targetSlide !== this.state.targetSlide) {
            const targetSlide = this.targetSlideRef.current;
            const wrapper = this.wrapperRef.current;
            if (wrapper && targetSlide) {
                wrapper.scrollTo({
                    top: 0,
                    left: targetSlide.offsetLeft,
                    behavior: 'smooth',
                });
            }
        }
    };


    handleScroll = () => {
        let { width } = this.wrapperRef.current.getBoundingClientRect();
        let { scrollLeft } = this.wrapperRef.current;
        this.setState({
            visibleSlide: Math.round(scrollLeft / width),
        });
    };

    render() {
        return (
            <CarouselWrapper id="trap" tabIndex="0">
                <ArrowRightStyle
                    color={'white'}
                    onClick={() =>
                        this.setState({
                            targetSlide: Math.min(
                                this.state.targetSlide + 1,
                                this.props.data.length - 1
                            ),
                        })
                    }
                />

                <Wrapper onScroll={this.handleScroll} ref={this.wrapperRef}>
                    {this.props.data.map((item, i) => (
                        <Slide
                            key={`slide-${i}`}
                            ref={
                                i === this.state.targetSlide
                                    ? this.targetSlideRef
                                    : null
                            }
                        >
                            <SlideWrapper>
                                <ImgStyle
                                    src={item.photo}
                                />
                            </SlideWrapper>
                        </Slide>
                    ))}
                </Wrapper>
                <SlideLinkWrapper>
                    {this.props.data.map((_, i) => {
                        return (
                            <SlideLink
                            key={i}
                            firstItem={i === 0}
                            isActive={this.state.visibleSlide === i}
                            onClick={() =>
                            this.setState({ targetSlide: i })
                        }
                        />  
                        );
                    })}
                </SlideLinkWrapper>
            </CarouselWrapper>
        );
    }
}

export default CarouselComponent;
