import React, { Component } from 'react';
import { Wrapper, MenuOption } from '../ProfileMenuList/Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import IconNew from '../IconNew';
import ProfileMenuSettingsPrivileged from '../ProfileMenuSettingsPrivileged';
import ProfileMenuSettingsMyActivities from '../ProfileMenuSettingsMyActivities';

class ProfileMenuSettingsPrivacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
        };
        this.menu = [
            {
                title: 'Profile Privacy',
                icon: (
                    <IconNew bp1="-264" bp2="-448" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Privileged® Listing Settings',
                icon: (
                    <IconNew bp1="-234" bp2="-448" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'My Activities',
                icon: (
                    <IconNew bp1="-204" bp2="-448" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Realtyfeed Privacy Policy',
                icon: (
                    <IconNew bp1="-174" bp2="-448" iwidth="30" iheight="30" />
                ),
            },
        ];
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu,
        });
    };

    handleBackButton = () => {
        this.setState({ selectedMenu: -1 });
    };

    render() {
        const selectedMenu = this.state.selectedMenu;
        let itemsToShow;
        switch (selectedMenu) {
            case 1:
                itemsToShow = (
                    <ProfileMenuSettingsPrivileged
                        backButton={this.handleBackButton}
                    />
                );
                break;
            case 2:
                itemsToShow = (
                    <ProfileMenuSettingsMyActivities 
                        backButton={this.handleBackButton} 
                    />
                );
                break;
            default:
                itemsToShow = null;
        }
        return (
            <ProfileMenuContent
                title="Privacy/Security Settings"
                onTitleClick={() => {
                    if (selectedMenu !== -1) {
                        this.handleItemClick(-1);
                    } else {
                        this.props.backButton();
                    }
                }}
            >
                <Wrapper>
                    {selectedMenu === -1 &&
                        this.menu.map((option, index) => (
                            <MenuOption
                                key={index}
                                onClick={() =>
                                    this.setState({ selectedMenu: index })
                                }
                            >
                                <span>{option.icon}</span>
                                <p>{option.title}</p>
                            </MenuOption>
                        ))}
                    {selectedMenu !== -1 && itemsToShow}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettingsPrivacy;
