import React, {Component} from "react";
import {ImgWrapper, Wrapper} from "./Styled";
import {ClearFloats} from "../BaseTemplate/Styled";
import IconNew from "../IconNew";

class WizardItemMemberBox extends Component {
    render() {
        return (
            <Wrapper vip={(this.props.vip)}>
                <ImgWrapper vip={(this.props.vip)}>
                    <img src={this.props.img} alt="" />
                </ImgWrapper>
                <h4>
                    {this.props.name}
                    <small>
                        <IconNew bp1="-273" bp2="-152" iwidth="12" iheight="17" />
                        {this.props.address}
                    </small>
                </h4>
                <i>
                    {
                        (this.props.gearBadge) &&
                        <IconNew bp1="-106" bp2="-97" iwidth="14" iheight="14"/>
                    }
                    {
                        (this.props.userBadge) &&
                        <IconNew bp1="-121" bp2="-97" iwidth="14" iheight="14"/>
                    }
                </i>
                <em/>
                <ClearFloats/>
            </Wrapper>
        );
    }
}

export default WizardItemMemberBox;