import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew/BaseTemplateNew';
import ProfileMenu from '../Modules/ProfileMenu';
import { Main } from '../../styles/Styled';
import { Column } from '../../styles/Styled';
import { Container } from './Styled';
import Dashboard from '../Modules/Dashboard';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import { observer } from 'mobx-react';

class DashboardPage extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showFooter = false;
        super.showSearchBar = true;
    }

    renderBody() {
        return (
            <Main>
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    <Column type="one_column_setting" padding_right first>
                        <Container>
                            <Dashboard />
                        </Container>
                    </Column>
                )}
                <ProfileMenu />
            </Main>
        );
    }
}

export default observer(DashboardPage);
