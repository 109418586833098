import styled from 'styled-components';
import { ArrowRight } from '../BaseTemplate/Styled';

export const ProfileImageWrapper = styled.div`
    position: relative;
    float: left;
    margin: 0 40px 0 0;
    img {
        margin: 0;
    }
`;

export const About = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => 30 + (props.height / 90) * 21}px;
    h6 {
        font-size: 18px;
        line-height: 20px;
        width: 80%;
        height: ${(props) => (props.height / 90) * 21}px;
        font-weight: lighter;
        font-family: 'Montserrat';
        overflow: hidden;
        margin: 0;
    }
    span {
        color: ${(props) => props.theme.mainFontColor};
        font-size: 18px;
        float: right;

        span {
            margin-top: 8px;
        }
    }
`;

export const TextSection = styled.div`
    font-weight: lighter;
    word-wrap: break-word;
    color: ${(props) => props.theme.mainFontColor};
    strong {
        color: ${(props) => props.theme.textBlack};
    }
`;


export const GalleryCardWrapper = styled.div`
    height: 160px;
    width: 160px;
    margin-top:20px;
    margin-left:${props => props.everySeventh ? '0px' : '20px'};
    display: grid;
    grid-template-rows: repeat(2, 79px);
    grid-template-columns: repeat(2, 79px);
    grid-gap: 2px;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;

    .LastPicture {
        opacity: 0.6;
        background: ${(props) => props.theme.textBlack};
        height: 79px;
        width: 79px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const GalleryWrapper = styled.div`
`;

export const ImgStyle = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 79px;
    width: 79px;
    background: rgba(49, 49, 54, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
`;

export const ImgWrapper = styled.img`
    height: 79px;
    width: 79px;
`;

export const ExtendButton = styled.div`
    width: 160px;
    margin-left:20px;
    height: 160px;
    margin-top:20px;
    background: ${(props) => props.theme.grayLighter};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 36px;
    font-weight: bold;
    color: ${(props) => props.theme.textBlack};
    border-radius: 10px;
    cursor: pointer;

    .textStyle {
        font-size: 26px;
        margin-top: 5.4px;
    }
`;


export const ArrowRightMore = styled(ArrowRight)`
    margin: 0;
    margin-bottom: 2.5px;
`;

export const GalleryHeader = styled.span`
    font-size: 25px;
    font-weight: bold;
    color: ${(props) => props.theme.darkBlue};
`;

export const CardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
`;

