import React, { Component } from 'react';
import {
    ProfileMenuVaultTabWrapper,
    ProfileMenuVaultTabTop,
    ImgStyle,
    ImageWrapper,
    LevelText,
    TopImageWrapper,
    MoreInfoButton,
    ProfileMenuVaultTabProfileDetails,
    ProgessMenuVaulttabProgressBarSection,
    ProfileMenuVaulttabProgressBar,
    ProgressBarLevel,
    ProgressBar,
    ProgressBarNextLevel,
    ActiveProgressBar,
    XpLeftText,
    InlineXpText,
    ProgressText,
    ProfileMenuVaultInvatationHeaderRight,
    ProfileMenuVaultInvatationHeaderLeft,
    ProfileMenuVaultInvatationHeaderWrapper,
    ProfileMenuVaultInvatationSection,
    InvatationAmountText,
    InvatationCardWrapper,
    ProfileMenuVaultInvatationBodyWrapper,
    ImageStyle,
    InvatationName,
    ExtendButtonWrapper,
    InviteCard,
    InviteCardLeftSide,
    InviteCardRightSide,
    InvatationHeaderText,
} from './Styled';
import IconNew from '../IconNew';
import ProfileMenuVaultInvatationTab from '../ProfileMenuVaultInvatationTab';
import ProfileMenuSendInvatation from '../ProfileMenuSendInvatation';
import ProfileMenuVaultTabSectionsWrapper from '../ProfileMenuVaultTabSectionsWrapper';
import ProfileMenuVaultTabProfileDetail from '../ProfileMenuVaultTabProfileDetail/ProfileMenuVaultTabProfileDetail';
import { sendAsyncRequest } from '../../../Utils';

export class ProfileMenuVaultTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invatationsToShow: 4,
            currentPageIndex: 0,
            invatationCardata: {
                InvNum: null,
                textColor: '',
                bgColor: '',
                index: null,
            },
        };

        this.userProfile = {
            xp: 118,
            points: 1100,
            chips: 18,
            trophie: 8,
            interactions: 118,
            skills: 1100,
            network: 18,
            badges: 11,
            level: 7,
            levelProgess: 72,
            xpLeft: 103,
            photo: 'https://i.stack.imgur.com/l60Hf.png',
        };

        this.pagesToShow = {
            vaultPage: 0,
            invatationPage: 1,
            sendInvatationPage: 2,
        };

        this.invatationContainer = {
            invitation_count: 4,
            users: {
                accepted: [
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                    {
                        name: 'Mary Powell',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                        number: '(800)411-1226',
                        favorite: false,
                    },
                ],
                pending: [
                    {
                        name: 'John Smith',
                        number: '(800)411-1226',
                        date: '26 sep 11:03 AM',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                    },
                    {
                        name: 'John Smith',
                        number: '(800)411-1226',
                        date: '26 sep 11:03 AM',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                    },
                    {
                        name: 'John Smith',
                        number: '(800)411-1226',
                        date: '26 sep 11:03 AM',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                    },
                    {
                        name: 'John Smith',
                        number: '(800)411-1226',
                        date: '26 sep 11:03 AM',
                        photo:
                            'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                    },
                ],
            },
        };

        this.fakeTrophies = [
            { name: 'Trophy Name', id: 0 },
            { name: 'Trophy Name', id: 1 },
        ];

        this.skills = [
            { name: 'Skill Name', id: 0 },
            { name: 'Skill Name', id: 1 },
            { name: 'Skill Name', id: 2 },
            { name: 'Skill Name', id: 3 },
            { name: 'Skill Name', id: 4 },
            { name: 'Skill Name', id: 5 },
        ];

        this.badges = [
            { name: 'Badge Name', id: 0 },
            { name: 'Badge Name', id: 1 },
            { name: 'Badge Name', id: 2 },
        ];

        this.colors = [
            {
                bgColor:
                    'transparent linear-gradient(180deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box',
                color: '#07AED0',
            },
            {
                bgColor:
                    'transparent linear-gradient(180deg, #3F4EBC 0%, #121E74 100%) 0% 0% no-repeat padding-box',
                color: '#19257C',
            },
            {
                bgColor:
                    'transparent linear-gradient(180deg, #3FB5B0 0%, #108883 100%) 0% 0% no-repeat padding-box',
                color: '#1A928D',
            },
            {
                bgColor:
                    'transparent linear-gradient(180deg, #AF5DEE 0%, #7106C4 100%) 0% 0% no-repeat padding-box',
                color: '#7106C4',
            },
        ];

        this.acceptedInvatations = this.invatationContainer.users.accepted;
        this.pendingInvatations = this.invatationContainer.users.pending;
        this.allInvatations = [
            ...this.acceptedInvatations,
            ...this.pendingInvatations,
        ];
    }

    componentDidMount() {
        sendAsyncRequest('/invite/user/invitation/list/', 'post', {
            list_type: 4,
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    extendButtonHandler = () => {
        if (this.state.invatationsToShow + 10 <= this.allInvatations.length) {
            this.setState({
                invatationsToShow: this.state.invatationsToShow + 10,
            });
        } else {
            let filteredConnections =
                this.allInvatations.length - this.state.invatationsToShow;
            this.setState({
                invatationsToShow:
                    this.state.invatationsToShow + filteredConnections,
            });
        }
    };

    inviteFriendsHandler = () => {
        this.setState({
            currentPageIndex: this.pagesToShow.invatationPage,
        });
    };

    invatationHandler = (InvNum, textColor, bgColor, index) => {
        this.setState({
            invatationCardata: {
                ...this.state.invatationCardata,
                InvNum,
                textColor,
                bgColor,
                index,
            },
            currentPageIndex: this.pagesToShow.sendInvatationPage,
        });
    };

    AvailableInvatations = (item, index) => {
        let textColor = this.colors[index].color;
        let BgColor = this.colors[index].bgColor;
        return (
            <React.Fragment>
                <InviteCard
                    key={index}
                    onClick={() =>
                        this.invatationHandler(item, textColor, BgColor, index)
                    }
                >
                    <InviteCardLeftSide BgColor={BgColor}>
                        {item}
                    </InviteCardLeftSide>
                    <InviteCardRightSide>
                        <InvatationHeaderText textColor={textColor}>
                            Invatation
                        </InvatationHeaderText>
                        <div textColor={textColor}>{'>'}</div>
                    </InviteCardRightSide>
                </InviteCard>
            </React.Fragment>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.state.currentPageIndex === this.pagesToShow.vaultPage && (
                    <ProfileMenuVaultTabWrapper>
                        <ProfileMenuVaultTabTop>
                            <TopImageWrapper>
                                <i />
                                <ImageWrapper>
                                    <ImgStyle
                                        src={this.userProfile.photo}
                                        alt="helo"
                                    />
                                    <LevelText>
                                        Lvl.
                                        {this.userProfile.level}
                                    </LevelText>
                                </ImageWrapper>
                            </TopImageWrapper>
                        </ProfileMenuVaultTabTop>
                        <MoreInfoButton />
                        <ProfileMenuVaultTabProfileDetails>
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-356" bp2="-77" iwidth="28" iheight="28"/>}
                                detail={this.userProfile.xp}
                                detailType={'XP'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-385" bp2="-77" iwidth="23" iheight="27"/>}
                                detail={this.userProfile.points}
                                detailType={'Points'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-409" bp2="-77" iwidth="28" iheight="28"/>}
                                detail={this.userProfile.chips}
                                detailType={'Chips'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-438" bp2="-77" iwidth="28" iheight="28"/>}
                                detail={this.userProfile.trophie}
                                detailType={'Trophies'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-356" bp2="-108" iwidth="30" iheight="30"/>}
                                detail={this.userProfile.interactions}
                                detailType={'Interactions'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-387" bp2="-108" iwidth="26" iheight="31"/>}
                                detail={this.userProfile.skills}
                                detailType={'Skills'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-414" bp2="-108" iwidth="28" iheight="28"/>}
                                detail={this.userProfile.network}
                                detailType={'Network'}
                            />
                            <ProfileMenuVaultTabProfileDetail
                                icon={<IconNew bp1="-356" bp2="-140" iwidth="26" iheight="28"/>}
                                detail={this.userProfile.badges}
                                detailType={'Badges'}
                            />
                        </ProfileMenuVaultTabProfileDetails>
                        <ProfileMenuVaulttabProgressBar>
                            <ProgessMenuVaulttabProgressBarSection>
                                <ProgressBarLevel>
                                    {this.userProfile.level}
                                </ProgressBarLevel>
                                <ProgressBar>
                                    <ActiveProgressBar
                                        width={this.userProfile.levelProgess}
                                    />
                                </ProgressBar>
                                <ProgressBarNextLevel>
                                    {this.userProfile.level + 1}
                                </ProgressBarNextLevel>
                            </ProgessMenuVaulttabProgressBarSection>
                            <ProgessMenuVaulttabProgressBarSection>
                                <ProgressText>
                                    Progress {this.userProfile.levelProgess}%
                                </ProgressText>
                                <XpLeftText>
                                    {this.userProfile.xpLeft} XP{' '}
                                    <InlineXpText>to level up</InlineXpText>
                                </XpLeftText>
                            </ProgessMenuVaulttabProgressBarSection>
                        </ProfileMenuVaulttabProgressBar>
                        <ProfileMenuVaultInvatationSection>
                            <ProfileMenuVaultInvatationHeaderWrapper>
                                <ProfileMenuVaultInvatationHeaderLeft>
                                    Invatation
                                    <InvatationAmountText>
                                        ({this.allInvatations.length})
                                    </InvatationAmountText>
                                </ProfileMenuVaultInvatationHeaderLeft>
                                <ProfileMenuVaultInvatationHeaderRight
                                    onClick={this.inviteFriendsHandler}
                                >
                                    Invite Friends {'>'}
                                </ProfileMenuVaultInvatationHeaderRight>
                            </ProfileMenuVaultInvatationHeaderWrapper>
                            <ProfileMenuVaultInvatationBodyWrapper>
                                {this.allInvatations
                                    .slice(0, this.state.invatationsToShow)
                                    .map((item, index) => {
                                        const firstname = item.name.split(
                                            ' '
                                        )[0];
                                        const surname = item.name.split(' ')[1];                                    
                                        return (
                                            <InvatationCardWrapper  key={index}>
                                                <ImageStyle
                                                    src={item.photo}
                                                    alt="photo "
                                                />
                                                <InvatationName>
                                                    {firstname}{' '}
                                                    {surname.charAt(0)}
                                                </InvatationName>
                                            </InvatationCardWrapper>
                                        );
                                    })}
                                <ExtendButtonWrapper
                                    zeroInvatations={
                                        this.allInvatations.length -
                                            this.state.invatationsToShow ===
                                        0
                                    }
                                    onClick={this.extendButtonHandler}
                                >
                                    {this.state.invatationsToShow + 10 <=
                                    this.allInvatations.length ? (
                                        +10
                                    ) : (
                                        <React.Fragment>
                                            {this.allInvatations.length -
                                                this.state.invatationsToShow}
                                        </React.Fragment>
                                    )}
                                </ExtendButtonWrapper>
                            </ProfileMenuVaultInvatationBodyWrapper>
                        </ProfileMenuVaultInvatationSection>
                        <ProfileMenuVaultTabSectionsWrapper
                            sectionData={this.fakeTrophies}
                            sectionName={'Trophies'}
                            rightSideColor={'#7106C4'}
                            rightSideText={'Leadership'}
                        />
                        <ProfileMenuVaultTabSectionsWrapper
                            sectionData={this.skills}
                            sectionName={'Skills'}
                            rightSideColor={'#07AED0'}
                            rightSideText={'More'}
                        />  
                        <ProfileMenuVaultTabSectionsWrapper
                            sectionData={this.badges}
                            sectionName={'Badges'}
                            rightSideColor={'#24BA60'}
                            rightSideText={'More'}
                        />            
                    </ProfileMenuVaultTabWrapper>
                )}
                {this.state.currentPageIndex ===
                    this.pagesToShow.invatationPage && (
                    <React.Fragment>
                        <ProfileMenuVaultInvatationTab
                            data={this.invatationContainer}
                            InvatationCard={this.AvailableInvatations}
                            invatation_count={
                                this.invatationContainer.invitation_count
                            }
                        />
                    </React.Fragment>
                )}
                {this.state.currentPageIndex ===
                    this.pagesToShow.sendInvatationPage && (
                    <React.Fragment>
                        <ProfileMenuSendInvatation
                            inviteCardData={this.state.invatationCardata}
                            InvatationCard={this.AvailableInvatations}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ProfileMenuVaultTab;
