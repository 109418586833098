import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Wrapper, MenuOption, LogoutButton } from './Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import ProfileMenuSettings from '../ProfileMenuSettings';
import IconNew from '../IconNew';

class ProfileMenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: -1,
        };
        this.menu = [
            {
                title: 'Notifications',
                icon: (
                    <IconNew bp1="-294.5" bp2="-358" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Dashboard',
                icon: (
                    <IconNew bp1="-264" bp2="-358.5" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Messages',
                icon: (
                    <IconNew bp1="-234.5" bp2="-357" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'My Profile',
                icon: (
                    <IconNew bp1="-204" bp2="-358" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Lead Management',
                icon: (
                    <IconNew bp1="-174" bp2="-358" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Vault',
                icon: (
                    <IconNew bp1="-144" bp2="-358.5" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'My Listings',
                icon: (
                    <IconNew bp1="-294" bp2="-388" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Saved Listings',
                icon: (
                    <IconNew bp1="-264.5" bp2="-388" iwidth="30" iheight="30" />
                ),
            },
            {
                title: 'Settings',
                icon: (
                    <IconNew bp1="-234" bp2="-388" iwidth="30" iheight="30" />
                ),
            },
        ];
    }

    handleItemClick = (selectedMenu) => {
        this.setState({
            selectedMenu: -1,
        });
    };

    handleBackButton = () => {
        this.setState({ selectedMenu: -1 });
    };

    render() {
        const selectedMenu = this.state.selectedMenu;
        let itemsToShow;
        switch (selectedMenu) {
            case 1:
                if (this.props.history.location.pathname !== '/dashboard') {
                    this.props.history.push('/dashboard');
                } else {
                    this.handleBackButton();
                }
                break;
            case 6:
                if (this.props.history.location.pathname !== '/my-listings') {
                    this.props.history.push('/my-listings');
                }
                break;
            case 8:
                itemsToShow = (
                    <ProfileMenuSettings backButton={this.handleBackButton} />
                );
                break;
            default:
                itemsToShow = null;
        }
        return (
            <ProfileMenuContent
                title="Profile Menu"
                onTitleClick={() => {
                    if (selectedMenu !== -1) {
                        this.handleItemClick(-1);
                    } else {
                        this.props.backButton();
                    }
                }}
            >
                <Wrapper>
                    {selectedMenu === -1 && (
                        <div>
                            {this.menu.map((option, index) => (
                                <MenuOption
                                    key={index}
                                    onClick={() =>
                                        this.setState({ selectedMenu: index })
                                    }
                                >
                                    <span>{option.icon}</span>
                                    <p>{option.title}</p>
                                </MenuOption>
                            ))}
                            <LogoutButton onClick={() => this.props.history.push('/logout')}>
                                <IconNew
                                    bp1="-294.5"
                                    bp2="-448.5"
                                    iwidth="30"
                                    iheight="30"
                                />
                                <p>Log out</p>
                            </LogoutButton>
                        </div>
                    )}
                    {selectedMenu !== -1 && itemsToShow}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default withRouter(ProfileMenuList);
