import React, { Component, Fragment } from 'react';

import ListingTop from '../ListingTop';
import ListingGallery from '../ListingGallery';
//import { sendAsyncRequest } from '../../../Utils';

import {
    Wrapper,
    FeaturesItem,
    FeaturesParent,
    RequestForAccess,
    SchoolInfo,
    Section,
    SimilarProperties,
    SimilarProperty,
    PropertyAgent,
    AgentImage,
    AgentContent,
    AgentCountries,
    ButtonOnline,
    ButtonContainer,
    ButtonAgent,
    DottedSeparator,
    Banner,
    SectionForm,
    SectionFormInputStyles,
    MapContainer,
    SchoolInfoHeaderWrapper,
    SchoolDetailsWrapper,
    SchoolInfoHeadline,
    CircleWrap,
    SchoolName,
} from './Styled';
import { Column, TabContent } from '../../../styles/Styled';
import TabPages, { TabStyle } from '../TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import { TabPageStyles } from './Styled';
import { ArrowDown, ClearFloats } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';
import ListingComments, { CommentType } from '../ListingComments';
import { SponsorMask } from '../FeedPage/Styled';
import FormInputText from '../FormInputText';
import GoogleMapView from '../GoogleMapView';
import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';
import { Link } from 'react-router-dom';
import Config from '../../../config';

export default class ListingDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listingData: {},
            showInfo: false,
            schoolId: null,
        };
        this.fakeListing = {
            pk: 1,
            saved: false,
            name: 'Property Name', // Extra
            mls_id: 282588, // Extra
            description:
                'Nunc scelerisque tincidunt elit. Vestibulum non mi ipsum. Cras pretium suscipit tellus sit amet aliquet. Vestibulum maximus lacinia massa non porttitor. Pellentesque vehicula est a lorem gravida bibendum. Proin tristique diam ut urna pharetra, ac rhoncus elit elementum. Proin vitae purus ultrices, dignissim turpis ut, mattis eros. Maecenas ornare molestie urna, hendrerit venenatis sem. Nunc scelerisque tincidunt elit. Vestibulum non mi ipsum. Cras pretium suscipit tellus sit amet aliquet. Vestibulum maximus lacinia massa non porttitor. Pellentesque vehicula est a lorem gravida bibendum. Proin tristique diam ut urna pharetra, ac rhoncus elit elementum. Proin vitae purus ultrices. dignissim turpis ut, mattis eros. Maecenas ornare molestie urna, hendrerit venenatis sem.',
            bedrooms: 4,
            bathrooms: 5,
            country: 'USA',
            state: '2571 Walling ford Dr, Beverly Hills, CA 90210',
            city: 'Beverly Hills',
            status: 'Unknown',
            street: 'Walling ford',
            street_number: '2571',
            owner: 'string', // Should be Changed
            total_likes: 5,
            total_comments: 10,
            add_date: 'string',
            images: [
                {
                    pk: 2,
                    image: '/serverImages/property_detail_image_sample_1.jpg',
                },
                {
                    pk: 3,
                    image: '/serverImages/property_detail_image_sample_2.jpg',
                },
                {
                    pk: 4,
                    image: '/serverImages/property_detail_image_sample_3.jpg',
                },
                {
                    pk: 5,
                    image: '/serverImages/property_detail_image_sample_4.jpg',
                },
                {
                    pk: 6,
                    image: '/serverImages/property_detail_image_sample_1.jpg',
                },
                {
                    pk: 7,
                    image: '/serverImages/property_detail_image_sample_2.jpg',
                },
                {
                    pk: 8,
                    image: '/serverImages/property_detail_image_sample_3.jpg',
                },
                {
                    pk: 9,
                    image: '/serverImages/property_detail_image_sample_4.jpg',
                },
                {
                    pk: 10,
                    image: '/serverImages/property_detail_image_sample_1.jpg',
                },
                {
                    pk: 11,
                    image: '/serverImages/property_detail_image_sample_2.jpg',
                },
                {
                    pk: 12,
                    image: '/serverImages/property_detail_image_sample_3.jpg',
                },
                {
                    pk: 13,
                    image: '/serverImages/property_detail_image_sample_4.jpg',
                },
            ],
            is_owner: false,
            liked: 'Unknown',
            can_comment: true,
            can_share: true,
            can_hide: false,
            can_edit: false,
            slug: 'Unknown',
            visibility: true,
            price: 2587000,
            appliances: [
                // Extra (The Structure)
                { name: 'Swimming pool', extra: '' },
                { name: 'Jacuzzi', extra: '' },
                { name: 'Heating system', extra: 'Central' },
                { name: 'Cooling system', extra: 'Central' },
                { name: 'Elevator', extra: '' },
                { name: 'Garden', extra: '' },
                { name: 'View', extra: '' },
                { name: 'Pet Policy', extra: 'Allowed' },
            ],
            tags: [],
            features: [],
            listing_type: 'For Sale',
            formated_price: '2,587,000',
            price_unit: '$',
            square_feet: 2150,
            lot_area: 0,
            lot_area_unit: 'sqft',
            living_area: 0,
            living_area_unit: 'sqft',
            zipcode: '90210',
            latitude: 1.2,
            longitude: 1.3,
            build_year: 1990,
            property_type: 'Apartment',
            comments: [
                {
                    // Extra
                    pk: 11,
                    // "listing_id": "",
                    text:
                        'Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue',
                    image: '/serverImages/profile_image_1.png',
                    owner: 'Louis Hughes',
                    add_date: 1597235243200,
                    comment_total_likes: '10',
                    replies_count: '5',
                    comment_like: '3',
                    special: true,
                },
                {
                    // Extra
                    pk: 22,
                    // "listing_id": "",
                    text:
                        'Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque nunc in aliquet. Donec congue',
                    image: '/serverImages/profile_image_1.png',
                    owner: 'Louis Hughes',
                    add_date: 1597235243200,
                    comment_total_likes: '10',
                    replies_count: '5',
                    comment_like: '3',
                },
            ],
            properties: [
                {
                    // Extra
                    pk: 1111,
                    title: '756 DREAM ISLAND, Manatee, Florida FL 34228',
                    image: '/serverImages/listing_search_image_sample_1.png',
                    price: 818000,
                    currency: '$',
                    tags: ['%', 'N', 'R'],
                    bed: 3,
                    bath: 2,
                    area: 2150,
                    areaParam: 'sqft',
                    photosCount: 14,
                    position: {
                        lat: 40.714725,
                        lng: -73.9867893,
                    },
                    saved: false,
                },
                {
                    pk: 2222,
                    title: '756 DREAM ISLAND, Manatee, Florida FL 34228',
                    image: '/serverImages/listing_search_image_sample_3.png',
                    price: 818000,
                    currency: '$',
                    tags: ['%', 'N', 'R'],
                    bed: 3,
                    bath: 2,
                    area: 966,
                    areaParam: 'sqft',
                    photosCount: 14,
                    position: {
                        lat: 40.7147265,
                        lng: -73.9867893,
                    },
                    saved: true,
                },
                {
                    pk: 3333,
                    title: '756 DREAM ISLAND, Manatee, Florida FL 34228',
                    image: '/serverImages/listing_search_image_sample_2.png',
                    price: 818000,
                    currency: '$',
                    tags: ['%', 'N', 'R'],
                    bed: 3,
                    bath: 2,
                    area: 966,
                    areaParam: 'sqft',
                    photosCount: 14,
                    position: {
                        lat: 40.714727,
                        lng: -73.9867834,
                    },
                    saved: false,
                },
            ],
            school: [
                {
                    // Extra
                    id: 'c101',
                    color: 'noticeGreen',
                    num: 8,
                    name: 'Palo Pinto Elementary School',
                    tel: '(940) 659-2745',
                    address: 'PO Box280, Palo Pinto, TX76484',
                    distance: '16.87 Miles',
                    grade: 'Grade: PK-6',
                    type: 'Type: public',
                },
                {
                    id: 'c102',
                    color: 'textBlack',
                    num: 0,
                    name: 'Palo Pinto Elementary School',
                    tel: '(940) 659-2745',
                    address: 'PO Box280, Palo Pinto, TX76484',
                    distance: '16.87 Miles',
                    grade: 'Grade: PK-6',
                    type: 'Type: public',
                },
                {
                    id: 'c103',
                    color: 'noticeYellow',
                    num: 5,
                    name: 'Palo Pinto Elementary School',
                    tel: '(940) 659-2745',
                    address: 'PO Box280, Palo Pinto, TX76484',
                    distance: '16.87 Miles',
                    grade: 'Grade: PK-6',
                    type: 'Type: public',
                },
                {
                    id: 'c104',
                    color: 'noticeRed',
                    num: 3,
                    name: 'Palo Pinto Elementary School',
                    tel: '(940) 659-2745',
                    address: 'PO Box280, Palo Pinto, TX76484',
                    distance: '16.87 Miles',
                    grade: 'Grade: PK-6',
                    type: 'Type: public',
                },
                {
                    id: 'c105',
                    color: 'noticeYellow',
                    num: 5,
                    name: 'Palo Pinto Elementary School',
                    tel: '(940) 659-2745',
                    address: 'PO Box280, Palo Pinto, TX76484',
                    distance: '16.87 Miles',
                    grade: 'Grade: PK-6',
                    type: 'Type: public',
                },
                {
                    id: 'c106',
                    color: 'noticeRed',
                    num: 3,
                    name: 'Palo Pinto Elementary School',
                    tel: '(940) 659-2745',
                    address: 'PO Box280, Palo Pinto, TX76484',
                    distance: '16.87 Miles',
                    grade: 'Grade: PK-6',
                    type: 'Type: public',
                },
            ],
            agents: [
                {
                    // Extra
                    id: 'agent1',
                    type: 'agent_listing',
                    url: 'dave',
                    name: 'Vincent Collins',
                    image: '/serverImages/team_gallery_sample_1.png',
                    premium: true,
                    verified: true,
                    address: 'Little Falls, MN, 56345',
                },
                {
                    id: 'agent2',
                    type: 'top_agent',
                    url: 'dave',
                    name: 'Vincent Collins',
                    image: '/serverImages/team_gallery_sample_2.png',
                    premium: true,
                    verified: false,
                    address: 'Little Falls, MN, 56345',
                },
                {
                    id: 'agent3',
                    type: '',
                    url: 'dave',
                    name: 'Vincent Collins',
                    image: '/serverImages/team_gallery_sample_3.png',
                    premium: true,
                    verified: true,
                    address: 'Little Falls, MN, 56345',
                },
            ],
        };
    }

    componentDidMount() {
        // sendAsyncRequest(
        //     `/property/details/${this.props.listingId}/`,
        //     'get',
        //     {}
        // ).then((result) => {
        //     if (result.status === 200) {
        //         this.setState({
        //             listingData: { ...result.body },
        //         });
        //     }
        // });
        this.setState({ listingData: this.fakeListing });
    }

    handleRevealSchool = (id) => {
        this.setState({
            showInfo: !this.state.showInfo,
            schoolId: id,
        });
    };

    /*UNSAFE_componentWillMount() {
        this.takeListingData(this.props.listingId);

        if (this.props.modalType && this.props.modalType === 'comments' && this.props.listingId) {
            this.toggleAllCommentsModal(this.props.listingId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.listingId !== this.state.listingId) {
            this.takeListingData(nextProps.listingId);

            if (nextProps.modalType && nextProps.modalType === 'comments' && nextProps.listingId) {
                this.toggleAllCommentsModal(nextProps.listingId);
            }
        }
    }

    toggleAllCommentsModal = (id) => {
        this.setState({isAllCommentsModalOpen: !this.state.isAllCommentsModalOpen});
    }

    takeListingData = (listingId) => {
        window.scrollTo(0, 0);
        sendRequest('property/details/' + listingId, {}, 'get', this.responseCallback);
    }

    responseCallback = (requestStatus, responseJson) => {
        this.setState({listingData: responseJson});
        sendRequest('comment/list/' + responseJson.pk, {
            page: 1,
            page_size: 2
        }, 'get', this.responseCommentCallback, false);
    }

    responseCommentCallback = (requestStatus, responseJson) => {
        const results = responseJson.results;
        this.setState({commentsData: results});
    }

    handleLikeToggle = (hasLiked) => {
        const listingData = this.state.listingData;

        if (hasLiked) {
            listingData.total_likes++;
        } else {
            listingData.total_likes--;
        }

        this.setState({listingData});
    }*/

    render() {
        const listingData = this.state.listingData;

        if (!listingData.pk) {
            return (
                <Fragment>
                    <SkeletonLoading type={SkeletonLoadingType.LISTING_PAGE} />
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Wrapper>
                    <ListingTop listing={listingData} />

                    <TabContent margin={25}>
                        <TabPages
                            theme={TabStyle.PropertyDetailsTab}
                            defaultPageIndex={0}
                        >
                            <TabPage title="Overview" styled={TabPageStyles}>
                                <Fragment>
                                    <Column type="one_column" noMargin right>
                                        {listingData.agents.map((agent) => {
                                            let bg;
                                            switch (agent.type) {
                                                case 'agent_listing':
                                                    bg = 'blue';
                                                    break;
                                                case 'top_agent':
                                                    bg = 'cyan';
                                                    break;
                                                default:
                                                    bg = '';
                                                    break;
                                            }
                                            return (
                                                <PropertyAgent
                                                    bg={bg}
                                                    key={agent.id}
                                                >
                                                    {agent.type ===
                                                        'agent_listing' && (
                                                        <SponsorMask
                                                            text="Agent Listing"
                                                            bg="blue"
                                                            bold
                                                        />
                                                    )}
                                                    {agent.type ===
                                                        'top_agent' && (
                                                        <SponsorMask
                                                            text="Top Agent"
                                                            bg="cyan"
                                                            bold
                                                        />
                                                    )}

                                                    <AgentContent>
                                                        <AgentImage>
                                                            <Link
                                                                to={
                                                                    Config
                                                                        .ROUTES
                                                                        .profile
                                                                        .person +
                                                                    '/' +
                                                                    agent.url
                                                                }
                                                                title={
                                                                    agent.name
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        agent.image
                                                                    }
                                                                    alt={
                                                                        agent.name
                                                                    }
                                                                />
                                                            </Link>
                                                            {agent.premium && (
                                                                <IconNew
                                                                    bp1="0"
                                                                    bp2="-77"
                                                                    iwidth="29"
                                                                    iheight="34"
                                                                />
                                                            )}
                                                            <AgentCountries>
                                                                <IconNew
                                                                    bp1="-507"
                                                                    bp2="-511"
                                                                    iwidth="23"
                                                                    iheight="23"
                                                                />
                                                                <IconNew
                                                                    bp1="-532"
                                                                    bp2="-511"
                                                                    iwidth="23"
                                                                    iheight="23"
                                                                />
                                                            </AgentCountries>
                                                        </AgentImage>
                                                        <h3>
                                                            <Link
                                                                to={
                                                                    Config
                                                                        .ROUTES
                                                                        .profile
                                                                        .person +
                                                                    '/' +
                                                                    agent.url
                                                                }
                                                                title={
                                                                    agent.name
                                                                }
                                                            >
                                                                {agent.name}
                                                            </Link>
                                                            {agent.verified && (
                                                                <IconNew
                                                                    bp1="-30"
                                                                    bp2="-77"
                                                                    iwidth="28"
                                                                    iheight="28"
                                                                />
                                                            )}
                                                        </h3>
                                                        <h4>
                                                            <IconNew
                                                                bp1="-62"
                                                                bp2="-77"
                                                                iwidth="12"
                                                                iheight="17"
                                                            />
                                                            {agent.address}
                                                        </h4>
                                                        <ButtonContainer>
                                                            {bg !== '' ? (
                                                                <ButtonOnline>
                                                                    <i />
                                                                    Chat
                                                                    <IconNew
                                                                        bp1="-55"
                                                                        bp2="-213"
                                                                        iwidth="20"
                                                                        iheight="19"
                                                                    />
                                                                </ButtonOnline>
                                                            ) : (
                                                                <ButtonAgent>
                                                                    View profile
                                                                </ButtonAgent>
                                                            )}
                                                            <br />
                                                            <ButtonAgent>
                                                                Contact
                                                                <ArrowDown />
                                                            </ButtonAgent>
                                                        </ButtonContainer>
                                                        <ClearFloats />
                                                    </AgentContent>
                                                </PropertyAgent>
                                            );
                                        })}

                                        <DottedSeparator />

                                        <Banner>BANNER</Banner>

                                        <Section>
                                            <h2>Mortgage Calculator</h2>
                                            <SectionForm>
                                                <label>
                                                    <FormInputText
                                                        id="loanAmount"
                                                        name="loanAmount"
                                                        type="text"
                                                        placeholder="Load Amount"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                    />
                                                    <ClearFloats />
                                                </label>
                                                <label>
                                                    <FormInputText
                                                        id="totalPrice"
                                                        name="totalPrice"
                                                        type="text"
                                                        placeholder="Total Price"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                    />
                                                    <ClearFloats />
                                                </label>
                                                <label>
                                                    <FormInputText
                                                        id="downPricePercentage"
                                                        name="downPricePercentage"
                                                        type="text"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                        className="small"
                                                    />
                                                    <span>%</span>
                                                    <FormInputText
                                                        id="downPrice"
                                                        name="downPrice"
                                                        type="text"
                                                        placeholder="Down Price"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                        className="medium"
                                                    />
                                                    <ClearFloats />
                                                </label>
                                                <label>
                                                    <FormInputText
                                                        id="loanTerm"
                                                        name="loanTerm"
                                                        type="text"
                                                        placeholder="30-year fixed"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                    />
                                                    <ClearFloats />
                                                </label>
                                                <label>
                                                    <FormInputText
                                                        id="interestRate"
                                                        name="interestRate"
                                                        type="text"
                                                        placeholder="3.971"
                                                        className="long"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                    />
                                                    <span>%</span>
                                                    <ClearFloats />
                                                </label>
                                                <label>
                                                    <FormInputText
                                                        id="yearTaxPercentage"
                                                        name="yearTaxPercentage"
                                                        type="text"
                                                        placeholder="1.01"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                        className="small-placeholder"
                                                    />
                                                    <span>%</span>
                                                    <FormInputText
                                                        id="yearTax"
                                                        rightPadding={'60'}
                                                        name="yearTax"
                                                        type="text"
                                                        placeholder="$ 3,030"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                        className="medium-placeholder "
                                                    />
                                                    <span>/year</span>
                                                    <ClearFloats />
                                                </label>
                                                <label>
                                                    <FormInputText
                                                        id="insurance"
                                                        name="insurance"
                                                        type="text"
                                                        placeholder="$ 1,260"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                    />
                                                    <ClearFloats />
                                                </label>
                                                <label className="sum">
                                                    <FormInputText
                                                        id="monthlyPay"
                                                        name="monthlyPay"
                                                        type="text"
                                                        placeholder="Monthly Payment"
                                                        styled={
                                                            SectionFormInputStyles
                                                        }
                                                    />
                                                    <span className="monthlyPayment">
                                                        $ 0
                                                    </span>
                                                    <ClearFloats />
                                                </label>
                                            </SectionForm>
                                        </Section>
                                        <br />
                                        <MapContainer>
                                            <GoogleMapView
                                                container={{
                                                    width: '100%',
                                                    height: '515px',
                                                }}
                                                points={[
                                                    {
                                                        position: {
                                                            id: 1001,
                                                            lat: 51.585263,
                                                            lng: -73.300781,
                                                        },
                                                    },
                                                    {
                                                        position: {
                                                            id: 1002,
                                                            lat: 51.58527,
                                                            lng: -73.300785,
                                                        },
                                                    },
                                                    {
                                                        position: {
                                                            id: 1003,
                                                            lat: 51.585265,
                                                            lng: -73.300783,
                                                        },
                                                    },
                                                    {
                                                        position: {
                                                            id: 1004,
                                                            lat: 51.585475,
                                                            lng: -73.140793,
                                                        },
                                                    },
                                                    {
                                                        position: {
                                                            id: 1005,
                                                            lat: 51.745611,
                                                            lng: -73.274002,
                                                        },
                                                    },
                                                    {
                                                        position: {
                                                            id: 1006,
                                                            lat: 51.738808,
                                                            lng: -73.609085,
                                                        },
                                                    },
                                                    {
                                                        position: {
                                                            id: 1007,
                                                            lat: 51.570967,
                                                            lng: -73.642044,
                                                        },
                                                    },
                                                ]}
                                            />
                                        </MapContainer>
                                        <Section>
                                            <SchoolInfoHeadline>
                                                School Info
                                            </SchoolInfoHeadline>
                                            <br />
                                            {!!listingData.school &&
                                                listingData.school
                                                    .slice(0, 3)
                                                    .map((school, index) => (
                                                        <SchoolInfo
                                                            key={school.id}
                                                        >
                                                            <SchoolInfoHeaderWrapper
                                                                onClick={() =>
                                                                    this.handleRevealSchool(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <div>
                                                                    <SchoolName>
                                                                        {
                                                                            school.name
                                                                        }
                                                                    </SchoolName>
                                                                </div>
                                                                <CircleWrap
                                                                    color={
                                                                        school.color
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            school.num
                                                                        }
                                                                    </span>
                                                                </CircleWrap>
                                                            </SchoolInfoHeaderWrapper>
                                                            {this.state
                                                                .showInfo &&
                                                            this.state
                                                                .schoolId ===
                                                                index ? (
                                                                <SchoolDetailsWrapper
                                                                >
                                                                    <div>
                                                                        <i>
                                                                            <IconNew
                                                                                bp1="-66"
                                                                                bp2="-358"
                                                                                iwidth="20"
                                                                                iheight="21"
                                                                            />
                                                                        </i>
                                                                        {
                                                                            school.tel
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <i>
                                                                            <IconNew
                                                                                bp1="0"
                                                                                bp2="-358"
                                                                                iwidth="15"
                                                                                iheight="21"
                                                                            />
                                                                        </i>
                                                                        {
                                                                            school.address
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <i>
                                                                            <IconNew
                                                                                bp1="-17"
                                                                                bp2="-358"
                                                                                iwidth="21"
                                                                                iheight="21"
                                                                            />
                                                                        </i>
                                                                        {
                                                                            school.distance
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <i>
                                                                            <IconNew
                                                                                bp1="-40"
                                                                                bp2="-358"
                                                                                iwidth="24"
                                                                                iheight="21"
                                                                            />
                                                                        </i>
                                                                        {
                                                                            school.grade
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <i>
                                                                            <IconNew
                                                                                bp1="-88"
                                                                                bp2="-358"
                                                                                iwidth="22"
                                                                                iheight="21"
                                                                            />
                                                                        </i>
                                                                        {
                                                                            school.type
                                                                        }
                                                                    </div>
                                                                </SchoolDetailsWrapper>
                                                            ) : null}
                                                        </SchoolInfo>
                                                    ))}
                                            <ClearFloats />
                                        </Section>
                                    </Column>
                                    <Column type="two_column" noMargin>
                                        <ListingGallery
                                            listingImages={listingData.images}
                                        />

                                        <Section>
                                            <h2>Features</h2>
                                            <FeaturesParent>
                                                {listingData.appliances.map(
                                                    (apl, index) => (
                                                        <FeaturesItem
                                                            key={
                                                                apl.name + index
                                                            }
                                                        >
                                                            {apl.name}
                                                            {apl.extra && ': '}
                                                            {apl.extra && (
                                                                <span>
                                                                    {apl.extra}
                                                                </span>
                                                            )}
                                                        </FeaturesItem>
                                                    )
                                                )}
                                            </FeaturesParent>

                                            <RequestForAccess>
                                                <IconNew
                                                    bp1="-321"
                                                    bp2="-163"
                                                    iwidth="35"
                                                    iheight="48"
                                                />
                                                <br />
                                                <span>
                                                    Privileged Listing
                                                    <small>TM</small>
                                                </span>
                                                <br />
                                                <strong>
                                                    Request for Access
                                                </strong>
                                            </RequestForAccess>
                                            <ClearFloats />
                                        </Section>

                                        <Section>
                                            <h2>Description</h2>
                                            <p>{listingData.description}</p>
                                        </Section>
                                        <Section border>
                                            <h2>Comments</h2>

                                            {!!listingData.comments && (
                                                <ListingComments
                                                    type={
                                                        CommentType.LISTING_PAGE
                                                    }
                                                    listingId={
                                                        this.props.listingId
                                                    }
                                                    comments={
                                                        listingData.comments
                                                    }
                                                />
                                            )}
                                        </Section>
                                    </Column>
                                    <ClearFloats />
                                    <br />
                                    <Section border>
                                        <h2>Similar homes</h2>
                                        {!!listingData.properties && (
                                            <SimilarProperties>
                                                {listingData.properties.map(
                                                    (property) => (
                                                        <SimilarProperty
                                                            key={
                                                                'parent-' +
                                                                property.pk
                                                            }
                                                        >
                                                            Similar
                                                        </SimilarProperty>
                                                    )
                                                )}
                                                <ClearFloats />
                                            </SimilarProperties>
                                        )}
                                    </Section>
                                </Fragment>
                            </TabPage>
                            <TabPage
                                title="Description"
                                styled={TabPageStyles}
                            />
                            <TabPage
                                title="School information"
                                styled={TabPageStyles}
                            />
                            <TabPage
                                title="Neighborhood"
                                styled={TabPageStyles}
                            />
                            <TabPage
                                title="Similar homes"
                                styled={TabPageStyles}
                            />
                        </TabPages>
                    </TabContent>
                </Wrapper>
            </Fragment>
        );
    }
}
