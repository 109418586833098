import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 96px);
    padding-left: 5%;
    padding-right: 5%;
`;

export const ItemWrapper = styled.button`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    padding-right: 10px;
    padding-left: 10px;
    background: white;
    height: 80px;
    position:relative;
`;

export const TitleWrapper = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    cursor: pointer;
    height: 100%;
    font-family: 'Montserrat';

    span {
        font-size: 18px;
        color: ${(props) => props.theme.textBlack};
        margin: 0;
        text-align: left;
    }

    p {
        font-size: 14px;
        font-weight: lighter;
        width:100%;
        color: ${(props) => props.theme.coolGray};
        text-align: left;
        margin: 0;
        margin-top: 5px;
    }
`;

export const OptionsWrapper = styled.div`
    display: flex;
    width: 20%;
    justify-content: flex-end;
    cursor: pointer;
`;

