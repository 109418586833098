import styled, {css} from 'styled-components';
import {ArrowRight} from "../BaseTemplate/Styled";

export const ProfileMenuVerificationOfficialLicensesWrapper = styled.div`
padding-left:30px;
padding-right:30px;
padding-top:30px;
height:100%;
height:85vh;
overflow-y:scroll;
`;

export const ArrowRightWrap = styled(ArrowRight)`
 margin: 0 0 -13px;
height: 25px;

`;

export const HeaderText = styled.span`
font-size: 22px;
font-weight: bold;
color: ${(props) => props.theme.deepBlue};
`;

export const HeaderWrapper = styled.div`
display:flex;
`;

export const ProfileVerificationHeaderWrapper = styled.div`
display:flex;
justify-content:flex-end;
align-items:center;
padding-bottom:25.5px;
border-bottom:1px solid ${(props) => props.theme.textGrayLight};
`;

export const ProfileMenuVerificationHeader = styled.span`
 font-size: 22px;
font-weight: bold;
color:${(props) => props.theme.deepBlue};
`;

export const BackButton = styled.div`
cursor:pointer;
 i {
     top:-7px;
 }
`;
export const ContentWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
height:80vh;
`;
export const IDImage = styled.img`
width:100%;
height:370px;
border-radius: 5px;
border: solid 1px #cccccc;

`;
export const TopCard = styled.div`
padding-top:5%;
width:100%;
height:auto;

`;
export const MiddleCard = styled.div`
margin-top:9%;
/* temporary height */
height:370px;
`;
export const LowerButtonsWrapper = styled.div`
margin-top:16%;
display:flex;
align-items:center;
`;
export const SaveButton = styled.button`
width:295px;
background-image: linear-gradient(to right, #07aed0,  #094fd0);
border-radius:5px;
color:white;
font-size: 16px;
border:none;
padding-top:3.6%;
padding-bottom:3.6%;
`;
export const CancelButton = styled.button`
margin-left:30px;
font-size: 16px;
height:19px;
color:#2e2e33;
border:none;
background:transparent;
border-bottom:1px solid #2e2e33;
`;

export const FooterWrapper = styled.div`
display:flex;
flex-direction:column;
justify-self:end;
`;

export const FooterItemTextStyle = styled.span`
font-size: 14px;
color: ${(props) => props.theme.textGrayLight};
margin-left:${props => props.first ? null : `4px `};

`;

export const FooterItemCompanyTextStyle = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.textGrayLight};
`;

export const InputDropDownWrapperStyles = css`
font-size: 18px;
color:#2e2e33;
`;

export const InputTextDetailsStyles =css `
width:100%;
font-size: 18px;
color:#2e2e33;
border:none;
padding-bottom:2.5%;
border-bottom:1px solid #e5e5e5;
`;

export const ButtonWrapper = styled.button`
margin-top:7%;
border-radius:5px;
border:solid 2px #e3e3e5;
background:transparent;
padding-top:2.5%;
padding-bottom:2.5%;
font-size: 16px;
font-weight: bold;
cursor:pointer;
color:#2e2e33;
`;

export const PlusSpan = styled.span`
color:#2e2e33;
font-weight:bold;
font-size:22px;
`;