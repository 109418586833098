import React, {Component} from "react";
import {Close, Wrapper} from "./Styled";

class NoticeCompleteProfile extends Component {
    render() {
        return (
            <Wrapper>
                You need to verify your email address
                <Close />
            </Wrapper>
        );
    }
}

export default NoticeCompleteProfile;