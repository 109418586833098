import styled from "styled-components";
import {Button} from "../ProfileGroupType/Styled";

export const ActionButton = styled(Button)`
    background: ${props => (props.white) ? '#fff' : props.theme.grayLight};
    width: 245px;
    height: 42px;
    margin-left: ${props => props.group ? 0 : 100}px;
    color: ${props => props.theme.textBlack};
    font-family: ${props => props.theme.mainFont};
    font-size: 18px;
    border: ${props => (props.white) ? 'solid 3px ' + props.theme.grayLight : 'none'};
    border-radius: 5px;
    &:hover {
        cursor: pointer;
    }
    span {
        position: relative;
        margin-left: 7px;
    }
    span.chat { top: 4px; }
    span.call { top: 2px; }
    span.contact { top: 2px; margin-left: 10px; }

    @media ${props => props.theme.mediaHD} {
        width: 172px; height: 30px;
        font-size: 14px;
    }
`;