import styled, { css } from 'styled-components';
import { SharedCountsType } from '../../ListingSharedCounts';

export const Wrapper = styled.span`
    cursor: pointer;
    margin-left: ${(props) =>
        props.type === SharedCountsType.BIG ? 50 : 25}px;
    > span {
        position: relative;
        margin-bottom: -3px;
    }
`;

export const ModalList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const ModalListItem = styled.li`
    font-size: 18px;
    font-weight: normal;
    color: ${(props) => props.theme.mainFontColor};
    height: 60px;
    box-sizing: border-box;
    padding: 7px;
    border-bottom: solid 1px ${(props) => props.theme.grayLine};
    cursor: pointer;
    span {
        margin: 0 15px;
        position: relative;
        top: 7px;
    }
`;

export const ModalTitleStyles = css`
    font-size: 20px;
    border-bottom-width: 1px;
    height: 60px;
    box-sizing: border-box;
    padding: 14px 25px;
    margin-bottom: 0;
`;

export const ModalCloseStyles = css`
    top: 22px;
    right: 25px;
    width: 20px;
    height: 20px;
    &:before,
    &:after {
        top: 0;
        left: 9px;
        width: 2px;
        height: 20px;
    }
`;

export const ModalShareItem = styled.div``;
