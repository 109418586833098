import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div``;

export const Title = styled.h1`
    font-size: 36px;
    line-height: 48px;
    margin: 0 0 7px;
    padding: 0;
    color: ${(props) => props.theme.darkBlue};
    span {
        position: relative;
        top: 1px;
    }
    & .gold-badge {
        margin-right: 10px;
    }
    & .blue-badge {
        margin-left: 10px;
    }

    @media ${(props) => props.theme.mediaHD} {
        font-size: 28px;
    }
`;

export const TagLink = styled(Link)`
    display: block;
    font-size: 18px;
    line-height: 22px;
    font-weight: lighter;
    color: ${(props) => props.theme.darkBlue};
    margin-bottom: 5px;
    &:hover {
        text-decoration: underline;
    }

    @media ${(props) => props.theme.mediaHD} {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
    }
`;

export const Address = styled.p`
    font-size: 18px;
    font-weight: lighter;
    line-height: 22px;
    margin: 8px 0 1px;
    padding: 0;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
        position: relative;
        margin-right: 6px;
        top: 1px;
    }

    @media ${(props) => props.theme.mediaHD} {
        font-size: 14px;
        line-height: 17px;
        margin: 0 0 1px;
    }
`;

export const MergeLink = styled(Link)`
    font-size: 18px;
    font-weight: lighter;
    line-height: 22px;
    color: ${(props) => props.theme.cyan};
    span {
        position: relative;
        top: 2px;
        margin-right: 6px;
    }
    &:hover {
        text-decoration: underline;
    }

    @media ${(props) => props.theme.mediaHD} {
        font-size: 14px;
        line-height: 17px;
    }
`;
