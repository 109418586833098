import React, { Component } from 'react';
import {
    Wrapper,
    ItemWrapper,
    TitleWrapper,
    OptionsWrapper,
    ItemContainer,
    Option,
    SwitchBoxMenuStyles,
    ApplyButtonSubmit,
} from './Styled';
import FormSwitchBox from '../FormSwitchBox';
import ButtonText from '../FormButtonText';
import ProfileMenuContent from '../ProfileMenuContent';

class ProfileMenuSettingsNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: -1,
            data: [
                [
                    {
                        title: 'Login Attempts',
                        options: [
                            'Email',
                            'SMS',
                            'Push Notification',
                            'In App Notification',
                        ],
                    },
                    {
                        title: 'Change Password',
                        options: [
                            'Email',
                            'SMS',
                            'Push Notification',
                            'In App Notification',
                        ],
                    },
                    {
                        title: 'New Device',
                        options: [
                            'Email',
                            'Push Notification',
                            'In App Notification',
                        ],
                    },
                ],

                [
                    {
                        title: 'Payment',
                        options: [
                            'Email',
                            'SMS',
                            'Push Notification',
                            'In App Notification',
                        ],
                    },
                    {
                        title: 'Account Ststus Change',
                        options: [
                            'Email',
                            'SMS',
                            'Push Notification',
                            'In App Notification',
                        ],
                    },
                    {
                        title: 'Money Received',
                        options: [
                            'Email',
                            'Push Notification',
                            'In App Notification',
                        ],
                    },
                ],
            ],
        };
        this.menu = [
            {
                title: 'Login Activity',
                subtitle: 'login, change password ...',
                options: 'Email,SMS',
            },
            {
                title: 'Billing',
                subtitle: 'Billing, packages ...',
                options: 'Email, Notification',
            },
            {
                title: 'Lead Management',
                subtitle: 'new leads, messages ...',
                options: 'Email, Notification',
            },
            {
                title: 'Connections',
                subtitle: 'follow, connection ...',
                options: 'Email',
            },
            {
                title: 'Groups',
                subtitle: 'group notification, group activities ...',
                options: 'Email',
            },
            {
                title: 'Likes',
                subtitle: 'login, change password ...',
                options: 'Notification',
            },
            {
                title: 'Comments',
                subtitle: 'new comments, replies',
                options: 'Notification',
            },
            {
                title: 'Chat',
                subtitle: 'new comments, replies',
                options: 'Notification',
            },
        ];
    }

    handleItemClick = (selectedItem) => {
        this.setState({
            selectedItem,
        });
    };

    render() {
        const selected = this.state.data[this.state.selectedItem % 2];
        return (
            <ProfileMenuContent
                title={
                    this.state.selectedItem === -1
                        ? 'Notification Configuration'
                        : this.menu[this.state.selectedItem].title
                }
                onTitleClick={() => {
                    if (this.state.selectedItem === -1) {
                        this.props.backButton();
                    } else {
                        this.setState({
                            selectedItem: -1,
                        });
                    }
                }}
            >
                <Wrapper>
                    {this.state.selectedItem === -1 ? (
                        this.menu.map((item, index) => (
                            <ItemWrapper
                                onClick={() => this.handleItemClick(index)}
                                key={index}
                            >
                                <TitleWrapper>
                                    <span>{item.title}</span>
                                    <p>{item.subtitle}</p>
                                </TitleWrapper>
                                <OptionsWrapper>
                                    <p>{item.options}</p>
                                </OptionsWrapper>
                            </ItemWrapper>
                        ))
                    ) : (
                        <div>
                            {selected.map((item, index) => (
                                <ItemContainer key={index}>
                                    <p>{item.title}</p>
                                    {item.options.map((option, index) => (
                                        <Option key={index}>
                                            <p>{option}</p>
                                            <FormSwitchBox
                                                styled={SwitchBoxMenuStyles}
                                            />
                                        </Option>
                                    ))}
                                </ItemContainer>
                            ))}
                            <ButtonText
                                onClick={this.shareButtonClick}
                                styled={ApplyButtonSubmit}
                            >
                                Apply
                            </ButtonText>
                        </div>
                    )}
                </Wrapper>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuSettingsNotifications;
