import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    width: 1100px;
    height: 315px;
    margin: 30px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    @media ${(props) => props.theme.mediaHD} {
        width: 800px;
    }
`;

export const ItemWrapper = styled.div`
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    p {
        align-self: center;
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 80px;
    }
`;

const BackgroundAnimation = (height) => keyframes`
    0% {
        height: 0%
    };
    100% {
        height: ${height}px
    };
`;

export const TransparentBackground = styled.div`
    float: left;
    width: 140px;
    animation: ${(props) =>
            BackgroundAnimation(parseInt((props.amount / props.max) * 245))}
        1s linear;
    animation-fill-mode: forwards;
    background-attachment: fixed;
    background-image: linear-gradient(
        90deg,
        ${(props) => props.theme.cyan} 0%,
        ${(props) => props.theme.cyanDark} 100%
    );
    border-radius: 5px;
    color: ${(props) => props.theme.white};
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${(props) => props.theme.mediaHD} {
        width: 80px;
    }
`;
