import {css} from "styled-components";

import {
    InputLabelAfterAnimateStyles,
    InputLabelAnimateStyles,
    InputTextStyles,
    InputTextWrapperStyles
} from "../LoginForm/Styled";

export const InputTextGroupStyles = css`
    ${ InputTextStyles };
    font-size: 20px;
    height: 40px;
`;

export const InputLabelAnimateGroupStyles = css`
    ${ InputLabelAnimateStyles };
    font-size: 20px;
    color: ${props => props.theme.mainFontColor};
`;

export const InputLabelAfterAnimateGroupStyles = css`
    ${ InputLabelAfterAnimateStyles };
    font-size: 16px;
`;

export const InputTextWrapperGroupStyles = css`
    ${ InputTextWrapperStyles };
    width: 490px;
    margin: 40px 0 30px;
`;