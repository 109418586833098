import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg
            width={195.682}
            height={41.773}
            viewBox="0 0 195.682 41.773"
            {...props}
        >
            <g data-name="Group 1858" fill="#19257c">
                <path
                    data-name="Subtraction 9"
                    d="M20.946 41.773a20.749 20.749 0 01-7.776-1.509 20.539 20.539 0 01-6.836-4.526l-.282-.281A20.486 20.486 0 01.378 24.822a20.611 20.611 0 011.135-11.689 20.471 20.471 0 014.539-6.816l.282-.281A20.591 20.591 0 0117 .377a20.778 20.778 0 0111.722 1.132 20.54 20.54 0 016.836 4.526l14.893 14.851-14.893 14.852A20.591 20.591 0 0124.893 41.4a20.87 20.87 0 01-3.947.373zM11.279 19.68l2.675 4.079h7.268l4.259 7.315h7.2l-4.53-7.315h1.859a5.835 5.835 0 002.338-.869 5.24 5.24 0 002.318-4.734 5.7 5.7 0 00-2.338-4.878 6.134 6.134 0 00-2.318-1.013H6.416l2.674 4.077h19.5a1.563 1.563 0 01.529.369 1.761 1.761 0 01.36.556 2.262 2.262 0 01.155.889 2.041 2.041 0 01-.171.843 1.237 1.237 0 01-.358.456 1.083 1.083 0 01-.515.224z"
                />
                <path
                    data-name="Path 477"
                    d="M63.962 22.174v6.52h-1.819V13.017h4.3a6.766 6.766 0 014.263 1.1 4 4 0 011.378 3.324 4.164 4.164 0 01-3.153 4.2l4.257 7.045h-2.159l-3.8-6.52zm0-1.566h2.5a4.315 4.315 0 002.831-.767 2.853 2.853 0 00.9-2.3 2.617 2.617 0 00-.917-2.241 4.967 4.967 0 00-2.944-.686h-2.37zm20.492 8.085h-8.74V13.017h8.74v1.619h-6.916v5.051h6.5V21.3h-6.5v5.769h6.917zm13.329 0l-1.952-4.986h-6.288l-1.93 4.986h-1.84l6.2-15.742h1.533l6.166 15.742zm-2.52-6.627l-1.82-4.856q-.354-.922-.729-2.263a20.318 20.318 0 01-.676 2.263l-1.844 4.858zm6.563 6.627V13.017h1.823v14.026h6.917v1.651zm16.224 0h-1.822V14.636h-4.965v-1.619h11.753v1.619h-4.965zm11.313-7.839l4.18-7.837h1.973l-5.233 9.6v6.08h-1.84V22.7l-5.222-9.683h1.995zm19.93 7.84h-3.271V13.017h8.986v2.724h-5.715v4.043h5.319V22.5h-5.319zm17.812 0h-9.029V13.017h9.029v2.724h-5.7v3.442h5.308v2.724h-5.308v4.043h5.7zm12.3 0h-9.029V13.017h9.029v2.724h-5.7v3.442h5.308v2.724h-5.308v4.043h5.7zm16.278-7.989a7.72 7.72 0 01-2.2 5.93q-2.2 2.059-6.364 2.059h-4.439V13.017h4.922a8.289 8.289 0 015.962 2.027 7.455 7.455 0 012.118 5.661zm-3.453.086q0-5.051-4.461-5.051H186v10.209h1.426q4.803 0 4.803-5.158z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
