import React, { Component } from 'react';
import {
    InputWrapper,
    SearchWrapperStyles,
    Tag,
    Tags,
    Wrapper,
    InputTextActivitiesStyles,
    InputTextWrapperActivitiesStyles,
} from './Styled';
import InputAddressSearch from '../FormInputAddressSearch';
import InputText from '../FormInputText';
import IconNew from '../IconNew';

class FormInputAddressAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
        };
    }

    componentDidMount() {
        this.setState({
            tags: this.props.value || []
        })
    }

    addressChosenHandler = (item) => {
        const tags = [...this.state.tags];
        tags.push(item);
        this.setState({
            tags,
        });
        this.thatStateHandler(tags);
    };

    tagRemoveClickHandler = (tagId) => {
        let tags = [...this.state.tags];
        tags = tags.filter((tag) => tag.id !== tagId);
        this.setState({
            tags,
        });
        this.thatStateHandler(tags);
    };

    thatStateHandler = (tags) => {
        if (this.props.states) {
            this.props.states.forEach((that) => {
                const isGlobalState = that.constructor.name === 'Object',
                    fields = isGlobalState
                        ? that.getFields()
                        : { ...that.state.fields },
                    errorFields = isGlobalState
                        ? that.getErrorFields()
                        : { ...that.state.errorFields },
                    name = this.props.id,
                    extraLocalStatesOnChange = {
                        ...this.props.extraLocalStatesOnChange,
                    };
                // Separate 1 Field to 7 Fields
                // "full_address" => "city", "street", "latitude", "longitude",
                //                   "location_text", "country", "zipcode"
                fields[name] = tags.map((tag) => {
                    return {
                        latitude: tag.geo.lat,
                        longitude: tag.geo.lng,
                        location_text: tag.item.description,
                        country: tag.item.country,
                        city: tag.item.city,
                        street: tag.item.street,
                        zipcode: tag.item.postal_code,
                    };
                });
                errorFields[name] = null;
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields,
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...extraLocalStatesOnChange,
                        });
                    }
                }
            });
        } else {
            this.props.onChangeSelected(tags);
        }
    };

    render() {
        return (
            <Wrapper>
                <InputWrapper>
                    {this.props.country_lock && (
                        <InputText
                            id={this.props.id + '_lock'}
                            name={this.props.id + '_lock'}
                            type="text"
                            styled={InputTextActivitiesStyles}
                            wrapperStyled={InputTextWrapperActivitiesStyles}
                            icon={
                                <IconNew
                                    bp1="-324"
                                    bp2="-52"
                                    iwidth="19"
                                    iheight="23"
                                />
                            }
                            required={true}
                            readonly={true}
                            readOnlyValue={true}
                            value={this.props.country_lock}
                        />
                    )}
                    <InputAddressSearch
                        {...this.props}
                        searchWrapperStyled={SearchWrapperStyles}
                        onAddressChosen={(item) =>
                            this.addressChosenHandler(item)
                        }
                        states={[]} // Handle the State Manually
                        value="" // Override Value
                        country={this.props.country_lock_value}
                    />
                    {/*<ButtonText styled={AddButtonStyles}>Add</ButtonText>*/}
                </InputWrapper>

                <Tags>
                    {this.state.tags.map((tag) => {
                        tag = tag.item;
                        return (
                            <Tag key={tag.id || tag.placeId}>
                                {(tag.city ? tag.city : tag.subText) +
                                    (tag.postal_code
                                        ? ', ' + tag.postal_code
                                        : '')}
                                <i
                                    onClick={() =>
                                        this.tagRemoveClickHandler(tag.id)
                                    }
                                />
                            </Tag>
                        );
                    })}
                </Tags>
            </Wrapper>
        );
    }
}

export default FormInputAddressAdd;
