import styled, { createGlobalStyle } from 'styled-components';

const height = document.documentElement.scrollHeight - 100;

export const GlobalStyle = createGlobalStyle`
    @import url(//fonts.googleapis.com/css?family=Open+Sans);
    @import url(//fonts.googleapis.com/css?family=Catamaran);
    @import url(//fonts.googleapis.com/css2?family=Montserrat);
    
    body {
        background-color:${(props) =>
            props.pageBackgroundColor === 'blue'
                ? 'rgb(112,112,112)'
                : '#ffffff'} ;
        font-family: "Open Sans";
        margin:0;
        font-size:15px;
    }

    a{
        text-decoration:none;
    }
    textarea:hover, textarea:active, textarea:focus, a:hover, a:active, a:focus, button:hover, button:active, button:focus, input:hover, input:active, input:focus, select:hover, select:active, select:focus {
        outline: 0;
    }

    body .carousel.carousel-slider .control-arrow{
        background-color:rgb(0,0,0,0.8)
    }
    .carousel .slide img{
        max-width: 100%;
        width:auto !important;
        vertical-align: top;
        border: 0;
        width: auto;
        max-height: ${(props) => (height ? height : '300')}px ;
    }
    .pac-container { z-index: 2147483647; }
`;

export const ClearFloats = styled.br`
    clear: both;
`;

export const ArrowDown = styled.span`
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: relative;
    margin: 0 10px;
    &:after,
    &:before {
        content: '';
        top: 0.5rem;
        position: absolute;
        width: 0.65rem;
        height: 0.1rem;
        background-color: ${(props) =>
            props.color ? props.color : props.theme.textBlack};
        display: inline-block;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    &:after {
        left: 0;
        transform: rotate(45deg);
    }
    &:before {
        right: 0;
        transform: rotate(-45deg);
    }
    ${(props) =>
        props.isExpand
            ? ' &:after { ' +
              ' -webkit-transform: rotate(-45deg); ' +
              ' transform: rotate(-45deg) ' +
              ' } ' +
              ' &:before { ' +
              ' -webkit-transform: rotate(45deg); ' +
              ' transform: rotate(45deg) ' +
              ' } '
            : ''};
`;

export const ArrowRight = styled.span`
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: relative;
    margin: 0 10px;
    &:after,
    &:before {
        content: '';
        top: 0.5rem;
        position: absolute;
        width: 0.6rem;
        height: 0.1rem;
        background-color: ${(props) =>
            props.color ? props.theme[props.color] : 'rgb(46,46,51)'};
        display: inline-block;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    &:after {
        top: 1px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &:before {
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    ${(props) =>
        props.isExpand
            ? ' &:after { ' +
              ' -webkit-transform: rotate(-45deg); ' +
              ' transform: rotate(-45deg) ' +
              ' } ' +
              ' &:before { ' +
              ' -webkit-transform: rotate(45deg); ' +
              ' transform: rotate(45deg) ' +
              ' } '
            : ''};
`;

export const ArrowLeft = styled.span`
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: relative;
    margin: 0 10px;
    &:after,
    &:before {
        content: '';
        top: 0.5rem;
        position: absolute;
        width: 0.6rem;
        height: 0.1rem;
        background-color: ${(props) =>
            props.color ? props.theme[props.color] : 'rgb(46,46,51)'};
        display: inline-block;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    &:after {
        top: 2px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    &:before {
        bottom: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
