import React, { Component } from 'react';
import { ClearFloats } from '../BaseTemplate/Styled';
import { StatsItem, Wrapper } from './Styled';
import { numberFormatter } from '../../../Utils';

class ProfileGroupTypeStats extends Component {
    render() {
        return (
            <Wrapper noLine={this.props.noLine} small={this.props.small}>
                {this.props.items.map((item, index) => (
                    <StatsItem key={'stats-' + index} small={this.props.small}>
                        <strong>{numberFormatter(item.count)}</strong>
                        <br />
                        <span>{item.name}</span>
                    </StatsItem>
                ))}
                <ClearFloats />
            </Wrapper>
        );
    }
}

export default ProfileGroupTypeStats;
