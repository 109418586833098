import styled, {css} from 'styled-components'
import {IconButton} from '../../../styles/Styled';

export const Wrapper = styled.div`
    display: ${props => props.isOpen ? "block" : "none"};
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2147483647;
    > div {
        height: ${props => (props.height)
            ? (props.height === 'auto')
                ? props.height
                : props.height + 'px'
            : '100%'
        };
    }
    
    &.animated-enter {
      opacity: 0;
    }
    &.animated-enter-active {
      opacity: 1;
      transition: opacity .3s;
    }
    &.animated-exit {
      opacity: 1;
    }
    &.animated-exit-active {
      opacity: 0;
      transition: opacity .3s;
    }
`;

export const ModalWrapper = styled.div``;

export const ModalWrapperContent = styled.div`
    margin-top: ${props => (props.margin) ? props.margin : 40}px;
    text-align: left;
    & > div {
        width: 100%;
    }
`;

export const Container = styled.div`    
    position: fixed;
    background: white;
    box-sizing: border-box;
    width: ${props => props.containerWidth || "50"}${props => props.containerSizeUnit || "%"};
    height: auto;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    padding: ${props => props.containerPadding || "20"}px;
    border-radius: 15px;
    max-height: 90%;
    overflow-y: hidden;
    ${props => props.containerHeight && css`
        height: ${props => props.containerHeight}px;
        max-height: unset;`
    }
    ::after{
        content: "";
        clear: both;
        display: block;
    }
`;

export const Title = styled.h1`
    font-size: 18px;
    font-weight: normal;
    color: ${props => props.theme.darkBlue};
    margin: 0 0 45px;
    border-bottom: solid 2px ${props => props.theme.grayLine};
    position: relative;
    ${
        props => props.styled
    };
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: 5px; right: 0;
    width: 24px; height: 24px;
    &:before, &:after {
        content: " ";
        position: absolute;
        top: 0; left: 11px;
        width: 3px; height: 25px;
        background-color: ${props => props.theme.darkBlue};
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    ${
        props => props.styled
    };
`;