import React from 'react';
import { LoginRegisterColumn, LoginRegisterMain, Logo } from '../Login/Styled';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import LoginRegisterDesign from '../Modules/LoginRegisterDesign';
import LoginRegisterFooter from '../Modules/LoginRegisterFooter';
import ForgotPasswordForm from '../Modules/ForgotPasswordForm';
import MainLogo from '../Login/MainLogo';
import MainLogoSmall from '../Login/MainLogoSmall';

export default class Login extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showHeader = false;
        super.bodyBg = 'white';
    }

    renderBody() {
        return (
            <>
                <LoginRegisterMain>
                    <LoginRegisterDesign />
                    <LoginRegisterColumn marginTop={200}>
                        <Logo>
                            {window.matchMedia(this.theme.mediaMobile)
                                .matches ? (
                                <MainLogoSmall />
                            ) : (
                                <MainLogo />
                            )}
                        </Logo>
                        <ForgotPasswordForm />
                    </LoginRegisterColumn>
                </LoginRegisterMain>
                <LoginRegisterFooter />
            </>
        );
    }
}
