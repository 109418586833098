import styled, {css} from "styled-components";
import {InputTextStyles, InputTextWrapperStyles} from "../LoginForm/Styled";

export const Wrapper = styled.div`
    position: relative;
`;

export const InputWrapper = styled.div`
    display: flex;
`;

export const SearchWrapperStyles = css`
    margin: 0 10px 20px 0;
    flex: 1;
`;

export const AddButtonStyles = css`
    width: 100px; height: 55px;
    background: ${props => props.theme.darkBlue};
    font-size: 22px;
    color: #fff;
    border-radius: 5px;
    border: none;
    float: right;
    margin-top: -10px;
`;

export const Tags = styled.div``;

export const Tag = styled.span`
    display: inline-block;
    box-sizing: border-box;
    height: 45px;
    background: ${props => props.theme.grayBgInput};
    border-radius: 5px;
    font-size: 22px;
    padding: 5px 50px 5px 15px;
    margin-right: 15px;
    position: relative;
    i {
        position: absolute;
        right: 12px;
        top: 15px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
    i:before, i:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 16px;
        width: 2px;
        background-color: ${props => props.theme.mainFontColor};
    }
    i:before {
        transform: rotate(45deg);
    }
    i:after {
        transform: rotate(-45deg);
    }
`;

export const InputTextWrapperActivitiesStyles = css`
    ${InputTextWrapperStyles};
    float: left;
    width: 200px;
    height: 35px;
    margin: 0 30px 35px 0;
`;

export const InputTextActivitiesStyles = css`
    ${InputTextStyles};
    font-size: 20px;
`;