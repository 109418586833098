import styled, { css } from 'styled-components';
import {
    ButtonTextColoredStyles,
    InputTextStyles,
    InputTextWrapperStyles,
} from '../LoginForm/Styled';

export const FormWrapper = styled.div`
    margin-left: 50px;
    h2 {
        font-size: 24px;
        margin: 0 0 35px;
        color: ${(props) => props.theme.darkBlue};
    }
`;

export const InputTextContactStyles = css`
    ${InputTextStyles};
    border: solid 1px ${(props) => props.theme.grayLight};
    border-radius: 5px;
    padding: 15px;
    height: 70px;
    &:focus {
        border-color: ${(props) => props.theme.cyan};
    }
`;

export const InputRichTextContactStyles = css`
    ${InputTextContactStyles};
    height: 150px;
    padding-bottom: 100px;
`;

export const InputTextContactWrapperStyles = css`
    ${InputTextWrapperStyles};
    margin-bottom: 35px;
`;

export const ButtonTextDarkBlueStyles = css`
    ${ButtonTextColoredStyles};
    background: ${(props) => props.theme.darkBlue};
    height: 70px;
    font-size: 22px;
    margin-top: 125px;
    padding: 0 20px 2px 0;
    span {
        position: relative;
        top: 5px;
        left: 10px;
    }
`;
