import styled from 'styled-components';
import Slider from "react-slick";

export const StyledSlider = styled(Slider)`
${props => props.containerStyle};


 &.slick-slide  {
    ${props => props.styles};
}

 .slick-list { 
   padding:0 !important;
}

.slick-prev {
  left: 3% !important;
  z-index: 1000 !important;
  
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.slick-prev:before {
  color: black;
}
.slick-next:before {
  color: black;
}

div { 
  display:flex !important;
}
`;