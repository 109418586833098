import React, {Component} from 'react';
import {
    Address,
    Title,
    MergeLink,
    TagLink,
    Wrapper
} from "./Styled";
import IconNew from "../IconNew";

class ProfileGroupTypeHeaderInfo extends Component {
    render() {
        return (
            <Wrapper>
                <Title>
                    {
                        this.props.goldBadge &&
                        <IconNew bp1="0" bp2="-77" iwidth="29" iheight="34" className="gold-badge"/>
                    }
                    {this.props.groupTitle}
                    {
                        this.props.blueBadge &&
                        <IconNew bp1="-30" bp2="-77" iwidth="28" iheight="28" className="blue-badge"/>
                    }
                </Title>
                <TagLink to={'#'}>
                    {this.props.groupTagLink}
                </TagLink>
                <Address>
                    <IconNew bp1="-60" bp2="-77" iwidth="17" iheight="17"/>
                    {this.props.groupAddress}
                </Address>
                <MergeLink to={'#'}>
                    <IconNew bp1="-78" bp2="-77" iwidth="17" iheight="17"/>
                    {this.props.groupMergeLink}
                </MergeLink>
            </Wrapper>
        );
    }
}

export default ProfileGroupTypeHeaderInfo;