import React, { Component } from 'react';
import { Container, PopButton, Tick } from './Styled';
import { ArrowDown } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';

class SelectorPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.value,
            isOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mouseup', () =>
            this.setState({ isOpen: false })
        );
    }

    componentWillUnmount() {
        document.addEventListener('mouseup', () =>
            this.setState({ isOpen: false })
        );
    }

    render() {
        return (
            <Container
                isOpen={this.state.isOpen}
                color={this.props.color}
                float={this.props.float}
                styled={this.props.styled}
            >
                <button
                    id={'selector'}
                    onClick={() => this.setState({ isOpen: true })}
                    onBlur={() => this.setState({ isOpen: false })}
                >
                    {this.state.selectedValue.text}
                    {'   '}
                    {this.props.doubleArrow ? (
                        <IconNew
                            className='icon'
                            bp1='-345'
                            bp2='-217'
                            iwidth='11'
                            iheight='17'
                        />
                    ) : (
                        <ArrowDown />
                    )}
                </button>
                <div>
                    {this.props.items.map((item, index) => (
                        <PopButton
                            key={index}
                            onClick={() => {
                                this.setState({
                                    selectedValue: item,
                                    isOpen: false,
                                });
                                this.props.onChange(item);
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            selected={item.value === this.state.selectedValue.value}
                        >
                            {item.value === this.state.selectedValue.value && <Tick />}
                            {item.text}
                        </PopButton>
                    ))}
                </div>
            </Container>
        );
    }
}

export default SelectorPopUp;
