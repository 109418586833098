import styled, { css } from 'styled-components';
import { ArrowRight } from '../BaseTemplate/Styled';
import { InputTextWrapperStyles } from '../LoginForm/Styled';

export const Wrapper = styled.div`
    padding: 22px;
    overflow: hidden;
`;

export const IdeaInputStyles = css`
    width: 300px;
    font-size: 20px;
    font-weight: lighter;
    margin: 22px 0 0 20px;
    border: none;
    &::placeholder {
        color: ${(props) => props.theme.textGrayLight};
    }
`;

export const ContentWrapper = styled.div`
    padding: 10px;
    border: 1px dashed;
    border-color: ${(props) => props.theme.textGrayLight};
    margin-top: 40px;
    border-radius: 12px;
`;

export const PropertyImage = styled.div`
    position: relative;
    img {
        ${(props) => {
            switch (props.size) {
                case 'lg':
                    return `
                            width: 290px; height: 185px; 
                            float: left; 
                            margin-right: 7px;
                        `;
                case 'sm':
                    return `
                            width: 86px; height: 86px;
                        `;
                default:
                    return ``;
            }
        }};
        &:last-of-type {
            margin-top: 3px;
        }
        border-radius: 10px;
        border: 0;
    }
    > span {
        width: 86px;
        height: 86px;
        float: none;
        position: absolute;
        background: ${props => props.theme.textBlack};
        border-radius: 10px;
        opacity: .5;
        z-index: 1;
    }
    strong {
        pointer-events: none;
        width: 86px;
        position: absolute;
        top: 50%; right: 0;
        transform: translateY(-50%);
        font-size: 22px;
        text-align: center;
        color: #fff;
        z-index: 2;
    }
`;

export const ArrowRightBlack = styled(ArrowRight)`
    color: ${(props) => props.theme.textBlack};
    margin: 0;
    position: relative;
    vertical-align: middle;
`;

export const InfoText = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.textBlack};
    margin: 0;
`;

export const StatusText = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.mainFontColor};
    margin: 0;
`;

export const DescText = styled.p`
    font-size: 18px;
    color: ${(props) => props.theme.mainFontColor};
    margin: 0;
`;

export const PosterImage = styled.img`
    width: 28px;
    height: 28px;
    border-radius: 5px;
    vertical-align: middle;
    margin-right: 5px;
`;

export const PostText = styled.p`
    font-size: 16px;
    color: ${(props) => props.theme.mainFontColor};
    display: inline;
    margin-right: 5px;
`;

export const PosterName = styled.p`
    font-size: 16px;
    font-weight: 16px;
    color: ${(props) => props.theme.textBlack};
    margin: 0;
    display: inline;
`;

export const AnnouncementText = styled.p`
    font-size: 14;
    color: ${(props) => props.theme.cyan};
    margin: 0;
`;

export const AnnouncementImage = styled.img`
    width: 150px;
    height: 95px;
    border-radius: 10px;
    border: 0;
    background: black;
    float: left;
    margin-right: 10px;
`;

export const InputTextWrapperSearchStyles = css`
    ${InputTextWrapperStyles};
    height: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
`;
