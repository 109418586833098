import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from '../BaseTemplate/Styled';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    height: ${props => props.pictureHeight ? `${props.pictureHeight}px` : '225px'};
`;

export const SlideLink = styled.span`
  display: inline-block;
    width:8px;
    height:8px;
    border-radius: 100px;
    ${(props) => !props.firstItem && 'margin-left:10px'}
    background: ${(props) => (props.isActive ? '#313136' : '#bcbcbc')};
    color: white;
    cursor:pointer;
`;

export const SlideLinkInline= styled.span`
    display: inline-block;
    width: ${(props) => props.isActive ? '8px' : '5px'};
    height:${(props) => props.isActive ? '8px' : '5px'};
    border-radius: 100px;
    ${(props) => !props.firstItem && 'margin-left:5px'}
    background:white;
    opacity:${(props) => !props.isActive && '0.5'};
    color: white;
    cursor:pointer;
`;

export const Slide = styled.div``;



export const SlideWrapper = styled.div`
    position: relative;
`;

export const IconWrapper = styled.button`
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) =>
        props.selected
            ? `linear-gradient(
            to bottom,
            ${props.theme.cyan} 0%,
            ${props.theme.cyanDark} 100%)
        `
            : props.theme.white};
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
`;

export const Tick = styled.u`
    display: inline-block;
    transform: rotate(45deg);
    width: 4px;
    height: 8px;
    border-bottom: 3px solid ${(props) => props.theme.white};
    border-right: 3px solid ${(props) => props.theme.white};
    border-radius: 3px;
`;

export const ImgStyle = styled.img`
    width: ${props => props.pictureWidth ? `${props.pictureWidth}px` : '335px'};
    height: ${props => props.pictureHeight ? `${props.pictureHeight}px` : '225px'};
    cursor: ${props => props.selectableMode && `pointer`}
`;

export const ArrowRightStyle = styled(ArrowRight)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    margin: 0;
    z-index: 10;
    cursor: pointer;
`;

export const SlideLinkWrapper = styled.div`
    ${props => props.inlineCircles ? (
    `width: 100%;
    position: absolute;
    bottom: 10px;
    left:15px;
    `
    ) : `
    width: 100%;
    display: flex;
    position: absolute;
    bottom: -18px;
    justify-content: center;
    `}
`;

export const ArrrowLeftStyle = styled(ArrowLeft)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    margin: 0;
    z-index: 10;
    cursor: pointer;
   
`;

export const CarouselWrapper = styled.div`
    position: relative;
`;
