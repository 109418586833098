import styled, {css} from "styled-components";

export const PermissionTable = styled.div`
    padding-right: 30px;
`;

export const TableRow = styled.div`
    display: flex;
    font-size: ${props => (props.head) ? 20 : 22}px;
    margin-bottom: ${props => (props.head) ? 30 : 25}px;
    & > div:first-of-type {
        flex-grow: 4;
        flex-shrink: 0;
        flex-basis: auto;
        color: ${props => props.theme.mainFontColor};
        font-weight: normal;
        text-align: left;
        padding: ${props => (props.head) ? 0 : '8px 0 0 40px'};
    }
    & > div {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 140px;
        color: ${props => props.theme.darkBlue};
        font-weight: bold;
        text-align: center;
    }
`;

export const SwitchBoxStyles = css`
    width: 84px; height: 37px;
    border-radius: 40px;
    border: solid 1px ${props => props.theme.grayBgInput};
    box-sizing: border-box;
    i em {
        box-sizing: border-box;
        width: 76px; height: 31px;
        top: 2px; left: 3px;
    }
    i em:before {
        width: 21px; height: 21px;
        left: 5px; bottom: 5px;
    }
    input:checked + i em:before {
        transform: translateX(45px);
    }
    input:checked + i em {
        background-color: ${props => props.theme.noticeGreen};
    }
    b {
        position: absolute;
        right: 13px;
        top: 8px;
        width: 19px;
        height: 19px;
    }
    b:before, b:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 19px;
        width: 4px;
        background-color: #fff;
        border-radius: 10px;
    }
    b:before {
        transform: rotate(45deg);
    }
    b:after {
        transform: rotate(-45deg);
    }
    input:checked + i b {
        display: none;    
    }
    u {
        display: none;
        position: absolute;
        top: 6px; left: 17px;
        transform: rotate(45deg);
        width: 7px; height: 16px;
        border-radius: 4px;
        border-bottom: 4px solid #fff;
        border-right: 4px solid #fff;
    }
    input:checked + i u {
        display: block;
    }
`;