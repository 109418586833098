import styled, {css} from "styled-components";
import {InputTextWrapperStyles} from "../LoginForm/Styled";

export const Wrapper = styled.div``;

export const InputTextWrapperListStyles = css`
    ${InputTextWrapperStyles};
    margin: 0 0 30px 0;
`;

export const ButtonTextStyles = css`
    height: 45px;
    background: ${props => (props.active)
        ? props.theme.cyan : (props.red)
        ? props.theme.noticeRed : props.theme.grayLighter
    };
    color: ${props => (props.active || props.red)
        ? '#fff' : props.theme.textBlack
    };
    border: none;
    font-size: 18px;
    border-radius: 5px;
    padding: 0 17px 2px;
    margin: 0 15px 15px 0;
`;

export const InnerHeader = styled.h3`
    font-size: 30px;
    color: ${props => props.theme.textBlack};
    margin: 0 0 30px;
    small {
        font-size: 18px;
        font-weight: normal;
        color: ${props => props.theme.textGrayLight};
    }
`;

export const ClearInput = styled.i`
    position: absolute;
    right: 9px;
    top: 12px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    :before, :after {
        position: absolute;
        left: 8px;
        content: '';
        height: 18px;
        width: 2px;
        background-color: ${props => props.theme.mainFontColor};
    }
    :before {
        transform: rotate(45deg);
    }
    :after {
        transform: rotate(-45deg);
    }
`;