import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 96px);
    padding-left: 5%;
    padding-right: 5%;
`;

export const ItemWrapper = styled.button`
    width: 100%;
    display: flex;
    border: none;
    padding-right: 10px;
    padding-left: 10px;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    width: 85%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    cursor: pointer;
    height: 100%;
    font-family: 'Montserrat';
    span {
        font-size: 18px;
        color: ${(props) => props.theme.textBlack};
        margin: 0;
        text-align: left;
    }

    p {
        font-size: 14px;
        font-weight: lighter;
        color: ${(props) => props.theme.coolGray};
        text-align: left;
        margin: 0;
        margin-top: 5px;
    }
`;
export const OptionsWrapper = styled.div`
    align-self: center;
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    height: 100%;
`;

export const MenuOption = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    padding-left: 1%;
    padding-top: 20px;
    border-bottom: 2px solid;
    border-color: rgba(0, 0, 0, 0.16);

    p {
        font-size: 18px;
        color: #797a80;
        margin: 0;
        text-align: left;
        height: 30px;
        width: 40%;
    }
`;

export const ClearDataButton = css`
    width: 100%;
    height: 50px;
    margin-top: 50px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 18px;
    color: white;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        to right,
        ${(props) => props.theme.cyan} 1%,
        ${(props) => props.theme.cyanDark}
    );
`;
