import styled,{ css } from 'styled-components';

export const AddTagWrapper = styled.div`
width:1260px;
display:flex;
justify-content:space-between;
background-color:white;
border-radius:10px;
padding-left:40px;
`;


export const CardWrapper = styled.div`
width:400px;
background:white;
border-radius:10px;
margin-top:${props => props.moreMarginTop ? '40px' : `30px`};
`;

export const CardTopWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background-image: linear-gradient(to right, #07aed0 0%, #7106c4 113%);
 opacity: 0.7;
border-top-left-radius:10px;
border-top-right-radius:10px;

`;

export const BoostButtonWrapper = styled.button`
background:transparent;
background-image: linear-gradient(to right, #a64bec, #7106c4);
color:white;
font-size:14px;
border-radius:3px;
border:none;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
width:72px;
margin-left:5px;
font-family: 'Montserrat';
`;

export const RocketIconWrapper = styled.span`
padding-left:2px;

`;

export const AddTagDivide = styled.div`
flex:1;
background-color: rgba(26, 146, 141, 0.05);
`;


export const ArrowSpanWrapper = styled.div`
margin-top:5px;

`;

export const AddTagDivideLeft = styled.div`
flex:2;
`;

export const  AddTagLeftSideDivide = styled.div`
 flex:380px;
 padding-bottom:30px;
`;

export const  AddTagLeftRightDivide = styled.div`
width:440px;
 display:flex;
 flex-direction:column;
 align-items:center;
 padding-bottom:30px;
`;

export const AddTagHeaderWrapper = styled.div`
color:#182280;
font-size:26px;
padding-top:28px;
border-bottom:solid 1px ${(props) => props.theme.textGrayLight};
display:flex;
padding-left:10px;
align-items:center;
padding-bottom:3.6%;
`;

export const LeftSideWrapper = styled.div`
display:flex;
flex-direction:row;
`;

export const RightBorder = styled.div`
position:absolute;
right:0;
bottom:0;
border-left:2px solid ${(props) => props.theme.textGrayLight};
top: 50%;
transform: translateY(-50%);
`;

export const CardtopItem = styled.div`
width:101px;
display:flex;
align-items:center;
justify-content:center;
position:relative;

`;

export const TopTextWrapper =  styled.div`
font-size:14;
color:white;
font-family: 'Montserrat';
`;

export const CarouselMiddleContainer = styled.div`
display:flex;
flex-direction:column;
padding-left:10px;
padding-right:10px;
padding-bottom:23px;
border-left:${props => props.withBorders && `1px solid  #e5e5e5`};
border-right:${props => props.withBorders && `1px solid  #e5e5e5`};
padding-top:${props => props.bigPaddingTop ? '23px' : '4px'};

`;
export const CarouselTopSideHeaderText = styled.span`
 color: ${(props) => props.theme.mainFontColor};
 font-size:16px;
 line-height: 1.13;
 font-family: 'Montserrat';

`;
export const CarouselBottomAddressText = styled.span`
color: ${(props) => props.theme.mainFontColor};
font-size:12px;
margin-left:6.6px;
font-family: 'Montserrat';
font-weight:lighter;
`;
export const CarouselMiddleBottomSide = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
height:18px;
`;
export const CarouselMiddlePriceText = styled.div`
 color: ${(props) => props.theme.textBlack};
 font-size:18px;
 flex:1;
 line-height: 1.06;

`;
export const CarouselMiddleRightSideText = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
flex:1;
`;
export const CarouselMiddleTopSide = styled.div`
display:flex;
flex-direction:row;
height:18px;
justify-content:space-between;
align-items:center;
`;

export const CarouselMiddleSide = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;

`;

export const RightSideText = styled.div`
position:relative;
text-align:${props => props.lastItem ? 'right' : 'center'};
font-size:10px;
color: ${(props) => props.theme.mainFontColor};
flex:15.333333%;
font-family: 'Montserrat';
font-weight:lighter;
`;

export const RightSideTextBorder = styled.div`
position:absolute;
right:0;
bottom:0;
border-left:1px solid ${(props) => props.theme.textGrayLight};
top: 50%;
transform: translateY(-50%);
`;


export const RighttSideTextNumber = styled.span`
font-size:14px;
color: ${(props) => props.theme.mainFontColor};
padding-right:3px;
font-family: 'Montserrat';
`;


export const CardMiddlebottomLeftside = styled.div`
width:50%;
display:flex;
align-items:center;
`;

export const IconWrapper = styled.div`
font-size:12px;
`;


export const CardBottomLeftSide = styled.div`
width:25%;
display:flex;
align-items:center;
justify-content:space-between;
`;

export const CardBottomRightSide = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:flex-end;
flex:50%;
`;


export const CardBottomContainer = styled.div`
display:flex;
flex-direction:row;
height:38px;
justify-content:space-between;
align-items:center;
padding-left:10px;
padding-right:10px;
background-color: ${(props) => props.theme.grayBgInput};
border-bottom-left-radius:10px;
border-bottom-right-radius:10px;
`;

export const StatisticsButtonWrapper = styled.button`
font-size:14px;
color: ${(props) => props.theme.textBlack};
background:white;
border:1px solid white;
border-radius:3px;
border: 1px solid ${props => props.theme.grayBorderLight};
display:flex;
width:80px;
justify-content:center;
align-items:center;
cursor:pointer;
`;

export const TopText = styled.span`
padding-left:6.8px;
font-family: 'Montserrat';
`;


export const TagCardWrapper = styled.div`
width:344px;
height:110px;
border:1px solid ${props => props.colorBorder && props.colorBorder};
border-radius:5px;
padding:18px;
margin-top:20px;
display:flex;
flex-direction:column;
justify-content:space-between;
position:relative;
`;

export const TagCardtopWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;

.plus-minus {
    display:flex;
    align-items:center;

    .amountclass {
        color:#2e2e33;
        font-size:12px;
        font-weight:lighter;
        margin-left:12px;
        margin-right:12px;
    }

}
`;

export const BoxSquare = styled.div`
width:18px;
height:18px;
border-radius:2px;
border: solid 1px #e3e3e3;
display:flex;
align-items:center;
justify-content:center;
`;

export const TagCardItem = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
width:50%;
line-height:30px;

.datetype {
    color:#2e2e33;
    font-size:12px;
    font-weight:lighter;
}

.date {
    color:${props => props.theme.textBlack};
    font-size:14px;
    font-weight:normal;
    
}
`;

export const TagCardBody = styled.div`
`;

export const AItag = styled.div`
    width:49px;
    height:16px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    position: absolute;
    top:-8px;
    right:17px;
    border-radius:10px;
    background-color:${props => props.AIcolor && props.AIcolor};
    color:white;
    font-size:10px;
`;

export const DaysLeft = styled.div`
color:#2e2e33;
font-size:18px;
font-weight:lighter;

span {
color:${props => props.theme.textBlack};
font-size:18px;
font-weight:normal;
margin-right:6px;
}

`;

export const PrivilegedSide = styled.div`
display:flex;
flex-direction:row;
width:380px;
margin-top:20.5px;
align-items:center;
justify-content:space-between;
border-bottom: solid 1px ${props => props.theme.textGrayLight};
padding-bottom:17.5px;
`;

export const ConfirmButton = styled.button`
width:380px;
height:50px;
background-image: linear-gradient(to right, #07aed0, #094fd0);
color:white;
font-size:18px;
border:none;
border-radius:5px;
display:flex;
cursor:pointer;
align-items:center;
justify-content:center;
margin-top:100px;
`;

export const AssetType = styled.span`
flex:1;
display:flex;
flex-direction:row;
justify-content:${props => props.rightSide && 'flex-end'};
span {
color:${props => props.theme.textBlack};
border-bottom:1px solid ${props => props.theme.textGrayLight};
font-size:14px;
padding-right:${props => props.morePadding && '20px'};
line-height: 1.57;
}


`;

export const AssetsContainer = styled.div`
padding-left:21px;
padding-right:21px;
padding-top:29px;
`;

export const AssetsHeader = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
color:${props => props.theme.darkBlue};
font-weight:18px;
`;

export const AssetsBody = styled.div`

`;

export const PrivilegedItem = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
margin-top:22px;
line-height:20px;
`;

export const ItemLeftSide = styled.div`
display:flex;
align-items:center;
font-weight: 300;
font-size:16px;
color:#2e2e33;
line-height:20px;

.horizontaline {
    width:10px;
    height:1px;
    background:${props => props.theme.mainFontColor};
    margin-left:10px;
    margin-right:10px;

    }


`;

export const SponsoredBottomSide = styled.div`
display:flex;
align-items:center;

.amountdays {
font-weight:lighter;
font-size:14px;
color:${props => props.theme.textBlack};
}

.days {
    font-weight:lighter;
font-size:12px;
color:${props => props.theme.textBlack};
}
`;

export const SponsoredBottom = styled.div`
height:95px;
float:right;
width:367.5px;
position:relative;

.verticalline {
    height:95px;
    width:1px;
    background:${props => props.theme.mainFontColor};
    position:absolute;
    left:1.2%;
    top:0;
    z-index:0;
    bottom:0;
}


`;

export const TicketNumber= styled.span`
    color:#2e2e33;
    font-size:14px;
    font-weight:lighter;
    margin-left:11px;
`;

export const SponsoredItemWrapper = styled.div`
    z-index:5;
    margin-top:12.5px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
    line-height:20px;

    .right { 
    width:82px;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
`;

export const Amount = styled.div`
${props => props.customWidth ? `width:${props.customWidth}` : `flex:2` }
display:flex;
font-size:14px;
font-weight:lighter;
color: ${(props) => props.theme.textBlack};
flex-direction:row;
align-items:center;
justify-content:space-between;
`;

export const RightSide = styled.div`
display:flex;
align-items:center;
width:50%;
`;


export const ModalTitleStyles = css`
border:none;
margin:0;
padding-left:25px;
padding-top:25px;
`;

export const ModalCloseStyles = css`
margin-right:25px;
margin-top:25px;
`;

export const DurationModalWrapper = styled.div`

`;

export const DurationModalMiddle = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
    .amount {
        font-size:18px;
        color: ${(props) => props.theme.textBlack};
        margin-right:8px;
    }
    .day {
        color:#2e2e33;
        font-size:12px;
        font-weight:lighter;
    }
`;

export const ConfirmButtonModal = styled.button`
width:335px;
height:50px;
background-image: linear-gradient(to right, #07aed0, #094fd0);
color:white;
font-size:18px;
border:none;
border-radius:5px;
display:flex;
cursor:pointer;
align-items:center;
justify-content:center;
margin-top:${props => props.marginTop && props.marginTop}px;
`;

export const DaysLeftModal = styled.div`
color:#2e2e33;
font-size:18px;
margin-top:20px;
line-height:22px;
font-weight:lighter;

span {
    font-weight:normal;
}

`;

export const PrivilegedSettingModalWrapper = styled.div`
padding-left:20px;
padding-right:20px;
padding-top:15px;
`;

export const BoxStyles = styled.div`
display:flex;
margin-top:20px;
flex-direction:row;
align-items:center;

div {
    margin-left:24px;
    margin-right:24px;
}

`;

export const MapWrapper = styled.div`
width:7px;
height:11.5px;
background-color:rgba(26,146,141,0.05);
`;

export const NoteWrapper = styled.div`
background: rgba(221, 60, 57, 0.05);
padding:18px;
border-radius:10px;
font-size:14px;
line-height:22px;
color:${(props) => props.theme.textBlack};
span {
    line-height:22px;
    color:${(props) => props.theme.noticeRed};
    font-size:14px;

}

`;

export const MiddlebodyWrapper = styled.div`
display:flex;
flex-direction:column;
`;

export const PrivilegedModalItem = styled.div`
height:62px;
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
border-bottom: solid 1px #e3e3e6;

span {
    color:${(props) => props.theme.textGrayLight};
    font-weight:lighter;
    font-size:14px;
}
`;
export const PrivilegedModalPrivacyItem = styled.div`
display:flex;
flex-direction:row;
align-items:center;
margin-top:20px;
justify-content:flex-start;
`;

export const CircleModal = styled.span`
width:23px;
height:23px;
border-radius:100%;
border: 1px solid ${(props) => props.active ? 'transparent' : props.theme.mainFontColor };
background:${(props) => props.active ? 'linear-gradient(134deg, #07aed0 12%, #106ba6 88%);' : 'transparent' };
`;

export const PrivacyText = styled.span`
color:${(props) => props.theme.textBlack};
font-size:18px;
margin-left:10px;
`;