import React, {Component} from "react";
import {Wrapper} from "./Styled";

class LoginRegisterDesign extends Component {
    render() {
        return (
            <Wrapper>
                <i/><i/>
                <em/><em/>
                <b/><b/>
                <u/>
            </Wrapper>
        );
    }
}

export default LoginRegisterDesign;