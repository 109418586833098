import React, { Component } from 'react';
import {
    TabPageStyles,
    CardContainer,
    CardWrapper,
    CardTopWrapper,
    CardtopItem,
    RightBorder,
    TopTextWrapper,
    CarouselMiddleContainer,
    CarouselTopSideHeaderText,
    CarouselBottomAddressText,
    CarouselMiddleBottomSide,
    CarouselMiddlePriceText,
    CarouselMiddleRightSideText,
    CarouselMiddleTopSide,
    CarouselMiddleSide,
    RightSideText,
    RightSideTextBorder,
    RighttSideTextNumber,
    CardMiddlebottomLeftside,
    IconWrapper,
    CardBottomContainer,
    CardBottomLeftSide,
    CardBottomRightSide,
    StatisticsButtonWrapper,
    BoostButtonWrapper,
    RocketIconWrapper,
    TopText,
    VisibleAreaContainer,
    InputTextTagStyles,
    InputTextTagWrapperStyles,
    SearchTags,
    SearchTag,
    PrivilegedSection,
    PrivilegedSectionLeftSide,
    FeaturedSection,
    FeaturedSectionLeftSide,
    SponsoredLeftSide,
    SponsoredSection,
    BoostsectionsHeader,
    PlusMinusSpan,
    PrivilegedRightSide,
    PrivilegedRightSideAmount,
    CardTopContainer,
    CardTopBottomSide
} from './Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import TabPages, { TabStyle } from '../TabBarPages/TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import InputText from '../FormInputText/FormInputText';
import { TabContent, MainContentBg } from '../../../styles/Styled';
import CarosuelShrinkedComponent from '../CarosuelShrinkedComponent';
import { numberFormatter } from '../../../Utils';
import { withRouter } from 'react-router-dom';
import IconNew from '../IconNew';
import MyListingStatistics from '../MyListingsStatistics';
import MyListingsAddTag from '../MyListingsAddTag';



class MyListingsSearchTabBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagInputValue: '',
            tags: [],
            showstatType: { text: 'Profile Views', value: 1 },
            showstatTime: { text: 'Daily', value: 1 },
            isBoostVisible: false,
            data: this.props.fakeData,
            currentVisibilityIndex: 0,
            currentStatisticsData: null,
            currentVisibleArea: {
                cardsVisibility: 0,
                StatisticsVisibility: 1,

            },
        };
        this.leads = {
            newLeads: 50,
            inProgress: 30,
            closed: 20,
        };
        this.typeofSocial2 = {
            Followers: 64,
            Connections: 31,
            Others: 5,
        };
        this.statisticsData = {
            max: 237,
            values: [
                {
                    time: '15-21 Jun',
                    amount: 53,
                },
                {
                    time: '22-28 Jun',
                    amount: 89,
                },
                {
                    time: '29Jun-05Jul',
                    amount: 178,
                },
                {
                    time: '06-12 Jul',
                    amount: 103,
                },
                {
                    time: '13-19 Jul',
                    amount: 237,
                },
            ],
        };
        this.showStatTypes = [
            { text: 'Likes', value: 1 },
            { text: 'Views', value: 2 },
            { text: 'Shares', value: 3 },
            { text: 'New Followers', value: 4 },
            { text: 'Comments', value: 5 },
        ];
        this.showStatTime = [
            { text: 'Daily', value: 1 },
            { text: 'Weekly', value: 2 },
            { text: 'Monthly', value: 3 },
            { text: 'Yearly', value: 4 },
        ];

        this.bottomChartData = [
            { name: 'San Francisco', percetange: 62.8 },
            { name: 'Oakland', percetange: 16.3 },
            { name: 'San Jose', percetange: 12.3 },
            { name: 'Sample', percetange: 7.3 },
            { name: 'Others', percetange: 1.1 },
        ];

    }




    cardContainerWrapper = (item, index) => {
        return (
            <React.Fragment>
                <CardWrapper
                    noMargin={index === 0 || index % 3 === 0}
                    visible={item.isBoostVisible === true}
                >
                    <CardTopContainer>
                        <CardTopWrapper>
                            <CardtopItem>
                                <IconNew
                                    bp1="-446.89"
                                    bp2="-145"
                                    iwidth="20.59"
                                    iheight="13.12"
                                />
                                    <TopText>{numberFormatter(item.likes)}</TopText>
                                <RightBorder />
                            </CardtopItem> 
                            <CardtopItem>
                                <TopTextWrapper>
                                <IconNew
                                    bp1="-429.84"
                                    bp2="-209.2"
                                    iwidth="15.5"
                                    iheight="13.5"
                                />
                                    <TopText>{numberFormatter(item.views)}</TopText>
                                </TopTextWrapper>
                               
                                <RightBorder />
                            </CardtopItem>
                            <CardtopItem>
                                <TopTextWrapper>
                                    <IconNew
                                        bp1="-446.89"
                                        bp2="-177"
                                        iwidth="16.84"
                                        iheight="15.75"

                                    />
                                    <TopText>
                                        {numberFormatter(item.shares)}
                                    </TopText>
                                    <RightBorder /> 
                                </TopTextWrapper>
                            </CardtopItem>
                            <CardtopItem>
                                <TopTextWrapper>
                                    <IconNew
                                        bp1="-468.89"
                                        bp2="-159"
                                        iwidth="13.01"
                                        iheight="14.03"

                                    />
                                    <TopText>
                                        {numberFormatter(item.shares)}
                                    </TopText>
                                    
                                </TopTextWrapper>
                            </CardtopItem>
                        </CardTopWrapper>
                        <div>
                            <CardTopBottomSide>
                                <CardtopItem>
                                    <IconNew
                                        bp1="-448.15"
                                        bp2="-227"
                                        iwidth="14.8"
                                        iheight="11.5"

                                    />
                                    <TopText>5</TopText>
                                    <RightBorder />
                                </CardtopItem>
                                <CardtopItem>
                                    <IconNew
                                        bp1="-448.15"
                                        bp2="-210.81"
                                        iwidth="11.8"
                                        iheight="11.8"

                                    />
                                    <TopText>118</TopText>
                                    <RightBorder />
                                </CardtopItem>
                                <CardtopItem>
                                    <IconNew
                                        bp1="-448.15"
                                        bp2="-227"
                                        iwidth="14.8"
                                        iheight="11.5"

                                    />
                                    <TopText>32</TopText>
                                </CardtopItem>
                            </CardTopBottomSide>
                        </div>
                    </CardTopContainer>
                    <CarosuelShrinkedComponent data={item.photos} />
                    <CarouselMiddleContainer bigPaddingTop>
                        <CarouselMiddleTopSide>
                            <CarouselTopSideHeaderText>
                                Best Property in Thes Area
                            </CarouselTopSideHeaderText>
                            <IconWrapper>
                                <IconNew
                                    bp1="-396"
                                    bp2="-220"
                                    iwidth="13"
                                    iheight="14"
                                />
                            </IconWrapper>
                        </CarouselMiddleTopSide>
                        <CarouselMiddleSide>
                            <CarouselMiddlePriceText>
                                $3,400 / m
                            </CarouselMiddlePriceText>
                            <CarouselMiddleRightSideText>
                                <RightSideText>
                                    <RighttSideTextNumber>
                                        4{' '}
                                    </RighttSideTextNumber>{' '}
                                    BED
                                    <RightSideTextBorder />
                                </RightSideText>
                                <RightSideText>
                                    <RighttSideTextNumber>
                                        2{' '}
                                    </RighttSideTextNumber>{' '}
                                    BATH
                                    <RightSideTextBorder />
                                </RightSideText>
                                <RightSideText lastItem>
                                    <RighttSideTextNumber>
                                        2,750{' '}
                                    </RighttSideTextNumber>{' '}
                                    sqft
                                </RightSideText>
                            </CarouselMiddleRightSideText>
                        </CarouselMiddleSide>
                        <CarouselMiddleBottomSide>
                            <CardMiddlebottomLeftside>
                                <IconWrapper>
                                    <IconNew
                                        bp1="-172"
                                        bp2="-115"
                                        iwidth="9"
                                        iheight="13"
                                        className="heart"
                                    />
                                </IconWrapper>
                                <CarouselBottomAddressText>
                                    548 Market St, San Francisco
                                </CarouselBottomAddressText>
                            </CardMiddlebottomLeftside>
                        </CarouselMiddleBottomSide>
                    </CarouselMiddleContainer>
                    <CardBottomContainer>
                        <CardBottomLeftSide>
                            <IconNew
                                bp1="-201"
                                bp2="-77"
                                iwidth="26"
                                iheight="26"
                            />
                            <IconNew
                                bp1="-229"
                                bp2="-77"
                                iwidth="26"
                                iheight="26"
                            />
                            <IconNew
                                bp1="-257"
                                bp2="-77"
                                iwidth="26"
                                iheight="26"
                            />
                        </CardBottomLeftSide>
                        <CardBottomRightSide>
                            <StatisticsButtonWrapper
                                onClick={() =>
                                    this.onStatisticsButtonHandler(index, item.id)
                                }
                            >
                                Statistics
                            </StatisticsButtonWrapper>
                            <BoostButtonWrapper
                                onClick={() =>
                                    this.boostButtonHandler(item.id, index)
                                }
                            >
                                Boost <RocketIconWrapper>h</RocketIconWrapper>
                            </BoostButtonWrapper>
                        </CardBottomRightSide>
                    </CardBottomContainer>
                    {item.isBoostVisible && (
                        <VisibleAreaContainer>
                            <PrivilegedSection>
                                <PrivilegedSectionLeftSide>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                    <BoostsectionsHeader>
                                        Privileged&#174;
                                    </BoostsectionsHeader>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                </PrivilegedSectionLeftSide>
                                <PrivilegedRightSide>
                                    <PrivilegedRightSideAmount>
                                        1 / 2
                                    </PrivilegedRightSideAmount>
                                    <PlusMinusSpan>-</PlusMinusSpan>
                                </PrivilegedRightSide>
                            </PrivilegedSection>
                            <FeaturedSection>
                                <FeaturedSectionLeftSide>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                    <BoostsectionsHeader>
                                        Featured&#174;
                                    </BoostsectionsHeader>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                </FeaturedSectionLeftSide>
                                <PlusMinusSpan>+</PlusMinusSpan>
                            </FeaturedSection>
                            <SponsoredSection>
                                <SponsoredLeftSide>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                    <BoostsectionsHeader>
                                        Sponsored / Additional Zipcodes&#174;
                                    </BoostsectionsHeader>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                </SponsoredLeftSide>
                                <span>+</span>
                            </SponsoredSection>
                        </VisibleAreaContainer>
                    )}
                </CardWrapper>
            </React.Fragment>
        );
    };


    removeTag = (index) => {
        let tags = [...this.state.tags];
        tags.splice(index, 1);
        this.setState({ tags });
    };


    onStatisticsButtonHandler = (index,id) => {
        this.setState({
            currentStatisticsData: index,
            currentVisibilityIndex: VisibilityTypes.STATISTICS,
            setStatisticIndex: id
        });
        this.props.history.push({
            pathname: `/my-listings/statistics/${id}`
        });
    };

    boostButtonHandler = (id, index) => {
        this.setState({
            currentStatisticsData: index,
            currentVisibilityIndex: 2,
            setStatisticIndex: id
        });
        this.props.history.push({
            pathname: `/my-listings/add-tag/${id}`
        });
    };

    tagChangeHandler = (e) => {
        this.setState({
            tagInputValue: e.target.value,
        });
    };


    tagEnterHandler = () => {
        this.setState((prevState) => {
            let tags = [...prevState.tags];
            tags.push(this.state.tagInputValue);
            return {
                tags,
                tagInputValue: '',
            };
        });
    };



    render() {

        const dataLength = this.state.data.length;
        const filterPrivileged = this.state.data.filter(
            (item) => item.type === 'privileged'
        ).length;
        const filterFeatured = this.state.data.filter(
            (item) => item.type === 'featured'
        ).length;
        const filterSponsored = this.state.data.filter(
            (item) => item.type === 'sponsored'
        ).length;
        return (
            <React.Fragment>
                <MainContentBg>
                    <InputText
                        id="tagsInput"
                        name="tagsInput"
                        type="text"
                        onInput={this.tagChangeHandler}
                        value={this.state.tagInputValue}
                        onEnter={this.tagEnterHandler}
                        styled={InputTextTagStyles}
                        wrapperStyled={InputTextTagWrapperStyles}
                        placeholder="add tags..."
                                >
                                <IconNew
                                    bp1="-440"
                                    bp2="-49"
                                    iwidth="26"
                                    iheight="26"
                                 /> 
                                </InputText>
                                <SearchTags>
                                    {this.state.tags.map((tag, index) => (
                                        <SearchTag key={index}>
                                            <IconNew
                                                bp1="-467"
                                                bp2="-60"
                                                iwidth="10"
                                                iheight="15"
                                            />
                                            {tag}
                                            <i
                                                onClick={(e) =>
                                                    this.removeTag(index)
                                                }
                                            />
                                        </SearchTag>
                                    ))}
                                </SearchTags>
                </MainContentBg>
                 <TabContent margin='0'>
                        <TabPages
                            theme={TabStyle.MyListingsTab}
                            defaultPageIndex={this.props.defaultPageIndex}
                            Type="myListingsType"
                        >
                            <TabPage
                                title={`Listing (${dataLength})`}
                                styled={TabPageStyles}
                            >
                                {this.props.pageIndex ===
                                   0 && (
                                    <CardContainer>
                                        {this.state.data.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {this.cardContainerWrapper(
                                                        item,
                                                        index
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </CardContainer>
                                )}
                                 {(this.props.pageIndex ===
                                    1) && (
                                       <MyListingStatistics 
                                        id={this.props.currentStatisticsIndex}
                                        data={this.props.fakeData}
                                        backButtonHandler={this.props.backButtonHandler}
                                       />
                                )}
                                 {this.props.pageIndex === 
                                2 && (
                                    <MyListingsAddTag 
                                        id={this.props.currentStatisticsIndex}
                                        data={this.props.fakeData}
                                        backButtonHandler={this.props.backButtonHandler}
                                    />
                                )}
                                <ClearFloats />
                            </TabPage>

                            <TabPage
                                title={`Privileged (${filterPrivileged})`}
                                styled={TabPageStyles}
                            >
                                {this.props.pageIndex === VisibilityTypes.CARDS && (
                                     <CardContainer>
                                        {this.state.data
                                            .filter(
                                                (item) => item.type === 'privileged'
                                            )
                                            .map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {this.cardContainerWrapper(
                                                            item,
                                                            index
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                     </CardContainer>
                                )}
                                {this.props.pageIndex ===
                                    1 && (
                                       <MyListingStatistics 
                                            id={this.props.currentStatisticsIndex}
                                            data={this.props.fakeData}
                                            backButtonHandler={this.props.backButtonHandler}
                                       />
                                )}
                                 {this.props.pageIndex === 
                                2 && (
                                    <MyListingsAddTag
                                        id={this.props.currentStatisticsIndex}
                                        data={this.props.fakeData}
                                        backButtonHandler={this.props.backButtonHandler}
                                    />
                                )}       
                            </TabPage>
                            <TabPage
                                title={`Featured (${filterFeatured})`}
                                styled={TabPageStyles}
                            >
                                {this.props.pageIndex === VisibilityTypes.CARDS && (
                                    <CardContainer>
                                        {this.state.data
                                            .filter(
                                                (item) => item.type === 'featured'
                                            )
                                            .map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {this.cardContainerWrapper(
                                                            item,
                                                            index
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </CardContainer>
                                )}
                                {this.props.pageIndex ===
                                    1 && (
                                       <MyListingStatistics 
                                            id={this.props.currentStatisticsIndex}
                                            data={this.props.fakeData}
                                            backButtonHandler={this.props.backButtonHandler}
                                       />
                                )}   
                                {this.props.pageIndex === 
                                2 && (
                                    <MyListingsAddTag
                                        id={this.props.currentStatisticsIndex}
                                        data={this.props.fakeData}
                                        backButtonHandler={this.props.backButtonHandler}
                                    />
                                )}
                                <ClearFloats />
                            </TabPage>
                            <TabPage
                                title={`Sponsored (${filterSponsored})`}
                                styled={TabPageStyles}
                            >
                                {this.props.pageIndex === VisibilityTypes.CARDS && (
                                    <CardContainer>
                                        {this.state.data
                                            .filter(
                                                (item) => item.type === 'sponsored'
                                            )
                                            .map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {this.cardContainerWrapper(
                                                            item,
                                                            index
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </CardContainer>
                                )}
                                {this.props.pageIndex ===
                                    1 && (
                                       <MyListingStatistics 
                                        id={this.props.currentStatisticsIndex}
                                        data={this.props.fakeData}
                                        backButtonHandler={this.props.backButtonHandler}
                                       />
                                )}  
                                 {this.props.pageIndex === 
                                2 && (
                                    <MyListingsAddTag
                                    id={this.props.currentStatisticsIndex}
                                    data={this.props.fakeData}
                                    backButtonHandler={this.props.backButtonHandler}
                                    />
                                )}
                                <ClearFloats />
                            </TabPage>
                        </TabPages>
                    </TabContent>
            </React.Fragment>
        );
    }
}



export default withRouter(MyListingsSearchTabBar);


const VisibilityTypes = {
    CARDS: 0,
    STATISTICS: 1,
};