import styled, { css } from 'styled-components';


export const SponsoredColumnWrapper = styled.div`
background:white;
`;

export const TopWrapper = styled.div`
height:191px;
padding-top:20px;
display:flex;
border-bottom:1px solid ${(props) => props.theme.viridianGreen};
flex-direction:column;
justify-content:space-between;
padding-left:30px;
padding-bottom:28.5px;
background-color: rgba(26, 146, 141, 0.05);
.header {
    display:flex;
    align-items:center;
    text-align:right;
    padding-right:20px;
    align-self:flex-end;

    span {
        font-size:22;
        color:${(props) => props.theme.darkBlue};
    }
}

.totalsection {
    display:flex;
    flex-direction:column;
    padding-right:30px;
}
`;

export const BackButton = styled.button`
    border: 0;
    background: transparent;
    
    i {
        top: -4px;
    }
`;

export const AmountText = styled.span`
 color:${(props) => props.budget ?  props.theme.textBlack : props.theme.mainFontColor};
 font-size:16px;
 font-weight: 600;
`;

export const TotalItem = styled.div`
display:flex;
flex-direction:row;
margin-top:10px;
align-items:center;
justify-content:space-between;
 span {

    font-size:16px;
 }
 .budget { 
    color:${(props) => props.theme.textBlack};
    font-size:16px;
     }
`;

export const NumberText = styled.span`
    font-size:18px;
    font-weight:lighter;
    color:${(props) => props.theme.mainFontColor};
    margin-left:10px;
`;


export const DueDate = styled.div`
padding-left:18px;
 div {
     display:flex;
     flex-direction:row;
     width:216px;
     align-items:center;
     justify-content:space-between;
     line-height:30px;
 }


 .date { 
    font-size:14px;
    color:${(props) => props.theme.textBlack};

 }

 .datetype {
     color:#2e2e33;
     font-weight:lighter;
     font-size:12px;
 }
`;

export const DaysLeftText = styled.span`
    font-size:16px;
    color:${(props) => props.theme.darkBlue};
`;

export const TopSide = styled.div`
/* height:${props => props.isExtended ? '104.5px' : '54.5px'}; */
border-bottom: solid 1px #e3e3e3;
position:relative;
padding-top:22px;
padding-bottom:14.5px;
`;

export const LeftSideCard = styled.div`

`;

export const OrderNumber =styled.div`
font-weight:lighter;
font-size:12px;
line-height:15px;
color:#bcbcbc;
margin-left:8.4px;
`;

export const DeleteButton = styled.button`
width:18px;
height:18px;
position:absolute;
top:0;
right:0;
border-bottom-left-radius:5px;
border: solid 1px #e3e3e3;
background-color: rgba(241, 241, 242, 0.5);
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
`;

export const ReorderButton = styled.button`
 background-color:${(props) => props.theme.darkBlue};
 font-size:14px;
 color:white;
 display:flex;
 height:30px;
 cursor:pointer;
 border-bottom-left-radius:5px;
 width:100.5%;
 border:none;
 border-bottom-right-radius:5px;
 justify-content:center;
 align-items:center;
 
`;

export const CardWrapper = styled.div`
/* height:${props => props.isExtended ? '140px' : '90px'}; */
display:flex;
width:100%;

border-bottom-left-radius:5px;
border-bottom-right-radius:5px;
margin-top:20px;
flex-direction:column;
border-top-left-radius:5px;
border-top-right-radius:5px;
border: solid 1px #e3e3e3;


.topside {
    display:flex;
    flex-direction:row;
    padding-left:18px;
    height:${props => !props.isextended && '100%'};
    padding-right:18px;
    justify-content:space-between;
    align-items:center;
    
.daysleft {
    font-size:12px;
    color:${(props) => props.theme.darkBlue};
    span {
        font-size:16px;
        color:${(props) => props.theme.darkBlue};
    }
}

};
.bottomside {
    height:35.5px;
    display:flex;
    padding-left:18px;
    padding-right:18px;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    cursor:pointer;
    font-size:12px;

    .arrowup  {
        height:20px;
    }

    div {
        display:flex;
        flex-direction:row;
        align-items:center;
    }

    div.right {
        color:#2e2e33;
        font-size:12px;
        font-weight:lighter;
        display:flex;
        flex-direction:row;
        align-items:center;
    }
    span {
        color:${(props) => props.theme.textBlack};
        font-size:16px;
        font-weight:normal;
        padding-left:${props => props.isextended && '15px'};

    }
}
`;



export const BodyWrapper = styled.div`
padding-top:30px;
padding-left:30px;
padding-right:30px;
padding-bottom:44px;
`;

export const BodyHeader = styled.div`
margin-top:${props => props.margintop && props.margintop};
color:${(props) => props.theme.darkBlue};
font-size:18px;

`;

export const ExpiredTag = styled.span`


color:${(props) => props.theme.mainFontColor};
font-size:10px;
position:absolute;
line-height:13px;
right:20px;
top:-6.5px;
background:white;
padding-left:5px;
padding-right:5px;
`;

export const BlueButtonWrapper = styled.button`
border:none;
border-radius:10px;
margin-top:35px;
display:flex;
align-items:center;
justify-content:center;
font-size:16px;
color:white;
height:50px;
width:100%;
display:flex;
align-items:center;
background-image: linear-gradient(to right, #07aed0, #094fd0);
`;

export const BottomButtons= styled.div`
display:flex;
flex-direction:row;
align-items:center;
width:100.5%;
justify-content:space-between;
`;

export const ButtonCardWrapper = styled.div`
width:48%;
background-color:#bcbcbc;
height:30px;
border:none;
border-bottom-left-radius:5px;
border-bottom-right-radius:5px;
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
color:${(props) => props.theme.textBlack};
font-size:14px;
cursor:pointer;
`;

export const ModalTitleStyles = css`
border:none;
margin:0;
color:${(props) => props.theme.textBlack};
font-size:16px;
padding-left:25px;
padding-top:25px;
line-height:30px;
`;

export const ModalCloseStyles = css`
margin-right:25px;
margin-top:25px;
`;


export const SponsoredPackageContent = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-around;
padding-left:30px;
margin-top:76px;
padding-right:30px;
`;

export const RefundButton = styled.button`
border:none;
cursor:pointer;
width:45%;
color:${(props) => props.theme.textBlack};
background:transparent;
border-bottom: solid 1px ${(props) => props.theme.textGrayLight};;
font-size:16px;
width:100px;
`;
export const AssetsButton = styled.button`
height:40px;
border:none;
cursor:pointer;
background-image: linear-gradient(to right, #094fd0,  #07aed0);
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
width:45%;
border-radius:5px;
color:white;
font-size:16px;
`;