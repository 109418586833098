import React, { Component } from 'react';
import { AlbumWrapper,
        Options, 
        ImgWrapper,
        TileWrap, 
        FullScreenImageWrapper,
        BottomNavWrapper,
        BottomNavWrapperStyle,
        MainHeader,
        MainInformation,
        LikeNumber,
        BackToAlbumGallery
    } from './Styled';
import TabPages, { TabStyle } from '../TabBarPages/TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import GroupAlbumGalleryImageCard from '../GroupAlbumGalleryImageCard';


let brakePoints = [500, 750, 1000];

export class GroupAlbumGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: null,
            showAlbumGallery:true,
            currentImage:null,
            albumGalleryName: this.props.dynamicTitle,
            activeIndex: props.activeTabIndex,
            imageInformation: {
                galleryName: '',
                imageDescription: '',
                imageLikes: ''
            }
        };
    }
    

    imageClickHandler = (currentImage,likes,description,id, name) => {
        this.setState({
            imageInformation:{...this.state.imageInformation, imageDescription:description, imageLikes:likes},
            showAlbumGallery:false,
            currentImage,
            activeIndex:id,
            albumGalleryName:name

        })
        
    }

    backAlbumGalleryHandler = () => {
        this.setState({
            showAlbumGallery:true,
            imageInformation:{...this.state.imageInformation, galleryName:'',imageDescription:'',imageLikes:''}
        })
    }

    heightHandler = () => {
        console.log(this.state.height);
    };
    render() {

        if(this.state.showAlbumGallery === true) {
            return (
                <AlbumWrapper>
                    <TabPages
                        theme={TabStyle.GroupGalleryTab}
                        defaultPageIndex={this.state.activeIndex}
                        dynamicTitle={this.state.albumGalleryName}
                        galleryTabClickHandler={() =>
                            this.props.galleryTabClickHandler()
                        }
                        activeTabIndex={this.state.activeIndex}
                        handleButtonClick={this.props.handleButtonClick}
                    >
                        {this.props.imageGallery.map((item) => (
                            <TabPage
                                title={item.name}
                                key={item.id}
                                styled={Options}
                            >
                                <ImgWrapper>
                                        <GroupAlbumGalleryImageCard brakePoints={brakePoints}>
                                            {item.photos.map((image, index) => {
                                                return (
                                                    <Tile itemIndex={index} key={index} src={image.image} imageId={image.id} clickHandler={() => this.imageClickHandler(image.image,image.likes,image.description,item.id,item.name)}  />
                                                ) 
                                        })}
                                        </GroupAlbumGalleryImageCard>
                                </ImgWrapper>
                            </TabPage>
                        ))}
                    </TabPages>
                </AlbumWrapper>
            );
        } else if (this.state.showAlbumGallery === false) {
            return (
                <FullScreenImageWrapper>
                    <img src={this.state.currentImage} alt='propertyPicture'  />
                    <BottomNavWrapper>
                        <BottomNavWrapperStyle>
                        <MainHeader>{this.state.albumGalleryName}</MainHeader>
                        <MainInformation>{this.state.imageInformation.imageDescription}</MainInformation>
                        <LikeNumber>{this.state.imageInformation.imageLikes}</LikeNumber>
                        </BottomNavWrapperStyle>
                    </BottomNavWrapper>
                    <BackToAlbumGallery onClick={this.backAlbumGalleryHandler}>
                        X
                    </BackToAlbumGallery>
                </FullScreenImageWrapper>
            );
        }

    }
}

const Tile = ({ src, itemIndex, clickHandler }) => {
    return (
        <TileWrap index={itemIndex}>
            <img onClick={(id) => clickHandler(id)}  src={src} alt='property' />
        </TileWrap>
    );
  };

export default GroupAlbumGallery;
