import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
height: 100px;
position:fixed;
left:0;
top:0;
right:0;
display:flex;
align-items:center;
justify-content:space-between;
background-image:linear-gradient(to left, rgba(25, 37, 124, 0.5), rgba(21, 52, 136, 0.5));
padding-left:5.208333333333334%;
padding-right:5.208333333333334%;
z-index:1000;

`;

export const HeaderSection = styled.div`
align-items:center;
display:flex;
align-items:center;
justify-content:${props => props.right ? 'flex-end' : 'flex-start'};
width:50%;


`;

export const BurgerMenuWrapper = styled.div`
display:none;

@media ${props => props.theme.mediaMobile} {
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
width:100%;
height:100%;

div {
    width:100%;
    height:5%;
    min-width:30px;
    background-color: white;
    margin: 13% 0;
    border-radius:5px;
}
}
`;


export const HeaderRight = styled.div`

    @media ${props => props.theme.mediaMobile} {

        width:6%;
        font-size:29px;
        height:100%;
        display:flex;
        flex-direction:row;
        align-items:center;

        .options { 
            position:absolute;
            top:100px;
            left:0;
            right:0;
            display:${props => props.isActive ? 'flex'  : 'none'};
            flex-direction:column;
            z-index:1000;
            background-image:linear-gradient(to left, #7106C4, #19257C);
            
        }
 
    }

`;

export const NavItem = styled.a`
    cursor:pointer;
    margin-left:100px;
    color:white;
    text-decoration:underline;
    font-size:20px;
    @media (max-width:767px) {
        margin-left:0;
    }

    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 16px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 14px;    
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;

    }
   
`;

export const LogoWrapper = styled.img`
    width:100%;
    max-width:290px;
    height:60px;
`;

export const HeaderOptions = styled.div`
display:flex;
justify-content:space-between;
align-items:center;

`;


export const HeaderItem = styled.div`
    cursor:pointer;
    color:white;
    font-size:25px;

@media ${props => props.theme.mediaTabletLandscape} {
        font-size: 22px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 20px;    
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 16px;

    }
`;


export const FirstSectionWrapper = styled.div`
    display:flex;
    position:relative;
    justify-content:flex-start;
    align-items:center;
    padding-left:5.208333333333334%;
    padding-right:5.208333333333334%;
    min-height:100vh;
    z-index:1;
`;

export const BackgroundVideoWrapper = styled.video`
    position:absolute;
    z-index:-1;
    width:100%;
    height:100%;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    object-fit:cover;

`;

export const BackLayer = styled.div`
  position:absolute;
    z-index:0;
    width:100%;
    height:100%;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    object-fit:cover;
    background-color:rgba(0,0,0, 0.5);
`;

export const FirstSectionContainer = styled.div`
z-index:10;
display:flex;
flex-direction:column;

.bottom-text {
    background-image:linear-gradient(to left, rgba(25, 37, 124, 0.5), rgba(21, 52, 136, 0.5));
    font-size:32px;
    padding:3.5%;
    border-radius:5px;
    font-weight:bold;
    box-sizing:border-box;
    color:#464646;
    margin-top:2%;
    line-height:normal;
    color:${props => props.color ? props.color : 'white'};
    width:100%;
    text-align:${props => props.centered ? 'center'  : 'start'};
    margin-bottom:30px;
    
    @media (max-width: 992px) {
        width:100%;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        width:100%;
        font-size:24px
        
    }
    @media ${props => props.theme.mediaTablet} {
        width:100%;
        font-size:28px;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 19px;
        
    }
}


@media (max-width: 992px) {
        flex-direction:column;
        align-items:center;
        margin-top:20px;
    }

.mouse-wrap {
    position: absolute; 
    left: 0;
    right: 0; 
    bottom: 20px;
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    display:none;
    @media ${props => props.theme.mediaMobile} {
        font-size: 16px;
        display:flex;
    }
}
`;


export const DescriptionText = styled.div`
    font-size:16px;
    font-weight:bold;
    color:#464646;
    line-height:normal;
    color:${props => props.color ? props.color : 'white'};
    width:80%;
    text-align:${props => props.centered ? 'center'  : 'start'};
    margin-bottom:30px;
    text-align:${props => props.centered &&'center'};
    @media (max-width: 992px) {
        width:100%;
    }
    @media ${props => props.theme.mediaTabletLandscape} {
        width:100%;
        
    }
    @media ${props => props.theme.mediaTablet} {
        width:100%;
        
    }
`;

export const DownloadButton = styled.div`
display:flex;
align-items:center;
box-sizing: border-box;
justify-content:center;
color:black;
cursor:pointer;
font-size:16px;
background-image:linear-gradient(to right, #7106C4, #19257C);
height:50px;;
width:120px;
color:white;
border-radius:5px;
`;


export const ReadMoreWrapper = styled.a`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
cursor:pointer;
width:37%;
min-width:100px;
margin-left:auto;
margin-right:auto;
font-size:24px;
border-radius: 15px;
background:#17297F;
color:white;
font-weight:bold;
@media ${props => props.theme.mediaTabletLandscape} {
        font-size: 16px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 14px;    
        width:15%;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;
        width:15%;
    }
    span { 
    margin-left:10px;
}
`;

export const ScrollBox= styled.div`
background:#17297F;
width:fit-content;
align-self:flex-end;
display:flex;
flex-direction:row;
align-items:center;
border-radius: 10px;
justify-content:center;
margin-right:5%;
cursor:pointer;
width:85px;
height:85px;

@media ${props => props.theme.mediaTabletLandscape} {
        width:75px;
        height:75px;
    }
    @media ${props => props.theme.mediaTablet} {
        width:65px;
        height:65px;
    }
    @media ${props => props.theme.mediaMobile} {
        width:55px;
        height:55px;
    }

    @media ${props => props.theme.mediaHD} {
        
    }

    img {
    width:43px;
    height:28px;
    @media ${props => props.theme.mediaTabletLandscape} {
        width:40px;
        height:26px;
    }   
    @media ${props => props.theme.mediaTablet} {
        width:35px;
        height:23px;
    }
    @media ${props => props.theme.mediaMobile} {
        width:30px;
        height:20px;
    } 
    }
`;

export const ButtonWrapper = styled.div`
display:flex;
align-items:center;
margin-top:5%;
justify-content:flex-start;
display:none;
    @media (max-width: 992px) {
        align-self:flex-start;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 16px;
        display:block;
    }
`;

export const CircleButton = styled.div`
display:flex;
align-items:center;
justify-content:center;
color:black;
font-size:16px;
border-radius:100%;
background:white;
width:50px;
height:50px;
`;

export const ImgWrapper = styled.img`
width:100%;
height:auto;
`;


export const SecondSectionWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:#DFDFDF;
    padding-top:clamp(15px, 5.3%, 100px);
    padding-bottom:clamp(15px, 5.3%, 100px);
`;


export const HeaderText = styled.div`
  text-align:${props => props.centered &&'center'};
  font-size:80px;
  line-height:normal;
  font-weight:bold;
  color:${props => props.color  ? props.color : '#464646'};

    @media ${props => props.theme.mediaTabletLandscape} {

       font-size:60px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 50px;    
        margin-top:35px;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 40px;
        margin-top:35px;
    }

`;



export const ItemText = styled.div`
    font-size:40px;
    line-height:1;
    color:#4E4E4E;
    text-align:center;

    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 25px;  
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 25px;    
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 20px;
    }

`;



export const ItemsWrapper = styled.div`
display:flex;
align-items:flex-start;
flex-wrap:wrap;
align-items:stretch;
justify-content:space-between;
width:100%;
margin-top:10%;
margin-bottom:5%;
`;

export const  ItemContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    flex: 1 0 23%;
    height:100%;
    padding-left:1%;
    padding-right:1%;

    @media ${props => props.theme.mediaTabletLandscape} {
         
        }
        @media ${props => props.theme.mediaTablet} {
            flex: 1 0 48%;
            margin-top:40px;
        }
        @media ${props => props.theme.mediaMobile} {
            flex: 1 0 100%;
            margin-top:40px;
        }

    img { 
        width:100%;
        
    @media ${props => props.theme.mediaTabletLandscape} {
         
    }
    @media ${props => props.theme.mediaTablet} {
        width:80%;
    }
    @media ${props => props.theme.mediaMobile} {
        width:50%;
    }
    }
    

    .bottom-container {
        flex:1;
        margin-top:44px;
        @media ${props => props.theme.mediaTabletLandscape} {
         
        }
        @media ${props => props.theme.mediaTablet} {
            margin-top:14px;
        }
        @media ${props => props.theme.mediaMobile} {
            margin-top:14px;
        }
    }

    .icon-wrapper  {
       width:100%;
       max-width:193px;
       max-height:193px;
       display:flex;
       flex-direction:row;
       justify-content:center;
    }

    
`;

export const ItemDescription = styled.div`
    font-size:16px;
    color:#666666;
    width:68%;
    line-height:22px;
    text-align:center;
`;

export const ButtonClick = styled.div`
background-image:${props=> props.color ? props.color : 'linear-gradient(to right, #7106C4, #19257C)'};
width:202px;
height:60px;
display:flex;
flex-direction:row;
align-items:center;
font-size:16px;
color:${props => props.textColor && props.textColor};
border-radius:5px;
justify-content:center;
margin-top:86px;
`;


export const FifthSectionWrapper = styled.div`
padding-top:clamp(15px, 5.3%, 100px);
padding-bottom:clamp(15px, 5.3%, 100px);
background:white;
`;

export const SpecialistContentWrapper = css`
overflow: hidden;

    @media(min-width:1021px) {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    }

@media (max-width: 1020px) {
    display:flex;
    overflow: hidden;
}

`;

export const CardWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
padding:1.9%;
margin-bottom:2.8%;
border-left:${props => props.middle && '1px solid #707070'};
border-right:${props => props.middle && '1px solid #707070'};

img { 
    width:80%;
    max-width:417px;
    max-height:417px;
    @media ${props => props.theme.mediaTablet} {
        max-width:258px;
        max-height:258px;
    }
    @media ${props => props.theme.mediaMobile} {
        max-width:200px;
        max-height:200px;

    }
}

    @media ${props => props.theme.mediaTablet} {
        border-left:none;
        border-right:none;
    }
    @media ${props => props.theme.mediaMobile} {
        border-left:none;
        border-right:none;

    }

`;

export const CardContentTop = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    margin-top:15%;

    @media ${props => props.theme.mediaTablet} {
        margin-top:22px;    
    }
    @media ${props => props.theme.mediaMobile} {
        margin-top:22px;    
    }
`;

export const LeftSideCard = styled.div`
  width:43px;
  height:50px;
  display:flex;
  align-items:center;
  justify-content:center;
`;

export const CardHeader = styled.div`
font-size:30px;
line-height:1;
text-align:center;
color:#464646;
  @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 30px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 25px;    
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 15px;

    }

`;

export const CardDescription = styled.div`
  font-size:20px;  
  margin-top:5%;
  margin-bottom:5%;
  line-height:1.3;
  text-align:center;
  flex:1;
  width:80%;
  color:#464646;
  display:flex;
  align-items:flex-start;

  @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 17px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 13px;    
        margin-top:22px;
        margin-bottom:22px;
        width:70%;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;
        margin-top:22px;
        margin-bottom:22px;
        width:70%;

    }

 
`;

export const SecondDescriptionText = styled.div`
    font-size:20px;
    color:#666666;
    height:20%;
    margin-top:44px;
    text-align:center;
    line-height:1.3;
    white-space: pre-line;
    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 18px;  
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 16px;    
        margin-top:14px;
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;
        margin-top:14px;
    }
`;


export const CardBoxHeader = styled.div`
font-size:36px;
color:white;
`;

export const ToggleHeaderWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`;

export const ProfilePictureWrapper = styled.div`
border-radius:100%;
width:84px;
height:84px;
margin-right:16px;
`;

export const SeventhHeaderText = styled.div`
font-size:40px;
font-weight:bold;
margin-bottom:25px;
line-height:1;
`;

export const FooterWrapper = styled.div`
height:256px;
position:relative;
left:0;
bottom:0;
right:0;
display:flex;
flex-direction:column;
background-image:linear-gradient(to left, #17297F, #153488);
padding-left:5.208333333333334%;
padding-right:5.208333333333334%;
z-index:1000;
font-size:30px;

@media ${props => props.theme.mediaTabletLandscape} {
        font-size: 22px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 20px;    
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 18px;

    }

.topside { 
    width:40%;
    height:72.265625%;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    @media ${props => props.theme.mediaMobile} {
        width:100%;

    }
    @media ${props => props.theme.mediaTablet} {
       width: 50%;    
    }
}

.topside-item { 
    display:flex;
    flex-direction:row;
    align-items:center;
    flex:1

    div { 
        flex:1
    }
}

.bottomside {
    border-top: 2px solid #FFFFFF;
    text-align:center;
    font-size:22px;
    display:flex;
    height:27.34375%;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    @media ${props => props.theme.mediaTabletLandscape} {
        font-size: 18px;
    }
    @media ${props => props.theme.mediaTablet} {
        font-size: 16px;    
    }
    @media ${props => props.theme.mediaMobile} {
        font-size: 12px;

    }
}
`;

export const IconWrapper = styled.div`
    display:flex;
    align-items:center;
`;

export const IconItem = styled.a`
    margin-left:${props=> props.notFirst && '35px' };
`