import React from 'react';
import TabPages, { TabStyle } from '../TabBarPages/TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import {
    ProfileMenuVaultInvatationTabWrapper,
    TabWrapper,
    InputWrapper,
} from './Styled';
import ProfileMenuVaultInvatationTabusercardContainer from '../ProfileMenuVaultInvatationTabUsercardContainer';

class ProfileMenuVaultInvatationTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue:''
        };

        this.colors = [
            {
                bgColor:'transparent linear-gradient(180deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box',
                color:'#07AED0'
            },
            {
                bgColor:'transparent linear-gradient(180deg, #3F4EBC 0%, #121E74 100%) 0% 0% no-repeat padding-box',
                color:'#19257C'
            },
            {
                bgColor:'transparent linear-gradient(180deg, #3FB5B0 0%, #108883 100%) 0% 0% no-repeat padding-box',
                color:'#1A928D'
            },  {
                bgColor:'transparent linear-gradient(180deg, #AF5DEE 0%, #7106C4 100%) 0% 0% no-repeat padding-box',
                color:'#7106C4'
            }
        ];
        this.invitesLeftCounter = Array.from({length:props.invatation_count},(_, i ) => i + 1);

    };


    searchHandler = (e) => {
        this.setState({
            inputValue: e.target.value
        })
    }

    handler = () => {
        console.log('hey')
    }

    mapInvatationCard = () => {
        return (
            <React.Fragment>
                {this.invitesLeftCounter.map((item, index) => {
                     return (
                        <React.Fragment key={index}>
                            {this.props.InvatationCard(item, index)}
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    render() {
        return (
            <ProfileMenuVaultInvatationTabWrapper>
                <TabPages
                    theme={TabStyle.ProfileMenuVaultTabBar}
                    defaultPageIndex={0}
                >
                        <TabPage
                            title='All'
                        >
                        <TabWrapper>
                            <InputWrapper
                                placeholder={'Search'}
                                onChange={(e) => this.searchHandler(e)}
                            />
                            {this.mapInvatationCard()}
                            <ProfileMenuVaultInvatationTabusercardContainer 
                                data={this.props.data.users.accepted}
                                type={'connected'}
                            />
                            <ProfileMenuVaultInvatationTabusercardContainer 
                                data={this.props.data.users.pending}
                                type={'pending'}
                            />
                        </TabWrapper>
                        </TabPage>
                        <TabPage
                            title='Available'
                        >
                            <TabWrapper>
                                {this.mapInvatationCard()}
                            </TabWrapper>
                        </TabPage>
                        <TabPage
                            title='Pending'
                        >
                            <TabWrapper>
                                <InputWrapper
                                    placeholder={'Search'}
                                    onChange={(e) => this.searchHandler(e)}
                                />
                                <ProfileMenuVaultInvatationTabusercardContainer 
                                    data={this.props.data.users.pending}
                                    type={'pending'}
                                />
                            </TabWrapper> 
                        </TabPage>
                        <TabPage
                            title='Joined'
                        >
                             <TabWrapper>
                                <InputWrapper
                                    placeholder={'Search'}
                                    onChange={(e) => this.searchHandler(e)}
                                />
                                <ProfileMenuVaultInvatationTabusercardContainer 
                                    data={this.props.data.users.accepted}
                                    type={'connected'}
                                />
                            </TabWrapper> 
                        </TabPage>
                </TabPages>
            </ProfileMenuVaultInvatationTabWrapper>
        );
    }
}


export default ProfileMenuVaultInvatationTab
