import styled, {css} from 'styled-components';
import {SwitchBoxStyles} from "../WizardPageGroupPermissions/Styled";

export const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 96px);
    padding-left: 5%;
    padding-right: 5%;
`;

export const ItemWrapper = styled.button`
    width: 100%;
    display: flex;
    border: none;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    padding-right: 10px;
    padding-left: 10px;
    background: white;
    height: 80px;
    font-family: 'Montserrat';
`;

export const TitleWrapper = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    cursor: pointer;
    height: 100%;

    span {
        font-size: 18px;
        color: ${(props) => props.theme.textBlack};
        margin: 0;
        text-align: left;
    }

    p {
        font-size: 14px;
        font-weight: lighter;
        color: ${(props) => props.theme.coolGray};
        text-align: left;
        margin: 0;
        margin-top: 5px;
    }
`;

export const OptionsWrapper = styled.div`
    display: flex;
    width: 40%;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    height: 100%;

    p {
        font-size: 14px;
        font-weight: lighter;
        color: ${(props) => props.theme.cyan};
        margin: 0;
        text-align: left;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.grayBorderLight};
    padding-bottom: 30px;
    padding-top: 30px;
    :last-of-type {
        border-bottom: 0;
    }

    > p {
        font-size: 22px;
        color: ${props => props.theme.textBlack};
        margin: 0;
    }
`;

export const Option = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    margin-top: 30px;

    > p {
        font-size: 18px;
        font-weight: lighter;
        color: ${props => props.theme.coolGray};
        margin: 0;
    }
`;

export const SwitchBoxMenuStyles = css`
    ${SwitchBoxStyles};    
    width: 60px; height: 27px;
    i em {
        width: 54px; height: 24px;
        top: 1px; left: 2px;
    }
    i em:before {
        width: 18px; height: 18px;
        left: 5px; bottom: 3px;
    }
    input:checked + i em:before {
        transform: translateX(28px);
    }
    b {
        right: 16px;
        top: 5px;
        width: 10px;
        height: 10px;
    }
    b:before, b:after {
        left: 7px;
        height: 15px;
        width: 3px;
    }
    u {
        display: none;
        position: absolute;
        top: 5px; left: 14px;
        transform: rotate(45deg);
        width: 4px; height: 10px;
        border-radius: 2px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
    }
`;

export const ApplyButtonSubmit = css`
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 50px;
    font-size: 18px;
    color: white;
    background: ${props => props.theme.cyan};
    background: linear-gradient(
        to right,
        ${props => props.theme.cyan} 1%,
        ${props => props.theme.cyanDark}
    );
`;
