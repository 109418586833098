import React, { Component } from 'react';
import { Container, ItemWrapper, TransparentBackground, TimeWrapper } from './Styled';

class StatisticsChart extends Component {

    render() {
        return (
            <Container>
                {this.props.data.values.map((item, index) => (
                    <ItemWrapper>
                        <TransparentBackground
                            max={this.props.data.max}
                            amount={item.amount}
                        >
                            +{item.amount}
                        </TransparentBackground>
                        <TimeWrapper>{item.time}</TimeWrapper>
                    </ItemWrapper>
                ))}
            </Container>
        );
    }
}

export default StatisticsChart;
