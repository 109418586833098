import React, {Component, Fragment} from "react";
import {Redirect, withRouter} from 'react-router-dom';
import ProfileMenu from "../ProfileMenu";
import WizardPages from "../WizardPages";
import Config from "../../../config";
import {sendAsyncRequest} from "../../../Utils";
import SkeletonLoading, {SkeletonLoadingType} from "../SkeletonLoading";
import WizardTemplateDynamic from "../WizardTemplateDynamic";

class SettingsDynamicWizard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wizardType: "",
            results: []
        };
        this.mainRoute = props.mainRoute;
    }

    componentDidMount() {
        sendAsyncRequest(this.props.endpoint, 'get', {}).then(json => {
            if (json.body.Result) {
                const results = json.body.Result.step,
                    category = this.props.match.params.category,
                    page = this.props.match.params.page,
                    detail = this.props.match.params.detail;
                // Sort the Results & Add URLs
                // (url = step_name.LowerCase.ReplaceAllSpaceWithUnderscore)
                if (results.length > 1) {
                    results.sort((curr, next) => {
                        curr.url = curr.step_name.toLowerCase().replace(/\s/g, '_');
                        next.url = next.step_name.toLowerCase().replace(/\s/g, '_');
                        return parseInt(curr.step_order) - parseInt(next.step_order);
                    });
                } else if (results.length === 1) {
                    results[0].url = results[0].step_name.toLowerCase().replace(/\s/g, '_');
                    results.push({
                        url: null
                    });
                }
                if (!page) {
                    this.props.history.push(
                        this.mainRoute + '/' + results[0].url
                    );
                } else if (!detail) {
                    const result = results.find(
                        result => result.url === page
                    );
                    if (!result) {
                        this.props.history.push(
                            Config.ROUTES.pageNotFound
                        );
                    }
                    else if (result.children && result.children.length > 0) {
                        this.props.history.push(
                            this.mainRoute + '/' + page + '/' + result.children[0].url
                        );
                    }
                }

                this.setState({
                    results,
                    wizardType: Config.ROUTES.templates[category].navType
                });
            }
        });
    }

    render() {

        const category = this.props.match.params.category,
              page = this.props.match.params.page,
              detail = this.props.match.params.detail,
              wizardType = this.state.wizardType,
              results = [...this.state.results],
              menu = [];

        let template = results.find(
            result => result.url === page
        );

        if (!detail && template && template.children && template.children.length > 0) {
            return <Redirect to={
                this.mainRoute + '/' + page + '/' + template.children[0].url
            } />;
        }

        let childIndex = -1;
        if (template && template.children) {
            template = template.children.find(
                (child, index) => {
                    childIndex = index;
                    return child.url === detail;
                }
            );
        }

        const templateIndex = results.findIndex(
            result => result.url === page
        );

        // If we pass a node with child,
        // the children would be 100% complete
        const completedChildren = new Array(results.length).fill(false);
        results.forEach(
            (result, index) => {
                if (templateIndex > index && result.children) {
                    completedChildren[index] = true;
                }
            }
        );

        results.forEach(
            (result, index) => {
                let subMenu = [];
                if (result.children) {
                    const childIndex = result.children.findIndex(
                        child => child.url === detail
                    );
                    result.children.forEach(
                        (child, innerIndex) => {
                            subMenu.push({
                                title: child.step_name,
                                url: this.mainRoute + '/' + result.url+ '/' + child.url,
                                active: (detail === child.url),
                                checked: (completedChildren[index]) ? 100 : (childIndex > innerIndex)
                            });
                        }
                    )
                } else {
                    subMenu = null;
                }
                if (result.url) {
                    menu.push({
                        title: result.step_name,
                        url: this.mainRoute + '/' + result.url,
                        active: (page === result.url),
                        checked: (templateIndex > index),
                        childPercentComplete: (childIndex !== -1)
                            ? Math.round(childIndex / results[templateIndex].children.length * 100)
                            : 0,
                        subMenu
                    });
                }
            }
        );

        let nextUrl, prevUrl, nextTitle;
        if (templateIndex !== -1) {
            if (childIndex !== -1) {
                const childResults = results[templateIndex].children;
                if (childIndex === 0 && templateIndex === 0) { // 1st Child, 1st Parent
                    prevUrl = null;
                    nextUrl = childResults[childIndex + 1].url;
                    nextTitle = childResults[childIndex + 1].step_name;
                } else if (childIndex === 0 && templateIndex !== 0) { // 1st Child, (n)th Parent
                    prevUrl = this.mainRoute + '/' + results[templateIndex - 1].url;
                    nextUrl = childResults[childIndex + 1].url;
                    nextTitle = childResults[childIndex + 1].step_name;
                } else if (childIndex + 1 < childResults.length) { // Middle Child
                    prevUrl = childResults[childIndex - 1].url;
                    nextUrl = childResults[childIndex + 1].url;
                    nextTitle = childResults[childIndex + 1].step_name;
                } else if (templateIndex + 1 < results.length) { // Last Child, (n)th Parent
                    prevUrl = childResults[childIndex - 1].url;
                    nextUrl = this.mainRoute + '/' + results[templateIndex + 1].url;
                    nextTitle = results[templateIndex + 1].step_name;
                } else { // Last Child, Last Parent
                    prevUrl = childResults[childIndex - 1].url;
                    nextUrl = Config.ROUTES.homePage;
                }
            } else {
                if (templateIndex === 0) { // First Item
                    prevUrl = null;
                    nextUrl = results[templateIndex + 1].url;
                    nextTitle = results[templateIndex + 1].step_name;
                } else if (templateIndex + 1 < results.length) { // Middle Items
                    prevUrl = results[templateIndex - 1].url;
                    nextUrl = results[templateIndex + 1].url;
                    nextTitle = results[templateIndex + 1].step_name;
                } else { // Last Item
                    prevUrl = results[templateIndex - 1].url;
                    nextUrl = Config.ROUTES.homePage;
                }
            }
        }

        return (
            <Fragment>

                <ProfileMenu closed unexpandable/>

                <WizardPages
                    title={this.props.title}
                    //percentComplete={Math.round((templateIndex) / results.length * 100)}
                    menu={menu}
                >
                    {
                        (menu.length === 0) &&
                        <SkeletonLoading
                            type={SkeletonLoadingType.WIZARD_TEMPLATE}
                        />
                    }
                    {
                        (menu.length !== 0) &&
                        <WizardTemplateDynamic
                            category={category}
                            page={page}
                            template={template}
                            nextUrl={nextUrl}
                            prevUrl={prevUrl}
                            wizardType={wizardType}
                            nextTitle={nextTitle}
                        />
                    }
                </WizardPages>

            </Fragment>
        );
    }
}    

export default withRouter(SettingsDynamicWizard);
