import React, { Component, Fragment } from 'react';
import { ClearFloats } from '../BaseTemplate/Styled';
import {
    PropertiesWrapperLeft,
    PropertiesWrapperRight,
} from '../FeedPage/Styled';
import { Column } from '../../../styles/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import IconNew from '../IconNew/IconNew';
import Stats from '../ProfileGroupTypeStats';
import { getInputNameAndValue } from '../../../Utils';
import SearchTabBar from '../SearchTabBar';
import GoogleMapView from '../GoogleMapView';
import {
    Title,
    TextInputStyles,
    ApplyButtonSubmit,
    CloseButton,
} from '../SearchNewListing/Styled';
import {
    InputWrapperStyles,
    SelectingWrapper,
    SelectableButton,
    MapCard,
} from '../SearchNewPeople/Styled';
import InputText from '../FormInputText';
import FormButtonText from '../FormButtonText/FormButtonText';
import SearchNewPeopleItem from '../SearchNewPeopleItem/SearchNewPeopleItem';

class SearchNewGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            result: [],
            mapResult: [],
            isLoading: false,
            resultError: false,
            filters: {
                nameSearch: '',
                categories: [],
            },
        };
        this.categories = [
            'Brokerage',
            'MLS',
            'Association',
            'Franchise',
            'Authority',
            'Private Group',
        ];
        this.fakeResult = [
            {
                type: 'group',
                group_type: 'Authority',
                name: 'FIABCI International Real Estate Federation',
                verified: true,
                location: '75015 Paris, France',
                members: 1200,
                followers: 482,
                active_listings: 136,
                group_activity: 76,
                last_activity: 1,
            },
            {
                type: 'group',
                group_type: 'MLS Provider',
                name: 'International Realtors',
                verified: true,
                location: '75015 Paris, France',
                members: 13200,
                followers: 9482,
                active_listings: 5438,
                group_activity: 856,
                last_activity: 1,
            },
        ];
    }

    componentDidMount() {
        this.loadSearch(this.fakeResult);
    }

    handleInputChange = (event) => {
        const inputData = getInputNameAndValue(event),
            filters = { ...this.state.filters };
        filters[inputData.name] = inputData.value;
        this.setState({
            filters,
        });
    };

    handleCategoryClick = (e, category) => {
        let newState = [...this.state.filters.categories];
        if (!this.state.filters.categories.includes(category)) {
            newState.push(category);
        } else {
            let index = newState.findIndex((x) => x === category);
            newState.splice(index, 1);
        }
        this.setState((prevState) => {
            return { filters: { ...prevState.filters, categories: newState } };
        });
    };

    loadSearch = (data) => {
        let result = [...this.state.result];
        let mapResult = [...this.state.mapResult];
        data.forEach((item, index) => {
            result.push(
                <SearchNewPeopleItem
                    type={'group'}
                    item={item}
                    key={`iitem${index}`}
                />
            );
            mapResult.push(
                <MapCard>
                    <CloseButton />
                    <img alt="agent" />
                    <span>
                        {item.type === 'people' ? item.role : item.group_type}
                    </span>
                    <h3>
                        {item.name}
                        {item.verified && (
                            <IconNew
                                bp1="-411"
                                bp2="-186"
                                iwidth="20"
                                iheight="20"
                            />
                        )}
                    </h3>

                    <p>
                        <IconNew bp1="-62" bp2="-77" iwidth="12" iheight="17" />{' '}
                        {item.location}
                    </p>
                    <Stats
                        items={
                            item.type === 'people'
                                ? [
                                      {
                                          name: 'Groups',
                                          count: item.groups,
                                      },
                                      {
                                          name: 'Followers',
                                          count: item.followers,
                                      },
                                      {
                                          name: 'Connections',
                                          count: item.connections,
                                      },
                                  ]
                                : [
                                      {
                                          name: 'Active Listings',
                                          count: item.active_listings,
                                      },
                                      {
                                          name: 'Followers',
                                          count: item.followers,
                                      },
                                      {
                                          name: 'Members',
                                          count: item.members,
                                      },
                                  ]
                        }
                        small
                        noLine
                    />
                    <div className="footer">
                        <div>
                            <IconNew
                                bp1="-470"
                                bp2="-117"
                                iwidth="13"
                                iheight="14"
                            />
                            <h6>
                                {item.type === 'people' ? 'Social' : 'Group'}{' '}
                                Activity
                            </h6>
                            <p>
                                {item.type === 'people'
                                    ? item.social_activity
                                    : item.group_activity}
                                %
                            </p>
                            <em />
                        </div>
                        <div>
                            <IconNew
                                bp1="-470"
                                bp2="-131"
                                iwidth="14"
                                iheight="14"
                            />
                            <h6>Last Activity</h6>
                            <p>{item.last_activity} day</p>
                            <em />
                        </div>
                    </div>
                </MapCard>
            );
        });
        this.setState({ result, mapResult });
    };

    render() {
        return (
            <Fragment>
                <Column type="one_column_setting" padding_right first>
                    <SearchTabBar
                        defaultIndex={3}
                        showMap={this.props.showMap}
                        toggleShow={this.props.toggleShow}
                    />
                    {!this.props.showMap ? (
                        <>
                            <PropertiesWrapperLeft>
                                {this.state.result.map(
                                    (item, index) => index % 3 === 0 && item
                                )}
                            </PropertiesWrapperLeft>
                            <PropertiesWrapperLeft>
                                {this.state.result.map(
                                    (item, index) => index % 3 === 1 && item
                                )}
                            </PropertiesWrapperLeft>
                            <PropertiesWrapperRight>
                                {this.state.result.map(
                                    (item, index) => index % 3 === 2 && item
                                )}
                            </PropertiesWrapperRight>
                        </>
                    ) : (
                        <GoogleMapView
                            container={{
                                width: '100%',
                                height: '700px',
                                marginTop: '40px',
                                borderRadius: '10px',
                            }}
                            points={[
                                {
                                    position: {
                                        lat: 40.714725,
                                        lng: -73.9867893,
                                    },
                                    id: 1,
                                },
                                {
                                    position: {
                                        lat: 40.714745,
                                        lng: -73.9867883,
                                    },
                                    id: 2,
                                },
                            ]}
                            color={'green'}
                            cards={this.state.mapResult}
                        />
                    )}
                </Column>

                <ProfileMenu closed unexpandable />

                <Column
                    type="one_column_right_fixed_simple"
                    first
                    background={'culturedGray'}
                >
                    <Title>Filters</Title>
                    <InputText
                        id={'nameSearch'}
                        type="text"
                        name={'nameSearch'}
                        onInput={this.handleInputChange}
                        styled={TextInputStyles}
                        wrapperStyled={InputWrapperStyles}
                        value={this.state.filters.nameSearch}
                        placeholder={'Enter Name'}
                    />
                    <SelectingWrapper>
                        {this.categories.map((category, index) => (
                            <SelectableButton
                                key={'category' + index}
                                selected={this.state.filters.categories.includes(
                                    category
                                )}
                                onClick={(e) =>
                                    this.handleCategoryClick(e, category)
                                }
                            >
                                {category}
                            </SelectableButton>
                        ))}
                    </SelectingWrapper>
                    <FormButtonText styled={ApplyButtonSubmit}>
                        Apply
                    </FormButtonText>
                </Column>

                <ClearFloats />
            </Fragment>
        );
    }
}

export default SearchNewGroup;
