import React from 'react';
import {
    CardWrapper,
    CardTopWrapper,
    CardtopItem,
    RightBorder,
    TopTextWrapper,
    CarouselMiddleContainer,
    CarouselTopSideHeaderText,
    CarouselBottomAddressText,
    CarouselMiddleBottomSide,
    CarouselMiddlePriceText,
    CarouselMiddleRightSideText,
    CarouselMiddleTopSide,
    CarouselMiddleSide,
    RightSideText,
    RightSideTextBorder,
    RighttSideTextNumber,
    CardMiddlebottomLeftside,
    IconWrapper,
    CardBottomContainer,
    CardBottomLeftSide,
    CardBottomRightSide,
    BoostButtonWrapper,
    RocketIconWrapper,
    TopText,
    VisibleAreaContainer,
    PrivilegedSection,
    PrivilegedSectionLeftSide,
    FeaturedSection,
    FeaturedSectionLeftSide,
    SponsoredLeftSide,
    SponsoredSection,
    BoostsectionsHeader,
    PlusMinusSpan,
    PrivilegedRightSide,
    PrivilegedRightSideAmount,
    StatisticsContainer,
    HeaderWrapper,
    HeaderText,
    LeftSide,
    RightSide,
    SocialStatisticsTopWrapper,
    SocialStatisticsTopItem,
    SocialStatisticsTopItemText,
    StatisticsPadding,
    ChartsContainer,
    SocialStatStyles,
    ChartsTopSide,
    ChartsMiddleSide,
    CircleChartContainer,
    CircleChartWrapper,
    ChartCircle,
    CircleChartTextWrapper,
    CircleChartRightText,
    CircleChartRightSide,
    CircleChartLeftText,
    BottomChartContainer,
    BottomSelectorWrapper,
    BottomChartDataWrapper,
    BottomLeftText,
    BottomChartWrapper,
    PercetangeText,
    ChartDisplay,
    ChartItemWrapper,
    ArrowSpanWrapper,

} from './Styled';
import { ArrowBack } from '../../../styles/Styled';
import CarouselComponent from '../CarouselComponent';
import StatisticsListingChart from '../StatisticsListingChart';
import SelectorPopUp from '../SelectorPopUp';
import IconNew from '../IconNew';
import { numberFormatter } from '../../../Utils';
import { withRouter } from 'react-router-dom';

class  MyListingsStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagInputValue: '',
            tags: [],
            showstatType: { text: 'Profile Views', value: 1 },
            showstatTime:{ text: 'Daily', value: 1 },
            isBoostVisible: false,
            currentIndex: 5,

        };
        this.leads = {
            newLeads: 50,
            inProgress: 30,
            closed: 20,
        };
        this.typeofSocial2 = {
            Followers:64,
            Connections:31,
            Others:5
        };
        this.statisticsData = {
            max: 237,
            values: [
                {
                    time: '15-21 Jun',
                    amount: 53,
                },
                {
                    time: '22-28 Jun',
                    amount: 89,
                },
                {
                    time: '29Jun-05Jul',
                    amount: 178,
                },
                {
                    time: '06-12 Jul',
                    amount: 103,
                },
                {
                    time: '13-19 Jul',
                    amount: 237,
                },
           
            ],
        };
        this.showStatTypes = [
            { text: 'Likes', value: 1 },
            { text: 'Views', value: 2 },
            { text: 'Shares', value: 3 },
            { text: 'New Followers', value: 4 },
            { text: 'Comments', value: 5 },
        ];
        this.showStatTime = [
            { text: 'Daily', value: 1 },
            { text: 'Weekly', value: 2 },
            { text: 'Monthly', value: 3 },
            { text: 'Yearly', value: 4 },
        ];

        this.bottomChartData = [
            {name:'San Francisco', percetange:62.8},
            {name:'Oakland', percetange:16.3},
            {name:'San Jose', percetange:12.3},
            {name:'Sample', percetange:7.3},
            {name:'Others', percetange:1.1},
        ];

        this.itemId = this.props.location.state;

        
    }

    componentDidMount() {
        if(this.props.id !== null) {
            this.setState({
                currentIndex:this.props.id * 1
    
            })
        }
    }


    render() {
        let allLeads =
            this.leads.closed + this.leads.inProgress + this.leads.newLeads;
    return (
        <React.Fragment>
            {this.props.data.map((item,index)=> {
                if(index === this.state.currentIndex) {
                    return (
                        <StatisticsContainer key={index}>
                            <StatisticsPadding>
                        <LeftSide>
                            <HeaderWrapper>
                                <ArrowSpanWrapper>
                                    <ArrowBack onClick={() => this.props.backButtonHandler()}  />
                                </ArrowSpanWrapper>                               
                                <HeaderText>
                                    Statistics
                                </HeaderText>
                            </HeaderWrapper>
                            <SocialStatisticsTopWrapper>
                                <SocialStatisticsTopItem>
                                    <SocialStatisticsTopItemText>
                                        {numberFormatter(item.likes)}
                                    </SocialStatisticsTopItemText>
                                    <IconNew
                                        bp1="-470"
                                        bp2="-103"
                                        iwidth="13"
                                        iheight="12"
                                    />
                                </SocialStatisticsTopItem>
                                <SocialStatisticsTopItem>
                                <SocialStatisticsTopItemText>
                                    {numberFormatter(item.comments)}
                                </SocialStatisticsTopItemText>
                                    <IconNew
                                        bp1="-447"
                                        bp2="-160"
                                        iwidth="17"
                                        iheight="16"
                                    />
                                </SocialStatisticsTopItem>
                                <SocialStatisticsTopItem>
                                <SocialStatisticsTopItemText>
                                    {numberFormatter(item.shares)}
                                </SocialStatisticsTopItemText>
                                    <IconNew
                                        bp1="-470"
                                        bp2="-116"
                                        iwidth="13"
                                        iheight="14"
                                    />
                                </SocialStatisticsTopItem>
                                <SocialStatisticsTopItem>
                                <SocialStatisticsTopItemText>
                                    {numberFormatter(item.views)}
                                </SocialStatisticsTopItemText>
                                    <IconNew
                                        bp1="-470"
                                        bp2="-116"
                                        iwidth="13"
                                        iheight="14"
                                    />
                                </SocialStatisticsTopItem>
                            </SocialStatisticsTopWrapper>
                            <ChartsContainer>
                                <ChartsTopSide>
                                    <SelectorPopUp 
                                        value={this.state.showstatType}
                                        items={this.showStatTypes}
                                        onChange={(type) => this.setState({ showstatType: type  })}
                                        color={'textBlack'}
                                        styled={SocialStatStyles}
                                    />
                                </ChartsTopSide>
                                <ChartsMiddleSide>
                                    <SelectorPopUp 
                                        value={this.state.showstatTime}
                                        items={this.showStatTime}
                                        onChange={(type) => this.setState({ showstatTime: type  })}
                                        color={'mainFontColor'}
                                        styled={SocialStatStyles}
                                    />
                                </ChartsMiddleSide>
                                <StatisticsListingChart data={this.statisticsData} />
                                <CircleChartContainer>
                                    <CircleChartWrapper>
                                    <ChartCircle
                                        newLeads={(this.leads.newLeads / allLeads) * 100}
                                        inProgress={(this.leads.inProgress / allLeads) * 100}
                                    >
                                        <div>{'  '}</div> 
                                    </ChartCircle>
                                    <CircleChartRightSide>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>
                                                {this.leads.newLeads}%
                                            </CircleChartLeftText>
                                            <CircleChartRightText>

                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>
                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>

                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                    </CircleChartRightSide>
                                    </CircleChartWrapper>
                                    <CircleChartWrapper>
                                        <ChartCircle
                                            newLeads={(this.leads.newLeads / allLeads) * 100}
                                            inProgress={(this.leads.inProgress / allLeads) * 100}
                                        >
                                            <div>{'  '}</div>
                                        </ChartCircle>
                                        <CircleChartRightSide>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>
                                                {this.typeofSocial2.Followers}%
                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                Followers
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>
                                            {this.typeofSocial2.Connections}%
                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                Connections
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>
                                            {this.typeofSocial2.Others}%
                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                Others
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                    </CircleChartRightSide>
                                    </CircleChartWrapper>
                                    <CircleChartWrapper>
                                        <ChartCircle
                                            newLeads={(this.leads.newLeads / allLeads) * 100}
                                            inProgress={(this.leads.inProgress / allLeads) * 100}
                                        >
                                            <div>{'  '}</div>
                                        </ChartCircle>
                                        <CircleChartRightSide>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>
                                                {this.leads.allLeads}
                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                hello
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                        <CircleChartTextWrapper>
                                            <CircleChartLeftText>

                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                
                                            </CircleChartRightText>
                                            </CircleChartTextWrapper>
                                            <CircleChartTextWrapper>
                                            <CircleChartLeftText>

                                            </CircleChartLeftText>
                                            <CircleChartRightText>
                                                
                                            </CircleChartRightText>
                                        </CircleChartTextWrapper>
                                    </CircleChartRightSide>  
                                    </CircleChartWrapper>
                                </CircleChartContainer>
                                <BottomChartContainer>
                                <BottomSelectorWrapper>
                                <SelectorPopUp 
                                    value={this.state.showstatTime}
                                    items={this.showStatTime}
                                    onChange={(type) => this.setState({ showstatTime: type  })}
                                    color={'mainFontColor'}
                                    styled={SocialStatStyles}
                                    />
                                    <BottomChartDataWrapper>
                                    {this.bottomChartData.map((item, index) => {
                                        return (
                                            <ChartItemWrapper key={index}>
                                                <BottomLeftText>
                                                    {item.name}
                                                </BottomLeftText>
                                                <BottomChartWrapper>
                                                    <PercetangeText>
                                                        {item.percetange}%
                                                    </PercetangeText>
                                                    <ChartDisplay width={item.percetange} />
                                                </BottomChartWrapper>
                                            </ChartItemWrapper>
                                        )
                                    })}
                                    </BottomChartDataWrapper>
                                </BottomSelectorWrapper>
                                </BottomChartContainer>
                            </ChartsContainer>
                        
                        </LeftSide>
                        <RightSide>
                        <CardWrapper moreMarginTop>
                            <CardTopWrapper>
                                <CardtopItem>
                                    <TopTextWrapper>
                                        <IconNew
                                        bp1="-470"
                                        bp2="-146"
                                        iwidth="12"
                                        iheight="12"
                                    />
                                    <TopText>
                                        {numberFormatter(item.likes)}
                                    </TopText>
                                    </TopTextWrapper>
                            <RightBorder />
                            </CardtopItem>
                            <CardtopItem>
                                <TopTextWrapper>
                                    <IconNew
                                        bp1="-447"
                                        bp2="-146"
                                        iwidth="20"
                                        iheight="12"
                                    />
                                    <TopText>
                                        {numberFormatter(item.views)}
                                    </TopText>
                                </TopTextWrapper>
                                <RightBorder />
                            </CardtopItem>
                            <CardtopItem>
                                <TopTextWrapper>
                                    <IconNew
                                        bp1="-447"
                                        bp2="-177"
                                        iwidth="16"
                                        iheight="15"
                                    />
                                    <TopText>
                                        {numberFormatter(item.comments)}
                                    </TopText>
                                </TopTextWrapper>
                                <RightBorder />
                            </CardtopItem>
                            <CardtopItem>
                                <TopTextWrapper>
                                <IconNew
                                    bp1="-470"
                                    bp2="-159"
                                    iwidth="12"
                                    iheight="14"
                                /> 
                                <TopText>
                                    {numberFormatter(item.shares)}
                                </TopText>
                                </TopTextWrapper>
                            </CardtopItem>
                        </CardTopWrapper>
                    <CarouselComponent 
                        pictureHeight='275'
                        pictureWidth='400'
                        onPictureClickHandler={() => null}
                        inlineCircles 
                        data={item.photos} 
                    />
                    <CarouselMiddleContainer withBorders> 
                        <CarouselMiddleTopSide>
                            <CarouselTopSideHeaderText>
                                Best Property in Thes Area
                            </CarouselTopSideHeaderText>
                            <IconWrapper>
                            <IconNew
                                bp1="-396"
                                bp2="-220"
                                iwidth="13"
                                iheight="14"
                            /> 
                            </IconWrapper>
                        </CarouselMiddleTopSide>
                        <CarouselMiddleSide>
                            <CarouselMiddlePriceText>
                                $3,400 / m
                            </CarouselMiddlePriceText>
                            <CarouselMiddleRightSideText>
                                <RightSideText>
                                    <RighttSideTextNumber>4 </RighttSideTextNumber>{' '} BED
                                    <RightSideTextBorder />
                                </RightSideText>
                                <RightSideText>
                                    <RighttSideTextNumber>2 </RighttSideTextNumber>{' '}BATH
                                    <RightSideTextBorder />
                                </RightSideText>
                                <RightSideText lastItem>
                                <RighttSideTextNumber>2,750 </RighttSideTextNumber>{' '} sqft
                                </RightSideText>
                            </CarouselMiddleRightSideText>
                        </CarouselMiddleSide>
                        <CarouselMiddleBottomSide>
                            <CardMiddlebottomLeftside>
                                <IconWrapper>
                                <IconNew
                                    bp1="-172"
                                    bp2="-115"
                                    iwidth="9"
                                    iheight="13"
                                    className="heart"
                                />  
                                </IconWrapper>
                                <CarouselBottomAddressText>
                                    548 Market St, San Francisco
                                </CarouselBottomAddressText>
                            </CardMiddlebottomLeftside>
                        </CarouselMiddleBottomSide>
                    </CarouselMiddleContainer>
                    <CardBottomContainer>
                        <CardBottomLeftSide>         
                            <IconNew
                                bp1="-201"
                                bp2="-77"
                                iwidth="26"
                                iheight="26"
                            />    
                                <IconNew
                                bp1="-229"
                                bp2="-77"
                                iwidth="26"
                                iheight="26"
                            />  
                            <IconNew
                                bp1="-257"
                                bp2="-77"
                                iwidth="26"
                                iheight="26"
                            />
                        </CardBottomLeftSide>
                        <CardBottomRightSide>
                            <BoostButtonWrapper onClick={() => this.boostButtonHandler(item.id, index)}>
                                Boost <RocketIconWrapper>h</RocketIconWrapper>
                            </BoostButtonWrapper>
                        </CardBottomRightSide>
                    </CardBottomContainer>
                    {(  item.isBoostVisible) && (
                        <VisibleAreaContainer>
                            <PrivilegedSection>
                                <PrivilegedSectionLeftSide>
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                    <BoostsectionsHeader>
                                        Privileged&#174;
                                    </BoostsectionsHeader>   
                                    <IconNew
                                        bp1="-201"
                                        bp2="-77"
                                        iwidth="26"
                                        iheight="26"
                                    />
                                </PrivilegedSectionLeftSide>
                                <PrivilegedRightSide>
                                    <PrivilegedRightSideAmount>
                                        1 / 2
                                    </PrivilegedRightSideAmount>
                                    <PlusMinusSpan>
                                        -
                                    </PlusMinusSpan>
                                    </PrivilegedRightSide>     
                            </PrivilegedSection>
                            <FeaturedSection>
                                <FeaturedSectionLeftSide>
                                    <IconNew
                                            bp1="-201"
                                            bp2="-77"
                                            iwidth="26"
                                            iheight="26"
                                        />
                                        <BoostsectionsHeader>
                                            Featured&#174;
                                        </BoostsectionsHeader>   
                                        <IconNew
                                            bp1="-201"
                                            bp2="-77"
                                            iwidth="26"
                                            iheight="26"
                                        />
                                </FeaturedSectionLeftSide>
                                <PlusMinusSpan>
                                    +
                                </PlusMinusSpan>
                            </FeaturedSection>
                            <SponsoredSection>
                                <SponsoredLeftSide>
                                <IconNew
                                    bp1="-201"
                                    bp2="-77"
                                    iwidth="26"
                                    iheight="26"
                                />
                                <BoostsectionsHeader>
                                    Sponsored / Additional Zipcodes&#174;
                                </BoostsectionsHeader>   
                                <IconNew
                                    bp1="-201"
                                    bp2="-77"
                                    iwidth="26"
                                    iheight="26"
                                />
                                </SponsoredLeftSide>
                                <span>
                                    +
                                </span>
                            </SponsoredSection>
                        </VisibleAreaContainer>     
                    )}                    
                        </CardWrapper>
                        </RightSide>
                        </StatisticsPadding>
                    </StatisticsContainer>
                    );
                } else  {
                    return null;
                }
            })} 
        </React.Fragment>
    );
        }
}

export default withRouter(MyListingsStatistics);
