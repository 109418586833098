import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowRight } from '../BaseTemplate/Styled';

export const ManagmentFormWrap = styled.div`
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px !important;
`;

export const FirstColumnWrap = styled.div`
    flex: 1;
    justify-content: start;

    span {
        color: #313136;
        font-size: 28px;
        padding-left: 5.5%;
        font-family: 'Montserrat';
    }    
    
`;

export const SecondColumnWrap = styled.div`
    flex: 1;
    justify-content: end;
    align-items: center;
`;

export const InputStyle = styled.input`
    float: right;
    vertical-align: middle;
    border: none;
    font-family: 'Montserrat';
    font-weight:lighter;
    padding-top: 1.5%;
    width: 332px;

    &::placeholder {
        font-size: 20px;
        color:${props => props.theme.mainFontColor};
    }
`;

export const InputWrap = styled.div`
    padding-right: 4%;

    font-weight: bold;
    padding-top: 1.5%;

    div {
    border-bottom: 2px solid ${props => props.theme.bodyBg};
    padding-bottom: 2.07%;
    display: flex;
    float: right;
    }

    input {
    float: right;
    vertical-align: middle;
    border: none;
    font-family: 'Montserrat';
    font-weight:lighter;
    padding-top: 1.5%;
    width: 332px;

    &::placeholder {
        font-size: 20px;
        color:${props => props.theme.mainFontColor};
    }
    }

    input[type='text'] {
        font-size: 20px;
        color: ${props => props.theme.mainFontColor};
    }
`;


export const TextWrapper = styled.span`
    color: #313136;
    font-size: 28px;
    padding-left: 5.5%;
    font-family: 'Montserrat';
`;

export const CardWrapper = styled.div`
    background: white;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const LeadManagmentWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin-top: 1.587%;
`;

export const FirstLeftSide = styled.div`
    flex: 1;
`;

export const FirstRightSide = styled.div`
    flex: 1;
`;

export const ImgStyle = styled.img`
    width: 49px;
    height: 49px;
    border-radius: 10px;
    margin-top: 11px;
`;

export const ButtonsWrap = styled.div``;

export const CardLowerSideWrap = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CardLowerItem = styled.div`
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 33px;
`;

export const TopText = styled.span`
    font-size: 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    background: #ededf7;
    font-family: 'Montserrat';
`;

export const Lowertext = styled.span`
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    margin-top: 3px;
    font-family: 'Montserrat';
`;

export const LeftSideContent = styled.div`
    display: flex;

    img {
        width: 49px;
    height: 49px;
    border-radius: 10px;
    margin-top: 11px;
    }
`;

export const RightSideContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
`;

export const TagsStyle = styled.span`
    color: #7106c4;
    font-size: 12px;
    background: #ededf7;
    margin-left: 5px;
    padding: 3px 4px;
    font-family: 'Montserrat';
`;

export const LeftSideContentWrap = styled.div`
    margin-left: 10px;
`;

export const ContactButtonStyle = styled(Link)`
    font-size: 13px;
    background-image: ${(props) =>
        `linear-gradient(to right, ${props.theme.violet} 3%, ${
            props.theme.indigo
        } 98%)`};
    color: white;
    border: none;
    padding-top: 4.2%;
    padding-bottom: 4.2%;
    padding-right: 4.9%;
    padding-left: 4.9%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-sizing: border-box;
`;

export const ProfileNameStyle = styled.span`
    color: #19257c;
    font-size: 16px;
    font-family: 'Montserrat';
`;

export const SecondButton = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: transparent;
    border: solid 1px #7106c4;
    border-radius: 5px;
    margin-left: 9px;
`;

export const VioletArrowRight = styled(ArrowRight)`
    top: 2px;
    margin: 0;
`;

export const ContactText = styled.span`
font-weight:lighter;
font-family: 'Montserrat';
font-size:14px;
`;


export const LeadManagmentFiltersWrapper = styled.div`

`;

export const HeaderWrapper = styled.div`
height:70px;
display:flex;
align-items:center;
border-bottom: 1px solid #7106C4;

span {
    color:#19257C;
    font-size:24px;
    margin-left:30px;
}
`;

export const LeadNameInputWrapper = styled.div`
height:94px;
display:flex;
align-items:center;
justify-content:center;
border-bottom:1px solid #B37DDC;
padding-right:30px;
padding-left:30px;

div {
    border-bottom: 1px solid ${props => props.theme.textGrayLight};
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
}
`;

export const InputWrapper = styled.input`
border:none;
color: ${props => props.theme.mainFontColor};
font-size:18px;
background:transparent;
flex:1;
`;

export const RecordDateWrapper = styled.div`
height:194px;
padding-right:30px;
padding-left:30px;
display:flex;
flex-direction:column;
justify-content:center;
border-bottom:1px solid #B37DDC;


`;

export const RecordDateItemWrapper = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;

span {
color: ${props => props.theme.textBlack};
font-size:18px;
}
span.date {
color: ${props => props.theme.violet};
font-size:16px;
font-weight:lighter;
}
`;


export const RecordDateMiddleWrapper = styled.div`
display:flex;
margin-top:20px;
flex-direction:column;
align-items:flex-start;
border-bottom:1px solid ${props => props.theme.grayDarkBorder};
span {
    color: ${props => props.theme.textGrayLight};
    font-size:12px;
    line-height:15px;
}
div { 
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;

}
`;

export const DropUpPlaceHolder = styled.div`
display:flex;
flex-direction:row;
align-items:center;
color: ${props => props.theme.textBlack};
font-size:18px;
font-weight:lighter;

.location {
    padding-bottom:10px;
}

div.locationDiv {

}
`;

export const RecordBottomWrapper = styled.div`
display:flex;
align-items:center;
width:100%;
div {
    display:flex;
    align-items:center;
    flex:1;
}

span {
    font-size:14px;
    color:${props => props.theme.textGrayLight};
    margin-left:10px;
}
`;

export const Circle = styled.span`
background:transparent;
width:16px;
height:16px;
border-radius:100%;
border:1px solid ${props => props.theme.grayDarkBorder};
`;

export const TimeFrameRangeWrapper = styled.div`
height:155px;
padding-right:30px;
padding-left:30px;
display:flex;
flex-direction:column;
justify-content:center;
border-bottom:1px solid #B37DDC;
`;

export const DropDownWrapper = styled.div`
display:flex;
flex-direction:row;
align-items:center;
width:100%;
margin-top:${props => props.noMarginTop ? '0' :'20px'};
justify-content:space-between;

div.dropdownitem {
    width:65%;
    border-bottom: 1px solid ${props => props.theme.textGrayLight};
}

div.dropdownitem-small {
    width:30%;
    border-bottom: 1px solid ${props => props.theme.textGrayLight};
}

`;

export const DropDownMiddleItem = styled.div`
display:flex;
width:48%;
flex-direction:column;
justify-content:flex-start;

span {
    color: ${props => props.theme.textGrayLight};
    font-size:12px;
    line-height:15px;
}
div { 
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:100%;
    border-bottom: 1px solid ${props => props.theme.textGrayLight};
}
`;


export const LastInteraction = styled.div`
height:136px;
padding-right:30px;
padding-left:30px;
display:flex;
flex-direction:column;
justify-content:center;
border-bottom:1px solid #B37DDC;
`;

export const ListingWrapper = styled.div`
height:313px;
padding-right:30px;
padding-left:30px;
display:flex;
flex-direction:column;
justify-content:center;
border-bottom:1px solid #B37DDC;
`;


export const PriceRange = styled.div`
height:156px;
padding-right:30px;
padding-left:30px;
display:flex;
flex-direction:column;
justify-content:center;
border-bottom:1px solid  #B37DDC;
`;

export const LocationInput = styled.input`
    color:${props => props.theme.mainFontColor};
    font-size:18px;
    font-weight:lighter;
    border:none;
    background:transparent;
    min-width:50%;
    line-height:22px;
    flex:1;
`;

export const TagStyle = styled.span`
    background:${props => props.theme.grayBg};
    color:${props => props.theme.mainFontColor};
    border:none;
    margin-left:${props => props.firstItem ? '0' : '5px'};
    font-size:14px;
    height:23px;
    padding-left:2%;
    padding-right:2%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
`;


export const PriceRangeContent = styled.div`
display:flex;
flex-direction:column;
span {
    line-height:15px;
    font-size:12px;
    color: ${props => props.theme.textGrayLight};
}
`;

export const ApplySection = styled.div`
padding-left:30px;
height:288px;
display:flex;
flex-direction:column;
padding-right:30px;

div.pre {
    display:flex;
    margin-top:31px;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
}
div.button {
    display:flex;
    flex-direction:column;
}

`;

export const ToggleButton = styled.div`
width:40px;
position:relative;
height:19px;
background:transparent;
border:2px solid #7106C4;
border-radius:11px;
cursor:pointer;
`;

const ButtonToggle = keyframes`
0% {
    right:2.5px;
    bottom:2.5px;
}
25% {
    right:7.875px;
    bottom:2.5px;
},
50% {
    right:13.25px;
    bottom:2.5px;
},
75% {
    right:18.625px;
    bottom:2.5px;
}
100% {
    right:23.5px;
    bottom:2.5px;
}
`;

const BtnFromLeft = keyframes`
0% {
    right:23.5px;
    bottom:2.5px;
},
25% {
    right:18.625px;
    bottom:2.5px;
},
50% {
    right:13.25px;
    bottom:2.5px;
},
75% {
    right:7.875px;
    bottom:2.5px;
},
100% {
    right:2.5px;
    bottom:2.5px;
}
`;

export const BtnCircle = styled.span`
position:absolute;
width:14px;
height:14px;
background: #7106C4;
bottom:2.5px;
right:2.5px;
border-radius:100%;
animation:${props => props.isBtnOff && ButtonToggle};
animation:${props => props.isBtnOn && BtnFromLeft};
animation-duration: 0.2s;
animation-fill-mode:forwards;
`;

export const ApplyButton = styled.button`
width:100%;
background: transparent linear-gradient(90deg, #7106C4 0%, #390362 100%) 0% 0% no-repeat padding-box;
border-radius: 5px;
height:50px;
margin-top:80px;
font-size:18px;
cursor:pointer;
color:white;
border:none;
margin-bottom:60px;
`;

export const MenuFooter = styled.div`
font-size: 14px;
color: ${(props) => props.theme.textGrayLight};
float:left;
box-sizing: border-box;
height: 90px;
`;




