import styled, {css} from "styled-components";
import {InputLabelAnimateStyles, InputTextStyles, InputTextWrapperStyles} from "../LoginForm/Styled";
import {SelectButtonStyles} from "../FormDynamicField/Styled";
import {SwitchBoxStyles} from "../FormDynamicField/Styled";

export const Wrapper = styled.div``;

export const InputLabelAnimateGroupStyles = css`
    ${InputLabelAnimateStyles};
    font-size: 20px;
    color: ${props => props.theme.mainFontColor};
`;

export const InputTextWrapperDocStyles = css`
    ${InputTextWrapperStyles};
    width: 350px;
    margin: 40px 0 30px;
`;

export const InputTextWrapperUploadedStyles = css`
    ${InputTextWrapperStyles};
    width: 320px;
    float: left;
    margin: 15px 0 5px 15px;
`;

export const InputLabelAfterAnimateUploadedStyles = css`
    font-size: 16px;
    bottom: 60px;
`;

export const InputTextUploadedStyles = css`
    ${InputTextStyles};
    width: 320px; height: 40px;
    font-size: 20px;
    padding: 0;
`;

export const DropDownWrapper = styled.div`
    width: 220px;
    float: left;
    margin: 15px 0 20px 15px;
`;

export const InputDropDownWrapperUploadedStyles = css`
    ${InputTextWrapperStyles};
    margin-bottom: 0;
    height: 45px;
`;

export const InputDropDownUploadedStyles = css`
    ${InputTextUploadedStyles};
    width: 220px;
`;

export const SelectButtonDocStyles = css`
    ${SelectButtonStyles};
    background: ${props => props.theme.darkBlue};
`;

export const DragAndDropUpload = styled.label`
    display: block;
    padding: 77px 0;
    background: ${props => (props["data-loading"])
        ? props.theme.grayBg
        : (props["data-drag"])
        ? props.theme.grayBg
        : props.theme.grayBgBig
    };
    border: dashed 2px ${props => props.theme.textGrayLight};
    border-radius: 10px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    span {
        color: ${props => props.theme.cyan};
    }
    ${
        props => (props["data-loading"]) &&
            ` pointer-events: none; cursor: default; `
    };
    span.arrow {
        display: inline-block;
        transform: rotate(90deg);    
    }
`;

export const UploadedGallery = styled.div`
    width: 559px; height: 140px;
    border: solid 1px ${props => props.theme.textGrayLight};
    border-radius: 5px;
    box-sizing: border-box;
    padding: 3px;
    margin: 30px 30px 0 0;
    position: relative;
    float: left;
    &:nth-of-type(even) {
        margin-right: 0;
    }
    img {
        width: 200px; height: 132px;
        object-fit: cover;
        border-radius: 3px;
        float: left;
    }
    strong {
        font-size: 14px;
        position: absolute;
        bottom: 37px; right: 13px;
    }
    > i {
        display: block;
        width: 23px; height: 23px;
        background: ${props => props.theme.noticeRed};
        border-radius: 100%;
        margin-left: 15px;
        position: absolute;
        top: 8px; right: 8px;
        cursor: pointer;
    }
    > i:before, > i:after {
        content: '';
        position: absolute;
        top: 5px; left: 10px;
        width: 2px; height: 13px;        
        background-color: white;
    }
    > i:before {
        transform: rotate(45deg);
    }
    > i:after {
        transform: rotate(-45deg);
    }
`;

export const SwitchBoxDocStyles = css`
    ${SwitchBoxStyles};    
    position: absolute;
    bottom: 15px; right: 15px;
    width: 60px; height: 27px;
    i em {
        width: 54px; height: 23px;
        top: 1px; left: 2px;
    }
    i em:before {
        width: 17px; height: 17px;
        left: 3px; bottom: 3px;
    }
    input:checked + i em:before {
        transform: translateX(31px);
    }
    b {
        right: 10px;
        top: 7px;
        width: 12px;
        height: 12px;
    }
    b:before, b:after {
        left: 7px;
        height: 12px;
        width: 3px;
    }
    u {
        display: none;
        position: absolute;
        top: 6px; left: 14px;
        transform: rotate(45deg);
        width: 4px; height: 9px;
        border-radius: 3px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
    }
`;