import React, { Component } from 'react';
import {
    Wrapper,
    InputTextSearchStyles,
    InputTextWrapperSearchStyles,
    SelectedGroupsTag,
    GroupRowItem,
    CheckBoxRowStyles,
    ShareButtonSubmit,
    GroupRowItems,
} from './Styled';
import InputText from '../FormInputText';
import IconNew from '../IconNew';
import FormCheckBox from '../FormCheckBox';
import ButtonText from '../FormButtonText';
import { sendAsyncRequest } from '../../../Utils';
import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';

class SharedListingGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInputValue: '',
            groups: [],
        };
    }

    componentDidMount() {
        sendAsyncRequest('/share-groups', 'get', {}).then((result) => {
            if (result.status === 200) {
                this.setState({
                    groups: result.body.results.map((result) => {
                        return {
                            show: true,
                            selected: false,
                            ...result,
                        };
                    }),
                });
            }
        });
    }

    searchChangeHandler = (e) => {
        const value = e.target.value;
        let groups = [...this.state.groups];
        groups.forEach((group) => {
            group.show = group.name
                .toLowerCase()
                .includes(value.toString().toLowerCase());
        });
        this.setState({
            searchInputValue: e.target.value,
            groups,
        });
    };

    groupRowClickHandler = (groupId) => {
        let groups = [...this.state.groups];
        groups.forEach((group) => {
            if (group.id === groupId) {
                group.selected = !group.selected;
            }
        });
        this.setState({
            groups,
        });
    };

    shareButtonClick = () => {
        this.state.groups.filter((group) => group.selected);
    };

    render() {
        return (
            <Wrapper>
                <InputText
                    id="searchGroups"
                    name="searchGroups"
                    type="text"
                    styled={InputTextSearchStyles}
                    wrapperStyled={InputTextWrapperSearchStyles}
                    placeholder="Search"
                    required={true}
                    icon={
                        <IconNew
                            bp1="-407"
                            bp2="-159"
                            iwidth="25"
                            iheight="25"
                        />
                    }
                    onInput={this.searchChangeHandler}
                    value={this.state.searchInputValue}
                />

                <SelectedGroupsTag>
                    {this.state.groups.map(
                        (group) =>
                            group.selected && (
                                <span
                                    key={group.id}
                                    onClick={() =>
                                        this.groupRowClickHandler(group.id)
                                    }
                                >
                                    {group.name}
                                    <i />
                                </span>
                            )
                    )}
                </SelectedGroupsTag>

                <GroupRowItems>
                    {this.state.groups.length === 0 && (
                        <SkeletonLoading
                            type={SkeletonLoadingType.GENERAL_ROWS}
                        />
                    )}
                    {this.state.groups.length > 0 &&
                        this.state.groups.map(
                            (group) =>
                                group.show && (
                                    <GroupRowItem
                                        key={group.id}
                                        onClick={() =>
                                            this.groupRowClickHandler(group.id)
                                        }
                                    >
                                        <img src={group.img} alt="Group" />
                                        <div>
                                            <strong>{group.title}</strong>
                                            <h4>
                                                {group.name
                                                    .split(' ')
                                                    .slice(0, 2)
                                                    .join(' ')}
                                                <br />
                                                {group.name
                                                    .split(' ')
                                                    .slice(2)
                                                    .join(' ')}
                                                {group.verified && (
                                                    <IconNew
                                                        bp1="-411"
                                                        bp2="-186"
                                                        iwidth="20"
                                                        iheight="20"
                                                    />
                                                )}
                                            </h4>
                                            <span>
                                                <IconNew
                                                    bp1="-380"
                                                    bp2="-221"
                                                    iwidth="14"
                                                    iheight="13"
                                                />
                                                {group.share_counts}
                                            </span>
                                        </div>
                                        <FormCheckBox
                                            onChange={() =>
                                                this.groupRowClickHandler(
                                                    group.id
                                                )
                                            }
                                            selected={group.selected}
                                            styled={CheckBoxRowStyles}
                                        />
                                    </GroupRowItem>
                                )
                        )}
                </GroupRowItems>

                <ButtonText
                    onClick={this.shareButtonClick}
                    styled={ShareButtonSubmit}
                >
                    Share
                </ButtonText>
            </Wrapper>
        );
    }
}

export default SharedListingGroup;
