import styled, { css } from 'styled-components';
import { InputTextWrapperStyles } from '../LoginForm/Styled';

export const InputTextTagStyles = css`
    width: 100%;
    height: 55px;
    font-family: ${(props) => props.theme.mainFont};
    font-size: 23px;
    line-height: 23px;
    color: ${(props) => props.theme.textBlack};
    border: none;
    padding: 0 0 12px;
    background: transparent;
    &:focus {
        border-bottom-color: ${(props) => props.theme.cyan};
    }
    &::placeholder {
        color: ${(props) => props.theme.mainFontColor};
    }
    padding-left: 45px;
`;

export const InputTextTagWrapperStyles = css`
    ${InputTextWrapperStyles};
    float: left;
    margin-bottom: 0;
    width: 500px;
    span {
        position: absolute;
        left: 0;
        bottom: 1px;
    }
`;

export const TabPageStyles = css`
    margin-top: 10px;
`;

export const SearchTags = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    float: left;
`;

export const MyListingsSearchTab = styled.div`
    position: absolute;
    bottom: 100px;
`;

export const CardTopContainer = styled.div`
background-image: linear-gradient(to right, #07aed0 0%, #7106c4 113%);
opacity: 0.7;
`;

export const SearchTag = styled.span`
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    padding: 2px 16px 3px 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.darkBlue};
    background: ${(props) => props.theme.lightestBlue};
    border-radius: 3px;
    position: relative;
    span {
        position: relative;
        top: 2px;
        margin-right: 5px;
    }
    i {
        position: absolute;
        right: 4px;
        top: 7px;
        width: 8px;
        height: 8px;
        cursor: pointer;
    }
    i:before,
    i:after {
        position: absolute;
        left: 3px;
        content: ' ';
        height: 8px;
        width: 1px;
        background-color: ${(props) => props.theme.darkBlue};
    }
    i:before {
        transform: rotate(45deg);
    }
    i:after {
        transform: rotate(-45deg);
    }
`;

export const FiltersButtonStyle = css`
    margin-right: 20px;
`;

export const LeadManagementFiltersStyle = styled.button`
    color: ${(props) => props.theme.mainFontColor};
    margin-right: 0.1px;
    border: 1px solid ${(props) => props.theme.grayLight};
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: -3px;
    margin-right: 10px;
    width: 120px;
    background: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    float: right;
`;

export const ButtonTextWrap = styled.span`
    font-size: 17px;
    margin-top: 2px;
`;


export const InputWrapper = styled.div`
    width:1260px;
    display:block;
    background-color:white;
    height:54px;
    border-radius:10px;
`;

export const SaveButtonStyle = styled.button`
    width: 60px;
    height: 40px;
    background: transparent;
    border: 1px solid ${(props) => props.theme.cyan};
    border-radius: 3px;
    border-radius: 5px;
    color: ${(props) => props.theme.cyan};
    cursor: pointer;
    margin-top: -3px;
    margin-right: 1px;
    font-size: 14px;
    font-weight: bold;
    float: right;
`;



export const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const CardWrapper = styled.div`
    width: 400px;
    margin-left: ${(props) => (props.noMargin ? '0' : '30px')};
    border-radius: ${(props) => (props.visible ? '0' : '10px')};
    margin-top: 30px;
    display: inline-block;
    overflow: hidden;
    box-shadow: 0px 0px 10px #0000002E;
`;

export const CardTopWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const RightBorder = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    border-left: 2px solid ${(props) => props.theme.textGrayLight};
    top: 50%;
    transform: translateY(-50%);
`;

export const CardtopItem = styled.div`
    flex:1;
    display: flex;
    align-items: center;
    position:relative;
    justify-content: center;

`;

export const TopTextWrapper = styled.div`
    font-size: 14;
    color: white;
    display:flex;
    flex-direction:row;
    align-items:center;
`;

export const CarouselMiddleContainer = styled.div`
    display: flex;
    background: white;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 23px;
    border-left: ${(props) => props.withBorders && `1px solid  #e5e5e5`};
    border-right: ${(props) => props.withBorders && `1px solid  #e5e5e5`};
    padding-top: ${(props) => (props.bigPaddingTop ? '23px' : '4px')};
`;
export const CarouselTopSideHeaderText = styled.span`
    color: ${(props) => props.theme.mainFontColor};
    font-size: 16px;
    line-height: 1.13;
    font-family:'Montserrat';
`;
export const CarouselBottomAddressText = styled.span`
    color: ${(props) => props.theme.mainFontColor};
    font-size: 12px;
    margin-left: 6.6px;
    font-family:'Montserrat';
    font-weight:lighter;
`;
export const CarouselMiddleBottomSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 18px;
`;
export const CarouselMiddlePriceText = styled.div`
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;
    flex: 1;
    line-height: 1.06;
    font-family:'Montserrat';
`;
export const CarouselMiddleRightSideText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    font-family:'Montserrat';
`;
export const CarouselMiddleTopSide = styled.div`
    display: flex;
    flex-direction: row;
    height: 18px;
    justify-content: space-between;
    align-items: center;
`;

export const CarouselMiddleSide = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const RightSideText = styled.div`
    position: relative;
    text-align: ${(props) => (props.lastItem ? 'right' : 'center')};
    font-size: 10px;
    color: ${(props) => props.theme.mainFontColor};
    flex: 15.333333%;
    font-family:'Montserrat';
`;

export const RightSideTextBorder = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    border-left: 1px solid ${(props) => props.theme.textGrayLight};
    top: 50%;
    transform: translateY(-50%);
`;

export const RighttSideTextNumber = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.mainFontColor};
    padding-right: 3px;
`;

export const CardMiddlebottomLeftside = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
`;

export const IconWrapper = styled.div`
    font-size: 12px;
`;

export const CardBottomLeftSide = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CardBottomRightSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 50%;
`;

export const CardBottomContainer = styled.div`
    display: flex;
    flex-direction: row;

    height: 38px;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${(props) => props.theme.grayBgInput};
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
`;

export const StatisticsButtonWrapper = styled.button`
    font-size: 14px;
    color: ${(props) => props.theme.textBlack};
    background: white;
    font-family:'Montserrat';
    border: 1px solid white;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.grayBorderLight};
    display: flex;
    width: 80px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const TopText = styled.span`
    padding-left: 6.8px;
    font-family:'Montserrat';
    color:white;
    font-size:14px;
`;

export const CardTopBottomSide = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding-left:60px;
padding-right:60px;
`;

export const BoostButtonWrapper = styled.button`
    background: transparent;
    background-image: linear-gradient(to right, #a64bec, #7106c4);
    color: white;
    font-size: 14px;
    border-radius: 3px;
    border: none;
    font-family:'Montserrat';
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 72px;
    margin-left: 5px;
`;

export const RocketIconWrapper = styled.span`
    padding-left: 2px;
`;

export const VisibleAreaContainer = styled.div`
    display: flex;
    background: white;
    flex-direction: column;
    padding-left: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 10px;
    position: absolute;
    width: 400px;
    box-sizing: border-box;
    z-index: 2;
`;
export const PrivilegedSection = styled.div`
    border-top: solid 1px #cccccc;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const PrivilegedSectionLeftSide = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
`;
export const FeaturedSection = styled.div`
    border-top: solid 1px #cccccc;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const FeaturedSectionLeftSide = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
`;
export const SponsoredLeftSide = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;
export const SponsoredSection = styled.div`
    display: flex;
    height: 46px;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px #cccccc;
    height: 46px;
    flex: 1;
`;

export const BoostsectionsHeader = styled.span`
    color: ${(props) => props.theme.textBlack};
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const PlusMinusSpan = styled.div`
    color: #2c80f5;
    font-size: 25px;
`;

export const PrivilegedRightSideAmount = styled.span`
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;
    margin-right: 44.6px;
`;

export const PrivilegedRightSide = styled.div`
    display: flex;
    align-items: center;
`;

export const StatisticsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
`;

export const StatisticsPadding = styled.div`
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    margin-top: 28px;
    align-items: center;
    width: 380px;
    border-bottom: 1px solid ${(props) => props.theme.textGrayLight};
    padding-bottom: 26.5px;
`;

export const HeaderText = styled.span`
    color: ${(props) => props.theme.deepBlue};
    font-size: 26px;
`;

export const LeftSide = styled.div`
    width: 65.87301587301587%;
    padding-right: 30px;
`;

export const RightSide = styled.div`
    width: 34.12698412698413%;
`;

export const SocialStatisticsTopItemText = styled.span`
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;
    padding-bottom: 7px;
`;

export const SocialStatisticsTopWrapper = styled.div`
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.textGrayLight};
    height: 100px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

export const SocialStatisticsTopItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ChartsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 14.5px;
`;

export const SocialStatStyles = css`
    width: 180px;
`;
export const ChartsMiddleSide = styled.div`
    align-self: flex-start;
`;

export const ChartsTopSide = styled.div`
    align-self: center;
`;

export const CircleChartWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
`;

export const CircleChartContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ChartCircle = styled.div`
    background: ${(props) => `
		radial-gradient(
			circle closest-side,
			transparent 100%,
			white 0
		),
		conic-gradient(
			${props.theme.violet} 0,
			${props.theme.violet} ${props.newLeads}%,
			${props.theme.cheeseYellow} 0,
			${props.theme.cheeseYellow} ${props.newLeads + props.inProgress}%,
			${props.theme.textGrayLight} 0,
			${props.theme.textGrayLight} 100%
	);`};
    position: relative;
    width: 130px;
    min-height: 130px;
    padding: 0;

    > div {
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background: ${(props) => props.theme.white};
        position: absolute;
        left: 25%;
        top: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: bold;
        color: ${(props) => props.theme.violet};
    }
`;

export const CircleChartTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CircleChartRightText = styled.div`
    margin-left: 14px;
`;

export const CircleChartRightSide = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CircleChartLeftText = styled.div``;

export const BottomChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 53px;
`;

export const BottomSelectorWrapper = styled.div`
    justify-self: flex-start;
`;

export const BottomChartDataWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 23px;
`;

export const BottomLeftText = styled.span`
    width: 20%;
`;
export const BottomChartWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: flex-end;
`;
export const PercetangeText = styled.span`
    margin-right: 8px;
    color: ${(props) => props.theme.mainFontColor};
`;
export const ChartDisplay = styled.span`
    background: ${(props) => props.theme.cyan};
    width: ${(props) => props.width && `${props.width}%`};
    height: 10px;
`;

export const ChartItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ArrowSpanWrapper = styled.div`
    margin-top: 10px;
`;


