import styled, { css } from 'styled-components';
import { InfoText, InputTextWrapperStyles } from '../LoginForm/Styled';

export const Wrapper = styled.div``;

export const FormBox = styled.div`
    width: 566px;
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    margin-top: -30px;

    @media ${(props) => props.theme.mediaHD} {
        width: 404px;
    }
    @media ${(props) => props.theme.mediaHD} {
        width: 404px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        width: 400px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        width: 400px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        width: 335px;
    }
`;

export const InnerBox = styled.div`
    float: left;
    position: relative;
    right: ${(props) => props.step * -566}px;
    margin-right: -566px;
    width: 566px;
    box-sizing: border-box;
    transition: 0.3s;

    @media ${(props) => props.theme.mediaHD} {
        right: ${(props) => props.step * -404}px;
        margin-right: -404px;
        width: 404px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        right: ${(props) => props.step * -400}px;
        margin-right: -400px;
        width: 400px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        right: ${(props) => props.step * -400}px;
        margin-right: -400px;
        width: 400px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        right: ${(props) => props.step * -335}px;
        margin-right: -335px;
        width: 335px;
    }
`;

export const InputTextWrapperRegisterStyles = css`
    ${InputTextWrapperStyles};
    height: 40px;
    p {
        top: 50px;
        bottom: auto;
        line-height: 18px;
    }
`;

export const ButtonTextFlatStyles = css`
    width: 100%;
    height: 80px;
    font-size: 24px;
    border-radius: 5px;
    padding-bottom: 6px;
    color: #fff;
    border: none;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        90deg,
        ${(props) => props.theme.cyan} 0%,
        ${(props) => props.theme.ceruleanBlue} 100%
    );
    ${(props) =>
        props.disabled && ` background: ${props.theme.textGrayLight}; `};

    @media ${(props) => props.theme.mediaHD} {
        height: 60px;
        font-size: 21px;
    }
    @media ${(props) => props.theme.mediaTabletLandscape} {
        height: 60px;
        font-size: 18px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        height: 60px;
        font-size: 18px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        height: 50px;
        font-size: 16px;
    }
`;

export const ButtonTextFlatStylesDark = css`
    ${ButtonTextFlatStyles};
    background: ${(props) => props.theme.darkBlue};
    margin-top: 20px;
    ${(props) =>
        props.disabled && ` background: ${props.theme.textGrayLight}; `};
`;

export const InfoTextRegister = styled(InfoText)`
    margin: 20px 0 25px;
`;

export const InfoParagraph = styled.p`
    text-align: center;
    margin: 0 0 40px;
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;
    font-weight: lighter;
`;

export const ErrorMessage = styled.div`
    font-size: 16px;
    line-height: 22px;
    background: ${(props) => props.theme.errorMessage};
    color: #fff;
    padding: 10px 15px;
    margin: -30px 0 20px;
    border-radius: 10px;
    i {
        position: absolute;
        right: 15px;
        top: 13px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
    i:before,
    i:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 16px;
        width: 2px;
        background-color: white;
    }
    i:before {
        transform: rotate(45deg);
    }
    i:after {
        transform: rotate(-45deg);
    }

    @media ${(props) => props.theme.mediaHD} {
        font-size: 13px;
        line-height: 18px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        margin: -10px 0 20px;
        font-size: 10px;
        line-height: 14px;
    }
`;

export const HintText = styled.p`
    font-size: 14px;
    margin: -65px 0 35px;
    color: ${(props) => props.theme.mainFontColor};

    @media ${(props) => props.theme.mediaTabletLandscape} {
        margin-top: -35px;
    }
    @media ${(props) => props.theme.mediaTablet} {
        margin-top: -35px;
    }
    @media ${(props) => props.theme.mediaMobile} {
        margin-top: -20px;
    }
`;
