import styled from 'styled-components';

export const TextArea = styled.textarea`
    float: left;
    width: 450px;
    font-size: 18px;
    font-weight: lighter;
    font-weight: lighter;
    margin: 22px 0 0 20px;
    border: none;
    &::placeholder {
        color: ${(props) => props.theme.textGrayLight};
    }
    font-family: ${(props) => props.theme.mainFont};
    color: ${props => props.theme.mainFontColor};
    position: relative;
    resize: none;

    @media ${(props) => props.mediaHD} {
        width: 250px;
    }
`;

export const Triangle = styled.div`
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${(props) => props.theme.textGrayLight};

    position: absolute;
    top: -7px;
    left: ${(props) => `${props.left - 8}px`};

    ::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid white;
        position: absolute;
        top: 1px;
        left: -6px;
    }
`;

export const List = styled.div`
    width: 450px;
    margin-left: 92px;
    background: ${(props) => props.theme.white};
    float: left;
    border: 1px solid ${(props) => props.theme.textGrayLight};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: ${(props) => `${65 + props.top}px`};
    z-index: 3;

    button.listItem {
        display: block;
        margin: 0;
        padding: 10px;
        width: 100%;
        text-align: left;
        border: none;
        padding-bottom: 5px;
        background: transparent;
        cursor: pointer;

        > h5 {
            color: ${(props) => props.theme.mainFontColor};
            font-size: 16px;
            line-height: 24px;
            margin: 0;
        }

        > p {
            font-size: 10px;
            line-height: 14px;
            margin: 0;
        }

        :last-of-type {
            border-bottom: none;
        }
    }

    @media ${(props) => props.mediaHD} {
        width: 250px;
    }
`;
