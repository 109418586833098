import React, {Component} from 'react';
import {IconWrapper} from './Styled';

class IconNew extends Component {
    render() {
        return (
            <IconWrapper
                bp1={this.props.bp1}
                bp2={this.props.bp2}
                iwidth={this.props.iwidth}
                iheight={this.props.iheight}
                className={this.props.className}
                onClick={this.props.onClick}
            />
        )
    }
}

export default IconNew;