import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ClearFloats } from '../BaseTemplate/Styled';
import {
    PropertiesWrapperLeft,
    PropertiesWrapperRight,
} from '../FeedPage/Styled';
import { Column } from '../../../styles/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import {
    sendAsyncRequest,
    numberFormatter,
    commaSeparator,
    getInputNameAndValue,
} from '../../../Utils';
import SearchTabBar from '../SearchTabBar';
import {
    Price,
    ListingInfoWrapper,
    TabPage,
    Title,
    PopContainer,
    Input,
    CheckBoxGroup,
    CheckBoxStyles,
    InputLabelStyles,
    TextInputStyles,
    InputWrapperStyles,
    InputLabeledWrapperStyles,
    InputSelectStyles,
    LabelStyles,
    LabelAfterStyles,
    SwitchBoxMenuStyles,
    ApplyButtonSubmit,
    MapCard,
    CloseButton,
    CardSectionWrapper,
    SocialDetailsWrapper,
    SocialActinvityAmount,
} from './Styled';
import SearchNewListingItem from '../SearchNewListingItem';
import IconNew from '../IconNew/IconNew';
import { Tag, TagWrapper } from '../SearchNewListingItem/Styled';
import FilterTab, { TabStyle } from '../TabBarPages';
import GoogleMapView from '../GoogleMapView';
import SearchFilterCategory from '../SearchFilterCategory';
import CheckBox from '../FormCheckBox';
import InputText from '../FormInputText';
import InputDropDown from '../FormInputDropDown';
import FormSwitchBox from '../FormSwitchBox';
import FormButtonText from '../FormButtonText';

class SearchNewListing extends Component {
    constructor(props) {
        super(props);
        this.currentUrlParams = new URLSearchParams(window.location.search);
        this.state = {
            result: [],
            mapResult: [],
            points: [],
            filters: {
                listingType: 'For Sale',
                propertyType: [],
                minPrice: '',
                maxPrice: '',
                minBed: { text: '' },
                maxBed: { text: '' },
                minBath: { text: '' },
                maxBath: { text: '' },
                builtUpArea: '',
                builtUpUnit: '',
                lotSize: '',
                lotSizeUnit: '',
            },
            showListingTypePop: false,
        };
        this.propertyTypes = [
            'Houses',
            'Condos/co-ops',
            'Apartments',
            'Townhomes',
            'Manufactured',
            'Multi-family',
            'Lots/Land',
        ];
        this.bedBathList = [
            {
                text: '1',
                value: 1,
            },
            {
                text: '2',
                value: 2,
            },
            {
                text: '3',
                value: 3,
            },
            {
                text: '4',
                value: 4,
            },
            {
                text: '5',
                value: 5,
            },
            {
                text: '6',
                value: 6,
            },
        ];
        this.units = [
            {
                text: 'sqft',
                value: 'sqft',
            },
        ];
    }

    async componentDidMount() {
        await this.handleInitialSearch();
        sendAsyncRequest('/property/search/', 'get', {}).then((res) => {
            console.log(res.body.Result.results);
            this.loadSearch(res.body.Result.results);
            this.loadPoints(res.body.Result.results);
        });
    }

    handleInitialSearch = () => {
        this.currentUrlParams.forEach((value, key) => {
            let newVal;
            if (
                key === 'minBed' ||
                key === 'maxBed' ||
                key === 'minBath' ||
                key === 'maxBath'
            ) {
                newVal = { text: value, value: parseInt(value) };
            } else {
                newVal = value;
            }
            this.setState((prevState) => {
                return {
                    filters: {
                        ...prevState.filters,
                        [key]: newVal,
                    },
                };
            });
        });
    };

    handleTypeChange = (type) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    listingType: type,
                },
                showListingTypePop: false,
            };
        });
        this.currentUrlParams.set('listingType', type);
        this.props.history.push(
            window.location.pathname + '?' + this.currentUrlParams.toString()
        );
    };

    changeCheckBoxHandler = (e, type) => {
        let newState = [...this.state.filters.propertyType];
        const isChecked = newState.includes(type);
        if (isChecked) {
            const index = newState.findIndex((x) => x === type);
            newState.splice(index, 1);
            this.setState((prevState) => {
                return {
                    filters: {
                        ...prevState.filters,
                        propertyType: newState,
                    },
                };
            });
        } else {
            newState.push(type);
            this.setState((prevState) => {
                return {
                    filters: {
                        ...prevState.filters,
                        propertyType: newState,
                    },
                };
            });
            this.state.filters.propertyType.push(type);
        }
        this.currentUrlParams.set('propertyType', newState);
        this.props.history.push(
            window.location.pathname + '?' + this.currentUrlParams.toString()
        );
    };

    handleInputChange = (event) => {
        const inputData = getInputNameAndValue(event),
            filters = { ...this.state.filters };
        filters[inputData.name] = inputData.value;
        this.setState({
            filters,
        });
        this.currentUrlParams.set(inputData.name, inputData.value);
        this.props.history.push(
            window.location.pathname + '?' + this.currentUrlParams.toString()
        );
    };

    handleDropDownChange = (name, valueObj) => {
        const filters = { ...this.state.filters };
        filters[name] = valueObj;
        this.setState({ filters });
        this.currentUrlParams.set(name, valueObj.value);
        this.props.history.push(
            window.location.pathname + '?' + this.currentUrlParams.toString()
        );
    };

    loadPoints = (data) => {
        let points = [...this.state.points];
        data.forEach((item, index) => {
            points.push({
                position: {
                    lat: item.latitude,
                    lng: item.longitude,
                },
                id: index,
            });
        });
        this.setState({ points });
    };

    loadSearch = (data) => {
        let result = [...this.state.result];
        let mapResult = [...this.state.mapResult];

        data.forEach((item, index) => {
            result.push(
                <SearchNewListingItem
                    type={'listing'}
                    listing={item}
                    key={`listing${index}`}
                />
            );
            mapResult.push(
                <MapCard>
                    <CloseButton />
                    <p>
                        {item.street}, {item.zipcode} {item.city}
                    </p>
                    <img alt="property" src={item.images[0].image} />
                    <CardSectionWrapper>
                        <Price>${commaSeparator(parseFloat(item.price))}</Price>
                        <ListingInfoWrapper left>
                            <strong>{item.bedrooms}</strong> BEDS
                            <em />
                            <strong>{item.bathrooms}</strong> BATH
                            <em />
                            <strong>
                                {commaSeparator(parseFloat(item.living_area))}
                            </strong>
                            <span> sqft</span>
                        </ListingInfoWrapper>
                        <TagWrapper all>
                            {item.tags.map((tag) => {
                                switch (tag) {
                                    case '%':
                                        return (
                                            <Tag
                                                key={'newListing-' + tag}
                                                to="/"
                                            >
                                                <IconNew
                                                    bp1="-257"
                                                    bp2="-77"
                                                    iwidth="26"
                                                    iheight="26"
                                                />
                                            </Tag>
                                        );
                                    case 'N':
                                        return (
                                            <Tag
                                                key={'newListing-' + tag}
                                                to="/"
                                            >
                                                <IconNew
                                                    bp1="-229"
                                                    bp2="-77"
                                                    iwidth="26"
                                                    iheight="26"
                                                />
                                            </Tag>
                                        );
                                    case 'R':
                                        return (
                                            <Tag
                                                key={'newListing-' + tag}
                                                to="/"
                                            >
                                                <IconNew
                                                    bp1="-201"
                                                    bp2="-77"
                                                    iwidth="26"
                                                    iheight="26"
                                                />
                                            </Tag>
                                        );
                                    default:
                                        return '';
                                }
                            })}
                        </TagWrapper>
                    </CardSectionWrapper>
                    <div className="footer">
                        <SocialDetailsWrapper>
                            <IconNew
                                bp1="-470"
                                bp2="-103"
                                iwidth="13"
                                iheight="12"
                            />
                            <SocialActinvityAmount style={{ fontSize: '14px' }}>
                                {numberFormatter(item.total_likes)}
                            </SocialActinvityAmount>
                            <em />
                        </SocialDetailsWrapper>
                        <SocialDetailsWrapper>
                            <IconNew
                                bp1="-447"
                                bp2="-160"
                                iwidth="17"
                                iheight="14"
                            />
                            <SocialActinvityAmount>
                                {numberFormatter(item.total_comments)}
                            </SocialActinvityAmount>
                            <em />
                        </SocialDetailsWrapper>
                        <SocialDetailsWrapper>
                            <IconNew
                                bp1="-447"
                                bp2="-131"
                                iwidth="22"
                                iheight="13"
                            />
                            <SocialActinvityAmount>
                                {numberFormatter(item.visibility)}
                            </SocialActinvityAmount>
                            <em />
                        </SocialDetailsWrapper>
                        <SocialDetailsWrapper>
                            <IconNew
                                bp1="-470"
                                bp2="-117"
                                iwidth="13"
                                iheight="14"
                            />
                            <SocialActinvityAmount>
                                {numberFormatter(item.total_share)}
                            </SocialActinvityAmount>
                            <em />
                        </SocialDetailsWrapper>
                    </div>
                    <ClearFloats />
                </MapCard>
            );
        });
        this.setState({ result, mapResult });
    };

    render() {
        return (
            <Fragment>
                <Column type="one_column_setting" padding_right first>
                    <SearchTabBar
                        defaultIndex={1}
                        showMap={this.props.showMap}
                        toggleShow={this.props.toggleShow}
                    />
                    {!this.props.showMap ? (
                        <>
                            <PropertiesWrapperLeft>
                                {this.state.result.map(
                                    (item, index) => index % 3 === 0 && item
                                )}
                            </PropertiesWrapperLeft>
                            <PropertiesWrapperLeft>
                                {this.state.result.map(
                                    (item, index) => index % 3 === 1 && item
                                )}
                            </PropertiesWrapperLeft>
                            <PropertiesWrapperRight>
                                {this.state.result.map(
                                    (item, index) => index % 3 === 2 && item
                                )}
                            </PropertiesWrapperRight>
                        </>
                    ) : (
                        <GoogleMapView
                            container={{
                                width: '100%',
                                height: '700px',
                                marginTop: '40px',
                                borderRadius: '10px',
                            }}
                            points={this.state.points}
                            cards={this.state.mapResult}
                            color={'blue'}
                        />
                    )}
                </Column>

                <ProfileMenu closed unexpandable />

                <Column
                    type="one_column_right_fixed_simple"
                    first
                    scrollable
                    background={'culturedGray'}
                >
                    <Title>Filters</Title>
                    <FilterTab
                        theme={TabStyle.SearchPropertyFilter}
                        defaultPageIndex={0}
                    >
                        <TabPage key={'tabPage-1'} title={'Residential'}>
                            <SearchFilterCategory title={'Listing type'}>
                                <Input
                                    id="listingType"
                                    name="listingType"
                                    readOnly
                                    onClick={() =>
                                        this.setState({
                                            showListingTypePop: true,
                                        })
                                    }
                                    value={this.state.filters.listingType}
                                    onBlur={() =>
                                        this.setState({
                                            showListingTypePop: false,
                                        })
                                    }
                                />
                                <PopContainer
                                    display={
                                        this.state.showListingTypePop
                                            ? 'block'
                                            : 'none'
                                    }
                                >
                                    {['For Sale', 'For Rent'].map(
                                        (type, index) => (
                                            <button
                                                key={index}
                                                onClick={() =>
                                                    this.handleTypeChange(type)
                                                }
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                {type}
                                            </button>
                                        )
                                    )}
                                </PopContainer>
                                <ClearFloats />
                            </SearchFilterCategory>
                            <SearchFilterCategory
                                title={'Property Type'}
                                subTitle={`(${
                                    this.state.filters.propertyType.length
                                })`}
                            >
                                <CheckBoxGroup>
                                    {this.propertyTypes.map((type, index) => (
                                        <CheckBox
                                            key={'check' + index}
                                            id={'check' + index}
                                            name={'check' + index}
                                            label={type}
                                            checked={this.state.filters.propertyType.includes(
                                                type
                                            )}
                                            onChange={(e) =>
                                                this.changeCheckBoxHandler(
                                                    e,
                                                    type
                                                )
                                            }
                                            styled={CheckBoxStyles}
                                        />
                                    ))}
                                </CheckBoxGroup>
                            </SearchFilterCategory>
                            <SearchFilterCategory
                                title={'Price'}
                                subTitle={`($${
                                    this.state.filters.minPrice
                                } - $${this.state.filters.maxPrice})`}
                            >
                                <InputText
                                    id={'minPrice'}
                                    type="text"
                                    name={'minPrice'}
                                    label={'Price Range ( Min / Max )'}
                                    onInput={this.handleInputChange}
                                    styled={TextInputStyles}
                                    labelStyled={InputLabelStyles}
                                    wrapperStyled={InputWrapperStyles}
                                    value={this.state.filters.minPrice}
                                />
                                <InputText
                                    id={'maxPrice'}
                                    type="text"
                                    name={'maxPrice'}
                                    onInput={this.handleInputChange}
                                    styled={TextInputStyles}
                                    wrapperStyled={InputWrapperStyles}
                                    value={this.state.filters.maxPrice}
                                />
                                <ClearFloats />
                            </SearchFilterCategory>
                            <SearchFilterCategory
                                title={'Bed'}
                                subTitle={`(${
                                    this.state.filters.minBed.text
                                } - ${this.state.filters.maxBed.text}) Beds`}
                            >
                                <InputDropDown
                                    id={'minBed'}
                                    name={'minBed'}
                                    type="text"
                                    styled={InputSelectStyles}
                                    wrapperStyled={InputLabeledWrapperStyles}
                                    labelAnimate="Min Bed"
                                    labelAnimateStyled={LabelStyles}
                                    labelAfterAnimateStyled={LabelAfterStyles}
                                    list={this.bedBathList}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                    value={this.state.filters.minBed}
                                />
                                <InputDropDown
                                    id={'maxBed'}
                                    name={'maxBed'}
                                    type="text"
                                    styled={InputSelectStyles}
                                    wrapperStyled={InputLabeledWrapperStyles}
                                    labelAnimate="Max Bed"
                                    labelAnimateStyled={LabelStyles}
                                    labelAfterAnimateStyled={LabelAfterStyles}
                                    list={this.bedBathList}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                    value={this.state.filters.maxBed}
                                />
                                <ClearFloats />
                            </SearchFilterCategory>

                            <SearchFilterCategory
                                title={'Bath'}
                                subTitle={`(${
                                    this.state.filters.minBath.text
                                } - ${this.state.filters.maxBath.text}) Bath`}
                            >
                                <InputDropDown
                                    id={'minBath'}
                                    name={'minBath'}
                                    type="text"
                                    styled={InputSelectStyles}
                                    wrapperStyled={InputLabeledWrapperStyles}
                                    labelAnimate="Min Bath"
                                    labelAnimateStyled={LabelStyles}
                                    labelAfterAnimateStyled={LabelAfterStyles}
                                    list={this.bedBathList}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                    value={this.state.filters.minBath}
                                />
                                <InputDropDown
                                    id={'maxBath'}
                                    name={'maxBath'}
                                    type="text"
                                    styled={InputSelectStyles}
                                    wrapperStyled={InputLabeledWrapperStyles}
                                    labelAnimate="Max Bath"
                                    labelAnimateStyled={LabelStyles}
                                    labelAfterAnimateStyled={LabelAfterStyles}
                                    list={this.bedBathList}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                    value={this.state.filters.maxBath}
                                />
                                <ClearFloats />
                            </SearchFilterCategory>

                            <SearchFilterCategory title={'More Details'}>
                                <InputText
                                    id={'builtUpArea'}
                                    type="text"
                                    name={'builtUpArea'}
                                    label={'Built-up Area'}
                                    onInput={this.handleInputChange}
                                    styled={TextInputStyles}
                                    labelStyled={InputLabelStyles}
                                    wrapperStyled={InputWrapperStyles}
                                    value={this.state.filters.builtUpArea}
                                    placeholder={'Enter Number ...'}
                                />
                                <InputDropDown
                                    id={'builtUpUnit'}
                                    name={'builtUpUnit'}
                                    type="text"
                                    styled={InputSelectStyles}
                                    wrapperStyled={InputWrapperStyles}
                                    list={this.units}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                    //value={}
                                />

                                <InputText
                                    id={'lotSize'}
                                    type="text"
                                    name={'lotSize'}
                                    label={'Lot Size'}
                                    onInput={this.handleInputChange}
                                    styled={TextInputStyles}
                                    labelStyled={InputLabelStyles}
                                    wrapperStyled={InputWrapperStyles}
                                    value={this.state.filters.lotSize}
                                    placeholder={'Enter Number ...'}
                                />
                                <InputDropDown
                                    id={'lotSizeUnit'}
                                    name={'lotSizeUnit'}
                                    type="text"
                                    styled={InputSelectStyles}
                                    wrapperStyled={InputWrapperStyles}
                                    list={this.units}
                                    listParent={true}
                                    dropDownValue={this.handleDropDownChange}
                                    //value={}
                                />
                                <ClearFloats />
                            </SearchFilterCategory>

                            <SearchFilterCategory
                                title={'With Picture'}
                                noArrow
                            >
                                <FormSwitchBox
                                    styled={SwitchBoxMenuStyles}
                                    noSigns
                                />
                                <ClearFloats />
                            </SearchFilterCategory>
                            <FormButtonText styled={ApplyButtonSubmit}>
                                Apply
                            </FormButtonText>
                        </TabPage>
                        <TabPage key={'tabPage-2'} title={'Commercial'} />
                    </FilterTab>
                </Column>

                <ClearFloats />
            </Fragment>
        );
    }
}

export default withRouter(SearchNewListing);
