import React from 'react';
import {
    LoginRegisterColumn,
    LoginRegisterMain,
    Logo,
    TwoSideWrapper,
    VideoSideWrapper,
    VideoWrapper,
} from './Styled';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import LoginForm from '../Modules/LoginForm';
import LoginRegisterDesign from '../Modules/LoginRegisterDesign';
import LoginRegisterFooter from '../Modules/LoginRegisterFooter';
import ReactPlayer from 'react-player';
import MainLogo from './MainLogo';
import SecondLogo from './SecondLogo';
import MainLogoSmall from './MainLogoSmall';
import SecondLogoSmall from './SecondLogoSmall';

export default class Login extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.showHeader = false;
        super.bodyBg = 'white';
    }

    renderBody() {
        return (
            <>
            <LoginRegisterMain>
                <LoginRegisterDesign />
                {window.matchMedia(this.theme.mediaFullHD).matches ||
                window.matchMedia(this.theme.mediaHD).matches ||
                window.matchMedia(this.theme.mediaTabletLandscape).matches ? (
                    <TwoSideWrapper>
                        <VideoSideWrapper>
                            <Logo align={'left'}>
                                <MainLogo />
                            </Logo>
                            <VideoWrapper>
                                <ReactPlayer
                                    url="https://realtyfeed.com/video/realtyfeed-v3-final.mp4"
                                    height="100%"
                                    width="100%"
                                    controls
                                    config={{
                                        file: {
                                            attributes: {
                                                poster:
                                                    '/assets/img/poster.jpeg',
                                                preload: 'true',
                                            },
                                        },
                                    }}
                                />
                            </VideoWrapper>
                            <p>
                                Real Estate Social Networking | Privileged®
                                (listing functions)
                            </p>
                        </VideoSideWrapper>
                        <LoginRegisterColumn noMargin>
                            <Logo align={'left'}>
                                <SecondLogo />
                            </Logo>
                            <LoginForm />
                        </LoginRegisterColumn>
                    </TwoSideWrapper>
                ) : (
                    <LoginRegisterColumn>
                        <Logo align={'left'}>
                            {window.matchMedia(this.theme.mediaMobile)
                                .matches ? (
                                <MainLogoSmall />
                            ) : (
                                <MainLogo />
                            )}
                        </Logo>
                        <Logo align={'left'}>
                            {window.matchMedia(this.theme.mediaMobile)
                                .matches ? (
                                <SecondLogoSmall />
                            ) : (
                                <SecondLogo />
                            )}
                        </Logo>
                        <VideoWrapper>
                            <ReactPlayer
                                url="https://realtyfeed.com/video/realtyfeed-v3-final.mp4"
                                height="100%"
                                width="100%"
                                controls
                                config={{
                                    file: {
                                        attributes: {
                                            poster: '/assets/img/poster.jpeg',
                                            preload: 'true',
                                        },
                                    },
                                }}
                            />
                        </VideoWrapper>
                        <p>
                            Real Estate Social Networking | Privileged® (listing
                            functions)
                        </p>
                        <LoginForm />
                    </LoginRegisterColumn>
                )}
            </LoginRegisterMain>
            <LoginRegisterFooter />
            </>
        );
    }
}
