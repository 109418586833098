import styled from 'styled-components';
import { ArrowDown } from '../BaseTemplate/Styled';

export const Wrapper = styled.div`
    border-top: 1px solid ${(props) => props.theme.grayBorderLight};
    border-bottom: 1px solid ${(props) => props.theme.grayBorderLight};
    padding: 30px 20px;

    :first-of-type {
        border-top: none;
    }
    :last-of-type {
        border-bottom: none;
    }
`;

export const CategoryTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.textBlack};
    text-align: left;
    margin: 0;
    display: inline-block;
`;

export const Arrow = styled(ArrowDown)`
    float: right;
    margin-top: 10px;
    cursor: pointer;
    transition: all 1s;
    ${(props) => props.open && 'transform : rotate(180deg)'};
`;

export const SubTitle = styled.p`
    font-size: 16;
    color: ${(props) => props.theme.cyan};
    text-align: right;
    float: right;
    margin: 0;
`;
