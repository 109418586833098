import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabPages, { TabStyle } from '../TabBarPages';
import ListingOnFeed from '../ListingOnFeed';
import IconNew from '../IconNew';
import { ClearFloats } from '../BaseTemplate/Styled';
import { TabPage } from '../TabBarPages/Styled';
import { TabContent } from '../../../styles/Styled';
import { TabPageStyles } from './Styled';
import {
    PropertiesWrapperLeft,
    PropertiesWrapperRight,
    PropertyWrapper,
} from '../FeedPage/Styled';

class SimilarListings extends Component {
    render() {
        return (
            <TabContent>
                <TabPages theme={TabStyle.ListingsTab} defaultPageIndex={0}>
                    <TabPage title="Feed" styled={TabPageStyles}>
                        {this.props.listings.map(
                            (listing, index) => {
                                if (index % 4 !== 3) {
                                    return (
                                        <PropertiesWrapperLeft>
                                            <PropertyWrapper>
                                                <ListingOnFeed
                                                    id={listing.pk}
                                                    type={listing.type}
                                                    listing={listing}
                                                />
                                            </PropertyWrapper>
                                        </PropertiesWrapperLeft>
                                    );
                                } else {
                                    return (
                                        <PropertiesWrapperRight>
                                            <PropertyWrapper>
                                                <ListingOnFeed
                                                    id={listing.pk}
                                                    type={listing.type}
                                                    listing={listing}
                                                />
                                            </PropertyWrapper>
                                        </PropertiesWrapperRight>
                                    );
                                }
                            }
                        )}

                        <ClearFloats />
                    </TabPage>
                    <TabPage title="Active Listings">
                        <p>
                            Active Listings Page
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </TabPage>
                    <TabPage title="Closed">
                        <p>
                            Closed Page
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </TabPage>
                    <TabPage title="Shared Listings">
                        <p>
                            Shared Listings Page
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </TabPage>
                    <TabPage
                        titleIcon={
                            <IconNew
                                bp1="-135"
                                bp2="-113"
                                iwidth="35"
                                iheight="35"
                            />
                        }
                    >
                        <p>
                            Search
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </TabPage>
                </TabPages>
            </TabContent>
        );
    }
}

export default SimilarListings;

SimilarListings.propTypes = {
    listings: PropTypes.any.isRequired,
};
