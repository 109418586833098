import styled, { css } from 'styled-components';
import { InputTextWrapperStyles } from '../LoginForm/Styled';

export const InputTextTagStyles = css`
    width: 100%;
    height: 55px;
    font-family: ${(props) => props.theme.mainFont};
    font-size: 23px;
    line-height: 23px;
    color: ${(props) => props.theme.textBlack};
    border: none;
    padding: 0 0 12px;
    background: transparent;
    &:focus {
        border-bottom-color: ${(props) => props.theme.cyan};
    }
    &::placeholder {
        color: ${(props) => props.theme.mainFontColor};
    }
    padding-left: 45px;
`;

export const InputTextTagWrapperStyles = css`
    ${InputTextWrapperStyles};
    float: left;
    margin-bottom: 0;
    width: 500px;
    span {
        position: absolute;
        left: 0;
        bottom: 1px;
    }
`;

export const TabPageStyles = css`
    margin-top: 10px;
    padding: 0 20px;
`;

export const SearchTags = styled.div`
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    float: left;
`;

export const SearchTag = styled.span`
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    padding: 2px 16px 3px 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.darkBlue};
    background: ${(props) => props.theme.lightestBlue};
    border-radius: 3px;
    position: relative;
    span {
        position: relative;
        top: 2px;
        margin-right: 5px;
    }
    i {
        position: absolute;
        right: 4px;
        top: 7px;
        width: 8px;
        height: 8px;
        cursor: pointer;
    }
    i:before,
    i:after {
        position: absolute;
        left: 3px;
        content: ' ';
        height: 8px;
        width: 1px;
        background-color: ${(props) => props.theme.darkBlue};
    }
    i:before {
        transform: rotate(45deg);
    }
    i:after {
        transform: rotate(-45deg);
    }
`;
