import styled from "styled-components";
import {ArrowDown} from "../BaseTemplate/Styled";

export const ArrowDownExpandButton = styled(ArrowDown)`
    margin: 0;

    @media ${props => props.theme.mediaHD} {
        right: 4px;
    }
`;

export const Wrapper = styled.div`
    margin-top: 25px;
    padding-bottom: 30px;
    height: ${props => (!props.isExpand) ? props.height : '40px'};
    border-bottom: solid 1px #e6e6e6;
    font-family: 'Montserrat', sans-serif;
    line-height: 28px;
    transition: all .2s ease;
    overflow: hidden;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        height: 15px;
        background: #fff;
        left: 0; right: 0; bottom: 0;
    }
    h2 {
        font-size: 24px;
        color: rgb(25,37,124);
        margin: 0;
        padding: 5px 0;
    }
    h2:hover {
        cursor: pointer;
    }
    h2 span {
        font-size: 22px;
        color: rgb(186,190,216);
        margin-left: 5px;
    }
    p {
        font-size: 21px;
        line-height: 35px;
        color: rgb(121,122,128);
        margin: 10px 0 0;
    }

    @media ${props => props.theme.mediaHD} {
        h2 {
            font-size: 17px;
            padding: 10px 0;
        }
        h2 span {
            font-size: 14px;
        }

        p {
            font-size: 14px;
        }
    }
`;

export const ExpandButton = styled.button`
    width: 28px; height: 84px;
    border-radius: 5px;
    background: rgb(223,223,223);
    border: 0 none;
    float: right;
    margin: 8px 0 0 10px;
    &:hover {
        background: #ccc;
        cursor: pointer;
    }

    @media ${props => props.theme.mediaHD} {
        width: 20px; height: 59px;
    }
`;

export const Arrow = styled(ArrowDown)`
    margin-left: 0;
    &:after, &:before {
        background-color: rgb(25,37,124);
        transition: none;
        width: 0.6rem;
    }
    ${
        props => (props.isExpand)
            ? ' top: 2px; ' +
            ' &:after { ' +
                ' left: auto; ' +
                ' top: 1px; ' +
                ' -webkit-transform: rotate(45deg); ' +
                ' transform: rotate(45deg) ' +
            ' } ' +
            ' &:before { ' +
                ' right: auto; ' +
                ' bottom: 0; ' +
                ' -webkit-transform: rotate(-45deg); ' +
                ' transform: rotate(-45deg) ' +
            ' } '
            : ''
    }
    
`;
export const HeadButton = styled.button`
    height: 42px;
    padding: 0 12px;
    line-height: 40px;
    background: #fff;
    border: solid 1px rgb(178,178,181);
    border-radius: 5px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    float: right;
    margin-top: -5px;
    span {
        position: relative;
        top: 4px;
        margin-left: 7px !important;
    }
    &:hover {
        border-color: rgb(25,37,124);
        cursor: pointer;
    }
`;

export const Image = styled.img`
    width: 80px; height: 80px;
    float: left;
    margin-right: 15px;
    border-radius: 5px;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const ImageSmall = styled(Image)`
    width: 70px; height: 70px;
    margin-left: 20px;
    margin-top: 5px;
`;

export const ImageBadge = styled(Image)`
    width: 70px; height: 78px;
    margin-left: 15px;
`;

export const LinkItem = styled.span`
    display: block;
    &:first-of-type {
        margin-top: 10px;
    }
    strong {
        display: inline-block;
        width: 85px;
    }
    a {
        color: rgb(178,178,181);
        text-decoration: underline;
    }
    a:hover {
        color: #999;
    }
`;