import React, {Component} from 'react';
import {Wrapper, TreeItem, TreeText, PDF, TreeContent, TreeArrow, RootContent, RootContentWrapper, UrlLink} from "./Styled";
import Icon from "../Icon";

class ProfileGroupTypeInfoTree extends Component {
    constructor(props) {
        super(props);

        const itemsArr = [],
            itemsHeight = [];

        // Do Some Calculations for Tree Items Height
        // Because CSS 'transition' just worked with Fixed Height (Not Auto)
        props.items.forEach(
            item => {
                const filesRowCount = Math.ceil(item.files.length / 6),
                    itemHeight =
                        (filesRowCount >= 2)
                            ? (this.treeItemDefaultHeight * filesRowCount) +
                            ((filesRowCount - 1) * this.treeItemExtraPadding)
                            : this.treeItemDefaultHeight;
                itemsArr.push(false);
                itemsHeight.push(itemHeight);
            }
        );

        this.state = {
            isTreeItemsExpand: [...itemsArr],
            isTreeRootExpand: true,
            treeRootHeight: this.treeRootDefaultHeight,
            treeItemsHeight: [...itemsHeight]
        };
    }

    treeRootDefaultHeight = 100;
    treeItemDefaultHeight = 80;
    treeItemExtraPadding = 20;

    treeRootClickHandler = () => {
        // It is more safe to use prevState instead of direct use
        // Because setState runs ASYNC
        this.setState((prevState, props) => {
            return {isTreeRootExpand: !prevState.isTreeRootExpand};
        });
    };

    treeItemClickHandler = (index) => {
        // It is more safe to use prevState instead of direct use
        // Because setState runs ASYNC
        this.setState((prevState, props) => {
            const prevStateTreeItemsExpandCloned = [
                ...prevState.isTreeItemsExpand
            ];
            prevStateTreeItemsExpandCloned[index] = !prevStateTreeItemsExpandCloned[index];

            // Set TreeRoot Height
            let newTreeRootHeight = this.treeRootDefaultHeight;
            prevStateTreeItemsExpandCloned.forEach(
                (item, index) =>
                    (item) ?
                        newTreeRootHeight += prevState.treeItemsHeight[index]
                        : 0
            );
            const expandedItemsCount = prevStateTreeItemsExpandCloned.filter(x => x).length,
                rootExtraHeight = (expandedItemsCount - 1) * this.treeItemExtraPadding;
            newTreeRootHeight += rootExtraHeight;

            return {
                isTreeItemsExpand: prevStateTreeItemsExpandCloned,
                treeRootHeight: newTreeRootHeight
            };
        });
    };

    render() {
        return (
            <Wrapper>
                <RootContentWrapper>
                    <TreeArrow isExpand={!this.state.isTreeRootExpand}/>
                    <TreeText onClick={this.treeRootClickHandler}>
                        {this.props.title}
                    </TreeText>
                    <RootContent isExpand={this.state.isTreeRootExpand} height={this.state.treeRootHeight}>
                        {
                            this.props.items.map(
                                (item, index) =>
                                    <TreeItem key={'treeItem-'+index}>
                                        <TreeArrow isExpand={!this.state.isTreeItemsExpand[index]}/>
                                        <TreeText
                                            onClick={() => this.treeItemClickHandler(index)}>{item.name}</TreeText>
                                        <TreeContent
                                            isExpand={this.state.isTreeItemsExpand[index]}
                                            height={this.state.treeItemsHeight[index]}
                                        >
                                            {
                                                item.files.map(
                                                    (file, innerIndex) => {
                                                        switch (file.type) {
                                                            case FileType.PDF:
                                                                return (
                                                                    <PDF
                                                                        key={'pdf-'+index+'-'+innerIndex}
                                                                        to={file.to}
                                                                    >
                                                                        <Icon bp1="-113" bp2="-1716" iwidth="47"
                                                                              iheight="47"/>
                                                                        <br/>
                                                                        {file.name}
                                                                    </PDF>
                                                                );
                                                            case FileType.LINK:
                                                                return(
                                                                    <UrlLink key={'url-'+index+'-'+innerIndex} to={file.to} target="_blank" rel="noopener noreferrer" >{file.name}</UrlLink>
                                                                )
                                                            default:
                                                                return '';
                                                        }
                                                    }
                                                )
                                            }
                                        </TreeContent>
                                    </TreeItem>
                            )
                        }
                    </RootContent>
                </RootContentWrapper>
            </Wrapper>
        );
    }
}

export default ProfileGroupTypeInfoTree;

export const FileType = {
    PDF: 'pdf',
    LINK: 'link',
};