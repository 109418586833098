import styled, { css } from 'styled-components';
import { ArrowRight } from '../BaseTemplate/Styled';

export const ProfileMenuVerificationWrapper = styled.div`
    padding: 30px;
`;

export const ProfileVerificationHeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ProfileMenuVerificationHeader = styled.span`
    font-size: 22px;
    font-weight: bold;
    color: ${(props) => props.theme.deepBlue};
`;

export const BackButton = styled.div`
    cursor: pointer;
    i {
        top: -7px;
    }
`;

export const VerificationStepsWrapper = styled.div`
    height: 61px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #cccccc;
    justify-content: space-between;
    width: 100%;
`;

export const VerificationStepsRightSide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const VerificationStepsLeftSide = styled.div`
    display: flex;
`;

export const StepWrapper = styled.div`
    overflow: hidden;
    line-height: 0;
    display: flex;
    flex-direction: column;
    span:empty {
        display: none;
    }
`;

export const VerifyButton = styled.div`
    position: relative;
    line-height: 0;
    display: flex;
    margin-left: 17px;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
`;

export const VerificationStatusHeader = styled.div`
    color: ${(props) => props.theme.darkBlue};
    font-size: 18px;
    font-weight: bold;
`;

export const VerifyButtonText = styled.button`
    font-size: 13px;
    font-weight: bold;
    color: ${(props) => props.theme.cyan};
    height: 17px;
    line-height: initial;
    border: none;
    background: transparent;
    cursor: pointer;
`;

export const StepTop = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: #2e2e33;
    height: 21px;
    line-height: initial;
`;

export const PendingWrapper = styled.div`
    background: ${(props) => props.theme.tagYellow};
    color: white;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    width: 62px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StepBottom = styled.span`
    font-size: 14px;
    font-weight: 300;
    color: ${(props) => props.theme.mainFontColor};
    height: 17px;
    line-height: initial;
`;

export const ArrowRightWrap = styled(ArrowRight)`
    margin: 0 0 -13px;
    height: 25px;
`;

export const ModalWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 16px;
        color: ${(props) => props.theme.mainFontColor};
        margin-bottom: 50px;
    }

    h6 {
        font-size: 16px;
        color: ${(props) => props.theme.mainFontColor};
        margin: 0;
        margin-bottom: 15px;
        display: inline-block;

        span {
            border-radius: 5px;
            padding: 1px 5px;
            margin-right: 5px;
            background: linear-gradient(
                90deg,
                ${(props) => props.theme.cyan} 0%,
                ${(props) => props.theme.ceruleanBlue} 100%
            );
            font-size: 16px;
            color: ${(props) => props.theme.white};
        }
    }
`;

export const ModalHeader = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.coolGray};
`;

export const VerificationWrapper = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 18px;
`;

export const ModalTitleStyles = css`
    font-size: 20px;
    font-weight: bold;
    height: 73.5px;
    margin: 0 !important;
    box-sizing: border-box;
    padding: 25px;
    border: solid 1px ${(props) => props.theme.grayBorderLight};
`;

export const VerifyButtonStyles = css`
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding-bottom: 6px;
    margin-top: 30px;
    border-radius: 5px;
    color: #fff;
    border: none;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        90deg,
        ${(props) => props.theme.cyan} 0%,
        ${(props) => props.theme.ceruleanBlue} 100%
    );
`;

export const OKButtonStyles = css`
    width: 100%;
    height: 44px;
    font-size: 16px;
    padding-bottom: 6px;
    margin-top: 30px;
    border-radius: 5px;
    color: #fff;
    border: none;
    background: ${(props) => props.theme.cyan};
    background: linear-gradient(
        90deg,
        ${(props) => props.theme.cyan} 0%,
        ${(props) => props.theme.ceruleanBlue} 100%
    );
`;

export const ModalCloseStyles = css`
    top: 30px;
    right: 25.5px;
    width: 14px;
    height: 14px;
    &:before,
    &:after {
        top: 0;
        left: 9px;
        width: 2px;
        height: 20px;
    }
`;

export const IDModalWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const IdModalTop = styled.div`
    height: 61.5px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px ${(props) => props.theme.grayBorderLight};
    padding-left: 24.5px;
    padding-right: 24.5px;
`;

export const IdModalBottom = styled.div`
    height: 61.5px;
    display: flex;
    align-items: center;
    padding-left: 24.5px;
    padding-right: 24.5px;
`;

export const IDModalText = styled.div`
    font-size: 18px;
    color: #808080;
    margin-left: 20px;
`;
