import React, { Component, Fragment } from 'react';
import { Theme, GlobalStyle } from './Styled';
import Header from '../Header';
import Footer from '../Footer';
import NoticeCompleteProfile from '../NoticeCompleteProfile';
// import InvitationHeader from '../InvitationHeader/InvitationHeader';
// import invitationHeaderStore from '../../../stores/invitationHeaderStore';

class BaseTemplateNew extends Component {
    constructor(props) {
        super(props);

        this.theme = {
            mediaFullHD: '(min-width : 1824px)',
            mediaHD: '(min-width : 1360px) and (max-width: 1823px)',
            mediaTabletLandscape:
                '(min-width : 1021px) and (max-width : 1359px)',
            mediaTablet: '(min-width : 768px) and (max-width : 1020px)',
            mediaMobile:
                '(min-device-width : 320px) and (max-device-width : 767px)',
            white: '#fff',
            mainFont: '"Montserrat", sans-serif',
            mainFontColor: '#797A80',
            bodyBg: '#EAEAEA',
            darkBlue: '#19257C',
            lightBlue: '#F3F4F8',
            lighterBlue: '#EBECF5',
            lightestBlue: '#EDEDF7',
            darkerBlue: '#06063A',
            darkerBlueBg: '#121C65',
            cyan: '#07AED0',
            cyanDark: '#106BA6',
            lightCyan: '#F2FBFD',
            lightCyan2: '#07AED00D',
            graySkeletonAnim: '#F3F3F3',
            grayLight: '#D9D9D9',
            grayLightBg: '#EBEBEB',
            grayLightBgInput: '#D4D4D4',
            grayLighter: '#F0F0F0',
            grayLine: '#BABBBF',
            grayLineLight: '#E6E6E6',
            grayLineLightDark: '#B2B2B2',
            grayCircle: '#B2B2B5',
            grayBorder: '#E8E8E8',
            grayBorderLight: '#E5E5E5',
            grayBorderFooter: '#C7C7C7',
            grayDarkBorder: '#707070',
            grayBg: '#E9E9E9',
            grayBgBig: '#F7F7F7',
            grayBgInput: '#F2F2F2',
            grayBgButton: '#AFAFB3',
            grayish: '#B3B3B3',
            taupeGray: '#84858a',
            coolGray: '#aaabb3',
            textBlack: '#313136',
            textBlueGray: '#8B90BF',
            textGrayLight: '#BCBCBC',
            textGrayLightInput: '#C1C1C1',
            textGrayLightPlaceholder: '#C4C4C4',
            statusGreen: '#278D31',
            counterBg: '#F15A21',
            googleRed: '#F84F45',
            facebookBlue: '#5D85C7',
            errorMessage: '#F44336',
            noticeRed: '#DD3C39',
            noticeRedLight: '#DD3C3922',
            noticeGreen: '#19C656',
            noticeYellow: '#FFB42B',
            tagYellow: '#F3D610',
            lipstickRed: '#DD1435',
            lightRed: '#DD14350D',
            purple: '#b90796',
            lightPurple: '#b907960D',
            violet: '#7106c4',
            lightViolet: '#7106c40D',
            ceruleanBlue: '#094fd0',
            lightCeruleanBlue: '#094fd00D',
            viridianGreen: '#1a928d',
            lightViridianGreen: '#1a928d0D',
            squash: '#eeb51c',
            culturedGray: '#f5f5f5',
            blue: '#0952d0',
            paleGray: '#f1f1f2',
            indigo: '#550493',
            cheeseYellow: '#efc726',
            orchid: '#ab5ce3',
            vividPurple: '#9443d2',
            darkPink: '#b90795',
            deepBlue: '#182280',
            vividCyan: '#09aacf',
        };

        this.state = {
            homeType: 'feed',
            showRoadMap: false,
        };

        this.showHeader = true;
        this.innerHeader = true;
        this.showFooter = true;
        this.showSearchBar = false;
        this.showNotice = false;
        this.bodyBg = this.theme.bodyBg;
        //this.invitationHeaderType = invitationHeaderStore.getHeaderType();
    }

    componentDidMount() {
        // this.setState({
        //     hasInvitationHeader: invitationHeaderStore.getHasHeader(),
        // });
    }

    toggleRoadMap() {
        this.setState((prevState) => {
            return { showRoadMap: !prevState.showRoadMap };
        });
    }

    renderHeader() {
        return (
            <Header
                showSearchBar={this.showSearchBar}
                innerHeader={this.innerHeader}
                changeHomeType={(homeType) => this.setState({ homeType })}
                homeType={this.state.homeType}
            />
        );
    }

    renderFooter() {
        return <Footer />;
    }

    renderNotice() {
        return <NoticeCompleteProfile />;
    }

    // renderInvitationHeader() {
    //     return (
    //         <InvitationHeader
    //             type={this.invitationHeaderType}
    //             onCloseHeader={() => {
    //                 this.setState({ hasInvitationHeader: false });
    //                 invitationHeaderStore.setHasHeader(false);
    //                 this.setState({
    //                     hasInvitationHeader: invitationHeaderStore.getHasHeader(),
    //                 });
    //             }}
    //         />
    //     );
    // }

    render() {
        return (
            <Theme theme={this.theme}>
                <Fragment>
                    {this.showNotice && this.renderNotice()}
                    {this.showHeader && this.renderHeader()}
                    {/* {this.state.hasInvitationHeader &&
                        this.renderInvitationHeader()} */}
                    {this.renderBody()}
                    {this.showFooter && this.renderFooter()}
                    <GlobalStyle bodyBg={this.bodyBg} />
                </Fragment>
            </Theme>
        );
    }
}

export default BaseTemplateNew;
