import {types} from 'mobx-state-tree';

const roadMapStore = types

    .model({
        showRoadMap: types.boolean,
    })

    .views(self => ({
        getShowRoadMap() {
            return self.showRoadMap;
        },
    }))

    .actions(self => ({
        setShowRoadMap(bool) {
            self.showRoadMap = bool;
        }
    }))

    .create({
        showRoadMap: false,
    });

export default roadMapStore;