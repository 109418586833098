import styled from 'styled-components';

export const ProfileMenuVaultTabWrapper = styled.div`
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
`;

export const ProfileMenuVaultTabTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const ImgStyle = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 100px;
`;

export const ImageWrapper = styled.div`
    width: 160px;
    height: 160px;
    border-radius: 100px;
    position: relative;
`;

export const LevelText = styled.div`
    width: 50px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #07aed0;
    border: 2px solid white;
    color: white;
    font-size: 15px;
    font-family: 'Montserrat';
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
`;

export const TopImageWrapper = styled.div`
    height: 303px;
    width: 303px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.grayDarkBorder};
    justify-content: center;
    position: relative;
    margin-top: -20px;
    box-sizing: border-box;

    i {
        height: 315px;
        width: 315px;
        border-radius: 100%;
        position: absolute;
        left: -5px;
        top: -5px;
        background-image: linear-gradient(to top, #ffffffff 40%, #ffffff00);
    }
`;

export const MoreInfoButton = styled.button`
    position: absolute;
    right: 22px;
    top: 67px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #07AED033;
    border: none;
    padding: 0;

    ::before {
        content: '?';
        color: ${(props) => props.theme.cyan};
        height: 36px;
        width: 36px;
        border-radius: 18px;
        border: 2px solid ${(props) => props.theme.cyan};
        box-sizing: border-box;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ProfileMenuVaultTabProfileDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
`;

export const ProgessMenuVaulttabProgressBarSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ProfileMenuVaulttabProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 32px;
    border-bottom: 1px solid ${(props) => props.theme.textGrayLight};
`;

export const ProgressBarLevel = styled.span`
    color: ${(props) => props.theme.viridianGreen};
    font-size: 14px;
    font-weight: lighter;
`;

export const ProgressBar = styled.div`
    width: 90.75%;
    height: 5px;
    background-color: #e3e3e6;
    position: relative;
    border-radius: 3px;

    div {
        background: transparent
            linear-gradient(
                90deg,
                #1a928d 0%,
                #07aed0 35%,
                #b90796 88%,
                #dd1435 100%
            )
            0% 0% no-repeat padding-box;
        position: absolute;
        height: 100%;
        left: 0;
        bottom: 0;
        top: 0;
        width: ${(props) => props.width && props.width}%;
        border-radius: 3px;
    }
`;

export const ProgressBarNextLevel = styled.span`
    color: ${(props) => props.theme.noticeRed};
    font-size: 14px;
    font-weight: lighter;
`;

export const ActiveProgressBar = styled.div`
    background: transparent
        linear-gradient(
            90deg,
            #1a928d 0%,
            #07aed0 35%,
            #b90796 88%,
            #dd1435 100%
        )
        0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    width: ${(props) => props.width && props.width}%;
    border-radius: 3px;
`;

export const XpLeftText = styled.span`
    color: ${(props) => props.theme.textBlack};
    font-size: 13px;
    font-weight: lighter;
`;

export const ProgressText = styled.span`
    color: ${(props) => props.theme.textBlack};
    font-size: 14px;
    font-weight: lighter;
`;

export const InlineXpText = styled.span`
    color: ${(props) => props.theme.textGrayLight};
    font-size: 14px;
    font-weight: lighter;
`;

export const ProfileMenuVaultInvatationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 29px;
`;

export const ProfileMenuVaultInvatationHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
`;
export const ProfileMenuVaultInvatationHeaderLeft = styled.div`
    color: ${(props) => props.theme.textBlack};
    font-size: 18px;
    width: 30%;
`;

export const ProfileMenuVaultInvatationHeaderRight = styled.div`
    color: #07aed0;
    font-size: 13px;
    cursor: pointer;
`;

export const InvatationAmountText = styled.span`
    color: #19257c;
    opacity: 0.5;
    font-size: 14px;
    margin-left: 5%;
    font-weight: lighter;
`;

export const ProfileMenuVaultInvatationBodyWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
`;

export const InvatationCardWrapper = styled.div`
    display: flex;
    flex-basis: 16.6666666667%;
    flex-direction: column;
    align-items: flex-start;
/*     margin-left:${props => props.everySixth ? '0px' : '20px'}; */
`;

export const ImageStyle = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 10px;
`;

export const InvatationName = styled.span`
    font-weight: lighter;
    font-size: 12px;
`;

export const ExtendButtonWrapper = styled.button`
    width: 50px;
    height: 50px;
    display: ${(props) => props.zeroInvatations && 'none'};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.textGrayLight};
    color: ${(props) => props.theme.mainFontColor};
    font-size: 14px;
    background-color: transparent;
    font-weight: lighter;
    cursor:pointer;
`;

export const InviteCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    margin-top: 15px;
    height: 50px;
    position: relative;

    ::after {
        width: 15px;
        height: 15px;
        content: '';
        background: #fff;
        border-radius: 50%;
        position: absolute;
        right: -8px;
        box-shadow: inset 1px 0 1px 0 #00000029;
        border: none;
    }
    ::before {
        width: 15px;
        height: 15px;
        content: '';
        background: #fff;
        border-radius: 50%;
        position: absolute;
        left: -8px;
        box-shadow: inset -1px 0 1px 0 #00000029;
        border: none;
    }
`;
export const InviteCardLeftSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    background: ${(props) => props.BgColor && props.BgColor};
    color: white;
    font-size: 16px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    height: 100%;
`;
export const InviteCardRightSide = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 20px;
    padding-left: 20px;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    flex: 5;
    height: 100%;
    justify-content: space-between;
`;
export const InvatationHeaderText = styled.div`
    font-weight: lighter;
    font-size: 18px;
    color: ${(props) => props.textColor && props.textColor};
`;
