import styled, { css } from 'styled-components';
import { AppLink } from '../../../styles/Styled';
import { ArrowDown } from '../BaseTemplate/Styled';
import { IconButton } from '../../../styles/Styled';

export const HeaderWrapper = styled.header`
    width: ${(props) => (props.innerHeader ? '1260px' : '1810px')};
    height: 140px;
    margin: 0 auto;
    ${(props) => props.innerHeader && 'padding-right: 500px'};

    @media ${(props) => props.theme.mediaHD} {
        width: ${(props) => (props.innerHeader ? '830px' : '1274px')};
        ${(props) => props.innerHeader && 'padding-right: 440px'};
    }
`;

export const LogoLink = styled(AppLink)`
    float: left;
    margin: 25px auto;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    width: 770px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    float: right;
`;

export const Button = styled.button`
    display: flex;
    flex-direction: column;
    border: none;
    background: transparent;
    margin-top: 20px;
    align-items: center;

    > div {
        float: right;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        ${props => props.selected && `border: 1px solid ${props.theme.darkBlue}`};
    }

    p {
        font-size: 12px;
        color: ${props => props.selected ? props.theme.darkBlue :props.theme.mainFontColor};
        font-family: 'Montserrat';
    }
`;

export const InputSearchWrapper = styled.label`
    position: relative;
    margin: 20px 0 0;
    width: 630px;
    height: 80px;
    box-sizing: border-box;
    z-index: ${(props) => (props.open ? 103 : 2)};
    .icon {
        position: absolute;
        z-index: 4;
        top: 20px;
        left: 20px;
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 400px;
    }
`;

export const InputSearchStyles = css`
    display: block;
    width: 630px;
    height: 60px;
    padding: 20px 25px 20px 50px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    color: ${(props) => props.theme.textBlack};
    &::placeholder {
        color: ${(props) => props.theme.mainFontColor};
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 400px;
    }
`;

export const InputSearchOpenStyles = css`
    display: block;
    width: 630px;
    height: 60px;
    padding: 20px 25px 20px 50px;
    border-radius: 10px 10px 0 0; /* The only changed style */
    border: none;
    font-size: 18px;
    color: ${(props) => props.theme.textBlack};
    &::placeholder {
        color: ${(props) => props.theme.mainFontColor};
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 400px;
    }
`;

export const ButtonInput = styled.button`
    height: 37px;
    font-family: ${(props) => props.theme.mainFont};
    font-size: 25px;
    color: ${(props) => props.theme.mainFontColor};
    border: none;
    border-left: solid 2px ${(props) => props.theme.grayLine};
    background: transparent;
    position: absolute;
    top: 25px;
    right: 30px;
    box-sizing: border-box;
    padding: 0 0 0 25px;
    &:hover {
        cursor: pointer;
    }
`;

export const ButtonInputCategories = styled(ButtonInput)`
    font-size: 18px;
    color: ${(props) => props.theme.grayLine};
    border-left: none;
    right: 30px;
    top: 12px;
    padding: 0 30px 0 25px;
`;

export const ButtonArrowDown = styled(ArrowDown)`
    position: absolute;
    top: 12px;
    right: 0;
    width: 1.1rem;
    &:after,
    &:before {
        width: 0.7rem;
        background-color: ${(props) => props.theme.grayLine};
    }
`;

export const ChangeCategory = styled.div`
    width: 137px;
    border: 1px solid;
    border-color: ${(props) => props.theme.grayLight};
    border-radius: 5px;
    background: white;
    z-index: 10;
    position: absolute;
    right: 15px;
    display: ${(props) => (props.show ? 'block' : 'none')};

    button {
        background: transparent;
        border: 0;
        width: 137px;
        margin: 0;
        font-size: 18px;
        color: ${(props) => props.theme.mainFontColor};
        padding-left: 10px;
        border-bottom: 1px solid;
        border-color: ${(props) => props.theme.grayBorder};
        padding-bottom: 10px;
        cursor: pointer;
        :last-of-type {
            border: 0;
        }
    }
`;

export const SearchDrop = styled.div`
    position: absolute;
    box-sizing: border-box;
    border-width: 2px 0 0 0;
    border-color: ${(props) => props.theme.grayBorder};
    border-style: solid;
    z-index: 103;
    display: ${(props) => (props.open ? 'block' : 'none')};
    width: 630px;
    background: white;
    float: right;
    margin: 0;
    border-radius: 0 0 10px 10px;
    padding: 0 25px 30px;
    top: 60px;

    h3 {
        font-size: 24px;
        color: ${(props) => props.theme.textBlack};
    }

    div {
        border-width: 0 0 2px 0;
        border-color: ${(props) => props.theme.grayBorder};
        border-style: solid;
        padding-bottom: 10px;
        position: relative;

        :last-of-type {
            border: none;
        }

        p {
            font-size: 20px;
            color: ${(props) => props.theme.mainFontColor};
            float: left;
            display: block;
            width: 100%;
            margin: 0;
        }
        span {
            background: ${(props) => props.theme.lightestBlue};
            padding: 2px 5px;
            color: ${(props) => props.theme.darkBlue};
            float: left;
            border-radius: 3px;
            margin-right: 8px;
            margin-bottom: 10px;
        }
    }

    @media ${(props) => props.theme.mediaHD} {
        width: 400px;
        left: 0;
    }
`;

export const BlackLayer = styled.div`
    position: absolute;
    display: ${(props) => (props.show ? 'block' : 'none')};
    background: ${(props) => props.theme.textBlack};
    opacity: 0.6;
    width: 200vw;
    height: 1000vh;
    z-index: 101;
    left: 0;
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: 15px;
    right: 0;
    width: 18px;
    height: 18px;
    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 11px;
        width: 3px;
        height: 15px;
        background-color: ${(props) => props.theme.taupeGray};
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    ${(props) => props.styled};
`;
