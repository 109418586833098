import styled, { css } from 'styled-components';




export const ProfileMenuSendInvatationWrapper = styled.div`
padding-left:50px;
padding-right:30px;
overflow-y:scroll;
height:100%;
`;

export const InviteText = styled.div`
line-height:26px;
font-size:16px;
color:${(props) => props.theme.mainFontColor};
margin-top:40px;
 `;

export const ContactListButtonWrapper = styled.button`
width:100%;
display:flex;
align-items:center;
justify-content:center;
background-color:transparent;
color:#D12E7E;
font-size:14px;
border:2px solid #D12E7E;
height:55px;
border-radius:5px;
cursor:pointer;
margin-top:40px;
`;

export const FacebookButtonWrapper = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
background-color:transparent;
color:#19257C;
font-size:14px;
border:2px solid #19257C;
height:55px;
border-radius:5px;
margin-top:30px;
cursor:pointer;
`;

export const EmailButtonWrapper = styled.button`
width:100%;
display:flex;
align-items:center;
cursor:pointer;
justify-content:center;
background-color:${props => props.active ? '#07AED0' : 'transparent'}; 
color:#07AED0;
color:${props => props.active ? 'white' : ' #07AED0'}; 
font-size:14px;
border:2px solid  #07AED0;
height:55px;
border-radius:5px;
margin-top:30px;
`;

export const ButtonsWrapper = styled.div`

`;

export const InputWrapper = styled.input`
font-weight:lighter;
font-size:14px;
color: ${(props) => props.theme.textGrayLight};
border:none;
border-bottom:1px solid ${(props) => props.theme.textGrayLight};
width:100%;
height:26px;
margin-top:29px;

::placeholder,
  ::-webkit-input-placeholder {
    font-weight:lighter;
    font-size:14px;
    color: ${(props) => props.theme.textGrayLight}; 
  }
`;

export const EmailCardWrapper = styled.div`
background-color: ${(props) => props.theme.grayBgInput};
color: ${(props) => props.theme.textBlack};
font-size:12px;
padding-left:10px;
padding-right:10px;
margin-right:9px;
display:flex;
align-items:center;
margin-top:10px;
`;

export const EmailCardContainer = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:center;
justify-content:flex-start;
margin-top:30px;
`;

export const InviteButtonWrapper = styled.button`
width:100%;
display:flex;
align-items:center;
cursor:pointer;
justify-content:center;
background:${props => props.active ? 'transparent linear-gradient(270deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box' :  `${(props) => props.theme.paleGray}`};
color:${props => props.active ? 'white' :  '#797A80'};
font-size:14px;
height:55px;
border-radius:5px;
margin-top:30px;
border:none;
`;

export const InviteInstructionWrapper = styled.div`
padding:15px;
background:rgba(221, 60, 57, 0.05);
margin-top:30px;
border-radius: 10px;
`;

export const InviteHeader = styled.div`
color: ${(props) => props.theme.noticeRed};
font-size:16px;
line-height:27px;
`;

export const InviteInstructionText = styled.div`
color: ${(props) => props.theme.textBlack};
font-size:14px;
line-height:22px;
`;

export const ContactcardWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
margin-top:23px;
`;

export const ContactCardSide = styled.div`
display:flex;
flex-direction:row;
align-items:center;
`;

export const ContactImage = styled.img`
width:40px;
height:40px;
border-radius:100px;
`;

export const ContactTextWrapper = styled.div`
display:flex;
flex-direction:column;
align-items:flex-start;
margin-left:8px;
`;

export const ContactName = styled.span`
color: ${(props) => props.theme.textBlack};
font-size:14px;
line-height:24px;
padding:0;
margin:0;
display:inline-block;

`;

export const ContactMail = styled.span`
font-weight:lighter;
font-size:12px;
color: ${(props) => props.theme.textGrayLight};
line-height:20px;
display:inline-block;
padding:0;
margin:0;
`;

export const Circle = styled.div`
width:23px;
height:23px;
border:${props => props.active ? `1px solid transparent` : `1px solid #797A80`};
border-radius:100px;
background:${props => props.active  ? 'transparent linear-gradient(134deg, #07AED0 0%, #106BA6 100%) 0% 0% no-repeat padding-box' : 'transparent'};
`;

export const AddContactButton = styled.button`
width:100%;
display:flex;
align-items:center;
cursor:pointer;
justify-content:center;
background: ${(props) => props.theme.paleGray};
color: ${(props) => props.theme.textBlack};
font-size:16px;
height:55px;
border-radius:5px;
margin-top:30px;
border:none;
`;

export const Invitebutton = styled.button`
width:100%;
display:flex;
align-items:center;
cursor:pointer;
padding-left:20px;
padding-right:20px;
justify-content:space-between;
background:${props => props.active ? 'transparent linear-gradient(270deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box' :  `${(props) => props.theme.paleGray}`};
color: white;
font-size:18px;
height:55px;
border-radius:5px;
margin-top:30px;
border:none;
`;

export const SendInvatationButton = styled.button`
width:100%;
display:flex;
align-items:center;
cursor:pointer;
padding-left:20px;
padding-right:20px;
justify-content:center;
background: transparent linear-gradient(270deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box;
color: white;
font-size:18px;
height:55px;
border-radius:5px;
margin-top:30px;
border:none;
`;

export const InvatationTextWrapper = styled.div`
margin-top:30px;
border-top:dashed 2px ${(props) => props.theme.textGrayLight};
padding-top:20px;
`;

export const InvatationTextInstruction = styled.span`
color:${(props) => props.theme.textGrayLight};
font-size:16px;
`;

export const InvtationText = styled.div`
border:1px solid #07AED0;
border-radius:20px;
padding-left:20px;
padding-right:10px;
padding-top:12px;
display:flex;
flex-direction:column;
align-items:flex-start;
padding-bottom:20px;

span {
white-space: pre-line;
font-size:14px;
line-height:20px;
color:${(props) => props.theme.textBlack};
};

span.link-text {
white-space: pre-line;
font-size:14px;
line-height:20px;
cursor:pointer;
color:#2C80F5;
}

`;

export const NameColor = styled.span`
color:${(props) => props.theme.darkerBlueBg};
`;


export const FirstModalStyle = css`
color:${(props) => props.theme.mainFontColor};
`;

export const NoButtonWrapper = styled.button`
font-size:18px;
border:none;
color:${(props) => props.theme.textBlack};
border-bottom: 1px solid ${(props) => props.theme.graydarkBorder};
background:transparent;
width:80px;
cursor:pointer;
height:24.5px;
`;

export const FirstModalButtonWrapper = styled.div`
display:flex;
flex-direction:row;
padding-left:10px;
padding-right:10px;
align-items:center;
justify-content:space-around;
`;

export const YesButtonWrapper = styled.button`
border:none;
background: transparent linear-gradient(90deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 8px #00000033;
border-radius: 5px;
width: 205px;
height: 50px;
color:white;
font-size:18px;
cursor:pointer;
`;

export const ThirdModalWrapper = styled.div`
display:flex;
flex-direction:column;
padding-left:10px;
padding-right:10px;
align-items:center;
`;

export const ModalNoteHeader = styled.span`
color: ${(props) => props.theme.noticeRed};
font-size:12px;
line-height:22spx;

`;

export const ModalNote = styled.div`
color: ${(props) => props.theme.textBlack};
font-size:12px;
line-height:22px;
`;

export const FinishButtonWrapper = styled.button`
width:100%;
display:flex;
align-items:center;
cursor:pointer;
padding-left:20px;
padding-right:20px;
justify-content:center;
background: transparent linear-gradient(90deg, #07AED0 0%, #094FD0 100%) 0% 0% no-repeat padding-box;
color: white;
font-size:18px;
height:55px;
border-radius:5px;
margin-top:30px;
border:none;
`;