import styled from "styled-components";
import {IconButton} from "../../../styles/Styled";

export const BookmarkButton = styled(IconButton)`
    ${
        props => !(props.inline) &&
            `
            position: absolute;
            top: ${props.top ? (props.top) : (props.big ? '-9' : '-5')}px;
            ${
                (props.left)
                    ? ` left: ${props.left}px; `
                    :
                    (props.right)
                        ? ` right: ${props.right}px; `
                        : ` right: 6px; `
            };
            `
    };
    ${
        props => (props.inline) &&
            ` float: right; `
    };
    span.bookmark {
        cursor: pointer;
        top: 5px;
        margin-right: 2px;
    }
    img.bookmark {
        top: 6px;
        ${
            props => (props.inline) &&
                ` margin-right: 2px; `
        };
    }
`;