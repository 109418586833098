import React, { Component } from 'react';
import { TabPageStyles } from './Styled';
import SocialConnections from '../SocialConnections';
import IconNew from '../IconNew';
import ProfileMenuTab, { TabStyle } from '../TabBarPages';
import { TabPage } from '../TabBarPages/Styled';
import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';
import ProfileMenuContent from '../ProfileMenuContent/ProfileMenuContent';

class ProfileMenuNetworkTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupLists: [],
        };
        this.groups = [
            {
                id: 1001,
                title: 'My Connections',
                list: [
                    {
                        id: 2001,
                        fullname: 'Sebastien Irving',
                        img: '/ServerImages/social_profile_photo_sample_1.png',
                        profession: 'Real Estate Agent',
                    },
                    {
                        id: 2002,
                        fullname: 'Sebastien Irving',
                        img: '/ServerImages/social_profile_photo_sample_2.png',
                        profession: 'Appraiser',
                    },
                ],
            },
            {
                id: 1002,
                title: 'User Suggestions',
                list: [
                    {
                        id: 2003,
                        fullname: 'Sebastien Irving',
                        img: '/ServerImages/social_profile_photo_sample_1.png',
                        profession: 'Real Estate Broker',
                    },
                    {
                        id: 2004,
                        fullname: 'Sebastien Irving',
                        img: '/ServerImages/social_profile_photo_sample_2.png',
                        profession: 'Appraiser',
                    },
                ],
            },
            {
                id: 1003,
                title: 'Followers',
                list: [
                    {
                        id: 2005,
                        fullname: 'Sebastien Irving',
                        img: '/ServerImages/social_profile_photo_sample_1.png',
                        profession: 'Lender',
                    },
                    {
                        id: 2006,
                        fullname: 'Sebastien Irving',
                        img: '/ServerImages/social_profile_photo_sample_2.png',
                        profession: 'Real Estate Broker',
                    },
                ],
            },
        ];
    }

    componentDidMount() {
        const groupLists = [];
        this.groups.forEach((result) => {
            groupLists.push(
                <SocialConnections
                    key={result.id}
                    title={result.title}
                    connections={result.list}
                    seeMore
                />
            );
        });
        this.setState({ groupLists });
    }

    render() {
        return (
            <ProfileMenuContent>
                <ProfileMenuTab
                    theme={TabStyle.ProfileMenuTab}
                    defaultPageIndex={0}
                >
                    <TabPage
                        key={'tabPage-1'}
                        title="People"
                        titleIcon={
                            <IconNew
                                className="titleIcon"
                                bp1="0"
                                bp2="-144"
                                iwidth="48"
                                iheight="48"
                            />
                        }
                        styled={TabPageStyles}
                    >
                        {this.state.groupLists.length === 0 && (
                            <SkeletonLoading
                                type={SkeletonLoadingType.MENU_GROUPS}
                            />
                        )}
                        {this.state.groupLists.length !== 0 &&
                            this.state.groupLists}
                    </TabPage>
                    <TabPage
                        key={'tabPage-2'}
                        title="Groups"
                        titleIcon={
                            <IconNew
                                className="titleIcon"
                                bp1="-49"
                                bp2="-140"
                                iwidth="45"
                                iheight="29"
                            />
                        }
                        styled={TabPageStyles}
                    >
                        <div style={{ padding: '30px' }}>Groups Tab</div>
                    </TabPage>
                    <TabPage
                        key={'tabPage-3'}
                        title="Requests"
                        titleIcon={
                            <IconNew
                                className="titleIcon"
                                bp1="-95"
                                bp2="-140"
                                iwidth="36"
                                iheight="35"
                            />
                        }
                        styled={TabPageStyles}
                    >
                        <div style={{ padding: '30px' }}>Requests Tab</div>
                    </TabPage>
                </ProfileMenuTab>
            </ProfileMenuContent>
        );
    }
}

export default ProfileMenuNetworkTab;
