import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg
            width={184.409}
            height={38.43}
            viewBox="0 0 184.409 38.43"
            {...props}
        >
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={0.869}
                    y1={0.074}
                    x2={0.077}
                    y2={0.944}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#07aed0" />
                    <stop offset={1} stopColor="#094fd0" />
                </linearGradient>
            </defs>
            <g data-name="Group 1859">
                <text
                    data-name="Better Connected social network"
                    transform="translate(38.408 19)"
                    fill="#07aed0"
                    fontSize={19}
                    fontFamily="Lato-Light, Lato"
                    fontWeight={300}
                >
                    <tspan x={0} y={0}>
                        {'Better Connected'}
                    </tspan>
                    <tspan fill="#272727" fontSize={11}>
                        <tspan x={0} y={15}>
                            {'social network'}
                        </tspan>
                    </tspan>
                </text>
                <g data-name="Group 1812">
                    <path
                        data-name="Subtraction 21"
                        d="M6.835 6.336h7.69a6.831 6.831 0 016.836 6.836v14.525H6.835a6.83 6.83 0 01-6.836-6.835v-7.69a6.83 6.83 0 016.836-6.836zm12.355 19.19V13.881a5.38 5.38 0 00-5.374-5.374h-6.27a5.381 5.381 0 00-5.375 5.374v6.271a5.381 5.381 0 005.375 5.374H19.19z"
                        fill="#19257c"
                    />
                    <path
                        data-name="Subtraction 22"
                        d="M14.526 21.361H6.835A6.83 6.83 0 010 14.525V0h14.526a6.83 6.83 0 016.836 6.835v7.69a6.83 6.83 0 01-6.836 6.836zM2.171 10.616v3.2a5.38 5.38 0 005.374 5.374h6.27a5.38 5.38 0 005.375-5.374v-6.27a5.381 5.381 0 00-5.375-5.374H2.171v6.27H0v2.175z"
                        transform="translate(11.503 17.069)"
                        fill="url(#prefix__a)"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
