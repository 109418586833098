import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Icons"
      x={0}
      y={0}
      width={35}
      height={35}
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st2{fill:none;stroke:white;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="prefix__st2"
        d="M16 29c-5 0-9-4-9-9V9c0-3.3 2.7-6 6-6h6c3.3 0 6 2.7 6 6v11c0 5-4 9-9 9zM16 3v5"
      />
      <path
        className="prefix__st2"
        d="M16 15c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
      />
    </svg>
  )
}

export default SvgComponent
