import React, {Component} from 'react';
import {CSSTransition} from 'react-transition-group';
import {Wrapper, Container, CloseButton, Title} from './Styled';

export default class Modal extends Component {

    setContainerRef = (node) => {
        this.containerRef = node;
    };

    handleWrapperClick = (event) => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            if (typeof this.props.handleClose == 'function') {
                this.props.handleClose();
            }
        }
    };

    render() {
        return (
            <CSSTransition
                in={this.props.isOpen}
                classNames="animated"
                timeout={300}
            >

                <Wrapper
                    isOpen={this.props.isOpen}
                    onClick={this.handleWrapperClick}
                    height={this.props.containerHeight}
                >

                    <Container
                        ref={this.setContainerRef}
                        containerWidth={this.props.containerWidth}
                        containerSizeUnit={this.props.containerSizeUnit}
                        containerPadding={this.props.containerPadding}
                        containerHeight={this.props.containerHeight}
                        className={this.props.isOpen ? 'active' : ''}
                    >
                        <Title
                            titlePadding={this.props.titlePadding}
                            styled={this.props.titleStyled}
                            simple={(this.props.simple)}
                            headerMargin={'0px'}
                        >
                            {this.props.title}
                            <CloseButton
                                onClick={this.props.handleClose}
                                styled={this.props.closeStyled}
                            />
                        </Title>
                        {
                            this.props.children
                        }
                    </Container>

                </Wrapper>

            </CSSTransition>
        )
    }
}