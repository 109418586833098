import styled from "styled-components";

export const SwitchBox = styled.label`
    position: relative;
    display: inline-block;
    line-height: 18px;
    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    i em {
        width: 40px;
        height: 19px;
        position: absolute;
        cursor: pointer;
        top: 1px; right: 0;
        background-color: ${props => props.theme.grayLightBgInput};
        transition: .1s;
        border-radius: 30px;
    }
    i em:before {
        position: absolute;
        content: "";
        height: 11px;
        width: 11px;
        left: 4px;
        bottom: 4px;
        background-color: #fff;
        transition: .4s;
        border-radius: 50%;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    }
    input:checked + i em {
        background-color: ${props => props.theme.cyan};
    }
    input:checked + i em:before {
        transform: translateX(21px);
    }
    ${
        props => props.styled
    };
`;