import React, { Component } from 'react';
import { Wrapper, CategoryTitle, Arrow, SubTitle } from './Styled';

class SearchFilterCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPop: false,
            open: false,
        };
    }

    render() {
        return (
            <Wrapper>
                <CategoryTitle>{this.props.title}</CategoryTitle>
                {!this.props.noArrow && <Arrow
                    open={this.state.open}
                    onClick={() =>
                        this.setState((prevState) => {
                            return {
                                open: !prevState.open,
                            };
                        })
                    }
                />}
                <SubTitle>{this.props.subTitle}</SubTitle>
                {this.props.children}
            </Wrapper>
        );
    }
}

export default SearchFilterCategory;
