import styled from "styled-components";

export const RadioButton = styled.label`
    display: inline-block;    
    position: relative;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    text-align: center;
    box-sizing: border-box;
    margin-right: 25px;
    &:last-of-type {
        margin-right: 0;
    }
    input {
        position: absolute;
        opacity: 0;
        height: 0; width: 0;
        cursor: pointer;
    }
    i {
        display: inline-block;
        font-style: normal;
        box-sizing: border-box;
        width: 215px; height: 60px;
        border: solid 2px ${props => props.theme.textGrayLight};
        margin: 0 2px;
        padding-top: 10px;
        border-radius: 5px;
        user-select: none;
    }
    input:checked ~ i {
        color: ${props => props.theme.cyan};
        border: solid 3px ${props => props.theme.cyan};
        margin: 0;
        padding-top: 9px;
    }
    em {
        position: relative;
        top: 4px;
        margin-right: 7px;
        display: inline-block;
        width: 21px; height: 21px;
        border: solid 1px ${props => props.theme.mainFontColor};
        border-radius: 50%;
    }
    input:checked ~ i em {
        background-color: #fff;
    }
    em:before,
    em:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ i em:before,
    input:checked ~ i em:after {
        display: block;
    }
    em:before {
        top: -1px; left: -1px;
        bottom: -1px; right: -1px;
        border-radius: 50%;
        background: ${props => props.theme.cyan};
    }
    em:after {
        top: 45%; left: 50%;
        transform: translate(-50%,-50%) rotate(45deg);
        height: 8px; width: 4px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
    }
    ${
        props => props.styled
    };
`;