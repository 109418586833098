import React, { Component } from 'react';
import {
    ProfileImageWrapper,
    InputWrapper,
    InputTextStyle,
    Line,
    SectionTitle,
    ModalWrapper,
    InputListStyles,
    ButtonWrapper,
    VerifyButton,
} from './Styled';
import { Wrapper } from '../ProfileMenuList/Styled';
import {
    InputLabelAnimateDynamicStyles,
    InputLabelAfterAnimateDynamicStyles,
    InputTextDynamicStyles,
} from '../FormDynamicField/Styled';
import {
    VerificationWrapper,
    ModalHeader,
} from '../ProfileMenuVerification/Styled';
import ProfileMenuContent from '../ProfileMenuContent';
import InputText from '../FormInputText';
import InputList from '../FormInputList';
import InputAddressAdd from '../FormInputAddressAdd';
import ChangeShowStatusPop from '../ChangeShowStatusPop';
import IconNew from '../IconNew';
import { ModalTitleStyles, ModalCloseStyles } from '../SharedListing/Styled';
import Modal from '../Modal';
import VerificationInput from '../VerificationInput';
// import { sendAsyncRequest } from '../../../Utils';
// import SkeletonLoading, { SkeletonLoadingType } from '../SkeletonLoading';

class ProfileMenuEditProfile extends Component {
    constructor(props) {
        super(props);
        this.types = ['Public', 'Hidden', 'Followers', 'Connections'];
        this.fakeUser = {
            info: {
                name: 'Samantha Smith',
                username: 'samantasmith69928',
                birthday: '08 March 1984',
                birthdayShow: 'Public',
                address: 'Metrotech Center,Brooklyn NY 11201,USA',
                addressShow: 'Conections',
                website: 'samantasmithrealty.com',
                websiteShow: 'Followers',
                aboutMe: 'Lorem ipsum dolor sit ...',
                gender: 'Prefer not to say',
            },
            contact: {
                accountEmail: 'Samantha.smith@gmail...',
                accountEmailVerified: true,
                profileEmail: 's.smith@yoursite.com',
                profileEmailVerified: false,
                profileEmailShow: 'Followers',
                accountPhone: '001 558 99833',
                accountPhoneIsVerified: false,
                profilePhone: '001 500 99800',
                profilePhoneVerified: true,
                profilePhoneShow: 'Hidden',
            },
            feed: {
                interestedIn: [
                    {
                        value: 1,
                        text: 'Residential',
                    },
                    {
                        value: 5,
                        text: 'Land',
                    },
                ],
                areaOfInterest: [
                    {
                        item: {
                            subText: '122583 NY, Manhattan',
                            postal_code: '145323',
                            city: 'Manhattan',
                        },
                    },
                ],
                areaOfInterestShow: 'Public',
                role: 'Real Estate Agent',
                expertise: [
                    {
                        value: 1,
                        text: 'For Rent',
                    },
                ],
            },
            pictures: {
                photosNumber: 18,
                photosShow: 'Public',
                certificatesNumber: 6,
                certificatesShow: 'Public',
                documentsNumber: 0,
                documentsShow: 'Hidden',
            },
        };
        this.state = {
            loading: false,
            modalOpen: false,
            modalTitle: '',
            verificationType: 3,  //1 for normal and 2 for buisness
            expertiesList: [
                { text: 'For Rent', value: 1 },
                { text: 'Villa', value: 2 },
                { text: 'Luxury', value: 3 },
            ],
            interestedInList: [
                { text: 'Residential', value: 1 },
                { text: 'Rental', value: 2 },
                { text: 'Villa', value: 3 },
                { text: 'Commercial', value: 4 },
                { text: 'Land', value: 5 },
                { text: 'Apartment', value: 6 },
                { text: 'Houses', value: 7 },
                { text: 'Townhomes', value: 8 },
            ],
            user: {
                info: {
                    name: '',
                    username: '',
                    birthday: '',
                    birthdayShow: '',
                    address: '',
                    addressShow: '',
                    website: '',
                    websiteShow: '',
                    aboutMe: '',
                    gender: '',
                },
                contact: {
                    accountEmail: '',
                    accountEmailVerified: true,
                    profileEmail: '',
                    profileEmailVerified: true,
                    profileEmailShow: '',
                    accountPhone: '',
                    accountPhoneVerified: true,
                    profilePhone: '',
                    profilePhoneVerified: true,
                    profilePhoneShow: '',
                },
                feed: {
                    interestedIn: [],
                    areaOfInterest: [],
                    areaOfInterestShow: '',
                    role: '',
                    expertise: [],
                },
                pictures: {
                    photosNumber: 0,
                    photosShow: '',
                    certificatesNumber: 0,
                    certificatesShow: '',
                    documentsNumber: 0,
                    documentsShow: '',
                },
            },
        };
    }

    componentDidMount() {
        this.setState({ user: this.fakeUser });
    }

    handleVerificationFill = () => {
        this.setState({ modalOpen: false });
    };

    handleInputChange(e, group, field) {
        let groupState = { ...this.state.user[group] };
        groupState[field] = e.target.value;
        let user = { ...this.state.user };
        user[group] = groupState;
        this.setState({ user });
    }

    handleShowChange = (group, field, newValue) => {
        let groupState = { ...this.state.user[group] };
        groupState[field] = newValue;
        let user = { ...this.state.user };
        user[group] = groupState;
        this.setState({ user });
    };

    modalClickHandler = () => {
        this.setState({ modalOpen: false });
    };

    render() {
        // if (!this.state.loading) {
        //     return <SkeletonLoading type={SkeletonLoadingType.EDIT_PROFILE} />;
        // }
        let modalComponent;
        switch (this.state.modalTitle) {
            case 'Verification':
                modalComponent = (
                    <>
                        <ModalHeader>
                            Please enter the verification code
                        </ModalHeader>
                        <VerificationWrapper>
                            <VerificationInput
                                length={6}
                                number={this.state.user.contact.accountPhone}
                                type={this.state.verificationType}
                                onComplete={this.handleVerificationFill}
                            />
                        </VerificationWrapper>
                    </>
                );
                break;
            case 'My Expertise':
                modalComponent = (
                    <InputList
                        id={'Expertise'}
                        type="text"
                        name={'Expertise'}
                        styled={InputListStyles}
                        labelAnimate="Search"
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        subTitle={'Please select from the list below'}
                        list={this.state.expertiesList}
                        required
                        value={this.state.user.feed.expertise}
                        onChangeSelected={(name, selectedItems) => {
                            this.setState((prevState) => {
                                return {
                                    user: {
                                        ...prevState.user,
                                        feed: {
                                            ...prevState.user.feed,
                                            expertise: selectedItems,
                                        },
                                    },
                                };
                            });
                        }}
                    />
                );
                break;
            case 'Area of Interest':
                modalComponent = (
                    <InputAddressAdd
                        id={'areaOfInterest'}
                        type="text"
                        name={'areaOfInterest'}
                        styled={InputTextDynamicStyles}
                        required={true}
                        value={this.state.user.feed.areaOfInterest}
                        onChangeSelected={(tags) => {
                            console.log(tags);
                            this.setState((prevState) => {
                                return {
                                    user: {
                                        ...prevState.user,
                                        feed: {
                                            ...prevState.user.feed,
                                            areaOfInterest: tags,
                                        },
                                    },
                                };
                            });
                            console.log(this.state);
                        }}
                        country_lock={null}
                        country_lock_value={null}
                    />
                );
                break;
            case 'I am Interested in':
                modalComponent = (
                    <InputList
                        id={'interested'}
                        type="text"
                        name={'interested'}
                        styled={InputListStyles}
                        labelAnimate="Search"
                        labelAnimateStyled={InputLabelAnimateDynamicStyles}
                        labelAfterAnimateStyled={
                            InputLabelAfterAnimateDynamicStyles
                        }
                        subTitle={
                            'Select your interest from the list and add it here'
                        }
                        list={this.state.interestedInList}
                        required
                        value={this.state.user.feed.interestedIn}
                        onChangeSelected={(name, selectedItems) => {
                            this.setState((prevState) => {
                                return {
                                    user: {
                                        ...prevState.user,
                                        feed: {
                                            ...prevState.user.feed,
                                            interestedIn: selectedItems,
                                        },
                                    },
                                };
                            });
                        }}
                    />
                );
                break;
            default:
                modalComponent = <div />;
        }
        return (
            <>
                <ProfileMenuContent
                    title="Edit Profile"
                    onTitleClick={this.props.backButton}
                >
                    <Wrapper>
                        <ProfileImageWrapper>
                            <img
                                src={'/assets/img/temp/profile_pic_sample.png'}
                                alt={'Profile'}
                            />
                            <button>Change Profile Picture</button>
                            <IconNew
                                className="icon"
                                bp1="-24"
                                bp2="-196"
                                iwidth="24"
                                iheight="14"
                            />
                        </ProfileImageWrapper>
                        <InputWrapper>
                            <p>Name</p>
                            <InputText
                                id="Name"
                                type="text"
                                name="Name"
                                value={this.state.user.info.name}
                                onInput={(e) =>
                                    this.handleInputChange(e, 'info', 'name')
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop showType={'Public'} disabled />
                        </InputWrapper>
                        <InputWrapper disabled>
                            <p>Username</p>
                            <InputText
                                id="Username"
                                type="text"
                                name="Username"
                                value={this.state.user.info.username}
                                onInput={(e) =>
                                    this.handleInputChange(
                                        e,
                                        'info',
                                        'username'
                                    )
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop showType={'Public'} disabled />
                        </InputWrapper>
                        <InputWrapper>
                            <p>Birthday</p>
                            <InputText
                                id="Birthday"
                                type="text"
                                name="Birthday"
                                value={this.state.user.info.birthday}
                                onInput={(e) =>
                                    this.handleInputChange(
                                        e,
                                        'info',
                                        'birthday'
                                    )
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop
                                showType={this.state.user.info.birthdayShow}
                                showChange={this.handleShowChange}
                                group={'info'}
                                field={'birthdayShow'}
                                types={this.types}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <p>Address</p>
                            <InputText
                                id="Address"
                                type="text"
                                name="Address"
                                value={this.state.user.info.address}
                                onInput={(e) =>
                                    this.handleInputChange(e, 'info', 'address')
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop
                                showType={this.state.user.info.addressShow}
                                showChange={this.handleShowChange}
                                group={'info'}
                                field={'addressShow'}
                                types={this.types}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <p>Website</p>
                            <InputText
                                id="Website"
                                type="text"
                                name="Website"
                                value={this.state.user.info.website}
                                onInput={(e) =>
                                    this.handleInputChange(e, 'info', 'website')
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop
                                showType={this.state.user.info.websiteShow}
                                showChange={this.handleShowChange}
                                group={'info'}
                                field={'websiteShow'}
                                types={this.types}
                            />
                        </InputWrapper>
                        <InputWrapper disabled>
                            <p>About Me</p>
                            <InputText
                                id="About Me"
                                type="text"
                                name="About Me"
                                value={this.state.user.info.aboutMe}
                                onInput={(e) =>
                                    this.handleInputChange(e, 'info', 'aboutMe')
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop showType={'Public'} disabled />
                        </InputWrapper>
                        <InputWrapper disabled>
                            <p>Gender</p>
                            <InputText
                                id="Gender"
                                type="text"
                                name="Gender"
                                value={this.state.user.info.gender}
                                onInput={(e) =>
                                    this.handleInputChange(e, 'info', 'gender')
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop showType={'Hidden'} disabled />
                        </InputWrapper>

                        <Line />

                        <SectionTitle>Contact Information</SectionTitle>
                        <InputWrapper disabled>
                            <p>Account Email</p>
                            {this.state.user.contact.accountEmailVerified ? (
                                <IconNew
                                    className="icon"
                                    bp1="-502"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="24"
                                />
                            ) : (
                                <IconNew
                                    className="icon"
                                    bp1="-478"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="21"
                                />
                            )}
                            <InputText
                                id="AccountEmail"
                                type="text"
                                name="AccountEmail"
                                value={this.state.user.contact.accountEmail}
                                onInput={(e) =>
                                    this.handleInputChange(
                                        e,
                                        'contact',
                                        'accountEmail'
                                    )
                                }
                                required
                                styled={InputTextStyle}
                            >
                                {!this.state.user.contact
                                    .accountEmailVerified && (
                                    <VerifyButton>Verify</VerifyButton>
                                )}
                            </InputText>
                            <ChangeShowStatusPop showType={'Hidden'} disabled />
                        </InputWrapper>
                        <InputWrapper>
                            <p>Profile Email</p>
                            {this.state.user.contact.profileEmailVerified ? (
                                <IconNew
                                    className="icon"
                                    bp1="-502"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="24"
                                />
                            ) : (
                                <IconNew
                                    className="icon"
                                    bp1="-478"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="21"
                                />
                            )}
                            <InputText
                                id="Profile Email"
                                type="text"
                                name="Profile Email"
                                value={this.state.user.contact.profileEmail}
                                onInput={(e) =>
                                    this.handleInputChange(
                                        e,
                                        'contact',
                                        'profileEmail'
                                    )
                                }
                                required
                                styled={InputTextStyle}
                            >
                                {!this.state.user.contact
                                    .profileEmailVerified && (
                                    <VerifyButton>Verify</VerifyButton>
                                )}
                            </InputText>
                            <ChangeShowStatusPop
                                showType={
                                    this.state.user.contact.profileEmailShow
                                }
                                showChange={this.handleShowChange}
                                group={'contact'}
                                field={'profileEmailShow'}
                                types={this.types}
                            />
                        </InputWrapper>
                        <InputWrapper disabled>
                            <p>Account Phone</p>
                            {this.state.user.contact.accountPhoneIsVerified ? (
                                <IconNew
                                    className="icon"
                                    bp1="-502"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="24"
                                />
                            ) : (
                                <IconNew
                                    className="icon"
                                    bp1="-478"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="21"
                                />
                            )}
                            <InputText
                                id="Account Phone"
                                type="text"
                                name="Account Phone"
                                value={this.state.user.contact.accountPhone}
                                onInput={(e) =>
                                    this.handleInputChange(
                                        e,
                                        'contact',
                                        'accountPhone'
                                    )
                                }
                                required
                                styled={InputTextStyle}
                            >
                                {!this.state.user.contact
                                    .accountPhoneVerified && (
                                    <VerifyButton
                                        onClick={() => {
                                            this.setState({
                                                modalOpen: true,
                                                modalTitle: 'Verification',
                                                verificationType: 1,
                                            });
                                        }}
                                    >
                                        Verify
                                    </VerifyButton>
                                )}
                            </InputText>
                            <ChangeShowStatusPop showType={'Hidden'} disabled />
                        </InputWrapper>
                        <InputWrapper>
                            <p>Profile Phone</p>
                            {this.state.user.contact.profilePhoneVerified ? (
                                <IconNew
                                    className="icon"
                                    bp1="-502"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="24"
                                />
                            ) : (
                                <IconNew
                                    className="icon"
                                    bp1="-478"
                                    bp2="-75"
                                    iwidth="24"
                                    iheight="21"
                                />
                            )}
                            <InputText
                                id="ProfilePhone"
                                type="text"
                                name="ProfilePhone"
                                value={this.state.user.contact.profilePhone}
                                onInput={(e) =>
                                    this.handleInputChange(
                                        e,
                                        'contact',
                                        'profilePhone'
                                    )
                                }
                                required
                                styled={InputTextStyle}
                            >
                                {!this.state.user.contact
                                    .profilePhoneVerified && (
                                    <VerifyButton
                                        onClick={() => {
                                            this.setState({
                                                verificationType: 2,
                                                modalOpen: true,
                                                modalTitle: 'Verification',
                                            });
                                        }}
                                    >
                                        Verify
                                    </VerifyButton>
                                )}
                            </InputText>
                            <ChangeShowStatusPop
                                showType={
                                    this.state.user.contact.profilePhoneShow
                                }
                                showChange={this.handleShowChange}
                                group={'contact'}
                                field={'profilePhoneShow'}
                                types={this.types}
                            />
                        </InputWrapper>
                        <Line />
                        <SectionTitle>Feed Settings</SectionTitle>
                        <InputWrapper disabled>
                            <p>Interested in</p>
                            <InputText
                                id="InterestedIn"
                                type="text"
                                name="InterestedIn"
                                value={this.state.user.feed.interestedIn.map(
                                    (item) => item.text
                                )}
                                onFocus={() =>
                                    this.setState({
                                        modalOpen: true,
                                        modalTitle: 'I am Interested in',
                                    })
                                }
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop showType={'Hidden'} disabled />
                        </InputWrapper>
                        <InputWrapper>
                            <p>Area of Interest</p>
                            {
                                <InputText
                                    id="AreaOfInterest"
                                    type="text"
                                    name="AreaOfInterest"
                                    value={this.state.user.feed.areaOfInterest.map(
                                        (tag) =>
                                            (tag.item.city
                                                ? tag.item.city
                                                : tag.item.subText) +
                                            (tag.item.postal_code
                                                ? ', ' + tag.item.postal_code
                                                : '')
                                    )}
                                    required
                                    styled={InputTextStyle}
                                    onFocus={() =>
                                        this.setState({
                                            modalOpen: true,
                                            modalTitle: 'Area of Interest',
                                        })
                                    }
                                    readonly
                                />
                            }
                            <ChangeShowStatusPop
                                showType={
                                    this.state.user.feed.areaOfInterestShow
                                }
                                showChange={this.handleShowChange}
                                group={'feed'}
                                field={'areaOfInterestShow'}
                                types={this.types}
                            />
                        </InputWrapper>
                        <InputWrapper disabled>
                            <p>I am a</p>
                            <InputText
                                id="Role"
                                type="text"
                                name="Role"
                                value={this.state.user.feed.role}
                                //onInput={}
                                required
                                styled={InputTextStyle}
                            />
                            <ChangeShowStatusPop showType={'Public'} disabled />
                        </InputWrapper>
                        <InputWrapper disabled>
                            <p>My Expertise</p>
                            <InputText
                                id="MyExpertise"
                                type="text"
                                name="MyExpertise"
                                value={this.state.user.feed.expertise.map(
                                    (item) => item.text
                                )}
                                required
                                styled={InputTextStyle}
                                onFocus={() =>
                                    this.setState({
                                        modalOpen: true,
                                        modalTitle: 'My Expertise',
                                    })
                                }
                                readonly
                            />
                            <ChangeShowStatusPop showType={'Public'} disabled />
                        </InputWrapper>
                        <Line />
                        <ButtonWrapper>
                            <button className="btn">
                                <SectionTitle cursor={'pointer'}>
                                    Photos
                                </SectionTitle>
                                {this.state.user.pictures.photosNumber === 0 ? (
                                    <p>upload here</p>
                                ) : (
                                    <p>
                                        ({this.state.user.pictures.photosNumber}
                                        )
                                    </p>
                                )}
                            </button>
                            <ChangeShowStatusPop
                                showType={this.state.user.pictures.photosShow}
                                showChange={this.handleShowChange}
                                group={'pictures'}
                                field={'photosShow'}
                                types={this.types}
                            />
                        </ButtonWrapper>
                        <Line />
                        <ButtonWrapper>
                            <button className="btn">
                                <SectionTitle cursor={'pointer'}>
                                    Certificates
                                </SectionTitle>
                                {this.state.user.pictures.certificatesNumber
                                    .number === 0 ? (
                                    <p>upload here</p>
                                ) : (
                                    <p>
                                        (
                                        {
                                            this.state.user.pictures
                                                .certificatesNumber
                                        }
                                        )
                                    </p>
                                )}
                            </button>
                            <ChangeShowStatusPop
                                showType={
                                    this.state.user.pictures.certificatesShow
                                }
                                showChange={this.handleShowChange}
                                group={'pictures'}
                                field={'certificatesShow'}
                                types={this.types}
                            />
                        </ButtonWrapper>
                        <Line />
                        <ButtonWrapper>
                            <button className="btn">
                                <SectionTitle cursor={'pointer'}>
                                    Documents
                                </SectionTitle>
                                {this.state.user.pictures.documentsNumber ===
                                0 ? (
                                    <p>upload here</p>
                                ) : (
                                    <p>
                                        (
                                        {
                                            this.state.user.pictures
                                                .documentsNumber
                                        }
                                        )
                                    </p>
                                )}
                            </button>
                            <ChangeShowStatusPop
                                showType={
                                    this.state.user.pictures.documentsShow
                                }
                                showChange={this.handleShowChange}
                                group={'pictures'}
                                field={'documentsShow'}
                                types={this.types}
                            />
                        </ButtonWrapper>
                        <Line />
                        <ButtonWrapper>
                            <button className="btn">
                                <SectionTitle cursor={'pointer'}>
                                    Change Password
                                </SectionTitle>
                            </button>
                        </ButtonWrapper>
                        <Line />
                        <ButtonWrapper>
                            <button className="btn">
                                <SectionTitle
                                    cursor={'pointer'}
                                    color={'noticeRed'}
                                >
                                    Delete Account
                                </SectionTitle>
                            </button>
                        </ButtonWrapper>
                    </Wrapper>
                </ProfileMenuContent>
                <Modal
                    isOpen={this.state.modalOpen}
                    handleClose={this.modalClickHandler}
                    containerWidth="450"
                    containerHeight="auto"
                    containerSizeUnit="px"
                    containerPadding="0"
                    title={this.state.modalTitle}
                    titleStyled={ModalTitleStyles}
                    closeStyled={ModalCloseStyles}
                >
                    <ModalWrapper>{modalComponent}</ModalWrapper>
                </Modal>
            </>
        );
    }
}

export default ProfileMenuEditProfile;
