import React from 'react';
import {
    ProfileDetailWrapper
} from './Styled';

class ProfileMenuVaultTabProfileDetail extends React.Component {
    render() {
        const { icon, detail ,detailType } = this.props;
    return (
        <ProfileDetailWrapper>
            <div>
                {icon}
            </div>
            <span>
                {detail}
            </span>
            <span className='DetailType'>
                {detailType}
            </span>
        </ProfileDetailWrapper>
    );
    }
}  

export default ProfileMenuVaultTabProfileDetail;
