import React, { Component, Fragment } from 'react';
import { ActionButton } from './Styled';
import IconNew from '../IconNew';
import { ArrowDown } from '../BaseTemplate/Styled';

class ProfileGroupTypeActionButtons extends Component {
    render() {
        return (
            <Fragment>
                {this.props.contact && (
                    <ActionButton width={220} white>
                        <ArrowDown />
                        Contact Form
                        <IconNew
                            bp1="-60"
                            bp2="-96"
                            iwidth="22"
                            iheight="17"
                            className="contact"
                        />
                    </ActionButton>
                )}
                {this.props.call && (
                    <ActionButton>
                        Call
                        <IconNew
                            bp1="-98"
                            bp2="-77"
                            iwidth="17"
                            iheight="17"
                            className="call"
                        />
                    </ActionButton>
                )}
                {this.props.chat && (
                    <ActionButton>
                        Chat
                        <IconNew
                            bp1="-84"
                            bp2="-96"
                            iwidth="20"
                            iheight="19"
                            className="chat"
                        />
                    </ActionButton>
                )}
                {this.props.contactInfo && (
                    <ActionButton group={this.props.group}>
                        Contact Information
                        <IconNew
                            bp1="-60"
                            bp2="-96"
                            iwidth="22"
                            iheight="17"
                            className="contact"
                        />
                    </ActionButton>
                )}
            </Fragment>
        );
    }
}

export default ProfileGroupTypeActionButtons;
