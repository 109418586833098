import React from 'react';
import {
    UsercardContainerWrapper,
    UsercardLeftside,
    UsercardRightside,
    UserCardNameText,
    UserCardNumber,
    UserCardLeftSideTextContainer,
    UsercardImg,
    UserCardPosition,
    DateText
} from './Styled';

class ProfileMenuVaultInvatationTabusercardContainer extends React.Component {
    render() {
        const { data, type } = this.props
    return (
        <UserCardPosition>
            {data.map((item, index) => {
                return (
                    <UsercardContainerWrapper>
                        <UsercardLeftside>
                            <UsercardImg 
                                src={item.photo}
                                alt='photo'
                            />
                            <UserCardLeftSideTextContainer>
                                <UserCardNameText>
                                    {item.name}
                                </UserCardNameText>
                                <UserCardNumber>
                                    {item.number}
                                </UserCardNumber>
                            </UserCardLeftSideTextContainer>
                        </UsercardLeftside>
                        {
                            type === 'connected' && (
                                <UsercardRightside forConnected>
                                    <span>
                                        h
                                    </span>
                                </UsercardRightside>
                            )
                        }
                        {
                            type === 'pending' && (
                                <UsercardRightside>
                                    <DateText>
                                        {item.date}
                                    </DateText>
                                    <span>
                                        h
                                    </span>
                                    <span>
                                        h
                                    </span>
                                </UsercardRightside>
                            )
                        }
                    </UsercardContainerWrapper>
                )
                ;
            })}
    </UserCardPosition>
    );
        }
}  

export default ProfileMenuVaultInvatationTabusercardContainer;
