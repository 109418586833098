import styled, {css} from "styled-components";

export const Wrapper = styled.div`
    float: left;
    position: relative;
    width: 330px; height: 84px;
    margin-right: 30px;
    border: solid 1px ${props => props.theme.textGrayLight};
    box-sizing: border-box;
    border-radius: ${props => (props.vip)
        ? '15px 0 15px 0' : '15px 0 15px 15px'
    };
    h4 {
        margin: 12px 0 0;
        font-size: 20px;
        line-height: 28px;
        color: ${props => props.theme.cyan};
        small {
            display: block;
            font-size: 18px;
            font-weight: normal;
            color: ${props => props.theme.mainFontColor};
            span {
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
        }
    }
    i {
        width: 25px; height: 25px;
        position: absolute;
        top: -13px; right: 25px;
        border-radius: 5px;
        background: ${props => props.theme.darkBlue};
        box-sizing: border-box;
        text-align: center;
        span {
            position: relative;
            top: -5px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -4px; left: 8px;
            width: 0; height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid ${props => props.theme.darkBlue};
        }
    }
    em:after {
        font-style: normal;
        display: inline-block;
        position: absolute;
        top: 0; right: 3px;
        content: "\\2807";
        font-size: 25px;
        color: ${props => props.theme.grayCircle};
        width: 15px;
        cursor: pointer;
    }
`;

export const ImgWrapper = styled.div`
    float: left;
    margin: 3px 13px 3px 3px;
    padding: 3px;
    background: ${props => (props.vip)
        ? props.theme.darkBlue : 'transparent'
    };
    width: 70px; height: 70px;
    border-radius: ${props => (props.vip)
        ? '13px 13px 13px 0' : 0
    };
    img {
        width: 70px; height: 70px;
        border-radius: ${props => (props.vip)
            ? '10px 10px 10px 0' : '10px'
        };
    }
`;

export const CurvedButtonStyles = css`
    height: 55px;
    background: ${props => props.theme.grayBgInput};
    color: ${props => props.theme.textBlack};
    font-size: 22px;
    font-weight: bold;
    border: none;
    padding: 0 30px 0 60px;
    border-radius: 15px 0 15px 15px;
    margin: 14px 0 0 40px;
    position: relative;
    ${
        props => (props.noMargin) &&
            ` margin-left: 0; `
    };
    &:after, &:before {
        content: "";
        position: absolute;
        background: ${props => props.theme.textBlack};
        top: 50%;
        transform: translateY(-50%);
    }
    &:after {
        width: 19px; height: 3px;
        left: 25px;
    }
    &:before {
        width: 3px; height: 19px;
        left: 33px;
    }
`;