import React, {Component, Fragment} from 'react';
import InvatationSteps from '../InvatationSteps';

class BuildNetworkMain extends Component {
    render() {
        return(
            <Fragment>
               <InvatationSteps />
            </Fragment>
        )
    }
}

export default BuildNetworkMain;