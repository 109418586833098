import React from 'react';
import BaseTemplateNew from '../Modules/BaseTemplateNew';
import { Main } from '../../styles/Styled';
import ProfilePage from '../Modules/ProfilePage';
import ProfileMenu from '../Modules/ProfileMenu';
import BuildNetworkMain from '../Modules/BuildNetworkMain';
import roadMapStore from '../../stores/roadMapStore';
import GroupAlbumGallery from '../Modules/GroupAlbumGallery';
import { observer } from 'mobx-react';
import { ImageGallery } from './temporaryData';

class Profile extends BaseTemplateNew {
    constructor(props) {
        super(props);
        super.innerHeader = false;

        this.state = {
            isButtonClicked: false,
            activeTabIndex: 0,
            dynamicTitle: '',
        };

    };

    handleButtonClick = (index, title) => {
        let isButtonClicked = !this.state.isButtonClicked;
        this.setState({
            isButtonClicked,
            activeTabIndex: index,
            dynamicTitle: title,
        });
    };

    galleryTabClickHandler = (Tabindex, tabtitle) => {
        this.setState({
            activeTabIndex: Tabindex,
            dynamicTitle: tabtitle,
        });
    };

    renderBody() {
        return (
            <Main backgrounded>
                <ProfileMenu closed />
                {roadMapStore.getShowRoadMap() ? (
                    <BuildNetworkMain />
                ) : (
                    this.state.isButtonClicked === true ? 
                    <GroupAlbumGallery
                        imageGallery={ImageGallery}
                        dynamicTitle={this.state.dynamicTitle}
                        activeTabIndex={this.state.activeTabIndex}
                        defaultIndex={this.state.activeTabIndex}
                        handleButtonClick={this.handleButtonClick}
                    /> 
                    :
                    <ProfilePage 
                        activeTabIndex={this.state.activeTabIndex}
                        imageGallery={ImageGallery}
                        handleButtonClick={this.handleButtonClick} 
                    />
                )
                 }
            </Main>
        );
    }

    /*constructor(props){
        super(props);
        this.state = {
            userName:'',
            groupName:'',
        };    
    }
    
    UNSAFE_componentWillMount(){
        this.showFooter = false;
        this.pageBackgroundColor = 'white';
        
        this.setState({
            userName: this.props.match.params.username, 
            groupName: this.props.match.params.groupname, 
        });
        this.goToTop();
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.username && nextProps.match.params.username !== this.state.userName) {
            this.setState({
                userName: nextProps.match.params.username, 
                groupName: '',
            });
        }else if(nextProps.match.params.groupname && nextProps.match.params.groupname !== this.state.groupName){
            this.setState({
                userName: '', 
                groupName: nextProps.match.params.groupname,
            });         
        }
        
        this.goToTop();
    }
    
    goToTop = () => {
        window.scrollTo(0, 0);
    }
    
    renderBody() {
        return(
            <InsideConrainer>
                
                <ProfilePage 
                    username={this.state.userName} 
                    groupname={this.state.groupName}
                />
                
            </InsideConrainer>
        )
    }*/
}

export default observer(Profile);
