import React, {Component} from "react";
import {
    InputLabelAfterAnimateGroupStyles,
    InputLabelAnimateGroupStyles,
    InputTextGroupStyles,
    InputTextWrapperGroupStyles
} from "./Styled";
import InputText from "../FormInputText";
import IconNew from "../IconNew";
import {objIsEqual} from "../../../Utils";

class FormGroupContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValues: {}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && !objIsEqual(prevProps.value, this.props.value)) {
            this.setState({
                inputValues: this.props.value
            });
        }
    }

    inputChangeHandler = (e, name) => {
        const states = this.props.states,
              fieldName = this.props.id;
        states.forEach(
            that => {
                const isGlobalState = (that.constructor.name === "Object"),
                    fields = (isGlobalState) ? that.getFields() : {...that.state.fields},
                    errorFields = (isGlobalState) ? that.getErrorFields() : {...that.state.errorFields},
                    inputValues = {...this.state.inputValues};
                inputValues[name] = e.target.value;
                fields[fieldName] = inputValues;
                errorFields[fieldName] = null;
                this.setState({
                    inputValues
                });
                if (isGlobalState) {
                    that.setFields(fields);
                    that.setErrorFields(errorFields);
                } else {
                    if (!this.props.extraLocalStatesOnChange) {
                        that.setState({
                            fields,
                            errorFields
                        });
                    } else {
                        that.setState({
                            fields,
                            errorFields,
                            ...this.props.extraLocalStatesOnChange
                        });
                    }
                }
            }
        );
    }

    render() {
        return(
            <>
                <InputText
                    id="groupContactNumber"
                    name="groupContactNumber"
                    type="text"
                    styled={InputTextGroupStyles}
                    wrapperStyled={InputTextWrapperGroupStyles}
                    icon={<IconNew bp1="-370" bp2="-57" iwidth="18" iheight="18" />}
                    labelAnimate="Group Contact Number"
                    labelAnimateStyled={InputLabelAnimateGroupStyles}
                    labelAfterAnimateStyled={InputLabelAfterAnimateGroupStyles}
                    required={true}
                    value={this.state.inputValues.phone || ""}
                    onInput={
                        (e) => this.inputChangeHandler(e, "phone")
                    }
                />
                <InputText
                    id="groupContactEmail"
                    name="groupContactEmail"
                    type="text"
                    styled={InputTextGroupStyles}
                    wrapperStyled={InputTextWrapperGroupStyles}
                    icon={<IconNew bp1="-345" bp2="-57" iwidth="23" iheight="18" />}
                    labelAnimate="Group Contact Email"
                    labelAnimateStyled={InputLabelAnimateGroupStyles}
                    labelAfterAnimateStyled={InputLabelAfterAnimateGroupStyles}
                    required={true}
                    value={this.state.inputValues.email || ""}
                    onInput={
                        (e) => this.inputChangeHandler(e, "email")
                    }
                />
            </>
        )
    }
}

export default FormGroupContact;