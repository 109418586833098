import styled from 'styled-components';
import { AppLink } from '../../../styles/Styled';

export const SearchListingWrapperAll = styled.div`
    width: 400px;
    height: 235px;
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
    margin-bottom: 30px;

    p {
        font-size: 16px;
        line-height: 19px;
        color: ${(props) => props.theme.mainFontColor};
    }

    img {
        background: #eee;
        width: 166px;
        height: 166px;
        float: left;
        margin-right: 15px;
        margin-bottom: 30px;
    }

    .footer {
        width: 100%;
        padding: 0 30px;
        height: 35px;
        background: ${(props) => props.theme.grayBgBig};
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
                margin-right: 5px;
            }

            p {
                font-size: 14px;
                color: ${(props) => props.theme.textBlack};
                align-self: center;
            }

            em {
                display: inline-block;
                height: 20px;
                border-right: solid 1px ${(props) => props.theme.grayLineLight};
                margin: 0 25px;
            }

            :last-of-type {
                em {
                    border: none;
                }
            }
        }
    }
`;

export const ListingInfoWrapper = styled.div`
    float: left;
    font-size: ${(props) => (props.big ? '12' : '10')}px;
    span {
        text-transform: lowercase;
    }
    strong {
        font-size: ${(props) => (props.big ? '18' : '14')}px;
        ${(props) => (props.big ? ' margin-right: 3px; ' : '')};
    }
    em {
        display: inline-block;
        height: 10px;
        border-right: solid 1px ${(props) => props.theme.grayLineLight};
        margin: 0 ${(props) => (props.big ? '12' : '7')}px;
    }
`;

export const Tag = styled(AppLink)`
    margin-left: 8px;
`;

export const TagWrapper = styled.div`
    display: inline-block;
    float: ${(props) => (props.all ? 'left' : 'right')};
`;

export const SearchListingWrapper = styled.div`
    width: 400px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
    margin-bottom: 30px;

    img {
        width: 100%;
        height: 275px;
    }

    p {
        font-size: 16px;
        line-height: 19px;
        color: ${(props) => props.theme.mainFontColor};
        margin: 0 0 10px 0;
    }

    h6 {
        font-size: 14px;
        line-height: 17px;
        color: ${(props) => props.theme.mainFontColor};
        margin-top: 15px;
        float: left;
    }

    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 45px;
        height: 35px;
        background-image: ${(props) =>
            `linear-gradient(to right, ${props.theme.cyan}, ${
                props.theme.violet
            })`};
        opacity: 0.5;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
                margin-right: 5px;
            }

            p {
                font-size: 14px;
                margin-top: 8px;
                color: #fff;
                align-self: center;
            }

            em {
                display: inline-block;
                height: 20px;
                border-right: solid 1px ${(props) => props.theme.grayLineLight};
                margin: 0 25px;
            }

            :last-of-type {
                em {
                    border: none;
                }
            }
        }
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
`;

export const CardSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CardLowerSection = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LocationTextWrapper = styled.span`
    margin-left: 6.6px;
`;

export const CardMiddleSection = styled.div``;

export const LocationWrapper = styled.div``;
