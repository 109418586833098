import styled, {css} from "styled-components";
import {InputTextWrapperStyles} from "../LoginForm/Styled";

export const SearchProfilesWrapper = styled.div`
    height: 340px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 20px;
`;

export const SearchProfileItem = styled.div`
    border-bottom: 1px solid ${props => props.theme.grayBorder};
    padding: 10px 0;
    display: flex;
    position: relative;
    cursor: pointer;
    line-height: 25px;
    :last-of-type {
        border-bottom: 0;
    }
    img {
        width: 50px;
        height: 50px;
        border-radius: ${(props) => (props.vip ? '10px 10px 10px 0' : '10px')};
        border-width: ${(props) => (props.vip ? '2px' : '0')};
        border-color: ${(props) => props.theme.darkBlue};
        border-style: solid;
        margin-right: 10px;
    }
    p {
        font-size: 16px;
        color: ${(props) => props.theme.darkBlue};
        font-weight: bold;
        margin: 0;
    }
    small {
        font-size: 14px;
        color: ${(props) => props.theme.textGrayLight};
        margin: 0;
    }
    span {
        margin-right: 5px;
    }
`;

export const CheckBoxRowStyles = css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-55%);
`;

export const InputTextWrapperSearchStyles = css`
    ${InputTextWrapperStyles};
    height: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
`;