import React, { Component } from 'react';
import {
    ProfileDetailsWrapper,
    LeftSideWrapper,
    RightSideWrapper,
    ProfileNameStyle,
    ProfileOptions,
    ButtonsWrapper,
    ContactButtonStyle,
    ConversationButton,
    TagWrapper,
    TagsStyle,
    AddButton,
    ButtonNumber,
    ConversationSpan,
    MeetingSchedule,
    FollowUpWrap,
    LeftSideTopWrapper,
    FollowUpItem,
    LastFollowUpText,
    RemiderButtonStyle,
    AddNoteButtonStyle,
    HeaderWrapper,
    NoteWrapper,
    NoteHeader,
    DateHeader,
    DetailsWrapper,
    RightSideWrap,
    LeftSideWrap,
    SectionWrapper,
    TextWrapper,
    TextWrapperUser,
    NoteText,
    ComponentWrapper,
} from './Styled';
import FormButtonText from '../FormButtonText';
import { ArrowDown, ArrowRight } from '../BaseTemplate/Styled';
import IconNew from '../IconNew';
import { Column } from '../../../styles/Styled';
import { ClearFloats } from '../BaseTemplate/Styled';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import SimilarLeadManagementListings from '../SimilarLeadManagementListings';

export class LeadManagmentProfileDetails extends Component {
    constructor(props) {
        super(props);
        this.ProfileData = {
            UserName: {
                User: '@sara.coner.w86',
                color: '#07aed0',
            },
            Age: '360 Days',
            LastInteraction: '3 Days ago',
            TimeFrame: '35 days out of 180 days',
            MartialStatus: {
                StatusOptions: ['cool', 'not cool', 'normal'],
            },
            PropertyType: 'Apartment',
            ListingType: 'For Sale',
            Location: 'Brooklyn, NY',
            PriceRange: '$220k - $375K',
            PreApproved: 'Yes',
        };
        this.PotentialData = [
            {
                id: 1,
                price: '2,578,000',
                bath: '4',
                bed: '4',
                sqft: '2,150',
                location: '6438 S State 59 Rd, Clay City, IN 47841',
                photo: 'https://i.stack.imgur.com/34AD2.jpg',
            },
            {
                id: 2,
                price: '2,578,000',
                bath: '4',
                bed: '4',
                sqft: '2,150',
                location: '6438 S State 59 Rd, Clay City, IN 47841',
                photo: 'https://i.stack.imgur.com/34AD2.jpg',
            },
        ];
    }

    render() {
        const Data = this.props.CardDetails;
        return (
            <React.Fragment>
                <Column type="one_column_setting" padding_right first>
                    <ComponentWrapper>
                        <ProfileDetailsWrapper>
                            <LeftSideWrapper>
                                <LeftSideTopWrapper>
                                    <img
                                        src={Data.details.photo}
                                        alt="profile"
                                    />
                                    <ProfileOptions>
                                        <ProfileNameStyle>
                                            {Data.details.name}
                                        </ProfileNameStyle>
                                        <IconNew
                                            bp1="-94"
                                            bp2="-77"
                                            iwidth="32"
                                            iheight="31"
                                            className="star"
                                        />
                                        <IconNew
                                            bp1="-75"
                                            bp2="-206"
                                            iwidth="24"
                                            iheight="29"
                                            className="share"
                                        />
                                        <ButtonsWrapper>
                                            <FormButtonText
                                                styled={ContactButtonStyle}
                                            >
                                                Contact{' '}
                                                <ArrowDown color={'white'} />
                                            </FormButtonText>
                                            <FormButtonText
                                                styled={ConversationButton}
                                            >
                                                <ButtonNumber>
                                                    {Data.details.conversation}
                                                </ButtonNumber>
                                                <ConversationSpan>
                                                    Conversations
                                                </ConversationSpan>
                                            </FormButtonText>
                                        </ButtonsWrapper>
                                        <TagWrapper>
                                            {Data.details.tags.map(
                                                (tag, index) => (
                                                    <TagsStyle key={index}>
                                                        {tag.tag}
                                                    </TagsStyle>
                                                )
                                            )}
                                            <FormButtonText styled={AddButton}>
                                                +
                                            </FormButtonText>
                                        </TagWrapper>
                                    </ProfileOptions>
                                </LeftSideTopWrapper>
                                <DetailsWrapper>
                                    <SectionWrapper
                                        BorderBottom="2px solid #EAEAEA"
                                        TopMargin="48px"
                                    >
                                        <LeftSideWrap>
                                            <div>Username</div>
                                            <div>Age</div>
                                            <div>
                                                Last Interaction
                                            </div>
                                            <div>
                                                Time Frame
                                            </div>
                                            <div>
                                                Martial Status
                                            </div>
                                        </LeftSideWrap>
                                        <RightSideWrap>
                                            <TextWrapperUser>
                                                {this.ProfileData.UserName.User}
                                            </TextWrapperUser>
                                            <TextWrapper>
                                                {this.ProfileData.Age}
                                            </TextWrapper>
                                            <TextWrapper>
                                                {
                                                    this.ProfileData
                                                        .LastInteraction
                                                }
                                            </TextWrapper>
                                            <TextWrapper>
                                                {this.ProfileData.TimeFrame}
                                                <IconNew
                                                    bp1="-134"
                                                    bp2="-185"
                                                    iwidth="19"
                                                    iheight="17"
                                                />
                                            </TextWrapper>
                                            <TextWrapper>
                                                {
                                                    this.ProfileData
                                                        .LastInteraction
                                                }
                                            </TextWrapper>
                                        </RightSideWrap>
                                    </SectionWrapper>
                                    <SectionWrapper
                                        BorderBottom="2px solid #EAEAEA"
                                        TopMargin="37.7px"
                                    >
                                        <LeftSideWrap>
                                            <TextWrapper>
                                                Property Type
                                            </TextWrapper>
                                            <TextWrapper>
                                                Listing Type
                                            </TextWrapper>
                                            <TextWrapper>Location</TextWrapper>
                                        </LeftSideWrap>
                                        <RightSideWrap>
                                            <TextWrapper>
                                                {this.ProfileData.PropertyType}
                                                <IconNew
                                                    bp1="-134"
                                                    bp2="-185"
                                                    iwidth="19"
                                                    iheight="17"
                                                />
                                            </TextWrapper>
                                            <TextWrapper>
                                                {this.ProfileData.ListingType}
                                                <IconNew
                                                    bp1="-134"
                                                    bp2="-185"
                                                    iwidth="19"
                                                    iheight="17"
                                                />
                                            </TextWrapper>
                                            <TextWrapper>
                                                {this.ProfileData.Location}
                                            </TextWrapper>
                                        </RightSideWrap>
                                    </SectionWrapper>
                                    <SectionWrapper TopMargin="21.7px">
                                        <LeftSideWrap>
                                            <div>
                                                Price Range
                                            </div>
                                            <div>
                                                Pre Approved
                                            </div>
                                        </LeftSideWrap>
                                        <RightSideWrap>
                                            <TextWrapper>
                                                {this.ProfileData.PriceRange}
                                                <IconNew
                                                    bp1="-134"
                                                    bp2="-185"
                                                    iwidth="19"
                                                    iheight="17"
                                                />
                                            </TextWrapper>
                                            <TextWrapper>
                                                {this.ProfileData.PreApproved}
                                            </TextWrapper>
                                        </RightSideWrap>
                                    </SectionWrapper>
                                </DetailsWrapper>
                            </LeftSideWrapper>
                            <RightSideWrapper>
                                <MeetingSchedule>
                                    <button>
                                        <IconNew
                                            bp1="-528"
                                            bp2="-424"
                                            iwidth="27"
                                            iheight="27"
                                        />
                                    </button>
                                    Meeting - {Data.details.meeting.date} -{' '}
                                    {Data.details.meeting.hour}
                                </MeetingSchedule>
                                <FollowUpWrap>
                                    <FollowUpItem>
                                        <LastFollowUpText
                                            style={{
                                                color: '#797a80',
                                            }}
                                        >
                                            Last Follow Up
                                        </LastFollowUpText>
                                        <span
                                            style={{
                                                marginRight: '50px',
                                                color: '#313136',
                                            }}
                                        >
                                            {Data.details.followUp}
                                        </span>
                                        <FormButtonText
                                            styled={RemiderButtonStyle}
                                        >
                                            + Reminder
                                        </FormButtonText>
                                    </FollowUpItem>
                                </FollowUpWrap>
                                <div style={{ marginTop: '30.5px' }}>
                                    <HeaderWrapper>
                                        <ArrowRight color={'violet'} />
                                        <p>Notes</p>
                                    </HeaderWrapper>
                                    {Data.details.note.map((note, index) => (
                                        <NoteWrapper key={index}>
                                            <NoteText>{note.text}</NoteText>
                                            <NoteHeader>NOTE</NoteHeader>
                                            <DateHeader>{note.date}</DateHeader>
                                        </NoteWrapper>
                                    ))}
                                    <FormButtonText styled={AddNoteButtonStyle}>
                                        Add Note
                                    </FormButtonText>
                                </div>
                            </RightSideWrapper>
                        </ProfileDetailsWrapper>
                        <SimilarLeadManagementListings
                            PotentialData={this.PotentialData}
                        />
                    </ComponentWrapper>
                </Column>
                <ProfileMenu />
                <ClearFloats />
            </React.Fragment>
        );
    }
}

export default LeadManagmentProfileDetails;
