import styled, { css } from 'styled-components';

export const ProfileDetailsWrapper = styled.div`
    display: flex;
    background: white;
    margin-top: 20px;
    border-radius: 10px;
    padding: 45px 66px;
    justify-content: space-between;
    height: 100%;
`;

export const LeftSideWrapper = styled.div`
    width: 52%;
    position: relative;
`;

export const RightSideWrapper = styled.div`
    width: 45%;
`;


export const ProfileNameStyle = styled.span`
    color: #19257c;
    font-size: 39px;
    font-family: 'Montserrat';
`;

export const ProfileOptions = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;

    .share {
        float: right;
        margin-top: 3px;
    }
    .star {
        float: right;
        margin-left: 20px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const ContactButtonStyle = css`
    font-size: 17px;
    font-family: 'Montserrat';
    background-image: linear-gradient(
        to right,
        ${(props) => props.theme.violet} 3%,
        #550493 98%
    );
    color: white;
    border: none;
    padding-top: 4%;
    padding-bottom: 4%;
    padding-right: 7.1%;
    padding-left: 7.1%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
`;
export const ConversationButton = css`
    margin-left: 10px;
    font-size: 17px;
    border: 2px solid ${(props) => props.theme.violet};
    border-radius: 5px;
    background: white;
    padding-right: 21px !important;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    padding-left: 12px !important;
    color: ${(props) => props.theme.violet};
`;

export const TagsStyle = styled.span`
    color: ${(props) => props.theme.violet};
    font-size: 18px;
    font-family: 'Montserrat';
    background: ${(props) => props.theme.lightestBlue};
    padding-left: 8px;
    padding-right: 8px;
    &:not(:first-of-type) {
        margin-left: 8px;
    }
`;

export const TagWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    
`;

export const AddButton = css`
    border: 1px solid ${(props) => props.theme.violet};
    background: white;
    font-size: 25px;
    color: ${(props) => props.theme.violet};
    margin-left: 8px;
    font-weight: bold;
    height: 34px;
    width: 34px;
`;

export const ButtonNumber = styled.span`
    color: white;
    background: ${(props) => props.theme.violet};
    border: none;
    font-size: 17px;
    padding: 2px 8px;
    border-radius: 4px;
    margin-right: 5px;
`;

export const ConversationSpan = styled.div`
    display: inline-block;
    margin-left: 1px;
    font-family: 'Montserrat';
`;

export const MeetingSchedule = styled.div`
    color: ${(props) => props.theme.mainFontColor};
    font-size: 22px;
    font-family: 'Montserrat';
    text-align: right;
    button {
        background: transparent;
        border: 0;
    }
`;

export const FollowUpWrap = styled.div`
    margin-top: 175px;
    padding-top: 24.5px;
    padding-bottom: 24.5px;
    border-top: 2px solid ${(props) => props.theme.bodyBg};
    border-bottom: 2px solid ${(props) => props.theme.bodyBg};
`;
export const FollowUpItem = styled.div`
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const RemiderButtonStyle = css`
    border-radius: 5px;
    border: solid 1px ${(props) => props.theme.violet};
    background: white;
    color: ${(props) => props.theme.violet};
    padding: 5px 19px;
    font-size: 19px;
`;

export const HeaderWrapper = styled.div`
    color: ${(props) => props.theme.violet};
    font-size: 24px;
    display: flex;
    align-items: center;

    > span {
        margin-top: 7px;
    }

    > p {
        margin: 0;
    }
`;

export const AddNoteButtonStyle = css`
    border-radius: 5px;
    font-size: 18px;
    color: ${(props) => props.theme.violet};
    background: white;
    border: 1px solid ${(props) => props.theme.violet};
    margin-top: 20px !important;
    padding: 1.3% 5.6%;
    width: 150px;
    height: 40px;
    cursor: pointer;
`;

export const NoteWrapper = styled.div`
    border-radius: 5px;
    background: ${(props) => props.theme.grayBgInput};
    font-size: 18px;
    position: relative;
    padding-top: 43px;
    padding-bottom: 30px;
    width: 529px;
    line-height: 1.33;
    text-align: center;
    margin-top: 17px;
    padding-left: 2px;
`;

export const NoteText = styled.span`
    width: 496px;
    display: inline-block;
    text-align: left;
    font-family: 'Montserrat';
`;

export const DateHeader = styled.span`
    position: absolute;
    right: 10px;
    font-family: 'Montserrat';
    top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.textBlack};
`;

export const NoteHeader = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    font-family: 'Montserrat';
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    background: #c2c2c2;
    color: ${(props) => props.theme.textBlack};
    font-weight: bold;
    line-height: 1.31;
    padding: 5px 12px;
`;

export const DetailsWrapper = styled.div``;

export const SectionWrapper = styled.div`
    display: flex;
    border-bottom: ${(props) =>
        props.BorderBottom ? props.BorderBottom : null};
    padding-bottom: 30px;
    margin-top: ${(props) => (props.TopMargin ? props.TopMargin : null)};
`;

export const LeftSideWrap = styled.div`
    color: ${(props) => props.theme.mainFontColor};
    font-size: 21px;
    width: 40%;

    div {
    position: relative;
    font-family: 'Montserrat';
    &:not(:first-of-type) {
        margin-top: 20px;
    }

    > span {
        position: absolute;
        right: 0;
        top: 10px;
    }
    }
`;

export const TextWrapper = styled.div`
    position: relative;
    font-family: 'Montserrat';
    &:not(:first-of-type) {
        margin-top: 20px;
    }

    > span {
        position: absolute;
        right: 0;
        top: 10px;
    }
`;

export const RightSideWrap = styled.div`
    margin-left: 10%;
    width: 70%;
    font-size: 21px;
    color: ${(props) => props.theme.textBlack};
`;

export const TextWrapperUser = styled.div`
    color: ${(props) => props.theme.cyan};
    font-size: 21px;
    font-family: 'Montserrat';
`;

export const ComponentWrapper = styled.div`
    display: flex;
    background: white;
    flex-direction: column;
`;

export const LastFollowUpText = styled.span`
    font-size: 22;
    color: #797a80;
    font-family: 'Montserrat';
`;

export const LeftSideTopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 142px;
        height: 142px;
        border-radius: 10px;
    }
`;
