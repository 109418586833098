import React, {Fragment, useState} from "react";
import {
    ArrowLeftNav,
    ArrowRightNav,
    NavButtonBlankStyles,
    NavButtonRedStyles,
    NavButtonStyles, NavLoading,
    Wrapper
} from "./Styled";
import ButtonText from "../FormButtonText";
import {ClearFloats} from "../BaseTemplate/Styled";
import {useHistory} from "react-router-dom";
import {sendAsyncRequest} from "../../../Utils";
import PropTypes from 'prop-types';

const WizardItemNavigation = (props) => {

    const [loading, isLoading] = useState(false);
    const history = useHistory();

    const handleSaveDraft = async () => {
        const fields = {...props.fields};
        isLoading(true);
        const result = await sendAsyncRequest('/post', 'post', {
            ...fields
        });
        props.pageChanged(false);
        console.log(result);
        isLoading(false);
    };

    const handleDiscardDraft = async () => {
        isLoading(true);
        const result = await sendAsyncRequest('/post', 'post', {});
        props.pageChanged(false);
        console.log(result);
        isLoading(false);
    };

    const handlePrev = () => {
        if (props.prevPage) {
            history.push(props.prevPage);
        }
    };

    return (
        <Wrapper type={props.type}>
            {
                (props.type === WizardNavigationType.NEXT_PREV) &&
                <Fragment>
                    <button onClick={props.nextPage}>
                        {
                            (props.isFinal)
                                ? 'Finish'
                                : 'Next'
                        }
                        <ArrowRightNav/>
                    </button>
                    <button
                        onClick={handlePrev}
                        className={(!props.prevPage) ? 'no-pointer' : ''}
                    >
                        <ArrowLeftNav/>
                    </button>
                </Fragment>
            }
            {
                (props.type === WizardNavigationType.DRAFT) &&
                <Fragment>
                    {
                        (props.hasOwnProperty("nextPage")) &&
                        <ButtonText
                            styled={NavButtonStyles}
                            onClick={props.nextPage}
                        >
                            {
                                (!props.isFinal) ? props.buttonText : "Publish"
                            }
                            <ArrowRightNav/>
                        </ButtonText>
                    }
                    {
                        !(props.hasOwnProperty("nextPage")) &&
                        <ButtonText styled={NavButtonStyles}
                            onClick={props.onPublish}>
                            {
                                (!props.isFinal) ? props.buttonText : "Publish"
                            }
                            <ArrowRightNav/>
                        </ButtonText>
                    }
                    <ButtonText styled={NavButtonBlankStyles}
                                onClick={handleSaveDraft}>
                        Save as Draft
                    </ButtonText>
                    <ButtonText styled={NavButtonRedStyles}
                                onClick={handleDiscardDraft}>
                        Discard<i/>
                    </ButtonText>
                    <ClearFloats/>
                </Fragment>
            }
            {
                (loading || props.loading) &&
                <NavLoading />
            }
        </Wrapper>
    );
};

export default WizardItemNavigation;

export const WizardNavigationType = {
    NEXT_PREV: 'next_prev',
    DRAFT: 'draft'
};

WizardItemNavigation.propTypes = {
    type: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    fields: PropTypes.object,
    pageChanged: PropTypes.func,
    loading: PropTypes.bool,
    nextPage: (props, propName, componentName) => {
        if (props.nextPage && props.onPublish && props.type === WizardNavigationType.DRAFT) {
            return new Error(
                `You shouldn't use 'nextPage' & 'onPublish' together in '${componentName}'.`
            );
        }
        if (!props.nextPage && !props.onPublish && props.type === WizardNavigationType.DRAFT) {
            return new Error(
                `You should have one of 'nextPage' or 'onPublish' in '${componentName}'.`
            );
        }
    },
    onPublish: (props, propName, componentName) => {
        if (props.nextPage && props.onPublish && props.type === WizardNavigationType.DRAFT) {
            return new Error(
                `You shouldn't use 'nextPage' & 'onPublish' together in '${componentName}'.`
            );
        }
        if (!props.nextPage && !props.onPublish && props.type === WizardNavigationType.DRAFT) {
            return new Error(
                `You should have one of 'nextPage' or 'onPublish' in '${componentName}'.`
            );
        }
    }
};
